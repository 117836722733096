import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
  // Tabs,
  Card,
  Row,
  Col,
  Form,
  Input,
  DatePicker,
  Button,
  Popover,
  Tooltip,
  Modal,
  Radio,
  Steps,
  Icon,
  Dropdown,
  Menu
} from "antd";
import { dateFormatList } from "util/config";
import { formatDate } from "util/helpers";
import AutoCompleteClient from "components/AutoCompleteClient";
import { schema } from "./schema";
import crypt from 'node-cryptex'
import {k, v} from 'util/config'
import moment from 'moment-timezone'
import {
  rxRegistroEditarProyecto,
  rxOptimizarDataProyecto,
  rxActualizarProyectoEstado,
  rxSeleccionarProyectoEtiquetas,
  rxSeleccionarProyectoSeguimiento,
  rxToggleModalTableros
} from "appRedux/actions/Projects";
import {rxSelectProyetoCliente, clearRetrieveInfoClient} from '../../../appRedux/actions/Clients'
import LeyendaCodigoCantos from "../Table/LeyendaCodigoCantos";
import FormDataGeneral from "../Client/FormDataGeneral";
import PageBack from "components/PageBack";
import { Tracing } from "../Modal/Tracing";
import Material from "../Modal/Material";
import MapTableros from "../Modal/MapTableros";
import Cantos from "../Modal/Cantos";
import Groove from "../Modal/Groove";
import Perforation from "../Modal/Perforation";
import GrooveD25 from "../Modal/GrooveD25";
import PerforationD25 from "../Modal/PerforationD25";

moment.tz('America/Lima')

const current = sEstado => {
  const es = sEstado ? sEstado.toLowerCase() : 0;
  switch (es) {
    case "emitido":
      return 0;
    case "optimizado":
      return 1;
    case "pedido":
      return 2;
    case "aprobado":
      return 3;
    default:
      return 0;
  }
};

const StatusProject = ({ sEstado }) => {
  return (
    <Popover
      content={
        <Steps direction="horizontal" current={current(sEstado)} size="small">
          <Steps.Step title="Emitido" />
          <Steps.Step title="Optimizado" />
          <Steps.Step title="Pedido" />
          <Steps.Step title="Aprobado" />
        </Steps>
      }
    >
      <Icon type="info-circle" />
    </Popover>
  );
};

class ProyectTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visibleModalCliente: false,
      actionModal: 'register',
      activeKey: "projects",
      visibleTracing: false,
      nIdCliente: null,
      cliNombre: ''
    };
    window.addEventListener("keyup", event => {
      event.preventDefault();
      switch (event.key) {
        case "F4":
          this.handleSubmit(event);
          break;
        case "F8":
          this.handleOptimizarProyecto();
          break;
        case "F9":
          this.handleVerPlanos();
          break;
        case "F10":
          this.handleVerResultadosProyecto();
          break;
        default:
          break;
      }
    });
  }

  componentDidUpdate(prevProps) {
    const { headerProject } = this.props;
    const { setFieldsValue } = this.props.form;
    if (prevProps.headerProject !== headerProject) {
      /**
       * SET VALUE HEADER
       */
      if (headerProject) {
        setFieldsValue({
          sProyecto: headerProject.sProyecto,
          sCliNombre: headerProject.sCliNombre,
          sObra: headerProject.sObra,
          dFechaRegistro: headerProject.dFechaRegistro
            ? moment(
                formatDate(headerProject.dFechaRegistro, "date"),
                dateFormatList[0]
              )
            : null,
          sEstado: headerProject.sEstado,
          sTipoMedida: headerProject.sTipoMedida
        });
        this.setState({nIdCliente: headerProject.nIdCliente})
      }
    }
  }

  handleChangeTabOnRequiredError = errors => {
    if (
      errors.includes("sPedido") ||
      errors.includes("dPedido") ||
      errors.includes("sFactura") ||
      errors.includes("dFactura")
    ) {
      this.onChangeTab("sales");
    } else if (errors.includes("sObsVentas") || errors.includes("sObsProd")) {
      this.onChangeTab("obs");
    } else {
      this.onChangeTab("projects");
    }
  };

  generateForm = (values, dataTable, sUsuario, nIdUsuario) => {
    const formData = new FormData();
    const JSONCantos = [];
    const JSONDetalle = [];
    const JSONTableros = [];
    const { listCodEdges, nIdProyecto, listCodeBoards } = this.props;

    for (const edge of listCodEdges) {
      JSONCantos.push({
        nIdProyectoCanto: edge.nIdProyectoCanto ? edge.nIdProyectoCanto : null,
        nIdCanto: edge.nIdCanto,
        nIdProyecto: nIdProyecto ? nIdProyecto : "",
        sCodCanto: edge.sCodCanto,
        sDescCanto: edge.sDescCanto,
        bMatCliente: false,
        bKdxCliente: false
      });
    }
    for (let i = 0; i < dataTable.length; i++) {
      JSONDetalle.push({
        nItem: dataTable[i].nItem ? dataTable[i].nItem : i + 1,
        nId: dataTable[i].nId,
        nIdProyecto: nIdProyecto,
        nIdTablero: dataTable[i].nIdTablero,
        nAncho: dataTable[i].nAncho,
        nLargo: dataTable[i].nLargo,
        nCantidad: dataTable[i].nCantidad,
        bVeta: dataTable[i].bVeta,
        sCantoA1: dataTable[i].sCantoA1,
        sCantoA2: dataTable[i].sCantoA2,
        sCantoL1: dataTable[i].sCantoL1,
        sCantoL2: dataTable[i].sCantoL2,
        sCodRan: dataTable[i].sCodRan,
        sTipoRanura: "",
        nDist_BordeLateralRan: 0,
        nEspesorRan: 0,
        nProfundidadRan: 0,
        bRanA1: dataTable[i].bRanA1 ? true : false,
        bRanA2: dataTable[i].bRanA2 ? true : false,
        bRanL1: dataTable[i].bRanL1 ? true : false,
        bRanL2: dataTable[i].bRanL2 ? true : false,
        sCodPerf: dataTable[i].sCodPerf,
        nCantidadPerf: dataTable[i].nCantidadPerf,
        nDistBordeLateralPerf: 0,
        nDistBordeSuperiorPerf: 0,
        nDistBordeInferiorPerf: 0,
        nProfundidadPerf: 0,
        nDiametroPerf: 0,
        sLadoPerf: dataTable[i].sLadoPerf,
        sCodRanD25: dataTable[i].sCodRanD25,
        sCodPerfD25: dataTable[i].sCodPerfD25,
        sLadoD25: dataTable[i].sLadoD25,
        bServicioEsp: dataTable[i].bServicioEsp ? true : false,
        sPlano: "",
        sDescPiezas: "",
        sDescMueble: "",
        sObservaciones: dataTable[i].sObservaciones
      });
    }

    for (const board of listCodeBoards) {
      JSONTableros.push({
        nIdProyectoTablero: board.nIdProyectoTablero
          ? board.nIdProyectoTablero
          : null,
        nIdProyecto: nIdProyecto,
        nIdTablero: board.nIdTablero,
        nLinea: board.nLinea,
        sCodTablero: board.sCodTablero,
        sDescTablero: board.sDescTablero,
        nStock: board.nStock,
        nEspesor: board.nEspesor,
        nAncho: board.nAncho,
        nLargo: board.nLargo,
        bVeta: board.bVeta,
        bMatCliente: board.bMatCliente,
        bKdxCliente: 0
      });
    }

    if (nIdProyecto) formData.set("nIdProyecto", nIdProyecto);
    if (nIdUsuario) formData.set("nIdUsuario", nIdUsuario);
    formData.set('nIdCliente', this.state.nIdCliente)
    
    if (values.sObra) formData.set("sObra", values.sObra);
    if (values.sObsVentas) formData.set("sObsVentas", values.sObsVentas);
    if (values.sObsProd) formData.set("sObsProd", values.sObsProd);
    if (values.sTipoMedida) formData.set("sTipoMedida", values.sTipoMedida);
    if (sUsuario) formData.set("sUser", sUsuario);
    formData.set("sOrigen", "M");
    formData.set("sTipoEntrega", "I");
    formData.set("JSONCantos", JSON.stringify(JSONCantos));
    formData.set("JSONDetalle", JSON.stringify(JSONDetalle));
    formData.set("JSONTableros", JSON.stringify(JSONTableros));
    this.props.rxRegistroEditarProyecto(formData, nIdProyecto);
  };

  handleSubmit = e => {
    e.preventDefault();
    const { form, instanciaTabla, optimizerID } = this.props;
    form.validateFields((err, values) => {
      if (!err) {
        instanciaTabla.validateCells(valid => {
          if (valid) {
            const dataTable = instanciaTabla.getSourceData();
            this.generateForm(values, dataTable, 'eCarpintero', optimizerID);
          } else {
            instanciaTabla.validateCells();
            this.props.openNotificationWithIcon({
              type: "error",
              message: "Error",
              description: "Existen algunas celdas invalidas, Para guardar primero debe corregir."
            });
          }
        });
      } else {
        const errors = Object.keys(err);
        this.handleChangeTabOnRequiredError(errors);
      }
    });
  };

  onChangeTab = activeKey => {
    this.setState({ activeKey: activeKey });
  };

  /**
   * Modal Cliente
   */
  handleShowModalRegCliente = (action) => {
    this.setState({ visibleModalReg: true, actionModal: action });
  };
  handleCancelModalClient = (form = null) => {
    if (form) {
      form();
    }
    this.setState({ visibleModalReg: false });
    this.props.clearRetrieveInfoClient();
  };
  changeRegisteredCliente = (nIdCliente, cliNombre) => {
    this.setState({nIdCliente, cliNombre});
  }
  /**
   * Proyecto
   */
  handleOptimizarProyecto = () => {
    const { nIdProyecto } = this.props;
    this.props.rxOptimizarDataProyecto({
      nIdProyecto,
      sEstado: "O",
      sObs: "",
      sUser: 'eCarpintero'
    });
  };

  handleVerPlanos = () => {
    const { nIdProyecto } = this.props;
    if (nIdProyecto !== null) {
      window.open(
        `/optimizador/planos/${crypt.encrypt(
          JSON.stringify(nIdProyecto),
          k,
          v
        )}`,
        "_blank"
      );
    }
  };

  handleVerResultadosProyecto = () => {
    const { nIdProyecto, headerProject, historyProp } = this.props;
    historyProp.push(
      `/usuario/optimizador/material-summary/${crypt.encrypt(
        JSON.stringify(nIdProyecto),
        k,
        v
      )}/${crypt.encrypt(
        JSON.stringify(headerProject.sEstado),
        k,
        v
      )}`
    );
  };

  handleActualizarEstadoProyecto = () => {
    const { nIdProyecto } = this.props;
    this.props.rxActualizarProyectoEstado({
      nIdProyecto,
      sEstado: "E",
      sObs: "Reversión de Optimización",
      sUser: 'eCarpintero'
    });
  };

  handleOnClickInfoUser = nIdCliente => {
    if (nIdCliente && !isNaN(Number(nIdCliente))) {
      this.setState({nIdCliente});
    }
  };

  handleShowMapTableros = () => {
    this.props.rxToggleModalTableros({ visible: true, info: null });
  };

  handleAddRow = () => {
    const { instanciaTabla } = this.props;
    if (instanciaTabla) {
      const totalRow = instanciaTabla.countRows();
      this.props.handleAddNeRow(totalRow - 1);
    }
  };
  handleOnPrintLabels = () => {
    const { rxSeleccionarProyectoEtiquetas, headerProject } = this.props;
    const nId = headerProject && headerProject.nIdProyecto;
    rxSeleccionarProyectoEtiquetas(nId);
  };

  // seguimiento proyecto
  handleSeguimientoProyecto = () => {
    const { rxSeleccionarProyectoSeguimiento, headerProject } = this.props;
    const nId = headerProject && headerProject.nIdProyecto;
    rxSeleccionarProyectoSeguimiento(nId);
    this.setState({ visibleTracing: true });
  };
  OnCloseModalTracing = () => {
    this.setState({ visibleTracing: false });
  };

  gotBack = () => {
    this.props.historyProp.push('/usuario/optimizador');
  };

  render() {
    const {
      listCodEdges,
      savingProject,
      nIdProyecto,
      form,
      handleMassiveChange,
      headerProject,
      fetchingTracing,
      listTracingProject,
      handleAddNeRowOnBoardsTBL,
      handleOnSelectBoardInModal,
      handleOnSelectEdgesModal,
      handleOnSelectGroove,
      handleOnSelectPerforation,
      handleOnSelectGrooveD25,
      handleOnSelectPerforationD25
    } = this.props;
    const { getFieldDecorator } = form;
    const { visibleTracing } = this.state;
    const disabledLgnTBL = headerProject && headerProject.sEstado === "Optimizado" ? true : false;

    const menu = (
      <Menu>
        {headerProject && (headerProject.sEstado === 'Optimizado' || headerProject.sEstado === 'Pedido') && (
          <Menu.Item key="0" onClick={this.handleVerResultadosProyecto}>
            <i className="far fa-eye gx-mr-2" style={{width: '20px'}}/>
            Ver resultado
          </Menu.Item>
        )}
        {headerProject && (headerProject.sEstado === 'Optimizado' || headerProject.sEstado === 'Pedido') && (
          <Menu.Item key="1" onClick={this.handleSeguimientoProyecto}>
            {!fetchingTracing ? <i className="far fa-tasks gx-mr-2" style={{width: '20px'}}/> : null}
            Seguimiento
          </Menu.Item>
        )}
        <Menu.Item key="2" onClick={() => this.handleShowMapTableros()}>
          <i className="fal fa-border-all gx-mr-2" style={{width: '20px'}}/>
          Ver Tableros
        </Menu.Item>
      </Menu>
    )

    return (
      <Fragment>
        <Col xs={24} sm={24} md={24} lg={24} className="gx-p-0 gx-p-lg-3">
          <Card
            title={
              <PageBack
                arrowTitle="Ir a Proyectos"
                pageTitle={
                  headerProject && headerProject.sProyecto
                    ? "EDITAR PROYECTO"
                    : "CREAR PROYECTO"
                }
                handleOnClick={this.gotBack}
              />
            }
            bodyStyle={{ padding: "10px 0 10px 0" }}
          >
            <Form
              className="gx-form-row0"
              onSubmit={this.handleSubmit}
              hideRequiredMark
            >
              <Row /* gutter={16} className="gx-pl-2 gx-pr-2" */>
                <Col xs={24} sm={24} md={6} lg={6}>
                  <Form.Item className="form-item-imd" label="Proyecto">
                    {getFieldDecorator(schema.sProyecto.name, {
                      rules: [
                        {
                          required: schema.sProyecto.rule.required,
                          message: schema.sProyecto.rule.message
                        }
                      ]
                    })(
                      <Input
                        type={schema.sProyecto.type}
                        placeholder={schema.sProyecto.placeHolder}
                        readOnly
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6}>
                  <Form.Item
                    className="form-item-imd"
                    label={schema.dFechaRegistro.label}
                  >
                    {getFieldDecorator(
                      schema.dFechaRegistro.name,
                      { initialValue: moment() },
                      {
                        rules: [
                          {
                            required: schema.dFechaRegistro.rule.required,
                            message: schema.dFechaRegistro.rule.message
                          }
                        ]
                      }
                    )(
                      <DatePicker
                        format={dateFormatList[0]}
                        style={{ width: "100%" }}
                        disabled
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6}>
                  <Form.Item
                    className="form-item-imd"
                    label={schema.sEstado.label}
                  >
                    {getFieldDecorator(
                      schema.sEstado.name,
                      { initialValue: "Emitido" },
                      {
                        rules: [
                          {
                            required: schema.sEstado.rule.required,
                            message: schema.sEstado.rule.message
                          }
                        ]
                      }
                    )(
                      <Input
                        readOnly
                        suffix={
                          headerProject ? (
                            <StatusProject
                              sEstado={headerProject.sEstado}
                            />
                          ) : null
                        }
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6}>
                  <Form.Item
                    className="form-item-imd"
                    label={schema.nOptimizador.label}
                  >
                    {getFieldDecorator(
                      schema.nOptimizador.name,
                      { initialValue: "Estándar" },
                      {
                        rules: [
                          {
                            required: schema.nOptimizador.rule.required,
                            message: schema.nOptimizador.rule.message
                          }
                        ]
                      }
                    )(<Input readOnly />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <AutoCompleteClient
                    form={form}
                    label={schema.sCliNombre.label}
                    name={schema.sCliNombre.name}
                    required={schema.sCliNombre.rule.required}
                    message={schema.sCliNombre.rule.message}
                    infoClient={this.props.infoClient}
                    handleOnSelect={value => this.handleOnClickInfoUser(value)}
                    handleShowModalRegCliente={this.handleShowModalRegCliente}
                    getInfoClient={this.props.rxSelectProyetoCliente}
                    nIdCliente={this.state.nIdCliente}
                    cliNombre={this.state.cliNombre}
                    nIdClienteClear={() => this.setState({nIdCliente : null})}
                    changeRegisteredCliente={this.changeRegisteredCliente}
                  />
                </Col>
                <Col xs={24} sm={24} md={6} lg={6}>
                  <Form.Item
                    className="form-item-imd"
                    label={schema.sObra.label}
                  >
                    {getFieldDecorator(schema.sObra.name, {
                      rules: [
                        {
                          required: schema.sObra.rule.required,
                          message: schema.sObra.rule.message
                        }
                      ]
                    })(<Input />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6}>
                  <Form.Item
                    className="form-item-imd"
                    label={schema.sTipoMedida.label}
                  >
                    {getFieldDecorator(schema.sTipoMedida.name, {
                      initialValue: 'A',
                      rules: [
                        {
                          required: schema.sTipoMedida.rule.required,
                          message: schema.sTipoMedida.rule.message
                        }
                      ]
                    })(
                      <Radio.Group buttonStyle="solid">
                        <Radio.Button value="A">Corte</Radio.Button>
                      </Radio.Group>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} className="gx-mb-0">
          <div className="gx-mb-2 gx-btn-group-amz gx-d-flex gx-align-items-center gx-justify-content-end">
            {headerProject ? (
              <Fragment>
                {headerProject ? (
                  headerProject.sEstado === "Emitido" ? (
                    <Tooltip title="Agregar Fila">
                      <Button size="small" onClick={this.handleAddRow}>
                        <i className="far fa-plus" />
                      </Button>
                    </Tooltip>
                  ) : null
                ) : null}
                {headerProject.sEstado === "Optimizado" ? (
                  nIdProyecto ? (
                    <Fragment>
                      <Button size="small" className="gx-d-flex gx-align-items-center" onClick={this.handleActualizarEstadoProyecto}>
                        <i className="far fa-undo gx-mr-2 gx-d-none gx-d-sm-block" />
                        <span className="gx-d-none gx-d-lg-block">Reversar Optimización</span>
                        <span className="gx-d-block gx-d-lg-none">Reversar</span>
                      </Button>
                      <Button size="small" className="gx-d-flex gx-align-items-center" onClick={this.handleVerPlanos}>
                        <i className="far fa-file-spreadsheet gx-mr-2 gx-d-none gx-d-sm-block" />
                        <span className="gx-d-none gx-d-lg-block">Ver planos (F9)</span>
                        <span className="gx-d-block gx-d-lg-none">Planos</span>
                      </Button>
                      <Button className="gx-d-none gx-d-lg-flex gx-align-items-center" size="small" onClick={this.handleVerResultadosProyecto}>
                        <i className="far fa-eye gx-mr-2" />
                        <span className="gx-d-none gx-d-lg-block">Ver resultado (F10)</span>
                        <span className="gx-d-block gx-d-lg-none">Ver resultado</span>
                      </Button>
                      <Button
                        size="small"
                        loading={fetchingTracing}
                        onClick={this.handleSeguimientoProyecto}
                        className="gx-d-none gx-d-lg-block"
                      >
                        {!fetchingTracing ? (
                          <i className="far fa-tasks gx-mr-2" />
                        ) : null}
                        Seguimiento
                      </Button>
                    </Fragment>
                  ) : null
                ) : headerProject.sEstado === "Pedido" ? (
                  <Fragment>
                    <Button size="small" className="gx-d-flex gx-align-items-center" onClick={this.handleVerPlanos}>
                      <i className="far fa-file-spreadsheet gx-mr-2 gx-d-none gx-d-sm-block" />
                      <span className="gx-d-none gx-d-lg-block">Ver planos (F9)</span>
                      <span className="gx-d-block gx-d-lg-none">Planos</span>
                    </Button>
                    <Button className="gx-d-none gx-d-lg-block" size="small" onClick={this.handleVerResultadosProyecto}>
                      <i className="far fa-eye gx-mr-2" />
                      <span className="gx-d-none gx-d-lg-block">Ver resultado (F10)</span>
                      <span className="gx-d-block gx-d-lg-none">Ver resultado</span>
                    </Button>
                    <Button
                      loading={fetchingTracing}
                      onClick={this.handleSeguimientoProyecto}
                      className="gx-d-none gx-d-lg-block"
                    >
                      {!fetchingTracing ? (
                        <i className="far fa-tasks gx-mr-2" />
                      ) : null}
                      Seguimiento
                    </Button>
                  </Fragment>
                ) : headerProject.sEstado === "Emitido" ? (
                  <Button size="small" className="gx-d-flex gx-align-items-center" onClick={this.handleSubmit} disabled={savingProject}>
                    <i className="far fa-save gx-mr-2 gx-d-none gx-d-sm-block" />
                    <span className="gx-d-none gx-d-lg-block">Guardar (F4)</span>
                    <span className="gx-d-block gx-d-lg-none">Guardar</span>
                  </Button>
                ) : null}

                {headerProject.sEstado === "Emitido" ? (
                  <Button size="small" className="gx-d-flex gx-align-items-center" onClick={this.handleOptimizarProyecto}>
                    <i className="far fa-file-spreadsheet gx-mr-2 gx-d-none gx-d-sm-block" />
                    <span className="gx-d-none gx-d-lg-block">Optimizar (F8)</span>
                    <span className="gx-d-block gx-d-lg-none">Optimizar</span>
                  </Button>
                ) : null}
              </Fragment>
            ) : (
              <Button size="small" className="gx-d-flex gx-align-items-center" onClick={this.handleSubmit} disabled={savingProject}>
                <i className="far fa-save gx-mr-2 gx-d-none gx-d-sm-block" />
                <span className="gx-d-none gx-d-lg-block">Guardar (F4)</span>
                <span className="gx-d-block gx-d-lg-none">Guardar</span>
              </Button>
            )}
            <Button className="gx-d-none gx-d-lg-block" size="small" onClick={() => this.handleShowMapTableros()}>
              <i className="fal fa-border-all gx-mr-2" />
              Ver Tableros
            </Button>

            {listCodEdges.length > 0 ? (
              <Popover
                content={
                  <LeyendaCodigoCantos
                    dataSource={listCodEdges}
                    handleMassiveChange={handleMassiveChange}
                  />
                }
                placement="bottom"
                trigger={['click', 'hover']}
              >
                <Button size="small" className="gx-d-flex gx-align-items-center">
                  <i className="fal fa-wave-sine gx-mr-2 gx-d-none gx-d-sm-block" />
                  <span className="gx-d-none gx-d-lg-block">Ver cantos</span>
                  <span className="gx-d-block gx-d-lg-none">Cantos</span>
                </Button>
              </Popover>
            ) : null}

            <Dropdown.Button className="gx-d-flex gx-d-lg-none" size="small" trigger={['click']} overlay={menu}>
              Más
            </Dropdown.Button>
          </div>
        </Col>
        <Modal
          visible={this.state.visibleModalReg}
          onCancel={this.handleCancelModalClient}
          footer={null}
          keyboard={false}
          closable={false}
          centered
          maskClosable={false}
          title="Registrar Nuevo Cliente"
        >
          <FormDataGeneral 
            nIdClient={this.state.nIdCliente} 
            action={this.state.actionModal}
            isQuickRecord={true}
            handleCancelModalClient={this.handleCancelModalClient}
            changeRegisteredCliente={this.changeRegisteredCliente}
          />
        </Modal>
        <Tracing
          dataSource={listTracingProject}
          loading={fetchingTracing}
          onClose={this.OnCloseModalTracing}
          visibleTracing={visibleTracing}
        />
        <MapTableros
          handleAddNeRowOnBoardsTBL={handleAddNeRowOnBoardsTBL}
          disabledLgnTBL={disabledLgnTBL}
        />

        <Material handleOnSelectBoardInModal={handleOnSelectBoardInModal} />

        <Cantos handleOnSelectEdgesModal={handleOnSelectEdgesModal} />
        <Groove handleOnSelectGroove={handleOnSelectGroove} />
        <Perforation handleOnSelectPerforation={handleOnSelectPerforation} />
        <GrooveD25 handleOnSelectGrooveD25={handleOnSelectGrooveD25} />
        <PerforationD25
          handleOnSelectPerforationD25={handleOnSelectPerforationD25}
        />
      </Fragment>
    );
  }
}

const WrappedProyectTab = Form.create("formulario_registro")(ProyectTab);
const mapStateToProps = ({ auth, settings, projects, clients }) => {
  const { pathname } = settings;
  const { authUser, optimizerID } = auth;
  const {
    headerProject,
    savingProject,
    fetchingLabels,
    fetchingTracing,
    listTracingProject,
    fetchingInfoUser,
    infoUserPrj,
    listCodeBoards
  } = projects;
  const {infoClient} = clients;
  return {
    pathname,
    optimizerID,
    authUser,
    headerProject,
    savingProject,
    fetchingLabels,
    fetchingTracing,
    listTracingProject,
    infoClient,    
    fetchingInfoUser,
    infoUserPrj,
    listCodeBoards
  };
};
export default connect(mapStateToProps, {
  rxRegistroEditarProyecto,
  rxOptimizarDataProyecto,
  clearRetrieveInfoClient,
  rxActualizarProyectoEstado,
  rxSeleccionarProyectoEtiquetas,
  rxSeleccionarProyectoSeguimiento,
  rxSelectProyetoCliente,
  rxToggleModalTableros
})(WrappedProyectTab);
