import gql from "graphql-tag";

export const LOGIN_MUTATION = gql`
  mutation authenticate($input: LoginInput!) {
    login(input: $input) {
      access_token
      user {
        id
        name
        email
        last_name
        avatar
        lat
        lng
        phone
        cellphone
        reference_address
        user_type
        document_type {
          id
        }
        document_number,
        email_verified_at
        birth_date
        sex
        address
        specialty {
          id
          parent {
            id
          }
        }
        ubigeo {
          id
          district
          department
          province
        }
        favorites {
          id
          project {
            id
          }
        }
        uuid
        optimizer
        experience_years
        experience_resume
        wizard_panel
      }
    }
  }
`;

export const LOGOUT_MUTATION = gql`
  mutation {
    logout {
      status
      message
    }
  }
`;

export const RESEND_VERIFY_EMAIL = gql`
  mutation resendVerifyEmail($email: String!) {
    resendVerifyEmail(input: { email: $email }) {
      status
      message
    }
  }
`;

export const VERIFY_EMAIL = gql`
  mutation verifyEmail($token: String!) {
    verifyEmail(input: { token: $token }) {
      email
      message
      status
      tokens{
        access_token
        expires_in
        user{
          id
        }
      }
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation updatePassword(
    $old_password: String!
    $password: String!
    $password_confirmation: String!
  ) {
    updatePassword(
      input: {
        old_password: $old_password
        password: $password
        password_confirmation: $password_confirmation
      }
    ) {
      status
      message
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(input: { email: $email }) {
      status
      message
    }
  }
`;

export const UPDATE_FORGOTTEN_PASSWORD = gql`
  mutation updateForgottenPassword(
    $email: String!
    $token: String!
    $password: String!
    $password_confirmation: String!
  ) {
    updateForgottenPassword(
      input: {
        email: $email
        token: $token
        password: $password
        password_confirmation: $password_confirmation
      }
    ) {
      status
      message
    }
  }
`;
