import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, Row, Col, Table } from "antd";
import withSearchOnTable from "HOC/withSearchOnTable";
import { toggleModalRanura } from "appRedux/actions";
const columns = [
  {
    title: "Ranura",
    dataIndex: "sRanura",
    key: "sRanura",
    align: "center"
  },
  {
    title: "Espesor",
    dataIndex: "nEspesor",
    key: "nEspesor",
    align: "center"
  },
  {
    title: "Dist. Borde Lateral",
    dataIndex: "nDistBordeLateral",
    key: "nDistBordeLateral",
    align: "center"
  },
  {
    title: "Profundidad",
    dataIndex: "nProfundidad",
    key: "nProfundidad",
    align: "center"
  }
];
class Groove extends Component {
  constructor(props) {
    super(props);
    this.state = { dataSource: [] };
    this.refSearch = React.createRef();
  }
  componentDidUpdate(prevProps) {
    const { modalGroove, listGroove } = this.props;
    if (prevProps.modalGroove !== modalGroove) {
      this.refSearch.current && this.refSearch.current.focus();
      
      if (modalGroove.visible && this.state.dataSource.length === 0) {
        setTimeout(() => {
          this.setState({
            dataSource: listGroove.length > 0 ? listGroove.slice(0, 10) : []
          });
        }, 500);
      }
    }
  }

  closeModalGroove = () => {
    this.props.toggleModalRanura({ visible: false, info: null });
  };
  render() {
    const {
      // REDUX
      listGroove,
      fetchingGroove,
      modalGroove,
      // HOC
      resultSearch,
      onSearchNow,
      getInputSearchProps,
      handleOnSelectGroove
    } = this.props;
    return (
      <Modal
        visible={modalGroove && modalGroove.visible}
        onCancel={this.closeModalGroove}
        title="Ranuras"
        footer={null}
      >
        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={24}>
            {getInputSearchProps(
              "sRanura",
              listGroove,
              "Buscar Ranura",
              this.refSearch,
              "default"
            )}
          </Col>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Table
              dataSource={
                resultSearch.length > 0 || onSearchNow === true
                  ? resultSearch
                  : this.state.dataSource
              }
              title={() => (
                <span>
                  <i className="fad fa-mouse gx-pr-1 gx-text-color-primary" />
                  Doble click para seleccionar una Ranura.
                </span>
              )}
              loading={fetchingGroove}
              columns={columns}
              pagination={false}
              bordered={true}
              rowKey={row => row.sRanura}
              onRow={(row, index) => {
                return {
                  onDoubleClick: event => {
                    handleOnSelectGroove(row, index, modalGroove);
                  }
                };
              }}
              size={"middle"}
              scroll={{ y: "calc(100vh - 360px)" }}
              rowClassName="gx-pointer"
            />
          </Col>
        </Row>
      </Modal>
    );
  }
}

const mapStateToProps = ({ projects }) => {
  const { listGroove, fetchingGroove, modalGroove } = projects;
  return { listGroove, fetchingGroove, modalGroove };
};

const mapDispatchToProps = { toggleModalRanura };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSearchOnTable(Groove));
