import React from "react";
import { Button } from "antd";
import PropTypes from "prop-types";

export const FilterButton = ({
  type = "default",
  text,
  icon = "fa-filter",
  loading,
  htmlType,
  onClick
}) => {
  return (
    <Button
      type={type}
      htmlType={htmlType}
      onClick={onClick}
      disabled={loading}
      className="gx-mb-0 gx-border-primary"
    >
      <i className={`fal ${loading ? "fa-spinner" : icon} gx-pr-2`} />
      {text}
    </Button>
  );
};
FilterButton.propTypes = {
  type: PropTypes.string,
  text: PropTypes.any.isRequired,
  icon: PropTypes.string,
  htmlType: PropTypes.string,
  onClick: PropTypes.func
};
