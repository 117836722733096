import React, { useState, useEffect, Fragment } from "react";
import { Col, Row, Card, Button, Tag, Spin } from "antd";
import { Link, useHistory } from "react-router-dom";
import { useLazyQuery } from "@apollo/react-hooks";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import capitalize from "capitalize";
import './index.less';

import { Error404, Error500, spinner } from "../../../util/customComponents";
import {
  PROFILE_CARPENTER as WITH_CONTACT,
  PROFILE_CARPENTER_WITHOUT_CONTACT as WITHOUT_CONTACT,
} from "../../../graphql/query/user";

import Auxiliary from "util/Auxiliary";
import WidgetHeader from "../../../components/WidgetHeader";
// import CustomScrollbars from "util/CustomScrollbars";
import Widget from "../../../components/Widget";
import { cloudinarySecure } from "../../../util/config";
import { queriedTags } from "../../../appRedux/actions";
import Seo from "../../../components/seo";

const iconStyle = {
  width: 18,
};

const SpecialistProfile = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { authUser } = useSelector(({ auth }) => auth);

  const [parsedID, setParsedID] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [profile, setProfile] = useState(null);

  const {
    params: { id },
  } = props.match;

  useEffect(() => {
    try {
      setParsedID(atob(id));
    } catch (e) {
      setError(true);
      setLoading(false);
    }
  }, [id]);

  const [getProfile] = useLazyQuery(authUser ? WITH_CONTACT : WITHOUT_CONTACT, {
    onCompleted({ profileCarpenter }) {
      setProfile(profileCarpenter);
      setLoading(false);
    },
    onError() {
      setError(true);
    },
    fetchPolicy: "network-only",
  });

  const searchProjectsByTag = (tag) => {
    dispatch(queriedTags([tag]));
    history.push("/search");
  };

  useEffect(() => {
    if (parsedID) {
      getProfile({ variables: { id: parsedID } });
    }
  }, [parsedID, getProfile]);

  if (loading)
    return (
      <Spin className="gx-full-width" spinning={loading} indicator={spinner} />
    );
  if (!loading && error) return <Error500 />;
  if (!loading && !profile) return <Error404 />;

  const { publicProjects: projects } = profile;
  let totalProjects = 0;
  let totalLikes = 0;
  let totalVisited = 0;
  let tags = [];

  if (projects.length > 0) {
    projects.forEach((item) => {
      totalProjects++;
      totalLikes = parseInt(totalLikes) + parseInt(item.favorites);
      totalVisited = parseInt(totalVisited) + parseInt(item.visited);
      if (item.tags) {
        const projectTags = JSON.parse(item.tags);
        projectTags.forEach((itemTag) => {
          const filtered = tags.includes(itemTag);
          if (!filtered) {
            tags.push(itemTag);
          }
        });
      }
    });
  }

  return (
    <div className="gx-main-content gx-mt-4 gx-mb-4">
      <Seo
        title={`${profile.name} ${profile.last_name}`}
        url={window.location.href}
        description={profile.experience_resume ? profile.experience_resume : ""}
        keywords={tags && tags.toString()}
        image={profile.avatar && profile.avatar}
      />
      <div className="content-profile">
        <Row>
          <Col xl={6} lg={8} md={8} sm={10} xs={24}>
            <div>
              <Auxiliary>
                <div className="gx-profileon">
                  <div
                    className="gx-profileon-thumb gx-profileon-thumb-htctrcrop"
                    style={{ border: "1px solid #e8e8e8" }}
                  >
                    <img
                      src={
                        profile.avatar ||
                        "https://via.placeholder.com/500x500.jpg?text=No%20disponible"
                      }
                      alt={`${profile.name} ${profile.last_name}`}
                      style={{ height: "100%", objectFit: "scale-down" }}
                    />
                  </div>
                  <div
                    className="gx-profileon-content"
                    style={{ background: "rgba(0,0,0,.6)" }}
                  >
                    <p className="gx-profileon-title gx-mb-1 gx-mt-3 gx-fs-xl">
                      {`${profile.name} ${profile.last_name}`}
                    </p>
                    {profile.specialty ? (
                      <span className="gx-fs-md gx-text-muted gx-d-inline-flex gx-align-items-center">
                        <i className="far fa-user-tag gx-mr-2" />
                        {`${profile.specialty.parent
                          ? profile.specialty.parent.description
                          : ""
                          } - ${profile.specialty.description}`}
                      </span>
                    ) : (
                      <span className="gx-fs-md gx-text-muted gx-d-inline-flex gx-align-items-center">
                        <i className="far fa-user-tag gx-mr-2" />
                      Especialidad no especificada
                      </span>
                    )}
                  </div>
                </div>
                <div className="gx-follower gx-text-center">
                  <ul className="gx-follower-list">
                    <li>
                      <span className="gx-follower-title">{totalProjects}</span>
                      <span>Proyecto{totalProjects !== 1 && "s"}</span>
                    </li>
                    <li>
                      <span className="gx-follower-title">{totalVisited}</span>
                      <span>Visitas</span>
                    </li>
                    <li>
                      <span className="gx-follower-title">{totalLikes}</span>
                      <span>Me Gusta</span>
                    </li>
                  </ul>
                </div>
                <div className="gx-mb-3">
                  {profile.experience_resume && (
                    <p>{profile.experience_resume}</p>
                  )}
                  <p>
                    <i className="far fa-calendar-alt gx-mr-2" />
                  Años de Experiencia:{" "}
                    {profile.experience_years || (
                      <span className="gx-text-muted">Sin experiencia</span>
                    )}
                  </p>
                </div>
                <div className="gx-mb-xl-4 gx-mb-3">
                  <WidgetHeader title="Contacto" />
                  {authUser ? (
                    <Fragment>
                      <p className="gx-mb-1">
                        <i
                          className="far fa-envelope-open-text gx-mr-2"
                          style={iconStyle}
                        />
                      Email: {profile.email}
                      </p>
                      <p className="gx-mb-1">
                        <i
                          className="far fa-phone-office gx-mr-2"
                          style={iconStyle}
                        />
                      Teléfono:{" "}
                        {profile.phone || (
                          <span className="gx-text-muted">No especificado</span>
                        )}
                      </p>
                      <p className="gx-mb-1">
                        <i className="far fa-phone gx-mr-2" style={iconStyle} />
                      Celular:{" "}
                        {profile.cellphone || (
                          <span className="gx-text-muted">No especificado</span>
                        )}
                      </p>
                    </Fragment>
                  ) : (
                    <div className="gx-text-muted gx-mb-0">
                      Por favor inicie sesión o regístrate para ver la información
                      de contacto del especialista.
                      <div className="gx-mb-0 gx-mt-1 gx-d-inline-flex gx-full-width gx-align-items-center">
                        <Link
                          to={{
                            pathname: "/login",
                            redirect: `/perfil/especialista/${id}`,
                          }}
                        >
                          <Button
                            type="primary"
                            className="gx-mb-0 gx-mr-1"
                            size="small"
                          >
                            Iniciar sesión
                          <i className="fa fa-sign-in-alt gx-ml-2" />
                          </Button>
                        </Link>
                        <Link
                          to={{
                            pathname: "/register",
                            redirect: `/perfil/especialista/${id}`,
                          }}
                        >
                          <Button type="primary" className="gx-mb-0" size="small">
                            Registrarse
                          <i className="fa fa-user-plus gx-ml-2" />
                          </Button>
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
              </Auxiliary>
              <div className="gx-entry-sec">
                <WidgetHeader title="Etiquetas usadas" />
                {tags.map((item, key) => (
                  <Tag
                    key={key}
                    className="gx-pointer"
                    onClick={() => searchProjectsByTag(item)}
                  >
                    {item}
                  </Tag>
                ))}
              </div>
            </div>
          </Col>
          <Col xl={12} lg={8} md={16} sm={14} xs={24}>
            <div>
              <div className="gx-wall-postlist">
                {projects &&
                  projects.map((item, projectIndex) => {
                    return (
                      <Card className="gx-card" key={projectIndex}>
                        <div className="gx-wall-content">
                          <div className="gx-media gx-wall-user-info gx-flex-nowrap gx-align-items-center">
                            <div className="gx-media-body">
                              <h5 className="gx-wall-user-title gx-fs-lg gx-text-uppercase">
                                <Link to={`/proyecto/${btoa(item.id)}`}>
                                  {item.name}
                                </Link>
                              </h5>
                            </div>
                          </div>
                          <p className="gx-text-muted gx-fs-sm">
                            Publicado el:{" "}
                            {capitalize.words(
                              moment(item.created_at).format(
                                "dddd DD MMMM[,] YYYY"
                              )
                            )}
                          </p>
                          <p>{item.description}</p>
                          {item.materials && (
                            <div className="gx-mb-2">
                              {JSON.parse(item.materials).map(
                                (material, materialIndex) => (
                                  <Tag key={materialIndex}>{material}</Tag>
                                )
                              )}
                            </div>
                          )}
                          {item.gallery && (
                            <div className="gx-wall-medialist">
                              <div className="gx-gallery-grid gx-gallery-2">
                                {item.gallery.map((galleryItem, galleryIndex) => (
                                  <div
                                    className="gx-gallery-item"
                                    key={galleryIndex}
                                  >
                                    <Link to={`/proyecto/${btoa(item.id)}`}>
                                      <img
                                        style={{
                                          height: "100%",
                                          objectFit: "scale-down",
                                        }}
                                        className="gx-img-fluid"
                                        src={`${cloudinarySecure}/c_scale,q_auto:low,w_400,r_16/${galleryItem.url.split(
                                          cloudinarySecure
                                        )[1]
                                          }`}
                                        alt={item.name}
                                      />
                                    </Link>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                          <div className="gx-flex-row gx-mb-0">
                            <p className="gx-fs-sm gx-pointer gx-mb-0 gx-mr-3 gx-text-grey">
                              <i className="icon icon-view-o gx-fs-lg gx-mr-2 gx-d-inline-flex gx-vertical-align-middle" />
                              <span className="gx-d-inline-flex gx-vertical-align-middle">
                                {item.visited} Visitas
                            </span>
                            </p>
                            <p className="gx-fs-sm gx-pointer gx-mb-0 gx-mr-3 gx-text-grey">
                              <i className="icon icon-like-o gx-fs-lg gx-mr-2 gx-d-inline-flex gx-vertical-align-middle" />
                              <span className="gx-d-inline-flex gx-vertical-align-middle">
                                {item.favorites} Me gusta
                            </span>
                            </p>
                            <p className="gx-fs-sm gx-pointer gx-mb-0 gx-ml-auto gx-text-grey">
                              <Link to={`/proyecto/${btoa(item.id)}`}>
                                <Button size="small" className="gx-mb-0">
                                  Ver detalles
                                <i className="far fa-external-link gx-ml-2" />
                                </Button>
                              </Link>
                            </p>
                          </div>
                        </div>
                      </Card>
                    );
                  })}
              </div>
            </div>
          </Col>
          <Col xl={6} lg={8} md={6} sm={24} xs={24}>
            <div>
              <Widget styleName="gx-widget-bg">
                <span className="gx-widget-badge">ANUNCIO</span>
                <i className="far fa-tools gx-mb-4 gx-fs-xlxl" />

                <h1 className="gx-fs-xxxl gx-font-weight-semi-bold gx-mb-3 gx-mb-sm-4">
                  Ferretería Cobos
              </h1>
                <p>
                  <i className="far fa-map gx-mr-2" />
                Jr.Alegría 534 - San Martín
              </p>
                <p>
                  Obtenga los mejores materiales para la construcción de sus
                  proyectos.
              </p>
                <Button className="gx-mb-1 gx-btn-warning" htmlType="submit">
                  Visitar página
                <i className="far fa-external-link gx-ml-2" />
                </Button>
              </Widget>
              <img
                alt="Anuncio"
                src="https://via.placeholder.com/300x100.jpg?text=Anuncie%20aqu%C3%AD"
                className="gx-full-width"
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default SpecialistProfile;
