import React from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {Avatar, Badge, Card, message, Spin} from 'antd'
import Scrollbar from 'react-custom-scrollbars'
import {withRouter} from 'react-router-dom'
import {useMutation} from "@apollo/react-hooks"

import iconProject from "assets/images/options/projects.svg"
import iconProfile from "assets/images/options/profile.svg"
import iconOptimizer from "assets/images/options/optimizer.svg"
import iconClose from "assets/images/options/close.svg"

import {LOGOUT_MUTATION} from 'graphql/mutation/auth'
import {userSignOut } from "appRedux/actions/Auth"
import {spinner} from 'util/customComponents'

const OptionsPanel = ({history}) => {
  const dispatch = useDispatch()

  const [logout, {loading}] = useMutation(LOGOUT_MUTATION, {
    onCompleted: () => {
      localStorage.removeItem('token')
      localStorage.removeItem('optimizer_token')
      localStorage.removeItem('optimizer_id')
      localStorage.removeItem('skip_fill_info')
      localStorage.removeItem('skip-percent-panel')
      localStorage.removeItem('skip_wizard_forms')
      localStorage.removeItem("user")
      dispatch(userSignOut())
      message.success('Su sesión ha finalizado')
    },
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ extensions }) => {
          message.warn(extensions.reason)
        })
      }
      if (networkError) {
        message.error(`[Error de Red]: ${networkError}`)
      }
    }
  })

  const {authUser} = useSelector(({auth}) => auth)

  return (
    <Spin spinning={loading} indicator={spinner}>
      <div className="gx-mt-4">
        <div className="gx-d-flex gx-mx-4 gx-align-items-center">
          <Avatar src={authUser.avatar} size={50}/>
          <div className="gx-ml-2">
            <h4 className="gx-mb-0">{`${authUser.name} ${authUser.last_name}`}</h4>
            <p className="gx-mb-0 gx-text-muted">{authUser.email}</p>
          </div>
        </div>
        <h2 className="gx-mt-3 gx-mt-sm-4 gx-mx-4">Opciones</h2>
        <div className="gx-mx-4 gx-my-3">
          <Scrollbar
            autoHeight
            // autoHeightMin={0}
            autoHeightMin={'65vh'}
          >
          <Card className="gx-card-widget gx-card-full gx-dot-arrow-hover gx-mb-3" onClick={() => history.push('/usuario/perfil')}>
            <div className="gx-media gx-align-items-center gx-flex-nowrap">
              <div className="gx-px-3 gx-mr-2 gx-build-box-lay">
                <img alt="..." src={iconProfile} className=""/>
              </div>
              <div className="gx-media-body gx-py-3 gx-pr-4 gx-build-box-lay-content">
                <h2 className="h4 gx-text-truncate gx-mb-1">Mi perfil</h2>
                <p className="gx-mb-0 gx-text-grey gx-fs-sm">Gestiona tu información</p>
                <div className="gx-dot-arrow">
                  <div className="gx-bg-primary gx-hover-arrow">
                    <i className="icon icon-long-arrow-right gx-text-white"/>
                  </div>
                  <div className="gx-dot">
                    <i className="icon icon-ellipse-v gx-text-primary"/>
                  </div>
                </div>
              </div>
            </div>
          </Card>
          <Card className="gx-card-widget gx-card-full gx-dot-arrow-hover gx-mb-3" onClick={() => history.push('/usuario/proyectos')}>
            <div className="gx-media gx-align-items-center gx-flex-nowrap">
              <div className="gx-px-3 gx-mr-2 gx-build-box-lay">
                <img alt="..." src={iconProject} className=""/>
              </div>
              <div className="gx-media-body gx-py-3 gx-pr-4 gx-build-box-lay-content">
                <h2 className="h4 gx-text-truncate gx-mb-1">Proyectos</h2>
                <p className="gx-mb-0 gx-text-grey gx-fs-sm">Gestiona tu información</p>
                <div className="gx-dot-arrow">
                  <div className="gx-bg-primary gx-hover-arrow">
                    <i className="icon icon-long-arrow-right gx-text-white"/>
                  </div>
                  <div className="gx-dot">
                    <i className="icon icon-ellipse-v gx-text-primary"/>
                  </div>
                </div>
              </div>
            </div>
          </Card>
          <Card className="gx-card-widget gx-card-full gx-dot-arrow-hover gx-mb-3" onClick={() => history.push('/usuario/optimizador')}>
            <div className="gx-media gx-align-items-center gx-flex-nowrap">
              <div className="gx-px-3 gx-mr-2 gx-build-box-lay">
                <img alt="..." src={iconOptimizer} className=""/>
              </div>
              <div className="gx-media-body gx-py-3 gx-pr-4 gx-build-box-lay-content">
                <h2 className="h4 gx-text-truncate gx-mb-1">
                  Optimizador
                  <Badge className="gx-badge-radius-sm gx-mb-0 gx-ml-2" count="Nuevo" style={{backgroundColor: 'rgb(255, 85, 0)'}}/>
                </h2>
                <p className="gx-mb-0 gx-text-grey gx-fs-sm">Optimiza y agiliza tus proyectos</p>
                <div className="gx-dot-arrow">
                  <div className="gx-bg-primary gx-hover-arrow">
                    <i className="icon icon-long-arrow-right gx-text-white"/>
                  </div>
                  <div className="gx-dot">
                    <i className="icon icon-ellipse-v gx-text-primary"/>
                  </div>
                </div>
              </div>
            </div>
          </Card>
          <Card className="gx-card-widget gx-card-full gx-dot-arrow-hover" onClick={() => logout()}>
            <div className="gx-media gx-align-items-center gx-flex-nowrap">
              <div className="gx-px-3 gx-mr-2 gx-build-box-lay">
                <img alt="..." src={iconClose} className=""/>
              </div>
              <div className="gx-media-body gx-py-3 gx-pr-4 gx-build-box-lay-content">
                <h2 className="h4 gx-text-truncate gx-mb-1">Cerrar sesión</h2>
                <p className="gx-mb-0 gx-text-grey gx-fs-sm">Esperamos tu pronto regreso...</p>
                <div className="gx-dot-arrow">
                  <div className="gx-bg-primary gx-hover-arrow">
                    <i className="icon icon-long-arrow-right gx-text-white"/>
                  </div>
                  <div className="gx-dot">
                    <i className="icon icon-ellipse-v gx-text-primary"/>
                  </div>
                </div>
              </div>
            </div>
          </Card>
          </Scrollbar>
        </div>
      </div>
    </Spin>
  )
}

export default withRouter(OptionsPanel)
