import { TOGGLE_MODAL_UPDATE_PASSWORD } from "constants/ActionTypes";

const initialState = {
  togglePassword: {
    info: null,
    visible: false,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case TOGGLE_MODAL_UPDATE_PASSWORD:
      return {
        ...state,
        togglePassword: {
          info: payload.info,
          visible: payload.visible,
        },
      };

    default:
      return state;
  }
};
