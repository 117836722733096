export const schema = {
  sTipoDocumento: {
    rule: {
      required: true,
      message: "Campo requerido"
    },
    label: "Tipo Documento",
    name: "sTipoDocumento",
    type: "text",
    placeHolder: ""
  },
  sDocumento: {
    rule: {
      required: true,
      message: "Campo requerido"
    },
    label: "Documento",
    name: "sDocumento",
    type: "text",
    placeHolder: ""
  },
  sNombres: {
    rule: {
      required: true,
      message: "Campo requerido"
    },
    label: "Razón social/Nombres",
    name: "sNombres",
    type: "text",
    placeHolder: ""
  },
  sApellidoPaterno: {
    rule: {
      required: true,
      message: "Campo requerido"
    },
    label: "Apellido Paterno",
    name: "sApellidoPaterno",
    type: "text",
    placeHolder: ""
  },
  sApellidoMaterno: {
    rule: {
      required: true,
      message: "Campo requerido"
    },
    label: "Apellido Materno",
    name: "sApellidoMaterno",
    type: "text",
    placeHolder: ""
  },
  dFechaNacimiento: {
    rule: {
      required: false,
      message: "Campo requerido"
    },
    label: "Fecha Nacimiento",
    name: "dFechaNacimiento",
    type: "datePicker",
    format: "DD/MM/YYYY",
    placeHolder: "DD/MM/YYYY",
    width: "100%"
  },

  sGenero: {
    rule: {
      required: false,
      message: "Campo requerido"
    },
    label: "Genero",
    name: "sGenero",
    type: "radio",
    placeHolder: ""
  },
  sProfesion: {
    rule: {
      required: false,
      message: "Campo requerido"
    },
    label: "Profesion",
    name: "sProfesion",
    type: "text",
    placeHolder: ""
  },
  sTelefono: {
    rule: {
      required: true,
      message: "Campo requerido"
    },
    label: "Telefono",
    name: "sTelefono",
    type: "text",
    placeHolder: ""
  },
  sCorreo: {
    rule: {
      required: false,
      message: "Campo requerido"
    },
    label: "Correo",
    name: "sCorreo",
    type: "email",
    placeHolder: ""
  },
  sDireccion: {
    rule: {
      required: false,
      message: "Campo requerido"
    },
    label: "Dirección",
    name: "sDireccion",
    type: "text",
    placeHolder: ""
  },
  sDistrito: {
    rule: {
      required: false,
      message: "Campo requerido"
    },
    label: "Distrito",
    name: "sDistrito",
    type: "autoComplete",
    placeHolder: "Buscar Distrito"
  },
  //Datos Contacto
  sNombresContacto: {
    rule: {
      required: true,
      message: "Campo requerido"
    },
    label: "Nombres",
    name: "sNombresContacto",
    type: "text",
    placeHolder: ""
  },
  sCargoContacto: {
    rule: {
      required: true,
      message: "Campo requerido"
    },
    label: "Cargo",
    name: "sCargoContacto",
    type: "text",
    placeHolder: ""
  },
  sTelefonoContacto: {
    rule: {
      required: true,
      message: "Campo requerido"
    },
    label: "Teléfono",
    name: "sTelefonoContacto",
    type: "text",
    placeHolder: ""
  },
  sTelefonoContacto2: {
    rule: {
      required: true,
      message: "Campo requerido"
    },
    label: "Teléfono 2",
    name: "sTelefonoContacto2",
    type: "text",
    placeHolder: ""
  },
  sCorreoContacto: {
    rule: {
      required: true,
      message: "Campo requerido"
    },
    label: "Correo",
    name: "sCorreoContacto",
    type: "email",
    placeHolder: ""
  },

  //Datos Dirección

  nIdAddress: {
    rule: {
      required: true,
      message: "Campo requerido"
    },
    label: "",
    name: "nIdAddress",
    type: "text",
    placeHolder: ""
  },
  nIdClienteAddress: {
    rule: {
      required: true,
      message: "Campo requerido"
    },
    label: "",
    name: "nIdClienteAddress",
    type: "text",
    placeHolder: ""
  },
  sDireccionAddress: {
    rule: {
      required: true,
      message: "Campo requerido"
    },
    label: "Dirección",
    name: "sDireccionAddress",
    type: "text",
    placeHolder: ""
  },
  nUbigeoAddress: {
    rule: {
      required: true,
      message: "Campo requerido"
    },
    label: "Ubigeo",
    name: "nUbigeoAddress",
    type: "text",
    placeHolder: ""
  },
  sObservacionesAddress: {
    rule: {
      required: true,
      message: "Campo requerido"
    },
    label: "Observaciones",
    name: "sObservacionesAddress",
    type: "text",
    placeHolder: ""
  },
  sUserAddress: {
    rule: {
      required: true,
      message: "Campo requerido"
    },
    label: "",
    name: "sUserAddress",
    type: "text",
    placeHolder: ""
  },
  nIdClienteDireccionAddress: {
    rule: {
      required: true,
      message: "Campo requerido"
    },
    label: "",
    name: "nIdClienteDireccionAddress",
    type: "text",
    placeHolder: ""
  },
  // credito de clientes
  nIdCondPago: {
    name: "nIdCondPago",
    label: "Condición pago",
    rule: {
      required: true,
      message: "Campo requerido"
    }
  },
  nIdVendedor: {
    name: "nIdVendedor",
    label: "Vendedor",
    rule: {
      required: true,
      message: "Campo requerido"
    }
  },
  nLineaCredito: {
    name: "nLineaCredito",
    label: "Línia de crédito",
    rule: {
      required: true,
      message: "Campo requerido"
    }
  },
  nIdListaPrecio: {
    name: "nIdListaPrecio",
    label: "Lista de precio",
    rule: {
      required: true,
      message: "Campo requerido"
    }
  },
  bMoroso: {
    name: "bMoroso",
    label: "Moroso",
    rule: {
      required: true,
      message: "Campo requerido"
    }
  },
  nIdClaseCredito: {
    name: "nIdClaseCredito",
    label: "Clase de crédito",
    rule: {
      required: true,
      message: "Campo requerido"
    }
  }
};
