import React, { useState, useEffect, Fragment } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Card,
  Spin,
  message,
  Alert,
  Icon,
  Row,
  Col,
} from "antd";
import { useMutation } from "@apollo/react-hooks";
import { encrypt, decrypt } from "node-cryptex";
import api from "../../util/Api";

import { userSignInSuccess } from "../../appRedux/actions";
import {
  LOGIN_MUTATION,
  RESEND_VERIFY_EMAIL,
} from "../../graphql/mutation/auth";
import { spinner } from "../../util/customComponents";
import { k, v } from "../../util/config";
import Seo from "../../components/seo";
import "./index.less";
import Register from "../register";
// import {onErrorMessage} from "util/helpers";

const wrapperStyle = {
  minWidth: "320px",
  maxWidth: "500px",
  width: "100%",
  borderRadius: "6px",
  margin: "20px auto 20px",
};

const Login = (props) => {
  const { form } = props;
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [user, setUser] = useState(null);
  const [credentials, setCredentials] = useState(null);
  const [unverifiedAccount, setUnverifiedAccount] = useState(false);
  const [sentEmail, setSentEmail] = useState(false);
  const [loading, setLoading] = useState(false);
  const { getFieldDecorator, validateFields, getFieldValue } = form;

  const [login] = useMutation(LOGIN_MUTATION, {
    async onCompleted({ login }) {
      const remember = getFieldValue("remember");
      const { user, access_token } = login;
      setUser(user);
      if (user.email_verified_at) {
        localStorage.setItem("token", access_token);
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("skip_fill_info", "no-skip");
        if (remember) {
          const email = getFieldValue("email");
          const password = getFieldValue("password");
          const credentials = { email, password, remember };
          const encryptedCredentials = encrypt(
            JSON.stringify(credentials),
            k,
            v
          );
          localStorage.setItem("_credentials", encryptedCredentials);
        }
        const { user_type, wizard_panel } = user;
        if (user.optimizer && user.uuid) {
          const info = new FormData();
          info.set("uuid", user.uuid);
          api
            .post("/system/create-optimizer-token", info)
            .then(({ data }) => {
              localStorage.setItem("optimizer_token", data.token);
              localStorage.setItem("optimizer_id", data.nIdUsuario);
              api.defaults.headers.common["Authorization"] = `Bearer ${decrypt(
                data.token,
                k,
                v
              )}`;
              dispatch(
                userSignInSuccess(
                  user,
                  access_token,
                  data.token || null,
                  data.nIdUsuario || null
                )
              );
              setLoading(false);
              if (location) {
                let link = "";
                if (location.state) {
                  if (location.state.from) {
                    const from = location.state.from;
                    link = from.pathname + from.search;
                  }
                } else {
                  if (user_type === "CARPINTERO") {
                    if (wizard_panel) {
                      link = "/usuario/joined";
                    }
                  } else {
                    link = "/";
                  }
                }
                if (location.redirect) {
                  link = location.redirect;
                }
                history.push(link);
              }
            })
            .catch(() => {
              message.error(
                "El servicio del optimizador no se encuentra disponible"
              );
              setLoading(false);
              dispatch(userSignInSuccess(user, access_token, null, null));
            });
        } else {
          dispatch(userSignInSuccess(user, access_token, null, null));
          localStorage.setItem("display_tour_optimizer", false);
          setLoading(false);
        }
      } else {
        setUnverifiedAccount(true);
        setLoading(false);
      }
    },
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ extensions }) => {
          message.warn("No existe una cuenta con estas credenciales");
        });
      }
      if (networkError) {
        message.error(`[Error de Red]: ${networkError}`);
      }
      setLoading(false);
    },
  });

  const [resendEmail, { loading: sending }] = useMutation(RESEND_VERIFY_EMAIL, {
    onCompleted({ resendVerifyEmail }) {
      message.success(resendVerifyEmail.message);
      setSentEmail(true);
    },
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ extensions }) => {
          message.warn(extensions.reason);
        });
      }
      if (networkError) {
        message.error(`[Error de Red]: ${networkError}`);
      }
    },
  });

  const submit = (e) => {
    e.preventDefault();
    validateFields((errors, values) => {
      if (!errors) {
        setLoading(true);
        const variables = {
          input: {
            username: values.email,
            password: values.password,
          },
        };
        login({ variables });
      }
    });
  };

  const removeSavedCredentials = () => {
    localStorage.removeItem("_credentials");
  };

  const sendVerifyEmail = () => {
    const variables = {
      email: user.email,
    };
    resendEmail({ variables });
  };

  const resetLoginPage = () => {
    if (localStorage.getItem("_credentials")) {
      const decryptedCredentials = JSON.parse(
        decrypt(localStorage.getItem("_credentials"), k, v)
      );
      setCredentials(decryptedCredentials);
    } else {
      setCredentials(null);
    }
    setUser(null);
    setUnverifiedAccount(false);
    setSentEmail(false);
  };

  useEffect(() => {
    if (!credentials) {
      if (localStorage.getItem("_credentials")) {
        const decryptedCredentials = JSON.parse(
          decrypt(localStorage.getItem("_credentials"), k, v)
        );
        setCredentials(decryptedCredentials);
      }
    }
  }, [credentials, setCredentials]);

  return (
    <div style={{ position: "relative", minHeight: "100%", width: "100%" }}>
      <Seo
        title="Iniciar Sesión 🔐"
        url={window.location.href}
        description={`Ingrese sus credenciales de acceso`}
        keywords="iniciar sesión,carpintero,eCarpintero"
      />
      <div className="banner-micuenta">
        <span className="titulo-micuenta">Mi cuenta</span>
      </div>
      <div>
        <Row>
          <Col
            xxl={12}
            lg={12}
            md={12}
            sm={24}
            xs={24}
            style={{ marginTop: "18px" }}
          >
            <Card style={wrapperStyle} className="gx-align-self-start gx-mt-0">
              <Spin spinning={loading || sending} indicator={spinner}>
                {
                  !unverifiedAccount ? (
                    <Fragment>
                      <h1
                        className="gx-mb-1 gx-font-weight-bold"
                        style={{ color: "#000000", fontFamily: "Open Sans" }}
                      >
                        Iniciar Sesión
                      </h1>
                      <p
                        className="gx-mb-4 gx-font-weight-bold"
                        style={{ color: "#000000", fontFamily: "Open Sans" }}
                      >
                        Ingrese sus credenciales de acceso
                      </p>
                      <Form
                        onSubmit={submit}
                        layout="vertical"
                        hideRequiredMark
                      >
                        <Form.Item>
                          {getFieldDecorator("email", {
                            initialValue: credentials
                              ? credentials.email
                              : null,
                            rules: [
                              {
                                required: true,
                                message: "El correo electrónico es requerido",
                              },
                              {
                                type: "email",
                                message: "Ingrese un email válido",
                              },
                            ],
                          })(
                            <Input
                              autoFocus
                              type="email"
                              prefix={
                                <i
                                  className="fal fa-envelope"
                                  style={{ color: "rgba(0,0,0,.25)" }}
                                />
                              }
                              placeholder="Correo"
                            />
                          )}
                        </Form.Item>
                        <Form.Item>
                          {getFieldDecorator("password", {
                            initialValue: credentials
                              ? credentials.password
                              : null,
                            rules: [
                              {
                                required: true,
                                message: "Por favor ingresa tu contraseña",
                              },
                            ],
                          })(
                            <Input.Password
                              prefix={
                                <i
                                  className="fal fa-lock-alt"
                                  style={{ color: "rgba(0,0,0,.25)" }}
                                />
                              }
                              type="password"
                              placeholder="Contraseña"
                            />
                          )}
                        </Form.Item>
                        <Form.Item className="gx-text-left">
                          {getFieldDecorator("remember", {
                            valuePropName: "checked",
                            initialValue: credentials
                              ? credentials.remember
                              : false,
                          })(
                            <Checkbox
                              onChange={removeSavedCredentials}
                              className="gx-container-checked-description"
                            >
                              <span
                                className="gx-fs-sm"
                                style={{
                                  color: "#000000",
                                  fontFamily: "Open Sans",
                                }}
                              >
                                Recordar credenciales
                              </span>
                            </Checkbox>
                          )}
                        </Form.Item>
                        <Form.Item className="gx-mb-0">
                          <Button
                            type="primary"
                            htmlType="submit"
                            className="login-form-button gx-ml-0"
                            block
                            disabled={loading}
                          >
                            INICIAR SESIÓN
                          </Button>
                        </Form.Item>
                        <div className="gx-d-block gx-text-center">
                          <Link to="/forgot-password" className="gx-fs-sm">
                            Olvidé mi contaseña
                          </Link>
                        </div>
                      </Form>
                    </Fragment>
                  ) : !sentEmail ? (
                    <Fragment>
                      <h1 className="gx-mb-1">
                        Hola, {user && user.name.split(" ")[0]}.
                      </h1>
                      <p className="gx-mb-3 gx-mt-3">
                        Tu cuenta no se encuentra activa porque aún no has
                        verificado la autenticidad, por favor revisa la bandeja
                        de entrada o spam de tu correo electrónico e ingresa al
                        enlace enviado.
                        <br />
                        Si no recibió el correo puede enviarlo nuevamente.
                      </p>
                      <Alert
                        type="info"
                        message={null}
                        description={`Correo Electrónico: ${user.email}`}
                        banner
                        icon={<Icon type="mail" />}
                      />
                      <Button
                        className="gx-mb-2"
                        block
                        type="primary"
                        onClick={sendVerifyEmail}
                        disabled={sending}
                      >
                        Enviar correo de activación
                      </Button>
                      <Button
                        className="gx-mb-0"
                        block
                        disabled={sending}
                        onClick={resetLoginPage}
                      >
                        Cancelar
                      </Button>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <h1 className="gx-mb-1">
                        Genial, {user.name.split(" ")[0]}.
                      </h1>
                      <p className="gx-mb-3 gx-mt-3">
                        Hemos enviado el enlace de verificación a tu corre
                        electrónico, por favor revise y verifique su cuenta.
                      </p>
                      <Alert
                        type="info"
                        message={null}
                        description={`Correo Electrónico: ${user.email}`}
                        banner
                        icon={<Icon type="mail" />}
                      />
                      <Button
                        className="gx-mb-0"
                        block
                        onClick={resetLoginPage}
                      >
                        Continuar
                      </Button>
                    </Fragment>
                  )
                  // >>>>>>> master
                }
              </Spin>
            </Card>
          </Col>
          <Col xxl={12} lg={12} md={12} sm={24} xs={24}>
            <Register />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Form.create()(Login);
