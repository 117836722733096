import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  Alert,
  Button,
  Result,
  Checkbox,
  Col,
  Form,
  Input,
  List,
  Row,
  message,
  Spin,
  Upload,
  Modal,
} from "antd";
import { geolocated } from "react-geolocated";

import CustomStep from "./CustomStep";
import { Link } from "react-router-dom";

import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { spinner } from "../../util/customComponents";
import { numbersPhoneOnly } from "../../util/helpers";
import { CREATE_SERVICE } from "../../graphql/mutation/service";
import GeolocationExample from "./GeoLocation";
import { useSelector, useDispatch } from "react-redux";
import {
  cloudinaryEndPoint,
  cloudinaryUploadPreset,
  defaultUser,
} from "../../util/config";
import axios from "axios";
import { WIZARD_SPECIALIST } from "../../graphql/query/user";
import Auxiliary from "../../util/Auxiliary";
import WidgetHeader from "../../components/WidgetHeader";
import { UPDATE_USER_INFO_WIZARD } from "../../graphql/mutation/user";
import { updateUserInfoWizard } from "../../appRedux/actions";
import Seo from "../../components/seo";
import './index.less'

const WizardSpecialist = (props) => {
  const dispatch = useDispatch();

  const { form, isGeolocationAvailable, isGeolocationEnabled, coords } = props;
  const { getFieldDecorator, validateFields } = form;
  const { authUser } = useSelector(({ auth }) => auth);

  const _otherWorkEnv = useRef();
  const _otherMaterial = useRef();

  const [step, setStep] = useState(1);
  const [requirement, setRequirement] = useState(null);
  const [workEnvironment, setWorkEnvironment] = useState([]);
  const [otherWorkEnvironment, setOtherWorkEnvironment] = useState({
    checked: false,
    value: null,
  });
  const [material, setMaterial] = useState([]);
  const [otherMaterial, setOtherMaterial] = useState({
    checked: false,
    value: null,
  });
  const [extraInformation, setExtraInformation] = useState(undefined);
  const [preview, setPreview] = useState(null);
  const [fullName, setFullName] = useState(undefined);
  const [phone, setPhone] = useState(undefined);
  const [email, setEmail] = useState(undefined);
  const [referenceAddress, setReferenceAddress] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);

  const [loading, setLoading] = useState(false);
  const [searching, setSearching] = useState(false);
  const [carpenters, setCarpenters] = useState(null);
  const [updatingInfo, setUpdatingInfo] = useState(false);

  const [first] = useState(5);
  const [page] = useState(1);
  // const [first, setFirst] = useState(5);
  // const [page, setPage] = useState(1);

  useEffect(() => {
    if (authUser) {
      setFullName(`${authUser.name} ${authUser.last_name}`);
      setPhone(authUser.cellphone || authUser.phone || null);
      setEmail(authUser.email);
      setReferenceAddress(authUser.reference_address);
    }
  }, [authUser]);

  const searchAgain = () => {
    setStep(1);
    setRequirement(null);
    setWorkEnvironment([]);
    setOtherWorkEnvironment({ checked: false, value: null });
    setMaterial([]);
    setOtherMaterial({ checked: false, value: null });
    setExtraInformation(undefined);
    if (authUser) {
      setFullName(`${authUser.name} ${authUser.last_name}`);
      setPhone(authUser.cellphone || authUser.phone || null);
      setEmail(authUser.email);
      setReferenceAddress(authUser.reference_address);
    } else {
      setFullName(undefined);
      setEmail(undefined);
      setPhone(undefined);
      setReferenceAddress(undefined);
    }
    setCarpenters(null);
  };

  const changeWorkEnv = (value) => {
    if (value === "Otros") {
      setOtherWorkEnvironment({
        ...otherWorkEnvironment,
        checked: true,
      });
      setWorkEnvironment([]);
      setTimeout(() => _otherWorkEnv.current.focus(), 100);
    } else {
      setOtherWorkEnvironment({ checked: false, value: null });
      if (workEnvironment.includes(value)) {
        const filtered = workEnvironment.filter(
          (environment) => environment !== value
        );
        setWorkEnvironment(filtered);
      } else {
        const newWorkEnvironment = workEnvironment.concat(value);
        setWorkEnvironment(newWorkEnvironment);
      }
    }
  };

  const changeMaterial = (value) => {
    if (value === "Otros") {
      setOtherMaterial({
        ...otherMaterial,
        checked: true,
      });
      setMaterial([]);
      setTimeout(() => _otherMaterial.current.focus(), 100);
    } else if (value === "Por definir") {
      setMaterial([value]);
      setOtherMaterial({
        checked: false,
        value: null,
      });
    } else {
      setOtherMaterial({ checked: false, value: null });
      if (material.includes(value)) {
        const filtered = material.filter((item) => item !== value);
        setMaterial(filtered);
      } else {
        const newMaterial = material.concat(value);
        setMaterial(newMaterial);
      }
    }
  };

  const [mutationUpdateInfoUserWizard] = useMutation(UPDATE_USER_INFO_WIZARD, {
    onCompleted({ updateUserWizard }) {
      setUpdatingInfo(false);
      const info = {
        reference_address: updateUserWizard.reference_address,
        cellphone: updateUserWizard.cellphone,
        lat: updateUserWizard.lat,
        lng: updateUserWizard.lng,
      };
      dispatch(updateUserInfoWizard(info));
      message.success("Se actualizó correctamente tu información");
    },
    onError() {
      setUpdatingInfo(false);
      message.error("Ha ocurrido un error al actualizar tu información");
    },
  });

  const [handleCreateService] = useMutation(CREATE_SERVICE, {
    onCompleted({ createService }) {
      setLoading(false);
      if (createService.status) {
        setStep(7);
        if (authUser) {
          const modal = Modal.confirm({
            title: `¿Deseas actualizar tu información?`,
            content: `Si acepta solo actualizaremos tu ubicación actual, referencia y teléfono.`,
            okText: "Aceptar",
            cancelText: "Cancelar",
            okButtonProps: {
              loading: updatingInfo,
              disabled: updatingInfo,
            },
            centered: true,
            onOk: async () => {
              setUpdatingInfo(true);
              modal.update({
                okButtonProps: {
                  loading: true,
                  disabled: true,
                },
                cancelButtonProps: {
                  disabled: true,
                },
              });
              const input = {
                reference_address: referenceAddress,
                lat: latitude,
                lng: longitude,
                cellphone: phone,
              };
              if (referenceAddress) {
                input["reference_address"] = referenceAddress;
              }
              await mutationUpdateInfoUserWizard({
                variables: {
                  id: authUser.id,
                  input,
                },
              })
                .then(() => {
                  modal.destroy();
                })
                .catch(() => {
                  modal.destroy();
                });
            },
            onCancel: () => {
              setStep(7);
            },
          });
        }
      }
    },
    onError() {
      message.error("Ha ocurrido un error al registrar la solicitud servicio");
      setLoading(false);
    },
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    validateFields(async (err) => {
      if (!err) {
        setLoading(true);
        let receivers = [];
        let specialists = [];
        if (carpenters.length > 0) {
          carpenters.forEach((user) => {
            receivers.push(user.email);
            specialists.push({
              name: `${user.name} ${user.last_name}`,
              email: user.email,
              cellphone: user.cellphone,
              phone: user.phone,
            });
          });
        }
        let variables = {
          input: {
            requirement: requirement,
            work_environment: JSON.stringify(workEnvironment),
            material: JSON.stringify(material),
            extra_information: extraInformation,
            full_name: fullName,
            phone: phone,
            email: email,
            reference_address: referenceAddress || null,
            receivers: JSON.stringify(receivers),
            specialists: JSON.stringify(specialists),
            lat: latitude,
            lng: longitude,
          },
        };
        if (otherWorkEnvironment)
          variables.input["other_work_environment"] =
            otherWorkEnvironment.value;
        if (otherMaterial)
          variables.input["other_material"] = otherMaterial.value;
        if (preview) {
          const headers = { "Content-Type": "multipart/form-data" };
          const info = new FormData();
          info.append("file", preview);
          info.append("upload_preset", cloudinaryUploadPreset);
          await axios
            .post(cloudinaryEndPoint, info, { headers })
            .then(async (res) => {
              variables.input["preview"] = res.data.secure_url;
              await handleCreateService({ variables });
            })
            .catch(() => {
              message.warning("Error al subir foto");
              setLoading(false);
            });
        } else {
          await handleCreateService({ variables });
        }
      }
    });
  };

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const handleChangeFile = async (info) => {
    if (info.file.originFileObj) {
      const base64 = await getBase64(info.file.originFileObj);
      setPreview(base64);
    }
  };

  const beforeUpload = (file) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg";
    if (!isJpgOrPng) {
      message.warn("Solo puedes subir archivos tipo JPG/PNG/JPEG!");
    }
    const isLt2M = file.size / 1024 / 1024 <= 2;
    if (!isLt2M) {
      message.warn("El archivo debe pesar máximo 4MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const upload = {
    showUploadList: false,
    customRequest: ({ file, onSuccess }) => onSuccess("ok"),
    onChange: handleChangeFile,
    beforeUpload: beforeUpload,
    name: "file",
    accept: ".png,.jpg,.jpeg",
    multiple: false,
    listType: "text",
  };

  const [queryWizardCarpenter] = useLazyQuery(WIZARD_SPECIALIST, {
    fetchPolicy: "network-only",
    onCompleted({ wizardCarpenter }) {
      setCarpenters(wizardCarpenter.data);
      if (wizardCarpenter.data.length > 0) {
        setStep(4);
      }
      setSearching(false);
    },
    onError() {
      message.error("Ha ocurrido un error al consultar");
    },
  });

  const handleSearchSpecialist = async () => {
    setSearching(true);
    const lat = coords.latitude;
    const lng = coords.longitude;

    const radius = 6378;
    const pi = Math.PI;
    const km = 30;

    const north = lat + (km / radius) * (180 / pi);
    const south = lat - (km / radius) * (180 / pi);
    const east =
      lng + ((km / radius) * (180 / pi)) / Math.cos((lat * pi) / 180);
    const west =
      lng - ((km / radius) * (180 / pi)) / Math.cos((lat * pi) / 180);

    let query = "";

    if (requirement) query += requirement;
    if (otherWorkEnvironment.checked) query += ` ${otherWorkEnvironment.value}`;
    if (otherMaterial.checked) query += ` ${otherMaterial.value}`;
    if (workEnvironment.length > 0)
      workEnvironment.map((item) => (query += ` ${item}`));
    if (material.length > 0) material.map((item) => (query += ` ${item}`));

    const variables = { east, west, north, south, query, first, page };
    await queryWizardCarpenter({ variables });
  };
  /*const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  };*/
  const [bannerTitle, setBannerTitle] = useState("busqueda");
  return (
    <Fragment>
      <Seo
        title="Contrata al profesional que más te guste."
        url={window.location.href}
        description={`Dinos qué necesitas para que podamos ofrecerte los profesionales adecuados.`}
        keywords="carpintero, especialista, melamina"
      />
      <div className="container-busqueda">
        <div className={`${bannerTitle === "busqueda" ? "banner-especialista" : "banner-especialista-encontrado "}`}>
          <span className="tit-buscar-especialista">Consigue un especialista</span>
        </div>
        <div className="content-preguntas">
          {!isGeolocationAvailable ? (
            <div>Your browser does not support Geolocation</div>
          ) : !isGeolocationEnabled ? (
            <Alert
              showIcon
              message={<strong>¡Advertencia!</strong>}
              type="warning"
              description={
                <div className="gx-mb-0">
                  Para obtener mejores resultados solicitamos el permiso
                  para acceder a tu ubicación actual.
            </div>
              }
            />
          ) : coords ? (
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={16}>
                {step !== 6 && (
                  <Alert
                    className="gx-mt-4 gx-mb-2 gx-text-black gx-font-weight-normal"
                    message={<strong className="gx-text-black gx-font-weight-bold">Búsqueda de Especialista</strong>}
                    description="Te haremos algunas preguntas para contactarte con el especialista adecuado"
                    showIcon
                    closable
                  />
                )}
                {step === 1 && (
                  <CustomStep
                    question={<p className="gx-text-black gx-font-weight-bold">¿Qué tipo de requerimiento buscas?</p>}
                    children={
                      <Fragment>
                        <List bordered>
                          <List.Item>
                            <Checkbox
                              className="gx-text-black gx-font-weight-normal"
                              onClick={() => setRequirement("Nuevo Proyecto")}
                              checked={requirement === "Nuevo Proyecto"}
                            >
                              Nuevo Proyecto
                        </Checkbox>
                          </List.Item>
                          <List.Item>
                            <Checkbox
                              className="gx-text-black gx-font-weight-normal"
                              onClick={() => setRequirement("Remodelación")}
                              checked={requirement === "Remodelación"}
                            >
                              Remodelación
                        </Checkbox>
                          </List.Item>
                          <List.Item>
                            <Checkbox
                              className="gx-text-black gx-font-weight-normal"
                              onClick={() => setRequirement("Reparación")}
                              checked={requirement === "Reparación"}
                            >
                              Reparación
                        </Checkbox>
                          </List.Item>
                        </List>
                      </Fragment>
                    }
                    actions={
                      <Button type="primary" className="gx-text-white gx-border-primary gx-font-weight-bold"
                        onClick={() => setStep(2)} disabled={!requirement}>
                        Siguiente
                    <i className="far fa-arrow-right gx-ml-2" />
                      </Button>
                    }
                  />
                )}
                {step === 2 && (
                  <CustomStep
                    question={<p className="gx-text-black gx-font-weight-bold">Ambiente de Trabajo</p>}
                    children={
                      <Fragment>
                        <List bordered>
                          <List.Item>
                            <Checkbox
                              className="gx-text-black gx-font-weight-normal"
                              onClick={() => changeWorkEnv("Oficina")}
                              checked={workEnvironment.includes("Oficina")}
                            >
                              Oficina
                        </Checkbox>
                          </List.Item>
                          <List.Item>
                            <Checkbox
                              className="gx-text-black gx-font-weight-normal"
                              onClick={() => changeWorkEnv("Sala")}
                              checked={workEnvironment.includes("Sala")}
                            >
                              Sala
                        </Checkbox>
                          </List.Item>
                          <List.Item>
                            <Checkbox
                              className="gx-text-black gx-font-weight-normal"
                              onClick={() => changeWorkEnv("Cocina")}
                              checked={workEnvironment.includes("Cocina")}
                            >
                              Cocina
                        </Checkbox>
                          </List.Item>
                          <List.Item>
                            <Checkbox
                              className="gx-text-black gx-font-weight-normal"
                              onClick={() => changeWorkEnv("Dormitorio")}
                              checked={workEnvironment.includes("Dormitorio")}
                            >
                              Dormitorio
                        </Checkbox>
                          </List.Item>
                          <List.Item>
                            <Checkbox
                              className="gx-text-black gx-font-weight-normal"
                              onClick={() => changeWorkEnv("Terraza")}
                              checked={workEnvironment.includes("Terraza")}
                            >
                              Terraza
                        </Checkbox>
                          </List.Item>
                          <List.Item>
                            <Checkbox
                              className="gx-text-black gx-font-weight-normal"
                              onClick={() => changeWorkEnv("Lavandería")}
                              checked={workEnvironment.includes("Lavandería")}
                            >
                              Lavandería
                        </Checkbox>
                          </List.Item>
                          <List.Item>
                            <Checkbox
                              className="gx-text-black gx-font-weight-normal"
                              onClick={() => changeWorkEnv("Local Comercial")}
                              checked={workEnvironment.includes("Local Comercial")}
                            >
                              Local Comercial
                        </Checkbox>
                          </List.Item>
                          <List.Item>
                            <div className="gx-full-width">
                              <Checkbox
                                className="gx-text-black gx-font-weight-normal"
                                onClick={() => changeWorkEnv("Otros")}
                                checked={otherWorkEnvironment.checked}
                              >
                                Otros
                          </Checkbox>
                              <Input
                                ref={_otherWorkEnv}
                                disabled={!otherWorkEnvironment.checked}
                                value={otherWorkEnvironment.value}
                                className="gx-mt-2"
                                placeholder="Ingrese ambiente de trabajo"
                                onChange={(e) =>
                                  setOtherWorkEnvironment({
                                    checked: true,
                                    value: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </List.Item>
                        </List>
                      </Fragment>
                    }
                    actions={
                      <Fragment>
                        <Button
                          type="primary"
                          className="gx-text-white gx-font-weight-bold"
                          onClick={() => setStep(1)}>
                          <i className="far fa-arrow-left gx-mr-2" />
                      Anterior
                    </Button>
                        <Button
                          className="gx-text-primary gx-border-primary gx-font-weight-bold"
                          onClick={() => setStep(3)}
                          disabled={
                            (!otherWorkEnvironment.checked &&
                              workEnvironment.length === 0) ||
                            (otherWorkEnvironment.checked
                              ? !otherWorkEnvironment.value
                              : workEnvironment.length === 0)
                          }
                        >
                          Siguiente
                      <i className="far fa-arrow-right gx-ml-2" />
                        </Button>
                      </Fragment>
                    }
                  />
                )}
                {step === 3 && (
                  <Spin spinning={searching} indicator={spinner}>
                    <CustomStep
                      question={<p className="gx-text-black gx-font-weight-bold">Materiales a Utilizar</p>}
                      children={
                        <Fragment>
                          <List bordered>
                            <List.Item>
                              <Checkbox
                                className="gx-text-black gx-font-weight-normal"
                                onClick={() => changeMaterial("Madera")}
                                checked={material.includes("Madera")}
                              >
                                Madera
                          </Checkbox>
                            </List.Item>
                            <List.Item>
                              <Checkbox
                                className="gx-text-black gx-font-weight-normal"
                                onClick={() => changeMaterial("Melamina")}
                                checked={material.includes("Melamina")}
                              >
                                Melamina
                          </Checkbox>
                            </List.Item>
                            <List.Item>
                              <Checkbox
                                className="gx-text-black gx-font-weight-normal"
                                onClick={() => changeMaterial("Aluminio")}
                                checked={material.includes("Aluminio")}
                              >
                                Aluminio
                          </Checkbox>
                            </List.Item>
                            <List.Item>
                              <Checkbox
                                className="gx-text-black gx-font-weight-normal"
                                onClick={() => changeMaterial("Vidrio")}
                                checked={material.includes("Vidrio")}
                              >
                                Vidrio
                          </Checkbox>
                            </List.Item>
                            <List.Item>
                              <Checkbox
                                className="gx-text-black gx-font-weight-normal"
                                onClick={() => changeMaterial("Drywall")}
                                checked={material.includes("Drywall")}
                              >
                                Drywall
                          </Checkbox>
                            </List.Item>
                            <List.Item>
                              <Checkbox
                                className="gx-text-black gx-font-weight-normal"
                                onClick={() => changeMaterial("Granito")}
                                checked={material.includes("Granito")}
                              >
                                Granito
                          </Checkbox>
                            </List.Item>
                            <List.Item>
                              <Checkbox
                                className="gx-text-black gx-font-weight-normal"
                                onClick={() => changeMaterial("Mármol")}
                                checked={material.includes("Mármol")}
                              >
                                Mármol
                          </Checkbox>
                            </List.Item>
                            <List.Item>
                              <Checkbox
                                className="gx-text-black gx-font-weight-normal"
                                onClick={() => changeMaterial("Por definir")}
                                checked={material.includes("Por definir")}
                              >
                                Por definir
                          </Checkbox>
                            </List.Item>
                            <List.Item>
                              <div className="gx-full-width">
                                <Checkbox
                                  className="gx-text-black gx-font-weight-normal"
                                  onClick={() => changeMaterial("Otros")}
                                  checked={otherMaterial.checked}
                                >
                                  Otros
                            </Checkbox>
                                <Input
                                  ref={_otherMaterial}
                                  disabled={!otherMaterial.checked}
                                  value={otherMaterial.value}
                                  className="gx-mt-2"
                                  placeholder="Ingrese material a utilizar"
                                  onChange={(e) =>
                                    setOtherMaterial({
                                      checked: true,
                                      value: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </List.Item>
                          </List>
                        </Fragment>
                      }
                      actions={
                        <Fragment>
                          {carpenters && carpenters.length === 0 && (
                            <Alert
                              type="warning"
                              message={<strong className="gx-text-black gx-font-weight-bold">¡Lo sentimos!</strong>}
                              closable
                              description={<p className="gx-text-black gx-font-weight-normal">No se encontraron especialistas cercanos a tu ubicación.</p>}
                            />
                          )}
                          <div className="gx-full-width">
                            <Button
                              type="primary"
                              className="gx-text-white gx-font-weight-bold"
                              onClick={() => {
                                setStep(2);
                                setCarpenters(null);
                              }}
                            >
                              <i className="far fa-arrow-left gx-mr-2" />
                          Anterior
                        </Button>
                            <Button
                              className="gx-text-primary  gx-border-primary gx-font-weight-bold"
                              onClick={() => {
                                handleSearchSpecialist();
                                setBannerTitle("encontrado");
                              }}
                              disabled={
                                (!otherMaterial.checked && material.length === 0) ||
                                (otherMaterial.checked
                                  ? !otherMaterial.value
                                  : material.length === 0)
                              }
                            >
                              Siguiente
                          <i className="far fa-arrow-right gx-ml-2" />
                            </Button>
                          </div>
                        </Fragment>
                      }
                    />
                  </Spin>
                )}
                {carpenters && carpenters.length > 0 && step !== 7 && (
                  <Auxiliary>
                    <WidgetHeader
                      styleName="gx-flex-row"
                      title={<p className="gx-text-black gx-font-weight-bold gx-mt-2 gx-mb-2">Especialistas encontrados</p>}
                    />
                    <ul className="gx-agents-list">
                      {carpenters.map(
                        (user, index) =>
                          user.email && (
                            <li key={index}>
                              <div className="gx-profileon gx-pointer">
                                <div className="gx-profileon-thumb">
                                  <img
                                    width="100%"
                                    height="100%"
                                    alt="..."
                                    src={user.avatar || defaultUser}
                                    className="gx-rounded-lg "
                                  />
                                </div>
                                <div
                                  className="gx-profileon-content"
                                  style={{ backgroundColor: "rgba(0,0,0,.3)" }}
                                >
                                  <h5 className="gx-mb-1 gx-text-uppercase gx-text-truncate gx-text-black">
                                    {user.name}
                                  </h5>
                                  <p className="gx-mb-0 gx-fs-sm gx-text-truncate gx-text-black">
                                    <i className="far fa-briefcase gx-pr-1" />
                                    {user.publicProjects.length} Proyecto(s)
                              </p>
                                </div>
                              </div>
                            </li>
                          )
                      )}
                    </ul>
                    <span className="gx-text-primary gx-fs-md gx-pointer gx-mb-4 gx-d-block gx-d-sm-none gx-text-black">
                      Go to agents list
                  <i className="icon gx-ml-2 icon-long-arrow-right gx-fs-xxl gx-ml-2 gx-ml-2 gx-d-inline-flex gx-vertical-align-middle" />
                    </span>
                  </Auxiliary>
                )}
                {step === 4 && (
                  <CustomStep
                    question={<p className="gx-text-black gx-font-weight-bold">¿Algo más que los especialistas deban saber?</p>}
                    children={
                      <Fragment>
                        <div className="gx-full-width">
                          <Input.TextArea
                            autoFocus={!extraInformation}
                            value={extraInformation}
                            autoSize={{ minRows: 4, maxRows: 8 }}
                            onChange={(e) => setExtraInformation(e.target.value)}
                          />
                        </div>
                      </Fragment>
                    }
                    actions={
                      <Fragment>
                        <Button
                          type="primary"
                          className="gx-text-white gx-border-primary gx-font-weight-bold"
                          onClick={searchAgain}>
                          Cancelar
                        </Button>
                        <Button
                          className="gx-text-primary gx-border-primary gx-font-weight-bold"
                          onClick={() => setStep(5)}>
                          Siguiente
                      <i className="far fa-arrow-right gx-ml-2" />
                        </Button>
                      </Fragment>
                    }
                  />
                )}
                {step === 5 && (
                  <CustomStep
                    question={<p className="gx-text-black">¿Deseas cargar una foto de referencia?</p>}
                    children={
                      <Fragment>
                        <Upload
                          multiple={false}
                          {...upload}
                          className="gx-p-0 gx-m-0 gx-pointer"
                        >
                          {preview && (
                            <img src={preview} alt="Foto de Referencia" />
                          )}
                          <div
                            className={`gx-full-width gx-mb-0 ${preview && "gx-mt-2"
                              }`}
                          >
                            <Button className="gx-text-black" type="primary">
                              <i
                                className={`far fa-${preview ? "retweet-alt" : "search"
                                  } gx-mr-2`}
                              />
                              {preview ? "Cambiar foto" : "Si, buscar foto."}
                            </Button>
                          </div>
                        </Upload>
                      </Fragment>
                    }
                    actions={
                      <Fragment>
                        <Button
                          type="primary"
                          className="gx-text-white gx-border-primary gx-font-weight-bold"
                          onClick={() => setStep(4)}>
                          <i className="far fa-arrow-left gx-mr-2" />
                      Anterior
                    </Button>
                        <Button
                          className="gx-text-primary gx-border-primary gx-font-weight-bold"
                          onClick={() => setStep(6)}>
                          Siguiente
                      <i className="far fa-arrow-right gx-ml-2" />
                        </Button>
                      </Fragment>
                    }
                  />
                )}
                {step === 6 && (
                  <CustomStep
                    question={<p className="gx-text-black">Necesitamos tus datos de contacto</p>}
                    children={
                      <Spin spinning={loading} indicator={spinner}>
                        <Form
                          layout="vertical"
                          id="service-form"
                          onSubmit={handleSubmit}
                        >
                          <Form.Item label="Nombres y Apellidos">
                            {getFieldDecorator("fullName", {
                              initialValue: fullName,
                              rules: [
                                { required: true, message: "Campo requerido" },
                              ],
                            })(
                              <Input
                                autoFocus={!fullName}
                                onChange={(e) => setFullName(e.target.value)}
                              />
                            )}
                          </Form.Item>
                          <Form.Item label="Celular">
                            {getFieldDecorator("phone", {
                              initialValue: phone,
                              rules: [
                                { required: true, message: "Campo requerido" },
                              ],
                            })(
                              <Input
                                onKeyDown={numbersPhoneOnly}
                                onChange={(e) => setPhone(e.target.value)}
                              />
                            )}
                          </Form.Item>
                          <Form.Item label="Correo Electrónico">
                            {getFieldDecorator("email", {
                              initialValue: email,
                              rules: [
                                { required: true, message: "Campo requerido" },
                                {
                                  type: "email",
                                  message:
                                    "por favor ingrese un correo electrónico válido",
                                },
                              ],
                            })(
                              <Input onChange={(e) => setEmail(e.target.value)} />
                            )}
                          </Form.Item>
                          <Form.Item label="Referencia de Ubicación">
                            {getFieldDecorator("reference_address", {
                              initialValue: referenceAddress,
                            })(
                              <Input
                                onChange={(e) =>
                                  setReferenceAddress(e.target.value)
                                }
                              />
                            )}
                          </Form.Item>
                          <Form.Item>
                            <GeolocationExample
                              setLatitude={setLatitude}
                              setLongitude={setLongitude}
                              authUser={authUser}
                              {...props}
                            />
                          </Form.Item>
                        </Form>
                      </Spin>
                    }
                    actions={
                      <Fragment>
                        <Button
                          type="primary"
                          className="gx-text-white gx-font-weight-bold"
                          onClick={() => setStep(5)} disabled={loading}>
                          <i className="far fa-arrow-left gx-mr-2" />
                      Anterior
                    </Button>
                        <Button
                          className="gx-text-black"
                          type="primary"
                          disabled={
                            !fullName ||
                            !phone ||
                            !email ||
                            !latitude ||
                            !longitude ||
                            loading
                          }
                          htmlType="submit"
                          form="service-form"
                        >
                          Finalizar
                      <i className="far fa-arrow-right gx-ml-2" />
                        </Button>
                      </Fragment>
                    }
                  />
                )}
                {step === 7 && (
                  <Result
                    status="success"
                    title={<p className="gx-text-black">¡Hemos finalizado!</p>}
                    subTitle={
                      <Fragment>
                        <p className="gx-mb-0 gx-text-black">
                          Estamos comunicando a los especialistas que podrán
                          realizar tu proyecto y te contactarán muy pronto.
                    </p>
                        <p className="gx-text-black">Revisa tu correo para que los conozcas</p>
                      </Fragment>
                    }
                    extra={[
                      <Link to="/" key="go-home">
                        <Button className="gx-text-black" type="primary" key="console">
                          <i className="far fa-home gx-mr-2" />
                      Ir a inicio
                    </Button>
                      </Link>,
                      <Button
                        className="gx-text-black"
                        onClick={searchAgain} key="search-again" >
                        <i className="far fa-search gx-mr-2" />
                    Buscar de nuevo
                  </Button>,
                    ]}
                  />
                )}
              </Col>
            </Row>
          ) : (
            <Spin
              spinning
              indicator={spinner}
              tip="Obteniendo ubicación"
              className="gx-full-width"
            />
          )}
        </div>
      </div>
    </Fragment >
  );
};

const wrapperForm = Form.create()(WizardSpecialist);
export default geolocated({
  positionOptions: {
    enableHighAccuracy: true,
  },
  userDecisionTimeout: 5000,
})(wrapperForm);
