import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

const Seo = ({
  description,
  keywords,
  title,
  image,
  url,
  og_type,
  og_sitename,
  twitter_site,
}) => {
  return (
    <Helmet>
      <title>{`${title} | eCarpintero`}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />

      <meta property="og:title" content={title} />
      <meta property="og:image" content={image} />
      <meta property="og:site_name" content={og_sitename} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content={og_type} />
      <meta property="og:url" content={url} />

      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:site" content={twitter_site} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:image" content={image} />
    </Helmet>
  );
};

Seo.defaultProps = {
  title: "Portal de integración de Carpinteros y Clientes Finales",
  keywords:
    "programa para optimizar cortes de melamina gratis,colores de melamina,tableros melamina,melamina blanca,melamina madera,corte y canteado de tableros,eCarpintero Optimizando tu negocio, eCarpintero,Muebles,Duraplac,Novopan,Arauco,Duratex,Enchape,Ranura,Perforacion,D25,Tableros Hispanos,Maderba,Vesto,Cabinet,CNC,Wood Working,Optimizador de cortes gratis,ERP,madera,triplay,laminados,mejor optimizador de corte,calculo de cantos,Seccionadora, ERP cloud,ERP online,Facturacion electronica,Melamina blanca,Carpintero peru,melamineros",
  description: `Accede a nuestro servicio gratuito de publicación de proyectos y optimización. Encuentra el mejor ERP Integral que incluye optimizador de cortes, facturación electrónica, control de la producción y mucho más.`,
  og_sitename: "eCarpintero",
  og_type: "website",
  url: "https://ecarpintero.pe",
  twitter_site: "@eCarpintero",
  image: "https://ecarpintero.pe/images/eCarpinteroOpenGraph.jpg",
};

Seo.propTypes = {
  description: PropTypes.string,
  keywords: PropTypes.string,
  image: PropTypes.string,
  og_sitename: PropTypes.string,
  og_type: PropTypes.string,
  url: PropTypes.string,
  twitter_site: PropTypes.string,
};

export default Seo;
