import gql from "graphql-tag";

export const RETRIEVE_SHOP = gql`
  query retrieveShop($id: ID!) {
    retrieveShop(id: $id) {
      id
      ubigeo {
        id
      }
      name
      phone_numbers
      address
      location
      lat
      lng
      email
      contact
      thumbnail {
        id
        url
      }
      web
      services
      products
      brands
    }
  }
`;

export const GET_SHOPS = gql`
  {
    getShops {
      id
      ubigeo {
        id
        district
        province
        department
      }
      name
      phone_numbers
      address
      location
      lat
      lng
      email
      contact
      web
      services
      products
      brands
    }
  }
`;

// north: -11.976503482656776
// south: -12.168068827517988

// east: -76.85972296478118
// west: -77.11789631130065

export const NEAR_BY_SHOPS = gql`
  query nearbyShops($east: Mixed, $west: Mixed, $north: Mixed, $south: Mixed) {
    nearbyShops(
      where: {
        column: LNG
        operator: GTE
        value: $east
        AND: {
          column: LNG
          operator: LTE
          value: $west
          AND: {
            column: LAT
            operator: GTE
            value: $north
            AND: { column: LAT, operator: LTE, value: $south }
          }
        }
      }
    ) {
      id
      name
      phone_numbers
      address
      email
      contact
      lat
      lng
      attention
      web
      services
      products
      brands
      thumbnail {
        id
        url
      }
    }
  }
`;
export const SEARCH_SHOP = gql`
  query searchShop($value: Mixed) {
    searchShop(where: { column: NAME, operator: LIKE, value: $value }) {
      id
      ubigeo {
        id
        district
        province
        department
      }
      name
      phone_numbers
      address
      location
      lat
      lng
      email
      contact
      web
      services
      products
      brands
    }
  }
`;
