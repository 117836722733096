import React, {Fragment, useState, useEffect} from 'react'
import './card_plan.css'

const CardPlan = ({plan, selectPlan}) => {
  const [openedContent, setOpenedContent] = useState([])

  const toggleContent = key => {
    if (openedContent.includes(key)) {
      setOpenedContent(openedContent.filter(item => item !== key))
    } else {
      setOpenedContent([...openedContent, key])
    }
  }

  useEffect(() => {
    if (plan) {
      const opened = []
      plan.items.map((item, key) => opened.push(key))
      setOpenedContent(opened)
    }
  }, [plan])

  return (
    <div className="mobile-plan-card gx-mb-3">
      <div
        className="mobile-plan-card-header"
        style={{background: plan.color}}
      >
        <div><i className={`fad fa-${plan.icon} gx-mb-3 gx-fs-xxxl gx-text-white`}/></div>
        Plan {plan.title}
      </div>
      <div className="mobile-plan-card-body gx-pt-2">
        {plan.items.map((item, key) => (
          <Fragment key={key}>
            <div className="mobile-card-item gx-d-flex gx-full-width gx-align-items-center" onClick={() => toggleContent(key)}>
              <div className="mobile-item-avatar-icon gx-d-flex gx-align-items-center gx-justify-content-center gx-text-white">
                <i className={`fa fa-${item.icon}`}/>
              </div>
              <div className="mobile-item-title">
                {item.title}
              </div>
              <i className={`far fa-${openedContent.includes(key) ? 'chevron-up' : 'chevron-down'} gx-ml-auto mobile-icon-item gx-text-blue`}/>
            </div>
            <div className={`mobile-item-content gx-mt-2 ${openedContent.includes(key) ? 'gx-d-block' : 'gx-d-none'}`}>
              {item.concepts.map((concept, keyConcept) => (
                <div key={keyConcept} className={`gx-d-flex gx-align-items-center ${keyConcept === item.concepts.length - 1 ? 'gx-mb-2' : 'gx-mb-4'}`}>
                  <span className="mobile-item-concept-title">
                    {concept.title}
                  </span>
                  <span className="gx-ml-auto">
                    {concept.value}
                  </span>
                </div>
              ))}
            </div>
          </Fragment>
        ))}
      </div>
      <div
        className="mobile-plan-card-footer gx-pt-2 gx-d-flex gx-justify-content-center gx-text-white"
        style={{background: plan.color}}
      >
        <button className="contact-button gx-mt-2 gx-mb-1" onClick={() => selectPlan('basic')}>
          Cotizar
        </button>
      </div>
    </div>
  )
}

export default CardPlan
