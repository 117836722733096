import React, { useState, useEffect, useRef, Fragment } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import {
  Row,
  Col,
  Button,
  Card,
  Avatar,
  Divider,
  Alert,
  Modal,
  Icon,
  Spin,
  Tag,
  Badge,
  message,
  Tooltip,
  List
} from "antd";
import Widget from "../../components/Widget";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import initials from "initials";
import { useDispatch } from "react-redux";
import moment from "moment";
import capitalize from "capitalize";

import { queriedTags } from "../../appRedux/actions";

// Graphql
import {
  PROJECT,
  PROJECT_WITHOUT_CONTACT,
  SIMILAR_PROJECTS,
} from "../../graphql/query/project";
import { RANDOM_ADVERTISEMENT } from "../../graphql/query/advertisement";
import { CREATE_VISITED_PROJECT } from "../../graphql/mutation/project";

import { Error500, Error404, spinner } from "../../util/customComponents";

import "./style.less";
import { cloudinarySecure } from "../../util/config";
import EllipsisText from "react-ellipsis-text";
import Seo from "../../components/seo";

const iconStyle = {
  width: 18,
};

const Project = (props) => {
  const Carousel = useRef();
  const dispatch = useDispatch();
  const history = useHistory();
  const { authUser } = useSelector(({ auth }) => auth);

  const [parsedID, setParsedID] = useState(null);
  const [galleryItems, setGalleryItems] = useState([]);
  const [thumbs, setThumbs] = useState([]);
  const [contactInfo, setContactInfo] = useState(false);
  const [thumbSelected, setThumbSelected] = useState(0);
  const [dataProject, setDataProject] = useState(null);
  const [materials, setMaterials] = useState([]);

  const [similarProjects, setSimilarProjects] = useState(null);
  const [advertisement, setAdvertisement] = useState(null);

  const [loading, setLoading] = useState(true);
  const [displayError, setDisplayError] = useState(false);

  const [indiceImg, setIndiceImg] = useState(0);
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const {
    params: { id },
  } = props.match;

  useEffect(() => {
    try {
      setParsedID(atob(id));
    } catch (e) {
      setDisplayError(true);
      setLoading(false);
    }
  }, [id]);

  const [createVisitedProject] = useMutation(CREATE_VISITED_PROJECT, {
    onError() {
      setDisplayError(true);
      setLoading(false);
    },
  });

  const [handleRandomAdvertisement] = useLazyQuery(RANDOM_ADVERTISEMENT, {
    onCompleted({ randomAdvertisement }) {
      if (randomAdvertisement.data.length > 0) {
        setAdvertisement(randomAdvertisement.data[0]);
      }
      setLoading(false);
    },
    onError() {
      setDisplayError(true);
      setLoading(false);
    },
  });

  const [retrieveProject] = useLazyQuery(
    authUser ? PROJECT : PROJECT_WITHOUT_CONTACT,
    {
      async onCompleted({ project }) {
        setDataProject(project);
        const galleryProject = project.gallery;
        setGalleryItems(mapGalleryItems(galleryProject));
        setThumbs(galleryProject);
        setMaterials(JSON.parse(project.materials));
        await createVisitedProject({
          variables: { project_id: project.id },
        }).then(async () => {
          const variables = {
            first: 1,
            where: {
              column: "LOCATION",
              operator: "EQ",
              value: "P",
            },
          };
          const variablesSimilar = {
            id: project.id,
            first: 4,
            page: 1,
            query: project.name,
          };
          await handleRandomAdvertisement({ variables });
          await handleSimilarProjects({ variables: variablesSimilar });
        });
      },
      fetchPolicy: "network-only",
      onError() {
        setDisplayError(true);
        setLoading(false);
      },
    }
  );

  useEffect(() => {
    if (parsedID) {
      retrieveProject({ variables: { id: parsedID } });
    }
  }, [parsedID, retrieveProject]);

  const [handleSimilarProjects] = useLazyQuery(SIMILAR_PROJECTS, {
    onCompleted({ similarProjects }) {
      setSimilarProjects(similarProjects.data);
      setLoading(false);
    },
    onError() {
      message.error("Ha ocurrido un error al obtener los proyectos similares");
    },
  });

  const mapGalleryItems = (gallery) => {
    let photo = [];
    gallery.map((item, i) => {
      return photo.push(
        <div /* className="skeleton-avatar"*/>
          <img
            alt={i}
            key={i}
            style={{
              width: "80vh",
              maxHeight: 500,
              height: "100%",
              objectFit: "scale-down",
            }}
            src={`${cloudinarySecure}/c_scale,h_500,q_auto:best,r_16/${item.url.split(cloudinarySecure)[1]
              }`}
          />
        </div>
      );
    });
    return photo;
  };

  const handleChangeSlide = (slider) => {
    setThumbSelected(slider.item);
  };

  const searchProjectByTag = (tag) => {
    dispatch(queriedTags([tag]));
    history.push("/search");
  };

  const visitWebSite = (url) => {
    window.open(url, "_blank");
  };

  const goToProfileCarpenter = (event, id) => {
    event.stopPropagation();
    history.push(`/perfil/especialista/${btoa(id)}`);
  };

  const login = e => {
    e.stopPropagation();
    history.push('/login');
  };

  const showProject = id => {
    props.history.push('/proyecto/' + btoa(id));
  }

  const [detalleModal, setDetalleModal] = useState("especialista");



  if (loading) return <Spin className="gx-full-width" spinning={loading} indicator={spinner} />;
  if (!loading && displayError) return <Error500 />;
  if (!loading && !dataProject) return <Error404 />;

  return dataProject && (
    <div className="content-proyecto">
      <Seo
        title={dataProject.name}
        description={(dataProject.description && dataProject.description) || ""}
        keywords={JSON.parse(dataProject.tags).toString()}
        url={window.location.href}
        image={
          dataProject && dataProject.gallery.length > 0
            ? dataProject.gallery[0].url
            : ""
        }
      />
      <Modal
        centered
        visible={contactInfo}
        footer={null}
        onCancel={() => setContactInfo(false)}
        title="Información de Contacto"
      >
        {authUser ? (
          dataProject.user && (
            <Fragment>
              <p className="gx-mb-1">
                <i
                  className="far fa-envelope-open-text gx-mr-2"
                  style={iconStyle}
                />
                Email: {dataProject.user.email}
              </p>
              <p className="gx-mb-1">
                <i className="far fa-phone-office gx-mr-2" style={iconStyle} />
                Teléfono:{" "}
                {dataProject.user.phone || (
                  <span className="gx-text-muted">No especificado</span>
                )}
              </p>
              <p className="gx-mb-1">
                <i className="far fa-phone gx-mr-2" style={iconStyle} />
                Celular:{" "}
                {dataProject.user.cellphone || (
                  <span className="gx-text-muted">No especificado</span>
                )}
              </p>
            </Fragment>
          )
        ) : (
          <div className="gx-mb-0">
            Lo sentimos, para poder visualizar los datos de contacto de este
            usuario es necesario que inicies sesión o te registres de manera
            gratuita.
            <div className="gx-mb-0 gx-mt-3 gx-d-inline-flex gx-full-width gx-align-items-center">
              <Link
                to={{
                  pathname: "/login",
                  redirect: `/proyecto/${btoa(dataProject.id)}`,
                }}
              >
                <Button type="primary" className="gx-mb-0 gx-mr-3">
                  Iniciar sesión
                  <i className="fa fa-sign-in-alt gx-ml-2" />
                </Button>
              </Link>
              <Link
                to={{
                  pathname: "/register",
                  redirect: `/proyecto/${btoa(dataProject.id)}`,
                }}
              >
                <Button type="primary" className="gx-mb-0">
                  Registrarse
                  <i className="fa fa-user-plus gx-ml-2" />
                </Button>
              </Link>
            </div>
          </div>
        )}
      </Modal>
      <Row type="flex" justify="space-between">
        <Col xs={24} sm={24} md={16} lg={16} xl={13} className="gx-px-0">
          <Card
            className="gx-card-widget"
          >
            <div className="card-proyecto">
              <div className="imgs-proyecto">
                <nav
                  style={{ width: "100%" }}
                  className="menu-subimagenes gx-text-center gx-mt-3"
                >
                  <Button disabled={indiceImg === 0} type="primary" className="gx-m-0 gx-mb-3" onClick={() => {
                    Carousel.current.slideTo(indiceImg - 1);
                    if (indiceImg > 0) {
                      setIndiceImg(indiceImg - 1);
                    }
                  }}>
                    <i className="fas fa-angle-up"></i>
                  </Button>
                  {thumbs.map((item, i) => (
                    <Avatar
                      key={i}
                      size={50}
                      shape="square"
                      onClick={() => {
                        Carousel.current.slideTo(i);
                        setIndiceImg(i);
                      }
                      }
                      className="gx-mr-2 gx-pointer"
                      src={`${cloudinarySecure}/c_pad,h_40,q_auto:low,w_40/${item.url.split(cloudinarySecure)[1]
                        }`}
                      style={{
                        transform: thumbSelected === i ? "scale(1.2)" : "none",
                        border:
                          thumbSelected === i ? "2px solid #5c3715" : "none",
                      }}
                    />
                  ))}
                  <Button disabled={indiceImg === thumbs.length - 1} type="primary" className="gx-m-0 gx-mt-3" onClick={() => {
                    Carousel.current.slideTo(indiceImg + 1);
                    if (!(indiceImg === thumbs.length)) {
                      setIndiceImg(indiceImg + 1);
                    }
                  }}>
                    <i class="fas fa-angle-down"></i>
                  </Button>
                </nav>
              </div>
              <div className="img-proyecto">
                {galleryItems.length > 0 ? (
                  <Fragment>
                    <AliceCarousel
                      items={galleryItems}
                      buttonsDisabled={true}
                      dotsDisabled={true}
                      mouseTrackingEnabled={false}
                      swipeDisabled={false}
                      infinite={true}
                      preventEventOnTouchMove={true}
                      ref={Carousel}
                      onSlideChanged={handleChangeSlide}
                    />
                  </Fragment>
                ) : (
                  <Alert
                    banner
                    type="warning"
                    message={null}
                    description="No se encontraron imagenes aprobadas para este proyecto"
                  />
                )}
              </div>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={11} className="gx-px-0 gx-px-md-3">
          <Widget>
            <div>
              <h1 className="gx-font-weight-bold gx-text-black" style={{ fontFamily: "Open Sans" }} >{dataProject.description}</h1>
              {dataProject.tags && (
                <div className="gx-mb-0 gx-d-md-none gx-d-block">
                  <Divider orientation="left">
                    Etiquetas
                  </Divider>
                  {JSON.parse(dataProject.tags).map((tag, index) => (
                    <Tag
                      key={index}
                      className="gx-pointer"
                      onClick={() => searchProjectByTag(tag)}
                    >
                      {tag}
                    </Tag>
                  ))}
                </div>
              )}
            </div>
            <div className="gx-media gx-align-items-center gx-mb-4">
              <div className="gx-mr-3">
                <Avatar
                  size={50}
                  children={initials(dataProject.user.name)}
                  src={dataProject.user.avatar || null}
                  alt={dataProject.user.name}
                />
              </div>
              <div className="gx-media-body gx-mt-1">
                <h2 className="gx-mb-1 gx-text-black gx-font-weight-bold" style={{ fontFamily: "Open sans" }}>
                  {dataProject.user.name + " " + dataProject.user.last_name}
                </h2>
                {dataProject.user.specialty && (
                  <p className="gx-text-black gx-font-weight-normal gx-mb-0">
                    {`${dataProject.user.specialty.parent.description
                      ? dataProject.user.specialty.parent.description + " - "
                      : ""
                      } ${dataProject.user.specialty.description}`}
                  </p>
                )}
              </div>
            </div>
            <div className="gx-mb-2">
              {dataProject.user.experience_resume && (
                <p className="gx-text-black gx-font-weight-normal">{dataProject.user.experience_resume}</p>
              )}
              <p className="gx-mb-0 gx-text-black gx-font-weight-normal">
                <i className="far fa-calendar-alt gx-mr-2" />
                Años de Experiencia:{" "}
                {dataProject.user.experience_years || (
                  <span className="gx-text-muted gx-text-black gx-font-weight-normal">Sin experiencia</span>
                )}
              </p>
            </div>
            {dataProject.user.latest_connection && (
              <p className="gx-mb-2 gx-text-black gx-font-weight-normal">
                <Badge color="green" dot className="gx-mr-2">
                  <Icon type="notification" className="" />
                </Badge>
                {`Última conexión: ${capitalize.words(
                  moment(dataProject.user.latest_connection).format(
                    "ddd DD MMMM, YYYY [-] HH:mm A"
                  )
                )}`}
              </p>
            )}
            <div className="cont-btn-contacto gx-text-left gx-full-width gx-align-items-center gx-d-flex gx-justify-content-between" >
              <Button className="gx-mb-0 gx-text-primary gx-border-primary gx-fs-xs gx-font-weight-bold" onClick={showModal}>
                MÁS DETALLES
              </Button>
              <Modal
                visible={visible}
                onCancel={() => setVisible(false)}
                closable={true}
                closeIcon={<p className="gx-text-primary">X</p>}
                width="80%"
                keyboard={true}
                footer={null}
              >
                <Divider className="gx-mt-3" />
                <div className="content-detalles-modal gx-font-weight-bold gx-d-flex" style={{ fontFamily: "Open Sans" }}>
                  <p className="gx-mr-3 detalle-pro " style={{ color: `${detalleModal === "detalle" ? "#EEBF99" : "black"}` }} onClick={() => setDetalleModal("detalle")} >DETALLES</p>
                  <p className="gx-mr-3 gx-text-grey">|</p>
                  <p className="gx-mr-3 detalle-pro" style={{ color: `${detalleModal === "material" ? "#EEBF99" : "black"}` }} onClick={() => setDetalleModal("material")} >MATERIALES</p>
                  <p className="gx-mr-3 gx-text-grey">|</p>
                  <p className="detalle-pro " style={{ color: `${detalleModal === "especialista" ? "#EEBF99" : "black"}` }} onClick={() => setDetalleModal("especialista")} >ESPECIALISTA</p>
                </div>


                <div style={{ display: `${detalleModal === "especialista" ? "block" : "none"}` }}>
                  <div className="gx-media gx-align-items-center gx-mb-4">
                    <div className="gx-mr-3">
                      <Avatar
                        size={180}
                        children={initials(dataProject.user.name)}
                        src={dataProject.user.avatar || null}
                        alt={dataProject.user.name}
                      />
                    </div>

                    <div className="gx-media-body gx-mt-0 esp-datos">
                      <h2 className="gx-mb-1 gx-text-black gx-font-weight-bold " style={{ fontFamily: "Open sans" }}>
                        {dataProject.user.name + " " + dataProject.user.last_name}
                      </h2>
                      {dataProject.user.specialty && (
                        <p className="gx-mb-0 gx-text-black gx-font-weight-normal">
                          {`${dataProject.user.specialty.parent.description
                            ? dataProject.user.specialty.parent.description + " - "
                            : ""
                            } ${dataProject.user.specialty.description}`}
                        </p>
                      )}
                      {dataProject.user.experience_resume && (
                        <p className="gx-text-black gx-font-weight-normal">{dataProject.user.experience_resume}</p>
                      )}
                      <div className="gx-mb-2">
                        <p className="gx-mb-0 gx-text-black gx-font-weight-normal">
                          <i className="far fa-calendar-alt gx-mr-2" />
                Años de Experiencia:{" "}
                          {dataProject.user.experience_years || (
                            <span className="gx-text-muted gx-text-black gx-font-weight-normal">Sin experiencia</span>
                          )}
                        </p>
                      </div>
                      {dataProject.user.latest_connection && (
                        <p className="gx-mb-2 gx-text-black gx-font-weight-normal">
                          <Badge color="green" dot className="gx-mr-2">
                            <Icon type="notification" className="" />
                          </Badge>
                          {`Última conexión: ${capitalize.words(
                            moment(dataProject.user.latest_connection).format(
                              "ddd DD MMMM, YYYY [-] HH:mm A"
                            )
                          )}`}
                        </p>
                      )}
                    </div>
                  </div>
                  <Divider />
                  {
                    similarProjects && similarProjects.length > 0 && (
                      <Row type="flex" className="gx-mb-4">
                        <Col span={24}>
                          <div className="gx-entry-sec">
                            <div className="gx-entry-title gx-text-capitalize">
                              <h2 className="gx-mb-0" style={{ color: "#000000", fontFamily: "Open Sans", fontSize: "22px" }}>Otros proyectos relizados</h2>
                            </div>
                          </div>
                        </Col>
                        <div className="proyectos-similares">
                          {/*----------------CAMBIAR EL ARRAY A OTROS PROYECTOS DEL MISMO ESPECIALISTA----------------------------*/}
                          {similarProjects.map((similar, key) => (
                            <Col xs={24} sm={12} md={12} lg={6} xl={6} key={key}>
                              <div className="tile" onClick={() => showProject(similar.id)}>
                                <div className="img-proyecyo-similar">
                                  <img
                                    className="img-pro"
                                    src={`${cloudinarySecure}/c_scale,q_auto:low,w_400/${similar.thumbnail.url.split(cloudinarySecure)[1]
                                      }`}
                                    alt="Imagen Destacada"
                                  />
                                </div>
                                {/*-----------------------------------------------------------------------------------------------------*/}
                                <div className="details gx-d-none gx-d-lg-block">
                                  <div className="actions">
                                    <div className="gx-d-inline-flex gx-align-items-center buttons">
                                      <Button
                                        size="small"
                                        className="gx-mb-0 gx-gb-white gx-border-white gx-text-black gx-d-inline-flex gx-align-items-center"
                                        shape="round"
                                        onClick={event => goToProfileCarpenter(event, similar.user.id)}
                                      >
                                        <i className="far fa-user-circle gx-mr-2" />
                                        {similar.user.name}
                                      </Button>
                                      {authUser ? (
                                        <Button
                                          type="danger"
                                          size="small"
                                          shape="round"
                                          className="gx-ml-auto gx-mb-0 gx-mr-0"
                                        >
                                          <i className="far fa-thumbtack gx-mr-2" />
                                        Guardar
                                        </Button>
                                      ) : (
                                        <Button
                                          type="danger"
                                          size="small"
                                          shape="round"
                                          className="gx-ml-auto gx-mb-0 gx-mr-0"
                                          onClick={e => login(e)}
                                        >
                                          Iniciar sesión
                                        </Button>
                                      )}
                                      {authUser && (
                                        <Button
                                          size="small"
                                          shape="circle"
                                          className="dropdown gx-position-absolute gx-right-0 gx-bottom-0 gx-mb-2 gx-bg-white gx-border-white gx-text-black"
                                        >
                                          <i className="far fa-ellipsis-h" />
                                          <ul className="dropdown-content">
                                            <li /*onClick={e => shareProject(e)}*/>
                                              <i className="far fa-share gx-mr-2" />
                                              Compartir
                                            </li>
                                            <Divider className="gx-m-0 gx-p-0" />
                                            <li /*onClick={e => reportProject(e)}*/>
                                              <i className="far fa-bullhorn gx-mr-2" />
                                              Reportar
                                            </li>
                                          </ul>
                                        </Button>
                                      )}
                                    </div>
                                    <div className="data">
                                      <div className="gx-mb-1 gx-fs-sm">
                                        <Tooltip title="Visitas">
                                          <span>
                                            {similar.visited}
                                            <i className="fa fa-eye gx-ml-1" />
                                          </span>
                                        </Tooltip>
                                        <Tooltip title="Favoritos">
                                          <span className="gx-ml-2">
                                            {similar.favorites}
                                            <i className="fa gx-text-danger fa-heart gx-ml-1" />
                                          </span>
                                        </Tooltip>
                                        <Tooltip title="Compartir">
                                          <span className="gx-ml-2">
                                            {similar.shared}
                                            <i className="fa fa-share gx-ml-1" />
                                          </span>
                                        </Tooltip>
                                      </div>

                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="gx-text-black gx-font-weight-bold gx-ml-2" style={{ fontFamily: "Open Sans" }}>
                                {similar.description && (
                                  <p className="gx-mb-1">{capitalize(similar.description)}</p>
                                )}
                                {/*-----------------------COLOCAR EL TIPO DE MATERIAL ----------------------------*/}
                                <p className="gx-fs-xs" >Material: {"MADERA"} </p>
                                {/*-------------------------------------------------------------------------------*/}
                              </div>
                              <div
                                className="gx-d-lg-none gx-fs-md gx-text-black gx-mb-0 gx-d-inline-flex gx-align-items-center"
                                style={{ width: "100%" }}
                              >
                                <div className="gx-mb-0 phone-title">
                                  {similar.name}
                                  <div className="gx-fs-sm">
                                    <i className="far fa-user-circle gx-mr-1" />
                                    {similar.user.name}
                                  </div>
                                </div>
                                <Button
                                  type="link"
                                  shape="circle"
                                  size="small"
                                  className="dropdown dropdown-sm gx-p-0 gx-m-0 gx-text-black gx-ml-auto"
                                >
                                  <i className="far fa-ellipsis-h" />
                                  <ul className="dropdown-content">
                                    <li /*onClick={e => saveItem(e)}*/>
                                      <i className="far fa-thumbtack gx-mr-2" />
                                      Guardar
                                    </li>
                                    <li>
                                      <i className="far fa-heart gx-mr-2" />
                                      Me gusta
                                    </li>
                                    <li /*onClick={() => shareProject(props.item)}*/>
                                      <i className="far fa-share gx-mr-2" />
                                      Compartir
                                    </li>
                                    <Divider className="gx-m-0 gx-p-0" />
                                    <li>
                                      <i className="far fa-bullhorn gx-mr-2" />
                                      Reportar
                                    </li>
                                  </ul>
                                </Button>
                              </div>
                            </Col>
                          ))}
                        </div>
                      </Row>
                    )
                  }
                </div>


                <div style={{ display: `${detalleModal === "detalle" ? "block" : "none"}` }}>
                  <div className="gx-fs-lg gx-text-black gx-mt-3 gx-font-weight-normal" style={{ fontFamily: "Open Sans" }}>
                    {dataProject.description}
                  </div>
                </div>

                <div style={{ display: `${detalleModal === "material" ? "block" : "none"}` }}>
                  {materials.length > 0 && (
                    <Fragment>
                      <List
                        bordered
                        dataSource={materials}
                        renderItem={(item, index) => (
                          <List.Item className="gx-font-weight-normal gx-text-black" key={index} style={{ fontFamily: "Open Sans" }}>
                            <i className="far fa-check gx-mr-2" />
                            {item}
                          </List.Item>
                        )}
                      />
                    </Fragment>
                  )}
                </div>
              </Modal>

              <Button type="primary" className="gx-mb-0 gx-fs-xs gx-mr-3 gx-font-weight-bold">
                <Link to={`/perfil/especialista/${btoa(dataProject.user.id)}`}>
                  VER PROYECTOS
                  </Link>
              </Button>

              <Button
                className="gx-mb-0 gx-text-primary gx-border-primary gx-fs-xs gx-font-weight-bold"
                onClick={() => setContactInfo(true)}
              >
                CONECTAR
              </Button>
            </div>
            {dataProject.tags && (
              <div className="gx-mb-0 gx-d-md-block gx-d-none">
                <Divider orientation="left"><span className="gx-font-weight-bold">Etiquetas</span></Divider>
                {JSON.parse(dataProject.tags).map((tag, index) => (
                  <Tag
                    key={index}
                    className="gx-pointer"
                    onClick={() => searchProjectByTag(tag)}
                  >
                    {tag}
                  </Tag>
                ))}
              </div>
            )}
          </Widget>
          {/*advertisement && (
            <Widget
              styleName="gx-widget-bg gx-text-white gx-pointer"
              bodyStyle={{
                backgroundImage: `url(${cloudinarySecure}/bo_0px_solid_rgb:000,c_scale,e_blur:200,q_auto:eco,w_480/${advertisement.multimedia.url.split(cloudinarySecure)[1]})`,
                backgroundSize: 'cover',
                objectFit: 'scale-down',
                backgroundRepeat: 'no-repeat',
                borderRadius: '4px'
              }}
            >
              <span className="gx-widget-badge gx-bg-red">ANUNCIO</span>
              <i className="far fa-tools gx-mb-4 gx-fs-xlxl"/>

              <div style={{backgroundColor: "rgba(0,0,0,.5)"}} className="gx-p-4">
                <h1 className="gx-fs-xxxl gx-font-weight-semi-bold gx-text-white gx-mb-3 gx-mb-sm-4">
                  {advertisement.client.social_reason}
                </h1>
                <p>
                  <i className="far fa-map gx-mr-2"/>
                  {advertisement.client.address || <span className="gx-text-muted">No definido</span>}
                </p>
                <p>Obtenga los mejores materiales para la construcción de sus proyectos.</p>
                <a className="gx-btn gx-btn-primary gx-btn-warning" href={advertisement.redirect_url} target="_blank">
                  Visitar página
                  <i className="far fa-external-link gx-ml-2"/>
                </a>
              </div>
            </Widget>
          )*/}
          {advertisement && (
            <Widget styleName="gx-widget-bg" bodyStyle={{ padding: "0" }}>
              <span className="gx-widget-badge gx-bg-red">ANUNCIO</span>
              {/*<i className="far fa-tools gx-mb-4 gx-fs-xlxl"/>*/}

              <img
                src={`${cloudinarySecure}/c_scale,q_auto:eco,w_480/${advertisement.multimedia.url.split(cloudinarySecure)[1]
                  }`}
                alt="Foto de anuncio"
                style={{
                  width: "100%",
                }}
                className="gx-pointer"
                onClick={() => visitWebSite(advertisement.redirect_url)}
              />
            </Widget>
          )}
          {/*<img
            src="https://via.placeholder.com/400x80.jpg?text=Anuncie%20aqu%C3%AD"
            className="gx-full-width"
            alt="Anuncio"
          />*/}
        </Col>
      </Row >

      {
        similarProjects && similarProjects.length > 0 && (
          <Row type="flex" className="gx-mb-4">
            <Col span={24}>
              <div className="gx-entry-sec">
                <div className="gx-entry-title gx-text-capitalize">
                  <h2 className="gx-mb-0 gx-font-weight-bold" style={{ color: "#000000", fontFamily: "Open Sans", fontSize: "22px" }}>Proyectos Similares</h2>
                </div>
              </div>
            </Col>
            <div className="proyectos-similares">
              {similarProjects.map((similar, key) => (
                <Col xs={24} sm={12} md={12} lg={6} xl={6} key={key}>
                  <div className="tile" onClick={() => showProject(similar.id)}>
                    <div className="img-proyecyo-similar">
                      <img
                        className="img-pro"
                        src={`${cloudinarySecure}/c_scale,q_auto:low,w_400/${similar.thumbnail.url.split(cloudinarySecure)[1]
                          }`}
                        alt="Imagen Destacada"
                      />
                    </div>

                    <div className="details gx-d-none gx-d-lg-block">
                      <div className="actions">
                        <div className="gx-d-inline-flex gx-align-items-center buttons">
                          <Button
                            size="small"
                            className="gx-mb-0 gx-gb-white gx-border-white gx-text-black gx-d-inline-flex gx-align-items-center"
                            shape="round"
                            onClick={event => goToProfileCarpenter(event, similar.user.id)}
                          >
                            <i className="far fa-user-circle gx-mr-2" />
                            {similar.user.name}
                          </Button>
                          {authUser ? (
                            <Button
                              type="danger"
                              size="small"
                              shape="round"
                              className="gx-ml-auto gx-mb-0 gx-mr-0"
                            //onClick={e => saveItem(e)}
                            >
                              <i className="far fa-thumbtack gx-mr-2" />
                          Guardar
                            </Button>
                          ) : (
                            <Button
                              type="danger"
                              size="small"
                              shape="round"
                              className="gx-ml-auto gx-mb-0 gx-mr-0"
                              onClick={e => login(e)}
                            >
                              Iniciar sesión
                            </Button>
                          )}
                          {authUser && (
                            <Button
                              size="small"
                              shape="circle"
                              className="dropdown gx-position-absolute gx-right-0 gx-bottom-0 gx-mb-2 gx-bg-white gx-border-white gx-text-black"
                            >
                              <i className="far fa-ellipsis-h" />
                              <ul className="dropdown-content">
                                {/*<li onClick={e => !makingFavorite ? likeProject(e) : null}>
                              {makingFavorite
                                ? <Icon type="loading" className="gx-mr-2"/>
                                : <i className={`gx-mr-2 ${favorite ? 'gx-text-danger fa' : 'far'} fa-heart`}/>
                              }
                              Me gusta
                            </li>*/}
                                <li /*onClick={e => shareProject(e)}*/>
                                  <i className="far fa-share gx-mr-2" />
                              Compartir
                            </li>
                                <Divider className="gx-m-0 gx-p-0" />
                                <li /*onClick={e => reportProject(e)}*/>
                                  <i className="far fa-bullhorn gx-mr-2" />
                              Reportar
                            </li>
                              </ul>
                            </Button>
                          )}
                        </div>
                        <div className="data">
                          <div className="gx-mb-1 gx-fs-sm">
                            <Tooltip title="Visitas">
                              <span>
                                {similar.visited}
                                <i className="fa fa-eye gx-ml-1" />
                              </span>
                            </Tooltip>
                            <Tooltip title="Favoritos">
                              <span className="gx-ml-2">
                                {similar.favorites}
                                <i className="fa gx-text-danger fa-heart gx-ml-1" />
                              </span>
                            </Tooltip>
                            <Tooltip title="Compartir">
                              <span className="gx-ml-2">
                                {similar.shared}
                                <i className="fa fa-share gx-ml-1" />
                              </span>
                            </Tooltip>
                          </div>
                          <div className="title">
                            <EllipsisText
                              text={capitalize(similar.name)}
                              length={30}
                            />
                          </div>
                          <div className="info">
                            {similar.description && (
                              <EllipsisText
                                text={capitalize(similar.description)}
                                length={40}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="gx-d-lg-none gx-fs-md gx-text-black gx-mb-0 gx-d-inline-flex gx-align-items-center"
                    style={{ width: "100%" }}
                  >
                    <div className="gx-mb-0 phone-title">
                      {similar.name}
                      <div className="gx-fs-sm">
                        <i className="far fa-user-circle gx-mr-1" />
                        {similar.user.name}
                      </div>
                    </div>
                    <Button
                      type="link"
                      shape="circle"
                      size="small"
                      className="dropdown dropdown-sm gx-p-0 gx-m-0 gx-text-black gx-ml-auto"
                    >
                      <i className="far fa-ellipsis-h" />
                      <ul className="dropdown-content">
                        <li /*onClick={e => saveItem(e)}*/>
                          <i className="far fa-thumbtack gx-mr-2" />
                      Guardar
                    </li>
                        <li>
                          <i className="far fa-heart gx-mr-2" />
                      Me gusta
                    </li>
                        <li /*onClick={() => shareProject(props.item)}*/>
                          <i className="far fa-share gx-mr-2" />
                      Compartir
                    </li>
                        <Divider className="gx-m-0 gx-p-0" />
                        <li>
                          <i className="far fa-bullhorn gx-mr-2" />
                      Reportar
                    </li>
                      </ul>
                    </Button>
                  </div>
                </Col>
              ))}
            </div>
          </Row>
        )
      }
    </div >
  );
};

export default Project;
