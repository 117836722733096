const semVerGreaterThan = async (versionA, versionB) => {
  return versionA !== versionB ? true : false;
};

const cacheBuster = async () => {
  const refreshCacheAndReload = (currentVersion) => {
    const clearAllStorage = (cb = null) => {
      //SERVICE WORKER CHACHE SHOULD BE CLEARED WITH CACHES.DELETE();
      if (window.caches) {
        caches.keys().then((names) => {
          if (names && names.length > 0) {
            for (const name of names) caches.delete(name);
          }
        });
      }

      // DELETE SERVICEWORKER
      if (window.navigator && navigator.serviceWorker) {
        navigator.serviceWorker
          .getRegistrations()
          .then(function (registrations) {
            for (let registration of registrations) {
              registration.unregister();
            }
          });
      }

      //   CLEAR LOCALSTORAGE
      if (window.localStorage) {
        localStorage.clear();
      }

      cb && cb(currentVersion);
    };

    clearAllStorage((version) => {
      //delete browser cache and hard reload
      localStorage.setItem("_v", version);
      window.location.reload();
    });
  };

  try {
    const $response = await fetch(`${process.env.PUBLIC_URL}/meta.json`);
    const $meta = await $response.json();
    const currentVersion = (await localStorage.getItem("_v"))
      ? localStorage.getItem("_v")
      : "0.0.0";
    const newVersion = (await $meta.version) ? $meta.version : "0.0.0";
    const shouldForeceRefresh = await semVerGreaterThan(
      currentVersion,
      newVersion
    );
    if (shouldForeceRefresh) {
      refreshCacheAndReload(newVersion);
    }
  } catch (error) {
    console.log(
      "🚀 ~ file: cacheBuster.js ~ line 29 ~ cacheBuster ~ error",
      error
    );
  }
};

export default cacheBuster;
