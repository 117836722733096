import React, { useState } from "react";
import "./index.less";
import { Row, Col, Form, Input, Button, message, Spin, Alert } from "antd";
import { useMutation } from "@apollo/react-hooks";
import Whatsapp from "assets/images/social-media/whatsapp.svg";
import Facebook from "assets/images/social-media/facebook.svg";
import Instagram from "assets/images/social-media/instagram.svg";

import { REGISTER_ATTENTION } from "../../graphql/mutation/contact_us";
import { spinner } from "../../util/customComponents";
import Seo from "../../components/seo";

const ContactUs = (props) => {
  const { getFieldDecorator, resetFields, validateFields } = props.form;
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const [saveAttention, { loading: saving }] = useMutation(REGISTER_ATTENTION, {
    onCompleted({ registerAttention }) {
      if (registerAttention.registered) {
        setSuccessMessage(true);
        setErrorMessage(false);
        resetFields();
      } else {
        setSuccessMessage(false);
        setErrorMessage(true);
      }
    },
    onError() {
      message.error("Ha ocurrido un error al registrar la solicitud");
    },
  });

  const submit = (e) => {
    e.preventDefault();
    setSuccessMessage(false);
    setErrorMessage(false);
    validateFields((err, values) => {
      if (!err) {
        const full_name = `${values.nombre + " " + values.apellido}`;
        let parsedVariables = {
          full_name,
          email: values.email,
          subject: values.subject,
          message: values.message,
        };
        if (values.contact_number) {
          parsedVariables["contact_number"] = values.contact_number;
        }
        const variables = { input: parsedVariables };
        saveAttention({ variables });
      }
    });
  };

  return (
    <Spin spinning={saving} indicator={spinner} className="gx-p-0">
      <Seo
        title="Contáctanos"
        url={window.location.href}
        description={`Si tiene alguna consulta o sugerencia, agradeceremos completar el siguiente formulario. eCarpintero siempre en contacto.`}
        keywords="contactar"
      />
      <figure className="banner-contacto">
        <p className="mensaje">Contáctenos</p>
      </figure>
      <div className="iconos-contacto">
        <div>
          <a
            href="https://api.whatsapp.com/send?phone=51924770892"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Whatsapp} alt="wsp" title="Whatsapp" />
            <p className="gx-mt-3" style={{ textAlign: "center" }}>
              Whatsapp
            </p>
          </a>
        </div>
        <div
          style={{
            borderRight: "1px solid #b4b4b4",
            borderLeft: "1px solid #b4b4b4",
          }}
        >
          <a
            href="https://www.facebook.com/eCarpinteroPeru"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Facebook} alt="facebook" title="Facebook" />
            <p className="gx-mt-3" style={{ textAlign: "center" }}>
              Facebook
            </p>
          </a>
        </div>
        <div>
          <a
            href="https://www.instagram.com/ecarpinteroperu/?hl=es-la"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Instagram} alt="instagram" title="Instagram" />
            <p className="gx-mt-3" style={{ textAlign: "center" }}>
              Instagram
            </p>
          </a>
        </div>
      </div>
      <div className="fondo-contacto">
        <Row>
          <Col xs={24} sm={24} md={24} lg={24}>
            <div className="content-form gx-mt-5 gx-mb-5">
              <Row type="flex" justify="center">
                <Col xs={18} sm={16} md={20} lg={6}>
                  <div className="escribanos">
                    <p className="gx-text-white gx-fs-xxl">Escríbanos</p>
                    <p className="gx-text-white gx-fs-xl">
                      Puede hacernos llegar sus consultas llenando este
                      formulario
                    </p>
                  </div>
                </Col>
                <Col xs={18} sm={16} md={20} lg={12}>
                  <Form onSubmit={submit}>
                    <Row gutter={16}>
                      <Col xs={24} lg={12}>
                        <Form.Item className="form-control-ec">
                          {getFieldDecorator("nombre", {
                            rules: [
                              {
                                required: true,
                                message: "Ingrese su nombre",
                              },
                            ],
                          })(
                            <Input
                              maxLength={100}
                              autoFocus
                              placeholder="Nombre"
                            />
                          )}
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12}>
                        <Form.Item className="form-control-ec">
                          {getFieldDecorator("apellido", {
                            rules: [
                              {
                                required: true,
                                message: "Ingrese su apellido",
                              },
                            ],
                          })(
                            <Input
                              maxLength={100}
                              autoFocus
                              placeholder="Apellido"
                            />
                          )}
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                        <Form.Item className="form-control-ec">
                          {getFieldDecorator("contact_number")(
                            <Input
                              maxLength={15}
                              type="tel"
                              placeholder="Teléfono"
                            />
                          )}
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                        <Form.Item className="form-control-ec">
                          {getFieldDecorator("email", {
                            rules: [
                              {
                                required: true,
                                message: "Ingrese su correo electrónico",
                              },
                              {
                                type: "email",
                                message:
                                  "Por favor ingrese un correo electrónico válido",
                              },
                            ],
                          })(
                            <Input
                              maxLength={60}
                              type="email"
                              placeholder="E-Mail"
                            />
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item className="form-control-ec">
                          {getFieldDecorator("subject", {
                            rules: [
                              {
                                required: true,
                                message: "Ingrese el asunto del mensaje",
                              },
                            ],
                          })(<Input maxLength={123} placeholder="Asunto" />)}
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item className="form-control-ec">
                          {getFieldDecorator("message", {
                            rules: [
                              {
                                required: true,
                                message: "Ingrese el mensaje",
                              },
                            ],
                          })(
                            <Input.TextArea
                              minLength={30}
                              autoSize={{ maxRows: 8, minRows: 6 }}
                              placeholder="Mensaje"
                            />
                          )}
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24}>
                        <Form.Item
                          type="flex"
                          className="form-control-ec gx-mb-0"
                        >
                          <Row>
                            <Col
                              xs={24}
                              sm={8}
                              md={8}
                              lg={6}
                              className=" gx-pl-0"
                            >
                              <Button
                                className="btn-contacto gx-text-black"
                                htmlType="reset"
                                disabled={saving}
                                onClick={() => resetFields()}
                              >
                                Cancelar
                              </Button>
                            </Col>
                            <Col
                              xs={24}
                              sm={8}
                              md={8}
                              lg={6}
                              className=" gx-pl-0"
                            >
                              <Button
                                className="btn-contacto gx-font-weight-bold"
                                type="primary"
                                htmlType="submit"
                                disabled={saving}
                              >
                                ENVIAR
                              </Button>
                            </Col>
                          </Row>
                        </Form.Item>
                      </Col>
                      {successMessage && (
                        <Col span={24}>
                          <Alert
                            banner
                            closable
                            type="info"
                            message={<strong>¡Genial!</strong>}
                            description="Se ha registrado su solicitud correctamente. Nos contactaremos con usted lo más pronto posible."
                          />
                        </Col>
                      )}
                      {errorMessage && (
                        <Col span={24}>
                          <Alert
                            banner
                            closable
                            type="error"
                            message={<strong>¡Lo sentimos!</strong>}
                            description="Estamos teniendo problemas al registrar su solicitud, vuelva a intentarlo nuevamente."
                          />
                        </Col>
                      )}
                    </Row>
                  </Form>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </Spin>
  );
};

export default Form.create({ name: "contact_us_form" })(ContactUs);
