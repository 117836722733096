import React from 'react'
import {Form, Input, Button, Tooltip, Divider} from 'antd'
import {useDispatch} from 'react-redux'

import {toggleWizard} from '../../../../appRedux/actions'
import {numbersPhoneOnly} from '../../../../util/helpers'

const {Item} = Form

const ContactForm = ({form, skip, user, handleUpdateUser}) => {
  const {validateFields, getFieldDecorator, getFieldValue} = form
  const dispatch = useDispatch()

  const submit = event => {
    event.preventDefault()
    validateFields((errors, values) => {
      if (!errors) {
        const variables = {
          id: user.id,
          phone: values.phone || null,
          cellphone: values.cellphone || null
        }
        handleUpdateUser({variables})
      }
    })
  }

  return (
    <Form onSubmit={submit} layout="vertical">
      <Item>
        <h2 className="gx-font-weight-semi-bold gx-mb-1">
          Datos de Contacto
          <Tooltip 
            title={(
              <div className="gx-fs-sm">
                <p className="gx-mb-0">Tu correo electrónico, número de celular y teléfono podrá ser visualizado por usuarios registrados en la plataforma de eCarpintero.</p>
              </div>
            )}
          >
            <i className="fal fa-question-circle gx-pointer gx-fs-md gx-float-right"/>
          </Tooltip>
        </h2>
        <div className="gx-text-muted gx-fs-sm gx-mb-0">
          Esta información será útil para que tus clientes se contacten contigo mucho más rápido.
        </div>
      </Item>
      <Divider className="gx-mt-0 gx-mb-3" dashed/>
      <Item label="Celular">
        {getFieldDecorator('cellphone', {
          rules: [
            {required: true, message: 'Número de celular requerido'}
          ]
        })(
          <Input
            allowClear
            onKeyDown={numbersPhoneOnly}
            placeholder="Ingresa tu número de celular"
            suffix={<i className="far fa-phone" style={{color: '#bfbfbf'}}/>}
          />
        )}
      </Item>
      <Item label="Teléfono" className="gx-mb-4">
        {getFieldDecorator('phone')(
          <Input
            allowClear
            onKeyDown={numbersPhoneOnly}
            placeholder="Ingresa tu número de teléfono"
            suffix={<i className="far fa-phone-rotary" style={{color: '#bfbfbf'}}/>}
          />
        )}
      </Item>
      <Item className="gx-mb-0">
        <Button type="primary" htmlType="submit" className="gx-mb-0" disabled={!getFieldValue('cellphone')}>Guardar</Button>
        <Button onClick={() => skip('contact')} className="gx-mb-0">Omitir</Button>
        <Button onClick={() => dispatch(toggleWizard(false, form))} className="gx-float-right gx-mb-0">Cancelar</Button>
      </Item>
    </Form>
  )
}

export default Form.create()(ContactForm)
