import {
  USER_RENIEC_DATA,
  FETCH_START_RENIEC,
  FETCH_SUCCESS_RENIEC,
  FETCH_ERROR_RENIEC,
  FETCH_START_SUNAT,
  FETCH_SUCCESS_SUNAT,
  FETCH_ERROR_SUNAT,
  RESET_INITIAL_STATE_SUNAT,
  FETCH_CHANGE_TYPE_START,
  FETCH_CHANGE_TYPE_SUCCESS,
  FETCH_CHANGE_TYPE_ERROR,
  START_GET_TIPO_CAMBIO,
  SUCCESS_GET_TIPO_CAMBIO,
  ERROR_GET_TIPO_CAMBIO,
  RESET_INFO_RENIEC
} from "../../constants/ActionTypes";

import axios from "util/Api";
import crypt from "node-cryptex";

import { k, v } from "../../util/config";

export const getUserReniec = ({ sDni }) => {
  return dispatch => {
    dispatch({ type: FETCH_START_RENIEC });
    axios
      .get(`/query-sunat-reniec/reniec/${sDni}`)
      .then(({ data }) => {
        if (data) {
          const reniecPatient = JSON.parse(
            crypt.decrypt(data.information, k, v)
          );
          dispatch({ type: FETCH_SUCCESS_RENIEC });
          dispatch({ type: USER_RENIEC_DATA, payload: reniecPatient });
        } else {
          dispatch({ type: FETCH_ERROR_RENIEC, payload: data.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR_RENIEC, payload: error.message });
      });
  };
};

export const getDataSunat = ({ sRuc }) => {
  return dispatch => {
    dispatch({ type: FETCH_START_SUNAT });
    axios
      .get(`/query-sunat-reniec/sunat/${sRuc}`)
      .then(({ data }) => {
        if (data.state === "Successful") {
          const information = JSON.parse(crypt.decrypt(data.information, k, v));
          dispatch({ type: FETCH_SUCCESS_SUNAT, payload: information });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR_SUNAT, payload: error.message });
      });
  };
};

export const rxGetChangeSunat = () => {
  return dispatch => {
    dispatch({ type: FETCH_CHANGE_TYPE_START });
    axios
      .post("/query-sunat-reniec/sunat/tipo-cambio")
      .then(({ data }) => {
        if (data.state === "Successful") {
          dispatch({ type: FETCH_CHANGE_TYPE_SUCCESS, payload: data.change });
        }
      })
      .catch(error => {
        dispatch({ type: FETCH_CHANGE_TYPE_ERROR, payload: error });
      });
  };
};

export const obtenerTipoCambio = ({fecha}) => {
  return dispatch => {
    dispatch({type: START_GET_TIPO_CAMBIO});
    axios.get(`/query-sunat-reniec/consultar-tipo-cambio/${fecha}`).then(({data}) => {
      const tipocambio = JSON.parse(crypt.decrypt(data.record, k, v));
      dispatch({type: SUCCESS_GET_TIPO_CAMBIO, payload: tipocambio});
    }).catch(function (error) {
      dispatch({type: ERROR_GET_TIPO_CAMBIO, payload: error.message});
    });
  };
};

export const resetInfoReniec = () => ({
  type: RESET_INFO_RENIEC
});

export const resetInitialStateSunat = () => ({
  type: RESET_INITIAL_STATE_SUNAT
});