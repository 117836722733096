import gql from "graphql-tag";

const ADD_USER = gql`
  mutation addUser(
    $name: String!
    $last_name: String!
    $email: String!
    $password: String!
    $user_type: UserTypes!
  ) {
    register(
      input: {
        name: $name
        last_name: $last_name
        email: $email
        password: $password
        user_type: $user_type
      }
    ) {
      status
      tokens {
        access_token
        user {
          email
          name
          last_name
        }
      }
    }
  }
`;

const TOGGLE_OPTIMIZER = gql`
  mutation toggleOptimizer($id: ID!, $input: UpdateOptimizerInput!) {
    toggleOptimizer(id: $id, input: $input) {
      id
      name
      email
      last_name
      avatar
      lat
      lng
      phone
      cellphone
      reference_address
      user_type
      document_type {
        id
      }
      document_number,
      email_verified_at
      birth_date
      sex
      address
      specialty {
        id
        parent {
          id
        }
      }
      ubigeo {
        id
        district
        department
        province
      }
      favorites {
        id
        project {
          id
        }
      }
      uuid
      optimizer
      experience_years
      experience_resume
      wizard_panel
    }
  }
`;

const TOGGLE_WIZARD_PANEL = gql`
  mutation toggleWizardPanel($id: ID!, $input: UpdateWizardPanelInput!) {
    toggleWizardPanel(id: $id, input: $input) {
      id
      name
      email
      last_name
      avatar
      lat
      lng
      phone
      cellphone
      reference_address
      user_type
      document_type {
        id
      }
      document_number
      email_verified_at
      birth_date
      sex
      address
      specialty {
        id
        parent {
          id
        }
      }
      ubigeo {
        id
        district
        department
        province
      }
      favorites {
        id
        project {
          id
        }
      }
      uuid
      optimizer
      experience_years
      experience_resume
      wizard_panel
    }
  }
`;

const UPDATE_USER = gql`
  mutation updateUser(
    $id: ID!
    $name: String
    $last_name: String
    $document_type_id: ID
    $document_number: String
    $phone: String
    $cellphone: String
    $birth_date: Date
    $sex: String
    $address: String
    $ubigeo_id: ID
    $email: String
    $specialty_id: ID
    $experience_years: Int
    $experience_resume: String
    $lat: String
    $lng: String
  ) {
    updateUser(
      input: {
        id: $id
        name: $name
        last_name: $last_name
        document_type_id: $document_type_id
        document_number: $document_number
        phone: $phone
        cellphone: $cellphone
        birth_date: $birth_date
        sex: $sex
        address: $address
        ubigeo_id: $ubigeo_id
        email: $email
        experience_years: $experience_years
        experience_resume: $experience_resume
        specialty_id: $specialty_id
        lat: $lat
        lng: $lng
      }
    ) {
      id
      name
      email
      last_name
      avatar
      lat
      lng
      phone
      cellphone
      reference_address
      user_type
      document_type {
        id
      }
      document_number
      email_verified_at
      birth_date
      sex
      address
      specialty {
        id
        parent {
          id
        }
      }
      ubigeo {
        id
        district
        department
        province
      }
      favorites {
        id
        project {
          id
        }
      }
      uuid
      optimizer
      experience_years
      experience_resume
      wizard_panel
    }
  }
`;

const UPDATE_USER_INFO = gql`
  mutation update_user_info($input: updateUserInput!) {
    updateUser(input: $input) {
      id
      name
      email
      last_name
      avatar
      lat
      lng
      phone
      cellphone
      reference_address
      user_type
      document_type {
        id
      }
      document_number
      email_verified_at
      birth_date
      sex
      address
      specialty {
        id
        parent {
          id
        }
      }
      ubigeo {
        id
        district
        department
        province
      }
      favorites {
        id
        project {
          id
        }
      }
      uuid
      optimizer
      experience_years
      experience_resume
      wizard_panel
    }
  }
`;

const UPDATE_USER_AVATAR = gql`
  mutation updateUserAvatar($id: ID!, $avatar: String) {
    updateUserAvatar(id: $id, avatar: $avatar) {
      avatar
    }
  }
`;

const MARK_LATEST_CONNECTION = gql`
  mutation markLatestConnection($id: ID!, $input: LatestConnectionInput!) {
    markLatestConnection(id: $id, input: $input) {
      latest_connection
    }
  }
`;

const UPDATE_USER_INFO_WIZARD = gql`
  mutation updateUserWizard($id: ID!, $input: UpdateUserInfoWizardInput!) {
    updateUserWizard(id: $id, input: $input) {
      reference_address
      cellphone
      lat
      lng
    }
  }
`;

export {ADD_USER, UPDATE_USER, UPDATE_USER_AVATAR, UPDATE_USER_INFO_WIZARD, MARK_LATEST_CONNECTION, UPDATE_USER_INFO, TOGGLE_OPTIMIZER, TOGGLE_WIZARD_PANEL};
