import React from "react";
import { Col, Row, Table, Input } from "antd";
import Auxiliary from "util/Auxiliary";
const { Search } = Input;
const dataSource = [];
const columns = [
  {
    title: "Nombres",
    dataIndex: "sNombres",
    key: "sNombres",
  },
  {
    title: "Apellidos",
    dataIndex: "sApellidos",
    key: "sApellidos",
  },
  {
    title: "Correo",
    dataIndex: "sCorreo",
    key: "sCorreo",
  },
  {
    title: "Telefono",
    dataIndex: "nTelefono",
    key: "nTelefono",
  },
  {
    title: "Telefono 2",
    dataIndex: "nTelefono2",
    key: "nTelefono2",
  },
  {
    title: "Fecha Registro",
    dataIndex: "dRegistro",
    key: "dRegistro",
  },
  {
    title: "Tipo Usuario",
    dataIndex: "sUsuario",
    key: "sUsuario",
  },
];
const UserTable = () => {
  return (
    <Auxiliary>
      <Row>
        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
          <Search
            placeholder="Buscar usuario"
            onSearch={(value) => console.log(value)}
          />
        </Col>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Table
            dataSource={dataSource}
            columns={columns}
            bordered
            size="middle"
            pagination={false}
          />
        </Col>
      </Row>
    </Auxiliary>
  );
};

export default UserTable;
