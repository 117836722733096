import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import URLSearchParams from "url-search-params";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { ConfigProvider } from "antd";
import { IntlProvider } from "react-intl";

import AppLocale from "lngProvider";
import MainApp from "./MainApp";
import moment from "moment-timezone";
import Planos from "../../routes/optimizer/Plans/Planos";

import cacheBuster from "./cacheBuster";

import "assets/vendors/fontawesome/css/all.min.css";
import "../../styles/wieldy.less";
import {
  // onLayoutTypeChange,
  // onNavStyleChange,
  setThemeType,
} from "appRedux/actions/Setting";

// import {
//   LAYOUT_TYPE_BOXED,
//   LAYOUT_TYPE_FRAMED,
//   LAYOUT_TYPE_FULL,
//   NAV_STYLE_ABOVE_HEADER,
//   NAV_STYLE_BELOW_HEADER,
//   NAV_STYLE_DARK_HORIZONTAL,
//   NAV_STYLE_DEFAULT_HORIZONTAL,
//   NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
// } from "../../constants/ThemeSetting";
import "assets/vendors/fontawesome/css/all.min.css";
import "../../styles/wieldy.less";

const RestrictedRoute = ({ component: Component, token, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        token ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const App = () => {
  global.moment = moment;
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const { locale, navStyle, layoutType } = useSelector(
    ({ settings }) => settings
  );
  const { token } = useSelector(({ auth }) => auth);

  /**
   * clear cache
   */
  useEffect(() => {
    cacheBuster();
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    // console.log("🚀 ~ file: index.js ~ line 79 ~ useEffect ~ params", params);
    /**
     * Delete
     */
    if (params.has("theme")) {
      dispatch(setThemeType(params.get("theme")));
    }
    // if (params.has("nav-style")) {
    //   dispatch(onNavStyleChange(params.get("nav-style")));
    // }
    // if (params.has("layout-type")) {
    //   dispatch(onLayoutTypeChange(params.get("layout-type")));
    // }
    // setLayoutType(layoutType);
    document.body.classList.add("full-scroll");
    // setNavStyle(navStyle);
  }, [navStyle, layoutType, location.search, dispatch]);

  /**
   * Delete
   */
  // const setLayoutType = (layoutType) => {
  //   if (layoutType === LAYOUT_TYPE_FULL) {
  //     // document.body.classList.remove("boxed-layout");
  //     // document.body.classList.remove("framed-layout");
  //     // document.body.classList.add("full-layout");
  //   }
  //   // else if (layoutType === LAYOUT_TYPE_BOXED) {
  //   //   document.body.classList.remove("full-layout");
  //   //   document.body.classList.remove("framed-layout");
  //   //   document.body.classList.add("boxed-layout");
  //   // } else if (layoutType === LAYOUT_TYPE_FRAMED) {
  //   //   document.body.classList.remove("boxed-layout");
  //   //   document.body.classList.remove("full-layout");
  //   //   document.body.classList.add("framed-layout");
  //   // }
  // };

  /**
   * Delete
   */
  // const setNavStyle = (navStyle) => {
  //   if (
  //     navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
  //     navStyle === NAV_STYLE_DARK_HORIZONTAL ||
  //     navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
  //     navStyle === NAV_STYLE_ABOVE_HEADER ||
  //     navStyle === NAV_STYLE_BELOW_HEADER
  //   ) {
  //     document.body.classList.add("full-scroll");
  //     // document.body.classList.add("horizontal-layout");
  //   }
  //   // else {
  //   //   document.body.classList.remove("full-scroll");
  //   //   document.body.classList.remove("horizontal-layout");
  //   // }
  // };

  useEffect(() => {
    if (location.pathname === "/login" || location.pathname === "/register") {
      if (token) {
        history.push("/");
      }
    }
    if (location.pathname === "/usuario/joined" && token) {
      const storageUser = localStorage.getItem("user");
      const skip = localStorage.getItem("skip_fill_info");
      const parsedUser = JSON.parse(storageUser);
      if (!parsedUser.wizard_panel || skip === "skip") {
        history.push("/");
      }
    }
  }, [location, history, token]);

  const currentAppLocale = AppLocale[locale.locale];

  return (
    <ConfigProvider locale={currentAppLocale.antd}>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <Switch>
          <RestrictedRoute
            path="/cms"
            token={token}
            component={(props) => <MainApp {...props} type="restricted-cms" />}
          />
          <RestrictedRoute
            path="/usuario"
            token={token}
            component={(props) => <MainApp {...props} type="restricted-user" />}
          />
          <Route
            path={`/optimizador/planos/:nIdProyecto`}
            exact
            component={Planos}
          />
          <Route
            path="/"
            component={(props) => <MainApp {...props} type="landing" />}
          />
        </Switch>
      </IntlProvider>
    </ConfigProvider>
  );
};

export default memo(App);
