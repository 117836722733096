import React, { Component } from "react";
import {
  Row,
  Col,
  Table,
  Button,
  Input,
  Form,
  DatePicker,
  Spin,
  Result,
} from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { hotkeys } from "react-keyboard-shortcuts";
import crypt from "node-cryptex";
import { k, v, localeTour } from "util/config";
import moment from "moment-timezone";
import { decrypt } from "node-cryptex";

import { formatDate } from "../../../util/helpers";
import api from "../../../util/Api";
import { ClickButton } from "../../../components/HotKeyButtons";
import { dateFormatList } from "../../../util/config";
import { schema } from "./schema";
import Joyride, { EVENTS } from "react-joyride";
import {
  filterProjects,
  onSelectProject,
  detalleProyecto,
  rxDuplicarProyectoCopia,
  changeOptimizerToken,
  createdOptimizerToken,
  changeFilterProject
} from "../../../appRedux/actions";

import AutoCompleteClient from "components/AutoCompleteClient";
import withColumnTableSearch from "../../../HOC/withColumnTableSearch";
import { FilterButton } from "../../../components/Button";
import TableTitle from "../../../components/TableTitle";
import { State } from "../../../components/Statet";
import carpenter from "assets/images/tour/hammer.png";
import { spinner } from "../../../util/customComponents";
import Seo from "../../../components/seo";
import OptimizerMobile from "../OptimizerMobile";

moment.tz("America/Lima");
const { RangePicker } = DatePicker;

class ProjectTable extends Component {
  static propTypes = {
    joyride: PropTypes.shape({
      callback: PropTypes.func,
    }),
  };

  static defaultProps = {
    joyride: {},
  };

  constructor(props) {
    super(props);
    this.columns = [
      {
        title: "#",
        dataIndex: "nIndex",
        key: "nIndex",
        align: "center",
        width: "5%",
        render: (value, row, index) => index + 1,
      },
      {
        title: "PROYECTO",
        dataIndex: "sProyecto",
        key: "sProyecto",
        ...this.props.getColumnSearchProps("sProyecto"),
      },
      {
        title: "CLIENTE",
        dataIndex: "sCliNombre",
        key: "sCliNombre",
        ...this.props.getColumnSearchProps("sCliNombre"),
      },
      {
        title: "REFERENCIA",
        dataIndex: "sObra",
        key: "sObra",
        ...this.props.getColumnSearchProps("sObra"),
      },
      {
        title: "FECHA",
        dataIndex: "dFechaRegistro",
        key: "dFechaRegistro",
        render: (value) => (value ? formatDate(value, "date") : "-"),
      },
      {
        title: "ESTADO",
        dataIndex: "sEstado",
        key: "sEstado",
        align: "center",
        render: (value) => <State text={value} letter={false} />,
      },
      {
        title: "ITEMS",
        dataIndex: "nTotalItems",
        key: "nTotalItems",
        align: "center",
      },
      {
        title: "PZAS",
        dataIndex: "nTotalCortes",
        key: "nTotalCortes",
        align: "center",
      },
      {
        title: "ML",
        dataIndex: "nTotalCantos",
        key: "nTotalCantos",
        align: "center",
      },
      {
        title: "SUCURSAL",
        dataIndex: "sDenominacion",
        key: "sDenominacion",
      },
      {
        title: "PEDIDO",
        dataIndex: "sPedido",
        key: "sPedido",
        ...this.props.getColumnSearchProps("sPedido"),
      },
      {
        title: "FEC.PEDIDO",
        dataIndex: "dPedido",
        key: "dPedido",
        render: (value) => (value ? formatDate(value, "date") : "-"),
      },
      {
        title: "FACTURA",
        dataIndex: "sFactura",
        key: "sFactura",
        ...this.props.getColumnSearchProps("sFactura"),
      },
      {
        title: "FEC. FACTURA",
        dataIndex: "dFactura",
        key: "dFactura",
        render: (value) => (value ? formatDate(value, "date") : "-"),
      },
      {
        title: "USUARIO",
        dataIndex: "sUserCreated",
        key: "sUserCreated",
      },
    ];
    this.state = {
      availableService: true,
      connecting: true,
      selectedProyect: null,
      next: "¡Empecemos!",
      back: (
        <span>
          <i className="far fa-long-arrow-left gx-mr-2" />
          Atrás
        </span>
      ),
      steps: [
        {
          content: (
            <div className="gx-text-center">
              <Row>
                <Col span={8}>
                  <img
                    src={carpenter}
                    width={140}
                    alt="Optimizador"
                    className="gx-float-left custom-image-tour"
                  />
                </Col>
                <Col span={16} className="gx-text-left">
                  <h2 className="gx-text-white gx-font-weight-semi-bold">
                    Hola, {this.props.authUser.name}
                  </h2>
                  <p className="gx-mb-0">
                    Bienvenido al centro de ayuda para usuarios. A continuación
                    te explicaré la página inicial de tu optimizador.
                  </p>
                </Col>
              </Row>
            </div>
          ),
          styles: {
            options: {
              width: 500,
            },
          },
          placement: "center",
          target: "body",
        },
        {
          content: (
            <div className="gx-text-left">
              <h2 className="gx-text-white gx-font-weight-semi-bold">
                Lista de Proyectos
              </h2>
              <p className="gx-mb-0">
                En esta tabla podrás visualizar tus proyectos registrados.
              </p>
            </div>
          ),
          styles: {
            options: {
              arrowColor: "#EE8C69",
              primaryColor: "#ED7D64",
              width: 400,
              overlayColor: "transparent",
            },
          },
          target: ".tour-table",
          //target: 'tr.tour-row-0 td:first-child',
        },
        {
          content: (
            <div className="gx-text-left">
              <h2 className="gx-text-white gx-font-weight-semi-bold">
                Accesibilidad
              </h2>
              <p className="gx-mb-0">
                Puedes acceder a tu proyecto haciendo doble clic en el registro
                deseado.
              </p>
            </div>
          ),
          styles: {
            options: {
              arrowColor: "#EE8C69",
              primaryColor: "#ED7D64",
              width: 400,
              overlayColor: "rgba(255, 255, 255, 0.8)",
            },
          },
          target: ".tour-row-0",
        },
        {
          content: (
            <div className="gx-text-left">
              <h2 className="gx-text-white gx-font-weight-semi-bold">
                Opciones Adicionales de Proyecto
              </h2>
              <p className="gx-mb-0">
                Para acceder a más opciones del proyecto, haga click derecho
                sobre el registro. Inténtelo en el proyecto N° 1
              </p>
            </div>
          ),
          styles: {
            options: {
              arrowColor: "#EE8C69",
              primaryColor: "#ED7D64",
              width: 400,
              overlayColor: "transparent",
            },
          },
          spotlightClicks: true,
          target: ".tour-row-0",
        },
        {
          content: (
            <div className="gx-text-left">
              <h2 className="gx-text-white gx-font-weight-semi-bold">
                Selección de Proyecto
              </h2>
              <p className="gx-mb-0">
                Al momento de seleccionar un proyecto podrás acceder a los
                botones de acción.
              </p>
            </div>
          ),
          styles: {
            options: {
              arrowColor: "#EEAA73",
              primaryColor: "#ED7D64",
              width: 400,
              overlayColor: "rgba(255, 255, 255, 0.8)",
            },
          },
          spotlightClicks: true,
          target: "tr.tour-row-0 td:first-child",
        },
        {
          content: (
            <div className="gx-text-left">
              <h2 className="gx-text-white gx-font-weight-semi-bold">
                Botón de Acciones
              </h2>
              <p className="gx-mb-0">
                En este grupo de botones podrás ver los planos y resultados de
                tu proyecto de manera directa, previa selección del proyecto.
              </p>
            </div>
          ),
          styles: {
            options: {
              arrowColor: "#EE8C69",
              primaryColor: "#ED7D64",
              width: 400,
              overlayColor: "transparent",
            },
          },
          target: ".show-results",
        },
        {
          content: (
            <div className="gx-text-left">
              <h2 className="gx-text-white gx-font-weight-semi-bold">
                Barra de búsqueda y filtros
              </h2>
              <p className="gx-mb-0">
                Para buscar proyectos en específicos utilice esta barra de
                filtro y encuentre sus registros por cliente, código de proyecto
                y rango de fechas.
              </p>
            </div>
          ),
          styles: {
            options: {
              arrowColor: "#EE8C69",
              primaryColor: "#ED7D64",
              width: 400,
              overlayColor: "transparent",
            },
          },
          target: ".tour-form",
        },
        {
          content: (
            <div className="gx-text-left">
              <h2 className="gx-text-white">Botón de Registro</h2>
              <p>
                Para agregar un nuevo registro haga clic en este botón o
                presione la tecla F7
              </p>
            </div>
          ),
          styles: {
            options: {
              primaryColor: "#ED7D64",
              width: 400,
              overlayColor: "transparent",
            },
          },
          target: ".new-button",
        },
      ],
      run: localStorage.getItem('display_tour_optimizer') === 'true',
    };
    window.onclick = this.hideContextMenu;
    window.onkeydown = this.listenKeys;
    this.refContextMenu = React.createRef();
  }

  async componentWillMount() {
    const optimizerID = this.props.optimizerID;
    if (!optimizerID) {
      this.setState({ availableService: false });
      const info = new FormData();
      info.set("uuid", this.props.authUser.uuid);
      api
        .post("/system/create-optimizer-token", info)
        .then(({ data }) => {
          localStorage.setItem("optimizer_token", data.token);
          localStorage.setItem("optimizer_id", data.nIdUsuario);
          api.defaults.headers.common["Authorization"] = `Bearer ${decrypt(
            data.token,
            k,
            v
          )}`;
          this.props.createdOptimizerToken(data.token || null, data.nIdUsuario);
          this.setState({ availableService: true, connecting: false });
        })
        .catch(() => {
          this.setState({ availableService: false, connecting: false });
        });
    } else {
      const {project, client, from, to} = this.props.filterOptimizer
      this.handleFilterProyects({sProyecto: project, nIdCliente: client, dFechas: [moment(from), moment(to)]});
    }
  }

  /**
   * BTN NUEVO PROJECTO
   */
  handleNewPatient = () => {
    const urlPath = this.props.pathname;
    this.props.history.push(`${urlPath}/add`);
  };

  /**
   * FILTRAR PROJECTOS
   */
  handleOnSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.handleFilterProyects(values);
      }
    });
  };

  handleChangeDates = e => {
    this.props.changeFilterProject({field: 'from', value: moment(e[0]).format('YYYY/MM/DD')})
    this.props.changeFilterProject({field: 'to', value: moment(e[1]).format('YYYY/MM/DD')})
  }

  handleFilterProyects = ({
    sProyecto = null,
    nIdCliente = null,
    dFechas = [],
  }) => {
    const filterProyects = {
      nIdUsuario: this.props.optimizerID,
      sProyecto: sProyecto ? sProyecto : null,
      nIdCliente: nIdCliente ? nIdCliente : null,
      dDesde: dFechas[0]
        ? dFechas[0].format(dateFormatList[1])
        : moment().format(dateFormatList[1]),
      dHasta: dFechas[1]
        ? dFechas[1].format(dateFormatList[1])
        : moment().format(dateFormatList[1]),
    };
    this.props.filterProjects(filterProyects);
    this.setState({ connecting: false });
  };

  /**
   * INIT CONTEXT MENU
   */
  showContextMenu = (row, rowIndex, event) => {
    this.refContextMenu.current.style.display = "block";
    this.refContextMenu.current.style.left = event.clientX + "px";
    this.refContextMenu.current.style.top = event.clientY + "px";
    this.props.onSelectProject(row);
    return false;
  };

  hideContextMenu = () => {
    if (this.refContextMenu.current)
      this.refContextMenu.current.style.display = "none";
  };

  listenKeys = (event) => {
    let keyCode = event.which || event.keyCode;
    if (keyCode === 27) this.hideContextMenu();
  };

  /**
   * DOUBLECLIKC=>DETAIL PROJECT
   */
  showDetailProyect = (row, rowIndex = null, event = null) => {
    const { nIdProyecto } = row;
    this.props.history.push(
      `/usuario/optimizador/edit/${crypt.encrypt(
        JSON.stringify(nIdProyecto),
        k,
        v
      )}`
    );
  };

  handleOnDuplicate = () => {
    const {
      headerProject,
      authSucursal,
      authUser,
      rxDuplicarProyectoCopia,
    } = this.props;
    const form = new FormData();
    authSucursal && form.set("nIdSucursal", authSucursal.nIdSucursal);
    headerProject && form.set("nIdProyectoOrig", headerProject.nIdProyecto);
    authUser && form.set("sUser", authUser.sUsuario);
    rxDuplicarProyectoCopia(form, this.handleFilterProyects);
  };

  InitTour = (e) => {
    e.preventDefault();
    this.setState({ run: true });
  };

  handleJoyrideCallback = (data) => {
    const { joyride } = this.props;
    const { type, index } = data;

    if (index === 0) {
      this.setState({ next: "¡Empecemos!" });
    } else if (index > 0 && index <= this.state.steps.length - 1) {
      this.setState({
        next: (
          <span>
            Siguiente
            <i className="far fa-long-arrow-right gx-ml-2" />
          </span>
        ),
      });
    }

    if (type === EVENTS.TOUR_END && this.state.run) {
      this.setState({ run: false });
      localStorage.setItem('display_tour_optimizer', false);
    }

    if (typeof joyride.callback === "function") {
      joyride.callback(data);
    }
  };

  showPlans = () => {
    const { selectedProyect: nIdProyecto } = this.state;
    if (nIdProyecto !== null) {
      window.open(
        `/optimizador/planos/${crypt.encrypt(
          JSON.stringify(nIdProyecto),
          k,
          v
        )}`,
        "_blank"
      );
    }
  };

  render() {
    const {
      headerProject,
      doublingProject,
      filterOptimizer
    } = this.props;
    const {projects, filtering} = filterOptimizer;
    const { getFieldDecorator } = this.props.form;
    /**
     * CONFIG SELECT ROW
     */

    const rowSelection = {
      onChange: (rowKeys, row) => {
        this.props.onSelectProject(row[0]);
        const nIdProyecto = row[0].nIdProyecto;
        this.setState({ selectedProyect: nIdProyecto });
      },
      hideDefaultSelections: true,
      selectedRowKeys: [headerProject && headerProject.nIdProyecto],
      type: "radio",
      selections: false,
      getCheckboxProps: (record) => ({
        disabled: record.name === "Disabled User",
        name: record.name,
      }),
    };

    const guideButton = () => (
      <Button size="small" className="gx-mb-0 gx-ml-1" onClick={this.InitTour}>
        <i className="fa fa-book-reader gx-mr-2"/>
        Mostrar Guía
      </Button>
    );

    if (this.state.connecting)
      return (
        <Spin
          indicator={spinner}
          spinning={this.state.connecting}
          style={{ width: "100%" }}
        />
      );

    return this.state.availableService ? (
      <div>
        <Seo
          title="Optimizador"
          description="Programa para optimizar cortes de melamina gratis"
          url={window.location.href}
        />
        <Joyride
          continuous
          scrollToFirstStep
          showSkipButton
          run={this.state.run}
          steps={this.state.steps}
          locale={{
            ...localeTour,
            next: this.state.next,
            back: this.state.back,
          }}
          floaterProps={{
            styles: {
              background: "linear-gradient(to right, #fdc830, #f37335)",
            },
          }}
          styles={{
            options: {
              arrowColor: "#ED7D64",
              overlayColor: "rgba(255, 255, 255, 0.8)",
              primaryColor: "#ED7D64",
              textColor: "#fff",
              width: 900,
              zIndex: 1000,
            },
          }}
          disableOverlayClose={true}
          disableCloseOnEsc={true}
          callback={this.handleJoyrideCallback}
        />
        <Spin tip="Duplicando Proyecto" spinning={doublingProject}>
          <div className="gx-container-main-card gx-d-none gx-d-lg-block gx-p-4">
            <Row className="gx-container-header-table gx-mb-4">
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={12}
                className="gx-d-inline-flex gx-align-items-center"
              >
                <Form className="gx-form-row0 gx-mb-0 tour-form" onSubmit={this.handleOnSubmit}>
                  <Row
                    gutter={8}
                    className="gx-d-inline-flex gx-align-items-center"
                  >
                    <Col xs={24} sm={24} md={8} lg={8}>
                      {getFieldDecorator(schema.nIdCliente.name, {
                        initialValue: filterOptimizer.client,
                        rules: [
                          {
                            required: schema.nIdCliente.rule.required,
                            message: "Campo requerido",
                          },
                        ],
                      })(
                        <AutoCompleteClient
                          placeHolder={schema.nIdCliente.placeHolder}
                          name={schema.nIdCliente.name}
                          required={schema.nIdCliente.rule.required}
                          message={schema.nIdCliente.rule.message}
                          formItem={false}
                          form={false}
                          handleOnSelect={e => this.props.changeFilterProject({field: 'client', value: e})}
                          optionLabelProp="children"
                        />
                      )}
                    </Col>
                    <Col xs={24} sm={24} md={4} lg={4}>
                      {getFieldDecorator(schema.sProyecto.name, {
                        initialValue: this.props.filterOptimizer.project,
                        rules: [
                          {
                            required: schema.sProyecto.rule.required,
                            message: "Campo requerido",
                          },
                        ],
                      })(
                        <Input
                          prefix={
                            <i
                              className="far fa-hashtag"
                              style={{ color: "rgba(0,0,0,.25)" }}
                            />
                          }
                          placeholder={schema.sProyecto.placeHolder}
                          onChange={e => this.props.changeFilterProject({field: 'project', value: e.target.value})}
                        />
                      )}
                    </Col>
                    <Col xs={24} sm={24} md={9} lg={9}>
                      {getFieldDecorator(
                        schema.dFechas.name,
                        {
                          initialValue: [
                            moment(this.props.filterOptimizer.from, dateFormatList[1]),
                            moment(this.props.filterOptimizer.to, dateFormatList[1]),
                          ],
                        },
                        {
                          rules: [
                            {
                              required: schema.dFechas.rule.required,
                              message: "Campo requerido",
                            },
                          ],
                        }
                      )(
                        <RangePicker
                          format={dateFormatList[0]}
                          ranges={{
                            Hoy: [moment, moment],
                            "Este Mes": [
                              moment().startOf("Month"),
                              moment().endOf("Month"),
                            ],
                          }}
                          style={{ width: "100%" }}
                          onChange={this.handleChangeDates}
                        />
                      )}
                    </Col>
                    <Col xs={24} sm={24} md={3} lg={3}>
                      <FilterButton
                        text="Filtrar"
                        loading={filtering}
                        htmlType="submit"
                      />
                    </Col>
                  </Row>
                </Form>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={12}
                className="gx-d-flex gx-align-items-center gx-justify-content-end"
              >
                <Button.Group className="show-results gx-ml-2">
                  <Button
                    className="gx-mb-0"
                    onClick={this.showPlans}
                    disabled={!this.state.selectedProyect}
                  >
                    <i className="far fa-file-spreadsheet gx-pr-1" />
                    Ver Planos
                  </Button>
                  <Button className="gx-mb-0">
                    <i className="far fa-eye gx-pr-1" />
                    Ver Resultado
                  </Button>
                </Button.Group>
                <ClickButton
                  button={{
                    title: "Nuevo (F7)",
                    icon: <i className="fal fa-plus gx-pr-1" />,
                    action: this.handleNewPatient,
                    type: "primary",
                    className: "gx-mb-0",
                  }}
                  className="new-button gx-mb-0"
                  shortcut="f7"
                />
              </Col>
            </Row>

            <div
              onContextMenu={(e) => e.preventDefault()}
              className="gx-table-responsive"
            >
              <Table
                size="middle"
                className="tour-table gx-bg-white"
                bordered
                title={() => (
                  <TableTitle
                    title="OPTIMIZADOR: LISTA PROYECTOS"
                    tooltip={true}
                    popoverContent="Click derecho sobre la fila para mostrar más opciones"
                    GuideButton={guideButton}
                  />
                )}
                columns={this.columns}
                dataSource={projects}
                pagination={false}
                loading={filtering}
                rowKey={(row) => row.nIdProyecto}
                rowSelection={rowSelection}
                rowClassName={(record, index) =>
                  headerProject &&
                  headerProject.nIdProyecto === record.nIdProyecto
                    ? `gx-outline-record gx-pointer tour-row-${index}`
                    : `gx-pointer tour-row-${index}`
                }
                onRow={(record, rowIndex) => {
                  return {
                    onContextMenu: (event) => {
                      this.showContextMenu(record, rowIndex, event);
                    },
                    onDoubleClick: (event) => {
                      this.showDetailProyect(record, rowIndex, event);
                    },
                  };
                }}
              />
            </div>
          </div>
          <div className="gx-d-block gx-d-lg-none">
            <OptimizerMobile details={this.showDetailProyect}/>
          </div>
        </Spin>
        <div className="context-menu" ref={this.refContextMenu}>
          <ul>
            <li>
              <i className="far fa-print gx-pr-1" /> Imprimir
            </li>
            <li>
              <i className="far fa-file-spreadsheet gx-pr-1" /> Ver Planos
            </li>
            <li>
              <i className="far fa-envelope-open-text gx-pr-1" /> Enviar CORREO
            </li>
            <li>
              <i className="far fa-tasks gx-pr-1" /> Seguimiento
            </li>
            <li>
              <i className="far fa-print gx-pr-1" /> Imp. Etiquetas
            </li>
            <li>
              <i className="far fa-eye gx-pr-1" /> Ver. Resultado
            </li>
            <li
              onClick={() => {
                this.handleOnDuplicate();
              }}
            >
              <i className="far fa-copy gx-pr-1" /> Duplicar Proyecto
            </li>
          </ul>
        </div>
      </div>
    ) : (
      <Result
        status="500"
        title="Optimizador no disponible"
        subTitle="El servicio no se encuentra disponible, por favor intente en unos minutos."
        extra={
          <Button type="primary" onClick={() => this.props.history.push("/")}>
            <i className="far fa-home gx-mr-2" />
            Ir a inicio
          </Button>
        }
      />
    );
  }
}

const WrappedProjectTable = Form.create({ name: "project_table" })(
  ProjectTable
);
const mapStateToProps = ({ auth, common, settings, projects }) => {
  const { pathname } = settings;
  const { loading } = common;
  const {
    selectRoles,
    permissions,
    authSucursal,
    authUser,
    optimizerID,
  } = auth;
  const {
    headerProject,
    doublingProject,
    filterOptimizer
  } = projects;
  return {
    pathname,
    selectRoles,
    loading,
    permissions,
    authSucursal,
    authUser,
    headerProject,
    doublingProject,
    optimizerID,
    filterOptimizer,
  };
};

export default connect(mapStateToProps, {
  filterProjects,
  onSelectProject,
  detalleProyecto,
  changeOptimizerToken,
  rxDuplicarProyectoCopia,
  createdOptimizerToken,
  changeFilterProject
})(hotkeys(withColumnTableSearch(withRouter(WrappedProjectTable))));
