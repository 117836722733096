import React, { Fragment } from "react";
import Slider from "react-slick";
import ShopsItemCard from "./ShopsItemCard";
import CustomScrollbars from "util/CustomScrollbars";
import { Spin } from "antd";
import { EmpytCard } from "../EmptyCard";

const description = "No hay tiendas cerca";
const options = {
  className: "gx-map-carrousel",
  dots: true,
  infinite: true,
  speed: 500,
  marginLeft: 10,
  marginRight: 10,
  slidesToShow: 2,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1199,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
  ],
};

const Shop = ({
  isMobile = false,
  dataSource,
  loading,
  handleMarkerClick,
  handleMarkerClose,
}) => {
  return (
    <Fragment>
      <Spin spinning={loading}>
        {isMobile ? (
          <Fragment>
            {Array.isArray(dataSource) && dataSource.length > 0 ? (
              <Slider {...options}>
                {dataSource.map((data, index) => (
                  <div key={index} className="gx-p-3">
                    <ShopsItemCard data={data} />
                  </div>
                ))}
              </Slider>
            ) : (
              <EmpytCard description={description} />
            )}
          </Fragment>
        ) : (
          <CustomScrollbars className="gx-module-side-scroll">
            {Array.isArray(dataSource) && dataSource.length > 0 ? (
              dataSource.map((data, index) => (
                <ShopsItemCard
                  key={index}
                  data={data}
                  handleMarkerClick={handleMarkerClick}
                  handleMarkerClose={handleMarkerClose}
                />
              ))
            ) : (
              <EmpytCard description={description} />
            )}
          </CustomScrollbars>
        )}
      </Spin>
    </Fragment>
  );
};

export default Shop;
