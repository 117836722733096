// Customizer const
export const TOGGLE_COLLAPSED_NAV = "TOGGLE_COLLAPSE_MENU";
export const WINDOW_WIDTH = "WINDOW-WIDTH";
export const SWITCH_LANGUAGE = "SWITCH-LANGUAGE";

//Contact Module const
export const FETCH_START = "fetch_start";
export const FETCH_SUCCESS = "fetch_success";
export const FETCH_ERROR = "fetch_error";
export const SHOW_MESSAGE = "SHOW_MESSAGE";
export const HIDE_MESSAGE = "HIDE_MESSAGE";
export const ON_SHOW_LOADER = "ON_SHOW_LOADER";
export const ON_HIDE_LOADER = "ON_HIDE_LOADER";

//Auth const
export const SIGNUP_USER = "SIGNUP_USER";
export const SIGNUP_USER_SUCCESS = "SIGNUP_USER_SUCCESS";
export const SIGNIN_GOOGLE_USER = "SIGNIN_GOOGLE_USER";
export const SIGNIN_GOOGLE_USER_SUCCESS = "SIGNIN_GOOGLE_USER_SUCCESS";
export const SIGNIN_FACEBOOK_USER = "SIGNIN_FACEBOOK_USER";
export const SIGNIN_FACEBOOK_USER_SUCCESS = "SIGNIN_FACEBOOK_USER_SUCCESS";
export const SIGNIN_TWITTER_USER = "SIGNIN_TWITTER_USER";
export const SIGNIN_TWITTER_USER_SUCCESS = "SIGNIN_TWITTER_USER_SUCCESS";
export const SIGNIN_GITHUB_USER = "SIGNIN_GITHUB_USER";
export const SIGNIN_GITHUB_USER_SUCCESS = "signin_github_user_success";
export const SIGNIN_USER = "SIGNIN_USER";
export const SIGNIN_USER_SUCCESS = "SIGNIN_USER_SUCCESS";
export const SIGNOUT_USER = "SIGNOUT_USER";
export const SIGNOUT_USER_SUCCESS = "SIGNOUT_USER_SUCCESS";
export const INIT_URL = "INIT_URL";
export const LOADING_INFO_USER = "LOADING_INFO_USER";

//Sticky
export const GET_STICKY = "GET_STICKY";
export const NOTES_UPDATE = "NOTES_UPDATE";
export const FETCH_ALL_NOTES_SUCCESS = "FETCH_ALL_NOTES_SUCCESS";
export const UPDATE_ALL_NOTES_SUCCESS = "UPDATE_ALL_NOTES_SUCCESS";

//Contact
export const GET_All_CONTACT_SUCCESS = "GET_All_CONTACT_SUCCESS";
export const ON_ADD_CONTACT_SUCCESS = "ON_ADD_CONTACT_SUCCESS";
export const UPDATE_CONTACT_SUCCESS = "UPDATE_CONTACT_SUCCESS";
export const DELETE_CONTACT_SUCCESS = "DELETE_CONTACT_SUCCESS";

// Projet
export const TOGGLE_MODAL_PROJECT = "TOGGLE_MODAL_PROJECT";
export const SET_USER_PROJECTS = "SET_USER_PROJECTS";
export const ADD_USER_PROJECT = "ADD_USER_PROJECT";
export const SEARCH_PROJECTS = "SEARCH_PROJECTS";
export const QUERIED_TAGS = "QUERIED_TAGS";
export const CHANGE_VARIABLES = "CHANGE_VARIABLES";

//Shops
export const TOGGLE_MODAL_SHOPS = "TOGGLE_MODAL_SHOPS";
export const SET_LIST_SHOP = "SET_LIST_SHOP";
export const DELETE_SHOP_TABLE = "DELETE_SHOP_TABLE";

// Clients
export const CLIENTS_LIST = "CLIENTS_LIST";
export const ADVERTISEMENTS_LIST = "ADVERTISEMENTS_LIST";
export const TOGGLE_MODAL_ADVERTISEMENT = "TOGGLE_MODAL_ADVERTISEMENT";

//User
export const TOGGLE_MODAL_UPDATE_PASSWORD = "TOGGLE_MODAL_UPDATE_PASSWORD";
export const MARK_LATEST_CONNECTION = "MARK_LATEST_CONNECTION";
export const UPDATE_WIZARD_USER_INFO = "UPDATE_WIZARD_USER_INFO";
export const UPDATE_USER_STORAGE = "UPDATE_USER_STORAGE";
export const UPDATE_USER_STORAGE_WITH_TOKEN = "UPDATE_USER_STORAGE_WITH_TOKEN";
export const SKIP_FILL_INFO = "SKIP_FILL_INFO";

// Projects optimizer
export const FETCH_BOARD_LIST_START = "FETCH_BOARD_LIST_START";
export const FETCH_BOARD_LIST_SUCCESS = "FETCH_BOARD_LIST_SUCCESS";
export const FETCH_BOARD_LIST_ERROR = "FETCH_BOARD_LIST_ERROR";

export const FETCH_COTIZA_PROJECT_START="FETCH_COTIZA_PROJECT_START";
export const FETCH_COTIZA_PROJECT_SUCCESS="FETCH_COTIZA_PROJECT_SUCCESS";
export const FETCH_COTIZA_PROJECT_ERROR="FETCH_COTIZA_PROJECT_ERROR";

export const FETCH_COTIZA_PROJECT_INSERT_START="FETCH_COTIZA_PROJECT_INSERT_START";
export const FETCH_COTIZA_PROJECT_INSERT_SUCCESS="FETCH_COTIZA_PROJECT_INSERT_SUCCESS";
export const FETCH_COTIZA_PROJECT_INSERT_ERROR="FETCH_COTIZA_PROJECT_INSERT_ERROR";

export const FETCH_DUPLICATE_PROJECT_START="FETCH_DUPLICATE_PROJECT_START";
export const FETCH_DUPLICATE_PROJECT_SUCCESS="FETCH_DUPLICATE_PROJECT_SUCCESS";
export const FETCH_DUPLICATE_PROJECT_ERROR="FETCH_DUPLICATE_PROJECT_ERROR";

export const FETCH_EDGES_LIST_START = "FETCH_EDGES_LIST_START";
export const FETCH_EDGES_LIST_SUCCESS = "FETCH_EDGES_LIST_SUCCESS";
export const FETCH_EDGES_LIST_ERROR = "FETCH_EDGES_LIST_ERROR";

export const FETCH_GROOVE_LIST_D25_START = "FETCH_GROOVE_LIST_D25_START";
export const FETCH_GROOVE_LIST_D25_SUCCESS = "FETCH_GROOVE_LIST_D25_SUCCESS";
export const FETCH_GROOVE_LIST_D25_ERROR = "FETCH_GROOVE_LIST_D25_ERROR";

export const FETCH_GROOVE_LIST_START = "FETCH_GROOVE_LIST_START";
export const FETCH_GROOVE_LIST_SUCCESS = "FETCH_GROOVE_LIST_SUCCESS";
export const FETCH_GROOVE_LIST_ERROR = "FETCH_GROOVE_LIST_ERROR";

export const FETCH_LABELS_PROJECT_LIST_START="FETCH_LABELS_PROJECT_LIST_START";
export const FETCH_LABELS_PROJECT_LIST_SUCCESS="FETCH_LABELS_PROJECT_LIST_SUCCESS";
export const FETCH_LABELS_PROJECT_LIST_ERROR="FETCH_LABELS_PROJECT_LIST_ERROR";

export const FETCH_MATERIAL_SAMMARY_START="FETCH_MATERIAL_SAMMARY_START";
export const FETCH_MATERIAL_SAMMARY_SUCCESS="FETCH_MATERIAL_SAMMARY_SUCCESS";
export const FETCH_MATERIAL_SAMMARY_ERROR="FETCH_MATERIAL_SAMMARY_ERROR";

export const FETCH_OPTIMIZE_DATA_START = "FETCH_OPTIMIZE_DATA_START";
export const FETCH_OPTIMIZE_DATA_SUCCESS = "FETCH_OPTIMIZE_DATA_SUCCESS";
export const FETCH_OPTIMIZE_DATA_ERROR = "FETCH_OPTIMIZE_DATA_ERROR";

export const FETCH_PERFORATION_LIST_D25_START = "FETCH_PERFORATION_LIST_D25_START";
export const FETCH_PERFORATION_LIST_D25_SUCCESS = "FETCH_PERFORATION_LIST_D25_SUCCESS";
export const FETCH_PERFORATION_LIST_D25_ERROR = "FETCH_PERFORATION_LIST_D25_ERROR";

export const FETCH_PERFORATION_LIST_START = "FETCH_PERFORATION_LIST_START";
export const FETCH_PERFORATION_LIST_SUCCESS = "FETCH_PERFORATION_LIST_SUCCESS";
export const FETCH_PERFORATION_LIST_ERROR = "FETCH_PERFORATION_LIST_ERROR";

export const FETCH_PLANS_DATA_START = "FETCH_PLANS_DATA_START";
export const FETCH_PLANS_DATA_SUCCESS = "FETCH_PLANS_DATA_SUCCESS";
export const FETCH_PLANS_DATA_ERROR = "FETCH_PLANS_DATA_ERROR";

export const FETCH_PROJECTS_ADD_EDIT_START = "FETCH_PROJECTS_ADD_EDIT_START";
export const FETCH_PROJECTS_ADD_EDIT_SUCCESS = "FETCH_PROJECTS_ADD_EDIT_SUCCESS";
export const FETCH_PROJECTS_ADD_EDIT_ERROR = "FETCH_PROJECTS_ADD_EDIT_ERROR";

export const FETCH_PROJECTS_DETAILS_START = "FETCH_PROJECTS_DETAILS_START";
export const FETCH_PROJECTS_DETAILS_SUCCESS = "FETCH_PROJECTS_DETAILS_SUCCESS";
export const FETCH_PROJECTS_DETAILS_ERROR = "FETCH_PROJECTS_DETAILS_ERROR";

export const FETCH_PROJECTS_LIST_START = "FETCH_PROJECTS_LIST_START";
export const FETCH_PROJECTS_LIST_SUCCESS = "FETCH_PROJECTS_LIST_SUCCESS";
export const FETCH_PROJECTS_LIST_ERROR = "FETCH_PROJECTS_LIST_ERROR";

export const FETCH_SELECT_TRACING_PROJECT_START="FETCH_SELECT_TRACING_PROJECT_START"
export const FETCH_SELECT_TRACING_PROJECT_SUCCESS="FETCH_SELECT_TRACING_PROJECT_SUCCESS"
export const FETCH_SELECT_TRACING_PROJECT_ERROR="FETCH_SELECT_TRACING_PROJECT_ERROR"

export const FETCH_UPDATE_STATE_PROJECT_START = "FETCH_UPDATE_STATE_PROJECT_START";
export const FETCH_UPDATE_STATE_PROJECT_SUCCESS = "FETCH_UPDATE_STATE_PROJECT_SUCCESS";
export const FETCH_UPDATE_STATE_PROJECT_ERROR = "FETCH_UPDATE_STATE_PROJECT_ERROR";

export const RESET_TABLEPROYECT_DETAIL = "RESET_TABLEPROYECT_DETAIL";
export const SELECT_HIDE_SHOW_PROJECT = "SELECT_HIDE_SHOW_PROJECT";
export const SELECT_TABLE_IN_PROJECT_DETAIL = "SELECT_TABLE_IN_PROJECT_DETAIL";

export const SHOW_HIDE_MODAL_CANTOS = "SHOW_HIDE_MODAL_CANTOS";
export const TOGGLE_MODAL_GROOVE = "TOGGLE_MODAL_GROOVE";
export const TOGGLE_MODAL_GROOVE_D25 = "TOGGLE_MODAL_GROOVE_D25";
export const TOGGLE_MODAL_PERFORATION_D25 = "TOGGLE_MODAL_PERFORATION_D25";
export const TOGGLE_MODAL_MATERIAL = "TOGGLE_MODAL_MATERIAL";
export const TOGGLE_MODAL_PERFORATION = "TOGGLE_MODAL_PERFORATION";
export const UPDATE_DATA_DETAIL_PROJECT = "UPDATE_DATA_DETAIL_PROJECT";

export const FETCH_CLIENTS = "FETCH_CLIENTS";
export const FETCH_SEARCH_PATIENT_START = "FETCH_SEARCH_PATIENT_START";
export const FETCH_SEARCH_PATIENT_SUCCESS = "FETCH_SEARCH_PATIENT_SUCCESS";
export const FETCH_SEARCH_PATIENT_ERROR = "FETCH_SEARCH_PATIENT_ERROR";

export const CHANGE_OPTIMIZER_TOKEN = "CHANGE_OPTIMIZER_TOKEN";

export const START_GET_TIPO_CAMBIO = "START_GET_TIPO_CAMBIO";
export const SUCCESS_GET_TIPO_CAMBIO = "SUCCESS_GET_TIPO_CAMBIO";
export const ERROR_GET_TIPO_CAMBIO = "ERROR_GET_TIPO_CAMBIO";

export const FETCH_CHANGE_TYPE_START = "FETCH_CHANGE_TYPE_START";
export const FETCH_CHANGE_TYPE_SUCCESS = "FETCH_CHANGE_TYPE_SUCCESS";
export const FETCH_CHANGE_TYPE_ERROR = "FETCH_CHANGE_TYPE_ERROR";

export const FETCH_START_RENIEC = "FETCH_START_RENIEC";
export const FETCH_SUCCESS_RENIEC = "FETCH_SUCCESS_RENIEC";
export const FETCH_ERROR_RENIEC = "FETCH_ERROR_RENIEC";

export const FETCH_START_SUNAT = "FETCH_START_SUNAT";
export const FETCH_SUCCESS_SUNAT = "FETCH_SUCCESS_SUNAT";
export const FETCH_ERROR_SUNAT = "FETCH_ERROR_SUNAT";

export const RESET_INFO_RENIEC = "RESET_INFO_RENIEC";
export const RESET_INITIAL_STATE_SUNAT = "RESET_INITIAL_STATE_SUNAT";
export const USER_RENIEC_DATA = "USER_RENIEC_DATA";

export const CLEAN_LOCATION_LIST = "CLEAN_LOCATION_LIST";

export const FETCH_START_LOCATION = "FETCH_START_LOCATION";
export const FETCH_SUCCESS_LOCATION = "FETCH_SUCCESS_LOCATION";
export const FETCH_ERROR_LOCATION = "FETCH_ERROR_LOCATION";

export const FETCH_START_CLIENT = "FETCH_START_CLIENT";
export const FETCH_SUCCESS_CLIENT = "FETCH_SUCCESS_CLIENT";
export const FETCH_ERROR_CLIENT = "FETCH_ERROR_CLIENT";

export const START_SAVING_CLIENTS = "START_SAVING_CLIENTS";

export const SAVE_CLIENTS = "SAVE_CLIENTS";
export const UPDATE_CLIENTS = "UPDATE_CLIENTS";
export const DELETE_CLIENTS = "DELETE_CLIENTS";

export const SUCCESS_SAVING_CLIENTS = "SUCCESS_SAVING_CLIENTS";
export const ERROR_SAVING_CLIENTS = "ERROR_SAVING_CLIENTS";

export const UPDATE_ADDITIONALS = "UPDATE_ADDITIONALS";
export const DELETE_ADDITIONALS = "DELETE_ADDITIONALS";
export const SAVE_ADDITIONALS = "SAVE_ADDITIONALS";

export const FETCH_ADDITIONALS = "FETCH_ADDITIONALS";
export const SHOW_MODAL_CONFIG_ADDITIONALS = "SHOW_MODAL_CONFIG_ADDITIONALS";

export const FETCH_START_DELETING_ADDITIONALS = "FETCH_START_DELETING_ADDITIONALS";
export const FETCH_SUCCESS_DELETING_ADDITIONALS = "FETCH_SUCCESS_DELETING_ADDITIONALS";
export const FETCH_ERROR_DELETING_ADDITIONALS = "FETCH_ERROR_DELETING_ADDITIONALS";

export const START_SAVING_ADDITIONALS = "START_SAVING_ADDITIONALS";
export const FETCH_SUCCESS_SAVING_ADDITIONALS = "FETCH_SUCCESS_SAVING_ADDITIONALS";
export const FETCH_ERROR_SAVING_ADDITIONALS = "FETCH_ERROR_SAVING_ADDITIONALS";

export const START_GENERAL_ENTITIES = "START_GENERAL_ENTITIES";
export const SUCCESS_GENERAL_ENTITIES = "SUCCESS_GENERAL_ENTITIES";
export const ERROR_GENERAL_ENTITIES = "ERROR_GENERAL_ENTITIES";

export const START_SAVING_GENERALS = "START_SAVING_GENERALS";
export const FETCH_GENERALS = "FETCH_GENERALS";
export const UPDATE_GENERALS = "UPDATE_GENERALS";
export const SAVE_GENERALS = "SAVE_GENERALS";
export const DELETE_GENERALS = "DELETE_GENERALS";
export const FETCH_SUCCESS_SAVING_GENERALS = "FETCH_SUCCESS_SAVING_GENERALS";
export const FETCH_ERROR_SAVING_GENERALS = "FETCH_ERROR_SAVING_GENERALS";
export const FETCH_START_DELETING_GENERALS = "FETCH_START_DELETING_GENERALS";
export const FETCH_SUCCESS_DELETING_GENERALS = "FETCH_SUCCESS_DELETING_GENERALS";
export const FETCH_ERROR_DELETING_GENERALS = "FETCH_ERROR_DELETING_GENERALS";
export const SHOW_MODAL_GENERAL = "SHOW_MODAL_GENERAL";
export const FETCH_GENERALS_BY_ENTITY = "FETCH_GENERALS_BY_ENTITY";

export const OBTENER_INFORMACION_ADICONAL_USUARIO_START = "OBTENER_INFORMACION_ADICONAL_USUARIO_START";
export const OBTENER_INFORMACION_ADICONAL_USUARIO_SUCCESS = "OBTENER_INFORMACION_ADICONAL_USUARIO_SUCCESS";
export const OBTENER_INFORMACION_ADICONAL_USUARIO_ERROR = "OBTENER_INFORMACION_ADICONAL_USUARIO_ERROR";

export const START_GET_INFO_CLIENT = "START_GET_INFO_CLIENT";
export const SUCCESS_GET_INFO_CLIENT = "SUCCESS_GET_INFO_CLIENT";
export const ERROR_GET_INFO_CLIENT = "ERROR_GET_INFO_CLIENT";

export const CLEAR_RETRIEVE_INFO_CLIENTE = "CLEAR_RETRIVE_INFO_CLIENTE";
export const UPDATE_SELECTED_CLIENTE = "UPDATE_SELECTED_CLIENTE";

export const TOGGLE_WIZARD_USER = "TOGGLE_WIZARD_USER";
export const CREATED_OPTIMIZER_TOKEN = "CREATED_OPTIMIZER_TOKEN";

export const HANDLE_RESET_FILTER_PROJECT = "HANDLE_RESET_FILTER_PROJECT";
export const RX_TOGGLE_MODAL_TABLEROS = "RX_TOGGLE_MODAL_TABLEROS";
export const RX_SET_DATA_LOYEND_TABLE_BOARDS = "RX_SET_DATA_LOYEND_TABLE_BOARDS";

export const START_FILTERING_OPTIMIZER = "START_FILTERING_OPTIMIZER";
export const SUCCESS_FITLERING_OPTIMIZER = "SUCCESS_FITLERING_OPTIMIZER";
export const ERROR_FITLERING_OPTIMIZER = "ERROR_FITLERING_OPTIMIZER";
export const CHANGE_VALUE_FILTER_OPTIMIZER = "CHANGE_VALUE_FILTER_OPTIMIZER";
export const CLEAR_FILTER_OPTIMIZER = "CLEAR_FILTER_OPTIMIZER";