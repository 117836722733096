import React from "react";
import { Empty } from "antd";
import empty from "assets/images/empty.svg";

export const EmpytCard = ({
  image = empty,
  height = 60,
  description,
  button,
}) => {
  return (
    <Empty
      image={image}
      imageStyle={{
        height: height,
        margin: "auto",
      }}
      description={description ? description : ""}
      style={{ padding: "1rem auto" }}
    >
      {button ? button : null}
    </Empty>
  );
};
