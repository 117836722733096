import axios from "../../util/Api";
import crypt from "node-cryptex";
import { k, v } from "../../util/config";
import { message } from "antd";
import {
  FETCH_START,
  FETCH_ERROR,
  FETCH_SUCCESS,
  FETCH_GENERALS,
  UPDATE_GENERALS,
  DELETE_GENERALS,
  SAVE_GENERALS,
  START_SAVING_GENERALS,
  FETCH_SUCCESS_SAVING_GENERALS,
  FETCH_ERROR_SAVING_GENERALS,
  FETCH_START_DELETING_GENERALS,
  FETCH_SUCCESS_DELETING_GENERALS,
  FETCH_ERROR_DELETING_GENERALS,
  FETCH_GENERALS_BY_ENTITY,
  SHOW_MODAL_GENERAL,
  START_GENERAL_ENTITIES,
  SUCCESS_GENERAL_ENTITIES,
  ERROR_GENERAL_ENTITIES
} from "constants/ActionTypes";

export const getGenerals = () => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    axios
      .get("/system/general")
      .then(({ data }) => {
        const decryptedRecords = JSON.parse(crypt.decrypt(data.record, k, v));
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: FETCH_GENERALS, payload: decryptedRecords });
      })
      .catch(error => {
        message.error(error.message);
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const getGeneralEntities = ({ entities }) => {
  return dispatch => {
    dispatch({ type: START_GENERAL_ENTITIES });
    const encryptedEntities = crypt.encrypt(JSON.stringify(entities), k, v);
    axios.get(`/system/general/custom-list/${encryptedEntities}`).then(({ data }) => {
      const decrypted = JSON.parse(crypt.decrypt(data.records, k, v));
      dispatch({ type: SUCCESS_GENERAL_ENTITIES, payload: decrypted });
    }).catch(error => {
      dispatch({type: ERROR_GENERAL_ENTITIES});
      message.error(error.message);
    });
  };
};

export const getGeneralsByEntity = ({ sEntidad }) => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    axios
      .get(`/system/general/${sEntidad}/list`)
      .then(({ data }) => {
        const decryptedRecords = JSON.parse(crypt.decrypt(data.record, k, v));
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: FETCH_GENERALS_BY_ENTITY, payload: decryptedRecords });
      })
      .catch(error => {
        message.error(error.message);
        dispatch({ type: FETCH_GENERALS_BY_ENTITY, payload: [] });
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const saveGenerals = (
  {
    sEntidad,
    sCodigo,
    sDescripcion,
    nOrden,
    sEquivSunat,
    sValorOpc = 0,
    bEstado
  },
  form,
  id = null
) => {
  return dispatch => {
    dispatch({ type: START_SAVING_GENERALS });

    const info = new FormData();
    info.set("sEntidad", sEntidad);
    info.set("sCodigo", sCodigo);
    info.set("sDescripcion", sDescripcion);
    info.set("nOrden", nOrden);
    info.set("sEquivSunat", sEquivSunat);
    info.set("sValorOpc", sValorOpc);
    info.set("bEstado", bEstado);

    const url = id ? `/${id}` : "";
    const method = id ? "PATCH" : "POST";
    axios({ url: `/system/general${url}`, data: info, method })
      .then(({ data }) => {
        if (data.state === "Successful") {
          const decrypted = JSON.parse(crypt.decrypt(data.record, k, v));
          id
            ? dispatch({ type: UPDATE_GENERALS, payload: decrypted })
            : dispatch({ type: SAVE_GENERALS, payload: decrypted });
          dispatch({ type: FETCH_SUCCESS_SAVING_GENERALS, payload: decrypted });
          message.success(data.message);
          form.resetFields();
        } else {
          message.error(data.message);
        }
      })
      .catch(error => {
        message.error(error.message);
        dispatch({ type: FETCH_ERROR_SAVING_GENERALS, payload: error.message });
      });
  };
};

export const deleteGenerals = id => {
  return dispatch => {
    dispatch({ type: FETCH_START_DELETING_GENERALS, payload: id });
    axios
      .delete(`/system/general/${id}`)
      .then(({ data }) => {
        if (data.state === "Successful") {
          dispatch({ type: DELETE_GENERALS, payload: id });
          dispatch({
            type: FETCH_SUCCESS_DELETING_GENERALS,
            payload: data.message
          });
          message.success(data.message);
        } else {
          message.error(data.message);
        }
      })
      .catch(error => {
        message.error(error.message);
        dispatch({
          type: FETCH_ERROR_DELETING_GENERALS,
          payload: error.message
        });
      });
  };
};

export const showModalGenerals = payload => {
  return dispatch => {
    dispatch({ type: SHOW_MODAL_GENERAL, payload });
  };
};
