import React, { Fragment } from "react";
import { Descriptions } from "antd";
import { formatDate } from "util/helpers";
import PropTypes from "prop-types";

const Header = props => {
  const { dHeader } = props;
  return (
    <Fragment>
      <Descriptions className="gx-d-none gx-d-lg-block gx-mb-2" size="small" column={3}>
        <Descriptions.Item label="PROYECTO">
          <span className='gx-link'> {(dHeader && dHeader.sProyecto) || "-"}</span>
        </Descriptions.Item>
        <Descriptions.Item label="CLIENTE">
          <span className='gx-link'>{(dHeader && dHeader.sCliente) || "-"}</span>
        </Descriptions.Item>
        <Descriptions.Item label="FECHA">
          <span className='gx-link'>{(dHeader && formatDate(dHeader.dFechaRegistro, "date")) || "-"}</span>
        </Descriptions.Item>
        <Descriptions.Item label="OBRA">
          <span className='gx-link'>{(dHeader && dHeader.sObra) || "-"}</span>
        </Descriptions.Item>
        <Descriptions.Item label="VENDEDOR">
          <span className='gx-link'>{(dHeader && dHeader.sVendedor) || "-"}</span>
        </Descriptions.Item>
      </Descriptions>
      <Descriptions className="gx-d-block gx-d-lg-none gx-mb-2" size="small" column={1}>
        <Descriptions.Item label="PROYECTO">
          <span className='gx-link'> {(dHeader && dHeader.sProyecto) || "-"}</span>
        </Descriptions.Item>
        <Descriptions.Item label="CLIENTE">
          <span className='gx-link'>{(dHeader && dHeader.sCliente) || "-"}</span>
        </Descriptions.Item>
        <Descriptions.Item label="FECHA">
          <span className='gx-link'>{(dHeader && formatDate(dHeader.dFechaRegistro, "date")) || "-"}</span>
        </Descriptions.Item>
        <Descriptions.Item label="OBRA">
          <span className='gx-link'>{(dHeader && dHeader.sObra) || "-"}</span>
        </Descriptions.Item>
        <Descriptions.Item label="VENDEDOR">
          <span className='gx-link'>{(dHeader && dHeader.sVendedor) || "-"}</span>
        </Descriptions.Item>
      </Descriptions>
    </Fragment>
  );
};
Header.propTypes = {
  dHeader: PropTypes.object
};

export default Header;
