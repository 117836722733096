import React, { useEffect } from "react";
import { Collapse, Row, Col } from "antd";
import { topPage } from "../../util/helpers";
import Seo from "../../components/seo";

const { Panel } = Collapse;

const PrivacyPolicies = () => {
  useEffect(() => topPage());

  return (
    <Row gutter={16} className="gx-mb-4">
      <Seo
        title="Política de Privacidad de Datos"
        url={window.location.href}
        description={`En esta Política, se describe la información que tratamos para
        respaldar a eCarpintero, así como otros productos y funciones que
        ofrece AmazonTIC.`}
        keywords="Política de Privacidad"
      />
      <Col span={24}>
        <Row gutter={16} className="gx-justify-content-center">
          <Col md={12}>
            <h2 className="gx-font-weight-semi-bold">
              Política de Privacidad de Datos
            </h2>
            <p className="gx-text-justify">
              En esta Política, se describe la información que tratamos para
              respaldar a eCarpintero, así como otros productos y funciones que
              ofrece AmazonTIC.
            </p>
            <h3 className="gx-mt-4">¿Qué tipo de información recopilamos?</h3>
            <p>
              A fin de proporcionarte los Productos de eCarpintero debemos
              tratar información sobre ti. El tipo de información que
              recopilamos depende de la forma en la que usas nuestros Productos.
              Lo que tú y otras personas hacen y proporcionan.
            </p>
            <div className="gx-full-width">
              <Collapse expandIconPosition="right" defaultActiveKey={[0, 1, 2]}>
                <Panel key={0} header="Información y Contenido Proporcionado">
                  <p>
                    Recopilamos el contenidode tus proyectos, las comunicaciones
                    y otros datos que proporcionas cuando usas nuestros
                    Productos. Por ejemplo, cuando te registras para crear una
                    cuenta, creas o compartes contenidode proyectos, y te
                    contactas con otras personas. Esto puede incluir información
                    en el contenido, o sobre él, que proporcionas, por ejemplo,
                    la ubicación de tu taller, materiales usados. Nuestros
                    sistemas tratan automáticamente el contenido y las
                    comunicaciones que tú y otras personas proporcionan para
                    analizar el contexto y lo que incluyen en relación con los
                    propósitos que se describena continuación.
                  </p>
                </Panel>
                <Panel key={1} header="Redes y Conexión">
                  <p>
                    Recopilamos información sobre las personas a los que estás
                    conectado y cómo interactúas con ellos a través de nuestros
                    Productos, como las personas con las que más te comunicas o
                    los grupos de los que formas parte.
                  </p>
                </Panel>
                <Panel key={2} header="Tu Uso">
                  <p>
                    Recopilamos información sobre cómo usas nuestros Productos,
                    como los tipos de contenido que ves o con los que
                    interactúas, las funciones que utilizas, las acciones que
                    llevas a cabo, las personas o cuentas con las que
                    interactúas, y la hora, la frecuencia y la duración de tus
                    actividades.
                  </p>
                  <p>
                    La información que obtenemos de estos dispositivos incluye:
                  </p>
                  <Collapse
                    expandIconPosition="right"
                    defaultActiveKey={[0, 1, 2, 3]}
                  >
                    <Panel header="Atributos del Dispositivo">
                      <p>
                        Información como el sistema operativo, las versiones de
                        hardware y software, el nivel de carga de la batería, la
                        potencia de la señal, el tipo de navegador.
                      </p>
                    </Panel>
                    <Panel header="Datos de la Configuración del Dispositivo">
                      <p>
                        Información que nos permites recibir mediante la
                        configuración que activas en tu dispositivo, como el
                        acceso a la ubicación de GPS, la cámara o las fotos.
                      </p>
                    </Panel>
                    <Panel header="Red y Conexión">
                      <p>
                        Información, como el nombre del operador de telefonía
                        celular o proveedor de internet, el idioma, la zona
                        horaria, el número de teléfono celular, la direcciónIP,
                        la velocidad de la conexión
                      </p>
                    </Panel>
                    <Panel header="Datos de Cookies">
                      <p>
                        Datos provenientes de las cookies almacenadas en tu
                        dispositivo, incluidos la configuración y los
                        identificadores de cookies.
                      </p>
                    </Panel>
                  </Collapse>
                </Panel>
              </Collapse>
            </div>
            <h3 className="gx-mt-4">¿Cómo usamos esta información?</h3>
            <p>
              Usamos la información que tenemos (en función de las elecciones
              que hagas) como se describe a continuación y para proporcionar y
              respaldar los Productos de eCarpintero y los servicios
              relacionados descritos en los Términos de Servicios. Así es como
              lo hacemos:
            </p>
            <p>
              Proporcionamos, personalizamos y mejoramos nuestros Productos.
            </p>
            <p>
              Usamos la información que tenemos para proporcionar nuestros
              Productos, así comopara personalizar las funciones y el contenidoy
              hacerte sugerencias (como productos y serviciosque pueden
              interesarte) tanto dentro como fuera de nuestros Productos. Con el
              objetivo de crear Productos personalizados que sean únicos y
              relevantes para ti, usamos tus preferencias, intereses y
              actividades en función de los datos que recopilamos y que tú nos
              proporcionan,así como la forma en la que usas nuestros Productos e
              interactúas con ellos, y las personas, los lugares o las cosas con
              los que te conectas y que te interesan, tanto dentro como fuera de
              nuestros Productos.
            </p>
            <Collapse
              expandIconPosition="right"
              defaultActiveKey={[0, 1, 2, 3]}
            >
              <Panel
                key={0}
                header="Información de los Dispositivos y Productos de eCarpintero"
              >
                <p>
                  Vinculamos la información sobre tus actividades en diferentes
                  dispositivos y Productos de eCarpinteropara proporcionar una
                  experiencia más personalizada y uniforme en todosellos, donde
                  sea que los uses.
                </p>
              </Panel>
              <Panel key={1} header="Información Relacionada con la Ubicación">
                <p>
                  Usamos lainformación relacionada con la ubicación, como tu
                  ubicación actual, el taller donde trabajas, así como las
                  personas y las empresas que se encuentran cerca de ti, para
                  proporcionar, personalizar y mejorar nuestros
                  Productos,incluidos los anuncios, a fin de que resulten más
                  relevantes para ti y otras personas. La información
                  relacionada con la ubicación puede basarse en factores como la
                  ubicación exacta del dispositivo (si nos permitiste recopilar
                  esta información), direccionesIP e información sobre el uso
                  que tú y otras personas hacen de los Productos de eCarpintero.
                </p>
              </Panel>
              <Panel key={2} header="Investigación y Desarrollo de Productos">
                <p>
                  Usamos la información que tenemos para desarrollar, probar y
                  mejorar nuestros Productos, incluido por medio de encuestas e
                  investigaciones, y para pruebas y solución de problemas de
                  funciones y productos nuevos.
                </p>
              </Panel>
              <Panel key={3} header="Anuncios y Otro Contenido Publicitario">
                <p>
                  Usamos la información que tenemos sobre ti, incluida
                  información sobre tus intereses, acciones y conexiones, para
                  seleccionar y personalizar anuncios, ofertas y otro contenido
                  publicitario que te mostramos.
                </p>
              </Panel>
            </Collapse>
            <p className="gx-mt-3">
              Ofrecemos mediciones, análisis y otros servicios empresariales.
            </p>
            <p>
              Usamos la información que tenemos (incluida la actividad que
              realizas fuera de nuestros Productos, tal como los sitios web que
              visitas y los anuncios que ves) para ayudar a los anunciantes y
              otros socios a medir la eficacia y distribución de sus anuncios y
              servicios, así como a entender qué tipo de personas usan sus
              servicios y cómo estasinteractúan con sus sitios web, apps y
              servicios.
            </p>
            <p>Fomentamos la seguridad, la integridad yla protección.</p>
            <p>
              Usamos la información que tenemos para verificar cuentas y
              actividades, combatir conductas perjudiciales, detectar y prevenir
              spam y otras experiencias negativas, conservar la integridad de
              nuestros Productos y fomentar la seguridad tanto dentro como
              fuerade los Productos de eCarpintero.
            </p>
            <p>Nos comunicamos contigo.</p>
            <p>
              Usamos la información que tenemos para enviarte mensajes de
              marketing, comunicarnos contigo sobre nuestros Productos e
              informarte acerca de nuestras políticas y condiciones. También
              usamos tu información para responderte cuando te pones en contacto
              con nosotros.
            </p>
            <p>
              Realizamos investigaciones e innovamos en pro del bienestar
              social.
            </p>
            <h3 className="gx-mt-4">¿Cómo se comparte esta información?</h3>
            <p>
              Tu información se comparte con otros de las siguientes formas:
            </p>
            <p>En Productos de eCarpintero.</p>
            <p>
              Personas y cuentas con las que te comunicas y compartes
              información.
            </p>
            <p>
              Cuando te comunicas y compartes información usando nuestros
              Productos,aceptas al público que busca especialistas con para un
              determinado proyecto.
            </p>
            <p>
              <strong>
                Cualquier persona puede ver la información pública
              </strong>
              , dentro de nuestros Productos, aunque no tenga una cuenta. Esto
              incluye tu nombre de usuario de eCarpintero, la información que
              compartes con el público. Tú, otras personas que usan eCarpintero
              y nosotros podemos conceder acceso a información pública o enviar
              dicha información a cualquier persona, tanto dentro como fuera de
              nuestros Productos, incluido en otros Productos de las empresas de
              AmazonTIC, en resultados de búsqueda o por medio de herramientas y
              API. También es posible usar servicios de terceros, como motores
              de búsqueda, API y medios no relacionados con internet (como la
              televisión), así como apps, sitios web y otros servicios que se
              integran con nuestros Productos para acceder a información pública
              o verla, volver a compartirla odescargarla
            </p>
            <p>
              Las personas también pueden usar nuestros Productos para crear y
              compartir contenido sobre ti con el público que elijan. Por
              ejemplo, pueden compartir una foto de tu proyecto en algún
              servicio de terceros o red social.Si no te sientes cómodo con el
              contenido que otras personas compartieron sobre ti en nuestros
              Productos, obtén información sobre cómoreportarlo.
            </p>
            <p>
              Información sobre tu estado activo o presencia en nuestros
              Productos.
            </p>
            <p>
              Hay señales que indican a las personas que forman parte de tus
              redes si estás activo en nuestros Productos, incluido si estás
              activo en ese momento eneCarpintero o cuándo fue la última vez que
              usaste nuestros Productos.
            </p>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default PrivacyPolicies;
