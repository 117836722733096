import React, { memo, Fragment, useRef, useState, useEffect } from "react";
import "./index.less";
// import MailNotification from "components/MailNotification";
import { NavLink, Link, useHistory } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { useDispatch, useSelector } from "react-redux";
import {
  Popover,
  Button,
  Icon,
  message,
  Divider,
  Avatar,
  Spin,
  Tag,
  Select,
} from "antd";

import { ReactComponent as LogoSvg } from "assets/images/eCarpintero-logo.svg";
import { ReactComponent as CmsIcon } from "assets/icons/cms.svg";
import { ReactComponent as AccountIcon } from "assets/icons/account.svg";
import { ReactComponent as LogoutIcon } from "assets/icons/logout.svg";

import { LOGOUT_MUTATION } from "../../../graphql/mutation/auth";
import { spinner } from "util/customComponents";
import { queriedTags, searchProjects } from "../../../appRedux/actions";
import { SEARCH_PUBLIC_PROJECTS } from "../../../graphql/query/project";
import { GET_TAGS } from "../../../graphql/query/master";

import { userSignOut } from "appRedux/actions/Auth";

const { OptGroup, Option } = Select;

const iconStyle = {
  width: "18px",
};

const HorizontalDefault = () => {
  const [isSticky, setSticky] = useState(window.pageYOffset > 0);
  const ref = useRef(null);
  const handleScroll = () => {
    if (ref.current) {
      const doc = document.documentElement;
      const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
      setSticky(top > 0);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", () => handleScroll);
    };
  }, []);

  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = history.location;
  const { token, authUser, loader } = useSelector(({ auth }) => auth);
  const { filterProjects, queryTags } = useSelector(({ project }) => project);

  useEffect(() => {
    if (pathname !== "/search") {
      dispatch(
        searchProjects({
          loading: false,
          data: [],
          variables: { query: null, page: 1 },
        })
      );
      dispatch(queriedTags([]));
    }
  }, [pathname, dispatch]);

  const [filteredTags, setFilteredTags] = useState([]);

  const [logout, { loading }] = useMutation(LOGOUT_MUTATION, {
    onCompleted: () => {
      localStorage.removeItem("token");
      localStorage.removeItem("optimizer_token");
      localStorage.removeItem("optimizer_id");
      localStorage.removeItem("skip_fill_info");
      localStorage.removeItem("skip-percent-panel");
      localStorage.removeItem("skip_wizard_forms");
      localStorage.removeItem("user");
      dispatch(userSignOut());
      message.success("Su sesión ha finalizado");
    },
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ extensions }) => {
          message.warn(extensions.reason);
        });
      }
      if (networkError) {
        message.error(`[Error de Red]: ${networkError}`);
      }
    },
  });

  const goCMS = () => {
    history.push("/cms");
  };

  const handleFocus = () => {
    if (pathname !== "/search") {
      history.push("/search");
    }
  };

  const [searchPublicProjects] = useLazyQuery(SEARCH_PUBLIC_PROJECTS, {
    fetchPolicy: "network-only",
    onCompleted({ searchProject }) {
      const records = searchProject.data;
      const paginator = searchProject.paginatorInfo;
      dispatch(
        searchProjects({
          loading: false,
          data: filterProjects.data.concat(records),
          hasMore: paginator.hasMorePages,
        })
      );
    },
    onError() {},
  });

  const [getTags, { loading: loadingTags }] = useLazyQuery(GET_TAGS, {
    fetchPolicy: "network-only",
    onCompleted({ masters }) {
      setFilteredTags(masters);
    },
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        graphQLErrors.map((error) => message.error(error.message));
      }
      if (networkError) {
        message.error(`[Error de Red]: ${networkError}`);
      }
    },
  });

  let timer = 0;

  const searchTag = async (value) => {
    if (value) {
      await clearInterval(timer);
      timer = await setTimeout(() => {
        const variables = {
          where: {
            column: "ENTITY",
            operator: "EQ",
            value: "ETIQUETA",
            AND: [
              {
                column: "DESCRIPTION",
                operator: "LIKE",
                value: `%${value}%`,
              },
            ],
          },
        };
        getTags({ variables });
      }, 300);
    }
  };

  const changeSelectedTags = (values) => {
    dispatch(queriedTags(values));
    if (values.length > 0) {
      let query = "";
      values.map((item) => (query += ` ${item}`));
      const variables = {
        query,
        page: 1,
      };
      dispatch(searchProjects({ loading: true, variables }));
      searchPublicProjects({ variables });
    } else {
      setFilteredTags([]);
      dispatch(
        searchProjects({
          loading: false,
          data: [],
          variables: { query: null, page: 1 },
        })
      );
    }
  };
  const HOST = process.env.REACT_APP_OPTIMIZER_HOST;
  const userMenuOptions = authUser && (
    <ul className="gx-user-popover">
      <li
        style={{ width: 250 }}
        className="gx-d-inline-flex gx-align-items-center"
      >
        {authUser.name}
        {/* <span className="gx-ml-auto">
          <i className="far fa-external-link"/>
        </span> */}
      </li>
      <Divider className="gx-mt-1 gx-mb-1" />
      <li style={{ width: 250 }}>
        <NavLink
          to="/usuario/perfil"
          className="gx-text-default"
          activeClassName="gx-selected-mobile-nav"
        >
          <i className="far fa-id-card-alt gx-mr-2" style={iconStyle} />
          Mi cuenta
        </NavLink>
      </li>
      {authUser.user_type === "CARPINTERO" && (
        <Fragment>
          <li style={{ width: 250 }}>
            <NavLink
              to="/usuario/proyectos"
              className="gx-text-default"
              activeClassName="gx-selected-mobile-nav"
            >
              <i className="far fa-briefcase gx-mr-2" style={iconStyle} />
              Mis Proyectos
            </NavLink>
          </li>
          <li style={{ width: 250 }}>
            <a
              href={HOST + "/login?t=cd6feade5c3e5893d647d144116d74f4"}
              rel="noopener noreferrer"
              target="_blank"
              className="gx-text-default gx-d-inline-flex gx-align-items-center gx-full-width"
            >
              <i className="far fa-pencil-ruler gx-mr-2" style={iconStyle} />
              Optimizador
              <Tag color="#f50" size="small" className="gx-ml-auto gx-mb-0">
                <i className="fad fa-flame gx-mr-1" />
                Nuevo
              </Tag>
            </a>
          </li>
        </Fragment>
      )}
      <Divider className="gx-mt-1 gx-mb-1" />
      <li onClick={!loading ? () => logout() : null} style={{ width: 250 }}>
        {loading ? (
          <Icon type="loading" className="gx-mr-2" style={iconStyle} />
        ) : (
          <i
            className="far fa-power-off gx-mr-2 gx-text-danger"
            style={iconStyle}
          />
        )}
        <span className="gx-text-danger">Salir</span>
      </li>
    </ul>
  );

  return (
    <div
      className={`gx-header-horizontal ${isSticky ? "gx-sticky" : ""}`}
      ref={ref}
    >
      <div className="gx-header-horizontal-main">
        <div className="gx-container">
          <div
            className="first-header"
            style={{
              fontFamily: "Open Sans",
              borderBottom: "1px solid rgba(191, 191, 191, 1)",
            }}
          >
            <div>
              <p className="gx-text-primary">OPTIMIZANDO TU NEGOCIO</p>
              <p className="header__separador gx-text-black">|</p>
              <p style={{ color: "#000000" }}>CONECTÁNDOTE CON EL MERCADO</p>
            </div>
            <div>
              <p>
                <Link
                  to="/mapas/tiendas-carpinteros-cercanos"
                  className="gx-text-black gx-font-weight-bold"
                >
                  TIENDAS
                </Link>
              </p>
              <p>
                <Link
                  to="/contactanos"
                  className="gx-text-black gx-font-weight-bold"
                >
                  CONTÁCTANOS
                </Link>
              </p>
            </div>
          </div>

          <div className="gx-header-desktop-container">
            <div
              className="gx-item-header-desk-logo-group"
              style={{ zIndex: 100 }}
            >
              <Link
                to="/"
                className="gx-d-block gx-d-lg-none gx-pointer gx-w-logo"
              >
                <LogoSvg
                  className="gx-logo-svg"
                  title="logo"
                  style={{ width: 133 }}
                />
              </Link>

              <Link
                to="/"
                className="gx-d-none gx-d-lg-block gx-pointer gx-mr-xs-5 gx-logo"
              >
                <LogoSvg
                  title="logo"
                  className="gx-logo-svg"
                  style={{ width: "100%" }}
                />
              </Link>
            </div>

            <div className="content-header-menu">
              <div className="gx-header-notifications header-menu">
                {/*<li className={`gx-notify gx-notify-search gx-d-none gx-d-sm-flex gx-d-lg-none gx-mr-0 ${pathname.startsWith('/usuario/optimizador') && 'active-nav-link'}`}>
                  <NavLink to="/usuario/optimizador">
                    <Button className="gx-mb-0 gx-mr-0 heading-link-color" type="link">
                      <i className="fal fa-pencil-ruler gx-mr-2" />
                      Optimizador
                    </Button>
                  </NavLink>
                </li>*/}
                <div className="gx-notify gx-notify-search gx-d-lg-block gx-d-none gx-mr-0">
                  <Link to="/">
                    <span className="gx-mb-0 gx-mr-0 heading-link-color">
                      INICIO
                    </span>
                  </Link>
                </div>
                <div className="gx-notify gx-notify-search gx-d-lg-block gx-d-none gx-mr-0">
                  <Link to="/buscar-especialista">
                    <span className="gx-mb-0 heading-link-color">
                      ESPECIALISTAS
                    </span>
                  </Link>
                </div>
                <div className="gx-notify gx-notify-search gx-d-lg-block gx-d-none gx-mr-0">
                  <Link to="/proyectos-general/todos">
                    <span className="gx-mb-0 heading-link-color">
                      PROYECTOS
                    </span>
                  </Link>
                </div>
                {loader && spinner}
                {token && authUser && (
                  <Fragment>
                    {authUser.user_type === "ADMINISTRADOR" && (
                      <li className="gx-user-nav gx-mr-0" onClick={goCMS}>
                        <span className="gx-pointer gx-d-flex gx-mr-0 gx-menu-item-ec">
                          <CmsIcon />
                        </span>
                      </li>
                    )}
                    <li className="gx-user-nav gx-mr-0">
                      <Popover
                        overlayClassName="gx-popover-horizantal"
                        placement="bottomRight"
                        content={userMenuOptions}
                      >
                        <div className="gx-align-items-center gx-d-none gx-d-lg-flex">
                          {authUser.avatar ? (
                            <Avatar
                              src={authUser.avatar}
                              className="gx-avatar gx-d-lg-flex gx-d-none gx-ml-3 gx-pointer"
                              alt={authUser.name}
                            />
                          ) : (
                            <span className="gx-pointer gx-d-lg-flex gx-d-none gx-menu-item-ec">
                              <AccountIcon />
                            </span>
                          )}
                        </div>
                      </Popover>
                      <span
                        className="gx-pointer gx-d-sm-flex gx-d-none gx-d-lg-none gx-menu-item-ec"
                        onClick={() => logout()}
                      >
                        {loading ? <Icon type="loading" /> : <LogoutIcon />}
                      </span>
                    </li>
                  </Fragment>
                )}
              </div>
            </div>

            <div className="gx-item-header-desk-search-group gx-ml-3">
              {pathname !== "/search" ? (
                <div onClick={handleFocus} className="gx-full-width">
                  <Select
                    mode="multiple"
                    className="gx-custom-filter gx-d-lg-block gx-d-none"
                    open={false}
                    placeholder={
                      <p style={{ fontWeight: 200, color: "black" }}>
                        Buscar proyectos...
                      </p>
                    }
                  />
                </div>
              ) : (
                <Select
                  mode="multiple"
                  allowClear
                  value={queryTags}
                  className="gx-custom-filter gx-d-lg-block gx-d-none gx-full-width"
                  onChange={changeSelectedTags}
                  onSearch={searchTag}
                  filterOption={false}
                  autoFocus={pathname === "/search"}
                  placeholder={
                    <p style={{ fontWeight: 200, color: "black" }}>
                      Buscar proyectos...
                    </p>
                  }
                  notFoundContent={
                    loadingTags ? (
                      <Spin size="small" indicator={spinner} />
                    ) : null
                  }
                  onBlur={() => setFilteredTags([])}
                >
                  {filteredTags.length > 0 && (
                    <OptGroup label="Resultados de búsqueda">
                      {filteredTags.map((tag, tagIndex) => (
                        <Option
                          key={`filtered-${tagIndex}`}
                          value={tag.description}
                        >
                          {tag.description}
                        </Option>
                      ))}
                    </OptGroup>
                  )}
                </Select>
              )}
            </div>

            <div className="header-btn">
              <div
                className={`btn-erp gx-notify gx-notify-search gx-mr-0 ${
                  pathname === "/erp" && "active-nav-link"
                }`}
              >
                <NavLink to="/erp">
                  <Button
                    className="gx-mb-0 gx-mr-0 heading-link-color gx-font-weight-bold"
                    type="link"
                  >
                    <i className="fal fa-rocket gx-mr-2" />
                    ERP
                  </Button>
                </NavLink>
              </div>
              <div className="gx-notify gx-notify-search gx-d-lg-block gx-d-none gx-mr-0">
                {!token &&
                  !authUser &&
                  pathname !== "/login" &&
                  pathname !== "/register" && (
                    <Link to="/login">
                      <Button type="primary" className="gx-m-0">
                        <i className="fal fa-lock-alt gx-pr-1" />
                        Ingresar
                      </Button>
                    </Link>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(HorizontalDefault);
