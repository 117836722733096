import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, Row, Col, Table } from "antd";
import withSearchOnTable from "HOC/withSearchOnTable";
import { toggleModalPerforacion } from "appRedux/actions";
const columns = [
  {
    title: "Perforación",
    dataIndex: "sPerforacion",
    key: "sPerforacion",
    align: "center"
  },
  {
    title: "Diametro",
    dataIndex: "nDiametro",
    key: "nDiametro",
    align: "center"
  },
  {
    title: "Profundidad",
    dataIndex: "nProfundidad",
    key: "nProfundidad",
    align: "center"
  },
  {
    title: "Dist. Borde Lateral",
    dataIndex: "nDistBordeLateral",
    key: "nDistBordeLateral",
    align: "center"
  },

  {
    title: "Dist. Borde Superior",
    dataIndex: "nDistBordeSuperior",
    key: "nDistBordeSuperior",
    align: "center"
  },
  {
    title: "Dist. Borde Inferior",
    dataIndex: "nDistBordeInferior",
    key: "nDistBordeInferior",
    align: "center"
  }
];
class Perforation extends Component {
  constructor(props) {
    super(props);
    this.state = { dataSource: [] };
    this.refSearch = React.createRef();
  }
  componentDidUpdate(prevProps) {
    const { modalPerforation, listPerforation } = this.props;
    if (prevProps.modalPerforation !== modalPerforation) {
      this.refSearch.current && this.refSearch.current.focus();

      if (
        modalPerforation.visible === true &&
        this.state.dataSource.length === 0
      ) {
        setTimeout(() => {
          this.setState({
            dataSource:
              listPerforation.length > 0 ? listPerforation.slice(0, 10) : []
          });
        }, 500);
      }
    }
  }

  closeModalPerforation = () => {
    this.props.toggleModalPerforacion({ visible: false, info: null });
  };

  render() {
    const {
      // REDUX
      listPerforation,
      fetchingPerforation,
      modalPerforation,
      // HOC
      resultSearch,
      onSearchNow,
      getInputSearchProps,
      handleOnSelectPerforation
    } = this.props;

    return (
      <Modal
        visible={modalPerforation && modalPerforation.visible}
        onCancel={this.closeModalPerforation}
        title="Perforación"
        footer={null}
      >
        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={24}>
            {getInputSearchProps(
              "sPerforacion",
              listPerforation,
              "Buscar Perforación",
              this.refSearch,
              "default"
            )}
          </Col>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Table
              dataSource={
                resultSearch.length > 0 || onSearchNow === true
                  ? resultSearch
                  : this.state.dataSource
              }
              title={() => (
                <span>
                  <i className="fad fa-mouse gx-pr-1 gx-text-color-primary" />
                  Doble click para seleccionar una Perforación.
                </span>
              )}
              loading={fetchingPerforation}
              columns={columns}
              pagination={false}
              bordered={true}
              rowKey={row => row.sPerforacion}
              onRow={(row, index) => {
                return {
                  onDoubleClick: event => {
                    handleOnSelectPerforation(row, index, modalPerforation);
                  }
                };
              }}
              size={"middle"}
              scroll={{ y: "calc(100vh - 360px)" }}
              rowClassName="gx-pointer"
            />
          </Col>
        </Row>
      </Modal>
    );
  }
}

const mapStateToProps = ({ projects }) => {
  const { listPerforation, fetchingPerforation, modalPerforation } = projects;
  return { listPerforation, fetchingPerforation, modalPerforation };
};

const mapDispatchToProps = { toggleModalPerforacion };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSearchOnTable(Perforation));
