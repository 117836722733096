import React from 'react';
import { notification, message, Alert } from "antd";
import Geocode from "react-geocode";
import {cloudinarySecure} from "./config";
const { utcToZonedTime, format } = require("date-fns-tz");

const openNotification = ({
  type = "info",
  message,
  description = null,
  placement = "topRight",
}) => {
  notification[type]({
    message,
    description,
    placement,
    top: 80,
  });
};

const numbersOnly = (event) => {
  const keys = [8, 37, 46, 39, 9];
  const key = event.which;
  const shiftKey = event.shiftKey;
  return (
    (!shiftKey &&
      (keys.includes(key) ||
        (48 <= key && key <= 58) ||
        (96 <= key && key <= 105))) ||
    (event.preventDefault(), false)
  );
};

const numbersPhoneOnly = e => {
  let t = e.which;
  return (
    (e.shiftKey && (56 === t || 57 === t)) ||
    (!e.shiftKey &&
      (32 === t ||
        109 === t ||
        187 === t ||
        189 === t ||
        107 === t ||
        8 === t ||
        37 === t ||
        46 === t ||
        39 === t ||
        9 === t ||
        (48 <= t && t <= 57) ||
        (96 <= t && t <= 105))) ||
    (e.preventDefault(), !1)
  );
  // return e.shiftKey && (56 === t || 57 === t) || !e.shiftKey && (32 === t || 109 === t || 187 === t || 189 === t || 107 === t || 8 === t || 37 === t || 46 === t || 39 === t || 9 === t || 48 <= t && t <= 57 || 96 <= t && t <= 105) || (e.preventDefault(), !1)
};

const googleGeocode = async (address) => {
  try {
    Geocode.setApiKey("AIzaSyD8MqNEG3qPw8tWhwkUJPahwngpnZuOs4I");
    Geocode.setLanguage("es");
    Geocode.setRegion("pe");
    Geocode.enableDebug(false);
    const response = await Geocode.fromAddress(address);
    const { lat, lng } = await response.results[0].geometry.location;
    return { lat, lng };
  } catch (error) {
    console.log("googleGeocode -> error", error);
  }
};

const onErrorMessage = ({ graphQLErrors, networkError }, showMain = true, type = 'error') => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, extensions }) => {
      if (showMain) {
        openNotification({
          message: "Error",
          description: ` Message: ${message}`,
          type: "error",
        });
      }
      validateError(extensions, type);
    });

  if (networkError) {
    openNotification({
      message: "Error de Red",
      description: networkError.toString(),
      type: "error",
    });
  }
};

const validateError = (extensions = null, type = 'error') => {
  if (extensions && extensions.validation) {
    const validError = Object.entries(extensions.validation);
    if (validError && validError.length > 0) {
      for (let e = 0; e < validError.length; e++) {
        openNotification({
          message: type === 'warning' ? '¡Advertencia!' : '¡Ha ocurrido un error!',
          description: validError[e][1] ? validError[e][1] : "",
          type: type,
        });
      }
    }
  }
  if (extensions && extensions.errors) {
    const ValidationErrors = Object.entries(extensions.errors);
    if (validateError && ValidationErrors.length > 0) {
      for (let e = 0; e < ValidationErrors.length; e++) {
        openNotification({
          /*  message: ValidationErrors[e][0]
            ? ValidationErrors[e][0].replace("input.", "")
            : "", */
          message: type === 'warning' ? '¡Advertencia!' : '¡Ha ocurrido un error!',
          description: ValidationErrors[e][1] ? ValidationErrors[e][1] : "",
          type: "error",
        });
      }
    }
  }
};

const getVariablesListFromCache = (proxy, query) => {
  const queryName = query.definitions[0].name.value;
  const rootQuery = proxy.data.data.ROOT_QUERY;

  if (!rootQuery) return [];

  const matchQueryReducer = (names, name) => {
    if (name.startsWith(queryName)) {
      names.push(name);
    }
    return names;
  };

  const parseQueryNameToVariables = (name) => JSON.parse(name.match(/{.*}/)[0]);

  return Object.keys(rootQuery)
    .reduce(matchQueryReducer, [])
    .map(parseQueryNameToVariables);
};

export const getPublicIDCloudinaryFile = url => {
  const parsedVersion = url.split(cloudinarySecure)[1];
  const parsedImage = parsedVersion.split('/')[1];
  return parsedImage.split('.')[0];
}

export const topPage = () => {
  try {
    window.scroll({
      top: 0,
      left: 0
    });
  } catch (error) {
    window.scrollTo(0, 0);
  }
}

const formatDate = (date, type) => {
  try {
    const dateUTC = utcToZonedTime(new Date(date), {timeZone: "America/Lima"});
    switch (type) {
      case "date": {
        const dateFormat = format(dateUTC, "dd/MM/yyyy");
        return dateFormat;
      }
      case "time": {
        const timeFormat = format(dateUTC, "HH:mm");
        return timeFormat;
      }
      case "dateTime": {
        const timeFormat = format(dateUTC, "dd/MM/yyyy HH:mm:ss");
        return timeFormat;
      }
      default:
        break;
    }
  } catch (error) {
    message.error(error.toString());
  }
}

const displayNotificationMessage = ({
  description,
  title = "",
  placement = "topRight",
  type = "success"
}) => {
  notification[type]({
    message: title,
    description,
    placement: placement
  });
}

const showErrorInApi = (error) => {
  // registrar log
  const logSis = error.response
    ? JSON.stringify(error.response)
    : error.toString();
  const nameSession = format(new Date(), "dd-MM-yyy HH:mm");
  sessionStorage.setItem(nameSession, logSis);
  // personalisar mensaje
  const defaultMessage = error.message
    ? error.message
    : "Lamentamos el inconveniente tenemos problemas con la conexión.";
  const errorMessage = error.response
    ? error.response.data.originalError
      ? error.response.data.originalError.info
        ? error.response.data.originalError.info.message
        : defaultMessage
      : defaultMessage
    : defaultMessage;

  // Mostrar notificacion
  notification.error({
    message: "No se pudo ejecutar el proceso",
    description: <Alert type="error" description={errorMessage} />,
    placement: "topRight"
  });
}

export {
  openNotification,
  numbersOnly,
  numbersPhoneOnly,
  formatDate,
  googleGeocode,
  onErrorMessage,
  getVariablesListFromCache,
  displayNotificationMessage,
  showErrorInApi
};
