import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, Row, Col, Table } from "antd";
import withSearchOnTable from "HOC/withSearchOnTable";
import { toggleModalRanuraD25 } from "appRedux/actions";
const columns = [
  { title: "Ranura", dataIndex: "sRanura", key: "sRanura", align: "center" },
  {
    title: "Espesor",
    dataIndex: "nEspesor",
    key: "nEspesor",
    align: "center"
  },
  {
    title: "Profundidad",
    dataIndex: "nProfundidad",
    key: "nProfundidad",
    align: "center"
  },
  {
    title: "Dist. Borde L1",
    dataIndex: "nDistBordeLateral1",
    key: "nDistBordeLateral1",
    align: "center"
  },
  {
    title: "Dist. Borde L2",
    dataIndex: "nDistBordeLateral2",
    key: "nDistBordeLateral2",
    align: "center"
  }
];
class GrooveD25 extends Component {
  constructor(props) {
    super(props);
    this.state = { dataSource: [] };
    this.refSearch = React.createRef();
  }
  componentDidUpdate(prevProps, prevState) {
    const { modalGrooveD25, listGrooveD25 } = this.props;
    if (prevProps.modalGrooveD25 !== modalGrooveD25) {
      this.refSearch.current && this.refSearch.current.focus();

      if (modalGrooveD25.visible && this.state.dataSource.length === 0) {
        setTimeout(() => {
          this.setState({
            dataSource:
              listGrooveD25.length > 0 ? listGrooveD25.slice(0, 10) : []
          });
        }, 500);
      }
    }
  }

  closeModalGrooveD25 = () => {
    this.props.toggleModalRanuraD25({ visible: false, info: null });
  };
  render() {
    const {
      // REDUX
      listGrooveD25,
      fetchingGrooveD25,
      modalGrooveD25,
      // HOC
      resultSearch,
      onSearchNow,
      getInputSearchProps,
      handleOnSelectGrooveD25
    } = this.props;
    return (
      <Modal
        visible={modalGrooveD25 && modalGrooveD25.visible}
        onCancel={this.closeModalGrooveD25}
        title="Ranuras D-25"
        footer={null}
      >
        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={24}>
            {getInputSearchProps(
              "sRanura",
              listGrooveD25,
              "Buscar Ranura",
              this.refSearch,
              "default"
            )}
          </Col>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Table
              dataSource={
                resultSearch.length > 0 || onSearchNow === true
                  ? resultSearch
                  : this.state.dataSource
              }
              loading={fetchingGrooveD25}
              columns={columns}
              pagination={false}
              bordered={true}
              rowKey={row => row.sRanura}
              onRow={(row, index) => {
                return {
                  onDoubleClick: () => {
                    handleOnSelectGrooveD25(row, index, modalGrooveD25);
                  }
                };
              }}
              size={"middle"}
              scroll={{ y: "calc(100vh - 360px)" }}
              rowClassName="gx-pointer"
              title={() => (
                <span>
                  <i className="fad fa-mouse gx-pr-1 gx-text-color-primary" />
                  Doble click para seleccionar una Ranura D-25.
                </span>
              )}
            />
          </Col>
        </Row>
      </Modal>
    );
  }
}

const mapStateToProps = ({ projects }) => {
  const { listGrooveD25, fetchingGrooveD25, modalGrooveD25 } = projects;
  return { listGrooveD25, fetchingGrooveD25, modalGrooveD25 };
};

const mapDispatchToProps = { toggleModalRanuraD25 };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSearchOnTable(GrooveD25));
