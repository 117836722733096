import React from "react";
import {Card} from "antd";
import PropTypes from "prop-types";

const Widget = ({title, children, styleName, cover, extra, actions, bodyStyle, style}) => {

  return (
    <Card
      title={title}
      actions={actions}
      cover={cover}
      bodyStyle={bodyStyle}
      className={`gx-card-widget ${styleName}`}
      extra={extra}
      style={style}
    >
      {children}
    </Card>
  )
};

export default Widget;
Widget.defaultProps = {
  styleName: '',
  bodyStyle: {}
};

Widget.propTypes = {
  title: PropTypes.node,
  extra: PropTypes.node,
  cover: PropTypes.node,
  actions: PropTypes.node,
  bodystyle: PropTypes.object,
  style: PropTypes.object,
  children: PropTypes.node.isRequired
};
