import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Col, Form, notification, Card } from "antd";
import { injectIntl } from "react-intl";
import "handsontable/dist/handsontable.full.css";
import { HotTable } from "@handsontable/react";
import Handsontable from "handsontable";
import "handsontable/languages/es-MX";
import Material from "../Modal/Material";
import Cantos from "../Modal/Cantos";
import Groove from "../Modal/Groove";
import Perforation from "../Modal/Perforation";
import GrooveD25 from "../Modal/GrooveD25";
import PerforationD25 from "../Modal/PerforationD25";
import ProyectTab from "../Tab";
import arrow from "assets/images/arrow.png";
import enter from "assets/images/enter.png";
import rightClick from "assets/images/rightClick.png";
import {
  toggleModalMaterial,
  toggleModalCantos,
  updateDataDetailProject,
  rxTableroListar,
  rxCantosListar,
  rxRanuraListar,
  rxPerforacionListar,
  toggleModalRanura,
  toggleModalPerforacion,
  rxRanuraD25Listar,
  rxPerforacionD25Listar,
  toggleModalRanuraD25,
  toggleModalPerforacionD25
} from "appRedux/actions";
const styleIcon = { height: 20, width: 22 };
const nestedHeaders = [
  [
    {
      label: "TABLERO",
      colspan: 7
    },
    {
      label: "CANTOS",
      colspan: 4
    },
    {
      label: "RANURA",
      colspan: 5
    },
    {
      label: "PERFORACIÓN",
      colspan: 3
    },
    {
      label: "D-25",
      colspan: 3
    }
  ],

  [
    "nId",
    "Codigo",
    "Descripción",
    "Cant.",
    "Ancho",
    "Largo",
    "Veta",
    "A1",
    "A2",
    "L1",
    "L2",
    "Cod.",
    "A1",
    "A2",
    "L1",
    "L2",
    "Cod.",
    "Cant.",
    "Lados",
    "Ran.",
    "Perf.",
    "Lado",
    "SE",
    "OBS",
    "Espesor"
  ]
];

global.empytRowTable = {
  nId: null,
  nIdTablero: null,
  sCodTablero: "",
  sDescTablero: "",
  nCantidad: null,
  nAnchoTab: null,
  nLargoTab: null,
  nEspesorTab: null,
  bVetaTab: false,
  nAncho: null,
  nLargo: null,
  bVeta: false,
  sCantoA1: null,
  sCantoA2: null,
  sCantoL1: null,
  sCantoL2: null,
  sCodRan: null,
  bRanA1: false,
  bRanA2: false,
  bRanL1: false,
  bRanL2: false,
  sCodPerf: null,
  nCantidadPerf: 0,
  sLadoPerf: null,
  sCodRanD25: null,
  sCodPerfD25: null,
  sLadoD25: null,
  bServicioEsp: false,
  sObservaciones: null
};
class ProjectDetailTable extends Component {
  constructor(props) {
    super(props);
    this.hotTableComponent = React.createRef();
    this.state = {
      listCodEdges: [],
      legendGroove: [],
      legendGrooveD25: [],
      legendPerforation: [],
      legendPerforationD25: [],
      currentRow: 0,
      isLastRow: false,
      isMassive: false,
      massiveEdges: {
        isMassive: false,
        infoMassive: null
      }
    };
  }
  componentDidMount() {
    const { authSucursal } = this.props;
    const nId = authSucursal && authSucursal.nIdSucursal;
    this.props.rxTableroListar(nId); // Warning: Aquí se necesita el nIdSucursal
    this.props.rxCantosListar(nId); // Warning: Aquí se necesita el nIdSucursal
    this.props.rxRanuraListar();
    this.props.rxPerforacionListar();
    this.props.rxRanuraD25Listar();
    this.props.rxPerforacionD25Listar();
  }
  componentDidUpdate(prevProps) {
    const { listCodeEdges } = this.props;
    if (prevProps.listCodeEdges !== listCodeEdges) {
      listCodeEdges && this.setState({ listCodEdges: listCodeEdges });
    }
  }
  openNotificationWithIcon = ({ type, message, description }) => {
    notification[type]({
      message,
      description
    });
  };
  handleAutoScrollOnAddRow = () => {
    if (this.hotTableComponent.current) {
      this.hotTableComponent.current.hotElementRef.scrollTop =
        this.hotTableComponent.current.hotElementRef.scrollHeight -
        this.hotTableComponent.current.hotElementRef.clientHeight;
    }
  };

  handleAddNeRow = row => {
    const hot = this.hotTableComponent.current.hotInstance;
    const lastRow = hot.getSourceDataAtRow(row);
    if (
      lastRow.sCodTablero &&
      lastRow.sDescTablero &&
      lastRow.nAnchoTab &&
      lastRow.nLargoTab &&
      lastRow.nCantidad
    ) {
      hot.alter("insert_row");
      hot.setDataAtRowProp([
        [row + 1, "nAnchoTab", lastRow.nAnchoTab, "edit"],
        [row + 1, "nLargoTab", lastRow.nLargoTab, "edit"],
        [row + 1, "bVetaTab", lastRow.bVetaTab, "edit"],
        [row + 1, "nEspesorTab", lastRow.nEspesorTab, "edit"],
        [row + 1, "sDescTablero", lastRow.sDescTablero, "edit"],
        [row + 1, "bVeta", lastRow.bVeta, "edit"],
        [row + 1, "nEspesor", lastRow.nEspesor, "edit"],
        [row + 1, "sCodTablero", lastRow.sCodTablero, "edit"],
        [row + 1, "nIdTablero", lastRow.nIdTablero, "edit"],
        [row + 1, "sCantoA1", lastRow.sCantoA1, "edit"],
        [row + 1, "sCantoA2", lastRow.sCantoA2, "edit"],
        [row + 1, "sCantoL1", lastRow.sCantoL1, "edit"],
        [row + 1, "sCantoL2", lastRow.sCantoL2, "edit"]
      ]);
      this.openNotificationWithIcon({
        type: "success",
        message: "Bien",
        description: "Nueva fila agregada"
      });
    } else {
      this.openNotificationWithIcon({
        type: "error",
        message: "Error",
        description:
          "Antes debes registrar la descripción, cantidad y cantos para el tablero de la fila seleccionada."
      });
      hot.selectCell(row, 2);
    }
  };

  /**
   * CANTOS
   */
  handleMassiveChange = row => {
    this.props.toggleModalCantos({ visible: true, info: null });
    this.setState({ massiveEdges: { isMassive: true, infoMassive: row } });
  };

  showModalCantos = (col, row, prop) => {
    this.props.toggleModalCantos({ visible: true, info: { row, col, prop } });
  };

  handleOnSelectEdgesModal = async (row, index, modalCantos) => {
    try {
      const { info } = modalCantos;
      // console.log("handleOnSelectEdgesModal -> info", info);
      const { listCodEdges, massiveEdges } = this.state;
      const hot = this.hotTableComponent.current.hotInstance;

      if (massiveEdges.isMassive === true) {
        const sourceData = hot.getSourceData();
        const rowsReplace = await this.seletcEdgesWithTheSameSelectedCode(
          sourceData,
          massiveEdges
        );
        await this.execCambioMasivoCantos(
          rowsReplace,
          row,
          listCodEdges,
          massiveEdges.infoMassive.sCodCanto
        );
      } else {
        if (info) {
          const sCode = await this.handleOnGenerateCodigo(listCodEdges, row);
          const rowData = { row: info.row, prop: info.prop };
          this.handleSetDataAtRowProp(rowData, sCode, false);
          this.props.toggleModalCantos({ visible: false, info: null });
          hot.setDataAtRowProp(info.row, info.prop, sCode, "edit");
          hot.selectCell(info.row, info.col);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  seletcEdgesWithTheSameSelectedCode = (sourceData, massiveEdges) => {
    const rowsReplace = [];

    for (let i = 0; i < sourceData.length; i++) {
      if (sourceData[i].sCantoA1 === massiveEdges.infoMassive.sCodCanto) {
        rowsReplace.push({ row: i, prop: "sCantoA1" });
      }
      if (sourceData[i].sCantoA2 === massiveEdges.infoMassive.sCodCanto) {
        rowsReplace.push({ row: i, prop: "sCantoA2" });
      }
      if (sourceData[i].sCantoL1 === massiveEdges.infoMassive.sCodCanto) {
        rowsReplace.push({ row: i, prop: "sCantoL1" });
      }
      if (sourceData[i].sCantoL2 === massiveEdges.infoMassive.sCodCanto) {
        rowsReplace.push({ row: i, prop: "sCantoL2" });
      }
    }
    return rowsReplace;
  };

  execCambioMasivoCantos = async (
    rowsReplace,
    row,
    listCodEdges,
    sCodCanto
  ) => {
    if (rowsReplace.length > 0) {
      const listCodes = listCodEdges.filter(
        item => item.sCodCanto !== sCodCanto
      );
      const sCode = await this.handleOnGenerateCodigo(listCodes, row);
      this.handleSetDataAtRowProp(rowsReplace, sCode, true);
      this.openNotificationWithIcon({
        type: "success",
        message: "Bien",
        description: `Cantos reemplazados con - ${sCode}`
      });
      this.props.toggleModalCantos({ visible: false, info: null });
      this.setState({
        massiveEdges: {
          isMassive: false,
          infoMassive: null
        },
        listCodEdges: listCodes
      });
    } else {
      this.openNotificationWithIcon({
        type: "info",
        message: "Información",
        description: `No se encontro cantos con el codigo seleccionado.`
      });
    }
  };

  handleSetDataAtRowProp = (rowData, sCode, massive = false) => {
    const hot = this.hotTableComponent.current.hotInstance;
    if (massive === true) {
      for (const r of rowData) {
        hot.setDataAtRowProp(r.row, r.prop, sCode, "edit");
      }
    } else {
      hot.setDataAtRowProp(rowData.row, rowData.prop, sCode, "edit");
    }
  };

  /**
   * GENERAR CODIGO TEMPORAL
   */
  handleOnGenerateCodigo = async (listCode, row) => {
    const { nIdCanto, nEspesor, nAncho, sDescCanto } = row;
    const filterListCod = await listCode.filter(
      item => item.nIdCanto === nIdCanto
    );
    let sCodCanto = filterListCod.length > 0 ? filterListCod[0].sCodCanto : "";
    if (filterListCod.length <= 0) {
      const row = { nIdCanto, nEspesor, nAncho, sDescCanto };
      const type = nEspesor <= 1 ? "D" : "G";
      const generateCode = await this.handleIncrementNumber(
        listCode,
        row,
        type
      );
      sCodCanto = generateCode;
    }
    return sCodCanto;
  };

  handleIncrementNumber = async (listCode, row, type) => {
    const { nIdCanto, nEspesor, nAncho, sDescCanto } = row;
    const listIncrement =
      listCode.length > 0
        ? listCode.filter(
            item => item.sCodCanto && item.sCodCanto.includes(type)
          )
        : [];
    const nCode =
      listIncrement.length > 0
        ? Number(listIncrement[listIncrement.length - 1].sCodCanto.substr(1))
        : 0;
    const sCodCanto = `${type}${nCode < 10 ? "0" + (nCode + 1) : nCode + 1}`;

    this.setState({
      listCodEdges: [
        ...this.state.listCodEdges,
        { nIdCanto, nEspesor, nAncho, sDescCanto, sCodCanto }
      ]
    });

    return sCodCanto;
  };

  /**
   * MATERIAL
   */
  showModalMaterial = (row, col, prop, massive = false) => {
    this.props.toggleModalMaterial({ visible: true, info: { row, col, prop } });
    this.setState({ isMassive: massive });
  };
  removeFalsy = async arr => {
    return await arr.filter(a => a !== "N");
  };
  setDatatAtRow = (r, row) => {
    const hot = this.hotTableComponent.current.hotInstance;
    if (row.nEspesor >= 12) {
      hot.setDataAtRowProp([
        [r, "nIdTablero", row.nIdTablero, "edit"],
        [r, "sCodTablero", row.sCodTablero, "edit"],
        [r, "nAnchoTab", row.nAncho, "edit"],
        [r, "nLargoTab", row.nLargo, "edit"],
        [r, "nEspesorTab", row.nEspesor, "edit"],
        [r, "bVetaTab", row.bVeta, "edit"],
        [r, "bVeta", row.bVeta, "edit"],
        [r, "sDescTablero", row.sDescTablero, "edit"]
      ]);
    } else {
      hot.setDataAtRowProp([
        [r, "nIdTablero", row.nIdTablero, "edit"],
        [r, "sCodTablero", row.sCodTablero, "edit"],
        [r, "nAnchoTab", row.nAncho, "edit"],
        [r, "nLargoTab", row.nLargo, "edit"],
        [r, "nEspesorTab", row.nEspesor, "edit"],
        [r, "bVetaTab", row.bVeta, "edit"],
        [r, "bVeta", row.bVeta, "edit"],
        [r, "sDescTablero", row.sDescTablero, "edit"],
        [r, "sCantoA1", null, "edit"],
        [r, "sCantoA2", null, "edit"],
        [r, "sCantoL1", null, "edit"],
        [r, "sCantoL2", null, "edit"],
        [r, "sCodRan", null, "edit"],
        [r, "bRanA1", false, "edit"],
        [r, "bRanA2", false, "edit"],
        [r, "bRanL1", false, "edit"],
        [r, "bRanL2", false, "edit"],
        [r, "sCodPerf", null, "edit"],
        [r, "nCantidadPerf", null, "edit"],
        [r, "sLadoPerf", null, "edit"],
        [r, "sCodRanD25", null, "edit"],
        [r, "sCodPerfD25", null, "edit"],
        [r, "sLadoD25", null, "edit"]
      ]);
    }
  };
  handleOnSelectBoardInModal = async (row, index, modal_material) => {
    const { info } = modal_material;
    const hot = this.hotTableComponent.current.hotInstance;
    if (this.state.isMassive === true) {
      const currentRow = hot.getSourceDataAtRow(info.row);
      const rowData = hot.getSourceData();
      const rows = rowData.map((item, index) =>
        item.sCodTablero && item.sCodTablero === currentRow.sCodTablero
          ? index
          : "N"
      );
      const rowReplace = await this.removeFalsy(rows);
      if (rowReplace.length > 0) {
        for (const r of rowReplace) this.setDatatAtRow(r, row);

        this.openNotificationWithIcon({
          type: "success",
          message: "Bien",
          description: "Datos tablero reemplazado"
        });
      }
      this.setState({ isMassive: false });
    } else {
      this.setDatatAtRow(info.row, row);
    }
    hot.selectCell(info.row, info.col + 1);
    this.props.toggleModalMaterial({ visible: false, info: null });
  };

  /**
   * RANURAS
   */
  showModalGroove = (row, col, prop) => {
    this.props.toggleModalRanura({
      visible: true,
      info: { row, col, prop }
    });
  };

  handleOnSelectGroove = (row, index, modaGroove) => {
    const { info } = modaGroove;
    const { sRanura, nEspesor, nProfundidad, nDistBordeLateral } = row;
    this.hotTableComponent.current.hotInstance.setDataAtRowProp(
      info.row,
      info.prop,
      sRanura,
      "auto"
    );
    this.props.toggleModalRanura({
      visible: false,
      info: null
    });

    const filterLegend = this.state.legendGroove.filter(
      item => item.sRanura !== sRanura
    );
    this.setState({
      legendGroove: [
        ...filterLegend,
        { sRanura, nEspesor, nProfundidad, nDistBordeLateral }
      ]
    });
  };

  showModalGrooveD25 = (row, col, prop) => {
    this.props.toggleModalRanuraD25({
      visible: true,
      info: { row, col, prop }
    });
  };
  handleOnSelectGrooveD25 = (row, index, modaGrooveD25) => {
    const { info } = modaGrooveD25;
    const { sRanura, nEspesor, nProfundidad, nDistBordeLateral } = row;
    const hot = this.hotTableComponent.current.hotInstance;
    hot.setDataAtRowProp(info.row, info.prop, sRanura, "auto");
    this.props.toggleModalRanuraD25({
      visible: false,
      info: null
    });
    hot.selectCell(info.row, info.col);
    const filterLegend = this.state.legendGrooveD25.filter(
      item => item.sRanura !== sRanura
    );
    this.setState({
      legendGrooveD25: [
        ...filterLegend,
        { sRanura, nEspesor, nProfundidad, nDistBordeLateral }
      ]
    });
  };

  /**
   * PERFORATION
   */

  showModalPerforation = (row, col, prop) => {
    this.props.toggleModalPerforacion({
      visible: true,
      info: { row, col, prop }
    });
  };

  handleOnSelectPerforation = (row, index, modalPerforation) => {
    const { info } = modalPerforation;
    const hot = this.hotTableComponent.current.hotInstance;
    hot.setDataAtRowProp(info.row, info.prop, row.sPerforacion, "edit");
    hot.selectCell(info.row, info.col);
    this.props.toggleModalPerforacion({ visible: false, info: null });
    const filterLegend = this.state.legendPerforation.filter(
      item => item.sPerforacion !== row.sPerforacion
    );
    this.setState({ legendPerforation: [...filterLegend, row] });
  };

  showModalPerforationD25 = (row, col, prop) => {
    this.props.toggleModalPerforacionD25({
      visible: true,
      info: { row, col, prop }
    });
  };

  handleOnSelectPerforationD25 = (row, index, modalPerforationD25) => {
    const { info } = modalPerforationD25;
    const hot = this.hotTableComponent.current.hotInstance;
    hot.setDataAtRowProp(info.row, info.prop, row.sPerforacion, "edit");
    hot.selectCell(info.row, info.col);
    this.props.toggleModalPerforacionD25({ visible: false, info: null });
    const filterLegend = this.state.legendPerforationD25.filter(
      item => item.sPerforacion !== row.sPerforacion
    );
    this.setState({ legendPerforationD25: [...filterLegend, row] });
  };

  /**
   * CHANGE VALUE (GRID)
   */
  handleOnChangeRowData = async (changes, source) => {};

  validatorCantos = (value, callback) => {
    const filterCantos = this.state.listCodEdges.filter(
      item => item.sCodCanto && item.sCodCanto === value
    );
    if (
      (filterCantos && filterCantos.length > 0) ||
      value === "" ||
      value === null
    ) {
      callback(true);
    } else {
      callback(false);
    }
  };

  handleAfterCreateRow = (index, amount, source) => {
    const listDetailProject = this.hotTableComponent.current.hotInstance.getSourceData();
    this.props.updateDataDetailProject(listDetailProject);
  };

  handleRemoveRow = (index, amount, physicalRows, source) => {
    const listDetailProject = this.hotTableComponent.current.hotInstance.getSourceData();
    this.props.updateDataDetailProject(listDetailProject);
  };

  isEmpty = str => {
    if (str === "" || str === null) {
      return true;
    } else {
      return false;
    }
  };

  disabledCellByThickness = value => {
    return value < 12 ? true : false;
  };

  renderDisabledBtn = value => {
    const btn = `<button class="gx-block gx-btn-row-table-amz" readOnly disabled>${
      !!value ? value : ""
    }</button>`;
    return btn;
  };

  setDataNextCell = row => {
    const hot = this.hotTableComponent.current.hotInstance;
    const r = row[0][0];
    const c = row[0][1];
    const rowData = hot.getSourceDataAtRow(r);
    switch (c) {
      case 7:
        hot.setDataAtRowProp(r, "sCantoA2", rowData.sCantoA1, "edit");
        break;
      case 8:
        hot.setDataAtRowProp(r, "sCantoL1", rowData.sCantoA2, "edit");
        break;
      case 9:
        hot.setDataAtRowProp(r, "sCantoL2", rowData.sCantoL1, "edit");
        break;
      default:
        break;
    }
  };

  render() {
    const {
      detailProject,
      nIdProyecto,
      sEstadoProyecto,
      historyProp
    } = this.props;
    const contexto = this;
    const columns = [
      { data: "nIdTablero", readOnly: true },
      { data: "sCodTablero", readOnly: true },
      {
        data: "sDescTablero",
        type: "text",
        wordWrap: false,
        readOnly: true,
        // width: 280,
        renderer: function(instance, td, row, col, prop, value) {
          if (col === 2) {
            if (sEstadoProyecto !== "Optimizado") {
              let btn = document.createElement("button"),
                containerDiv = document.createElement("div"),
                iconI = document.createElement("i");
              btn.classList.add("gx-pointer");
              iconI.classList.add("fal", "fa-edit");
              containerDiv.classList.add("gx-container-btn-project-table");
              btn.append(iconI);
              containerDiv.textContent = value;
              containerDiv.appendChild(btn);
              Handsontable.dom.addEvent(btn, "click", function(event) {
                event.preventDefault();
                event.stopPropagation();
                contexto.showModalMaterial(row, col, prop, value);
              });
              td.innerHTML = "";
              td.append(containerDiv);
            } else {
              td.textContent = value;
            }
          } else {
            Handsontable.renderers.TextRenderer.apply(this, arguments);
          }

          return td;
        }
      },
      { data: "nCantidad", type: "numeric", allowEmpty: false },
      {
        data: "nAncho",
        type: "numeric",
        allowEmpty: false,
        validator: function(value, callback) {
          if (
            value < this.instance.getDataAtRowProp(this.row, "nAnchoTab") &&
            value >= 50
          ) {
            callback(true);
          } else {
            callback(false);
          }
        }
      },
      {
        data: "nLargo",
        type: "numeric",
        allowEmpty: false,
        validator: function(value, callback) {
          if (
            value < this.instance.getDataAtRowProp(this.row, "nLargoTab") &&
            value >= 50
          ) {
            callback(true);
          } else {
            callback(false);
          }
        }
      },
      {
        data: "bVeta",
        type: "checkbox",
        className: "htCenter"
      },
      {
        data: "sCantoA1",
        type: "text",
        validator: this.validatorCantos,
        renderer: function(instance, td, row, col, prop, value) {
          if (col === 7) {
            let rowData = instance.getSourceDataAtRow(row);
            if (rowData.nEspesorTab >= 12) {
              let btn = document.createElement("button");
              btn.classList.add("gx-btn-row-table-amz");
              Handsontable.dom.addEvent(btn, "dblclick", function(event) {
                event.stopPropagation();
                contexto.showModalCantos(col, row, prop, value);
              });
              btn.textContent = value;
              Handsontable.renderers.BaseRenderer.apply(this, arguments);
              Handsontable.dom.empty(td);
              td.appendChild(btn);
            } else {
              Handsontable.dom.empty(td);
              td.innerHTML = contexto.renderDisabledBtn(value);
            }
          } else {
            Handsontable.renderers.BaseRenderer.apply(this, arguments);
          }
          return td;
        }
      },
      {
        data: "sCantoA2",
        type: "text",
        validator: this.validatorCantos,
        renderer: function(instance, td, row, col, prop, value) {
          if (col === 8) {
            let rowData = instance.getSourceDataAtRow(row);
            if (rowData.nEspesorTab >= 12) {
              let btn = document.createElement("button");
              btn.classList.add("gx-btn-row-table-amz");
              Handsontable.dom.addEvent(btn, "dblclick", function(event) {
                event.stopPropagation();
                contexto.showModalCantos(col, row, prop, value);
              });
              btn.textContent = value;
              Handsontable.renderers.BaseRenderer.apply(this, arguments);
              Handsontable.dom.empty(td);
              td.appendChild(btn);
            } else {
              Handsontable.dom.empty(td);
              td.innerHTML = contexto.renderDisabledBtn(value);
            }
          } else {
            Handsontable.renderers.TextRenderer.apply(this, arguments);
          }

          return td;
        }
      },
      {
        data: "sCantoL1",
        type: "text",
        validator: this.validatorCantos,
        renderer: function(instance, td, row, col, prop, value) {
          if (col === 9) {
            let rowData = instance.getSourceDataAtRow(row);
            if (rowData.nEspesorTab >= 12) {
              let btn = document.createElement("button");
              btn.classList.add("gx-btn-row-table-amz");
              Handsontable.dom.addEvent(btn, "dblclick", function(event) {
                event.stopPropagation();
                contexto.showModalCantos(col, row, prop, value);
              });
              btn.textContent = value;
              Handsontable.renderers.BaseRenderer.apply(this, arguments);
              Handsontable.dom.empty(td);
              td.appendChild(btn);
            } else {
              Handsontable.dom.empty(td);
              td.innerHTML = contexto.renderDisabledBtn(value);
            }
          } else {
            Handsontable.renderers.TextRenderer.apply(this, arguments);
          }

          return td;
        }
      },
      {
        data: "sCantoL2",
        type: "text",
        validator: this.validatorCantos,
        renderer: function(instance, td, row, col, prop, value) {
          if (col === 10) {
            let rowData = instance.getSourceDataAtRow(row);
            if (rowData.nEspesorTab >= 12) {
              let btn = document.createElement("button");
              btn.classList.add("gx-btn-row-table-amz");
              Handsontable.dom.addEvent(btn, "dblclick", function(event) {
                event.stopPropagation();
                contexto.showModalCantos(col, row, prop, value);
              });
              btn.textContent = value;
              Handsontable.renderers.BaseRenderer.apply(this, arguments);
              Handsontable.dom.empty(td);
              td.appendChild(btn);
            } else {
              Handsontable.dom.empty(td);
              td.innerHTML = contexto.renderDisabledBtn(value);
            }
          } else {
            Handsontable.renderers.TextRenderer.apply(this, arguments);
          }

          return td;
        }
      },
      {
        data: "sCodRan",
        type: "text",
        renderer: function(instance, td, row, col, prop, value) {
          if (col === 11) {
            let rowData = instance.getSourceDataAtRow(row);
            if (rowData.nEspesorTab >= 12) {
              let btn = document.createElement("button");
              btn.classList.add("gx-btn-row-table-amz");
              Handsontable.dom.addEvent(btn, "dblclick", function(event) {
                event.stopPropagation();
                contexto.showModalGroove(row, col, prop, value);
              });
              btn.textContent = value;
              Handsontable.dom.empty(td);
              td.appendChild(btn);
            } else {
              Handsontable.dom.empty(td);
              td.innerHTML = contexto.renderDisabledBtn(value);
            }
          } else {
            Handsontable.renderers.TextRenderer.apply(this, arguments);
          }

          return td;
        }
      },
      { data: "bRanA1", type: "checkbox", className: "htCenter" },
      { data: "bRanA2", type: "checkbox", className: "htCenter" },
      { data: "bRanL1", type: "checkbox", className: "htCenter" },
      { data: "bRanL2", type: "checkbox", className: "htCenter" },
      {
        data: "sCodPerf",
        type: "text",
        renderer: function(instance, td, row, col, prop, value) {
          if (col === 16) {
            let rowData = instance.getSourceDataAtRow(row);
            if (rowData.nEspesorTab >= 12) {
              let btn = document.createElement("button");
              btn.classList.add("gx-btn-row-table-amz");
              Handsontable.dom.addEvent(btn, "dblclick", function(event) {
                event.stopPropagation();
                contexto.showModalPerforation(row, col, prop, value);
              });
              btn.textContent = value;
              Handsontable.dom.empty(td);
              td.appendChild(btn);
            } else {
              Handsontable.dom.empty(td);
              td.innerHTML = contexto.renderDisabledBtn(value);
            }
          } else {
            Handsontable.renderers.TextRenderer.apply(this, arguments);
          }

          return td;
        }
      },
      { data: "nCantidadPerf", type: "numeric" },
      { data: "sLadoPerf", type: "dropdown", source: ["A1", "A2", "L1", "L2"] },
      {
        data: "sCodRanD25",
        type: "text",
        renderer: function(instance, td, row, col, prop, value) {
          if (col === 19) {
            const rowData = instance.getSourceDataAtRow(row);
            if (rowData.nEspesorTab >= 12) {
              if (rowData.sCodPerfD25 === "" || rowData.sCodPerfD25 === null) {
                let btn = document.createElement("button");
                btn.classList.add("gx-btn-row-table-amz");
                Handsontable.dom.addEvent(btn, "dblclick", function(event) {
                  event.stopPropagation();
                  contexto.showModalGrooveD25(row, col, prop, value);
                });
                btn.textContent = value;
                Handsontable.dom.empty(td);
                td.appendChild(btn);
              } else {
                Handsontable.dom.empty(td);
                td.innerHTML = contexto.renderDisabledBtn(value);
              }
            } else {
              Handsontable.dom.empty(td);
              td.innerHTML = contexto.renderDisabledBtn(value);
            }
          } else {
            Handsontable.renderers.TextRenderer.apply(this, arguments);
          }

          return td;
        }
      },
      {
        data: "sCodPerfD25",
        type: "text",
        renderer: function(instance, td, row, col, prop, value) {
          if (col === 20) {
            const rowData = instance.getSourceDataAtRow(row);
            if (rowData.nEspesorTab >= 12) {
              if (rowData.sCodRanD25 === "" || rowData.sCodRanD25 === null) {
                let btn = document.createElement("button");
                btn.classList.add("gx-btn-row-table-amz");
                Handsontable.dom.addEvent(btn, "dblclick", function(event) {
                  event.stopPropagation();
                  contexto.showModalPerforationD25(row, col, prop, value);
                });
                btn.textContent = value;
                Handsontable.dom.empty(td);
                td.appendChild(btn);
              } else {
                Handsontable.dom.empty(td);
                td.innerHTML = contexto.renderDisabledBtn(value);
              }
            } else {
              Handsontable.dom.empty(td);
              td.innerHTML = contexto.renderDisabledBtn(value);
            }
          } else {
            Handsontable.renderers.TextRenderer.apply(this, arguments);
          }
          return td;
        }
      },
      {
        data: "sLadoD25",
        type: "dropdown",
        source: ["A1", "A2", "L1", "L2"]
      },
      {
        data: "bServicioEsp",
        type: "checkbox",
        className: "htCenter",
        width: 50
      },
      { data: "sObservaciones", type: "text", width: 120, wordWrap: false },
      { data: "nEspesorTab", type: "numeric", readOnly: true }
    ];

    return (
      <Fragment>
        <ProyectTab
          instanciaTabla={
            this.hotTableComponent.current &&
            this.hotTableComponent.current.hotInstance
          }
          nIdProyecto={nIdProyecto}
          listCodEdges={this.state.listCodEdges}
          openNotificationWithIcon={this.openNotificationWithIcon}
          handleAddNeRow={this.handleAddNeRow}
          handleMassiveChange={this.handleMassiveChange}
          historyProp={historyProp}
        />

        <Col xs={24} sm={24} md={24} lg={24} className="gx-p-0 gx-p-lg-3">
          <Card
            title={
              <div className="gx-d-flex gx-align-items-center">
                <div className="gx-mr-4 h6">
                  <img
                    src={arrow}
                    alt="arrow"
                    className="gx-pr-1"
                    style={styleIcon}
                  />
                  Navegación
                </div>
                <div className="gx-mr-4 h6">
                  <img
                    src={enter}
                    alt="arrow"
                    className="gx-pr-1"
                    style={styleIcon}
                  />
                  Editar
                </div>
                <div className="gx-mr-4 h6">
                  <img
                    src={rightClick}
                    alt="rightClick"
                    className="gx-pr-1"
                    style={styleIcon}
                  />
                  Más opciones
                </div>
              </div>
            }
            headStyle={{ paddingTop: 0, paddingBottom: 0 }}
            bodyStyle={{ padding: 0 }}
          >
            <div className="gx-table-responsive">
              <HotTable
                id="hot"
                language="es-MX"
                ref={this.hotTableComponent}
                settings={{
                  data: detailProject ? detailProject : [],
                  columns: columns,
                  nestedHeaders: nestedHeaders,
                  colHeaders: true,
                  rowHeaders: true,
                  autoWrapCol: false,
                  autoWrapRow: false,
                  fillHandle: false,
                  allowRemoveColumnBoolean: false,
                  viewportRowRenderingOffset: 20,
                  manualColumnResize: false,
                  hiddenColumns: {
                    columns: [0, 1, 24],
                    indicators: false
                  },
                  contextMenu: {
                    items: {
                      row_below: {},
                      remove_row: {},
                      copy: {},
                      separator: Handsontable.plugins.ContextMenu.SEPARATOR,
                      remplazar: {
                        name: "Reemplazo masivo tablero",
                        callback: function(key, options) {
                          const { start } = options[0];
                          contexto.showModalMaterial(
                            start.row,
                            start.col,
                            null,
                            true
                          );
                        }
                      }
                    }
                  },
                  width: "100%",
                  height: "50vh",
                  licenseKey: "non-commercial-and-evaluation",
                  cells: function(row, col, prop) {
                    var cellProperties = {};
                    switch (prop) {
                      case "sCodPerfD25":
                        {
                          let rowData = this.instance.getSourceDataAtRow(row);
                          if (rowData) {
                            if (
                              contexto.disabledCellByThickness(
                                rowData.nEspesorTab
                              )
                            ) {
                              cellProperties.readOnly = true;
                            } else {
                              const value = contexto.isEmpty(rowData.sCodRanD25);
                              value
                                ? (cellProperties.readOnly = false)
                                : (cellProperties.readOnly = true);
                            }
                          }
                        }
                        break;
                      case "sCodRanD25":
                        {
                          let rowData = this.instance.getSourceDataAtRow(row);
                          if (rowData) {
                            if (
                              contexto.disabledCellByThickness(
                                rowData.nEspesorTab
                              )
                            ) {
                              cellProperties.readOnly = true;
                            } else {
                              const value = contexto.isEmpty(rowData.sCodPerfD25);
                              cellProperties.readOnly = value
                                ? (cellProperties.readOnly = false)
                                : (cellProperties.readOnly = true);
                            }
                          }
                        }
                        break;
                      case "nCantidad":
                        if (sEstadoProyecto === "Optimizado")
                          cellProperties.readOnly = true;

                        break;
                      case "nAncho":
                        if (sEstadoProyecto === "Optimizado")
                          cellProperties.readOnly = true;

                        break;
                      case "nLargo": {
                        if (sEstadoProyecto === "Optimizado")
                          cellProperties.readOnly = true;
                        break;
                      }
                      case "bVeta": {
                        if (sEstadoProyecto === "Optimizado") {
                          cellProperties.readOnly = true;
                        } else {
                          let rowData = this.instance.getSourceDataAtRow(row);
                          if (rowData) {
                            cellProperties.readOnly = rowData.bVetaTab
                              ? false
                              : true;
                          }
                        }
                        break;
                      }
                      case "sCantoA1":
                        {
                          let rowData = this.instance.getSourceDataAtRow(row);
                          if (rowData)
                            cellProperties.readOnly = contexto.disabledCellByThickness(
                              rowData.nEspesorTab
                            );
                        }
                        break;
                      case "sCantoA2":
                        {
                          let rowData = this.instance.getSourceDataAtRow(row);
                          if (rowData)
                            cellProperties.readOnly = contexto.disabledCellByThickness(
                              rowData.nEspesorTab
                            );
                        }
                        break;
                      case "sCantoL1":
                        {
                          let rowData = this.instance.getSourceDataAtRow(row);
                          if (rowData)
                            cellProperties.readOnly = contexto.disabledCellByThickness(
                              rowData.nEspesorTab
                            );
                        }
                        break;
                      case "sCantoL2":
                        {
                          let rowData = this.instance.getSourceDataAtRow(row);
                          if (rowData)
                            cellProperties.readOnly = contexto.disabledCellByThickness(
                              rowData.nEspesorTab
                            );
                        }
                        break;
                      case "sCodRan":
                        {
                          let rowData = this.instance.getSourceDataAtRow(row);
                          if (rowData)
                            cellProperties.readOnly = contexto.disabledCellByThickness(
                              rowData.nEspesorTab
                            );
                        }
                        break;
                      case "bRanA1":
                        {
                          let rowData = this.instance.getSourceDataAtRow(row);
                          if (rowData)
                            cellProperties.readOnly = contexto.disabledCellByThickness(
                              rowData.nEspesorTab
                            );
                        }
                        break;
                      case "bRanA2":
                        {
                          let rowData = this.instance.getSourceDataAtRow(row);
                          if (rowData)
                            cellProperties.readOnly = contexto.disabledCellByThickness(
                              rowData.nEspesorTab
                            );
                        }
                        break;
                      case "bRanL1":
                        {
                          let rowData = this.instance.getSourceDataAtRow(row);
                          if (rowData)
                            cellProperties.readOnly = contexto.disabledCellByThickness(
                              rowData.nEspesorTab
                            );
                        }
                        break;
                      case "bRanL2":
                        {
                          let rowData = this.instance.getSourceDataAtRow(row);
                          if (rowData)
                            cellProperties.readOnly = contexto.disabledCellByThickness(
                              rowData.nEspesorTab
                            );
                        }
                        break;
                      case "sCodPerf":
                        {
                          let rowData = this.instance.getSourceDataAtRow(row);
                          if (rowData)
                            cellProperties.readOnly = contexto.disabledCellByThickness(
                              rowData.nEspesorTab
                            );
                        }
                        break;
                      case "nCantidadPerf":
                        {
                          let rowData = this.instance.getSourceDataAtRow(row);
                          if (rowData)
                            cellProperties.readOnly = contexto.disabledCellByThickness(
                              rowData.nEspesorTab
                            );
                        }
                        break;
                      case "sLadoPerf":
                        {
                          let rowData = this.instance.getSourceDataAtRow(row);
                          if (rowData)
                            cellProperties.readOnly = contexto.disabledCellByThickness(
                              rowData.nEspesorTab
                            );
                        }
                        break;

                      case "sLadoD25":
                        {
                          let rowData = this.instance.getSourceDataAtRow(row);
                          if (rowData)
                            cellProperties.readOnly = contexto.disabledCellByThickness(
                              rowData.nEspesorTab
                            );
                        }
                        break;
                      default:
                        break;
                    }
                    return cellProperties;
                  },
                  afterChange: (changes, source) => {
                    changes && this.handleOnChangeRowData(changes, source);
                  },
                  afterCreateRow: (index, amount, source) => {
                    index && this.handleAfterCreateRow(index, amount, source);
                  },
                  afterRemoveRow: (index, amount, physicalRows, source) => {
                    index &&
                      this.handleRemoveRow(index, amount, physicalRows, source);
                  },
                  enterMoves: function() {
                    const hot = contexto.hotTableComponent.current.hotInstance;
                    const row = hot.getSelected();
                    contexto.setDataNextCell(row);
                    if (row[1] === hot.countCols() - 1) {
                      hot.selectCell(row[0] + 1, 0);
                      return {
                        row: 0,
                        col: 0
                      };
                    } else {
                      return {
                        row: 0,
                        col: 1
                      };
                    }
                  },
                  beforeKeyDown: function(event) {
                    switch (event.key) {
                      case "ArrowDown":
                        {
                          const row = this.getSelected();
                          const currentRow = row[0][0];
                          const totalRow = contexto.hotTableComponent.current.hotInstance.countRows();
                          if (currentRow + 1 === totalRow) {
                            contexto.handleAddNeRow(currentRow);
                          }
                        }
                        break;
                      default:
                        break;
                    }
                  }
                }}
              />
            </div>
          </Card>
        </Col>
        <Material
          handleOnSelectBoardInModal={this.handleOnSelectBoardInModal}
        />
        <Cantos handleOnSelectEdgesModal={this.handleOnSelectEdgesModal} />
        <Groove handleOnSelectGroove={this.handleOnSelectGroove} />
        <Perforation
          handleOnSelectPerforation={this.handleOnSelectPerforation}
        />
        <GrooveD25 handleOnSelectGrooveD25={this.handleOnSelectGrooveD25} />
        <PerforationD25
          handleOnSelectPerforationD25={this.handleOnSelectPerforationD25}
        />
      </Fragment>
    );
  }
}

const WrappedProjectDetailTable = Form.create({
  name: "project_detail"
})(ProjectDetailTable);
const mapStateToProps = ({ auth, common, settings, projects }) => {
  const { pathname } = settings;
  const { loading } = common;
  const { selectRoles, permissions, authSucursal } = auth;
  const {
    fetchingDetail,
    listCodeEdges,
    detailProject,
    headerProject
  } = projects;
  const sEstadoProyecto = headerProject ? headerProject.sEstado : "Emitido";
  return {
    pathname,
    selectRoles,
    loading,
    permissions,
    authSucursal,
    fetchingDetail,
    listCodeEdges,
    detailProject,
    sEstadoProyecto
  };
};

export default connect(mapStateToProps, {
  toggleModalMaterial,
  toggleModalCantos,
  updateDataDetailProject,
  rxTableroListar,
  rxCantosListar,
  rxRanuraListar,
  rxPerforacionListar,
  toggleModalRanura,
  toggleModalPerforacion,
  rxRanuraD25Listar,
  rxPerforacionD25Listar,
  toggleModalRanuraD25,
  toggleModalPerforacionD25
})(injectIntl(WrappedProjectDetailTable));

/**
 * tablero 4 ocultos
 */
