import React, { Fragment, useState } from "react";
import { Col, Row, Table, Input, Button, Popconfirm, Spin } from "antd";
import { connect, useDispatch } from "react-redux";
import Auxiliary from "util/Auxiliary";
import WrappedStoreForm from "./Modal";
import { onErrorMessage, openNotification } from "util/helpers";
import {
  rxDeleteShop,
  rxSetListShop,
  rxToggleShopsModal,
} from "appRedux/actions";

import { GET_SHOPS, SEARCH_SHOP } from "graphql/query/shop";
import { DELETE_SHOP } from "graphql/mutation/shop";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";

const { Search } = Input;

const StoresTable = (props) => {
  const dispatch = useDispatch();
  const { listShops, rxSetListShop, rxDeleteShop } = props;
  const [selectId, setSelectId] = useState(null);

  // <<<<<<< HEAD
  const [searchShop, { loading: searchLoading }] = useLazyQuery(SEARCH_SHOP, {
    onCompleted({ searchShop }) {
      if (searchShop) rxSetListShop(searchShop);
    },
    onError(error) {
      onErrorMessage(error, false);
    },
  });

  const getShops = useQuery(GET_SHOPS, {
    // =======
    //   const { loading } = useQuery(GET_SHOPS, {
    // >>>>>>> master
    onCompleted({ getShops }) {
      if (getShops) rxSetListShop(getShops);
    },
    notifyOnNetworkStatusChange: true,
  });

  const eliminaTienda = useMutation(DELETE_SHOP, {
    onCompleted({ deleteShop }) {
      const { id } = deleteShop;
      rxDeleteShop(id);
      openNotification({
        message: "Bien",
        description: "Tienda fue eliminada",
        type: "success",
      });
    },
    onError(error) {
      onErrorMessage(error);
    },
  });

  const handleNewStore = () => {
    dispatch(rxToggleShopsModal({ info: null, visible: true }));
  };

  const handleonSearchShop = (value) => {
    if (value) {
      searchShop({
        variables: {
          value: `%${value}%`,
        },
      });
    } else {
      getShops && getShops.refetch();
    }
  };

  const handleConfirm = (row) => {
    if (row) {
      const [deleteShop] = eliminaTienda;
      setSelectId(row.id);
      deleteShop({
        variables: {
          id: row.id,
        },
      });
    }
  };

  const columns = [
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Telefonos",
      dataIndex: "phone_numbers",
      key: "phone_numbers",
    },
    {
      title: "Coordenadas",
      dataIndex: "coordinates",
      key: "coordinates",
      render: (value, row) => {
        return (
          <>
            <span className="gx-pr-1">{row.lat ? row.lat : "-"}</span>
            <span className="gx-pl-1">{row.lng ? row.lng : "-"}</span>
          </>
        );
      },
    },
    {
      title: "Dirección",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Distrito",
      dataIndex: "ubigeo",
      key: "ubigeo",
      render: (ubigeo) => {
        return `${ubigeo.district}, ${ubigeo.province}, ${ubigeo.department}`;
      },
    },
    {
      title: "Correo",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Contacto",
      dataIndex: "contact",
      key: "contact",
    },
    {
      dataIndex: "accion",
      key: "accion",
      align: "center",
      render: (text, row) => {
        return (
          <Fragment>
            <Button
              size="small"
              className="gx-text-success gx-mt-0 gx-mb-1 gx-ml-0 gx-mr-1"
              onClick={() => {
                props.rxToggleShopsModal({ visible: true, info: row });
              }}
            >
              Editar
            </Button>
            <Popconfirm
              title="¿Estás seguro de que quieres eliminar esta tienda?"
              onConfirm={() => {
                handleConfirm(row);
              }}
              okText="Si"
              cancelText="No"
            >
              <Button
                size="small"
                className="gx-text-danger gx-mt-0 gx-mb-1 gx-ml-0 gx-mr-1"
                loading={
                  row.id === selectId &&
                  eliminaTienda &&
                  eliminaTienda[1].loading === true
                }
              >
                Eliminar
              </Button>
            </Popconfirm>
          </Fragment>
        );
      },
    },
  ];

  return (
    <Auxiliary>
      <Row>
        <Col xl={12} lg={12} md={12} sm={16} xs={16}>
          <Search
            placeholder="Buscar tiendas"
            onSearch={handleonSearchShop}
            allowClear
          />
        </Col>
        <Col xl={12} lg={12} md={12} sm={8} xs={8}>
          <Button
            onClick={handleNewStore}
            type="primary"
            icon="plus"
            className="gx-float-right"
            ghost
          >
            Nueva tienda
          </Button>
        </Col>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Spin spinning={searchLoading} tip="Buscado...">
            <Table
              dataSource={listShops}
              columns={columns}
              loading={getShops && getShops.loading}
              pagination={false}
              rowKey={(row) => row.id}
              size="middle"
              bordered
            />
          </Spin>
        </Col>
      </Row>
      <WrappedStoreForm />
    </Auxiliary>
  );
};

const mapStateToProps = ({ shops }) => {
  const { listShops } = shops;
  return {
    listShops,
  };
};

const mapDispatchToProps = {
  rxToggleShopsModal,
  rxSetListShop,
  rxDeleteShop,
};

export default connect(mapStateToProps, mapDispatchToProps)(StoresTable);
