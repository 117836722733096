import React, { useState, Fragment } from "react";
import moment from "moment";
import {
  Card,
  Button,
  Row,
  Col,
  Tooltip,
  Modal,
  Tag,
  Alert,
  Spin,
  message,
  Collapse,
  Progress,
  Affix,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useMutation, useQuery } from "@apollo/react-hooks";
import EllipsisText from "react-ellipsis-text";
import capitalize from "capitalize";

import "moment/locale/es";

import { toggleProjectModal } from "appRedux/actions";
import PageNotFound from "../../customViews/errorPages/404";
import { DELETE_PROJECT_MUTATION } from "../../../graphql/mutation/project";
import { USER_PROJECTS } from "../../../graphql/query/project";

import ProjectsModal from "./Modal";
import { spinner } from "util/customComponents";
import { cloudinarySecure } from "../../../util/config";
import { toggleWizard } from "../../../appRedux/actions";

import WizardProfileModal from "./WizardProfileModal";
import Seo from "../../../components/seo";

const { Panel } = Collapse;

const Projects = () => {
  const dispatch = useDispatch();
  const { authUser } = useSelector(({ auth }) => auth);
  const { visible_wizard: visible } = useSelector(({ project }) => project);
  const [, setDownloading] = useState([]);
  // const [downloading, setDownloading] = useState([]);
  const [error, setError] = useState(null);
  const [projects, setProjects] = useState([]);
  const [percent, setPercent] = useState(0);
  const [displayProfileMeter, setDisplayProfileMeter] = useState(false);

  const skipPanelPercent = () => {
    localStorage.setItem("skip-percent-panel", moment().add(3, "days"));
    setDisplayProfileMeter(false);
  };

  const wizardForms = localStorage.getItem("skip_wizard_forms")
    ? JSON.parse(localStorage.getItem("skip_wizard_forms"))
    : { contact: false, professional: false };

  const PercentPanel = () => (
    <div className="gx-card gx-p-3">
      <div className="gx-mb-2 gx-d-flex gx-full-width gx-align-items-center">
        Hola {authUser.name.split(" ")[0]}. Te necesitamos un momento.
        <span className="gx-ml-auto gx-d-none gx-d-md-block">
          <Button
            size="small"
            type="primary"
            className="gx-m-0"
            onClick={() => dispatch(toggleWizard(true))}
          >
            Completar
          </Button>
          <Button
            size="small"
            className="gx-m-0 gx-ml-2"
            onClick={skipPanelPercent}
          >
            Omitir
          </Button>
        </span>
      </div>
      <div className="gx-fs-sm gx-text-muted gx-mb-2">
        {`Has completado el ${percent}% de tu perfil y deseamos que los clientes te contacten rápidamente. Para ello completa la información de tu perfil.`}
      </div>
      <Progress
        percent={percent}
        strokeColor={{ "0%": "#108ee9", "100%": "#87d068" }}
        className="gx-mb-2"
      />
      <span className="gx-ml-auto gx-d-block gx-d-md-none">
        <Button
          size="small"
          type="primary"
          className="gx-m-0"
          onClick={() => dispatch(toggleWizard(true))}
        >
          Completar
        </Button>
        <Button
          size="small"
          className="gx-m-0 gx-ml-2"
          onClick={skipPanelPercent}
        >
          Omitir
        </Button>
      </span>
    </div>
  );

  const { refetch, loading, networkStatus } = useQuery(USER_PROJECTS, {
    notifyOnNetworkStatusChange: true,
    onCompleted({ userProjects }) {
      let prevPercent = 0;
      Object.keys(authUser).forEach((key) => {
        if (
          key !== "id" &&
          key !== "reference_address" &&
          key !== "email_verified_at" &&
          key !== "favorites" &&
          key !== "wizard_panel" &&
          key !== "user_type" &&
          key !== "__typename"
        ) {
          if (authUser[key]) {
            return (prevPercent += 5.882352941176471);
          }
        }
      });
      const rounded = Math.round(prevPercent);
      if (rounded < 100) {
        setPercent(rounded);
        setDisplayProfileMeter(true);
      }
      setProjects(userProjects.data);
    },
    onError() {
      setError(true);
    },
  });

  const [deleteProject, { loading: deleting }] = useMutation(
    DELETE_PROJECT_MUTATION,
    {
      onCompleted() {
        message.success("El proyecto ha sido eliminado");
      },
      update(cache, { data: { deleteProject } }) {
        const {
          userProjects: { data, __typename },
        } = cache.readQuery({ query: USER_PROJECTS });
        const newData = data.filter((item) => item.id !== deleteProject.id);
        cache.writeQuery({
          query: USER_PROJECTS,
          data: { userProjects: { data: newData, __typename } },
        });
        setProjects(newData);
      },
      onError({ graphQLErrors, networkError }) {
        if (graphQLErrors) {
          graphQLErrors.forEach(({ extensions }) => {
            message.warn(extensions.reason);
          });
        }
        if (networkError) {
          message.error(`[Error de Red]: ${networkError}`);
        }
      },
    }
  );

  const newProject = () => {
    dispatch(toggleProjectModal({ info: null, visible: true }));
  };

  const editProject = (id) => {
    dispatch(toggleProjectModal({ info: { id }, visible: true }));
  };

  const removeProject = (project) => {
    const modal = Modal.confirm({
      title: "¿Quieres eliminar este proyecto?",
      content: `Al hacer clic en el botón Aceptar, el proyecto "${project.name}" será removido permanentemente de los registros.`,
      okText: "Aceptar",
      cancelText: "Cancelar",
      okButtonProps: {
        loading: deleting,
        disabled: deleting,
      },
      centered: true,
      onOk: async () => {
        modal.update({
          okButtonProps: {
            loading: true,
            disabled: true,
          },
          cancelButtonProps: {
            disabled: true,
          },
        });

        await deleteProject({
          variables: {
            id: project.id,
          },
        }).then(() => {
          modal.destroy();
        });
      },
    });
  };

  if (authUser && authUser.user_type !== "CARPINTERO") {
    return <PageNotFound />;
  }

  if (loading && !projects)
    return (
      <Spin className="gx-full-width" spinning={loading} indicator={spinner} />
    );
  if (error) return `Error! ${error.message}`;

  return (
    <Fragment>
      <Seo title="Proyectos" url={window.location.href} />
      <Row type="flex" justify="space-around" className="gx-mb-4">
        <Col
          xs={24}
          sm={24}
          md={18}
          lg={12}
          xl={12}
          xxl={12}
          className="gx-p-0"
        >
          <div className="gx-d-flex gx-align-items-center gx-justify-content-between gx-pt-3 gx-px-3 gx-px-md-0">
            <h4 className="gx-text-primary gx-font-weight-semi-bold gx-p-0 gx-m-0">
              <i className="fal fa-tools gx-pr-1" />
              PROYECTOS
            </h4>
            <Button
              type="primary"
              size="small"
              disabled={networkStatus === 4}
              className="gx-mb-0 gx-ml-auto gx-mr-1"
              onClick={newProject}
            >
              Nuevo
              <i className="fal fa-plus gx-ml-2" />
            </Button>
            <Button
              type="default"
              size="small"
              disabled={networkStatus === 4}
              className="gx-mb-0 gx-ml-1"
              onClick={() => refetch()}
            >
              <span className="gx-d-none gx-d-lg-inline-block gx-mr-2">
                Actualizar
              </span>
              <i className="fal fa-sync" />
            </Button>
          </div>
          <Spin spinning={networkStatus === 4} indicator={spinner}>
            {displayProfileMeter &&
              (localStorage.getItem("skip-percent-panel")
                ? moment().isAfter(
                    moment(localStorage.getItem("skip-percent-panel"))
                  )
                : true) &&
              ((!authUser.cellphone && !wizardForms.contact) ||
                ((!authUser.specialty || !authUser.experience_years) &&
                  !wizardForms.professional) ||
                ((!authUser.ubigeo || !authUser.address) &&
                  !wizardForms.ubication)) && (
                <div className="gx-mt-4 gx-mb-0">
                  <Affix offsetTop={50} className="gx-d-block gx-d-md-none">
                    <PercentPanel />
                  </Affix>
                  <Affix offsetTop={80} className="gx-d-none gx-d-md-block">
                    <PercentPanel />
                  </Affix>
                </div>
              )}
              {projects.length > 0 ? (
                <div className="gx-mt-3">
                  {projects.map((project, index) => (
                    <Card
                      key={index}
                      className="gx-list-projects gx-mb-3"
                      title={
                        <div
                          className="gx-mb-0 gx-d-inline-flex gx-align-items-center"
                          style={{ width: "100%" }}
                        >
                          <span className="gx-font-weight-semi-bold">
                            {project.name}
                          </span>
                          <Tooltip title="Editar">
                            <Button
                              type="link"
                              shape="circle"
                              size="small"
                              className="gx-ml-auto gx-m-0"
                              onClick={() => editProject(project.id)}
                            >
                              <i className="fa fa-pencil" />
                            </Button>
                          </Tooltip>
                          <Tooltip title="Eliminar">
                            <Button
                              type="link"
                              shape="circle"
                              size="small"
                              className="gx-ml-2 gx-m-0"
                              onClick={() => removeProject(project)}
                            >
                              <i className="fa fa-trash-alt gx-text-danger" />
                            </Button>
                          </Tooltip>
                        </div>
                      }
                    >
                      <div className="gx-list-body">
                        {project.status === "REGISTRADO" && (
                          <Alert
                            type="warning"
                            message={null}
                            description="El proyecto fue registrado y se encuentra en espera de aprobación"
                            banner
                          />
                        )}
                        <div className="gx-date-container gx-fs-sm gx-text-grey gx-mb-2">
                          <span className="gx-pr-1 gx-full-width gx-d-inline-flex gx-align-items-center">
                            <i className="far fa-calendar-alt gx-mr-2" />
                            Publicado el{" "}
                            {capitalize.words(
                              moment(project.created_at)
                                .locale("es")
                                .format("DD MMMM [, ] YYYY")
                            )}
                            {project.status === "APROBADO" && (
                              <span className="gx-ml-auto gx-text-success">
                                <i className="far fa-check-circle gx-mr-1 gx-text-success" />
                                Aprobado
                              </span>
                            )}
                          </span>
                        </div>
                        <div className="gx-description-container gx-mb-2">
                          <EllipsisText
                            text={capitalize(project.description)}
                            length={250}
                          />
                        </div>
                        <div className="gx-tags-container gx-mb-2">
                          {project.tags &&
                            JSON.parse(project.tags).map((tag, key) => (
                              <Tag key={key}>{tag}</Tag>
                            ))}
                        </div>
                        <Collapse expandIconPosition="right">
                          <Panel
                            key="gallery"
                            header={
                              <div className="gx-mb-0">
                                <i className="far fa-images gx-mr-2" />
                                Fotos
                              </div>
                            }
                          >
                            <div className="gx-image-container">
                              {project.gallery.map((item, key) => {
                                const parsedFile = item.url.split(
                                  cloudinarySecure
                                )[1];
                                return (
                                  <img
                                    key={key}
                                    src={`${cloudinarySecure}/c_fit,g_center,h_400,q_auto:low,w_400/${parsedFile}`}
                                    alt={item.url}
                                    onLoad={() => setDownloading(item.url)}
                                  />
                                );
                              })}
                            </div>
                          </Panel>
                        </Collapse>
                      </div>
                    </Card>
                  ))}
                </div>
              ) : (
                <Alert
                  type="info"
                  description="No se encontraron proyectos registrados"
                  banner
                  className="gx-mt-3"
                />
              )}
          </Spin>
        </Col>
      </Row>
      <ProjectsModal updateStateUserProjects={setProjects} />
      <WizardProfileModal visible={visible} />
    </Fragment>
  );
};

export default Projects;
