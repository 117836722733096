import React from "react";
import { useSelector } from 'react-redux';
import { Route, Switch, Redirect, Link, useLocation } from "react-router-dom";
import { Layout, Menu, Icon, Card, Spin, Result, Button } from "antd";

import Users from "./admin-cms/Users";
import Projects from "./admin-cms/Projects";
import Stores from "./admin-cms/Stores";
import {Clients, AddForm, EditForm, AdvertisementClient} from "./admin-cms/Clients";

import Error404 from "./customViews/errorPages/404";
import {spinner} from "../util/customComponents";

const { Content } = Layout;

const App = ({ match }) => {
  const location = useLocation();
  const { authUser } = useSelector(({ auth }) => auth);
  const splitUrl = location && location.pathname.split("/");
  const pathId = Array.isArray(splitUrl) ? `/${splitUrl[1]}/${splitUrl[2]}` : null;

  return (
    <div className="gx-main-content-wrapper">
      {!authUser ? (
        <div className="gx-text-center">
          <Spin
            spinning
            indicator={spinner}
            tip={(
              <p className="gx-mt-3">Cargando contenido</p>
            )}
          />
        </div>
      ) : (
        authUser.user_type !== 'ADMINISTRADOR' ? (
          <Result
            status="403"
            title="403"
            subTitle="Lo sentimos, no tienes autorización para acceder a esta página."
            extra={(
              <Button type="primary">
                <Link to="/">
                  Ir a inicio
                </Link>
              </Button>
            )}
          />
        ) : (
          <Card bodyStyle={{ padding: 0, position: "relative" }}>
            <Layout>
              <Menu selectedKeys={pathId} mode="horizontal">
                <Menu.Item key="/cms/usuarios">
                  <Link to="/cms/usuarios">
                    <Icon type="user" />
                    Usuarios
                  </Link>
                </Menu.Item>
                <Menu.Item key="/cms/proyectos">
                  <Link to="/cms/proyectos">
                    <Icon type="tool" />
                    Proyectos
                  </Link>
                </Menu.Item>
                <Menu.Item key="/cms/tiendas">
                  <Link to="/cms/tiendas">
                    <Icon type="shop" />
                    Tiendas
                  </Link>
                </Menu.Item>

                <Menu.Item key="/cms/clientes">
                  <Link to="/cms/clientes">
                    <Icon type="user-add" />
                    Cliente
                  </Link>
                </Menu.Item>
              </Menu>
              <Content style={{ padding: "16px", minHeight: 280 }}>
                <Switch>
                  <Redirect
                    exact
                    from={`${match.url}/`}
                    to={`${match.url}/usuarios`}
                  />
                  <Route
                    path={`${match.url}/usuarios`}
                    exact
                    component={Users}
                  />
                  <Route
                    path={`${match.url}/proyectos`}
                    exact
                    component={Projects}
                  />
                  <Route
                    path={`${match.url}/tiendas`}
                    exact
                    component={Stores}
                  />
                  <Route
                    path={`${match.url}/clientes`}
                    exact
                    component={Clients}
                  />
                  <Route
                    path={`${match.url}/clientes/agregar`}
                    exact
                    component={AddForm}
                  />
                  <Route
                    path={`${match.url}/clientes/editar/:id`}
                    exact
                    component={EditForm}
                  />
                  <Route
                    path={`${match.url}/clientes/:id/ads`}
                    exact
                    component={AdvertisementClient}
                  />
                  <Route component={Error404} />
                </Switch>
              </Content>
            </Layout>
          </Card>
        )
      )}
    </div>
  );
};

export default App;
