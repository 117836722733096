import React, { Component } from "react";
import { Row, Form, Spin } from "antd";
import { connect } from "react-redux";
import crypt from "node-cryptex";
import { k, v } from "../../../util/config";
import Auxiliary from "util/Auxiliary";

import {
  detalleProyecto,
  resetTableProyectDetail,
  changeOptimizerToken,
} from "appRedux/actions";
import withColumnTableSearch from "HOC/withColumnTableSearch";
import ProjectDetailTable from "../Table/ProjectDetailTable";
import Seo from "../../../components/seo";

class ProjectForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nIdProyecto: null,
    };
  }

  componentDidMount() {
    const { nIdProyecto } = this.props.match.params;
    if (nIdProyecto) {
      const nId = nIdProyecto && JSON.parse(crypt.decrypt(nIdProyecto, k, v));
      this.props.detalleProyecto(nId);
      this.setState({ nIdProyecto: nId });
    } else {
      this.props.resetTableProyectDetail();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      nIdProyectoReg,
      listDataOptimize,
      reverseStateProject,
    } = this.props;

    const { nIdProyecto } = this.state;

    if (prevProps.nIdProyectoReg !== nIdProyectoReg) {
      if (nIdProyectoReg) {
        this.redirectPageToRefresh(nIdProyectoReg);
        this.setState({ nIdProyecto: nIdProyectoReg });
      }
    }

    if (prevProps.listDataOptimize !== listDataOptimize)
      if (listDataOptimize.length > 0) this.redirectToPlans(nIdProyecto);

    if (prevProps.reverseStateProject !== reverseStateProject)
      if (reverseStateProject) this.redirectPageToRefresh(nIdProyecto);
  }

  redirectPageToRefresh = (nIdProyecto) => {
    if (nIdProyecto) {
      this.props.history.push(
        `/usuario/optimizador/edit/${crypt.encrypt(
          JSON.stringify(nIdProyecto),
          k,
          v
        )}`
      );
      this.props.detalleProyecto(nIdProyecto);
    }
  };

  redirectToPlans = (nIdProyecto) => {
    this.props.detalleProyecto(nIdProyecto);
    window.open(
      `/optimizador/planos/${crypt.encrypt(
        JSON.stringify(nIdProyecto),
        k,
        v
      )}`,
      "_blank"
    );
  };
  render() {
    const {
      fetchingOptimize,
      savingProject,
      fetchingDetail,
      updatingStateProject,
    } = this.props;
    const { nIdProyecto } = this.state;

    return (
      <Auxiliary>
        <Seo
          title="Registro de tableros para optimizar cortes"
          description="Programa para optimizar cortes de melamina gratis"
          url={window.location.href}
        />
        <Spin
          tip={
            fetchingDetail
              ? "Cargando"
              : savingProject
              ? "Guardando"
              : fetchingOptimize
              ? "Optimizando"
              : updatingStateProject
              ? "Reversando Estado"
              : null
          }
          spinning={
            fetchingDetail
              ? true
              : savingProject
              ? true
              : fetchingOptimize
              ? true
              : updatingStateProject
              ? true
              : false
          }
        >
          <Row type="flex" justify="center">
            <ProjectDetailTable
              nIdProyecto={nIdProyecto}
              historyProp={this.props.history}
            />
          </Row>
        </Spin>
      </Auxiliary>
    );
  }
}

const WrappedProjectForm = Form.create({ name: "proyect_form" })(ProjectForm);
const mapStateToProps = ({ projects, auth }) => {
  const {
    nIdProyectoReg,
    savingProject,
    fetchingDetail,
    fetchingOptimize,
    listDataOptimize,
    updatingStateProject,
    reverseStateProject,
  } = projects;
  const { authUser } = auth;

  return {
    nIdProyectoReg,
    savingProject,
    fetchingDetail,
    fetchingOptimize,
    listDataOptimize,
    updatingStateProject,
    reverseStateProject,
    authUser,
  };
};

export default connect(mapStateToProps, {
  detalleProyecto,
  resetTableProyectDetail,
  changeOptimizerToken,
})(withColumnTableSearch(WrappedProjectForm));
