import gql from 'graphql-tag';

export const REGISTER_ATTENTION = gql`
  mutation registerAttention($input: InputRegisterAttention!) {
    registerAttention(input: $input) {
      registered
      message
    }
  }
`;
