import React, { useState, Fragment } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Divider, Popover } from "antd";

const MobileMenu = () => {
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const { authUser } = useSelector(({ auth }) => auth);
  const { pathname } = history.location;

  return (
    <Fragment>
      <div className="gx-d-lg-none gx-mobile-nav">
        <div className={`mobile-nav-item ${pathname === '/' && 'active-mobile-link'}`}>
          <NavLink to="/" activeClassName="gx-selected-mobile-nav">
            <i className="fal fa-home-alt" />
            <span>Principal</span>
          </NavLink>
        </div>
        <Popover
          content={(
            <ul className="gx-user-popover">
              {pathname !== '/search' && (
                <li className="gx-d-inline-flex gx-align-items-center">
                  <Link to="/search" className="gx-text-dark gx-full-width gx-d-inline-flex gx-align-items-center">
                    Proyectos
                    <i
                      className={`fal fa-arrow-right ${pathname !== '/buscar-especialista' ? 'gx-ml-auto' : 'gx-ml-4'}`}
                    />
                  </Link>
                </li>
              )}
              {pathname !== '/buscar-especialista' && pathname !== '/search' && <Divider className="gx-mt-1 gx-mb-1" />}
              {pathname !== '/buscar-especialista' && (
                <li className="gx-d-inline-flex gx-align-items-center">
                  <Link to="/buscar-especialista" className="gx-text-dark">
                    Especialistas
                    <i className="fal fa-arrow-right gx-ml-4" />
                  </Link>
                </li>
              )}
            </ul>
          )}
          trigger="click"
          visible={visible}
          onVisibleChange={visibility => setVisible(visibility)}
        >
          <div className={`mobile-nav-item ${(pathname === '/search' || pathname === '/buscar-especialista') && 'active-mobile-link'}`}>
            <i className="fal fa-search" />
            <span>Buscar</span>
          </div>
        </Popover>
        <div className={`mobile-nav-item ${pathname === '/mapas/tiendas-cercanas' && 'active-mobile-link'}`}>
          <NavLink
            to="/mapas/tiendas-cercanas"
            activeClassName="gx-selected-mobile-nav"
          >
            <i className="fal fa-map-marker-alt" />
            <span>Tiendas</span>
          </NavLink>
        </div>
        {authUser && authUser.user_type === 'CARPINTERO' && (
          <div className={`mobile-nav-item ${pathname === '/usuario/proyectos' && 'active-mobile-link'}`}>
            <NavLink
              to="/usuario/proyectos"
              activeClassName="gx-selected-mobile-nav"
            >
              <i className="fal fa-tools" />
              <span>Proyecto</span>
            </NavLink>
          </div>
        )}
        <div className={`mobile-nav-item ${(pathname === '/usuario/perfil' || pathname === '/usuario/panel-de-opciones') && 'active-mobile-link'}`}>
          <NavLink
            to="/usuario/perfil"
            activeClassName="gx-selected-mobile-nav"
            className="gx-d-none gx-d-sm-flex"
          >
            {authUser ? (
              <Fragment>
                <i className="fal fa-user-circle" />
                <span>Mi Cuenta</span>
              </Fragment>
            ) : (
              <Fragment>
                <i className="fal fa-sign-in" />
                <span>Ingresar</span>
              </Fragment>
            )}
          </NavLink>
          <NavLink
            to="/usuario/panel-de-opciones"
            activeClassName="gx-selected-mobile-nav"
            className="gx-d-flex gx-d-sm-none"
          >
            {authUser ? (
              <Fragment>
                <i className="fal fa-user-circle" />
                <span>Mi Cuenta</span>
              </Fragment>
            ) : (
              <Fragment>
                <i className="fal fa-sign-in" />
                <span>Ingresar</span>
              </Fragment>
            )}
          </NavLink>
        </div>
      </div>
    </Fragment>
  );
}

export default MobileMenu;
