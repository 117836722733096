import React from "react";
import { Modal } from "antd";
import { TracingTable } from "../Table/TracingTable";
//import TableTitle from "components/TableTitle";
export const Tracing = ({ dataSource, loading, visibleTracing, onClose }) => {
  return (
    <Modal
      visible={visibleTracing}
      onCancel={onClose}
      footer={null}
      centered
      width="90%"
      title={
        <div className="gx-d-flex gx-align-items-center">
          <i className="fal fa-stream gx-mr-3 gx-ml-0" />
          LISTA DE PROYECTOS
        </div>
      }
      bodyStyle={{
        maxHeight: "calc(100vh - 260px)",
        height: "100%",
        overflowX: "auto",
        padding: 0,
      }}
    >
      <TracingTable dataSource={dataSource} loading={loading} />
    </Modal>
  );
};
