import React from "react";
import { Table } from "antd";
import { State } from "components/Statet";
import { formatDate } from "util/helpers";

const columns = [
  {
    title: "#",
    dataIndex: "nIndex",
    key: "nIndex",
    align: "center",
    width: "5%",
    render: (value, row, index) => index + 1
  },
  {
    title: "FECHA HORA",
    dataIndex: "dFecha",
    key: "dFecha",
    align: "center",
    width: "15%",
    render: value => formatDate(value, "dateTime") || "-"
  },
  {
    title: "ESTADO",
    dataIndex: "sEstado",
    key: "sEstado",
    align: "center",
    width: "15%",
    render: value => <State text={value} letter={false} />
  },
  {
    title: "OPERACIÓN",
    dataIndex: "sObs",
    key: "sObs",
    width: "50%",
  },
  {
    title: "USUARIO",
    dataIndex: "sUser",
    key: "sUser",
    align: "center",
    width: "15%",
  }
];
export const TracingTable = ({ dataSource, loading }) => {
  return (
    <div className="gx-table-responsive">
      <Table
        size="middle"
        bordered
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        loading={loading}
        rowKey={(row, index) => index + 1}
      />
    </div>
  );
};
