import gql from "graphql-tag";

export const LOGGED_USER_QUERY = gql`
  query {
    me {
      id
      name
      email
      last_name
      avatar
      lat
      lng
      phone
      cellphone
      reference_address
      user_type
      document_type {
        id
      }
      document_number
      email_verified_at
      birth_date
      sex
      address
      specialty {
        id
        parent {
          id
        }
      }
      ubigeo {
        id
        district
        department
        province
      }
      favorites {
        id
        project {
          id
        }
      }
      uuid
      optimizer
      experience_years
      experience_resume
      wizard_panel
    }
  }
`;
