import React, {Fragment, useState} from "react";
import {Col, Row, Table, Input, Button, List, Tooltip, message, Pagination, Modal} from "antd";
import {useSelector, useDispatch} from "react-redux";
import Auxiliary from "util/Auxiliary";
import {useHistory} from 'react-router-dom';
import {useMutation, useQuery} from "@apollo/react-hooks";
import capitalize from "capitalize";

import {setClientsList} from "../../../../appRedux/actions";
import {FILTER_CLIENTS} from "../../../../graphql/query/client";
import {DELETE_CLIENT} from "../../../../graphql/mutation/client";
import {spinner, Error500, Undefined} from "../../../../util/customComponents";

const {Search} = Input;

const TableClient = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {clients_list} = useSelector(({client}) => client);

  const [query, setQuery] = useState(null);
  const [first, setFirst] = useState(20);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState(null);
  const [errorDisplayed, displayError] = useState(false);

  const {refetch, loading, networkStatus} = useQuery(FILTER_CLIENTS, {
    fetchPolicy: 'network-only',
    variables: {query, first, page},
    notifyOnNetworkStatusChange: true,
    onCompleted({filterClients}) {
      if (filterClients) {
        parseClientsList(filterClients.data);
        setPagination(filterClients.paginatorInfo);
      }
    },
    onError() {
      displayError(true);
    }
  });

  const parseClientsList = data => {
    let formattedData = [];
    if (data.length > 0) {
      data.forEach(client => {
        let document = `${client.document_type.description}: ${client.document_number}`;
        formattedData.push({...client, document});
      });
    }
    dispatch(setClientsList(formattedData));
  };

  const changeQuery = e => {
    const value = e.target.value;
    if (!value) {
      setQuery(null);
      refetch();
    }
  };

  const searchProject = value => {
    if (value) {
      setQuery(value);
      refetch();
    }
  };

  const [removeClient, {loading: deleting}] = useMutation(DELETE_CLIENT, {
    onCompleted({deleteClient}) {
      const filteredClients = clients_list.filter(client => client.id !== deleteClient.id);
      parseClientsList(filteredClients);
    },
    onError() {
      message.error("Ha ocurrido un error al intentar eliminar el cliente");
    }
  });

  const handleDeleteClient = client => {
    const modal = Modal.confirm({
      title: `¿Quieres eliminar este cliente?`,
      content: `Al hacer clic en el botón Aceptar, el cliente "${client.social_reason}" será eliminado definitivamente.`,
      okText: 'Aceptar',
      cancelText: 'Cancelar',
      okButtonProps: {
        loading: deleting,
        disabled: deleting
      },
      centered: true,
      onOk: async () => {
        modal.update({
          okButtonProps: {
            loading: true,
            disabled: true
          },
          cancelButtonProps: {
            disabled: true
          }
        });

        await removeClient({
          variables: {
            id: client.id
          }
        }).then(() => {
          modal.destroy();
        });
      }
    });
  };

  const handleEditClient = client => {
    history.push(`/cms/clientes/editar/${btoa(client.id)}`);
  };

  const handleManagementAds = client => {
    history.push(`/cms/clientes/${btoa(client.id)}/ads`);
  };

  const changePagination = page => {
    setPage(page);
    refetch();
  };

  const changeSizePagination = (current, size) => {
    setFirst(size);
    refetch();
  };

  if (errorDisplayed) return Error500();

  const columns = [
    {
      title: "Documento",
      dataIndex: "document",
      key: "document",
      width: "10%",
    },
    {
      title: "Razón Social",
      dataIndex: "social_reason",
      key: "social_reason",
      width: "25%",
      className: "gx-text-uppercase",
    },
    {
      title: 'Teléfono',
      key: 'phone',
      children: [
        {
          title: "Principal",
          dataIndex: "first_phone_number",
          key: "first_phone_number",
          width: "10%",
          render: value => value || Undefined('-')
        },
        {
          title: "Secundario",
          dataIndex: "second_phone_number",
          key: "second_phone_number",
          width: "10%",
          render: value => value || Undefined('-')
        }
      ]
    },
    {
      title: "Correo",
      dataIndex: "email",
      key: "email",
      width: "15%",
      render: value => value || Undefined('-')
    },
    {
      title: "Distrito",
      dataIndex: null,
      key: "disctrict",
      width: "20%",
      render: (value, client) => {
        if (client.ubigeo) {
          return capitalize.words(`${client.ubigeo.district} / ${client.ubigeo.province} / ${client.ubigeo.department}`);
        } else {
          return Undefined('-');
        }
      }
    },
    {
      key: "actions",
      align: "center",
      width: "10%",
      render: (value, client) => (
        <Fragment>
          <Tooltip title="Anuncios">
            <Button size="small" className="gx-m-0 gx-mr-2" onClick={() => handleManagementAds(client)}>
              <i className="fas fa-ad"/>
            </Button>
          </Tooltip>
          <Tooltip title="Editar">
            <Button size="small" className="gx-m-0 gx-mr-2" onClick={() => handleEditClient(client)}>
              <i className="far fa-pencil"/>
            </Button>
          </Tooltip>
          <Tooltip title="Eliminar">
            <Button size="small" className="gx-m-0" onClick={() => handleDeleteClient(client)}>
              <i className="far fa-trash gx-text-danger"/>
            </Button>
          </Tooltip>
        </Fragment>
      )
    },
  ];

  return (
    <Auxiliary>
      <Row gutter={16}>
        <Col xl={12} lg={12} md={12} sm={16} xs={16}>
          <Search
            allowClear
            placeholder="Buscar Cliente"
            onChange={changeQuery}
            onSearch={searchProject}
            disabled={networkStatus === 4}
          />
        </Col>
        <Col xl={12} lg={12} md={12} sm={8} xs={8} className="gx-text-right">
          <Tooltip title="Actualizar">
            <Button onClick={() => refetch()} className="gx-mr-2" icon={networkStatus === 4 ? 'loading' : 'sync'}/>
          </Tooltip>
          <Button
            type="primary"
            onClick={() => props.history.push("/cms/clientes/agregar")}
            icon="plus"
            className="gx-ml-0"
            disabled={networkStatus === 4}
          >
            Nuevo
          </Button>
        </Col>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Table
            dataSource={clients_list}
            loading={{
              indicator: spinner,
              spinning: loading || (networkStatus === 4)
            }}
            expandedRowRender={client => (
              <Fragment>
                <List
                  header={(
                    <div className="gx-full-width gx-font-weight-semi-bold">
                      <i className="far fa-info-circle gx-mr-2"/>
                      INFORMACIÓN ADICIONAL
                    </div>
                  )}
                  size="small"
                  bordered
                  className="gx-m-2 gx-mb-3"
                >
                  <List.Item>Dirección: {client.address || Undefined()}</List.Item>
                  <List.Item>
                    Distrito: {client.ubigeo ? capitalize.words(client.ubigeo.district) : Undefined()}
                  </List.Item>
                  <List.Item>
                    Provincia: {client.ubigeo ? capitalize.words(client.ubigeo.province) : Undefined()}
                  </List.Item>
                  <List.Item>
                    Departamento: {client.ubigeo ? capitalize.words(client.ubigeo.department) : Undefined()}
                  </List.Item>
                </List>
                <List
                  header={(
                    <div className="gx-full-width gx-font-weight-semi-bold">
                      <i className="far fa-user-circle gx-mr-2"/>
                      INFORMACIÓN DE CONTACTO
                    </div>
                  )}
                  size="small"
                  bordered
                  className="gx-m-2"
                >
                  <List.Item>Nombre Completo: {client.contact_name || Undefined()}</List.Item>
                  <List.Item>Correo Electrónico: {client.contact_email || Undefined()}</List.Item>
                  <List.Item>Teléfono: {client.contact_phone_number || Undefined()}</List.Item>
                </List>
              </Fragment>
            )}
            rowKey={(row, key) => key}
            columns={columns}
            bordered
            size="small"
            pagination={false}
            className="gx-table-responsive"
          />
          {clients_list.length > 0 && (
            <Pagination
              size="small"
              className="gx-mt-2 gx-float-right"
              defaultCurrent={page}
              showSizeChanger={true}
              locale={{items_per_page: ''}}
              onShowSizeChange={changeSizePagination}
              pageSizeOptions={['5', '10', '20', '50', '100']}
              total={pagination ? pagination.lastPage : null}
              pageSize={first}
              onChange={changePagination}
            />
          )}
        </Col>
      </Row>
    </Auxiliary>
  );
};

export default TableClient;
