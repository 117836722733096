import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Popover, Button } from "antd";

const TableTitle = props => {
  const {
    title,
    titleIcon = "stream",
    popoverContent = "Doble Click en fila para ir al detalle.",
    tooltip = false,
    icon = "mouse",
    GuideButton
  } = props;
  return (
    <div className="gx-d-flex gx-align-items-center gx-full-width">
      <h2 className="h6 gx-font-weight-semi-bold gx-p-0 gx-m-0">
        <i className={`fal fa-${titleIcon} gx-mr-2`} />
        {title ? title.toUpperCase() : title}
      </h2>
      {tooltip ? (
        <Popover
          placement="right"
          content={
            <Fragment>
              <i className={`fad fa-${icon} gx-text-info gx-pr-2`} />
              {popoverContent}
            </Fragment>
          }
        >
          <Button size="small" className="gx-ml-auto gx-mr-1 gx-mb-0">Ayuda</Button>
        </Popover>
      ) : null}
      {GuideButton && <GuideButton/>}
    </div>
  );
};

TableTitle.propTypes = {
  title: PropTypes.string.isRequired,
  titleIcon: PropTypes.string,
  popoverContent: PropTypes.string,
  tooltip: PropTypes.bool,
  icon: PropTypes.string,
  GuideButton: PropTypes.func
};

export default TableTitle;
