import {
  FETCH_ADDITIONALS,
  SHOW_MODAL_CONFIG_ADDITIONALS,
  START_SAVING_ADDITIONALS,
  FETCH_SUCCESS_SAVING_ADDITIONALS,
  FETCH_ERROR_SAVING_ADDITIONALS,
  FETCH_START_DELETING_ADDITIONALS,
  FETCH_SUCCESS_DELETING_ADDITIONALS,
  FETCH_ERROR_DELETING_ADDITIONALS,
  UPDATE_ADDITIONALS,
  DELETE_ADDITIONALS,
  SAVE_ADDITIONALS
} from "constants/ActionTypes";

const initialState = {
  modalConfigAdditionals: {
    visible: false,
    info: null
  },
  configAdditionalsList: [],
  configSaving: false,
  configUpdating: false,
  configDeleting: false,
  configMessage: "",
  configError: "",
  configSelectedId: null
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case START_SAVING_ADDITIONALS: {
      return {
        ...state,
        configError: "",
        configMessage: "",
        configSaving: true
      };
    }
    case FETCH_SUCCESS_SAVING_ADDITIONALS: {
      return {
        ...state,
        configError: "",
        configMessage: "",
        configSaving: false,
        modalConfigAdditionals: {
          visible: false
        }
      };
    }
    case FETCH_ERROR_SAVING_ADDITIONALS: {
      return {
        ...state,
        configError: payload,
        configMessage: "",
        configSaving: false
      };
    }

    case FETCH_START_DELETING_ADDITIONALS: {
      return {
        ...state,
        configError: "",
        configMessage: "",
        configDeleting: true,
        configSelectedId: payload
      };
    }
    case FETCH_SUCCESS_DELETING_ADDITIONALS: {
      return {
        ...state,
        configError: "",
        configMessage: payload,
        configDeleting: false,
        configSelectedId: null
      };
    }
    case FETCH_ERROR_DELETING_ADDITIONALS: {
      return {
        ...state,
        configError: payload,
        configMessage: "",
        configDeleting: false,
        configSelectedId: null
      };
    }

    //system >configuration >generals
    case FETCH_ADDITIONALS: {
      return {
        ...state,
        configAdditionalsList: payload
      };
    }

    case SAVE_ADDITIONALS: {
      return {
        ...state,
        configAdditionalsList: state.configAdditionalsList.concat(payload)
      };
    }
    case UPDATE_ADDITIONALS: {
      const newList = state.configAdditionalsList.filter(
        additional => additional.nId !== state.modalConfigAdditionals.info.nId
      );
      return {
        ...state,
        configAdditionalsList: newList.concat(payload)
      };
    }
    case DELETE_ADDITIONALS: {
      return {
        ...state,
        configAdditionalsList: state.configAdditionalsList.filter(
          additional => additional.nId !== payload
        )
      };
    }

    // Modal > Maestros Generales
    case SHOW_MODAL_CONFIG_ADDITIONALS: {
      if (payload.visible) {
        return {
          ...state,
          modalConfigAdditionals: {
            visible: payload.visible,
            info: payload.row
          }
        };
      } else {
        return {
          ...state,
          modalConfigAdditionals: {
            visible: payload.visible,
            info: null
          }
        };
      }
    }
    default:
      return state;
  }
};

// H7vmwr9JnqtKYIch
