import {
  TOGGLE_MODAL_SHOPS,
  SET_LIST_SHOP,
  DELETE_SHOP_TABLE,
} from "../../constants/ActionTypes";
export const rxToggleShopsModal = (payload) => ({
  type: TOGGLE_MODAL_SHOPS,
  payload,
});

export const rxSetListShop = (payload = []) => ({
  type: SET_LIST_SHOP,
  payload,
});

export const rxDeleteShop = (payload) => ({
  type: DELETE_SHOP_TABLE,
  payload,
});
