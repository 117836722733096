import React, { Fragment } from "react";
import { Icon, Table } from "antd";
import { currencyFE } from "util/config";
import TableTitle from "components/TableTitle";
import currency from "currency-formatter";
import PropTypes from "prop-types";

const columns = [
  {
    title: "CODIGO",
    dataIndex: "sCodArticulo",
    key: "sCodArticulo",
    width: "10%"
  },
  {
    title: "DESCRIPCIÓN",
    dataIndex: "sDescArticulo",
    key: "sDescArticulo"
  },
  {
    title: "STOCK",
    dataIndex: "nStock",
    key: "nStock",
    align: "center",
    render: text =>
      isNaN(text) ? (
        <Fragment>
          0 <Icon type="warning" className="gx-text-warning gx-pl-1" />
        </Fragment>
      ) : parseInt(text) > 0 ? (
        text
      ) : (
        <Fragment>
          0 <Icon type="warning" className="gx-text-warning gx-pl-1" />
        </Fragment>
      )
  },
  {
    title: "CANTIDAD",
    dataIndex: "nCantidad",
    key: "nCantidad",
    align: "center"
  },
  {
    title: "PRECIO FINAL",
    dataIndex: "nPrecioFinal",
    key: "nPrecioFinal",
    align: "right",
    render: text =>
      text ? currency.format(Number(text).toFixed(2), currencyFE) : "0.00"
  },
  {
    title: "TOTAL",
    dataIndex: "nTotal",
    key: "nTotal",
    align: "right",
    render: text =>
      text ? currency.format(Number(text).toFixed(2), currencyFE) : "0.00"
  }
];

const calcTotal = dataSource => {
  if (Array.isArray(dataSource) && dataSource.length > 0) {
    const total = dataSource.reduce((prev, curr) => prev + curr.nTotal, 0);
    return total
      ? currency.format(Number(total).toFixed(2), currencyFE)
      : "0.00";
  }
};

const Cotizacion = props => {
  const { dataSource, loading } = props;
  return (
    <Table
      title={() => <TableTitle title="ARTICULOS" />}
      className="gx-mb-3"
      size="middle"
      bordered
      pagination={false}
      loading={loading}
      rowKey={(row, dataIndex) => `${dataIndex + 1}_CTZ`}
      dataSource={dataSource}
      columns={columns}
      footer={() => (
        <div className="gx-d-flex gx-justify-content-end">
          <div>
            <span className="h5 gx-font-weight-medium gx-text-success">
              Total:
            </span>
            <span className="h5 gx-font-weight-medium gx-pl-1">
              {calcTotal(dataSource)}
            </span>
          </div>
        </div>
      )}
    />
  );
};

Cotizacion.propTypes = {
  dataSource: PropTypes.array,
  loading: PropTypes.bool
};

export default Cotizacion;
