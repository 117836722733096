import React, { Component } from "react";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  withGoogleMap,
} from "react-google-maps";
import canUseDOM from "can-use-dom";

let timer = 0;
const google = window.google;
const geolocation =
  canUseDOM && navigator.geolocation
    ? navigator.geolocation
    : {
        getCurrentPosition(success, failure) {
          failure("Tu navegador no soporta geolocalización");
        },
      };

const PopUpInfoWindowShopsGoogleMap = withGoogleMap((props) => {
  const { zoom, center, refGoogleMaps, dataSource } = props;
  const options = {
    scrollwheel: true,
    mapTypeControl: false,
    fullscreenControl: false,
    zoomControlOptions: {
      position: google.maps.ControlPosition.TOP_RIGHT,
      style: google.maps.ZoomControlStyle.SMALL,
    },
    mapTypeControlOptions: {
      position: google.maps.ControlPosition.TOP_RIGHT,
    },
    draggable: true,
    rotateControl: true,
    scaleControl: true,
    streetViewControl: false,
    panControl: true,
  };
  return (
    <GoogleMap
      defaultZoom={zoom || 15}
      center={center}
      defaultOptions={options}
      onCenterChanged={function () {
        props.onCenterChanged(this);
      }}
      ref={refGoogleMaps}
    >
      {Array.isArray(dataSource) &&
        dataSource.length > 0 &&
        dataSource.map((marker, index) => {
          if (marker) {
            const lat = Number(marker.lat) || null;
            const lng = Number(marker.lng) || null;
            const cordenada = { lat, lng };
            return (
              <Marker
                icon={require("assets/images/gps_2.png")}
                key={index}
                position={cordenada}
                onClick={() => props.onMarkerClick(marker)}
                visible={true}
              >
                {marker.showInfo === true ? (
                  marker.user_type ? (
                    <InfoWindow
                      onCloseClick={() => props.onMarkerClose(marker)}
                    >
                      <div className="gx-popup-map-more-info">
                        <h1>{`${marker.name ? marker.name : "-"}, ${
                          marker.name ? marker.last_name : "-"
                        }`}</h1>
                        <p>
                          <i className="fal fa-map-marker-alt gx-text-primary gx-pr-1" />
                          {marker.address ? marker.address : "-"}
                        </p>
                        <p>
                          <i className="gx-text-primary gx-pr-1 fal fa-phone-plus" />
                          {marker.cellphone ? marker.cellphone : "-"}
                        </p>
                      </div>
                    </InfoWindow>
                  ) : (
                    <InfoWindow
                      onCloseClick={() => props.onMarkerClose(marker)}
                    >
                      <div className="gx-popup-map-more-info">
                        <h1>{marker.name ? marker.name : "-"}</h1>
                        <p>
                          <i className="fal fa-map-marker-alt gx-text-primary gx-pr-1" />
                          {marker.address ? marker.address : "-"}
                        </p>
                        <p>
                          <i className="gx-text-primary gx-pr-1 fal fa-phone-plus" />
                          {marker.phone_numbers
                            ? marker.phone_numbers.replace(/,/g, " / ")
                            : "-"}
                        </p>
                      </div>
                    </InfoWindow>
                  )
                ) : null}
              </Marker>
            );
          } else {
            return null;
          }
        })}
      {props.myLocation && <Marker position={props.myLocation} />}
    </GoogleMap>
  );
});

class Map extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bounds: null,
      zoom: 15,
      content: null,
      myLocation: null,
      center: null,
      showFreshBtn: false,
    };
    this.refGoogleMaps = React.createRef(null);
  }
  componentDidMount() {
    this.onMountPage();
  }
  async componentDidUpdate(prevProps) {
    const { activeKey } = this.props;
    if (prevProps.activeKey !== activeKey) {
      await this.getPosition(activeKey);
    }
  }

  getPosition = async (currenTab) => {
    const { bounds } = this.state;
    await clearInterval(timer);
    timer = await setTimeout(() => {
      const data =
        bounds ||
        (this.refGoogleMaps &&
          this.refGoogleMaps.current &&
          this.refGoogleMaps.current.getBounds() &&
          this.refGoogleMaps.current.getBounds().toJSON());
      data && this.props.getCarpenterShop(data, currenTab);
      this.setState({ showFreshBtn: false });
    }, 1000);
  };

  onMountPage = () => {
    const { activeKey } = this.props;
    geolocation.getCurrentPosition(
      (position) => {
        if (position) {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          this.setState(
            {
              center: { lat, lng },
              myLocation: { lat, lng },
              zoom: 15,
              content: `Ubicación encontrada usando HTML5`,
            },
            () => {
              this.getPosition(activeKey);
            }
          );
        }
      },
      (reason) => {
        this.setState({
          center: { lat: -12.0262676, lng: -77.1278634 },
          myLocation: { lat: -12.0262676, lng: -77.1278634 },
          zoom: 15,
          content: `Error: El servicio de geolocalización falló (${reason}).`,
        });
      }
    );
  };

  onCenterChanged = (that) => {
    const bounds = that.getBounds() ? that.getBounds().toJSON() : null;
    const cordenadas = that.getCenter() ? that.getCenter().toJSON() : null;
    if (cordenadas && bounds) {
      this.setState({ showFreshBtn: true, center: cordenadas, bounds });
    }
  };

  render() {
    const {
      height,
      activeKey,
      dataSource,
      handleMarkerClick,
      handleMarkerClose,
      loading,
    } = this.props;
    const { center, myLocation, bounds, zoom, showFreshBtn } = this.state;
    return (
      <div>
        {myLocation && center ? (
          (myLocation.lat !== center.lat || myLocation.lng !== center.lng) &&
          showFreshBtn === true ? (
            <button
              className="gx-btn-refresh-map"
              onClick={() => this.getPosition(activeKey)}
            >
              <i className="fal fa-search gx-pr-1 gx-text-primary" />
              {loading ? "Buscando..." : "Buscar en esta área"}
            </button>
          ) : null
        ) : null}

        <PopUpInfoWindowShopsGoogleMap
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height }} />}
          mapElement={<div style={{ height: `100%` }} />}
          center={center}
          myLocation={myLocation}
          dataSource={dataSource}
          onMarkerClick={handleMarkerClick}
          onMarkerClose={handleMarkerClose}
          bounds={bounds}
          zoom={zoom}
          onCenterChanged={this.onCenterChanged}
          refGoogleMaps={this.refGoogleMaps}
        />
      </div>
    );
  }
}
export default React.memo(Map);
