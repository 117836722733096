import React, { Component, Fragment } from "react";
import {
  Form,
  Row,
  Col,
  Input,
  DatePicker,
  Select,
  Radio,
  Button,
  Spin
} from "antd";
import Helmet from "react-helmet";
import { connect } from "react-redux";
// import { companyName } from "util/config";
import { schema } from "./schema";

import Auxiliary from "util/Auxiliary";
import moment from "moment";

import { getUserReniec } from "appRedux/actions/ReniecSunat";
import { searchLocation, getGeneralsByEntity } from "appRedux/actions";
import { getAdditionals } from "appRedux/actions/ConfigAdicionales";
import { saveClients, getRetrieveClient, getDataSunat } from "appRedux/actions";

const FormItem = Form.Item;
const { Option } = Select;

class FormDataGeneral extends Component {
  constructor(props) {
    super(props);
    this.state = {
      documentLength: 8,
      typeData: false,
      isCompany: false,
      documentType: 43,
      documentNumber: '',
      names: '',
      email: '',
      address: '',
      ubigeo: undefined,
      phone: ''
    };
  }

  componentWillMount() {
    this.props.getGeneralsByEntity({sEntidad: 'TIPO_DOCUMENTO'});
  }

  componentDidMount() {
    this.setState({ typeData: false, isCompany: false });
  }

  clearStateFormClient = () => {
    this.props.form.resetFields();
    this.setState({
      documentType: 43,
      documentNumber: '',
      names: '',
      email: '',
      address: '',
      ubigeo: undefined,
      phone: ''
    });
  }

  async componentDidUpdate(prevProps) {
    const { userReniecData, clientEditing, retrieveInfoClient, dataSunat } = this.props;
    const { typeData } = this.state;
    if (retrieveInfoClient.info && retrieveInfoClient.info !== prevProps.retrieveInfoClient.info && !retrieveInfoClient.loading) {
      const distrito = retrieveInfoClient.info.sDistrito;
      this.handleOnSearchLocation(distrito);
    }
    /**
     * P.NATURAL
     */
    if (prevProps.userReniecData !== userReniecData && typeData === true) {
      this.setState({ typeData: false });
      this.props.form.setFieldsValue({
        sDocumento: userReniecData.dni,
        sNombres: userReniecData.fullName
      });
    }
    /**
     * P.JURIDICA
     */
    if (prevProps.dataSunat !== dataSunat && typeData === true) {
      this.setState({ typeData: false });
      dataSunat.distrito &&
        (await this.handleOnSearchLocation(dataSunat.distrito));
      this.props.form.setFieldsValue({
        sDocumento: dataSunat.ruc,
        sNombres: dataSunat.razon_social,
        sDireccion: dataSunat.direccion_referencia,
        sDistrito: dataSunat.sUbigeo
      });
    }
    // distrito

    if (prevProps.clientEditing !== clientEditing) {
      if (clientEditing) {
        // Initial Location - Distrito
        if (clientEditing.Ubigeo) {
          const sDistritoSearch = clientEditing.Ubigeo.sDistrito
            ? clientEditing.Ubigeo.sDistrito.substring(0, 5)
            : "";
          this.handleOnSearchLocation(sDistritoSearch);
        }

        this.props.form.setFieldsValue({
          sNombres: clientEditing.sNombres,
          sCorreo:
            clientEditing.sEmail !== "undefined" ? clientEditing.sEmail : "",
          sDireccion:
            clientEditing.sDireccion !== "undefined"
              ? clientEditing.sDireccion
              : "",
          sDistrito: clientEditing.Ubigeo ? clientEditing.Ubigeo.sUbigeo : "",
          sDocumento: clientEditing.sDocumento,
          sGenero: clientEditing.sSexo,
          sProfesion:
            clientEditing.sProfesion !== "undefined"
              ? clientEditing.sProfesion
              : "",
          sTelefono: clientEditing.sTelefono1,
          sTipoDocumento: clientEditing.nIdTipoDoc,
          dFechaNacimiento: clientEditing.dNacimiento
            ? moment(new Date(clientEditing.dNacimiento), "DD/MM/YYYY").add(
                2,
                "days"
              )
            : null
        });
      }
    }
  }

  handleCallback = (nIdCliente, cliNombre) => {
    if (nIdCliente && cliNombre) {
      this.props.changeRegisteredCliente(nIdCliente, cliNombre);
      //this.props.searchClients(this.props.optimizerID, $searchValue);
    }
    this.props.handleCancelModalClient(this.clearStateFormClient);
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { nIdClient } = this.props;
        const generalData = {
          dNacimiento: values.dFechaNacimiento
            ? values.dFechaNacimiento.format("YYYY-MM-DD")
            : moment().format("YYYY-MM-DD"),
          sNombres: values.sNombres || null,
          sCorreo: values.sCorreo || null,
          sDireccion: values.sDireccion || null,
          nUbigeo: values.sDistrito || null,
          sDocumento: values.sDocumento || null,
          sSexo: values.sGenero || null,
          sProfesion: values.sProfesion || null,
          sTelefono1: values.sTelefono || null,
          nIdTipoDoc: values.sTipoDocumento || null,
          nIdUsuario: this.props.optimizerID,
          sUser: 'eCarpintero'
        };
        const id = nIdClient && this.props.action === 'edit' ? nIdClient : null;
        this.props.saveClients({
          generalData: generalData,
          form: this.props.form,
          id: id,
          callBack: this.handleCallback
        });
      }
    });
  };

  // Cambiar tipo documento
  handleOnSelectTipoDocumento = (value, option) => {
    if (option) {
      const { key } = option;
      this.setState({
        documentLength: parseInt(value) === 43 ? 8 : 12,
        isCompany: key === "RUC" ? true : false,
        documentType: value
      });
    }
  };

  // Verificar datos usuario en Reniec
  handleOnSearch = event => {
    const { value } = event.target;
    const { isCompany } = this.state;
    this.setState({ typeData: true, documentNumber: event.target.value });
    if (isCompany && value.length >= 10) {
      this.props.getDataSunat({ sRuc: value });
    } else if (value.length >= 7) {
      this.props.getUserReniec({ sDni: value });
    }
  };

  // clinic > patients > add -- Busqueda Ubigeo

  handleOnSearchLocation = value => {
    this.props.searchLocation({ sLocation: value });
  };

  // Can not select days after today and today
  handleDisabledDate = current => {
    return current && current > moment().endOf("day");
  };

  render() {
    const { documentLength, loadContact } = this.state;
    const { getFieldDecorator } = this.props.form;
    const {
      geting,
      generalsList,
      locationList,
      searching,
      loading,
      saving,
      nIdClient,
      isQuickRecord = false,
      retrieveInfoClient
    } = this.props;

    return (
      <Auxiliary>
        <Helmet>
          <title>
            eCarpintero - Clientes
          </title>
        </Helmet>

        <Row>
          <Col span={24}>
            <Form onSubmit={this.handleSubmit} hideRequiredMark={true} className="gx-form-row0">
              <Spin spinning={loading || saving || retrieveInfoClient.loading}>
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={6} lg={6}>
                    <FormItem
                      className="form-item-imd"
                      label={schema.sDocumento.label}
                      hasFeedback
                      validateStatus={
                        geting && loadContact !== true ? "validating" : null
                      }
                    >
                      {getFieldDecorator(schema.sDocumento.name, {
                        initialValue: this.state.documentNumber || (retrieveInfoClient.info ? retrieveInfoClient.info.sDocumento : ''),
                        rules: [
                          {
                            required: schema.sDocumento.rule.required,
                            message: schema.sDocumento.rule.message
                          }
                        ]
                      })(
                        <Input
                          maxLength={documentLength}
                          onChange={this.handleOnSearch}
                          placeholder={schema.sDocumento.placeHolder}
                          autoComplete="off"
                          autoFocus={true}
                          addonBefore={getFieldDecorator(
                            schema.sTipoDocumento.name,
                            {
                              initialValue: this.state.documentType || (retrieveInfoClient.info ? retrieveInfoClient.info.nIdTipoDoc : 43)
                            },
                            {
                              rules: [
                                {
                                  required:
                                    schema.sTipoDocumento.rule.required,
                                    message: schema.sTipoDocumento.rule.message
                                }
                              ]
                            }
                          )(
                            <Select
                              showSearch
                              placeholder={
                                schema.sTipoDocumento.placeHolder
                              }
                              optionFilterProp="children"
                              style={{ width: 100 }}
                              onSelect={(value, option) =>
                                this.handleOnSelectTipoDocumento(
                                  value,
                                  option
                                )
                              }
                              filterOption={(input, option) =>
                                option.props.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {generalsList &&
                                generalsList.map(general =>
                                  general.sEntidad === "TIPO_DOCUMENTO" ? (
                                    <Option
                                      value={general.nId}
                                      key={general.sCodigo}
                                    >
                                      {general.sCodigo}
                                    </Option>
                                  ) : null
                                )}
                            </Select>
                          )}
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col xs={24} sm={24} md={18} lg={18}>
                    <FormItem
                      className="form-item-imd"
                      label={schema.sNombres.label}
                    >
                      {getFieldDecorator(schema.sNombres.name, {
                        initialValue: this.state.names || (retrieveInfoClient.info ? retrieveInfoClient.info.sNombres : ''),
                        rules: [
                          {
                            required: schema.sNombres.rule.required,
                            message: schema.sNombres.rule.message
                          }
                        ]
                      })(
                        <Input
                          type={schema.sNombres.type}
                          placeholder={schema.sNombres.placeHolder}
                          onChange={e => this.setState({names: e.target.value})}
                        />
                      )}
                    </FormItem>
                  </Col>
                  {!isQuickRecord ? (
                    <Fragment>
                      <Col xs={24} sm={24} md={3} lg={3}>
                        <FormItem
                          className="form-item-imd"
                          label={schema.dFechaNacimiento.label}
                        >
                          {getFieldDecorator(schema.dFechaNacimiento.name, {
                            rules: [
                              {
                                required:
                                  schema.dFechaNacimiento.rule.required,
                                  message: schema.dFechaNacimiento.rule.message
                              }
                            ]
                          })(
                            <DatePicker
                              disabledDate={this.handleDisabledDate}
                              format={schema.dFechaNacimiento.format}
                              style={{
                                width: schema.dFechaNacimiento.width
                              }}
                              placeholder={
                                schema.dFechaNacimiento.placeHolder
                              }
                            />
                          )}
                        </FormItem>
                      </Col>

                      <Col xs={24} sm={24} md={3} lg={3}>
                        <FormItem
                          className="form-item-imd"
                          label={schema.sProfesion.label}
                        >
                          {getFieldDecorator(schema.sProfesion.name, {
                            rules: [
                              {
                                required: schema.sProfesion.rule.required,
                                message: schema.sProfesion.rule.message
                              }
                            ]
                          })(
                            <Input
                              type={schema.sProfesion.type}
                              placeholder={schema.sProfesion.placeHolder}
                            />
                          )}
                        </FormItem>
                      </Col>
                      <Col xs={24} sm={24} md={6} lg={6}>
                        <FormItem
                          className="form-item-imd"
                          label={schema.sGenero.label}
                        >
                          {getFieldDecorator(schema.sGenero.name, {
                            rules: [
                              {
                                required: schema.sGenero.rule.required,
                                message: schema.sGenero.rule.message
                              }
                            ]
                          })(
                            <Radio.Group buttonStyle="solid">
                              <Radio.Button value="M">
                                Masculino
                              </Radio.Button>
                              <Radio.Button value="F">
                                Femenino
                              </Radio.Button>
                              <Radio.Button value="O">Otros</Radio.Button>
                            </Radio.Group>
                          )}
                        </FormItem>
                      </Col>
                    </Fragment>
                  ) : null}
                  <Col xs={24} sm={24} md={6} lg={6}>
                    <FormItem
                      className="form-item-imd"
                      label={schema.sTelefono.label}
                    >
                      {getFieldDecorator(schema.sTelefono.name, {
                        initialValue: this.state.phone || (retrieveInfoClient.info ? retrieveInfoClient.info.sTelefono1 : ''),
                        rules: [
                          {
                            required: schema.sTelefono.rule.required,
                            message: schema.sTelefono.rule.message
                          }
                        ]
                      })(
                        <Input
                          type={schema.sTelefono.type}
                          placeholder={schema.sTelefono.placeHolder}
                          maxLength={9}
                          onChange={e => this.setState({phone: e.target.value})}
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col xs={24} sm={24} md={6} lg={6}>
                    <FormItem
                      className="form-item-imd"
                      label={schema.sCorreo.label}
                    >
                      {getFieldDecorator(schema.sCorreo.name, {
                        initialValue: this.state.email || (retrieveInfoClient.info ? retrieveInfoClient.info.sEmail : ''),
                        rules: [
                          {
                            required: schema.sCorreo.rule.required,
                            message: schema.sCorreo.rule.message
                          }
                        ]
                      })(
                        <Input
                          type={schema.sCorreo.type}
                          placeholder="Ingrese un correo electrónico"
                          onChange={e => this.setState({email: e.target.value})}
                        />
                      )}
                    </FormItem>
                  </Col>

                  <Fragment>
                    <Col xs={24} sm={24} md={6} lg={6}>
                      <FormItem
                        className="form-item-imd"
                        label={schema.sDireccion.label}
                      >
                        {getFieldDecorator(schema.sDireccion.name, {
                        initialValue: this.state.address || (retrieveInfoClient && retrieveInfoClient.info ? retrieveInfoClient.info.sDireccion : ''),
                          rules: [
                            {
                              required: schema.sDireccion.rule.required,
                              message: schema.sDireccion.rule.message
                            }
                          ]
                        })(
                          <Input
                            type={schema.sDireccion.type}
                            placeholder="Ingrese una dirección"
                            onChange={e => this.setState({address: e.target.value})}
                          />
                        )}
                      </FormItem>
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={6}>
                      <FormItem
                        className="form-item-imd"
                        label={schema.sDistrito.label}
                      >
                        {getFieldDecorator(schema.sDistrito.name, {
                          initialValue: this.state.ubigeo || (retrieveInfoClient.info ? retrieveInfoClient.info.nUbigeo : undefined),
                          rules: [
                            {
                              required: schema.sDistrito.rule.required,
                              message: schema.sDistrito.rule.message
                            }
                          ]
                        })(
                          <Select
                            showSearch
                            allowClear
                            loading={searching}
                            placeholder={schema.sDistrito.placeHolder}
                            onSelect={value => this.setState({ubigeo: value})}
                            onSearch={this.handleOnSearchLocation}
                            ref={input => {
                              this.locationInput = input;
                            }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.props.searchvalue
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {locationList.length &&
                              locationList.map(location => (
                                <Option
                                  value={location.sUbigeo}
                                  searchvalue={`${location.sDistrito} ${location.sProvincia} ${location.sDepartamento}`}
                                  key={location.sUbigeo}
                                >{`${location.sDistrito} / ${location.sProvincia} / ${location.sDepartamento}`}</Option>
                              ))}
                          </Select>
                        )}
                      </FormItem>
                    </Col>
                  </Fragment>

                  <Col xs={24} sm={24} md={24} lg={24} className="gx-mt-3">
                    <FormItem className="form-item-imd gx-d-flex gx-justify-content-end">
                      <Button
                        type="primary"
                        htmlType="submit"
                        icon={nIdClient ? "edit" : "save"}
                        disabled={saving}
                        loading={saving}
                        className="gx-mb-0"
                      >
                        {nIdClient
                          ? saving
                            ? "...actualizando"
                            : "Grabar"
                          : saving
                          ? "...registrando"
                          : "Grabar"}
                      </Button>
                      <Button disabled={saving} className="gx-mb-0" onClick={() => this.props.handleCancelModalClient(this.clearStateFormClient)}>
                        Cancelar
                      </Button>
                    </FormItem>
                  </Col>
                </Row>
              </Spin>
            </Form>
          </Col>
        </Row>
      </Auxiliary>
    );
  }
}
const WrappedFormDataGeneral = Form.create("FormDataGeneral")(FormDataGeneral);
const mapStateToProps = ({
  auth,
  settings,
  reniecSunat,
  general,
  ubigeo,
  configadicionales,
  common,
  clients
}) => {
  const { pathname } = settings;
  const { optimizerID } = auth;
  const { userReniecData, dataSunat, geting } = reniecSunat;
  const { generalsListByEntity: generalsList } = general;
  const { locationList, searching } = ubigeo;
  const { configAdditionalsList } = configadicionales;
  const { loading } = common;
  const { clientEditing, savingClients: saving, infoClient, retrieveInfoClient } = clients;
  return {
    pathname,
    optimizerID,
    userReniecData,
    dataSunat,
    geting,
    generalsList,
    locationList,
    searching,
    configAdditionalsList,
    loading,
    saving,
    clientEditing,
    infoClient,
    retrieveInfoClient
  };
};

export default connect(mapStateToProps, {
  getUserReniec,
  searchLocation,
  getAdditionals,
  saveClients,
  getRetrieveClient,
  getDataSunat,
  getGeneralsByEntity
})(WrappedFormDataGeneral);
