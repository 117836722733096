import gql from 'graphql-tag';

export const RETRIEVE_ADVERTISEMENT = gql`
  query retrieveAdvertisement($id: ID!) {
    retrieveAdvertisement(id: $id) {
      id
      location
      redirect_url
      from
      expire
      click
      multimedia {
        id
        url
      }
      tags
    }
  }
`;

export const RANDOM_ADVERTISEMENT = gql`
  query randomAdvertisement(
    $first: Int
    $where: RandomAdvertisementWhereWhereConditions
  ) {
    randomAdvertisement(first: $first, where: $where) {
      data {
        id
        redirect_url
        click
        multimedia {
          id
          url
        }
      }
      paginatorInfo {
        hasMorePages
      }
    }
  }
`;
