const PrintStyle = (tamanio, margen = "0.5cm 1cm 0.5cm 1cm") => {
  let style = document.createElement("style");
  style.rel = "stylesheet";
  style.media = "print";
  style.textContent = `
              @media print{
                .gx-main-content-wrapper {
                display: none;
                }

                .container_ticket-imd {
                -webkit-print-color-adjust: exact;
                display: block;
                }
                @page {
                  size:${tamanio};
                  margin: ${margen};
                }
              }`;
  style.appendChild(document.createTextNode(""));
  document.head.appendChild(style);
};

export { PrintStyle };
