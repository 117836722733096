import React, {useState, useRef} from "react";
import {Form, Row, Col, Input, Select, Button, Spin, Divider, message, AutoComplete} from "antd";
import {useHistory} from 'react-router-dom';

import Auxiliary from "util/Auxiliary";
import {useLazyQuery, useMutation, useQuery} from "@apollo/react-hooks";
import {GET_MASTERS} from "../../../../graphql/query/master";
import {CREATE_CLIENT} from "../../../../graphql/mutation/client";
import {Error500, spinner} from "../../../../util/customComponents";
import {FILTER_UBIGEOS} from "../../../../graphql/query/ubigeo";

const FormItem = Form.Item;
const {Option} = Select;

let timer = 0;

const ClientForm = props => {
  const history = useHistory();
  const _documentNumber = useRef();
  const _documentType = useRef();

  const [query, setQuery] = useState(null);
  const [ubigeos, setUbigeos] = useState(null);
  const [documentTypes, setDocumentTypes] = useState(null);
  const [displayedError, setDisplayError] = useState(false);
  const {getFieldDecorator, validateFields, setFields, getFieldValue, resetFields} = props.form;

  const {loading} = useQuery(GET_MASTERS, {
    variables: {
      column: "ENTITY",
      operator: "EQ",
      value: "TIPO_DOCUMENTO",
    },
    onCompleted({masters}) {
      setDocumentTypes(masters);
    },
    onError() {
      setDisplayError(true);
    }
  });

  const [filterUbigeo, {loading: searchingUbigeo}] = useLazyQuery(FILTER_UBIGEOS, {
    skip: !query,
    onCompleted({filterUbigeos}) {
      if (filterUbigeos && filterUbigeos.data) {
        setUbigeos(filterUbigeos.data);
      }
    },
    onError() {
      message.error('Ha ocurrido un error al consultar ubigeos');
    }
  });

  const [saveClient, {loading: saving}] = useMutation(CREATE_CLIENT, {
    onCompleted() {
      message.success('Cliente registrado correctamente');
      resetFields();
      _documentType.current.focus();
    },
    onError({graphQLErrors, networkError}) {
      if (graphQLErrors) {
        graphQLErrors.forEach(({extensions}) => {
          if (extensions.validation) {
            Object.keys(extensions.validation).forEach((key, index) => {
              let field = null;
              switch (key.toString()) {
                case 'input.email': {
                  field = 'email';
                  break;
                }
                case 'input.document_number': {
                  field = 'document_number';
                  break;
                }
                default:
                  break;
              }
              if (field) {
                setFields({
                  [field]: {
                    value: getFieldValue(field),
                    errors: [new Error(extensions.validation[key][0])]
                  }
                });
              }
            });
          }
        });
      }
      message.warning('Por favor corrija los siguiente errores.');
    }
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        const variables = {
          input: {
            document_type_id: values.document_type,
            document_number: values.document_number,
            social_reason: values.social_reason,
            email: values.email,
            first_phone_number: values.first_phone_number || null,
            second_phone_number: values.second_phone_number || null,
            address: values.address || null,
            ubigeo_id: values.ubigeo_id || null,
            contact_name: values.contact_name || null,
            contact_email: values.contact_email || null,
            contact_phone_number: values.contact_phone_number || null
          }
        };
        saveClient({variables});
      }
    });
  };

  const handleOnCancel = () => {
    history.goBack("/cms/clientes");
  };

  const changeFocus = _reference => {
    _reference.current.focus();
  }

  const handleSearch = async value => {
    if (value) {
      await clearInterval(timer);
      const variables = {query: value};
      timer = await setTimeout(() => filterUbigeo({variables}), 700);
    } else {
      setUbigeos(null);
    }
  };

  const renderOption = item => {
    return (
      <Option key={item.id} value={item.id}>
        {`${item.district} / ${item.province} / ${item.department}`}
      </Option>
    );
  };

  if (displayedError) return Error500();

  return (
    <Auxiliary>
      <Form
        onSubmit={handleSubmit}
        hideRequiredMark={true}
        className="gx-form-row0"
      >
        <Spin spinning={loading || saving} indicator={spinner}>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <h2>FORMULARIO DE NUEVO REGISTRO</h2>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24}>
              <h4 className="gx-text-primary">
                <i className="far fa-id-card-alt gx-mr-2"/>
                Datos Generales
              </h4>
              <Divider className="gx-mb-0 gx-mt-0"/>
            </Col>
            <Col xs={24} sm={24} md={3} lg={3}>
              <FormItem className="form-control-ec" label="Tipo Documento">
                {getFieldDecorator("document_type", {
                  rules: [{required: true, message: 'Campo requerido'}]
                })(
                  <Select
                    autoFocus
                    ref={_documentType}
                    placeholder="Seleccione"
                    loading={loading}
                    onChange={() => changeFocus(_documentNumber)}
                  >
                    {documentTypes && documentTypes.map((document, index) => (
                      <Option key={index} value={document.id}>
                        {document.description}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col xs={24} sm={24} md={5} lg={5}>
              <FormItem className="form-control-ec" label="Documento">
                {getFieldDecorator("document_number", {
                  rules: [{required: true, message: 'Campo requerido'}]
                })(<Input ref={_documentNumber} maxLength={15}/>)}
              </FormItem>
            </Col>
            <Col xs={24} sm={24} md={16} lg={16}>
              <FormItem
                className="form-control-ec"
                label="Razón Social/Nombres"
              >
                {getFieldDecorator("social_reason", {
                  rules: [{required: true, message: 'Campo requerido'}]
                })(<Input/>)}
              </FormItem>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8}>
              <FormItem className="form-control-ec" label="Correo">
                {getFieldDecorator("email", {
                  rules: [
                    {required: true, message: 'Correo electrónico requerido'},
                    {type: 'email', message: 'Por favor ingrese un correo electrónico válido'}
                  ]
                })(<Input type="email"/>)}
              </FormItem>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8}>
              <FormItem className="form-control-ec" label="Telefono">
                {getFieldDecorator("first_phone_number")(<Input type="text"/>)}
              </FormItem>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8}>
              <FormItem className="form-control-ec" label="Telefono 2">
                {getFieldDecorator("second_phone_number")(<Input type="tel"/>)}
              </FormItem>
            </Col>
            <Col xs={24} sm={24} md={16} lg={16}>
              <FormItem className="form-control-ec" label="Dirección">
                {getFieldDecorator("address")(<Input type="text"/>)}
              </FormItem>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8}>
              <FormItem className="form-control-ec" label="Distrito">
                {getFieldDecorator("ubigeo_id")(
                  <AutoComplete
                    allowClear
                    dataSource={!searchingUbigeo ? ubigeos ? ubigeos.map(renderOption) : [] : []}
                    onSearch={handleSearch}
                    className="gx-full-width"
                    notFoundContent={(
                      <Spin spinning={searchingUbigeo} indicator={spinner}>
                        <div className="gx-mb-0">
                          Buscar ubigeos...
                        </div>
                      </Spin>
                    )}
                  >
                    <Input onChange={e => setQuery(e.target.value)}/>
                  </AutoComplete>
                )}
              </FormItem>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24}>
              <h4 className="gx-text-primary">
                <i className="far fa-user-circle gx-mr-2"/>
                Datos de Contacto
              </h4>
              <Divider className="gx-mb-0 gx-mt-0"/>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8}>
              <FormItem className="form-control-ec" label="Nombres">
                {getFieldDecorator("contact_name")(<Input type="text"/>)}
              </FormItem>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8}>
              <FormItem className="form-control-ec" label="Correo">
                {getFieldDecorator("contact_email", {
                  rules: [{type: 'email', message: 'Por favor ingrese un correo electrónico válido'}]
                })(<Input type="email"/>)}
              </FormItem>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8}>
              <FormItem className="form-control-ec" label="Telefono">
                {getFieldDecorator("contact_phone_number")(<Input type="tel"/>)}
              </FormItem>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24}>
              <FormItem className="form-control-ec gx-d-flex gx-justify-content-end">
                <Button
                  type="default"
                  icon="arrow-left"
                  onClick={handleOnCancel}
                  disabled={saving || loading}
                >
                  Cancelar
                </Button>
                <Button disabled={saving || loading} type="primary" htmlType="submit" icon="save">
                  Guardar
                </Button>
              </FormItem>
            </Col>
          </Row>
        </Spin>
      </Form>
    </Auxiliary>
  );
}

export default Form.create()(ClientForm);
