import React, { Fragment } from "react";
import { connect } from "react-redux";
import CustomScrollbars from "util/CustomScrollbars";
import CarpinterItems from "./CarpinterItems";
import Slider from "react-slick";
import { EmpytCard } from "../EmptyCard";
import { Spin } from "antd";

const options = {
  className: "gx-map-carrousel",
  dots: true,
  infinite: true,
  speed: 500,
  marginLeft: 10,
  marginRight: 10,
  slidesToShow: 2,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1199,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
  ],
};
const description = "No hay carpinteros cerca.";
const Carpenter = ({
  isMobile = false,
  dataSource,
  loading,
  handleMarkerClick,
  handleMarkerClose,
}) => {
  return (
    <Spin spinning={loading}>
      {isMobile === true ? (
        <Fragment>
          {Array.isArray(dataSource) && dataSource.length > 0 ? (
            <Slider {...options}>
              {Array.isArray(dataSource) && dataSource.length > 0 ? (
                dataSource.map((carpenter, index) =>
                  carpenter ? (
                    <CarpinterItems
                      key={carpenter.id ? carpenter.id : index}
                      name={`${carpenter.name ? carpenter.name : "-"}, ${
                        carpenter.last_name ? carpenter.last_name : "-"
                      }`}
                      tags={[{ color: "Cocina", label: "Melamina" }]}
                      distance={carpenter.address ? carpenter.address : "-"}
                      avatar={carpenter.avatar}
                      experience_years={carpenter.experience_years}
                      occupation={
                        carpenter.specialty &&
                        carpenter.specialty.parent &&
                        carpenter.specialty.parent.description
                      }
                      specialty={
                        carpenter.specialty && carpenter.specialty.description
                      }
                      rate={3.5}
                    />
                  ) : null
                )
              ) : (
                <EmpytCard description={description} />
              )}
            </Slider>
          ) : (
            <EmpytCard description={description} />
          )}
        </Fragment>
      ) : (
        <CustomScrollbars className="gx-module-side-scroll">
          <div className="gx-container-capenters-list">
            {Array.isArray(dataSource) && dataSource.length > 0 ? (
              dataSource.map((carpenter, index) =>
                carpenter ? (
                  <CarpinterItems
                    id={carpenter.id}
                    key={carpenter.id ? carpenter.id : index}
                    name={`${carpenter.name ? carpenter.name : "-"}, ${
                      carpenter.last_name ? carpenter.last_name : "-"
                    }`}
                    tags={[{ color: "Cocina", label: "Melamina" }]}
                    distance={carpenter.address ? carpenter.address : "-"}
                    avatar={carpenter.avatar}
                    occupation={
                      carpenter.specialty &&
                      carpenter.specialty.parent &&
                      carpenter.specialty.parent.description
                    }
                    specialty={
                      carpenter.specialty && carpenter.specialty.description
                    }
                    experience_years={carpenter.experience_years}
                    rate={3.5}
                    handleMarkerClick={handleMarkerClick}
                    handleMarkerClose={handleMarkerClose}
                  />
                ) : null
              )
            ) : (
              <EmpytCard description={description} />
            )}
          </div>
        </CustomScrollbars>
      )}
    </Spin>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Carpenter);
