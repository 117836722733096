import gql from "graphql-tag";

export const DELETE_CLOUDINARY_FILE = gql`
  mutation deleteCloudinaryFile($file: String!) {
    deleteCloudinaryFile(file: $file) {
      status
      message
    }
  }
`;
