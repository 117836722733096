import React, {useEffect, useState} from 'react';
import {Form, Select, DatePicker, Input, InputNumber, Button, Upload, Icon, List, Tooltip, message, Spin} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {useLazyQuery, useMutation} from "@apollo/react-hooks";
import axios from "axios";

import {toggleModalAdvertisement} from "../../../../appRedux/actions";
import {CREATE_ADVERTISEMENT, UPDATE_ADVERTISEMENT} from "../../../../graphql/mutation/advertisement";
import moment from "moment";
import {spinner} from "../../../../util/customComponents";
import {GET_TAGS} from "../../../../graphql/query/master";
import {cloudinaryEndPoint, cloudinaryUploadPreset} from "../../../../util/config";
import {RETRIEVE_ADVERTISEMENT} from "../../../../graphql/query/advertisement";
import {DELETE_CLOUDINARY_FILE} from "../../../../graphql/mutation/cloudinary";
import {getPublicIDCloudinaryFile} from "../../../../util/helpers";

const {Option} = Select;
const {RangePicker} = DatePicker;

const extensions = [/*'video/webm', 'video/mp4', 'video/avi', 'video/mpeg', */'image/jpeg', 'image/jpg', 'image/gif'];
const rules = [{required: true, message: 'Campo requerido'}];

const AdvertisementForm = props => {
  const dispatch = useDispatch();
  const {modalAdvertisement} = useSelector(({client}) => client);

  const {clientID, infoID, form} = props;
  const {getFieldDecorator, validateFields, resetFields} = form;
  const {visible} = modalAdvertisement;

  const [tags, setTags] = useState([]);
  const [multimedia, setMultimedia] = useState(null);
  const [attachment, setAttachment] = useState(null);
  const [preview, setPreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [advertisementInfo, setAdvertisementInfo] = useState(null);
  const [filteredTags, setFilteredTags] = useState([]);

  const [retrieveAdvertisementInfo] = useLazyQuery(RETRIEVE_ADVERTISEMENT, {
    fetchPolicy: 'network-only',
    onCompleted({retrieveAdvertisement}) {
      setAdvertisementInfo(retrieveAdvertisement);
      setTags(JSON.parse(retrieveAdvertisement.tags));
      setLoading(false);
    },
    onError({graphQLErrors, networkError}) {
      if (graphQLErrors) {
        graphQLErrors.map(error => message.error(error.message));
        setLoading(false);
      }
      if (networkError) {
        message.error(`[Error de Red]: ${networkError}`);
        setLoading(false);
      }
    }
  })

  const [preloadTags] = useLazyQuery(GET_TAGS, {
    fetchPolicy: 'network-only',
    variables: {
      where: {
        column: "ENTITY",
        operator: "EQ",
        value: "ETIQUETA"
      },
      order: [{
        field: "DESCRIPTION",
        order: "ASC"
      }]
    },
    onCompleted({masters}) {
      setFilteredTags(masters);
      if (infoID) {
        const variables = {id: infoID}
        retrieveAdvertisementInfo({variables});
      } else {
        setLoading(false);
      }
    },
    onError({graphQLErrors, networkError}) {
      if (graphQLErrors) {
        graphQLErrors.map(error => message.error(error.message));
        setLoading(false);
      }
      if (networkError) {
        message.error(`[Error de Red]: ${networkError}`);
        setLoading(false);
      }
    }
  });

  useEffect(() => {
    if (clientID && visible) {
      setLoading(true);
      preloadTags();
    }
  }, [visible, preloadTags, clientID]);

  const [updateAdvertisement] = useMutation(UPDATE_ADVERTISEMENT, {
    async onCompleted() {
      await props.refetchAdvertisements().then(() => {
        dispatch(toggleModalAdvertisement({visible: false, info: null}));
      });
    },
    onError() {
      message.error('Error al actualizar anuncio');
      setLoading(false);
    }
  });

  const [createAdvertisement] = useMutation(CREATE_ADVERTISEMENT, {
    async onCompleted() {
      await props.refetchAdvertisements().then(() => {
        dispatch(toggleModalAdvertisement({visible: false, info: null}));
      });
    },
    onError() {
      message.error('Error al registrar anuncio');
      setLoading(false);
    }
  });

  const [deleteCloudinaryFile] = useMutation(DELETE_CLOUDINARY_FILE, {
    onError() {
      message.error("Ha ocurrido un error al eliminar archivo");
      setLoading(false);
    }
  });

  const handleSubmit = e => {
    e.preventDefault();
    validateFields(async (err, values) => {
      if (!err) {
        setLoading(true);
        if (infoID) {
          let variables = {
            id: infoID,
            input: {
              client_id: clientID,
              location: values.location,
              redirect_url: values.redirect_url,
              from: moment(values.validity[0]).format('YYYY-MM-DD hh:mm:ss'),
              expire: moment(values.validity[1]).format('YYYY-MM-DD hh:mm:ss'),
              tags: JSON.stringify(tags),
              click: values.click
            }
          };
          if(multimedia) {
            const public_id = getPublicIDCloudinaryFile(advertisementInfo.multimedia.url);
            await deleteCloudinaryFile({variables: {file: public_id}}).then(async ({data}) => {
              if (data.deleteCloudinaryFile) {
                if (data.deleteCloudinaryFile.status) {
                  const info = new FormData();
                  info.append('file', multimedia);
                  info.append('upload_preset', cloudinaryUploadPreset);
                  await axios.post(cloudinaryEndPoint, info, {
                    headers: {
                      "Content-Type": "multipart/form-data"
                    }
                  }).then(async res => {
                    variables['input']['multimedia'] = {
                      upsert: {
                        id: advertisementInfo.id,
                        url: res.data.secure_url,
                        extension: multimedia.type,
                        thumbnail: true
                      }
                    };
                    await updateAdvertisement({variables}).then(() => {
                      setLoading(false);
                    });
                  }).catch(() => {
                    message.warning('Error al subir foto');
                    setLoading(false);
                  });
                } else {
                  message.warning(data.deleteCloudinaryFile.message);
                  setLoading(false);
                }
              } else {
                setLoading(false);
              }
            });
          } else {
            await updateAdvertisement({variables}).then(() => {
              setLoading(false);
            });
          }
        } else {
          if (!multimedia) {
            message.warn('Por favor seleccione un archivo multimedia');
            return false;
          }
          const info = new FormData();
          info.append('file', multimedia);
          info.append('upload_preset', cloudinaryUploadPreset);
          await axios.post(cloudinaryEndPoint, info, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }).then(async res => {
            const variables = {
              input: {
                client_id: clientID,
                location: values.location,
                redirect_url: values.redirect_url,
                from: moment(values.validity[0]).format('YYYY-MM-DD hh:mm:ss'),
                expire: moment(values.validity[1]).format('YYYY-MM-DD hh:mm:ss'),
                multimedia: {
                  create: {
                    url: res.data.secure_url,
                    extension: multimedia.type,
                    thumbnail: true
                  }
                },
                tags: JSON.stringify(tags),
                click: values.click
              }
            }
            await createAdvertisement({variables});
          }).catch(() => {
            message.warning('Error al subir foto');
            setLoading(false);
          });
        }
      }
    });
  };

  const cancelAddAdvertisement = () => {
    resetFields();
    dispatch(toggleModalAdvertisement({visible: false, info: null}));
  };

  const toggleAttachment = (file, show = true) => {
    if (show) {
      let reader = new FileReader();
      reader.onload = e => {
        let newSelectedAttachment = {};
        newSelectedAttachment.file = file;
        newSelectedAttachment.blobData = e.target.result;
        newSelectedAttachment.type = file.type;
        setAttachment(newSelectedAttachment);
      };
      reader.readAsDataURL(file);
    } else {
      setAttachment(null);
    }
  };

  const changeSelectedTags = values => {
    setTags(values);
  };

  return (
    <Spin spinning={loading} indicator={spinner}>
      <Form layout="vertical" hideRequiredMark onSubmit={handleSubmit}>
        <Form.Item label="Ubicacion">
          {getFieldDecorator("location", {
            initialValue: advertisementInfo ? advertisementInfo.location : 'H',
            rules
          })(
            <Select>
              <Option key="H" value="H">
                Página de Inicio
              </Option>
              <Option key="P" value="P">
                Perfil / Proyecto
              </Option>
            </Select>
          )}
        </Form.Item>
        <Form.Item label="Vigencia">
          {getFieldDecorator("validity", {
            initialValue: advertisementInfo ? [moment(advertisementInfo.from), moment(advertisementInfo.expire)] : null,
            rules
          })(
            <RangePicker showTime format="DD/MM/YYYY HH:mm" style={{width: '100%'}}/>
          )}
        </Form.Item>
        <Form.Item label="Etiquetas">
          {getFieldDecorator("tag", {
            initialValue: tags,
            rules: [
              {
                required: true,
                message: "Por favor ingrese las etiquetas del proyecto",
              }
            ],
          })(
            <Select
              mode="multiple"
              onChange={changeSelectedTags}
              style={{width: '100%'}}
              placeholder="Ej.: Oficina, Escritorio, Moderno, etc."
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {filteredTags.length > 0 && (
                filteredTags.map((tag, tagIndex) => (
                  <Option key={`filtered-${tagIndex}`} value={tag.description}>
                    {tag.description}
                  </Option>
                ))
              )}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="Enlace de redirección">
          {getFieldDecorator("redirect_url", {
            initialValue: advertisementInfo ? advertisementInfo.redirect_url : undefined,
            rules
          })(<Input type="url"/>)}
        </Form.Item>
        <Form.Item label="Cantidad de Clic">
          {getFieldDecorator("click", {
            initialValue: advertisementInfo ? advertisementInfo.click : 0,
            rules
          })(<InputNumber min={0} style={{width: '100%'}}/>)}
        </Form.Item>
        <Form.Item
          label={(
            <div>
              <div className="gx-full-width gx-d-inline-flex gx-align-items-center" onClick={e => e.preventDefault()}>
                Multimedia
                <Upload
                  accept=".jpeg,.jpg,.gif"
                  multiple={false}
                  beforeUpload={e => false}
                  showUploadList={false}
                  onChange={info => {
                    if (info.file.status !== "uploading") {
                      if (extensions.includes(info.file.type)) {
                        setMultimedia(info.file);
                        setAttachment(null);
                      } else {
                        message.warn('Por favor seleccione archivos soportados');
                      }
                    }
                  }}
                  className="gx-ml-auto"
                >
                  <Button size="small" className="gx-mb-0">
                    <Icon type="upload" className="gx-mr-2"/>
                    {multimedia ? 'Cambiar' : 'Buscar'} Multimedia
                  </Button>
                </Upload>
              </div>
              <div className="gx-full-width gx-fs-sm gx-text-muted">
                Imagen: .jpeg, .jpg, .gif | Video: .webm, .mp4, .avi, .mpeg
              </div>
            </div>
          )}
        >
          <List bordered size="small">
            {!multimedia && advertisementInfo && (
              <List.Item
                extra={(
                  <Tooltip title="Vista Previa">
                    <Button size="small" className="gx-m-0" onClick={() => setPreview(!preview)}>
                      <i className={`far ${preview ? 'fa-eye-slash' : 'fa-eye'}`}/>
                    </Button>
                  </Tooltip>
                )}
              >
                {advertisementInfo.multimedia.url}
              </List.Item>
            )}
            {multimedia && (
              <List.Item
                extra={(
                  <Tooltip title="Vista Previa">
                    <Button size="small" className="gx-m-0" onClick={() => toggleAttachment(multimedia, !attachment)}>
                      <i className={`far ${attachment ? 'fa-eye-slash' : 'fa-eye'}`}/>
                    </Button>
                  </Tooltip>
                )}
              >
                {multimedia.name}
              </List.Item>
            )}
            {!multimedia && !advertisementInfo && (
              <List.Item className="gx-text-muted">
                No se ha seleccionado multimedia
              </List.Item>
            )}
          </List>
          {attachment && (
            <div className="gx-mb-0 gx-mt-3">
              {attachment.type.includes('video') && (
                <video width="100%" controls>
                  <source src={attachment.blobData} type={attachment.file.type}/>
                  Su navegador no soporta HTML5 Video
                </video>
              )}
              {attachment.type.includes('image') && (
                <img src={attachment.blobData} alt="Vista previa"/>
              )}
            </div>
          )}
          {preview && advertisementInfo && (
            <div className="skeleton-avatar gx-mt-3">
              <img src={advertisementInfo.multimedia.url} alt="Vista previa"/>
            </div>
          )}
        </Form.Item>
        <Form.Item className="gx-mb-0 gx-text-right">
          <Button type="primary" htmlType="submit" className="gx-mb-0">
            Guardar
          </Button>
          <Button className="gx-mb-0" onClick={cancelAddAdvertisement}>
            Cancelar
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default Form.create()(AdvertisementForm);
