import React from "react";
import { Table } from "antd";
import PropTypes from "prop-types";
import TableTitle from "components/TableTitle";
const columns = [
  {
    title: "CODIGO",
    dataIndex: "sCodCanto",
    key: "sCodCanto",
    width: "10%"
  },
  {
    title: "DESCRIPCIÓN",
    dataIndex: "sDescCanto",
    key: "sDescCanto"
  },
  {
    title: "PASADAS",
    dataIndex: "nPasadas",
    key: "nPasadas",
    align: "center"
  },
  {
    title: "ML",
    dataIndex: "nML",
    key: "nML",
    align: "center",
    render: text => (text ? text.toFixed(2) : "-")
  },
  {
    title: "MERMA",
    dataIndex: "nMerma",
    key: "nMerma",
    align: "center",
    render: text => (text ? text.toFixed(2) : "-")
  },
  {
    title: "ML+MERMA",
    dataIndex: "nMlMerma",
    key: "nMlMerma",
    align: "center",
    render: (text, row) => {
      const merma = isNaN(row.nMerma) ? 0 : parseFloat(row.nMerma);
      const ml = isNaN(row.nML) ? 0 : parseFloat(row.nML);
      const total = merma + ml;
      return total;
    }
  }
];
const CantosCliente = props => {
  const { dataSource } = props;
  return (
    <div className="gx-table-responsive">
      <Table
        title={() => <TableTitle title="CANTOS DEL CLIENTE" />}
        className="gx-mb-3 gx-table-custom-imd"
        size="small"
        bordered
        pagination={false}
        rowKey={(row, dataIndex) => `${dataIndex + 1}_CCLI`}
        dataSource={dataSource}
        columns={columns}
      />
    </div>
  );
};

CantosCliente.propTypes = {
  dataSource: PropTypes.array
};

export default CantosCliente;
