import React from "react";
import { Table } from "antd";
import PropTypes from "prop-types";
import TableTitle from "components/TableTitle";

const columns = [
  {
    title: "TABLERO",
    dataIndex: "sTablero",
    key: "sTablero",
    align: "center",
    children: [
      {
        title: "CORTES",
        dataIndex: "nTotalCortes",
        key: "nTotalCortes",
        align: "center"
      },
      {
        title: "M2",
        dataIndex: "nTotalM2",
        key: "nTotalM2",
        align: "center",
        render: nTotalM2 => (nTotalM2 ? nTotalM2.toFixed(2) : "-")
      }
    ]
  },
  {
    title: "TAPACANTOS",
    dataIndex: "sTapaCantos",
    key: "sTapaCantos",
    align: "center",
    children: [
      {
        title: "DELGADO (ML)",
        dataIndex: "nCantoDelgado",
        key: "nCantoDelgado",
        align: "center"
      },
      {
        title: "GRUESO (ML)",
        dataIndex: "nCantoGrueso",
        key: "nCantoGrueso",
        align: "center"
      }
    ]
  },
  {
    title: "RANURA",
    dataIndex: "nTotalRanuras",
    key: "nTotalRanuras",
    align: "center"
  },
  {
    title: "PERF",
    dataIndex: "nTotalPerf",
    key: "nTotalPerf",
    align: "center"
  },
  {
    title: "D25",
    dataIndex: "nD25",
    key: "nD25",
    align: "center"
  },
  {
    title: "SE",
    dataIndex: "nSE",
    key: "nSE",
    align: "center"
  }
];
const TablerosTapaCantos = props => {
  const { dataSource } = props;
  return (
    <div className="gx-table-responsive">
      <Table
        title={() => <TableTitle title="TABLEROS & TAPACANTOS" />}
        className="gx-mb-3 gx-table-custom-imd"
        size="small"
        bordered
        pagination={false}
        rowKey={(row, dataIndex) => `${dataIndex + 1}_TBLC`}
        dataSource={dataSource}
        columns={columns}
      />
    </div>
  );
};

TablerosTapaCantos.propTypes = {
  dataSource: PropTypes.array
};

export default TablerosTapaCantos;
