import gql from 'graphql-tag';

export const CREATE_PROJECT_MUTATION = gql`
  mutation createProject($input: createProjectInput!) {
    createProject(input: $input) {
      id
      name
      description
      materials
      tags
      currency
      investment
      status
      created_at
      gallery {
        id
        url
        base64
        extension
        thumbnail
      }
    }
  }
`;

export const UPDATE_PROJECT_MUTATION = gql`
  mutation updateProject($id: ID!, $input: updateProjectInput!) {
    updateProject(id: $id, input: $input) {
      id
      name
      description
      materials
      tags
      currency
      investment
      status
      created_at
      gallery {
        id
        url
        base64
        extension
        thumbnail
      }
    }
  }
`;

export const DELETE_PROJECT_MUTATION = gql`
  mutation deleteProject($id: ID!) {
    deleteProject(id: $id) {
      id
    }
  }
`;

export const CMS_UPDATE_STATUS_PROJECT = gql`
  mutation updateStatusProject($id: ID!, $status: ProjectStatuses!) {
    updateStatusProject(id: $id, status: $status) {
      id
      name
      description
      user {
        id
        name
        last_name
      }
      status
      created_at
    }
  }
`;

export const CREATE_VISITED_PROJECT = gql`
  mutation createVisitedProject($project_id: ID!) {
    createVisitedProject(input: { project_id: $project_id }) {
      created_at
    }
  }
`;

export const CREATE_FAVORITE_PROJECT = gql`
  mutation registerFavorite($id: ID, $input: InputUpsertFavorite!) {
    registerFavorite(id: $id, input: $input) {
      user {
        id
      }
      project {
        id
      }
      status
    }
  }
`;
