import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";
import { Layout, Button, message, Form, Divider } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  withRouter,
  useRouteMatch,
  useHistory,
  useLocation,
  Link,
} from "react-router-dom";
import eCarpinteroLogo from "../../assets/images/eCarpintero-logo.svg";
import Whatsapp from "assets/images/social-media/whatsapp.svg";
import Facebook from "assets/images/social-media/facebook.svg";
import Instagram from "assets/images/social-media/instagram.svg";
import FlechaDe from "assets/images/banner/flechaDe.svg";
import FlechaIz from "assets/images/banner/flechaIz.svg";
import { Carousel } from "react-responsive-carousel";
import { useMutation } from "@apollo/react-hooks";

// import Sidebar from "../Sidebar/index";
import LandingRoutes from "routes/landing";
import RestrictedCMS from "routes/cms";
import RestrictedUser from "routes/user";

import MobileMenu from "../Topbar/MobileMenu";
import { footerText } from "util/config";
import { MARK_LATEST_CONNECTION } from "../../graphql/mutation/user";
import { latestConnection } from "../../appRedux/actions";

import HorizontalDefault from "../Topbar/HorizontalDefault/index";
import {
  // NAV_STYLE_ABOVE_HEADER,
  // NAV_STYLE_BELOW_HEADER,
  // NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  // NAV_STYLE_DRAWER,
  // NAV_STYLE_FIXED,
  // NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  // NAV_STYLE_MINI_SIDEBAR,
  // NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  // NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  // TAB_SIZE,
} from "../../constants/ThemeSetting";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "./radar.css";

const { Content, Footer } = Layout;
const styles = {
  icon: {
    width: "25px",
    display: "inline-flex",
    justifyContent: "center",
    marginRight: "6px",
  },
};

const MainApp = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { token, authUser, skipFillInfo, latest } = useSelector(
    ({ auth }) => auth
  );
  const { navStyle } = useSelector(({ settings }) => settings);

  let App;
  switch (props.type) {
    case "restricted-user":
      App = RestrictedUser;
      break;
    case "restricted-cms":
      App = RestrictedCMS;
      break;
    default:
      App = LandingRoutes;
      break;
  }
  const match = useRouteMatch();
  const [currentSlide, setCurrentSlide] = useState(0);

  // /**
  //  * Delete
  //  * @param {*} navStyle
  //  * @return {*}
  //  */
  // const getContainerClass = (navStyle) => {
  //   switch (navStyle) {
  //     case NAV_STYLE_DARK_HORIZONTAL:
  //       return "gx-container-wrap";
  //     case NAV_STYLE_DEFAULT_HORIZONTAL:
  //       return "gx-container-wrap";
  //     case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
  //       return "gx-container-wrap";
  //     case NAV_STYLE_BELOW_HEADER:
  //       return "gx-container-wrap";
  //     case NAV_STYLE_ABOVE_HEADER:
  //       return "gx-container-wrap";
  //     default:
  //       return "";
  //   }
  // };

  // /**
  //  *Delete
  //  * @param {*} navStyle
  //  * @return {*}
  //  */
  const getNavStyles = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return <HorizontalDefault />;
      default:
        return null;
    }
  };

  const previous = () => {
    setCurrentSlide(currentSlide - 1);
  };

  const next = () => {
    setCurrentSlide(currentSlide + 1);
  };

  const updateCurrentSlide = (index) => {
    if (currentSlide !== index) {
      setCurrentSlide(index);
    }
  };

  const [markLatestConnection] = useMutation(MARK_LATEST_CONNECTION, {
    onError() {
      message.error("Ha ocurrido un error al marcar la última conexión");
    },
  });

  const markLatest = useCallback(() => {
    if (!latest) {
      const latest_connection = moment().format("YYYY-MM-DD HH:mm:ss");
      const variables = {
        id: authUser.id,
        input: { latest_connection },
      };
      try {
        markLatestConnection({ variables });
        dispatch(latestConnection(latest_connection));
      } catch (e) {
        dispatch(latestConnection(null));
      }
    }
  }, [authUser, dispatch, latest, markLatestConnection]);

  useEffect(() => {
    const storageUser = localStorage.getItem("user");
    const parsedUser = JSON.parse(storageUser);
    if (parsedUser) {
      const { wizard_panel, user_type } = parsedUser;
      if (user_type === "CARPINTERO") {
        if (skipFillInfo === "no-skip") {
          if (wizard_panel) {
            const pathname = location.pathname;
            if (pathname !== "/usuario/joined") {
              history.push("/usuario/joined");
            }
          } else {
            markLatest();
          }
        } else {
          markLatest();
        }
      } else {
        markLatest();
      }
    }
  }, [token, latest, skipFillInfo, history, location, markLatest]);

  return (
    <Layout className="gx-app-layout">
      <Layout>
        {getNavStyles(navStyle)}
        {props.location.pathname === "/" && (
          <div className="banner-principal">
            <Carousel
              style={{ height: "480px" }}
              autoPlay={true}
              dynamicHeight={true}
              renderArrowNext={() => null}
              renderArrowPrev={() => null}
              infiniteLoop
              showStatus={false}
              showIndicators={false}
              showThumbs={false}
              selectedItem={currentSlide}
              onChange={updateCurrentSlide}
            >
              <div className="gx-text-left primer-banner">
                <Button
                  onClick={previous}
                  size="large"
                  className="arrow-btn gx-mr-2 arrow-iz"
                >
                  <img height="105%" src={FlechaIz} alt="siguiente" />
                </Button>
                <div className="primer-text legend-title gx-font-weight-bold gx-mb-0 gx-text-white">
                  <p className="titulo-eCarpintero gx-mb-0  gx-font-weight-bold">
                    eCarpintero
                  </p>
                  <Divider
                    type="vertical"
                    style={{
                      width: "3px",
                      height: "90%",
                      backgroundColor: "#C26E2D",
                    }}
                  />
                  <p className="gx-mb-0  gx-font-weight-bold">
                    Conectándote con especialistas
                  </p>
                </div>
                <Button
                  onClick={next}
                  size="large"
                  className="arrow-btn arrow-de"
                >
                  <img height="105%" src={FlechaDe} alt="siguiente" />
                </Button>
              </div>

              <div className="gx-text-left segundo-banner">
                <Button
                  onClick={previous}
                  size="large"
                  className="arrow-btn gx-mr-2 arrow-iz"
                >
                  <img height="105%" src={FlechaIz} alt="siguiente" />
                </Button>
                <div className="segundo-text legend-title gx-font-weight-bold gx-mb-0 gx-text-white">
                  <p className="titulo-eCarpintero gx-mb-0  gx-font-weight-bold">
                    eCarpintero
                  </p>
                  <Divider
                    type="vertical"
                    style={{
                      width: "3px",
                      height: "90%",
                      backgroundColor: "#C26E2D",
                    }}
                  />
                  <p className="gx-mb-0 g">Compartiendo tus proyectos.</p>
                </div>
                <Button
                  onClick={next}
                  size="large"
                  className="arrow-btn arrow-de"
                >
                  <img height="105%" src={FlechaDe} alt="siguiente" />
                </Button>
              </div>

              <div className="gx-text-left tercer-banner">
                <Button
                  onClick={previous}
                  size="large"
                  className="arrow-btn gx-mr-2 arrow-iz"
                >
                  <img height="105%" src={FlechaIz} alt="siguiente" />
                </Button>
                <div className="tercer-text legend-title gx-font-weight-bold gx-mb-0 gx-text-white">
                  <p className="titulo-eCarpintero gx-mb-0 gx-font-weight-bold">
                    eCarpintero
                  </p>
                  <Divider
                    type="vertical"
                    style={{
                      width: "3px",
                      height: "90%",
                      backgroundColor: "#C26E2D",
                    }}
                  />
                  <p className="gx-mb-0 ">Optimizando tu negocio.</p>
                </div>
                <Button
                  onClick={next}
                  size="large"
                  className="arrow-btn arrow-de"
                >
                  <img height="105%" src={FlechaDe} alt="siguiente" />
                </Button>
              </div>
            </Carousel>
          </div>
        )}
        {/* className={`gx-layout-content ${getContainerClass(navStyle)}`} */}
        <Content>
          <App match={match} />
          <Footer className="gx-d-none gx-d-lg-block">
            <div className="gx-layout-footer-content gx-d-none gx-d-lg-block">
              <div className="gx-footer-flex-ec cont-footer">
                <div className="eCar-mensaje">
                  <img
                    src={eCarpinteroLogo}
                    width="60%"
                    alt="Logo de eCarpintero"
                  />
                  <p className="mensaje">
                    Tu aliado para contactar y ofrecer productos de calidad.
                  </p>

                  <div className="medio-contacto">
                    <div className="content-social">
                      <a
                        href="https://api.whatsapp.com/send?phone=51924770892"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={Whatsapp} alt="wsp" title="wsp" />
                      </a>
                    </div>
                    <div className="content-social">
                      <a
                        href="https://www.facebook.com/eCarpinteroPeru"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={Facebook} alt="facebook" />
                      </a>
                    </div>
                    <div className="content-social">
                      <a
                        href="https://www.instagram.com/ecarpinteroperu/?hl=es-la"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={Instagram} alt="instagram" />
                      </a>
                    </div>
                  </div>
                </div>

                <div className="gx-footer-flex-item-info-cs">
                  <h1 className="h5 gx-font-weight-bold">ENLACES DE INTERÉS</h1>
                  <ul>
                    <li className="gx-mb-1">
                      <Link to="/politicas-de-privacidad">
                        <i className="far fa-gavel" style={styles.icon} />
                        Politicas de privacidad
                      </Link>
                    </li>
                    <li>
                      <Link to="/terminos-y-condiciones">
                        <i className="far fa-scroll-old" style={styles.icon} />
                        Términos y Condiciones
                      </Link>
                    </li>
                    <li>
                      <Link to="/terminos-y-condiciones">
                        <i className="far fa-scroll-old" style={styles.icon} />
                        Proyectos
                      </Link>
                    </li>
                    <li>
                      <Link to="/erp">
                        <i
                          className="fal fa-rocket gx-mr-2"
                          style={styles.icon}
                        />
                        ERP
                      </Link>
                    </li>
                    <li>
                      <Link to="/buscar-especialista">
                        <i
                          className="fal fa-user-hard-hat gx-mr-2"
                          style={styles.icon}
                        />
                        Especialistas
                      </Link>
                    </li>
                    <li>
                      <Link to="/mapas/tiendas-carpinteros-cercanos">
                        <i
                          className="fal fa-store gx-mr-2"
                          style={styles.icon}
                        />
                        Tiendas
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="gx-footer-flex-item-services-cs">
                  <h1 className="h5 gx-font-weight-bold">SERVICIOS</h1>
                  <ul>
                    <li className="gx-mb-1">
                      <Link to="/">
                        <i className="far fa-pennant" style={styles.icon} />
                        Publicidad
                      </Link>
                    </li>
                    <li className="gx-mb-1">
                      <Link to="/">
                        <i className="far fa-user-chart" style={styles.icon} />
                        Asesoría
                      </Link>
                    </li>
                    <li>
                      <Link to="/">
                        <i className="far fa-users-class" style={styles.icon} />
                        Capacitación
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="gx-sub-footer">
                <div className="gx-sub-footer-items copy">{footerText}</div>
                <div className="gx-sub-footer-items">
                  <a
                    href="https://www.amazontic.pe"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={require("assets/images/amazontic.png")}
                      alt="By AmazonTic"
                    />
                  </a>
                </div>
              </div>
            </div>
          </Footer>
        </Content>
      </Layout>
      <MobileMenu />
    </Layout>
  );
};
export default withRouter(Form.create()(MainApp));
