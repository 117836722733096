import {
  FETCH_START_LOCATION,
  FETCH_SUCCESS_LOCATION,
  FETCH_ERROR_LOCATION
} from "constants/ActionTypes";

const initialState = {
  locationList: [],
  searching: false,
  message: "",
  error: ""
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_START_LOCATION:
      return { ...state, searching: true, message: "", error: "" };

    case FETCH_SUCCESS_LOCATION: {
      return { ...state, locationList: payload, searching: false };
    }
    case FETCH_ERROR_LOCATION: {
      return {
        ...state,
        locationList: [],
        searching: false,
        error: payload,
        message: ""
      };
    }
    default:
      return state;
  }
};
