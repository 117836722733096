import React from 'react'
import { Drawer, AutoComplete, Spin, Empty, Button, Form, Input, DatePicker } from 'antd'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'

import { filterProjects, searchClients, changeFilterProject, clearFilterOptimizer } from 'appRedux/actions'
import { spinner } from 'util/customComponents'

const { Item } = Form
const { Option } = AutoComplete

const FilterDrawer = ({ visible, setVisible, form }) => {
  const dispatch = useDispatch()

  const { optimizerID } = useSelector(({ auth }) => auth)
  const { filterOptimizer: { project, client, filtering, from, to } } = useSelector(({ projects }) => projects)
  const { patientSearchList: clientsList, pattientSearching: searching } = useSelector(({ clients }) => clients)

  const { resetFields, getFieldDecorator, validateFields } = form

  const onClose = () => {
    setVisible(false)
    resetFields()
    dispatch(clearFilterOptimizer())
  }

  const submitFilter = e => {
    e.preventDefault()
    validateFields((errors, values) => {
      if (!errors) {
        const { client, from, to, project } = values
        const filterProyects = {
          nIdUsuario: optimizerID,
          sProyecto: project,
          nIdCliente: client,
          dDesde: moment(from).format('YYYY-MM-DD'),
          dHasta: moment(to).format('YYYY-MM-DD'),
          setVisible: setVisible
        }
        dispatch(filterProjects(filterProyects))
      }
    })
  }

  const onSearchClient = value => {
    const nIdUsuario = localStorage.getItem('optimizer_id');
    if (value !== '') {
      const valueTrim = value.trim();
      dispatch(searchClients(nIdUsuario, valueTrim))
    } else {
      dispatch(searchClients(nIdUsuario, ''))
    }
  };

  return (
    <Drawer
      width='100%'
      placement="right"
      closable={false}
      onClose={onClose}
      visible={visible}
    >
      <Spin spinning={filtering} indicator={spinner}>
        <h3 className="gx-mb-0">Filtro de búsqueda</h3>
        <small>Optimizador eCarpintero</small>
        <Form className="gx-my-4" layout="vertical" onSubmit={submitFilter}>
          <Item label="Cliente">
            {getFieldDecorator('client', {
              initialValue: client || undefined
            })(
              <AutoComplete
                onSearch={onSearchClient}
                placeholder="Nombres y/o apellidos"
                style={{ width: "100%" }}
                loading={searching}
                optionLabelProp="children"
                onSelect={value => dispatch(changeFilterProject({ field: 'client', value: value }))}
                allowClear
                dataSource={
                  clientsList &&
                  clientsList.map(client => (
                    <Option value={client.nId.toString()} key={client.nId}>
                      {client.sNombres}
                    </Option>
                  ))
                }
                notFoundContent={
                  <Spin spinning={searching}>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  </Spin>
                }
              />
            )}
          </Item>
          <Item label="Proyecto">
            {getFieldDecorator('project', { initialValue: project })(
              <Input
                placeholder="Código, descripción, obra, etc."
                onChange={e => dispatch(changeFilterProject({ field: 'project', value: e.target.value }))}
              />
            )}
          </Item>
          <Item label="Desde">
            {getFieldDecorator('from', { initialValue: moment(from, 'YYYY/MM/DD') })(
              <DatePicker
                className="gx-full-width"
                format="DD/MM/YYYY"
                onChange={value => dispatch(changeFilterProject({ field: 'from', value: moment(value).format('YYYY/MM/DD') }))}
              />
            )}
          </Item>
          <Item label="Hasta" className="gx-mb-4">
            {getFieldDecorator('to', { initialValue: moment(to, 'YYYY/MM/DD') })(
              <DatePicker
                className="gx-full-width"
                format="DD/MM/YYYY"
                onChange={value => dispatch(changeFilterProject({ field: 'to', value: moment(value).format('YYYY/MM/DD') }))}
              />
            )}
          </Item>
          <Item>
            <div className="gx-d-flex gx-align-items-center">
              <Button className="gx-mb-0" onClick={onClose} disabled={filtering}>
                Limipiar
              </Button>
              <div className="gx-ml-auto">
                <Button htmlType="submit" type="primary" disabled={filtering} className="gx-mb-0">
                  <i className="far fa-search gx-mr-2" />
                  Buscar
                </Button>
                <Button className="gx-mb-0" onClick={() => setVisible(false)} disabled={filtering}>
                  Cancelar
                </Button>
              </div>
            </div>
          </Item>
        </Form>
      </Spin>
    </Drawer>
  )
}

export default Form.create()(FilterDrawer)
