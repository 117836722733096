export const schema = {
  nIdCliente: {
    rule: {
      required: false,
      message: "Campo requerido"
    },
    label: "Cliente",
    name: "nIdCliente",
    type: "text",
    placeHolder: "Cliente"
  },
  sProyecto: {
    rule: {
      required: false,
      message: "Campo requerido"
    },
    label: "Proyecto",
    name: "sProyecto",
    type: "text",
    placeHolder: "Proyecto"
  },
  dFechas: {
    rule: {
      required: false,
      message: "Campo requerido"
    },
    label: "Fechas",
    name: "dFechas",
    type: "text",
    placeHolder: "Fechas"
  }
};
