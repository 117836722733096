import React, {useRef, useEffect, useCallback, useState, Fragment} from "react";
import {useSelector, useDispatch} from "react-redux";
import {Form, Input, Button, Row, Col, Drawer, InputNumber, Modal, Card, Steps, Select, message, Spin} from "antd";
import {useDropzone} from 'react-dropzone';
import {useMutation, useLazyQuery} from '@apollo/react-hooks';
import {toggleProjectModal} from "../../../appRedux/actions";
import axios from 'axios';

import {CREATE_PROJECT_MUTATION, UPDATE_PROJECT_MUTATION} from '../../../graphql/mutation/project';
import {DELETE_MULTIMEDIA_MUTATION} from '../../../graphql/mutation/multimedia';
import {RETRIEVE_USER_PROJECT, USER_PROJECTS} from '../../../graphql/query/project';
import {GET_TAGS} from '../../../graphql/query/master';

import {spinner} from '../../../util/customComponents';
import {cloudinaryEndPoint, cloudinaryUploadPreset} from '../../../util/config';
import {getPublicIDCloudinaryFile, numbersOnly} from "../../../util/helpers";

import './grid-gallery.css';
import {DELETE_CLOUDINARY_FILE} from "../../../graphql/mutation/cloudinary";

const {Option} = Select;
const {Step} = Steps;

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const ProjectsForm = props => {
  const dispatch = useDispatch();
  const _name = useRef();
  const _investment = useRef();
  const _form = useRef();

  const {modalProject} = useSelector(({project}) => project);
  const {visible} = modalProject;

  const [fetchingFiles, setFetchingFiles] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [galleryItems, setGalleryItems] = useState([]);
  const [selectedGalleryItems, changeSelectedGalleryItems] = useState([]);
  const [filteredTags, setFilteredTags] = useState([]);
  const [tags, setTags] = useState([]);
  const [deletingPhoto, setDeletingPhoto] = useState(false);

  const [preloadTags, {loading: loadingTags}] = useLazyQuery(GET_TAGS, {
    fetchPolicy: 'network-only',
    variables: {
      where: {
        column: "ENTITY",
        operator: "EQ",
        value: "ETIQUETA"
      },
      order: [{
        field: "DESCRIPTION",
        order: "ASC"
      }]
    },
    onCompleted({masters}) {
      setFilteredTags(masters);
    },
    onError({graphQLErrors, networkError}) {
      if (graphQLErrors) {
        graphQLErrors.map(error => message.error(error.message));
      }
      if (networkError) {
        message.error(`[Error de Red]: ${networkError}`);
      }
    }
  });

  useEffect(() => {
    if (visible) {
      preloadTags();
    }
  }, [visible, preloadTags]);

  // Form items
  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [materials, setMaterials] = useState([]);
  const [investment, setInvestment] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);

  const {getFieldDecorator, validateFields, getFieldValue, resetFields} = props.form;

  const [retrieveUserProject, {loading}] = useLazyQuery(RETRIEVE_USER_PROJECT, {
    fetchPolicy: 'network-only',
    onCompleted({userProject: project}) {
      setName(project.name);
      if (project.description) setDescription(project.description);
      if (project.materials) setMaterials(JSON.parse(project.materials));
      if (project.tags) setTags(JSON.parse(project.tags));
      if (project.investment) setInvestment(project.investment);
      if (project.gallery) {
        const filteredThumbnail = project.gallery.filter(item => !!item.thumbnail);
        if (filteredThumbnail.length > 0) {
          setThumbnail(filteredThumbnail[0]);
          setGalleryItems(project.gallery);
        }
      }
    },
    onError({graphQLErrors, networkError}) {
      if (graphQLErrors) {
        graphQLErrors.forEach(error => {
          message.error(error.message);
        });
      }
      if (networkError) {
        message.error(`[Error de Red]: ${networkError}`);
      }
    }
  });

  useEffect(() => {
    if (modalProject && modalProject.info) {
      const id = modalProject.info.id;
      retrieveUserProject({variables: {id}});
    }
  }, [modalProject, retrieveUserProject]);

  const [deleteCloudinaryFile] = useMutation(DELETE_CLOUDINARY_FILE, {
    onError() {
      message.error("Ha ocurrido un error al eliminar archivo");
    }
  });

  const [deleteMultimedia] = useMutation(DELETE_MULTIMEDIA_MUTATION, {
    onCompleted({deleteMultimedia: {id}}) {
      message.success('Imagen eliminada');
      const filterRemoved = galleryItems.filter(item => item.id ? item.id !== id : item);
      setGalleryItems(filterRemoved);
    },
    update(cache, {data: {deleteMultimedia}}) {
      if (!props.useCMS) {
        const {id: photoID, multimediaable_id: projectID} = deleteMultimedia;
        const {userProjects: {data, __typename}} = cache.readQuery({query: USER_PROJECTS});
        const indexFilter = data.findIndex(item => item.id === projectID);
        if (indexFilter !== -1) {
          data[indexFilter].gallery = data[indexFilter].gallery.filter(item => item.id !== photoID);
          cache.writeQuery({
            query: USER_PROJECTS,
            data: {userProjects: {data, __typename}},
          });
        }
      }
    },
    onError({graphQLErrors, networkError}) {
      if (graphQLErrors) {
        graphQLErrors.forEach(error => {
          message.error(error.message);
        });
      }
      if (networkError) {
        message.error(`[Error de Red]: ${networkError}`);
      }
    }
  });

  const closeModalProject = () => {
    setGalleryItems([]);
    changeSelectedGalleryItems([]);
    setName(null);
    setDescription(null);
    setMaterials([]);
    setTags([]);
    setInvestment(null);
    setThumbnail(null);
    resetFields();
    dispatch(toggleProjectModal({info: null, visible: false}));
  };

  // DropZone
  const onDrop = useCallback(acceptedFiles => {
    setCurrentStep(2);
    let files = Array.from(acceptedFiles);
    let items = [];
    setFetchingFiles(true);
    files = files.map(async file => {
      const base64 = await getBase64(file);
      const filterIfExists = galleryItems.filter(item => item.base64 === base64);
      if (filterIfExists.length === 0) {
        const object = Object.assign(file, {
          index: file.name, base64,
          extension: file.name.split('.')[1]
        });
        return items.push(object);
      }
    });
    Promise.all(files).then(() => {
      setGalleryItems([...galleryItems, ...items]);
      setFetchingFiles(false);
      if (!thumbnail) {
        Modal.info({
          title: '¡Importante!',
          okText: 'Entendido',
          centered: true,
          content: (
            <div>
              Por favor seleccione una imagen destacada para usar como portada de tu proyecto. Para elegir una imagen
              haga clic en el ícono de la estrella que se muestra en cada foto cargada.
            </div>
          ),
          onOk() {},
        });
      }
    });
  }, [galleryItems, thumbnail]);

  const {isDragAccept, isDragReject, getRootProps, getInputProps, isDragActive} = useDropzone({
    accept: 'image/jpeg, image/png, image/jpg',
    noKeyboard: true,
    minSize: 10,
    maxSize: 4000000,
    multiple: true,
    onDropRejected: (files) => {
      files.forEach(file => {
        const filename = file.file.name;
        if (file.errors) {
          file.errors.forEach(error => {
            if (error.code === 'file-too-large') {
              message.warn(`Error: La imagen: ${filename} pesa más de 4MB`);
            }
          });
        }
      });
    },
    onDrop
  });

  useEffect(() => () => {
    galleryItems.forEach(file => URL.revokeObjectURL(file.preview));
  }, [galleryItems]);

  const toggleSelectedGalleryItem = item => {
    const filtered = selectedGalleryItems.includes(item);
    if (!filtered) {
      changeSelectedGalleryItems([...selectedGalleryItems, item]);
    } else {
      const filteredRemoved = selectedGalleryItems.filter(photo => photo.index !== item.index);
      changeSelectedGalleryItems(filteredRemoved);
    }
  }

  const removeSelectedGalleryItems = () => {
    const filtered = galleryItems.filter(item => !selectedGalleryItems.includes(item));
    setGalleryItems(filtered);
    changeSelectedGalleryItems([]);
  };

  const [createProject, {loading: saving}] = useMutation(CREATE_PROJECT_MUTATION, {
    update(cache, {data: {createProject}}) {
      const {userProjects: {data}} = cache.readQuery({query: USER_PROJECTS});
      const newData = [createProject, ...data];
      props.updateStateUserProjects(newData);
    },
    onError({graphQLErrors, networkError}) {
      if (graphQLErrors) {
        graphQLErrors.forEach(({extensions}) => {
          message.warn(extensions.reason);
        });
      }
      if (networkError) {
        message.error(`[Error de Red]: ${networkError}`);
      }
    }
  });

  const [updateProject, {loading: updating}] = useMutation(UPDATE_PROJECT_MUTATION, {
    update(cache, {data: {updateProject}}) {
      if (!props.useCMS) {
        let {userProjects: {data}} = cache.readQuery({query: USER_PROJECTS});
        const indexFilter = data.findIndex(item => item.id === updateProject.id);
        if (indexFilter >= 0) {
          data[indexFilter] = updateProject;
          props.updateStateUserProjects(data);
        }
      }
    },
    onError({graphQLErrors, networkError}) {
      if (graphQLErrors) {
        graphQLErrors.forEach(({extensions}) => {
          message.warn(extensions.reason);
          // console.log(graphQLErrors)
        });
      }
      if (networkError) {
        message.error(`[Error de Red]: ${networkError}`);
      }
    }
  });

  const submit = e => {
    e.preventDefault();
    validateFields(async errors => {
      if (errors) {
        message.warning('Por favor ingrese la información requerida');
        return false;
      }
      if (!errors) {
        if (!name || !description || !materials || !investment || !tags) {
          message.warning('Por favor ingrese la información requerida');
          return false;
        }
        if (galleryItems.length === 0) {
          message.warning('Por favor agregue fotos al proyecto');
          return false;
        }
        if (!thumbnail) {
          message.warning('Por favor seleccione una foto destacada haciendo clic en la estrella.');
          return false;
        }
        let gallery = [];
        galleryItems.forEach(item => {
          const object = {
            base64: item.base64,
            extension: item.extension,
            thumbnail: item.thumbnail || item === thumbnail
          };
          if (item.id) {
            object['id'] = item.id;
          }
          if (item.url) {
            object['url'] = item.url;
          }
          gallery.push(object);
        });
        let variables = {
          input: {
            name, description,
            materials: JSON.stringify(materials),
            tags: JSON.stringify(tags),
            investment: investment ? investment.toFixed(2) : null
          }
        };
        let filesPresets = [];
        setUploading(true);
        const headers = {"Content-Type": "multipart/form-data"};
        if (modalProject.info) {
          await Promise.all(
            gallery.map(async photo => {
              let file;
              if (!photo.url) {
                const info = new FormData();
                info.append('file', photo.base64);
                info.append('upload_preset', cloudinaryUploadPreset);
                await axios.post(cloudinaryEndPoint, info, {headers}).then(res => {
                  file = {
                    extension: photo.extension,
                    thumbnail: photo.thumbnail,
                    url: res.data.secure_url
                  };
                }).catch(() => message.warning('Error al subir foto'));
              } else {
                file = {
                  id: photo.id,
                  extension: photo.extension,
                  thumbnail: photo.thumbnail,
                  url: photo.url
                };
              }
              filesPresets.push(file);
            })
          ).then(() => {
            setUploading(false);
            variables['id'] = parseInt(modalProject.info.id);
            variables['input']['gallery'] = {upsert: filesPresets};
            updateProject({variables}).then(() => {
              message.success('Proyecto actualizado correctamente');
              closeModalProject();
            });
          });
        } else {
          await Promise.all(
            gallery.map(async photo => {
              const info = new FormData();
              info.append('file', photo.base64);
              info.append('upload_preset', cloudinaryUploadPreset);
              await axios.post(cloudinaryEndPoint, info, {headers}).then(res => {
                const uploadedFile = {
                  extension: photo.extension,
                  thumbnail: photo.thumbnail,
                  url: res.data.secure_url
                };
                filesPresets.push(uploadedFile);
              }).catch(() => message.warning('Error al subir foto'));
            })
          ).then(() => {
            setUploading(false);
            if (filesPresets.length > 0) {
              variables['input']['gallery'] = {create: filesPresets};
              createProject({variables}).then(() => {
                message.success('Proyecto registrado');
                closeModalProject();
              });
            }
          });
        }
      }
    })
  };

  const changeThumbnail = file => {
    setThumbnail(file);
    if (thumbnail && thumbnail.id) {
      const filteredIndex = galleryItems.findIndex(item => item.id ? item.id === thumbnail.id : false);
      if (filteredIndex > -1) {
        galleryItems[filteredIndex].thumbnail = false;
      }
    }
    if (file.id) {
      const filteredIndex = galleryItems.findIndex(item => item.id ? item.id === file.id : false);
      if (filteredIndex > -1) {
        galleryItems[filteredIndex].thumbnail = true;
      }
    }
    setGalleryItems(galleryItems);
  };

  const deletePhoto = file => {
    const modal = Modal.confirm({
      title: `¿Quieres eliminar esta foto?`,
      content: `Al hacer clic en el botón Aceptar, la imagen "${file.index}" será eliminada definitivamente.`,
      okText: 'Aceptar',
      cancelText: 'Cancelar',
      okButtonProps: {
        loading: deletingPhoto,
        disabled: deletingPhoto
      },
      centered: true,
      onOk: async () => {
        modal.update({
          okButtonProps: {
            loading: true,
            disabled: true
          },
          cancelButtonProps: {
            disabled: true
          }
        });
        setDeletingPhoto(true);
        const public_id = getPublicIDCloudinaryFile(file.url);
        await deleteCloudinaryFile({variables: {file: public_id}}).then(async ({data}) => {
          if (data.deleteCloudinaryFile) {
            if (data.deleteCloudinaryFile.status) {
              await deleteMultimedia({
                variables: {
                  id: file.id
                }
              }).then(() => {
                setDeletingPhoto(false);
              });
            } else {
              message.warning(data.deleteCloudinaryFile.message);
              setDeletingPhoto(false);
            }
          } else {
            setDeletingPhoto(false);
          }
        });
      }
    });
  };

  const changeSelectedTags = values => {
    setTags(values);
  };

  return (
    <Drawer
      visible={visible}
      placement="bottom"
      width="100vw"
      height="100vh"
      bodyStyle={{height: '100%', padding: 0}}
      onClose={closeModalProject}
      title={(
        <Row type="flex" justify="space-around">
          <Col xs={24} sm={24} md={18} lg={12} xl={12} xxl={12} className="gx-p-0">
            {`${modalProject.info ? 'EDICIÓN DE' : 'NUEVO'} PROYECTO`}
          </Col>
        </Row>
      )}
      headerStyle={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        zIndex: 100
      }}
    >
      <Row type="flex" justify="space-around" className="gx-mb-4" style={{marginTop: 50}}>
        <Col xs={24} sm={24} md={18} lg={12} xl={12} xxl={12} className="gx-p-0">
          <Spin spinning={saving || loading || updating || loadingTags || uploading} indicator={spinner}>
            <Card bordered={false}>
              <Form onSubmit={submit} ref={_form} id="project-form" layout="vertical" hideRequiredMark>
                <Steps direction="vertical" size="small" current={currentStep}>
                  <Step
                    status={getFieldValue('name') && getFieldValue('description') ? 'finish' : currentStep === 0 ? 'process' : 'wait'}
                    title={(
                      <div className="gx-full-width gx-mb-3">
                        <div className="gx-mb-0">
                          CUÉNTANOS DE TU PROYECTO
                        </div>
                        <div className="gx-full-width gx-fs-sm gx-text-muted">
                          Información básica de tu proyecto
                        </div>
                      </div>
                    )}
                    description={(
                      <Fragment>
                        <Form.Item label="Nombre">
                          {getFieldDecorator("name", {
                            initialValue: name,
                            rules: [
                              {
                                required: true,
                                message: "Ingrese nombre proyecto",
                              }
                            ],
                            onChange: e => setName(e.target.value)
                          })(<Input ref={_name} autoFocus onFocus={() => setCurrentStep(0)}/>)}
                        </Form.Item>
                        <Form.Item label="Descripción">
                          {getFieldDecorator("description", {
                            initialValue: description,
                            rules: [
                              {
                                required: true,
                                message: "Ingrese nombre proyecto",
                              }
                            ],
                            onChange: e => setDescription(e.target.value)
                          })(<Input.TextArea onFocus={() => setCurrentStep(0)} autoSize={{maxRows: 8, minRows: 4}}/>)}
                        </Form.Item>
                      </Fragment>
                    )}
                  />
                  <Step
                    status={materials.length > 0 && getFieldValue('investment') ? 'finish' : currentStep === 1 ? 'process' : 'wait'}
                    title={(
                      <div className="gx-full-width gx-mb-3">
                        <div className="gx-mb-0">
                          ¿QUÉ MATERIALES USASTE?
                        </div>
                        <div className="gx-full-width gx-fs-sm gx-text-muted">
                          Es importante para que los clientes conozcan la calidad de tu producto
                        </div>
                      </div>
                    )}
                    description={(
                      <Fragment>
                        <Form.Item
                          label={(
                            <div className="gx-full-width">
                              Materiales
                              <div className="gx-full-width">
                                <small className="gx-text-grey">
                                  Especifique los materiales y marcas utilizadas (Separar por comas)
                                </small>
                              </div>
                            </div>
                          )}
                        >
                          {getFieldDecorator("materials", {
                            initialValue: materials,
                            rules: [
                              {
                                required: true,
                                message: "Ingrese los materiales usados",
                              }
                            ],
                            onChange: values => setMaterials(values)
                          })(
                            <Select
                              mode="tags"
                              style={{width: '100%'}}
                              tokenSeparators={[',']}
                              dropdownClassName="gx-d-none"
                              placeholder="Ej.: Melamina Vesto, Clavos, etc."
                              onFocus={() => setCurrentStep(1)}
                            />
                          )}
                        </Form.Item>
                        <Form.Item
                          label={(
                            <div className="gx-full-width">
                              Inversión (S/)
                              <div className="gx-full-width">
                                <small className="gx-text-grey">
                                  Especifique el precio del proyecto
                                </small>
                              </div>
                            </div>
                          )}
                        >
                          {getFieldDecorator("investment", {
                            initialValue: investment,
                            rules: [
                              {
                                required: true,
                                message: "Ingrese la inversión",
                              }
                            ],
                            onChange: amount => setInvestment(amount)
                          })(
                            <InputNumber
                              style={{width: '100%'}}
                              min={0}
                              placeholder="1,000"
                              ref={_investment}
                              onKeyDown={numbersOnly}
                              formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              parser={value => value.replace(/\\s?|(,*)/g, '')}
                              onFocus={() => setCurrentStep(1)}
                            />
                          )}
                        </Form.Item>
                      </Fragment>
                    )}
                  />
                  <Step
                    status={tags.length > 0 && (galleryItems.length > 0 && thumbnail) ? 'finish' : currentStep === 2 ? 'process' : 'wait'}
                    title={(
                      <div className="gx-full-width gx-mb-3">
                        <div className="gx-mb-0">
                          IDENTIFIQUEMOS TU PROYECTO
                        </div>
                        <div className="gx-full-width gx-fs-sm gx-text-muted">
                          Hagamos tu proyecto accesible
                        </div>
                      </div>
                    )}
                    description={(
                      <Fragment>
                        <Form.Item
                          className="form-control-ec"
                          label={(
                            <div className="gx-full-width">
                              Etiquetas
                              <div className="gx-full-width">
                                <span className="gx-fs-sm gx-text-grey">
                                  Permite a los usuarios encontrar con facilidad tu proyecto. Intenta usar palabras claves.
                                </span>
                              </div>
                            </div>
                          )}
                        >
                          {getFieldDecorator("tag", {
                            initialValue: tags,
                            rules: [
                              {
                                required: true,
                                message: "Por favor ingrese las etiquetas del proyecto",
                              }
                            ],
                          })(
                            <Select
                              mode="multiple"
                              onChange={changeSelectedTags}
                              style={{width: '100%'}}
                              placeholder="Ej.: Oficina, Escritorio, Moderno, etc."
                              notFoundContent={loadingTags ? <Spin size="small" indicator={spinner} /> : null}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              onFocus={() => setCurrentStep(2)}
                            >
                              {filteredTags.length > 0 && (
                                filteredTags.map((tag, tagIndex) => (
                                  <Option key={`filtered-${tagIndex}`} value={tag.description}>
                                    {tag.description}
                                  </Option>
                                ))
                              )}
                            </Select>
                          )}
                        </Form.Item>
                        <Form.Item
                          label={(
                            <div className="gx-full-width">
                              <div className="gx-full-width gx-d-inline-flex gx-align-items-center">
                                Fotos
                                <span className="gx-text-muted gx-fs-sm gx-ml-auto">
                                  Tamaño máximo por foto: 4MB
                                </span>
                              </div>
                            </div>
                          )}
                        >
                          <Spin spinning={fetchingFiles} indicator={spinner}>
                            <div {...getRootProps()} className="dropzone gx-pointer gx-mb-1">
                              <input {...getInputProps()}/>
                              {isDragActive ?
                                <p className="gx-mb-0">Suelta los archivos aquí...</p> :
                                <p className="gx-mb-0">
                                  Arrastre y suelte algunos archivos aquí, o haga clic para seleccionar archivos
                                </p>
                              }
                              {isDragAccept && (
                                <p className="gx-mb-0 gx-mt-1 gx-text-success">Todos los archivos serán aceptados.</p>
                              )}
                              {isDragReject && (
                                <p className="gx-mb-0 gx-mt-1 gx-text-danger">Algunos archivos serán rechazados</p>
                              )}
                            </div>
                            <div className='gallery-wrapper gx-mt-2'>
                              {galleryItems.map((item, index) => {
                                const selected = selectedGalleryItems.includes(item);
                                return (
                                  <figure className={selected ? 'gallery-selected' : ''} key={index}>
                                    <img
                                      src={item.base64 || item.url}
                                      alt={`${item.url}`}
                                    />
                                    <div className="gallery-actions">
                                      {!item.id ? (
                                        <Fragment>
                                          {thumbnail !== item && (
                                            <Button
                                              shape="circle"
                                              type={selected ? 'danger' : 'default'}
                                              className="gx-border-none gx-mr-1"
                                              onClick={() => toggleSelectedGalleryItem(item)}
                                            >
                                              <i
                                                className={`far fa-trash-alt ${!selected ? 'gx-text-danger' : 'gx-text-white'}`}
                                              />
                                            </Button>
                                          )}
                                        </Fragment>
                                      ) : (
                                        <Fragment>
                                          {!item.thumbnail && (
                                            <Button
                                              type="danger"
                                              shape="circle"
                                              className="gx-border-none gx-mr-1"
                                              onClick={() => deletePhoto(item)}
                                            >
                                              <i className="far fa-trash-alt"/>
                                            </Button>
                                          )}
                                        </Fragment>
                                      )}
                                      {!selected && (
                                        <Button
                                          shape="circle"
                                          type={thumbnail === item ? 'primary' : 'default'}
                                          onClick={() => changeThumbnail(item)}
                                        >
                                          <i className={`${thumbnail === item ? 'fa gx-text-yellow' : 'fal'} fa-star`}/>
                                        </Button>
                                      )}
                                    </div>
                                  </figure>
                                );
                              })}
                            </div>
                            <Button
                              type="danger"
                              className="gx-mt-2 gx-mb-0"
                              onClick={removeSelectedGalleryItems}
                              disabled={selectedGalleryItems.length === 0}
                            >
                              <i className="far fa-trash-alt gx-mr-2"/>
                              Quitar imagenes
                            </Button>
                          </Spin>
                        </Form.Item>
                      </Fragment>
                    )}
                  />
                </Steps>
              </Form>
            </Card>
          </Spin>
        </Col>
      </Row>

      <div
        className="gx-mb-0"
        style={{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }}
      >
        <Button className="gx-mb-0" disabled={saving || updating || deletingPhoto} style={{marginRight: 8}} onClick={closeModalProject}>
          Cancelar
        </Button>
        <Button disabled={saving || updating || deletingPhoto} className="gx-mb-0" type="primary" htmlType="submit" form="project-form">
          Guardar
        </Button>
      </div>
    </Drawer>
  );
};

export default Form.create()(ProjectsForm);
