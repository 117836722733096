import gql from 'graphql-tag';

export const PUBLIC_PROJECTS = gql`
  query getPublicProjects($page: Int!){
    publicProjects(first: 10, page: $page) {
      data {
        id
        name
        description
        thumbnail {
          url
          base64
        }
        user {
          id
          name
        }
        favorites
        shared
        visited
        created_at
      }
      paginatorInfo {
        hasMorePages
      }
    }
  }
`;

export const SEARCH_PUBLIC_PROJECTS = gql`
  query searchProjects($query: String, $page: Int!) {
    searchProject(query: $query, first: 20, page: $page) {
      data {
        id
        name
        description
        thumbnail {
          url
        }
        user {
          id
          name
        }
        tags
        favorites
        shared
        visited
        created_at
      }
      paginatorInfo {
        hasMorePages
      }
    }
  }
`;

export const USER_PROJECTS = gql`
  query userProjects {
    userProjects (
      orderBy: {
        field: ID,
        order: DESC
      }
    ) {
      data {
        id
        name
        description
        materials
        tags
        currency
        investment
        status
        created_at
        gallery {
          id
          url
          base64
          extension
          thumbnail
        }
      }
    }
  }
`;

export const PROJECT = gql`
  query retrieveProject($id: ID!) {
    project(id: $id){
      id
      name
      description
      materials
      gallery {
        url
        base64
      }
      currency
      investment
      tags
      status
      user {
        id
        avatar
        name
        last_name
        email
        phone
        cellphone
        specialty {
          id
          description
          parent {
            id
            description
          }
        }
        experience_years
        experience_resume
        latest_connection
      }
    }
  }
`;

export const PROJECT_WITHOUT_CONTACT = gql`
  query retrieveProject($id: ID!) {
    project(id: $id){
      id
      name
      description
      materials
      gallery {
        url
        base64
      }
      currency
      investment
      tags
      status
      user {
        id
        avatar
        name
        last_name
        specialty {
          id
          description
          parent {
            id
            description
          }
        }
        experience_years
        experience_resume
        latest_connection
      }
    }
  }
`;

export const RETRIEVE_USER_PROJECT = gql`
  query retrieveUserProject($id: ID!) {
    userProject(id: $id) {
      id
      name
      description
      materials
      gallery {
        id
        url
        extension
        thumbnail
      }
      currency
      investment
      tags
    }
  }
`;

export const CMS_PROJECTS = gql`
  query filterCMSProjects(
    $query: String,
    $range: RangeInput,
    $first: Int!,
    $page: Int
  ) {
    filterCMSProjects(
      first: $first,
      page: $page,
      query: $query,
      range: $range
    ) {
      data {
        id
        name
        description
        user {
          id
          name
          last_name
        }
        status
        created_at
      }
      paginatorInfo {
        hasMorePages
        currentPage
        lastPage
      }
    }
  }
`;

export const SIMILAR_PROJECTS = gql`
  query similarProjects($first: Int, $id: ID!, $query: String!, $page: Int) {
    similarProjects(id: $id, page: $page, first: $first, query: $query) {
      data {
        id
        name
        description
        thumbnail {
          url
        }
        user {
          id
          name
        }
        favorites
        shared
        visited
        created_at
      }
      paginatorInfo {
        hasMorePages
      }
    }
  }
`;
