import React, {useState, Fragment, useEffect, useCallback} from 'react'
import {
  Row,
  Col,
  Card,
  Button,
  Input,
  DatePicker,
  Form,
  message,
  Result,
  Radio,
  Select,
  Empty,
  Spin,
  Modal,
  InputNumber
} from 'antd'
import {googleGeocode} from "../../util/helpers"
import {useSelector} from 'react-redux'
import fireworks from './fireworks.png'
import {GoogleMap, withGoogleMap, Marker} from "react-google-maps"
import {GET_LOCATIONS} from "../../graphql/query/ubigeo"
import {useMutation, useQuery, useLazyQuery} from "@apollo/react-hooks"
import {GET_MASTERS} from "../../graphql/query/master"
import {useDispatch} from "react-redux"
import {spinner} from "../../util/customComponents"
import {Link, useHistory} from "react-router-dom"
import {UPDATE_USER_INFO, TOGGLE_WIZARD_PANEL} from "../../graphql/mutation/user"
import {skipRegisterInfo, updateUserStorage} from "../../appRedux/actions"
import moment from "moment"

const {Item} = Form

const entity = ["MEMBRESIA", "TIPO_DOCUMENTO", "ESPECIALIDAD", "PROFESION"]
let timer = 0

const google = window.google
const GoogleMapConfig = withGoogleMap((props) => (
  <GoogleMap
    onClick={props.handleOnClickMap}
    defaultZoom={props.zoom}
    center={props.center}
    defaultOptions={props.options}
  >
    <Marker position={props.center} onClick={props.handleOnClickMap}/>
  </GoogleMap>
))

const options = {
  scrollwheel: true,
  mapTypeControl: false,
  fullscreenControl: false,
  zoomControlOptions: {
    style: google.maps.ZoomControlStyle.SMALL,
  },
  mapTypeControlOptions: {
    position: google.maps.ControlPosition.TOP_RIGHT,
  },
  draggable: true,
  rotateControl: true,
  scaleControl: true,
  streetViewControl: false,
  panControl: true,
}

const Joined = props => {
  const {authUser} = useSelector(({auth}) => auth)
  const dispatch = useDispatch()
  const history = useHistory()
  const {form} = props
  const {getFieldDecorator, getFieldValue, validateFields, setFieldsValue} = form

  const [step, setStep] = useState(0)
  const [loading, setLoading] = useState(true)
  const [dataMasters, setDataMasters] = useState([])
  const [hasStarted, setHasStarted] = useState(false)

  const [documentType, setDocumentType] = useState(authUser.document_type ? authUser.document_type.id : undefined)
  const [document, setDocument] = useState(authUser.document_number)
  const [birthday, setBirthday] = useState(authUser.birth_date ? moment(authUser.birth_date) : null)
  const [sex, setSex] = useState(authUser.sex)
  const [cellphone, setCellphone] = useState(authUser.cellphone)
  const [phone, setPhone] = useState(authUser.phone)
  const [locations, setLocations] = useState(authUser.ubigeo ? [authUser.ubigeo] : [])
  const [address, setAddress] = useState(authUser.address)
  const [currentDistrict, setCurrentDistrict] = useState(authUser.ubigeo ? authUser.ubigeo.id : undefined)
  const [currentDistrictName, setCurrentDistrictName] = useState(authUser.ubigeo ? `${authUser.ubigeo.district}/${authUser.ubigeo.province}/${authUser.ubigeo.department}` : undefined)
  const [profession, setProfession] = useState(authUser.specialty ? authUser.specialty.parent ? authUser.specialty.parent.id : undefined : undefined)
  const [specialty, setSpecialty] = useState(authUser.specialty ? authUser.specialty.id : undefined)
  const [years, setYears] = useState(authUser.experience_years)
  const [resume, setResume] = useState(authUser.experience_resume)
  const [center, setCenter] = useState({
    lat: authUser.lat ? parseFloat(authUser.lat) : -12.0262676,
    lng: authUser.lng ? parseFloat(authUser.lng) : -77.1278634,
  })

  const [checkedGeneralData, setCheckedGeneralData] = useState(documentType !== undefined && document !== null && birthday !== null && sex !== null)
  const [checkedContactData, setCheckedContactData] = useState(cellphone !== null)
  const [checkedAddress, setCheckedAddress] = useState(address !== null && currentDistrict !== undefined && (center && (center.lat !== null && center.lng !== null)) && authUser.lat !== null && authUser.lng !== null)
  const [checkedProfessional, setCheckedProfessional] = useState(specialty !== undefined && profession !== undefined && resume !== null && years !== null)

  const [saveToggleWizard, {loading: finishing}] = useMutation(TOGGLE_WIZARD_PANEL, {
    onCompleted({toggleWizardPanel}) {
      localStorage.setItem('user', JSON.stringify(toggleWizardPanel))
      dispatch(updateUserStorage(toggleWizardPanel))
      props.history.push('/')
    },
    onError() {
      message.error('Ha ocurrido un error al finalizar el proceso de actualización.')
    }
  })
  
  const finishWizardPanel = useCallback((finish = false) => {
    const variables = {
      id: authUser.id,
      input: {
        wizard_panel: false
      }
    }
    saveToggleWizard({variables});
  }, [authUser, saveToggleWizard])

  useEffect(() => {
    if (checkedGeneralData && checkedContactData && checkedAddress && checkedProfessional) {
      finishWizardPanel(false)
    }
  }, [checkedGeneralData, checkedContactData, checkedAddress, checkedProfessional, finishWizardPanel])

  const {loading: loadingMasters} = useQuery(GET_MASTERS, {
    variables: {
      column: "ENTITY",
      operator: "IN",
      value: entity,
    },
    onCompleted({masters}) {
      setDataMasters(masters)
      setLoading(false)
    },
    onError() {
      message.error('Ha ocurrido un error')
    },
  })

  const [queryLocations, {loading: loadingLocation}] = useLazyQuery(GET_LOCATIONS, {
    onCompleted({consultar_ubigeos}) {
      setLocations(consultar_ubigeos)
    },
    onError() {
      message.error('Ha ocurrido un error al obtener la lista de ubigeos.')
    }
  })

  const [updateUserInfo] = useMutation(UPDATE_USER_INFO, {
    onCompleted({updateUser}) {
      localStorage.setItem('user', JSON.stringify(updateUser))
      setLoading(false)
    },
    onError() {
      message.error('Ha ocurrido un error al actualizar tu información')
      setLoading(false)
    }
  })

  const renderEntity = ({entity, data = []}) => {
    return Array.isArray(data) &&
      data.map((master) =>
        master.entity === entity ? (
          <Select.Option value={master.id} key={master.id}>
            {master.description}
          </Select.Option>
        ) : null
      )
  }

  const submitGeneral = event => {
    event.preventDefault()
    validateFields(async (error, values) => {
      if (!error) {
        setLoading(true)
        const variables = {
          input: {
            id: authUser.id,
            document_type_id: values.documentType || null,
            document_number: values.document || null,
            birth_date: values.birthday ? moment(values.birthday).format('YYYY-MM-DD') : null,
            sex: values.sex || null
          }
        }
        await updateUserInfo({variables}).then(() => {
          setCheckedGeneralData(true)
          setStep(2)
        })
      }
    })
  }

  const contactForm = event => {
    event.preventDefault()
    validateFields(async (error, values) => {
      if (!error) {
        setLoading(true)
        const variables = {
          input: {
            id: authUser.id,
            cellphone: values.cellphone !== '' ? values.cellphone : null,
            phone: values.phone !== '' ? values.phone : null,
          }
        }
        await updateUserInfo({variables}).then(() => {
          setCheckedContactData(true)
          setStep(3)
        })
      }
    })
  }

  const addressForm = event => {
    event.preventDefault()
    validateFields(async (error, values) => {
      if (!error) {
        setLoading(true)
        const variables = {
          input: {
            id: authUser.id,
            address: values.address,
            ubigeo_id: parseInt(currentDistrict),
            lat: parseFloat(center.lat).toString(),
            lng: parseFloat(center.lng).toString()
          }
        }
        await updateUserInfo({variables}).then(() => {
          setCheckedAddress(true)
          setStep(4)
        })
      }
    })
  }

  const professionalForm = event => {
    event.preventDefault()
    validateFields(async (error, values) => {
      if (!error) {
        setLoading(true)
        const variables = {
          input: {
            id: authUser.id,
            specialty_id: values.specialty,
            experience_years: values.years,
            experience_resume: values.resume
          }
        }
        await updateUserInfo({variables}).then(() => {
          finishWizardPanel(false)
          setCheckedProfessional(true)
        })
      }
    })
  }

  const goToNextPanel = () => {
    if (!hasStarted){
      setHasStarted(true)
    }
    if (!checkedGeneralData) {
      setStep(1)
      return
    }
    if (!checkedContactData) {
      setStep(2)
      return
    }
    if (!checkedAddress) {
      setStep(3)
      if (authUser.ubigeo) {
        getPosition({sDistrito: authUser.ubigeo.district, sDireccion: authUser.address})
      }
      return
    }
    if (!checkedProfessional) {
      setStep(4)
      return
    }
  }

  const skip = () => {
    const modal = Modal.confirm({
      title: `¿Seguro que deseas omitir este proceso?`,
      content: `Este proceso se cancelará al aceptar. Pero si deseas actualizar tu información puedes hacerlo en "Mi cuenta".`,
      okText: 'Aceptar',
      cancelText: 'Cancelar',
      centered: true,
      onOk: async () => {
        modal.update({
          okButtonProps: {
            loading: true,
            disabled: true
          },
          cancelButtonProps: {
            disabled: true
          }
        })
        localStorage.setItem('skip_fill_info', 'skip')
        dispatch(skipRegisterInfo('skip'))
        history.push('/')
      }
    })
  }

  const handleOnSearch = (value) => {
    clearInterval(timer)
    timer = setTimeout(() => {
      if (value) {
        queryLocations({variables: {value: `%${value}%`}})
      }
    }, 500)
  }

  const handleOnClickMap = (event) => {
    const lat = event.latLng.lat()
    const lng = event.latLng.lng()
    setCenter({lat, lng})
  }

  const handleSelectDistrict = (value, options) => {
    if (options) {
      const {text} = options.props
      const stringChild = (text && text.replace(/[/]/g, "")) || ""
      setCurrentDistrict(value)
      setCurrentDistrictName(stringChild)
      getPosition({sDistrito: stringChild, sDireccion: address})
    }
  }

  const handleOnBlurAddress = ({target: {value}}) => {
    getPosition({sDistrito: currentDistrictName, sDireccion: value})
  }

  const handleAddress = value => {
    getPosition({sDistrito: currentDistrictName, sDireccion: value})
  }

  const getPosition = async ({sDireccion, sDistrito}) => {
    // console.log(sDireccion, sDistrito)
    try {
      const stringLocation = `${sDireccion} ${sDistrito}`
      const coordinates = await googleGeocode(stringLocation)
      setCenter(coordinates)
    } catch (error) {
      message.warning("No se pudo obtener coordenadas")
    }
  }

  const handleChangeSex = value => {
    if (value === sex) {
      setSex(null)
      setFieldsValue({sex: null})
    } else {
      setSex(value)
    }
  }

  const skipContact = () => {
    setCellphone(authUser.cellphone)
    setPhone(authUser.phone)
    setStep(2)
  }

  const skipLaboralStore = () => {
    setLocations(authUser.ubigeo ? [authUser.ubigeo] : [])
    setAddress(authUser.address)
    setCurrentDistrict(authUser.ubigeo ? authUser.ubigeo.id : undefined)
    setCenter({
      lat: authUser.lat ? parseFloat(authUser.lat) : -12.0262676,
      lng: authUser.lng ? parseFloat(authUser.lng) : -77.1278634,
    })
    setStep(3)
  }

  const skipProfessional = () => {
    const {cellphone, phone, ubigeo, address, lat, lng, specialty, experience_resume, experience_years} = authUser
    if (!cellphone && !phone && !ubigeo && !address && !lat && !lng && !specialty && !experience_resume && !experience_years) {
      setProfession(authUser.specialty ? authUser.specialty.id : undefined)
      setResume(authUser.experience_resume ? authUser.experience_resume : null)
      setYears(authUser.experience_years ? authUser.experience_years : null)
      setStep(4)
    } else {
      skip()
    }
  }

  return (
    <Fragment>
      <Row type="flex" justify="space-around" align="middle" className="gx-mb-4">
        <Col xs={24} sm={24} md={18} lg={12} xl={12} xxl={12} className="gx-p-0 gx-mt-4">
          <Spin spinning={loadingMasters || loading} indicator={spinner}>
            {!hasStarted && step === 0 && (
              <Card>
                <h1 className="gx-font-weight-semi-bold">Hola, {authUser.name.split(' ')[0]}</h1>
                <p className="gx-font-weight-semi-bold gx-fs-lg">Gracias por unirte a eCarpintero.</p>
                <div className="gx-mt-4">
                  Antes de continuar necesitamos un poco más de información sobre tí. Por ejemplo:
                </div>
                <div className="gx-mt-3 gx-ml-4">
                  <div>
                    {checkedContactData ? (
                      <i className="far fa-check-double gx-text-success gx-mr-2"/>
                    ) : (
                      <i className="far fa-times gx-text-danger gx-mr-2"/>
                    )}
                    Datos de Contacto
                  </div>
                  <div>
                    {checkedAddress ? (
                      <i className="far fa-check-double gx-text-success gx-mr-2"/>
                    ) : (
                      <i className="far fa-times gx-text-danger gx-mr-2"/>
                    )}
                    Ubicación de Taller
                  </div>
                  <div>
                    {checkedProfessional ? (
                      <i className="far fa-check-double gx-text-success gx-mr-2"/>
                    ) : (
                      <i className="far fa-times gx-text-danger gx-mr-2"/>
                    )}
                    Datos Profesionales
                  </div>
                  {!authUser.cellphone || !authUser.phone || !authUser.ubigeo || !authUser.address || !authUser.lat || !authUser.lng || !authUser.specialty || !authUser.experience_resume || !authUser.experience_years || (
                    <div>
                      {checkedGeneralData ? (
                        <i className="far fa-check-double gx-text-success gx-mr-2"/>
                      ) : (
                        <i className="far fa-times gx-text-danger gx-mr-2"/>
                      )}
                      Datos Personales
                    </div>
                  )}
                </div>
                {dataMasters && (
                  <div className="gx-mt-4">
                    <Button className="gx-mb-0" onClick={skip}>
                      Omitir
                    </Button>
                    <Button type="primary" className="gx-mb-0" onClick={goToNextPanel}>
                      Continuar
                      <i className="far fa-long-arrow-right gx-ml-2"/>
                    </Button>
                  </div>
                )}
                <img
                  alt="Imagen"
                  src={fireworks}
                  className="gx-d-md-block gx-d-none"
                  style={{
                    position: 'absolute',
                    width: 250,
                    bottom: -55,
                    right: -100
                  }}
                />
              </Card>
            )}
            {step === 1 && (
              <Card>
                <h1 className="gx-font-weight-semi-bold">
                  <i className="fal fa-phone-volume gx-mr-3"/>
                  Datos de Contacto
                </h1>
                <Form id="contactForm" onSubmit={contactForm} layout="vertical" className="gx-mt-4">
                  <Item label="Celular">
                    {getFieldDecorator('cellphone', {
                      initialValue: cellphone,
                      rules: [
                        {required: false, message: 'Por favor ingrese el campo solicitado'}
                      ]
                    })(<Input allowClear onChange={e => setCellphone(e.target.value)} placeholder="Ingrese el número de celular"/>)}
                  </Item>
                  <Item label="Teléfono">
                    {getFieldDecorator('phone', {
                      initialValue: phone
                    })(<Input allowClear onChange={e => setPhone(e.target.value)} placeholder="Ingrese el numero de teléfono"/>)}
                  </Item>
                  <Item>
                    <small className="gx-mb-2 gx-text-primary">
                      <i className="far fa-info-circle gx-mr-1"/>
                      Puedes agregar tu información de contacto para que tus clientes se comuniquen contigo.
                    </small>
                  </Item>
                  <Item className="gx-mb-0">
                    <Button disabled={loading} type="primary" htmlType="submit" form="contactForm" className="gx-mb-0">
                      <i className="far fa-save gx-mr-2"/>
                      Guardar
                    </Button>
                    <Button disabled={loading} htmlType="button" className="gx-mb-0" onClick={skipContact}>
                      Omitir
                    </Button>
                  </Item>
                </Form>
              </Card>
            )}
            {step === 2 && (
              <Card>
                <h1 className="gx-font-weight-semi-bold">
                  <i className="fal fa-store gx-mr-3"/>
                  Ubicación de Taller
                </h1>
                <Form id="addressForm" onSubmit={addressForm} layout="vertical" className="gx-mt-4">
                  <Item label="Distrito">
                    {getFieldDecorator('district', {
                      initialValue: currentDistrictName,
                      rules: [
                        {required: false, message: 'Por favor ingrese el campo solicitado'}
                      ]
                    })(
                      <Select
                        allowClear
                        showSearch
                        onSearch={handleOnSearch}
                        onSelect={handleSelectDistrict}
                        filterOption={(input, option) => {
                          const regExp = new RegExp(input, "gi")
                          return option.props.district.match(regExp)
                        }}
                        placeholder="Buscar distrito"
                        notFoundContent={
                          <Spin spinning={loadingLocation}>
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                          </Spin>
                        }
                      >
                        {Array.isArray(locations) && locations.map((location) => (
                          <Select.Option
                            value={location.id}
                            key={location.id}
                            district={location.district}
                            text={`${location.district}, ${location.province}, ${location.department}`}
                          >
                            {`${location.district}/${location.province}/${location.department}`}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </Item>
                  <Item label="Dirección">
                    {getFieldDecorator('address', {
                      initialValue: address,
                      rules: [
                        {required: false, message: 'Por favor ingrese el campo solicitado'}
                      ]
                    })(
                      <Input
                        onBlur={handleOnBlurAddress}
                        onKeyPress={e => {
                          if (e.key === 'Enter') {
                            e.preventDefault()
                            handleAddress(e.target.value)
                          }
                        }}
                        placeholder="Ingrese una dirección"
                        onChange={e => setAddress(e.target.value)}
                      />
                    )}
                  </Item>
                  <Item>
                    <GoogleMapConfig
                      loadingElement={<div style={{height: `100%`}}/>}
                      containerElement={
                        <div style={{height: 300, width: "100%"}}/>
                      }
                      mapElement={<div style={{height: `100%`}}/>}
                      center={center}
                      zoom={17}
                      options={options}
                      handleOnClickMap={handleOnClickMap}
                    />
                  </Item>
                  <Item>
                    <small className="gx-mb-2 gx-text-primary">
                      <i className="far fa-info-circle gx-mr-1"/>
                      Esta información es pública para que los usuarios (clientes) ubiquen tu centro de labores. Si no deseas compartir esta información puedes 
                      saltar este paso dando clic en el botón de "Omitir".
                    </small>
                  </Item>
                  <Item className="gx-mb-0">
                    <Button disabled={loading} type="primary" htmlType="submit" form="addressForm" className="gx-mb-0">
                      <i className="far fa-save gx-mr-2"/>
                      Guardar
                    </Button>
                    <Button disabled={loading} htmlType="button" className="gx-mb-0" onClick={skipLaboralStore}>
                      Omitir
                    </Button>
                  </Item>
                </Form>
              </Card>
            )}
            {step === 3 && (
              <Card>
                <h1 className="gx-font-weight-semi-bold">
                  <i className="fal fa-user-tie gx-mr-3"/>
                  Datos Profesionales
                </h1>
                <Form id="professionalForm" onSubmit={professionalForm} layout="vertical" className="gx-mt-4">
                  <Item label="Profesión">
                    {getFieldDecorator('profession', {
                      initialValue: profession,
                      rules: [
                        {required: false, message: 'Por favor ingrese el campo solicitado'}
                      ]
                    })(
                      <Select allowClear loading={loadingMasters} onChange={item => setProfession(item)} placeholder="Seleccione...">
                        {renderEntity({entity: "PROFESION", data: dataMasters})}
                      </Select>
                    )}
                  </Item>
                  <Item label="Especialidad">
                    {getFieldDecorator('specialty', {
                      initialValue: specialty,
                      rules: [
                        {required: getFieldValue('profession'), message: 'Por favor ingrese el campo solicitado'}
                      ]
                    })(
                      <Select allowClear loading={loadingMasters} onChange={item => setSpecialty(item)} placeholder="Seleccione...">
                        {renderEntity({entity: "ESPECIALIDAD", data: dataMasters})}
                      </Select>
                    )}
                  </Item>
                  <Item label="¿Cuántos años de Experiencia tiene?">
                    {getFieldDecorator('years', {
                      initialValue: years,
                      rules: [
                        {required: false, message: 'Por favor ingrese el campo solicitado'}
                      ]
                    })(
                      <InputNumber
                        min={1}
                        max={80}
                        allowClear
                        placeholder="0"
                        style={{width: "100%"}}
                        onChange={value => setYears(value)}
                      />
                    )}
                  </Item>
                  <Item label="Cuéntanos sobre tu experiencia">
                    {getFieldDecorator('resume', {
                      initialValue: resume,
                      rules: [
                        {required: false, message: 'Por favor ingrese el campo solicitado'}
                      ]
                    })(
                      <Input.TextArea
                        allowClear
                        autoSize={{minRows: 6, maxRows: 10}}
                        style={{width: "100%"}}
                        onChange={e => setResume(e.target.value)}
                      />
                    )}
                  </Item>
                  <Item className="gx-mb-0">
                    <Button
                      disabled={loading}
                      type="primary"
                      htmlType="submit"
                      form="professionalForm"
                      className="gx-mb-0"
                    >
                      <i className="far fa-save gx-mr-2"/>
                      Guardar
                    </Button>
                    <Button disabled={loading} htmlType="button" className="gx-mb-0" onClick={skipProfessional}>
                      Omitir
                    </Button>
                    {(!authUser.ellphone || !authUser.phone || !authUser.ubigeo || !authUser.address || !authUser.lat || !authUser.lng || !authUser.specialty || !authUser.experience_resume || !authUser.experience_years) && (
                      <Button disabled={loading || finishing} htmlType="button" className="gx-mb-0 gx-float-right gx-mr-0" onClick={() => finishWizardPanel(true)}>
                        Finalizar
                      </Button>
                    )}
                  </Item>
                </Form>
              </Card>
            )}
            {step === 4 && (
              <Card>
                <h1 className="gx-font-weight-semi-bold">
                  <i className="fal fa-address-card gx-mr-3"/>
                  Datos Personales
                </h1>
                <Form id="generalForm" onSubmit={submitGeneral} layout="vertical" className="gx-mt-4">
                  <Item label="Tipo de Documento">
                    {getFieldDecorator('documentType', {
                      initialValue: documentType,
                      rules: [
                        {required: false, message: 'Por favor ingrese el campo solicitado'}
                      ]
                    })(
                      <Select
                        loading={loadingMasters}
                        placeholder="Seleccione"
                        onChange={value => {
                          setDocumentType(value)
                          setDocument(null)
                        }}
                        allowClear
                      >
                        {renderEntity({
                          entity: "TIPO_DOCUMENTO",
                          data: dataMasters,
                        })}
                      </Select>
                    )}
                  </Item>
                  <Item label="Documento">
                    {getFieldDecorator('document', {
                      initialValue: document,
                      rules: [
                        {required: getFieldValue('documentType'), message: 'Por favor ingrese el campo solicitado'}
                      ]
                    })(
                      <Input allowClear maxLength={15} onChange={e => setDocument(e.target.value)} placeholder="Ingrese el número de documento"/>
                    )}
                  </Item>
                  <Item label="Fecha de Nacimiento">
                    {getFieldDecorator('birthday', {
                      initialValue: birthday,
                      rules: [
                        {required: false, message: 'Por favor ingrese el campo solicitado'}
                      ]
                    })(
                      <DatePicker allowClear onChange={value => setBirthday(value)} className="gx-full-width"/>
                    )}
                  </Item>
                  <Item label="Sexo">
                    {getFieldDecorator('sex', {
                      initialValue: sex,
                      rules: [
                        {required: false, message: 'Por favor ingrese el campo solicitado'}
                      ]
                    })(
                      <Radio.Group buttonStyle="solid">
                        <Radio.Button value="M" onClick={e => handleChangeSex(e.target.value)}>Masculino</Radio.Button>
                        <Radio.Button value="F" onClick={e => handleChangeSex(e.target.value)}>Femenino</Radio.Button>
                      </Radio.Group>
                    )}
                  </Item>
                  <Item>
                    <small className="gx-mb-2 gx-text-primary">
                      <i className="far fa-info-circle gx-mr-1"/>
                      Esta información no se comparte en tu información de perfil.
                    </small>
                  </Item>
                  <Item className="gx-mb-0">
                    <Button disabled={loading} type="primary" htmlType="submit" form="generalForm" className="gx-mb-0">
                      <i className="far fa-save gx-mr-2"/>
                      Guardar
                    </Button>
                    <Button disabled={loading} htmlType="button" className="gx-mb-0" onClick={skip}>
                      Omitir
                    </Button>
                    <Button disabled={loading || finishing} htmlType="button" className="gx-mb-0 gx-float-right gx-mr-0" onClick={() => finishWizardPanel(true)}>
                      Finalizar
                    </Button>
                  </Item>
                </Form>
              </Card>
            )}
            {step === 5 && (
              <Result
                icon={<i className="far fa-smile-wink fa-6x gx-text-blue"/>}
                title="¡Genial! Hemos finalizado."
                extra={(
                  <div>
                    <Link to="/">
                      <Button type="primary">
                        <i className="far fa-home gx-mr-2"/>
                        Ir a inicio
                      </Button>
                    </Link>
                    <Link to="/usuario/proyectos" className={"gx-ml-2"}>
                      <Button type="primary">
                        <i className="far fa-plus gx-mr-2"/>
                        Crear Proyecto
                      </Button>
                    </Link>
                  </div>
                )}
              />
            )}
          </Spin>
        </Col>
      </Row>
    </Fragment>
  )
}

export default Form.create()(Joined)
