import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, Row, Col, Table } from "antd";
import withSearchOnTable from "HOC/withSearchOnTable";
import { toggleModalPerforacionD25 } from "appRedux/actions";
const columns = [
  {
    title: "Perforación",
    dataIndex: "sPerforacion",
    key: "sPerforacion",
    align: "center"
  },
  {
    title: "Cant.",
    dataIndex: "nCantidad",
    key: "nCantidad",
    align: "center"
  },
  {
    title: "Dist.Borde Lateral",
    dataIndex: "nDistBordeLateral",
    key: "nDistBordeLateral",
    align: "center"
  }
];

class PerforationD25 extends Component {
  constructor(props) {
    super(props);
    this.state = { dataSource: [] };
    this.refSearch = React.createRef();
  }
  componentDidUpdate(prevProps) {
    const { modalPerforationD25, listPerforationD25 } = this.props;
    if (prevProps.modalPerforationD25 !== modalPerforationD25) {
      this.refSearch.current && this.refSearch.current.focus();

      if (modalPerforationD25.visible && this.state.dataSource.length === 0) {
        setTimeout(() => {
          this.setState({
            dataSource:
              listPerforationD25.length > 0
                ? listPerforationD25.slice(0, 10)
                : []
          });
        }, 500);
      }
    }
  }

  closeModalPerforationD25 = () => {
    this.props.toggleModalPerforacionD25({ visible: false, info: null });
  };

  render() {
    const {
      // REDUX
      listPerforationD25,
      fetchingPerforationD25,
      modalPerforationD25,
      // HOC
      resultSearch,
      onSearchNow,
      getInputSearchProps,
      handleOnSelectPerforationD25
    } = this.props;

    return (
      <Modal
        visible={modalPerforationD25 && modalPerforationD25.visible}
        onCancel={this.closeModalPerforationD25}
        title="Perforación D-25"
        footer={null}
      >
        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={24}>
            {getInputSearchProps(
              "sPerforacion",
              listPerforationD25,
              "Buscar Perforación",
              this.refSearch,
              "default"
            )}
          </Col>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Table
              dataSource={
                resultSearch.length > 0 || onSearchNow === true
                  ? resultSearch
                  : this.state.dataSource
              }
              loading={fetchingPerforationD25}
              columns={columns}
              pagination={false}
              bordered={true}
              rowKey={row => row.sPerforacion}
              onRow={(row, index) => {
                return {
                  onDoubleClick: () => {
                    handleOnSelectPerforationD25(
                      row,
                      index,
                      modalPerforationD25
                    );
                  }
                };
              }}
              size={"middle"}
              scroll={{ y: "calc(100vh - 360px)" }}
              rowClassName="gx-pointer"
              title={() => (
                <span>
                  <i className="fad fa-mouse gx-pr-1 gx-text-color-primary" />
                  Doble click para seleccionar una Perforación D-25.
                </span>
              )}
            />
          </Col>
        </Row>
      </Modal>
    );
  }
}

const mapStateToProps = ({ projects }) => {
  const {
    listPerforationD25,
    fetchingPerforationD25,
    modalPerforationD25
  } = projects;
  return { listPerforationD25, fetchingPerforationD25, modalPerforationD25 };
};

const mapDispatchToProps = { toggleModalPerforacionD25 };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSearchOnTable(PerforationD25));
