import gql from 'graphql-tag';

export const CREATE_SERVICE = gql`
  mutation createService($input: CreateServiceInput!) {
    createService(input: $input) {
      message
      status
      error
    }
  }
`;
