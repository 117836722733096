import React, { useEffect, useState, useCallback } from "react";
import { Result, Icon, Button, Spin, message } from "antd";
import { useMutation } from "@apollo/react-hooks";
import { openNotification, onErrorMessage } from "util/helpers";
import { VERIFY_EMAIL, RESEND_VERIFY_EMAIL } from "graphql/mutation/auth";
import { useLocation } from "react-router-dom";
import Seo from "../../components/seo";

let c = 0;
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
const EmailVerify = (props) => {
  const query = useQuery();
  const [verify, setVerify] = useState(false);
  const [sentEmail, setSentEmail] = useState(false);
  const [userMail, setUserMail] = useState(null);

  const [verifyEmail, { loading }] = useMutation(VERIFY_EMAIL, {
    onCompleted({ verifyEmail }) {
      if (verifyEmail.status === "EXPIRED" && verifyEmail.email !== null) {
        setUserMail(verifyEmail.email);
      } else {
        setVerify(true);
        handleUserRegisterSuccess();
      }
    },
    onError(error) {
      onErrorMessage(error);
    },
  });

  const [resendEmail, { loading: sending }] = useMutation(RESEND_VERIFY_EMAIL, {
    onCompleted({ resendVerifyEmail }) {
      message.success(resendVerifyEmail.message);
      setSentEmail(true);
    },
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ extensions }) => {
          message.warn(extensions.reason);
        });
      }
      if (networkError) {
        message.error(`[Error de Red]: ${networkError}`);
      }
    },
  });

  const handleUserRegisterSuccess = () => {
    setUserMail(null);
    openNotification({
      message: "Tu cuenta ha sido verificada",
      description: "Ahora puedes iniciar sesión en eCarpintero",
      type: "success",
    });
    props.history.push("/login");
  };

  const handleOnVerifyAcount = useCallback(
    (token) => {
      verifyEmail({
        variables: { token },
      });
    },
    [verifyEmail]
  );

  useEffect(() => {
    if (props && props.match) {
      const queryToken = query.get("token");
      c++;
      if (queryToken && c === 1) {
        handleOnVerifyAcount(queryToken);
      }
    }
  }, [props, query, handleOnVerifyAcount]);

  const handleSendMail = () => {
    // console.log("test");
    resendEmail({
      variables: {
        email: userMail,
      },
    });
  };

  const maskMail = (emailID = "") => {
    const maskid = emailID.replace(/^(.)(.*)(.@.*)$/, (_, a, b, c) => {
      return a + b.replace(/./g, "*") + c;
    });
    return maskid;
  };

  return (
    <Spin spinning={loading}>
      <Seo
        title="Verificar cuenta"
        url={window.location.href}
        description={`Verifica tu cuenta en eCarpintero`}
        keywords="activar,cuenta"
      />
      {loading ? (
        <Result
          icon={<Icon type="unlock" theme="twoTone" twoToneColor="#0aa9ff" />}
          title="Verificando cuenta..."
        />
      ) : verify ? (
        <Result
          icon={<Icon type="lock" theme="twoTone" twoToneColor="#52c41a" />}
          title="Tu cuenta ha sido verificada, ahora puedes iniciar sesión en eCarpintero."
          extra={
            <Button
              type="primary"
              onClick={() => {
                props.history.push("/login");
              }}
            >
              Iniciar Sesión
            </Button>
          }
        />
      ) : (
        <Result
          icon={
            <Icon type="close-circle" theme="twoTone" twoToneColor="#FF0000" />
          }
          title="No se pudo verificar la cuenta porque el enlace es inválido o ha expirado."
          subTitle={
            <>
              {sentEmail ? (
                <p className="gx-mb-3 gx-mt-3">
                  Hemos enviado el enlace de verificación a tu corre
                  electrónico, por favor revise y verifique su cuenta.
                </p>
              ) : (
                <>
                  Puede solicitar un nuevo enlace de activación, haciendo click
                  en el botón.
                </>
              )}
            </>
          }
          extra={
            sentEmail ? null : (
              <Button loading={sending} onClick={handleSendMail} type="primary">
                Reenviar enlace a {userMail && maskMail(userMail)}
              </Button>
            )
          }
        />
      )}
    </Spin>
  );
};

export default EmailVerify;
