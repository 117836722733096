import React, { Fragment, useState } from 'react'
import { Tooltip } from 'antd'
import './erp.css'
import WizardQuotation from './wizard_quotation'
import CardPlan from './card_plan'

const checked = <i className="far fa-check gx-text-success" />
const optional = <i className="far fa-check gx-text-warning" />
const notChecked = <i className="far fa-times gx-text-danger" />

const ERP = () => {
  const [visible, setVisible] = useState(false)
  const [plan, setPlan] = useState(false)

  const selectPlan = plan => {
    setPlan(plan)
    setVisible(true)
  }

  const generalInfo = { title: 'General', icon: 'info' }
  const salesInfo = { title: 'Ventas', icon: 'cash-register' }
  const inventoryInfo = { title: 'Inventarios', icon: 'warehouse' }
  const purchaseInfo = { title: 'Compras', icon: 'coins' }
  const bankInfo = { title: 'Bancos', icon: 'landmark' }
  const productionInfo = { title: 'Producción', icon: 'industry' }
  const infraestructureInfo = { title: 'Infraestructura', icon: 'shapes' }
  const supportInfo = { title: 'Soporte', icon: 'life-ring' }

  const plans = [
    {
      plan: 'basic',
      title: 'Básico',
      color: '#039BE5',
      icon: 'pencil-ruler',
      items: [
        {
          ...generalInfo,
          concepts: [
            { title: 'Sucursales', value: '1' },
            { title: 'Usuarios', value: '5' },
            { title: 'Optimizaciones por mes', value: '300' },
            { title: 'Piezas por proyecto', value: '300' },
          ]
        },
        {
          ...salesInfo,
          concepts: [
            { title: 'Proyectos', value: checked },
            { title: 'Optimización', value: checked },
            { title: 'Pedidos', value: checked },
            { title: 'Facturas / Devoluciones', value: checked },
            { title: 'Facturación electrónica', value: '300' },
            { title: 'Cobranza', value: checked },
            { title: 'Apertura / Cierra de caja', value: checked },
            { title: 'Libro de reclamaciones', value: optional },
            { title: 'Comisiones de vendedores', value: notChecked },
          ]
        },
        {
          ...inventoryInfo,
          concepts: [
            { title: 'Ingreso / Salida de mercancía', value: checked },
            { title: 'Kits', value: checked },
            { title: 'Despacho de pedidos', value: notChecked },
            { title: 'Transferencia entre almacenes', value: notChecked },
            { title: 'Reabastecimiento', value: notChecked },
          ]
        },
        {
          ...purchaseInfo,
          concepts: [
            { title: 'Órdenes de compra', value: checked },
            { title: 'Recepción de compras', value: checked },
            { title: 'Liquidaciones de importación', value: optional },
            { title: 'Registro de documentos por pagar', value: checked },
          ]
        },
        {
          ...bankInfo,
          concepts: [
            { title: 'Pago a proveedores', value: checked },
            { title: 'Registro de operaciones auxiliares', value: checked },
            { title: 'Importación de extractos bancarios', value: notChecked },
            { title: 'Conciliación', value: notChecked },
          ]
        },
        {
          ...productionInfo,
          concepts: [
            { title: 'Planificación', value: notChecked },
            { title: 'Control', value: notChecked },
            { title: 'indicadores de cumplimiento', value: notChecked },
            { title: 'Interfaz seccionadora', value: notChecked },
          ]
        },
        {
          ...infraestructureInfo,
          concepts: [
            { title: 'Base de datos dedicada', value: checked },
            { title: 'Almacenamiento', value: '2GB' },
          ]
        },
        {
          ...supportInfo,
          concepts: [
            { title: 'Whatsapp / Correo / Acceso remoto', value: checked },
            { title: 'Capacitaciones remotas', value: '1 x año' },
            { title: 'Copias de Seguridad - Base de datos', value: checked },
          ]
        }
      ]
    },
    {
      plan: 'standard',
      title: 'Estándar',
      color: '#2E3A4D',
      icon: 'hammer',
      items: [
        {
          ...generalInfo,
          concepts: [
            { title: 'Sucursales', value: '2' },
            { title: 'Usuarios', value: '10' },
            { title: 'Optimizaciones por mes', value: '700' },
            { title: 'Piezas por proyecto', value: '2000' },
          ]
        },
        {
          ...salesInfo,
          concepts: [
            { title: 'Proyectos', value: checked },
            { title: 'Optimización', value: checked },
            { title: 'Pedidos', value: checked },
            { title: 'Facturas / Devoluciones', value: checked },
            { title: 'Facturación electrónica', value: '1000' },
            { title: 'Cobranza', value: checked },
            { title: 'Apertura / Cierra de caja', value: checked },
            { title: 'Libro de reclamaciones', value: checked },
            { title: 'Comisiones de vendedores', value: optional },
          ]
        },
        {
          ...inventoryInfo,
          concepts: [
            { title: 'Ingreso / Salida de mercancía', value: checked },
            { title: 'Kits', value: checked },
            { title: 'Despacho de pedidos', value: checked },
            { title: 'Transferencia entre almacenes', value: checked },
            { title: 'Reabastecimiento', value: optional },
          ]
        },
        {
          ...purchaseInfo,
          concepts: [
            { title: 'Órdenes de compra', value: checked },
            { title: 'Recepción de compras', value: checked },
            { title: 'Liquidaciones de importación', value: checked },
            { title: 'Registro de documentos por pagar', value: checked },
          ]
        },
        {
          ...bankInfo,
          concepts: [
            { title: 'Pago a proveedores', value: checked },
            { title: 'Registro de operaciones auxiliares', value: checked },
            { title: 'Importación de extractos bancarios', value: checked },
            { title: 'Conciliación', value: checked },
          ]
        },
        {
          ...productionInfo,
          concepts: [
            { title: 'Planificación', value: optional },
            { title: 'Control', value: optional },
            { title: 'indicadores de cumplimiento', value: optional },
            { title: 'Interfaz seccionadora', value: optional },
          ]
        },
        {
          ...infraestructureInfo,
          concepts: [
            { title: 'Base de datos dedicada', value: checked },
            { title: 'Almacenamiento', value: '10GB' },
          ]
        },
        {
          ...supportInfo,
          concepts: [
            { title: 'Whatsapp / Correo / Acceso remoto', value: checked },
            { title: 'Capacitaciones remotas', value: '2 x año' },
            { title: 'Copias de Seguridad - Base de datos', value: checked },
          ]
        }
      ]
    },
    {
      plan: 'advanced',
      title: 'Avanzado',
      color: '#C26E2D',
      icon: 'galaxy',
      items: [
        {
          ...generalInfo,
          concepts: [
            { title: 'Sucursales', value: 'Ilimitado' },
            { title: 'Usuarios', value: 'Ilimitado' },
            { title: 'Optimizaciones por mes', value: 'Ilimitado' },
            { title: 'Piezas por proyecto', value: 'Ilimitado' },
          ]
        },
        {
          ...salesInfo,
          concepts: [
            { title: 'Proyectos', value: checked },
            { title: 'Optimización', value: checked },
            { title: 'Pedidos', value: checked },
            { title: 'Facturas / Devoluciones', value: checked },
            { title: 'Facturación electrónica', value: 'Ilimitado' },
            { title: 'Cobranza', value: checked },
            { title: 'Apertura / Cierra de caja', value: checked },
            { title: 'Libro de reclamaciones', value: checked },
            { title: 'Comisiones de vendedores', value: checked },
          ]
        },
        {
          ...inventoryInfo,
          concepts: [
            { title: 'Ingreso / Salida de mercancía', value: checked },
            { title: 'Kits', value: checked },
            { title: 'Despacho de pedidos', value: checked },
            { title: 'Transferencia entre almacenes', value: checked },
            { title: 'Reabastecimiento', value: checked },
          ]
        },
        {
          ...purchaseInfo,
          concepts: [
            { title: 'Órdenes de compra', value: checked },
            { title: 'Recepción de compras', value: checked },
            { title: 'Liquidaciones de importación', value: checked },
            { title: 'Registro de documentos por pagar', value: checked },
          ]
        },
        {
          ...bankInfo,
          concepts: [
            { title: 'Pago a proveedores', value: checked },
            { title: 'Registro de operaciones auxiliares', value: checked },
            { title: 'Importación de extractos bancarios', value: checked },
            { title: 'Conciliación', value: checked },
          ]
        },
        {
          ...productionInfo,
          concepts: [
            { title: 'Planificación', value: checked },
            { title: 'Control', value: checked },
            { title: 'indicadores de cumplimiento', value: checked },
            { title: 'Interfaz seccionadora', value: checked },
          ]
        },
        {
          ...infraestructureInfo,
          concepts: [
            { title: 'Base de datos dedicada', value: checked },
            { title: 'Almacenamiento', value: '250GB' },
          ]
        },
        {
          ...supportInfo,
          concepts: [
            { title: 'Whatsapp / Correo / Acceso remoto', value: checked },
            { title: 'Capacitaciones remotas', value: 'A coordinar' },
            { title: 'Copias de Seguridad (BD)', value: checked },
          ]
        }
      ]
    }
  ]

  return (
    <Fragment>
      <div className="banner-erp" >
        <span className="titulo-erp">eCarpintero ERP</span>
      </div>

      <div className="content-erp">
        <h1 className="title-erp">
          Seleccione el plan que más te interese.
        </h1>

        <div className="gx-mt-2 gx-mb-4 plans-card gx-mx-2 gx-mx-sm-4 gx-mx-md-4 gx-mx-lg-auto">
          <div className="plans-card-content gx-p-3">
            <table className="plans-table gx-d-none gx-d-lg-block">
              <thead className="plans-table-header">
                <tr>
                  <th className="header-cell header-cell-features">
                    <span className="action-plan-text">Características</span>
                  </th>
                  <th className="header-cell header-cell-basic">
                    <div><i className="fad fa-pencil-ruler gx-mb-3 gx-fs-xxxl gx-text-white" /></div>
                    <span className="action-plan-text">Básico</span>
                    <button className="contact-button gx-mb-1" onClick={() => selectPlan('basic')}>
                      Cotizar
                    </button>
                  </th>
                  <th className="header-cell header-cell-standard">
                    <div><i className="fad fa-hammer gx-mb-3 gx-fs-xxxl gx-text-white" /></div>
                    <span className="action-plan-text">Estándar</span>
                    <button className="contact-button gx-mb-1" onClick={() => selectPlan('standard')}>
                      Cotizar
                    </button>
                  </th>
                  <th className="header-cell header-cell-advanced">
                    <div><i className="fad fa-galaxy gx-mb-3 gx-fs-xxxl gx-text-white" /></div>
                    <span className="action-plan-text">Avanzado</span>
                    <button className="contact-button gx-mb-1" onClick={() => selectPlan('advanced')}>
                      Cotizar
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody className="plans-table-tbody">
                <tr>
                  <td className="body-cell-features">Sucursales</td>
                  <td>1</td>
                  <td>2</td>
                  <td>Ilimitado</td>
                </tr>
                <tr>
                  <td className="body-cell-features">Usuarios</td>
                  <td>5</td>
                  <td>10</td>
                  <td>Ilimitado</td>
                </tr>
                <tr>
                  <td className="body-cell-features">
                    Optimizaciones
                    <ul>
                      <li>Mensual</li>
                    </ul>
                  </td>
                  <td className="body-cell-with-ul">
                    <ul>
                      <li>300</li>
                    </ul>
                  </td>
                  <td className="body-cell-with-ul">
                    <ul>
                      <li>700</li>
                    </ul>
                  </td>
                  <td className="body-cell-with-ul">
                    <ul>
                      <li>Ilimitado</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td className="body-cell-features">Piezas por proyecto</td>
                  <td>300</td>
                  <td>2000</td>
                  <td>Ilimitado</td>
                </tr>
                <tr>
                  <td className="body-cell-features">
                    Ventas
                    <ul>
                      <li>Proyectos</li>
                      <li>Optimización</li>
                      <li>Pedidos</li>
                      <li>Facturas</li>
                      <li>Devoluciones</li>
                      <li>Facturación Electrónica</li>
                      <li>Cobranza</li>
                      <li>Apertura/Cierre de Caja</li>
                      <li>Libro de reclamaciones</li>
                      <li>Comisiones de vendedores</li>
                    </ul>
                  </td>
                  <td className="body-cell-with-ul">
                    <ul>
                      <li>{checked}</li>
                      <li>{checked}</li>
                      <li>{checked}</li>
                      <li>{checked}</li>
                      <li>{checked}</li>
                      <li>300</li>
                      <li>{checked}</li>
                      <li>{checked}</li>
                      <li>{optional}</li>
                      <li>{notChecked}</li>
                    </ul>
                  </td>
                  <td className="body-cell-with-ul">
                    <ul>
                      <li>{checked}</li>
                      <li>{checked}</li>
                      <li>{checked}</li>
                      <li>{checked}</li>
                      <li>{checked}</li>
                      <li>1000</li>
                      <li>{checked}</li>
                      <li>{checked}</li>
                      <li>{checked}</li>
                      <li>{optional}</li>
                    </ul>
                  </td>
                  <td className="body-cell-with-ul">
                    <ul>
                      <li>{checked}</li>
                      <li>{checked}</li>
                      <li>{checked}</li>
                      <li>{checked}</li>
                      <li>{checked}</li>
                      <li>Ilimitado</li>
                      <li>{checked}</li>
                      <li>{checked}</li>
                      <li>{checked}</li>
                      <li>{checked}</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td className="body-cell-features">
                    Inventarios
                    <ul>
                      <li>Ingreso/Salida Mercandería</li>
                      <li>Kits</li>
                      <li>Depacho de pedidos</li>
                      <li>Transferencias entre almacenes</li>
                      <li>Reabastecimiento</li>
                    </ul>
                  </td>
                  <td className="body-cell-with-ul">
                    <ul>
                      <li>{checked}</li>
                      <li>{checked}</li>
                      <li>{notChecked}</li>
                      <li>{notChecked}</li>
                      <li>{notChecked}</li>
                    </ul>
                  </td>
                  <td className="body-cell-with-ul">
                    <ul>
                      <li>{checked}</li>
                      <li>{checked}</li>
                      <li>{checked}</li>
                      <li>{checked}</li>
                      <li>{optional}</li>
                    </ul>
                  </td>
                  <td className="body-cell-with-ul">
                    <ul>
                      <li>{checked}</li>
                      <li>{checked}</li>
                      <li>{checked}</li>
                      <li>{checked}</li>
                      <li>{checked}</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td className="body-cell-features">
                    Compras
                    <ul>
                      <li>Órdenes de compras</li>
                      <li>Recepción de compras</li>
                      <li>Liquidaciones de importación</li>
                      <li>Registro de documentos por pagar</li>
                    </ul>
                  </td>
                  <td className="body-cell-with-ul">
                    <ul>
                      <li>{checked}</li>
                      <li>{checked}</li>
                      <li>{optional}</li>
                      <li>{checked}</li>
                    </ul>
                  </td>
                  <td className="body-cell-with-ul">
                    <ul>
                      <li>{checked}</li>
                      <li>{checked}</li>
                      <li>{checked}</li>
                      <li>{checked}</li>
                    </ul>
                  </td>
                  <td className="body-cell-with-ul">
                    <ul>
                      <li>{checked}</li>
                      <li>{checked}</li>
                      <li>{checked}</li>
                      <li>{checked}</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td className="body-cell-features">
                    Bancos
                    <ul>
                      <li>Pago a proveedores</li>
                      <li>Registro de operaciones auxiliares</li>
                      <li>Importación de extractos bancarios</li>
                      <li>Conciliación</li>
                    </ul>
                  </td>
                  <td className="body-cell-with-ul">
                    <ul>
                      <li>{checked}</li>
                      <li>{checked}</li>
                      <li>{notChecked}</li>
                      <li>{notChecked}</li>
                    </ul>
                  </td>
                  <td className="body-cell-with-ul">
                    <ul>
                      <li>{checked}</li>
                      <li>{checked}</li>
                      <li>{checked}</li>
                      <li>{checked}</li>
                    </ul>
                  </td>
                  <td className="body-cell-with-ul">
                    <ul>
                      <li>{checked}</li>
                      <li>{checked}</li>
                      <li>{checked}</li>
                      <li>{checked}</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td className="body-cell-features">
                    Producción
                    <ul>
                      <li>Planificación</li>
                      <li>Control</li>
                      <li>Indicadores de cumplimiento</li>
                      <li>Interfaz de seccionadora</li>
                    </ul>
                  </td>
                  <td className="body-cell-with-ul">
                    <ul>
                      <li>{notChecked}</li>
                      <li>{notChecked}</li>
                      <li>{notChecked}</li>
                      <li>{notChecked}</li>
                    </ul>
                  </td>
                  <td className="body-cell-with-ul">
                    <ul>
                      <li>{optional}</li>
                      <li>{optional}</li>
                      <li>{optional}</li>
                      <li>{optional}</li>
                    </ul>
                  </td>
                  <td className="body-cell-with-ul">
                    <ul>
                      <li>{checked}</li>
                      <li>{checked}</li>
                      <li>{checked}</li>
                      <li>{checked}</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td className="body-cell-features">
                    Infraestructura
                    <ul>
                      <li>Base de datos dedicada</li>
                      <li>Almacenamiento</li>
                    </ul>
                  </td>
                  <td className="body-cell-with-ul">
                    <ul>
                      <li>{checked}</li>
                      <li>2 GB</li>
                    </ul>
                  </td>
                  <td className="body-cell-with-ul">
                    <ul>
                      <li>{checked}</li>
                      <li>10 GB</li>
                    </ul>
                  </td>
                  <td className="body-cell-with-ul">
                    <ul>
                      <li>{checked}</li>
                      <li>250 GB</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td className="body-cell-features">
                    Soporte
                    <ul>
                      <li>Whatsapp / Correo / Acceso remoto</li>
                      <li>Capacitaciones remotas por año</li>
                      <li>Copias de seguridad (Base de datos)</li>
                    </ul>
                  </td>
                  <td className="body-cell-with-ul">
                    <ul>
                      <li>{checked}</li>
                      <li>1</li>
                      <li>{checked}</li>
                    </ul>
                  </td>
                  <td className="body-cell-with-ul">
                    <ul>
                      <li>{checked}</li>
                      <li>2</li>
                      <li>{checked}</li>
                    </ul>
                  </td>
                  <td className="body-cell-with-ul">
                    <ul>
                      <li>{checked}</li>
                      <li>
                        <Tooltip title="Cantidad a coordinar">
                          <i className="fa fa-question-circle gx-text-muted gx-pointer" />
                        </Tooltip>
                      </li>
                      <li>{checked}</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td className="body-cell-features">
                    <span className="action-plan-text">Escoge un plan</span>
                    <div className="gx-mt-2 font-legend-plans">
                      <small>* Los precios no incluyen IGV</small>
                    </div>
                    <div className="gx-mt-4 font-legend-plans">
                      Leyenda
                      <div className="gx-mt-3 gx-d-flex" style={{ width: '100%' }}>
                        <div className="legend-plans include">
                          {checked}
                          <div />
                          Incluido
                        </div>
                        <div className="legend-plans not-include">
                          {notChecked}
                          <div />
                          No incluido
                        </div>
                        <div className="legend-plans optional">
                          {optional}
                          <div />
                          Opcional <small className="gx-text-muted">Costo adicional</small>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="footer-cell-basic">
                    <span className="action-plan-text">Básico</span>
                    <button className="contact-button" onClick={() => selectPlan('basic')}>
                      Cotizar
                    </button>
                  </td>
                  <td className="footer-cell-standard">
                    <span className="action-plan-text">Estándar</span>
                    <button className="contact-button" onClick={() => selectPlan('standard')}>
                      Cotizar
                    </button>
                  </td>
                  <td className="footer-cell-advanced">
                    <span className="action-plan-text">Avanzado</span>
                    <button className="contact-button" onClick={() => selectPlan('advanced')}>
                      Cotizar
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="gx-d-block gx-d-lg-none">
              {plans.map((plan, index) => <CardPlan selectPlan={selectPlan} key={index} plan={plan} />)}
              <div className="gx-mt-3 font-legend-plans">
                <small>* Los precios no incluyen IGV</small>
              </div>
              <div className="gx-mt-3 font-legend-plans">
                Leyenda
                <div className="gx-mt-3 gx-d-flex" style={{ width: '100%' }}>
                  <div className="legend-plans include">
                    {checked}
                    <div />
                    Incluido
                  </div>
                  <div className="legend-plans not-include">
                    {notChecked}
                    <div />
                    No incluido
                  </div>
                  <div className="legend-plans optional">
                    {optional}
                    <div />
                    Opcional <small className="gx-text-muted">Costo adicional</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <WizardQuotation
        setVisible={setVisible}
        setPlan={setPlan}
        visible={visible}
        plan={plan}
      />
    </Fragment>
  )
}

export default ERP
