import axios from "util/Api";
import { message } from 'antd'
import cryptex from "node-cryptex";
import { k, v } from "util/config";
import { showErrorInApi } from "util/helpers";

import {
  FETCH_CLIENTS,
  FETCH_START,
  FETCH_SUCCESS,
  FETCH_ERROR,
  FETCH_SEARCH_PATIENT_START,
  FETCH_SEARCH_PATIENT_SUCCESS,
  FETCH_SEARCH_PATIENT_ERROR,
  FETCH_START_CLIENT,
  FETCH_SUCCESS_CLIENT,
  FETCH_ERROR_CLIENT,
  START_SAVING_CLIENTS,
  // UPDATE_CLIENTS,
  SUCCESS_SAVING_CLIENTS,
  // SAVE_CLIENTS,
  ERROR_SAVING_CLIENTS,
  OBTENER_INFORMACION_ADICONAL_USUARIO_START,
  OBTENER_INFORMACION_ADICONAL_USUARIO_SUCCESS,
  OBTENER_INFORMACION_ADICONAL_USUARIO_ERROR,
  ERROR_GET_INFO_CLIENT,
  START_GET_INFO_CLIENT,
  SUCCESS_GET_INFO_CLIENT,
  CLEAR_RETRIEVE_INFO_CLIENTE,
  UPDATE_SELECTED_CLIENTE
} from "../../constants/ActionTypes";

let timerCount = 0;

export const getClients = ({ nIdUsuario, sBusqueda }) => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    const info = new FormData();
    nIdUsuario && info.set("nIdUsuario", nIdUsuario);
    sBusqueda && info.set("sBusqueda", sBusqueda);
    axios
      .post("/system/listar-clientes", info)
      .then(({ data }) => {
        if (data.state === "Successful") {
          const decryptedRecords = JSON.parse(
            cryptex.decrypt(data.records, k, v)
          );
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: FETCH_CLIENTS, payload: decryptedRecords });
        }
      })
      .catch(error => {
        showErrorInApi(error);
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const searchClients = (nIdUsuario, sBusqueda) => {
  return dispatch => {
    dispatch({ type: FETCH_SEARCH_PATIENT_START });
    clearInterval(timerCount);
    const INFO = new FormData();
    INFO.append("nIdUsuario", nIdUsuario);
    INFO.append("sBusqueda", sBusqueda);
    timerCount = setTimeout(() => {
      axios
        .post(`/system/listar-clientes`, INFO)
        .then(({ data }) => {
          if (data.state === "Successful") {
            const decryptedRecords = data.records
              ? JSON.parse(cryptex.decrypt(data.records, k, v))
              : [];
            dispatch({
              type: FETCH_SEARCH_PATIENT_SUCCESS,
              payload: decryptedRecords
            });
          }
        })
        .catch(error => {
          /* const responseError = JSON.parse(error.request.response) || undefined;
          const messageError = responseError
            ? responseError.originalError.info.message
            : error.message; */
          message.error('Ha ocurrido un error al consultar clientes');
          dispatch({
            type: FETCH_SEARCH_PATIENT_ERROR,
            payload: error.message
          });
        });
    }, 500);
  };
};

export const getRetrieveClient = nId => {
  return dispatch => {
    dispatch({ type: FETCH_START_CLIENT });
    axios
      .get(`/system/client/${nId}`)
      .then(({ data }) => {
        if (data.state === "Successful") {
          const decryptedRecords = JSON.parse(
            cryptex.decrypt(data.record, k, v)
          );
          dispatch({ type: FETCH_SUCCESS_CLIENT, payload: decryptedRecords });
        }
      })
      .catch(error => {
        showErrorInApi(error);
        dispatch({ type: FETCH_ERROR_CLIENT, payload: error.message });
      });
  };
};

export const saveClients = ({ generalData, form, id = null, callBack = null }) => {
  return dispatch => {
    dispatch({ type: START_SAVING_CLIENTS });
    const {
      dNacimiento,
      sCorreo,
      sDireccion,
      nUbigeo,
      sDocumento,
      sSexo,
      sNombres,
      sProfesion,
      sTelefono1,
      nIdTipoDoc,
      nIdUsuario
    } = generalData;

    const INFO = new FormData();
    if (sNombres) INFO.set("sNombres", sNombres);
    if (dNacimiento) INFO.set("dNacimiento", dNacimiento);
    if (sCorreo) INFO.set("sEmail", sCorreo);
    if (sDireccion) INFO.set("sDireccion", sDireccion);
    if (nUbigeo) INFO.set("nUbigeo", nUbigeo);
    if (sDocumento) INFO.set("sDocumento", sDocumento);
    if (sSexo) INFO.set("sSexo", sSexo);
    if (sProfesion) INFO.set("sProfesion", sProfesion);
    if (sTelefono1) INFO.set("sTelefono1", sTelefono1);
    if (nIdTipoDoc) INFO.set("nIdTipoDoc", nIdTipoDoc);
    if (nIdUsuario) INFO.set("nIdUsuario", nIdUsuario);
    if (id) INFO.set('nIdCliente', id);

    axios.post(`/system/client`, INFO)
      .then(({ data }) => {
        if (data.state === "Successful") {
          const objectClient = {
            nId: data.nIdCliente,
            sDireccionFiscal: sDireccion,
            sEmail: sCorreo,
            sNombres: sNombres,
            sTelefono1: sTelefono1
          };
          if (id) {
            dispatch({ type: SUCCESS_SAVING_CLIENTS, payload: objectClient });
            dispatch({
              type: UPDATE_SELECTED_CLIENTE,
              payload: objectClient
            });
            message.success('Cliente actualizado correctamente');
          } else {
            dispatch({ type: SUCCESS_SAVING_CLIENTS, payload: [objectClient] });
            form.resetFields();
            message.success('Cliente registrado correctamente');
          }
          callBack(data.nIdCliente, sNombres);
        }
      })
      .catch(error => {
        message.error('Ha ocurrido un error al registrar cliente')
        dispatch({ type: ERROR_SAVING_CLIENTS, payload: error.message });
      });
  };
};

export const rxSelectProyetoCliente = nIdCliente => {
  return async dispatch => {
    try {
      dispatch({ type: OBTENER_INFORMACION_ADICONAL_USUARIO_START });
      const url = `/system/projects/client/info/${nIdCliente}`;
      const response = await axios.get(url);
      const data = await response.data;
      if (data && data.record) {
        dispatch({
          type: OBTENER_INFORMACION_ADICONAL_USUARIO_SUCCESS,
          payload: JSON.parse(cryptex.decrypt(data.record, k, v))
        });
      }
    } catch (error) {
      message.error('Ha ocurrido un error al obtener la información del cliente');
      dispatch({
        type: OBTENER_INFORMACION_ADICONAL_USUARIO_ERROR
      });
    }
  };
};

export const retrieveClientAction = nIdClient => {
  return async dispatch => {
    try {
      dispatch({type: START_GET_INFO_CLIENT});
      const res = await axios.get(`/system/client/${nIdClient}`);
      const data = await res.data;
      const decryptedData = JSON.parse(cryptex.decrypt(data.record, k, v));
      dispatch({type: SUCCESS_GET_INFO_CLIENT, payload: decryptedData});
    } catch (error) {
      // console.log(error)
      message.error('Ha ocurrido un error al obtener la información del cliente');
      dispatch({type: ERROR_GET_INFO_CLIENT});
    }
  };
};

export const clearRetrieveInfoClient = () => ({
  type: CLEAR_RETRIEVE_INFO_CLIENTE
});