import React, { useEffect } from "react";
import { Collapse, Row, Col } from "antd";
import { topPage } from "../../util/helpers";
import Seo from "../../components/seo";

const { Panel } = Collapse;

const TermsAndConditions = () => {
  useEffect(() => topPage());

  return (
    <Row gutter={16} className="gx-mb-4">
      <Seo
        title="Términos y Condiciones"
        url={window.location.href}
        description={`eCarpintero("eCarpintero", "nuestro", "nosotros" o "nuestros") es
        una marca comercial de AmazonTIC, ofrece servicio de publicación
        de proyectos, ubicación de carpinteros o especialistas, y otras
        herramientas que pueden ser de paga alrededor deAmérica. Por favor
        lee nuestros Términos del servicio para entender las condiciones
        del uso de eCarpintero. Aceptas nuestros Términos del servicio
        ("Términos") al instalar, acceder o usar nuestras aplicaciones,
        servicios, funciones, software o sitio web (en conjunto,
        "Servicios").`}
        keywords="Términos y Condiciones"
      />
      <Col span={24}>
        <Row gutter={16} className="gx-justify-content-center">
          <Col md={12}>
            <h2>Términos y Condiciones</h2>
            <p className="gx-text-justify">
              eCarpintero("eCarpintero", "nuestro", "nosotros" o "nuestros") es
              una marca comercial de AmazonTIC, ofrece servicio de publicación
              de proyectos, ubicación de carpinteros o especialistas, y otras
              herramientas que pueden ser de paga alrededor deAmérica. Por favor
              lee nuestros Términos del servicio para entender las condiciones
              del uso de eCarpintero. Aceptas nuestros Términos del servicio
              ("Términos") al instalar, acceder o usar nuestras aplicaciones,
              servicios, funciones, software o sitio web (en conjunto,
              "Servicios").
            </p>
            <h2>Acerca de Nuestros Servicios</h2>
            <div className="gx-full-width">
              <Collapse
                expandIconPosition="right"
                defaultActiveKey={[0, 1, 2, 3]}
              >
                <Panel header="REGISTRO">
                  <p>
                    Debes registrarte para usar nuestros Servicios con datos
                    correctos, proporcionar tu dirección de correo y númerode
                    teléfono actual y, si lo cambias, actualizar mediante la
                    opción “Mi Cuenta”.
                  </p>
                  <p>
                    Aceptas recibir correos, mensajes de texto y llamadas
                    telefónicas (por parte de nosotros o proveedores externos)
                    con códigos para registrarte para usar nuestros Servicios.
                    Confirmas que estás autorizado a proporcionarnos dichos
                    números para permitirnos proveer nuestros Servicios
                  </p>
                </Panel>
                <Panel header="EDAD">
                  Debes tener por lo menos 15 años de edad para poder usar
                  nuestros Servicios (o la edad mínima requerida en tu país para
                  tener autorización para usar nuestrosServicios sin aprobación
                  de tus padres). Además de tener la edad mínima requerida para
                  usar nuestros Servicios en virtud de la ley aplicable, si no
                  tienes la edad suficiente para poder aceptar nuestras
                  Condiciones en tu país, tu padre, madre o tutor deben aceptar
                  nuestras Condiciones en tu nombre.
                </Panel>
                <Panel header="DISPOSITIVOS Y SOFTWARE">
                  Debes proporcionar ciertos dispositivos, software y conexiones
                  de datos que nosotros no proporcionamos para usar nuestros
                  Servicios. Durante todo el tiempo que uses nuestros Servicios,
                  aceptas descargar e instalar actualizaciones de nuestros
                  Servicios, incluso de forma automática.
                </Panel>
                <Panel header="TARIFAS E IMPUESTOS">
                  <p>
                    Tú eres responsable por los costos del plan de datos de tu
                    operador de telefonía móvil, así como de las demás tarifas e
                    impuestos asociados con el uso de nuestros Servicios.
                    Podemos cobrarte por nuestros Servicios, incluidos por los
                    impuestos correspondientes. Podemos rechazar o cancelar
                    pedidos. No otorgamos reembolsos por nuestros Servicios.
                  </p>
                  <p>
                    Algunos de nuestros servicios tendrán un costo y se
                    informará antes de la contratación, con los términos de
                    condiciones correspondientes al servicio contratado.La
                    publicidad es un servicio que se brindará de forma
                    automática para mantener la plataforma.
                  </p>
                </Panel>
              </Collapse>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default TermsAndConditions;
