import gql from 'graphql-tag';

export const DELETE_ADVERTISEMENT = gql`
  mutation deleteAdvertisement($id: ID!) {
    deleteAdvertisement(id: $id) {
      id
    }
  }
`;

export const CREATE_ADVERTISEMENT = gql`
  mutation createAdvertisement($input: InputCreateAdvertisement) {
    createAdvertisement(input: $input) {
      id
      location
      redirect_url
      from
      expire
      click
      multimedia {
        id
        url
        extension
      }
      tags
    }
  }
`;

export const UPDATE_ADVERTISEMENT = gql`
  mutation updateAdvertisement($id: ID!, $input: InputUpdateAdvertisement) {
    updateAdvertisement(id: $id, input: $input) {
      id
      location
      redirect_url
      from
      expire
      click
      multimedia {
        id
        url
        extension
      }
      tags
    }
  }
`;

export const UPDATE_ADVERTISEMENT_CLICK = gql`
  mutation updateAdvertisementClick($id: ID!, $input: UpdateClickInput!) {
    updateAdvertisementClick(id: $id, input: $input) {
      click
    }
  }
`;
