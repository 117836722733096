import React, {useState, Fragment} from 'react'
import {Form, Input, Button, Tooltip, Divider, message, Select, Spin, Empty} from 'antd'
import {useDispatch} from 'react-redux'
import {GoogleMap, withGoogleMap, Marker} from "react-google-maps"
import {useLazyQuery} from "@apollo/react-hooks";

import {toggleWizard} from '../../../../appRedux/actions'
import {onErrorMessage, googleGeocode} from '../../../../util/helpers'
import {GET_LOCATIONS} from "../../../../graphql/query/ubigeo";

const {Item} = Form
let timer = 0;

const google = window.google
const GoogleMapConfig = withGoogleMap((props) => (
  <GoogleMap
    onClick={props.handleOnClickMap}
    defaultZoom={props.zoom}
    center={props.center}
    defaultOptions={props.options}
  >
    <Marker position={props.center} onClick={props.handleOnClickMap}/>
  </GoogleMap>
))

const options = {
  scrollwheel: true,
  mapTypeControl: false,
  fullscreenControl: false,
  zoomControlOptions: {
    style: google.maps.ZoomControlStyle.SMALL,
  },
  mapTypeControlOptions: {
    position: google.maps.ControlPosition.TOP_RIGHT,
  },
  draggable: true,
  rotateControl: true,
  scaleControl: true,
  streetViewControl: false,
  panControl: true,
}

const ContactForm = ({form, skip, user, handleUpdateUser}) => {
  const {validateFields, getFieldDecorator, getFieldValue, setFieldsValue} = form
  const dispatch = useDispatch()

  const [displayMap, setDisplayMap] = useState(false)
  const [currentDistrict, setCurrentDistrict] = useState(null);
  const [currentDistrictName, setCurrentDistrictName] = useState(null);
  const [locations, setLocations] = useState([])
  const [center, setCenter] = useState({
    lat: null,
    lng: null,
  });

  const [queryLocations, {loading: loadingLocation}] = useLazyQuery(
    GET_LOCATIONS,
    {
      onCompleted({consultar_ubigeos}) {
        setLocations(consultar_ubigeos)
      },
      onError(error) {
        onErrorMessage(error)
      },
    }
  )

  const handleOnSearch = (value) => {
    clearInterval(timer);
    timer = setTimeout(() => {
      if (value) {
        queryLocations({variables: {value: `%${value}%`}});
      }
    }, 500);
  };

  const handleSelectDistric = (value, options) => {
    if (options) {
      const {text} = options.props;
      const stringChild = (text && text.replace(/[/]/g, "")) || "";
      const sDireccion = getFieldValue("sDireccion");
      setCurrentDistrict(value);
      setCurrentDistrictName(stringChild);
      if (displayMap) {
        getPosition({sDistrito: stringChild, sDireccion});
      }
    }
  };

  const handleOnBlurAddress = ({target: {value}}) => {
    if (displayMap) {
      getPosition({sDistrito: currentDistrictName, sDireccion: value});
    }
  };

  const getPosition = async ({sDireccion, sDistrito}) => {
    try {
      const stringLocation = `${sDireccion} ${sDistrito}`;
      const coordinates = await googleGeocode(stringLocation);
      setCenter(coordinates);
      setDisplayMap(true)
    } catch (error) {
      message.warning("No se pudo obtener coordenadas");
    }
  };

  const handleOnClickMap = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setCenter({lat, lng});
  };

  const cleanCoords = () => {
    setCenter({lat: 0, lng: 0});
    setDisplayMap(false)
  }

  const addCoords = () => {
    const address = getFieldValue('address');
    const district = getFieldValue('district');
    if (user) {
      if (user.ubigeo) {
        if (user.address === address && user.ubigeo.id === district) {
          if (user.lat && user.lng) {
            setCenter({
              lat: Number(user.lat),
              lng: Number(user.lng)
            });
            setDisplayMap(true)
          } else {
            getPosition({sDistrito: currentDistrictName, sDireccion: address});
          }
        } else {
          getPosition({sDistrito: currentDistrictName, sDireccion: address});
        }
      } else {
        getPosition({sDistrito: currentDistrictName, sDireccion: address});
      }
    } else {
      getPosition({sDistrito: currentDistrictName, sDireccion: address});
    }
  }

  const submit = event => {
    event.preventDefault()
    validateFields((errors, values) => {
      if (!errors) {
        const variables = {
          id: user.id,
          address: values.address,
          ubigeo_id: parseInt(currentDistrict),
          lat: center.lat ? parseFloat(center.lat).toString() : null,
          lng: center.lng ? parseFloat(center.lng).toString() : null
        }
        handleUpdateUser({variables})
      }
    })
  }

  return (
    <Form onSubmit={submit} layout="vertical">
      <Item>
        <h2 className="gx-font-weight-semi-bold gx-mb-1">
          Ubicación de Taller
          <Tooltip 
            title={(
              <div className="gx-fs-sm">
                <p className="gx-mb-0">La ubicación de tu taller estará disponible públicamente y sabemos que es importante tu privacidad por lo tanto si no deseas compartir la ubicación de tu centro de labores puedes dejar en blanco dicha información u ocultarlo en el mapa.</p>
              </div>
            )}
          >
            <i className="fal fa-question-circle gx-pointer gx-fs-md gx-float-right"/>
          </Tooltip>
        </h2>
        <div className="gx-text-muted gx-fs-sm gx-mb-0">
          Posiciona tu taller en nuestro buscador y haz que tus clientes te conozcan.
        </div>
      </Item>
      <Divider className="gx-mt-0 gx-mb-3" dashed/>
      <Item className="form-control-ec" label="Distrito">
        {getFieldDecorator('district')(
          <Select
            allowClear
            showSearch
            onSearch={handleOnSearch}
            onSelect={handleSelectDistric}
            onChange={value => {
              if (!value) {
                setDisplayMap(false)
                setCenter({lat: null, lng: null})
                setFieldsValue({address: null})
                setLocations([])
              }
            }}
            filterOption={(input, option) => {
              const regExp = new RegExp(input, "gi")
              return option.props.district.match(regExp)
            }}
            placeholder="Buscar distrito"
            notFoundContent={
              <Spin spinning={loadingLocation}>
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="Buscar distrito..."
                />
              </Spin>
            }
          >
            {Array.isArray(locations) &&
            locations.map((location) => (
              <Select.Option
                value={location.id}
                key={location.id}
                district={location.district}
                text={`${location.district}, ${location.province}, ${location.department}`}
              >
                {`${location.district}/${location.province}/${location.department}`}
              </Select.Option>
            ))}
          </Select>
        )}
      </Item>
      <Form.Item className="form-control-ec" label="Dirección">
        {getFieldDecorator('address')(
          <Input onBlur={handleOnBlurAddress} disabled={!getFieldValue('district')} allowClear placeholder="Ingrese dirección"/>
        )}
      </Form.Item>
      {displayMap ? (
        <Fragment>
          <GoogleMapConfig
            loadingElement={<div style={{height: `100%`}}/>}
            containerElement={
              <div style={{height: 300, width: "100%"}}/>
            }
            mapElement={<div style={{height: `100%`}}/>}
            center={center}
            zoom={17}
            options={options}
            handleOnClickMap={handleOnClickMap}
          />
          <Button type="primary" htmlType="button" size="small" className="gx-mt-2 gx-mb-4" onClick={cleanCoords}>
            Limpiar coordenadas
          </Button>
        </Fragment>
      ) : (
        <Button type="primary" disabled={!getFieldValue('address') || !getFieldValue('district')} htmlType="button" size="small" className="gx-mt-2 gx-mb-4" onClick={addCoords}>
          <i className="fal fa-map-marker-check gx-mr-2"/>
          Marcar ubicación en Google Maps
        </Button>
      )}
      <Item className="gx-mb-0">
        <Button type="primary" htmlType="submit" className="gx-mb-0" disabled={!getFieldValue('address') || !getFieldValue('district')}>Guardar</Button>
        <Button onClick={() => skip('ubication')} className="gx-mb-0">Omitir</Button>
        <Button onClick={() => dispatch(toggleWizard(false, form))} className="gx-float-right gx-mb-0">Cancelar</Button>
      </Item>
    </Form>
  )
}

export default Form.create()(ContactForm)
