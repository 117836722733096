import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Modal,
  Table,
  Button,
  Tooltip,
  Checkbox,
  InputNumber,
  Input,
} from "antd";
import {
  rxToggleModalTableros,
  rxSetDataLeyendTableBoards,
} from "appRedux/actions/Projects";

const styles = {
  inputNumber: {
    width: "100%",
    border: 0,
    textAlign: "center",
  },
  input: {
    border: 0,
    paddingLeft: 0,
  },
};
class MapTableros extends Component {
  handleCancel = () => {
    this.props.rxToggleModalTableros({ visible: false, info: null });
  };
  /**
   * ACTUALIZAR DATO EN COLUMNA
   */
  handleOnChangeDataBoardsTBL = async (value, idx, name) => {
    const { listCodeBoards } = this.props;
    const $list = listCodeBoards;
    if (idx && name && $list[idx][name] !== value) {
      const $value = name === "sDescTablero" ? value : Number(value);
      $list[idx][name] = await $value;
      this.props.rxSetDataLeyendTableBoards($list);
    }
  };

  /**
   * ELIMINAR RESTO
   */
  handleDeleteRowOnBoardsTBL = (index) => {
    const { listCodeBoards } = this.props;
    const $list = listCodeBoards;
    const $removeRow = $list.filter((i, idx) => idx !== index);
    this.props.rxSetDataLeyendTableBoards($removeRow);
  };

  /**
   * CLONAR FILA PARA RESTO
   */
  handleAddNeRowOnBoardsTBL = async (row) => {
    const { listCodeBoards } = this.props;
    const $code = await this.generateCodeTBL(listCodeBoards);
    const DATA_INDEX_BOARDS = {
      nLinea: $code,
      nIdTablero: row.nIdTablero,
      sDescTablero: row.sDescTablero,
      nStock: 0,
      nAncho: row.nAncho,
      nLargo: row.nLargo,
      bVeta: row.bVeta,
      bMatCliente: true,
      nEspesor: row.nEspesor,
      sCodTablero: row.sCodTablero,
    };

    this.props.rxSetDataLeyendTableBoards([
      ...listCodeBoards,
      DATA_INDEX_BOARDS,
    ]);
  };

  /**
   * GENERA CODIGO TABLERO
   */
  generateCodeTBL = async (list = []) => {
    const $newCode =
      (await list.length) > 0 ? list[list.length - 1].nLinea : null;
    return $newCode + 1;
  };

  render() {
    const { modalTableros, listCodeBoards, disabledLgnTBL } = this.props;
    const columns = [
      {
        title: "#",
        dataIndex: "nLinea",
        key: "nLinea",
        width: "5%",
        align: "center",
      },
      {
        title: "Descripción",
        dataIndex: "sDescTablero",
        key: "sDescTablero",
        render: (text, record, index) => {
          return record &&
            record.bMatCliente === true &&
            disabledLgnTBL === false ? (
            <Input
              style={styles.input}
              defaultValue={text}
              onBlur={(event) => {
                const { value } = event.target;
                this.handleOnChangeDataBoardsTBL(value, index, "sDescTablero");
              }}
            />
          ) : (
            text
          );
        },
      },
      {
        title: "Cantidad",
        dataIndex: "nStock",
        key: "nStock",
        align: "center",
        render: (text, record, index) => {
          return record &&
            record.bMatCliente === true &&
            disabledLgnTBL === false ? (
            <InputNumber
              style={styles.inputNumber}
              value={text}
              className="gx-text-center-in"
              min={0}
              onBlur={(event) => {
                const { value } = event.target;
                this.handleOnChangeDataBoardsTBL(value, index, "nStock");
              }}
            />
          ) : (
            text
          );
        },
      },
      {
        title: "Ancho",
        dataIndex: "nAncho",
        key: "nAncho",
        align: "center",
        render: (text, record, index) => {
          return record &&
            record.bMatCliente === true &&
            disabledLgnTBL === false ? (
            <InputNumber
              style={styles.inputNumber}
              value={text}
              className="gx-text-center-in"
              min={0}
              onBlur={(event) => {
                const { value } = event.target;
                this.handleOnChangeDataBoardsTBL(value, index, "nAncho");
              }}
            />
          ) : (
            text
          );
        },
      },
      {
        title: "Largo",
        dataIndex: "nLargo",
        key: "nLargo",
        align: "center",
        render: (text, record, index) => {
          return record &&
            record.bMatCliente === true &&
            disabledLgnTBL === false ? (
            <InputNumber
              style={styles.inputNumber}
              value={text}
              className="gx-text-center-in"
              min={0}
              onBlur={(event) => {
                const { value } = event.target;
                this.handleOnChangeDataBoardsTBL(value, index, "nLargo");
              }}
            />
          ) : (
            text
          );
        },
      },
      {
        title: "Veta",
        dataIndex: "bVeta",
        key: "bVeta",
        align: "center",
        width: "5%",
        render: function (value) {
          return <Checkbox checked={value} />;
        },
      },
      {
        title: "Mat. Clie.",
        dataIndex: "bMatCliente",
        key: "bMatCliente",
        width: "5%",
        align: "center",
        render: (value) => {
          return <Checkbox checked={value} className="gx-mt-1" />;
        },
      },
      {
        title: "",
        dataIndex: "sAcction",
        key: "sAcction",
        width: "5%",
        align: "center",
        render: (value, record, index) => {
          return disabledLgnTBL === true ? null : record &&
            record.bMatCliente === true ? (
            <div className="gx-btn-show-on-hover">
              <Tooltip title="Eliminar">
                <Button
                  type="danger"
                  size="small"
                  icon="delete"
                  ghost
                  className="gx-m-0"
                  onClick={() => {
                    this.handleDeleteRowOnBoardsTBL(index);
                  }}
                />
              </Tooltip>
            </div>
          ) : (
            <div className="gx-btn-show-on-hover">
              <Tooltip title="Agregar Resto">
                <Button
                  type="danger"
                  ghost
                  size="small"
                  icon="plus"
                  className="gx-m-0"
                  onClick={() => {
                    this.handleAddNeRowOnBoardsTBL(record, index);
                  }}
                />
              </Tooltip>
            </div>
          );
        },
      },
    ];
    return (
      <Modal
        title={
          <div className="gx-mb-0">
            <i className="far fa-border-all gx-mr-2" />
            Tableros
          </div>
        }
        centered
        visible={modalTableros.visible}
        onCancel={this.handleCancel}
        footer={null}
        width="90%"
        bodyStyle={{
          maxHeight: "calc(100vh - 260px)",
          height: "100%",
          overflowX: "auto",
          padding: 0,
        }}
      >
        <div className="gx-table-responsive">
          <Table
            columns={columns}
            dataSource={listCodeBoards}
            pagination={false}
            bordered={true}
            rowClassName={(record) =>
              record.bMatCliente === true
                ? "gx-container-show-on-hover editable-row gx-resalt-row-table-ec"
                : "gx-container-show-on-hover editable-row"
            }
            rowKey={(record, index) => index + 1}
            className="gx-reduce-espacio-in-row"
          />
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = ({ projects }) => {
  const { modalTableros, listCodeBoards } = projects;
  return {
    modalTableros,
    listCodeBoards,
  };
};

const mapDispatchToProps = {
  rxToggleModalTableros,
  rxSetDataLeyendTableBoards,
};

export default React.memo(
  connect(mapStateToProps, mapDispatchToProps)(MapTableros)
);
