import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_FACEBOOK_USER_SUCCESS,
  SIGNIN_GITHUB_USER_SUCCESS,
  SIGNIN_GOOGLE_USER_SUCCESS,
  SIGNIN_TWITTER_USER_SUCCESS,
  SIGNIN_USER_SUCCESS,
  SIGNIN_USER,
  SIGNOUT_USER,
  SIGNUP_USER_SUCCESS,
  MARK_LATEST_CONNECTION,
  UPDATE_WIZARD_USER_INFO,
  UPDATE_USER_STORAGE,
  UPDATE_USER_STORAGE_WITH_TOKEN,
  SKIP_FILL_INFO,
  CHANGE_OPTIMIZER_TOKEN,
  CREATED_OPTIMIZER_TOKEN
} from "../../constants/ActionTypes";

const INIT_STATE = {
  loader: false,
  alertMessage: "",
  showMessage: false,
  initURL: "",
  authUser: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null,
  authSucursal: {nIdSucursal: 4, description: 'Sucursal Principal'},
  token: localStorage.getItem("token") || null,
  optimizerToken: localStorage.getItem("optimizer_token") || null,
  optimizerID: localStorage.getItem('optimizer_id') || null,
  skipFillInfo: localStorage.getItem('skip_fill_info') || 'no-skip',
  latest: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_OPTIMIZER_TOKEN : {
      return {
        ...state,
        optimizerToken: action.payload
      }
    }
    case MARK_LATEST_CONNECTION: {
      return {
        ...state,
        latest: action.payload
      };
    }
    case CREATED_OPTIMIZER_TOKEN: {
      return {
        ...state,
        optimizerToken: action.payload.token,
        optimizerID: action.payload.optimizer_id
      }
    }
    case UPDATE_USER_STORAGE: {
      return {
        ...state,
        authUser: action.payload
      }
    }
    case SKIP_FILL_INFO: {
      return {
        ...state,
        skipFillInfo: action.payload
      }
    }
    case UPDATE_USER_STORAGE_WITH_TOKEN: {
      return {
        ...state,
        authUser: action.payload,
        optimizerToken: action.token,
        optimizerID: action.optimizer_id
      }
    }
    case UPDATE_WIZARD_USER_INFO: {
      return {
        ...state,
        authUser: action.payload
      }
    }
    case SIGNUP_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload,
      };
    }
    case SIGNIN_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload.authUser,
        token: action.payload.token,
        optimizerToken: action.payload.optimizerToken,
        optimizerID: action.payload.optimizerId,
        skipFillInfo: 'no-skip'
      };
    }
    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload,
      };
    }
    case SIGNOUT_USER: {
      return {
        ...state,
        authUser: null,
        token: null,
        optimizerToken: null,
        skipFillInfo: null
      };
    }
    case SIGNIN_USER: {
      return {
        ...state,
        authUser: action.payload
      }
    }

    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false,
      };
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: "",
        showMessage: false,
        loader: false,
      };
    }

    case SIGNIN_GOOGLE_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload,
      };
    }
    case SIGNIN_FACEBOOK_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload,
      };
    }
    case SIGNIN_TWITTER_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload,
      };
    }
    case SIGNIN_GITHUB_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload,
      };
    }
    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true,
      };
    }
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false,
      };
    }
    default:
      return state;
  }
};
