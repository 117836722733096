import {
  FETCH_PROJECTS_LIST_START,
  FETCH_PROJECTS_LIST_SUCCESS,
  FETCH_PROJECTS_LIST_ERROR,
  SELECT_HIDE_SHOW_PROJECT,
  FETCH_PROJECTS_DETAILS_START,
  FETCH_PROJECTS_DETAILS_SUCCESS,
  FETCH_PROJECTS_DETAILS_ERROR,
  SHOW_HIDE_MODAL_CANTOS,
  UPDATE_DATA_DETAIL_PROJECT,
  FETCH_BOARD_LIST_START,
  FETCH_BOARD_LIST_SUCCESS,
  FETCH_BOARD_LIST_ERROR,
  SELECT_TABLE_IN_PROJECT_DETAIL,
  FETCH_EDGES_LIST_START,
  FETCH_EDGES_LIST_SUCCESS,
  FETCH_EDGES_LIST_ERROR,
  FETCH_GROOVE_LIST_START,
  FETCH_GROOVE_LIST_SUCCESS,
  FETCH_GROOVE_LIST_ERROR,
  FETCH_PERFORATION_LIST_START,
  FETCH_PERFORATION_LIST_SUCCESS,
  FETCH_PERFORATION_LIST_ERROR,
  TOGGLE_MODAL_GROOVE,
  TOGGLE_MODAL_PERFORATION,
  FETCH_GROOVE_LIST_D25_START,
  FETCH_GROOVE_LIST_D25_SUCCESS,
  FETCH_GROOVE_LIST_D25_ERROR,
  FETCH_PERFORATION_LIST_D25_START,
  FETCH_PERFORATION_LIST_D25_SUCCESS,
  FETCH_PERFORATION_LIST_D25_ERROR,
  TOGGLE_MODAL_GROOVE_D25,
  TOGGLE_MODAL_PERFORATION_D25,
  RESET_TABLEPROYECT_DETAIL,
  FETCH_PROJECTS_ADD_EDIT_START,
  FETCH_PROJECTS_ADD_EDIT_SUCCESS,
  FETCH_PROJECTS_ADD_EDIT_ERROR,
  FETCH_OPTIMIZE_DATA_START,
  FETCH_OPTIMIZE_DATA_SUCCESS,
  FETCH_OPTIMIZE_DATA_ERROR,
  FETCH_PLANS_DATA_START,
  FETCH_PLANS_DATA_SUCCESS,
  FETCH_PLANS_DATA_ERROR,
  FETCH_UPDATE_STATE_PROJECT_START,
  FETCH_UPDATE_STATE_PROJECT_SUCCESS,
  FETCH_UPDATE_STATE_PROJECT_ERROR,
  FETCH_MATERIAL_SAMMARY_START,
  FETCH_MATERIAL_SAMMARY_SUCCESS,
  FETCH_MATERIAL_SAMMARY_ERROR,
  FETCH_COTIZA_PROJECT_START,
  FETCH_COTIZA_PROJECT_SUCCESS,
  FETCH_COTIZA_PROJECT_ERROR,
  FETCH_COTIZA_PROJECT_INSERT_START,
  FETCH_COTIZA_PROJECT_INSERT_SUCCESS,
  FETCH_COTIZA_PROJECT_INSERT_ERROR,
  FETCH_DUPLICATE_PROJECT_START,
  FETCH_DUPLICATE_PROJECT_SUCCESS,
  FETCH_DUPLICATE_PROJECT_ERROR,
  FETCH_LABELS_PROJECT_LIST_START,
  FETCH_LABELS_PROJECT_LIST_SUCCESS,
  FETCH_LABELS_PROJECT_LIST_ERROR,
  FETCH_SELECT_TRACING_PROJECT_START,
  FETCH_SELECT_TRACING_PROJECT_SUCCESS,
  FETCH_SELECT_TRACING_PROJECT_ERROR,
  OBTENER_INFORMACION_ADICONAL_USUARIO_START,
  OBTENER_INFORMACION_ADICONAL_USUARIO_SUCCESS,
  OBTENER_INFORMACION_ADICONAL_USUARIO_ERROR,
  RX_TOGGLE_MODAL_TABLEROS,
  RX_SET_DATA_LOYEND_TABLE_BOARDS,
  START_FILTERING_OPTIMIZER,
  SUCCESS_FITLERING_OPTIMIZER,
  ERROR_FITLERING_OPTIMIZER,
  CHANGE_VALUE_FILTER_OPTIMIZER,
  CLEAR_FILTER_OPTIMIZER,
} from "constants/ActionTypes";
import { TOGGLE_MODAL_MATERIAL } from "../../constants/ActionTypes";
import moment from "moment";

const initialState = {
  fetchingProyect: false,
  savingProject: false,
  listProyects: [],
  updatingStateProject: false,
  // detalle
  fetchingDetail: false,
  headerProject: null,
  detailProject: [],
  listCodeEdges: [],
  nIdProyectoReg: null,

  // tableros
  fetchingBoard: false,
  listBoards: [],
  searchAndSelectBoards: null,
  //tablero>material
  modal_material: {
    info: null,
    visible: false,
  },
  // tableros>Cantos
  fetchingEdges: false,
  listEdges: [],
  modalCantos: {
    visible: false,
    info: null,
  },
  // tablero > ranura
  listGroove: [],
  fetchingGroove: false,
  modalGroove: {
    visible: false,
    info: null,
  },
  modalGrooveD25: {
    visible: false,
    info: null,
  },
  // tablero>perforacion
  listPerforation: [],
  fetchingPerforation: false,
  modalPerforation: {
    visible: false,
    info: null,
  },
  modalPerforationD25: {
    visible: false,
    info: null,
  },
  // optimizar
  fetchingOptimize: false,
  listDataOptimize: [],
  //planos
  fetchingPlans: false,
  reverseStateProject: false,
  // resumen material
  projectMaterialSummary: null,
  fetchingProjectMaterialSummary: false,
  // cotizacion
  fetchingCotizacion: false,
  listCotizacion: [],
  savingOrderInProject: false,
  outputNIdPedido: null,
  // duplicar proyecto
  doublingProject: false,
  // etiquetas
  fetchingLabels: false,
  listLabelsProject: [],
  fetchingTracing: false,
  listTracingProject: [],
  // info adicional de usuario en proyectos7
  fetchingInfoUser: false,
  infoUserPrj: null,
  modalTableros: { info: null, visible: false },
  listCodeBoards: [],
  filterOptimizer: {
    projects: [],
    client: null,
    from: moment(new Date()).format("YYYY/MM/DD"),
    to: moment(new Date()).format("YYYY/MM/DD"),
    project: null,
    filtering: false,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    // list
    case CLEAR_FILTER_OPTIMIZER: {
      return {
        ...state,
        filterOptimizer: {
          projects: [],
          client: null,
          from: moment(new Date()).format("YYYY/MM/DD"),
          to: moment(new Date()).format("YYYY/MM/DD"),
          project: null,
          filtering: false,
        },
      };
    }
    case START_FILTERING_OPTIMIZER: {
      return {
        ...state,
        filterOptimizer: {
          ...state.filterOptimizer,
          filtering: true,
        },
      };
    }
    case SUCCESS_FITLERING_OPTIMIZER: {
      return {
        ...state,
        filterOptimizer: {
          ...state.filterOptimizer,
          filtering: false,
          projects: payload,
          client: null,
        },
      };
    }
    case ERROR_FITLERING_OPTIMIZER: {
      return {
        ...state,
        filterOptimizer: {
          ...state.filterOptimizer,
          filtering: false,
          projects: [],
        },
      };
    }
    case CHANGE_VALUE_FILTER_OPTIMIZER: {
      return {
        ...state,
        filterOptimizer: {
          ...state.filterOptimizer,
          [payload.field]: payload.value,
        },
      };
    }
    case FETCH_PROJECTS_LIST_START: {
      return {
        ...state,
        ...payload,
        nIdProyectoReg: null,
        headerProject: null,
        detailProject: [],
        listCodeEdges: [],
        reverseStateProject: false,
      };
    }
    case FETCH_PROJECTS_LIST_SUCCESS: {
      return {
        ...state,
        ...payload,
        nIdProyectoReg: null,
        headerProject: null,
        detailProject: [],
        listCodeEdges: [],
        reverseStateProject: false,
      };
    }
    case FETCH_PROJECTS_LIST_ERROR: {
      return {
        ...state,
        ...payload,
        nIdProyectoReg: null,
        headerProject: null,
        detailProject: [],
        listCodeEdges: [],
        reverseStateProject: false,
      };
    }
    /**
     * Optimizar proyecto
     */
    case FETCH_OPTIMIZE_DATA_START: {
      return {
        ...state,
        fetchingOptimize: true,
        listDataOptimize: [],
      };
    }
    case FETCH_OPTIMIZE_DATA_SUCCESS: {
      return {
        ...state,
        fetchingOptimize: false,
        listDataOptimize: payload,
      };
    }
    case FETCH_OPTIMIZE_DATA_ERROR: {
      return {
        ...state,
        fetchingOptimize: false,
        listDataOptimize: [],
      };
    }
    /**
     * Fetch Plano proyecto
     */
    case FETCH_PLANS_DATA_START: {
      return {
        ...state,
        fetchingPlans: true,
        listDataOptimize: [],
      };
    }
    case FETCH_PLANS_DATA_SUCCESS: {
      return {
        ...state,
        fetchingPlans: false,
        listDataOptimize: payload,
      };
    }
    case FETCH_PLANS_DATA_ERROR: {
      return {
        ...state,
        fetchingPlans: false,
        listDataOptimize: [],
      };
    }
    // cambiar estado proyecto
    case FETCH_UPDATE_STATE_PROJECT_START: {
      return {
        ...state,
        updatingStateProject: true,
        reverseStateProject: false,
      };
    }
    case FETCH_UPDATE_STATE_PROJECT_SUCCESS: {
      return {
        ...state,
        updatingStateProject: false,
        reverseStateProject: true,
      };
    }
    case FETCH_UPDATE_STATE_PROJECT_ERROR: {
      return {
        ...state,
        updatingStateProject: false,
        reverseStateProject: false,
      };
    }
    // Select proyect
    case SELECT_HIDE_SHOW_PROJECT: {
      return { ...state, headerProject: payload };
    }
    // add
    case FETCH_PROJECTS_ADD_EDIT_START: {
      return {
        ...state,
        savingProject: true,
      };
    }
    case FETCH_PROJECTS_ADD_EDIT_SUCCESS: {
      return {
        ...state,
        savingProject: false,
        nIdProyectoReg: payload,
      };
    }
    case FETCH_PROJECTS_ADD_EDIT_ERROR: {
      return {
        ...state,
        savingProject: false,
      };
    }
    // detalle
    case FETCH_PROJECTS_DETAILS_START: {
      return {
        ...state,
        fetchingDetail: true,
        headerProject: null,
      };
    }
    case FETCH_PROJECTS_DETAILS_SUCCESS: {
      return {
        ...state,
        fetchingDetail: false,
        headerProject: payload.header,
        detailProject: payload.details,
        listCodeEdges: payload.listCodeEdges,
        listCodeBoards: payload.listCodeBoards,
      };
    }
    case FETCH_PROJECTS_DETAILS_ERROR: {
      return {
        ...state,
        fetchingDetail: false,
        headerProject: null,
      };
    }
    case RESET_TABLEPROYECT_DETAIL: {
      return {
        ...state,
        detailProject: [global.empytRowTable],
      };
    }
    // MODAL MATERIAL
    case TOGGLE_MODAL_MATERIAL: {
      return {
        ...state,
        modal_material: {
          visible: payload.visible,
          info: payload.info,
        },
      };
    }
    // MODAL CANTOS
    case SHOW_HIDE_MODAL_CANTOS: {
      return {
        ...state,
        modalCantos: {
          info: payload.info,
          visible: payload.visible,
        },
      };
    }
    /**
     * ACTUALIZAR DATA PROJECT
     */
    case UPDATE_DATA_DETAIL_PROJECT: {
      return { ...state, detailProject: payload };
    }

    /**
     * TABLEROS
     */
    case FETCH_BOARD_LIST_START: {
      return {
        ...state,
        fetchingBoard: true,
        listBoards: [],
      };
    }
    case FETCH_BOARD_LIST_SUCCESS: {
      return {
        ...state,
        fetchingBoard: false,
        listBoards: payload,
      };
    }
    case FETCH_BOARD_LIST_ERROR: {
      return {
        ...state,
        fetchingBoard: false,
        listBoards: [],
      };
    }
    /**
     * BUSCAR SELECCIONAR TABLERO
     */
    case SELECT_TABLE_IN_PROJECT_DETAIL: {
      return {
        ...state,
        searchAndSelectBoards: payload,
      };
    }
    /**
     * CANTOS
     */
    case FETCH_EDGES_LIST_START: {
      return {
        ...state,
        listEdges: [],
        fetchingEdges: true,
      };
    }
    case FETCH_EDGES_LIST_SUCCESS: {
      return {
        ...state,
        listEdges: payload,
        fetchingEdges: false,
      };
    }
    case FETCH_EDGES_LIST_ERROR: {
      return {
        ...state,
        listEdges: [],
        fetchingEdges: false,
      };
    }
    /**
     * RANURA
     */
    case FETCH_GROOVE_LIST_START: {
      return {
        ...state,
        listGroove: [],
        fetchingGroove: true,
      };
    }
    case FETCH_GROOVE_LIST_SUCCESS: {
      return {
        ...state,
        listGroove: payload,
        fetchingGroove: false,
      };
    }
    case FETCH_GROOVE_LIST_ERROR: {
      return {
        ...state,
        listGroove: [],
        fetchingGroove: false,
      };
    }
    case TOGGLE_MODAL_GROOVE: {
      return {
        ...state,
        modalGroove: {
          visible: payload.visible,
          info: payload.info,
        },
      };
    }
    case FETCH_GROOVE_LIST_D25_START: {
      return {
        ...state,
        listGrooveD25: [],
        fetchingGrooveD25: true,
      };
    }
    case FETCH_GROOVE_LIST_D25_SUCCESS: {
      return {
        ...state,
        listGrooveD25: payload,
        fetchingGrooveD25: false,
      };
    }
    case FETCH_GROOVE_LIST_D25_ERROR: {
      return {
        ...state,
        listGrooveD25: [],
        fetchingGrooveD25: false,
      };
    }
    case TOGGLE_MODAL_GROOVE_D25: {
      return {
        ...state,
        modalGrooveD25: { info: payload.info, visible: payload.visible },
      };
    }
    /**
     * PERFORACIÓN
     */
    case FETCH_PERFORATION_LIST_START: {
      return {
        ...state,
        listPerforation: [],
        fetchingPerforation: true,
      };
    }
    case FETCH_PERFORATION_LIST_SUCCESS: {
      return {
        ...state,
        listPerforation: payload,
        fetchingPerforation: false,
      };
    }
    case FETCH_PERFORATION_LIST_ERROR: {
      return {
        ...state,
        listPerforation: [],
        fetchingPerforation: false,
      };
    }

    case TOGGLE_MODAL_PERFORATION: {
      return {
        ...state,
        modalPerforation: {
          visible: payload.visible,
          info: payload.info,
        },
      };
    }

    case FETCH_PERFORATION_LIST_D25_START: {
      return {
        ...state,
        listPerforationD25: [],
        fetchingPerforationD25: true,
      };
    }
    case FETCH_PERFORATION_LIST_D25_SUCCESS: {
      return {
        ...state,
        listPerforationD25: payload,
        fetchingPerforationD25: false,
      };
    }
    case FETCH_PERFORATION_LIST_D25_ERROR: {
      return {
        ...state,
        listPerforationD25: [],
        fetchingPerforationD25: false,
      };
    }
    case TOGGLE_MODAL_PERFORATION_D25: {
      return {
        ...state,
        modalPerforationD25: { visible: payload.visible, info: payload.info },
      };
    }
    // resument material
    case FETCH_MATERIAL_SAMMARY_START: {
      return {
        ...state,
        projectMaterialSummary: null,
        fetchingProjectMaterialSummary: true,
      };
    }
    case FETCH_MATERIAL_SAMMARY_SUCCESS: {
      return {
        ...state,
        projectMaterialSummary: payload,
        fetchingProjectMaterialSummary: false,
      };
    }
    case FETCH_MATERIAL_SAMMARY_ERROR: {
      return {
        ...state,
        projectMaterialSummary: null,
        fetchingProjectMaterialSummary: false,
      };
    }
    case FETCH_COTIZA_PROJECT_START: {
      return {
        ...state,
        fetchingCotizacion: true,
        listCotizacion: [],
      };
    }
    case FETCH_COTIZA_PROJECT_SUCCESS: {
      return {
        ...state,
        fetchingCotizacion: false,
        listCotizacion: payload,
      };
    }
    case FETCH_COTIZA_PROJECT_ERROR: {
      return {
        ...state,
        fetchingCotizacion: false,
        listCotizacion: [],
      };
    }
    case FETCH_COTIZA_PROJECT_INSERT_START: {
      return {
        ...state,
        savingOrderInProject: true,
        outputNIdPedido: null,
      };
    }
    case FETCH_COTIZA_PROJECT_INSERT_SUCCESS: {
      return {
        ...state,
        savingOrderInProject: false,
        outputNIdPedido: payload,
      };
    }
    case FETCH_COTIZA_PROJECT_INSERT_ERROR: {
      return {
        ...state,
        savingOrderInProject: false,
        outputNIdPedido: null,
      };
    }
    // duplicar proyecto
    case FETCH_DUPLICATE_PROJECT_START: {
      return {
        ...state,
        doublingProject: true,
      };
    }
    case FETCH_DUPLICATE_PROJECT_SUCCESS: {
      return {
        ...state,
        doublingProject: false,
      };
    }
    case FETCH_DUPLICATE_PROJECT_ERROR: {
      return {
        ...state,
        doublingProject: false,
      };
    }
    // Etiquetas
    case FETCH_LABELS_PROJECT_LIST_START: {
      return {
        ...state,
        fetchingLabels: true,
        listLabelsProject: [],
      };
    }
    case FETCH_LABELS_PROJECT_LIST_SUCCESS: {
      return {
        ...state,
        fetchingLabels: false,
        listLabelsProject: payload,
      };
    }
    case FETCH_LABELS_PROJECT_LIST_ERROR: {
      return {
        ...state,
        fetchingLabels: false,
        listLabelsProject: [],
      };
    }
    // seguimiento de projectos
    case FETCH_SELECT_TRACING_PROJECT_START: {
      return {
        ...state,
        fetchingTracing: true,
        listTracingProject: [],
      };
    }
    case FETCH_SELECT_TRACING_PROJECT_SUCCESS: {
      return {
        ...state,
        fetchingTracing: false,
        listTracingProject: payload,
      };
    }
    case FETCH_SELECT_TRACING_PROJECT_ERROR: {
      return {
        ...state,
        fetchingTracing: false,
        listTracingProject: [],
      };
    }
    /**
     * Info user fetching
     */
    case OBTENER_INFORMACION_ADICONAL_USUARIO_START: {
      return {
        ...state,
        fetchingInfoUser: true,
        infoUserPrj: null,
      };
    }
    case OBTENER_INFORMACION_ADICONAL_USUARIO_SUCCESS: {
      return {
        ...state,
        fetchingInfoUser: false,
        infoUserPrj: payload,
      };
    }
    case OBTENER_INFORMACION_ADICONAL_USUARIO_ERROR: {
      return {
        ...state,
        fetchingInfoUser: false,
        infoUserPrj: null,
      };
    }
    /**
     * Modal tableros
     */
    case RX_TOGGLE_MODAL_TABLEROS: {
      return {
        ...state,
        modalTableros: {
          info: payload.info,
          visible: payload.visible,
        },
      };
    }
    case RX_SET_DATA_LOYEND_TABLE_BOARDS: {
      return {
        ...state,
        listCodeBoards: payload.concat([]),
      };
    }
    default:
      return state;
  }
};
