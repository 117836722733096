import gql from 'graphql-tag';

export const CREATE_CLIENT = gql`
  mutation createClient($input: InputCreateClient!) {
    createClient(input: $input) {
      id
      document_type {
        id
        description
      }
      document_number
      social_reason
      email
      first_phone_number
      second_phone_number
      address
      ubigeo {
        id
        code
        district
        province
        department
      }
      contact_name
      contact_email
      contact_phone_number
    }
  }
`;

export const UPDATE_CLIENT = gql`
  mutation updateClient($id: ID!, $input: InputUpdateClient!) {
    updateClient(id: $id, input: $input) {
      id
      document_type {
        id
        description
      }
      document_number
      social_reason
      email
      first_phone_number
      second_phone_number
      address
      ubigeo {
        id
        code
        district
        province
        department
      }
      contact_name
      contact_email
      contact_phone_number
    }
  }
`;

export const DELETE_CLIENT = gql`
  mutation deleteClient($id: ID!) {
    deleteClient(id: $id) {
      id
    }
  }
`;
