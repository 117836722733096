import React from "react";
import { Rate, Button } from "antd";
import defaultProfile from "assets/images/labor.svg";
import { cloudinarySecure } from "../../../../util/config";

const CarpinterItems = ({
  name,
  distance,
  rate,
  avatar,
  occupation,
  specialty,
  experience_years,
  id,
  handleMarkerClick,
  handleMarkerClose,
}) => {
  return (
    <div className="gx-item-list-carpinter">
      <div className="gx-carpenter-header">
        <img
          src={
            avatar
              ? `${cloudinarySecure}/c_scale,q_auto:low,w_100/${
                  avatar.split(cloudinarySecure)[1]
                }`
              : defaultProfile
          }
          alt="FotoUsuario"
          className="gx-carpenter-photo"
        />
      </div>
      <div
        className="gx-carpenter-body"
        onMouseEnter={() => handleMarkerClick({ id })}
        onMouseLeave={() => handleMarkerClose({ id })}
      >
        {name ? <h3 className="gx-carpenter-title">{name}</h3> : null}
        {experience_years ? (
          <div className="gx-distance-carpenter-container gx-text-grey gx-mb-1">
            <i className="fal fa-clock gx-text-primary gx-pr-1" />
            {experience_years} Años de experiencia.
          </div>
        ) : null}
        {/* {distance ? (
          <div className="gx-distance-carpenter-container gx-text-grey gx-mb-1">
            <i className="fal fa-map-marker-alt gx-text-primary gx-pr-1" />
            {distance}
          </div>
        ) : null} */}
        {occupation ? (
          <div className="gx-distance-carpenter-container gx-text-grey gx-mb-1">
            <i className="fal fa-user-tie gx-text-primary gx-pr-1" />
            {occupation}
          </div>
        ) : null}
        {specialty ? (
          <div className="gx-distance-carpenter-container gx-text-grey gx-mb-1">
            <i className="fal fa-comment-lines gx-text-primary gx-pr-1" />
            {specialty}
          </div>
        ) : null}

        {/* <div className="gx-tags-carpenter-container gx-mb-1">
          {Array.isArray(tags) &&
            tags.map((tag, index) => (
              <Tag style={{ margin: 0 }} color={tag.color} key={index}>
                {tag.label}
              </Tag>
            ))}
        </div> */}
        <div className="gx-review-carpenter-container gx-d-block gx-d-lg-none">
          <Rate allowHalf defaultValue={rate} />
          <Button
            type="primary"
            ghost
            size="small"
            block
            className="gx-d-block"
          >
            Contactar
          </Button>
        </div>
      </div>

      <div className="gx-review-carpenter-container gx-text-center gx-d-none gx-d-lg-block">
        <Button type="primary" ghost size="small">
          Contactar
        </Button>
        <Rate allowHalf defaultValue={rate} />
      </div>
    </div>
  );
};
export default CarpinterItems;
