import {CLIENTS_LIST, ADVERTISEMENTS_LIST, TOGGLE_MODAL_ADVERTISEMENT} from "../../constants/ActionTypes";

const INIT_STATE = {
  clients_list: [],
  advertisements: [],
  modalAdvertisement: {
    visible: false,
    info: null
  }
};

export default (state = INIT_STATE, {type, payload}) => {
  switch (type) {
    case CLIENTS_LIST: {
      return {
        ...state,
        clients_list: payload
      }
    }
    case ADVERTISEMENTS_LIST: {
      return {
        ...state,
        advertisements: payload
      }
    }
    case TOGGLE_MODAL_ADVERTISEMENT: {
      return {
        ...state,
        modalAdvertisement: {
          visible: payload.visible,
          info: payload.info
        }
      }
    }
    default: {
      return {
        ...state
      }
    }
  }
};
