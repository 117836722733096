import React from "react";
import { Icon } from "antd";
import { formatDate } from "util/helpers";
import QRCode from "qrcode.react";
import "./labels.css";

export default function LabelsPDF(props) {
  const { width, height, dataSource } = props;
  return (
    <div
      style={{ width: `${width + 1}cm` }}
      className="gx-main-container-labels-print"
    >
      {Array.isArray(dataSource) && dataSource.length > 0
        ? dataSource.map((label, idx) => (
            <div
              style={{
                width: `${width}cm`,
                height: `${height}cm`,
                display: "block"
              }}
              className="gx-labels-container"
              key={idx}
            >
              <div className="gx-box-left">{label.sDescA1}</div>
              <div className="gx-box-right">{label.sDescA2}</div>
              <div className="gx-box-label">
                <div className="gx-item-labels-container">
                  <ul className="gx-titles">
                    <li>Proyecto:</li>
                    <li>Cliente:</li>
                    <li>Obra:</li>
                    <li>Tablero:</li>
                    <li>Medidas:</li>
                    <li>Obs:</li>
                    <li>Ranura:</li>
                    <li>Perf:</li>
                    <li>Item/Cant.:</li>
                  </ul>
                </div>
                <div className="gx-item-labels-container">
                  <ul className="gx-values">
                    <li className="gx-text-grid-3">
                      <span>{label.sProyecto}</span>
                      <strong>F.E.:</strong>
                      <span>{formatDate(label.dEntrega, "date") || "-"}</span>
                      <span>
                        {label.bServicioEsp === true ? (
                          <Icon type="star" theme="twoTone" />
                        ) : null}
                      </span>
                    </li>
                    <li>{label.sCliente}</li>
                    <li>{label.sObra}</li>
                    <li>{label.sDescTablero}</li>
                    <li>
                      {label.nAncho}x{label.nLargo}
                    </li>
                    <li>{label.sObservaciones}</li>
                    <li>{label.sRanura}</li>
                    <li>{label.sPerforacion}</li>
                    <li>
                      {label.nItem}/{label.nCantidad}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="gx-box-top">{label.sDescL1}</div>
              <div className="gx-box-bottom">{label.sDescL2}</div>
              <div className="gx-labels-qr-code">
                <QRCode
                  value={label.sProyecto}
                  size={60}
                  includeMargin={true}
                />
              </div>
            </div>
          ))
        : null}
    </div>
  );
}
