import React from "react";
import { Popover } from "antd";

import defaultThumbnail from "assets/images/business-and-finance.png";
import { cloudinarySecure } from "../../../../util/config";

const renderItems = (data) => {
  if (data) {
    const dataArray = data.split(",");
    if (dataArray.length > 0) {
      const response = dataArray.map((item, idx) => <li key={idx}>{item}</li>);
      return response;
    } else {
      return null;
    }
  }
  return null;
};

const ShopsItemCard = ({ data, handleMarkerClick, handleMarkerClose }) => {
  return data ? (
    <div className="gx-card-shops-container">
      <div className="gx-card-shop-thumnail">
        <img
          src={
            data.thumbnail
              ? data.thumbnail.url
                ? `${cloudinarySecure}/c_scale,q_auto:low,w_100/${
                    data.thumbnail.url.split(cloudinarySecure)[1]
                  }`
                : defaultThumbnail
              : defaultThumbnail
          }
          alt={data.name || "portada"}
        />
      </div>
      <ul
        className="gx-card-shop-content"
        onMouseEnter={() => handleMarkerClick(data)}
        onMouseLeave={() => handleMarkerClose(data)}
      >
        {data.name ? (
          <li>
            <h1>
              {data.name}
              {data.services || data.products || data.brands ? (
                <Popover
                  placement="topLeft"
                  title={null}
                  content={
                    <div>
                      {data.services ? (
                        <>
                          <h6 className="h6">Servicios</h6>
                          <ul>{renderItems(data.services)}</ul>
                        </>
                      ) : null}
                      {data.products ? (
                        <>
                          <h6 className="h6">Productos</h6>
                          <ul>{renderItems(data.products)}</ul>
                        </>
                      ) : null}
                      {data.brands ? (
                        <>
                          <h6 className="h6">Marcas</h6>
                          <ul>{renderItems(data.brands)}</ul>
                        </>
                      ) : null}
                    </div>
                  }
                >
                  <i className="fal fa-ellipsis-h gx-pl-1 gx-text-color" />
                </Popover>
              ) : null}
            </h1>
          </li>
        ) : null}
        {data.attention ? (
          <li>
            <span className="gx-text-grey">
              <i className="gx-text-primary gx-pr-1 fal fa-calendar" />
              {data.attention}
            </span>
          </li>
        ) : null}
        {data.address ? (
          <li>
            <span className="gx-text-grey gx-link">
              <i className="gx-text-primary gx-pr-1 fal fa-map-marker-alt" />
              {data.address}
            </span>
          </li>
        ) : null}
        {data.phone_numbers ? (
          <li>
            <span className="gx-text-grey gx-link">
              <i className="gx-text-primary gx-pr-1 fal fa-phone-plus" />
              {data.phone_numbers.replace(/,/g, " / ")}
            </span>
          </li>
        ) : null}
        {data.email ? (
          <li>
            <a
              href={`mailto:${data.email}`}
              target="_blank"
              className="gx-text-grey"
              rel="noopener noreferrer"
            >
              <i className="gx-text-primary gx-pr-1 fal fa-envelope" />
              {data.email}
            </a>
          </li>
        ) : null}

        {data.web ? (
          <li>
            <a
              href={data.web}
              target="_blank"
              rel="noopener noreferrer"
              className="gx-text-grey"
            >
              <i className="gx-text-primary gx-pr-1 fal fa-globe" />
              {data.web}
            </a>
          </li>
        ) : null}
      </ul>
    </div>
  ) : null;
};

export default ShopsItemCard;
