import React from 'react';
import PropTypes from "prop-types";

const CustomStep = ({question, children, actions}) => {
  return (
    <div className="ant-card gx-card-widget gx-card-full gx-pt-4 gx-pb-3 gx-px-3 ant-card-bordered">
      <div className="ant-card-body">
        <div className="gx-separator gx-ml-0 gx-bg-sepia"/>
        <h2 className="gx-mb-4 gx-text-sepia">
          {question}
        </h2>
        <div className="gx-d-block">
          {children}
        </div>
        {actions && <div className="gx-mt-3">{actions}</div>}
      </div>
    </div>
  )
};

export default CustomStep;

CustomStep.propTypes = {
  question: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  actions: PropTypes.node.isRequired
};

CustomStep.defaultProps = {
  question: '',
  children: '',
  actions: ''
};
