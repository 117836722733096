import React from 'react';
import { Button } from 'antd';

const SubscriptionItem = ({
  nId,
  title,
  icon,
  description,
  price,
  include,
  currentIs = false,
  isBest = false,
  onPressCard,
  onPay,
  plan,
  showBtn = true,
  isDemo = false,
  dFin = null
}) => {
  return (
    <div
      className='gx-subscription-card'
      data-current={currentIs}
      onClick={() => onPressCard && onPressCard({ ...plan, description, nId })}
    >
      {isBest && (
        <div className='gx-best-flag'>
          <span>MÁS POPULAR</span>
        </div>
      )}
      <div className='gx-subscription-card__flag-current-plan' data-current={currentIs}>
        {currentIs && <i className='fal fa-check' />}
      </div>
      <div className='gx-subscription-card__icon gx-pb-2'>
        <i className={`fad fa-${icon} fa-2x`} />
      </div>
      <div className='gx-subscription-card__title gx-pb-1'>{title}</div>
      <div className='gx-subscription-card__description gx-pb-1'>{description}</div>
      <div className='gx-subscription-card__price gx-pb-2'>
        {isDemo ? (
          <span>Tu prueba será cancelada el {dFin && global.moment(dFin).format('DD/MM/YYYY')}</span>
        ) : (
          <>
            S/{price}.00
            <span className='gx-pl-1'>Al mes</span>
          </>
        )}
      </div>
      <ul className='gx-subscription-card__include-options gx-pb-2'>
        {include&&include.map((item, key) => (
          <li key={key}>
            <i className='fal fa-check gx-pr-1' />
            {item}
          </li>
        ))}
      </ul>
      {currentIs && title !== 'Gratuito' && showBtn === true && (
        <Button type='primary' size='large' block onClick={() => onPay && onPay({ ...plan, description })}>
          Comprar
        </Button>
      )}
    </div>
  );
};

export default SubscriptionItem;
