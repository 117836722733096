import React, { Fragment } from "react";
import { Button, Form, Input, Spin, Modal } from "antd";
import { useMutation } from "@apollo/react-hooks";
import { openNotification } from "util/helpers";
import { FORGOT_PASSWORD } from "./../../graphql/mutation/auth";
import Seo from "../../components/seo";

const FormItem = Form.Item;

const ForgotPassword = (props) => {
  const { resetFields, getFieldDecorator } = props.form;
  const [forgotPassword, { loading }] = useMutation(FORGOT_PASSWORD, {
    onCompleted({ forgotPassword }) {
      const { status, message } = forgotPassword;
      if (status === "EMAIL_SENT") {
        Modal.success({
          content: (
            <Fragment>
              {message || "Ahora puedes iniciar sesión en eCarpintero"}
            </Fragment>
          ),
          okText: "De acuerdo",
          onOk: () => {
            props.history.push("/login");
          },
        });
        resetFields();
      }
    },
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ extensions }) => {
          openNotification({
            message: "[Error]",
            description: extensions.reason,
            type: "error",
          });
        });
      }
      if (networkError) {
        openNotification({
          message: `[Error de Red]`,
          description: networkError,
          type: "error",
        });
      }
    },
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        if (values && values.email) {
          forgotPassword({
            variables: {
              email: values.email,
            },
          });
        } else {
          openNotification({
            message: "Requerido",
            description: "Por favor, ingrese tu correo electronico",
            type: "warning",
          });
        }
      }
    });
  };

  return (
    <div className="gx-login-container">
      <Seo
        title="¿Olvidaste tu contraseña?"
        url={window.location.href}
        description={`No te preocupes Recuperar la contraseña es fácil. Solo díganos el
        correo electrónico que ha registrado en eCarpintero.`}
        keywords="carpintero"
      />
      <div className="gx-login-content">
        <div className="gx-login-header">
          <img
            src={require("assets/images/eCarpintero-logo.svg")}
            alt="eCarpintero"
            title="eCarpintero"
          />
        </div>
        <div className="gx-mb-4">
          <h2>¿Olvidaste tu contraseña?</h2>
          <p>
            No te preocupes Recuperar la contraseña es fácil. Solo díganos el
            correo electrónico que ha registrado en eCarpintero.
          </p>
        </div>

        <Spin spinning={loading}>
          <Form
            layout="vertical"
            onSubmit={handleSubmit}
            className="gx-login-form gx-form-row0"
          >
            <FormItem>
              {getFieldDecorator("email", {
                rules: [
                  {
                    type: "email",
                    message: "Ingrese un correo valido!",
                  },
                  {
                    required: true,
                    message: "Por favor ingrese correo!",
                  },
                ],
              })(
                <Input
                  autoFocus
                  type="email"
                  placeholder="tu-correo@ejemplo.com"
                />
              )}
            </FormItem>

            <FormItem>
              <Button type="primary" block htmlType="submit">
                ENVIAR
              </Button>
            </FormItem>
          </Form>
        </Spin>
      </div>
    </div>
  );
};

const WrappedForgotPasswordForm = Form.create()(ForgotPassword);

export default WrappedForgotPasswordForm;
