import React, {useState, Fragment} from 'react'
import {Form, Input, Button, Tooltip, Divider, Select, InputNumber} from 'antd'
import {useDispatch} from 'react-redux'
import {useQuery} from "@apollo/react-hooks"

import {GET_MASTERS} from "../../.././../graphql/query/master"
import {onErrorMessage, numbersOnly} from "../../.././../util/helpers"
import {toggleWizard} from "../../.././../appRedux/actions"

const {Item} = Form
const {Option} = Select

const ProfessionalForm = ({form, skip, user, handleUpdateUser}) => {
  const {validateFields, getFieldDecorator, getFieldValue} = form
  const entities = ["MEMBRESIA", "TIPO_DOCUMENTO", "ESPECIALIDAD", "PROFESION"]
  const dispatch = useDispatch()

  const [dataMasters, setDataMasters] = useState([])

  const renderEntity = ({data = [], parent = false}) => {
    if (parent) {
      data = data.filter(item => item.parent_id === parent)
    }
    return Array.isArray(data) &&
      data.map((master) =>
        <Option value={master.id} key={master.id}>
          {master.description}
        </Option>
      )
  }

  const {loading: loadingMasters} = useQuery(GET_MASTERS, {
    variables: {
      column: "ENTITY",
      operator: "IN",
      value: entities,
    },
    onCompleted({masters}) {
      setDataMasters(masters)
    },
    onError(error) {
      onErrorMessage(error)
    }
  })

  const submit = event => {
    event.preventDefault()
    validateFields((errors, values) => {
      if (!errors) {
        const variables = {
          id: user.id,
          experience_years: values.experience_years || null,
          experience_resume: values.experience_resume || null,
          specialty_id: values.specialty || null,
        }
        handleUpdateUser({variables})
      }
    })
  }

  return (
    <Form onSubmit={submit} layout="vertical">
      <Item>
        <h2 className="gx-font-weight-semi-bold gx-mb-1">
          Datos Profesionales
          <Tooltip 
            title={(
              <div className="gx-fs-sm">
                <p className="gx-mb-0">Esta información se comparte de manera pública en tu perfil.</p>
              </div>
            )}
          >
            <i className="fal fa-question-circle gx-pointer gx-fs-md gx-float-right"/>
          </Tooltip>
        </h2>
        <div className="gx-text-muted gx-fs-sm gx-mb-0">
          Es importante que tus clientes sepan del profesionalismo que brindas en tus servicios y/o productos.
        </div>
      </Item>
      <Divider className="gx-mt-0 gx-mb-3" dashed/>
      {!user.specialty && (
        <Fragment>
          <Item label="Profesión">
            {getFieldDecorator('profession', {
              rules: [
                {required: true, message: 'Campo requerido'}
              ]
            })(
              <Select loading={loadingMasters} allowClear placeholder="Seleccione">
                {renderEntity({data: dataMasters.filter(item => item.entity === 'PROFESION')})}
              </Select>
            )}
          </Item>
          <Item label="Especialidad">
            {getFieldDecorator('specialty', {
              rules: [
                {required: true, message: 'Campo requerido'}
              ]
            })(
              <Select loading={loadingMasters} allowClear placeholder="Seleccione">
                {getFieldValue('profession') && renderEntity({data: dataMasters.filter(item => item.entity === 'ESPECIALIDAD'), parent: getFieldValue('profession')})}
              </Select>
            )}
          </Item>
        </Fragment>
      )}
      {!user.experience_years && (
        <Item label="Años de experiencia">
          {getFieldDecorator('experience_years', {
            rules: [
              {required: true, message: 'Campo requerido'}
            ]
          })(
            <InputNumber min={1} max={80} placeholder="Ingrese los años" onKeyDown={numbersOnly} style={{width: '100%'}}/>
          )}
        </Item>
      )}
      {!user.experience_resume && (
        <Item
          label="Resúmen de experiencia"
          className="gx-mb-4"
          extra={
            <small className="gx-text-grey gx-mt-0">
              Describa brevemente sus habilidades y experiencia
            </small>
          }
        >
          {getFieldDecorator('experience_resume', {
            rules: [
              {required: true, message: 'Campo requerido'}
            ]
          })(
            <Input.TextArea
              autoSize={{
                maxRows: 8,
                minRows: 4
              }}
              allowClear
              placeholder="..."
          />
          )}
        </Item>
      )}
      <Item className="gx-mb-0">
        <Button type="primary"
          htmlType="submit"
          className="gx-mb-0"
          disabled={
            !getFieldValue('specialty') || 
            !getFieldValue('profession') || 
            !getFieldValue('experience_resume') ||
            !getFieldValue('experience_years')
          }
        >
          Guardar
        </Button>
        <Button onClick={() => skip('professional')} className="gx-mb-0">Omitir</Button>
        <Button onClick={() => dispatch(toggleWizard(false, form))} className="gx-float-right gx-mb-0">Cancelar</Button>
      </Item>
    </Form>
  )
}

export default Form.create()(ProfessionalForm)
