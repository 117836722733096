import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Row,
  Col,
  Modal,
  Button,
  Select,
  message,
  Spin,
  Empty,
  Upload,
} from "antd";
import { connect, useDispatch } from "react-redux";
import { rxToggleShopsModal, rxSetListShop } from "appRedux/actions";
import { GoogleMap, withGoogleMap, Marker } from "react-google-maps";
import { googleGeocode, onErrorMessage } from "util/helpers";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";
import { GET_LOCATIONS } from "graphql/query/ubigeo";
import { RETRIEVE_SHOP, GET_SHOPS } from "graphql/query/shop";
import { REGISTER_SHOP, UPDATE_SHOP } from "graphql/mutation/shop";
import emptyThumbnail from "assets/images/thumbnail175x131.png";
import {
  cloudinaryEndPoint,
  cloudinaryUploadPreset,
} from "../../../../util/config";
import axios from "axios";
import { DELETE_CLOUDINARY_FILE } from "../../../../graphql/mutation/cloudinary";
import { getPublicIDCloudinaryFile } from "../../../../util/helpers";

let timer = 0;
const { Option } = Select;
const google = window.google;
const GoogleMapConfig = withGoogleMap((props) => (
  <GoogleMap
    onClick={props.handleOnClickMap}
    defaultZoom={props.zoom}
    center={props.center}
    defaultOptions={props.options}
  >
    <Marker position={props.center} onClick={props.handleOnClickMap} />
  </GoogleMap>
));

const StoreForm = (props) => {
  const { modalShops, rxToggleShopsModal } = props;
  const {
    validateFields,
    getFieldDecorator,
    getFieldValue,
    setFieldsValue,
    resetFields,
  } = props.form;
  const dispatch = useDispatch();
  const [zoom, setZoom] = useState(17);
  const [currentDistric, setCurrentDistric] = useState(null);
  const [coverPage, setCoverPage] = useState(null);
  const [currentCoverPage, setCurrentCoverPage] = useState(null);
  const [currentCoverPageID, setCurrentCoverPageID] = useState(null);
  // const [aServices, setAServices] = useState([
  const [aServices] = useState([
    "Corte",
    "Corte especial",
    "Canteado",
    "Postformado",
    "Ranurado",
    "Bisagrado",
    "Pintado",
  ]);
  const [aProducts] = useState([]);
  const [aBrands] = useState([]);
  // const [aProducts, setAProducts] = useState([]);
  // const [aBrands, setABrands] = useState([]);
  const [center, setCenter] = useState({
    lat: -12.0262676,
    lng: -77.1278634,
  });

  const options = {
    scrollwheel: true,
    mapTypeControl: false,
    fullscreenControl: false,
    zoomControlOptions: {
      style: google.maps.ZoomControlStyle.SMALL,
    },
    mapTypeControlOptions: {
      position: google.maps.ControlPosition.TOP_RIGHT,
    },
    draggable: true,
    rotateControl: true,
    scaleControl: true,
    streetViewControl: false,
    panControl: true,
  };

  const [locations, setLocations] = useState([]);
  const [retrieveShop, { loading }] = useLazyQuery(RETRIEVE_SHOP, {
    fetchPolicy: "network-only",
    onCompleted({ retrieveShop }) {
      if (retrieveShop) {
        const coordenadas = {
          lat: retrieveShop.lat ? Number(retrieveShop.lat) : 0,
          lng: retrieveShop.lng ? Number(retrieveShop.lng) : 0,
        };
        setFieldsValue({
          sContacto: retrieveShop.contact,
          sCordenadas: JSON.stringify(coordenadas),
          sCorreo: retrieveShop.email,
          sDireccion: retrieveShop.address,
          sDistrito: retrieveShop.ubigeo && retrieveShop.ubigeo.id,
          sNombre: retrieveShop.name,
          sTelefonos: retrieveShop.phone_numbers
            ? retrieveShop.phone_numbers.split(",")
            : [],
          sWeb: retrieveShop.web,
          sServicos: retrieveShop.services
            ? retrieveShop.services.split(",")
            : [],
          sProductos: retrieveShop.products
            ? retrieveShop.products.split(",")
            : [],
          sMarcas: retrieveShop.brands ? retrieveShop.brands.split(",") : [],
        });
        retrieveShop.thumbnail &&
          setCurrentCoverPage(retrieveShop.thumbnail.url);
        retrieveShop.thumbnail &&
          setCurrentCoverPageID(retrieveShop.thumbnail.id);
        setCenter(coordenadas);
        setZoom(17);
        if (modalShops.info.ubigeo) {
          handleOnSearch(modalShops.info.ubigeo.district);
        }
      }
    },
    onError(error) {
      onErrorMessage(error);
    },
  });

  useEffect(() => {
    if (modalShops && modalShops.info) {
      retrieveShop({
        variables: {
          id: modalShops && modalShops.info && modalShops.info.id,
        },
      });
    }
  }, [modalShops, retrieveShop]);

  const [getShopsUpdated] = useLazyQuery(GET_SHOPS, {
    fetchPolicy: "network-only",
    onCompleted({ getShops }) {
      dispatch(rxSetListShop(getShops));
    },
    onError(error) {
      onErrorMessage(error, false);
    },
    notifyOnNetworkStatusChange: true,
  });

  const [queryLocations, { loading: locationLoading }] = useLazyQuery(
    GET_LOCATIONS,
    {
      onCompleted({ consultar_ubigeos }) {
        setLocations(consultar_ubigeos);
      },
      onError(error) {
        onErrorMessage(error);
      },
    }
  );

  const mutationShop = useMutation(REGISTER_SHOP, {
    onCompleted({ registerShop }) {
      const { id } = registerShop;
      if (id) {
        message.success("Tienda registrada correctamente");
        resetFields();
        setCoverPage(null);
        setCurrentCoverPage(null);
        getShopsUpdated();
      }
    },
    onError(error) {
      onErrorMessage(error);
    },
  });

  const mutationUpdateShop = useMutation(UPDATE_SHOP, {
    onCompleted({ updateShop }) {
      const { id } = updateShop;
      if (id) {
        message.success("La información fue actualizada correctamente");
        getShopsUpdated();
      }
    },
    onError(error) {
      onErrorMessage(error);
    },
  });

  const handleSelectDistrict = (value, options) => {
    if (options) {
      const { text } = options.props;
      const stringChild = (text && text.replace(/[/]/g, "")) || "";
      const sDireccion = getFieldValue("sDireccion");
      setCurrentDistric(stringChild);
      getPosition({ sDistrito: stringChild, sDireccion });
    }
  };

  const handleOnBlurAddress = ({ target: { value } }) => {
    getPosition({ sDistrito: currentDistric, sDireccion: value });
  };

  const getPosition = async ({ sDireccion, sDistrito }) => {
    try {
      const stringLocation = `${sDireccion} ${sDistrito}`;
      const coordinates = await googleGeocode(stringLocation);
      setCenter(coordinates);
      setFieldsValue({
        sCordenadas:
          (coordinates && JSON.stringify(coordinates)) || "{lat:0,lng:0}",
      });
    } catch (error) {
      message.warning("No se pudo obtener coordenadas");
    }
  };

  const handleOnSearch = (value) => {
    clearInterval(timer);
    timer = setTimeout(() => {
      if (value) {
        queryLocations({ variables: { value: `%${value}%` } });
      }
    }, 500);
  };

  const [deleteCloudinaryFile] = useMutation(DELETE_CLOUDINARY_FILE, {
    onError() {
      message.error("Ha ocurrido un error");
    },
  });

  const updateInfoShop = (variables, updateShop) => {
    updateShop({ variables });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validateFields(async (err, values) => {
      if (!err) {
        const [registerShop] = mutationShop;
        const [updateShop] = mutationUpdateShop;
        const {
          sContacto,
          sCordenadas,
          sCorreo,
          sDireccion,
          sDistrito,
          sNombre,
          sTelefonos,
          sWeb,
          sServicos,
          sProductos,
          sMarcas,
        } = values;
        const headers = { "Content-Type": "multipart/form-data" };
        const LatLng = JSON.parse(sCordenadas);
        if (coverPage !== null || currentCoverPage !== null) {
          if (modalShops && modalShops.info && modalShops.info.id) {
            let variables = {
              id: modalShops.info.id,
              ubigeo_id: sDistrito,
              name: sNombre,
              phone_numbers: sTelefonos ? sTelefonos.toString() : null,
              address: sDireccion,
              location: null,
              lat: LatLng.lat || "",
              lng: LatLng.lng || "",
              email: sCorreo,
              contact: sContacto,
              web: sWeb || null,
              services: sServicos ? sServicos.toString() : null,
              products: sProductos ? sProductos.toString() : null,
              brands: sMarcas ? sMarcas.toString() : null,
            };
            if (coverPage) {
              const public_id = getPublicIDCloudinaryFile(currentCoverPage);
              deleteCloudinaryFile({ variables: { file: public_id } }).then(
                ({ data }) => {
                  if (data.deleteCloudinaryFile) {
                    if (data.deleteCloudinaryFile.status) {
                      const info = new FormData();
                      info.append("file", coverPage);
                      info.append("upload_preset", cloudinaryUploadPreset);
                      axios
                        .post(cloudinaryEndPoint, info, { headers })
                        .then((res) => {
                          variables["thumbnail"] = {
                            upsert: {
                              id:
                                currentCoverPageID &&
                                parseInt(currentCoverPageID),
                              url: res.data.secure_url,
                              extension: "png",
                              thumbnail: true,
                            },
                          };
                          updateInfoShop(variables, updateShop);
                        })
                        .catch(() => message.warning("Error al subir foto"));
                    } else {
                      message.warning(data.deleteCloudinaryFile.message);
                    }
                  } else {
                    message.error("Ha ocurrido un error");
                  }
                }
              );
            } else {
              updateInfoShop(variables, updateShop);
            }
          } else {
            const info = new FormData();
            info.append("file", coverPage);
            info.append("upload_preset", cloudinaryUploadPreset);
            await axios
              .post(cloudinaryEndPoint, info, { headers })
              .then((res) => {
                registerShop({
                  variables: {
                    ubigeo_id: sDistrito,
                    name: sNombre,
                    phone_numbers: sTelefonos ? sTelefonos.toString() : null,
                    address: sDireccion,
                    location: null,
                    lat: LatLng.lat || "",
                    lng: LatLng.lng || "",
                    email: sCorreo,
                    contact: sContacto,
                    web: sWeb || null,
                    services: sServicos ? sServicos.toString() : null,
                    products: sProductos ? sProductos.toString() : null,
                    brands: sMarcas ? sMarcas.toString() : null,
                    thumbnail: {
                      create: {
                        url: res.data.secure_url,
                        extension: "png",
                        thumbnail: true,
                      },
                    },
                  },
                });
              })
              .catch(() => message.warning("Error al subir portada"));
          }
        } else {
          message.warn("Por favor, seleccione una portada para la tienda.");
        }
      }
    });
  };

  const handleClose = () => {
    rxToggleShopsModal({ info: null, visible: false });
    setCenter({ lat: -12.0262676, lng: -77.1278634 });
    resetFields();
    setCurrentCoverPage(null);
    setCoverPage(null);
  };

  const handleOnClickMap = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setCenter({ lat, lng });
    setFieldsValue({ sCordenadas: JSON.stringify({ lat, lng }) });
    setZoom(15);
  };

  // portada

  const scaleToFill = (ctx, img) => {
    const canvas = ctx.canvas;
    const scale = Math.min(
      canvas.width / img.width,
      canvas.height / img.height
    );
    const x = canvas.width / 2 - (img.width / 2) * scale;
    const y = canvas.height / 2 - (img.height / 2) * scale;
    ctx.drawImage(img, x, y, img.width * scale, img.height * scale);
  };

  const getBase64 = (img, callback) => {
    const width = 172;
    const height = 131;
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const image = new Image();
      image.src = reader.result;
      image.onload = function () {
        const elem = document.createElement("canvas");
        elem.width = width;
        elem.height = height;
        const ctx = elem.getContext("2d");
        scaleToFill(ctx, image);
        const dataURI = ctx.canvas.toDataURL("image/png", 1.0);
        if (dataURI) {
          callback(dataURI);
        } else {
          message.error("Las medidas de la imagen debe ser 175x131 (px)");
        }
      };
    });
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg";
    if (!isJpgOrPng) {
      message.error("Solo puedes subir archivos tipo JPG/PNG/JPEG!");
    }
    const isLt2M = file.size / 1024 / 1024 <= 2;
    if (!isLt2M) {
      message.error("El archivo debe pesar máximo de 4MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handleChangeFile = (info) => {
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (imageUrl) => {
        setCoverPage(imageUrl);
      });
    }
  };

  const upload = {
    showUploadList: false,
    customRequest: dummyRequest,
    onChange: handleChangeFile,
    beforeUpload: beforeUpload,
    name: "file",
    accept: ".pdf,.png,.jpg,.jpeg,.doc,.docx,.xls,.xlsx",
    multiple: false,
    listType: "text",
  };
  return (
    <Modal
      visible={modalShops && modalShops.visible}
      onCancel={handleClose}
      bodyStyle={{ padding: 0 }}
      footer={null}
      centered
      title={`${
        modalShops.info && modalShops.info.name ? "ACTUALIZAR" : "REGISTRAR"
      } TIENDA`}
      className="gx-mt-3"
    >
      <Spin
        spinning={loading}
        tip={`Cargando datos ${
          modalShops && modalShops.info && modalShops.info.name
        }`}
      >
        <Spin
          spinning={
            (mutationShop && mutationShop[1].loading) ||
            (mutationUpdateShop && mutationUpdateShop[1].loading)
          }
          tip="Grabando"
        >
          <Form
            onSubmit={handleSubmit}
            className="gx-form-row0 gx-text-left"
            hideRequiredMark
          >
            <Row>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Form.Item className="form-control-ec" label="Nombre">
                  {getFieldDecorator("sNombre", {
                    rules: [
                      {
                        required: true,
                        message: "Ingrese Nombre Tienda",
                      },
                    ],
                  })(<Input autoFocus />)}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Form.Item className="form-control-ec" label="Pagina Web">
                  {getFieldDecorator("sWeb", {
                    rules: [
                      {
                        required: false,
                        message: "Ingrese web",
                      },
                    ],
                  })(<Input type="url" placeholder="www.miweb.com" />)}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Form.Item className="form-control-ec" label="Portada">
                  <Upload {...upload} className="gx-p-0 gx-m-0 gx-pointer">
                    <Spin spinning={false}>
                      <img
                        src={
                          coverPage
                            ? coverPage
                            : currentCoverPage
                            ? currentCoverPage
                            : emptyThumbnail
                        }
                        alt="coverPage"
                        style={{
                          color: "#f56a00",
                          backgroundColor: "#fde3cf",
                          border: "1px dashed #f56a00",
                          cursor: "pointer",
                          width: 175,
                          height: 131,
                        }}
                      />
                    </Spin>
                  </Upload>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Form.Item className="form-control-ec" label="Telefonos">
                  {getFieldDecorator("sTelefonos", {
                    rules: [
                      {
                        required: true,
                        message: "Ingrese Telefonos Tienda",
                      },
                    ],
                  })(
                    <Select
                      mode="tags"
                      style={{ width: "100%" }}
                      tokenSeparators={[","]}
                      dropdownClassName="gx-d-none"
                      placeholder="Separar por comas"
                    />
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Form.Item className="form-control-ec" label="Dirección">
                  {getFieldDecorator("sDireccion", {
                    rules: [
                      {
                        required: true,
                        message: "Ingrese Dirección Tienda",
                      },
                    ],
                  })(<Input onBlur={handleOnBlurAddress} />)}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Form.Item className="form-control-ec" label="Distrito">
                  {getFieldDecorator("sDistrito", {
                    rules: [
                      {
                        required: true,
                        message: "Ingrese Distrito Tienda",
                      },
                    ],
                  })(
                    <Select
                      showSearch
                      allowClear
                      onSearch={handleOnSearch}
                      filterOption={(input, option) => {
                        const regExp = new RegExp(input, "gi");
                        return option.props.district.match(regExp);
                      }}
                      onSelect={handleSelectDistrict}
                      notFoundContent={
                        <Spin spinning={locationLoading}>
                          <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description="Buscar distrito..."
                          />
                        </Spin>
                      }
                    >
                      {Array.isArray(locations) &&
                        locations.map((location) => (
                          <Select.Option
                            value={location.id}
                            key={location.id}
                            district={location.district}
                            address={`${location.district}, ${location.province}, ${location.department}`}
                          >
                            {`${location.district}/${location.province}/${location.department}`}
                          </Select.Option>
                        ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Form.Item className="form-control-ec" label="Cordenadas">
                  {getFieldDecorator("sCordenadas", {
                    rules: [
                      {
                        required: true,
                        message: "Ingrese Cordenadas Tienda",
                      },
                    ],
                  })(<Input readOnly />)}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24}>
                <GoogleMapConfig
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={
                    <div style={{ height: 200, width: "100%" }} />
                  }
                  mapElement={<div style={{ height: `100%` }} />}
                  center={center}
                  zoom={zoom}
                  options={options}
                  handleOnClickMap={handleOnClickMap}
                />
              </Col>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Form.Item className="form-control-ec" label="Correo">
                  {getFieldDecorator("sCorreo", {
                    rules: [
                      {
                        required: false,
                        message: "Ingrese Correo Tienda",
                      },
                    ],
                  })(<Input type="email" />)}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Form.Item className="form-control-ec" label="Contacto">
                  {getFieldDecorator("sContacto", {
                    rules: [
                      {
                        required: false,
                        message: "Ingrese Contacto Tienda",
                      },
                    ],
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Form.Item
                  className="form-control-ec"
                  label="Horario de atencón"
                >
                  {getFieldDecorator("sAtencion", {
                    rules: [
                      {
                        required: false,
                        message: "Ingrese Horario de atención",
                      },
                    ],
                  })(<Input placeholder="L-V 8:00am - 6:00pm" />)}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Form.Item
                  className="form-control-ec"
                  label="Servicios que realiza"
                >
                  {getFieldDecorator("sServicos", {
                    rules: [
                      {
                        required: false,
                        message: "Seleccione servicios",
                      },
                    ],
                  })(
                    <Select
                      mode="tags"
                      style={{ width: "100%" }}
                      tokenSeparators={[","]}
                    >
                      {aServices &&
                        aServices.map((service, index) => (
                          <Option value={service} key={index}>
                            {service}
                          </Option>
                        ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Form.Item className="form-control-ec" label="Productos">
                  {getFieldDecorator("sProductos", {
                    rules: [
                      {
                        required: false,
                        message: "Seleccione producto",
                      },
                    ],
                  })(
                    <Select
                      mode="tags"
                      style={{ width: "100%" }}
                      tokenSeparators={[","]}
                    >
                      {aProducts &&
                        aProducts.map((product, index) => (
                          <Option value={product} key={index}>
                            {product}
                          </Option>
                        ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Form.Item className="form-control-ec" label="Marcas">
                  {getFieldDecorator("sMarcas", {
                    rules: [
                      {
                        required: false,
                        message: "Seleccione Marca",
                      },
                    ],
                  })(
                    <Select
                      mode="tags"
                      style={{ width: "100%" }}
                      tokenSeparators={[","]}
                    >
                      {aBrands &&
                        aBrands.map((brand, index) => (
                          <Option value={brand} key={index}>
                            {brand}
                          </Option>
                        ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24}>
                <div className="gx-d-flex gx-justify-content-end">
                  <Button htmlType="reset" onClick={handleClose}>
                    Cancelar
                  </Button>
                  <Button htmlType="submit" type="primary">
                    Grabar
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Spin>
    </Modal>
  );
};

const WrappedStoreForm = Form.create({ name: "store_form" })(StoreForm);
const mapStateToProps = ({ shops }) => {
  const { modalShops } = shops;
  return { modalShops };
};

const mapDispatchToProps = { rxToggleShopsModal, rxSetListShop };

export default connect(mapStateToProps, mapDispatchToProps)(WrappedStoreForm);
