import React, {Fragment} from 'react';
import {Icon, Result, Button} from 'antd';
import {Link, useHistory} from 'react-router-dom';

export const spinner = <Icon type="loading"/>;

export const Error500 = () => {
  const history = useHistory();
  return (
    <Result
      status="500"
      title="500"
      subTitle="¡Oh no!, algo salió mal."
      extra={(
        <Fragment>
          <Button type="primary">
            <Link to="/">
              Ir a inicio
            </Link>
          </Button>
          <Button onClick={() => history.goBack()}>Atrás</Button>
        </Fragment>
      )}
    />
  );
};

export const Error404 = () => {
  const history = useHistory();
  return (
    <Result
      status="404"
      title="404"
      subTitle="Lo sentimos, la página que visitaste no existe."
      extra={(
        <Fragment>
          <Button type="primary">
            <Link to="/">
              Ir a inicio
            </Link>
          </Button>
          <Button onClick={() => history.goBack()}>Atrás</Button>
        </Fragment>
      )}
    />
  );
};

export const Undefined = (message = null) => {
  return (
    <span className="gx-text-muted">
      {message || 'No especificado'}
    </span>
  )
};
