import {
  USER_RENIEC_DATA,
  FETCH_START_RENIEC,
  FETCH_SUCCESS_RENIEC,
  FETCH_ERROR_RENIEC,
  FETCH_START_SUNAT,
  FETCH_SUCCESS_SUNAT,
  FETCH_ERROR_SUNAT,
  RESET_INITIAL_STATE_SUNAT,
  FETCH_CHANGE_TYPE_START,
  FETCH_CHANGE_TYPE_SUCCESS,
  FETCH_CHANGE_TYPE_ERROR,
  RESET_INFO_RENIEC,
  START_GET_TIPO_CAMBIO,
  SUCCESS_GET_TIPO_CAMBIO,
  ERROR_GET_TIPO_CAMBIO
} from "../../constants/ActionTypes";

const INIT_STATE = {
  userReniecData: null,
  geting: false,
  error: "",
  message: "",
  errorSunat: "",
  messageSunat: "",
  getingSunat: false,
  dataSunat: null,
  tCambioSunat: null,
  tipocambio: {
    loading: false,
    data: null
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    // Reniec
    case FETCH_START_RENIEC: {
      return { ...state, error: "", message: "", geting: true };
    }
    case FETCH_SUCCESS_RENIEC: {
      return { ...state, error: "", message: action.payload, geting: false };
    }
    case FETCH_ERROR_RENIEC: {
      return { ...state, error: action.payload, message: "", geting: false };
    }
    case USER_RENIEC_DATA: {
      return { ...state, userReniecData: action.payload };
    }
    // Sunat
    case FETCH_START_SUNAT: {
      return {
        ...state,
        errorSunat: "",
        messageSunat: "",
        getingSunat: true
      };
    }
    case FETCH_SUCCESS_SUNAT: {
      return {
        ...state,
        errorSunat: "",
        messageSunat: "",
        getingSunat: false,
        dataSunat: action.payload
      };
    }
    case FETCH_ERROR_SUNAT: {
      return {
        ...state,
        errorSunat: action.payload,
        messageSunat: "",
        getingSunat: false
      };
    }
    case RESET_INITIAL_STATE_SUNAT: {
      return {
        ...state,
        errorSunat: "",
        messageSunat: "",
        getingSunat: false,
        dataSunat: null
      };
    }
    case FETCH_CHANGE_TYPE_START: {
      return {
        ...state,
        tCambioSunat: null
      };
    }
    case FETCH_CHANGE_TYPE_SUCCESS: {
      return {
        ...state,
        tCambioSunat: action.payload
      };
    }
    case FETCH_CHANGE_TYPE_ERROR: {
      return {
        ...state,
        tCambioSunat: null
      };
    }
    case RESET_INFO_RENIEC: {
      return {
        ...state,
        geting: false,
        userReniecData: null
      }
    }
    case START_GET_TIPO_CAMBIO: {
      return {
        ...state,
        tipocambio: {
          loading: true,
          data: null
        }
      }
    }
    case SUCCESS_GET_TIPO_CAMBIO: {
      return {
        ...state,
        tipocambio: {
          loading: false,
          data: action.payload
        }
      }
    }
    case ERROR_GET_TIPO_CAMBIO: {
      return {
        ...state,
        tipocambio: {
          loading: false,
          data: null
        }
      }
    }

    default:
      return state;
  }
};
