import React, {useEffect, useState, useCallback} from 'react'
import {Modal, Spin, message} from 'antd'
import {ContactForm, ProfessionalForm, UbicationForm} from './wizard-forms'
import {useSelector, useDispatch} from 'react-redux'
import {spinner} from '../../../util/customComponents'
import {useMutation} from '@apollo/react-hooks'

import {UPDATE_USER} from "../../.././graphql/mutation/user"
import {updateUserStorage, toggleWizard} from '../../../appRedux/actions'
import {onErrorMessage} from '../../../util/helpers'

const WizardProfileModal = ({visible}) => {
  const {authUser} = useSelector(({auth}) => auth)
  const dispatch = useDispatch()

  const [selectedForm, setSelectedForm] = useState(false)
  const [storageWizardForms, setStorageWizardForms] = useState(null)

  const handleValidateData = useCallback((form, wizardForms = null, user) => {
    const {cellphone, experience_years, experience_resume, specialty, ubigeo, address} = user
    let formSelected = 'empty'
    if (form === 'contact') {
      if (!cellphone && !wizardForms.contact) {
        formSelected = 'contact'
      } else {
        if ((!experience_years || !experience_resume || !specialty) && !wizardForms.professional) {
          formSelected = 'professional'
        } else {
          if ((!ubigeo || !address) && !wizardForms.ubication) {
            formSelected = 'ubication'
          } else {
            formSelected = 'empty'
          }
        }
      }
    }
    if (form === 'professional') {
      if ((!experience_years || !experience_resume || !specialty) && !wizardForms.professional) {
        formSelected = 'professional'
      } else {
        if ((!ubigeo || !address) && !wizardForms.ubication) {
          formSelected = 'ubication'
        } else {
          formSelected = 'empty'
        }
      }
    }
    if (form === 'ubication') {
      if ((!ubigeo || !address) && !wizardForms.ubication) {
        formSelected = 'ubication'
      } else {
        formSelected = 'empty'
      }
    }
    if (formSelected === 'empty') {
      dispatch(toggleWizard(false))
    }
    setSelectedForm(formSelected)
    if (wizardForms) setStorageWizardForms(wizardForms)
  }, [dispatch])

  useEffect(() => {
    const wizardForms = localStorage.getItem('skip_wizard_forms') ? JSON.parse(localStorage.getItem('skip_wizard_forms')) : {contact: false, professional: false, ubication: false}
    let form = !wizardForms.contact ? 'contact' : !wizardForms.professional ? 'professional' : !wizardForms.ubication ? 'ubication' : 'empty'
    if (authUser.cellphone && form === 'contact') {
      if (!authUser.experience_resume || !authUser.experience_years || !authUser.specialty) {
        form = 'professional'
      } else if (!authUser.ubigeo || !authUser.address) {
        form = 'ubication'
      }
    }
    if ((!authUser.experience_resume || !authUser.experience_years || !authUser.specialty) && form === 'professional') {
      if (!authUser.ubigeo || !authUser.address) {
        form = 'ubication'
      } else {
        form = 'professional'
      }
    }
    if (form) handleValidateData(form, wizardForms, authUser)
  }, [authUser, handleValidateData])



  const handleSkipWizardForms = skippedForm => {
    let wizardForms = {...storageWizardForms, [skippedForm]: true}
    localStorage.setItem('skip_wizard_forms', JSON.stringify(wizardForms))
    handleValidateData(skippedForm, wizardForms, authUser)
  }

  const [handleUpdateUser, {loading: updating}] = useMutation(UPDATE_USER, {
    onCompleted({updateUser: updatedUser}) {
      handleValidateData(selectedForm, storageWizardForms, updatedUser)
      message.success('Información actualizada correctamente')
      dispatch(updateUserStorage(updatedUser))
    },
    onError(error) {
      onErrorMessage(error, false, 'warning')
    }
  })

  return (
    <Modal
      visible={visible && selectedForm !== 'empty'}
      closable={false}
      maskClosable={false}
      keyboard={false}
      footer={null}
      centered
    >
      <Spin spinning={!selectedForm || updating} indicator={spinner}>
        {selectedForm === 'contact' && (
          <ContactForm
            user={authUser}
            skip={handleSkipWizardForms}
            handleUpdateUser={handleUpdateUser}
          />
        )}
        {selectedForm === 'professional' &&(
          <ProfessionalForm
            user={authUser}
            skip={handleSkipWizardForms}
            handleUpdateUser={handleUpdateUser}
          />
        )}
        {selectedForm === 'ubication' &&(
          <UbicationForm
            user={authUser}
            skip={handleSkipWizardForms}
            handleUpdateUser={handleUpdateUser}
          />
        )}
      </Spin>
    </Modal>
  )
}

export default WizardProfileModal