import React from "react";
import { Modal } from "antd";
import PropTypes from "prop-types";
import Cotizacion from "../Tables/Cotizacion";

const ModalCotizacion = props => {
  const {
    visible,
    handleOk,
    onCancel,
    loading,
    dataSource,
    savingOrderInProject
  } = props;
  return (
    <Modal
      visible={visible}
      onOk={handleOk}
      onCancel={onCancel}
      closable={false}
      okButtonProps={{
        loading: savingOrderInProject
      }}
      bodyStyle={{ padding: 0 }}
      okText="Cotizar"
      width="50%"
    >
      <Cotizacion dataSource={dataSource} loading={loading} />
    </Modal>
  );
};

ModalCotizacion.propTypes = {
  visible: PropTypes.bool,
  handleOk: PropTypes.func,
  onCancel: PropTypes.func,
  dataSource: PropTypes.array,
  loading: PropTypes.bool,
  savingOrderInProject: PropTypes.bool
};

export default ModalCotizacion;
