// Concurrent Mode example
import React, { useState, useRef, useCallback, Fragment } from "react";
import { withRouter, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Carousel from "react-elastic-carousel";
//import Masonry from "react-masonry-css";
import {
  Button,
  Modal,
  Divider,
  Spin,
  Icon,
  Tooltip,
  message,
  Breadcrumb,
} from "antd";
import EllipsisText from "react-ellipsis-text";
import capitalize from "capitalize";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { userSignIn } from "../../appRedux/actions";

import { PUBLIC_PROJECTS } from "../../graphql/query/project";
import { CREATE_FAVORITE_PROJECT } from "../../graphql/mutation/project";
import { cloudinarySecure } from "../../util/config";
import Cocina from "../../assets/images/proyectos/cocina.png";
import HomeOffice from "../../assets/images/proyectos/homeOffice.png";
import SinFoto from "../../assets/images/sinFoto.png";
import Aluminio from "../../assets/images/proyectos/aluminio.png";
import Melamina from "../../assets/images/proyectos/melamina.png";
import Disenio1 from "../../assets/images/design/disenio1.png";
import Disenio2 from "../../assets/images/design/disenio2.png";
import Disenio3 from "../../assets/images/design/disenio3.png";
import Disenio4 from "../../assets/images/design/disenio4.png";
import Disenio5 from "../../assets/images/design/disenio5.png";
import Disenio6 from "../../assets/images/design/disenio6.png";
import "./styles.less";
import { RANDOM_ADVERTISEMENT } from "../../graphql/query/advertisement";
import Widget from "../../components/Widget";
import { UPDATE_ADVERTISEMENT_CLICK } from "../../graphql/mutation/advertisement";
import Seo from "../../components/seo";
import OptimizerWeb from "./../OptimizerWeb/index";

/*const breakpointColumnsObj = {
  default: 5,
  1750: 4,
  1350: 3,
  700: 3,
  600: 2,
  358: 1,
};*/

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2, itemsToScroll: 2 },
  { width: 768, itemsToShow: 3 },
  { width: 1200, itemsToShow: 4 },
];

const MasonryApp = (props) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [share, shareProject] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  let [items, setItems] = useState([]);

  const { refetch, loading, error } = useQuery(PUBLIC_PROJECTS, {
    fetchPolicy: "network-only",
    variables: { page: pageNumber },
    onCompleted({ publicProjects }) {
      setHasMore(publicProjects.paginatorInfo.hasMorePages);
      setItems(items.concat(publicProjects.data));
    },
    onError({ graphQLErrors, networkError }) {
      console.log(graphQLErrors, networkError);
    },
  });

  useQuery(RANDOM_ADVERTISEMENT, {
    fetchPolicy: "network-only",
    variables: {
      first: 1,
      where: {
        column: "LOCATION",
        operator: "EQ",
        value: "H",
      },
    },
    onCompleted({ randomAdvertisement }) {
      if (randomAdvertisement.data.length > 0) {
        setItems(
          items.concat({ ...randomAdvertisement.data[0], advertisement: true })
        );
      }
    },
    onError() {
      message.error("Ha ocurrido un error al obtener los anuncios");
    },
  });

  const observer = useRef();
  const lastItemElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
          refetch();
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore, refetch]
  );

  const showProject = (item) => {
    props.history.push("/proyecto/" + btoa(item.id));
  };

  const [handleUpdateClicks] = useMutation(UPDATE_ADVERTISEMENT_CLICK, {
    errorPolicy: "ignore",
  });

  const visitWebSite = async (advertisement) => {
    const click = parseInt(advertisement.click) + 1;
    const variables = {
      id: advertisement.id,
      input: { click },
    };
    window.open(advertisement.redirect_url, "_blank");
    await handleUpdateClicks({ variables }).then(() => {
      const filteredIndex = items.findIndex((item) => item.advertisement);
      items[filteredIndex]["click"] = click;
    });
  };

  return (
    <div className="container gx-mb-3 gx-p-3 gx-p-md-4">
      <Seo />

      <div className="container-proyectos">
        <div>
          <p>Proyectos por ambiente</p>
        </div>

        <div className="proyectos">
          <div className="cont-proyecto">
            <Link to="/proyectos-general/sala">
              <img
                className="img-proyecto"
                src={Disenio2}
                alt="Sala"
                title="Sala"
              />
              <div className="content">
                <span>Sala</span>
              </div>
            </Link>
          </div>
          <div className="cont-proyecto">
            <Link to="/proyectos-general/cocina">
              <img
                className="img-proyecto"
                src={Cocina}
                alt="Cocina"
                title="Cocina"
              />
              <div className="content">
                <span>Cocina</span>
              </div>
            </Link>
          </div>

          <div className="cont-proyecto">
            <Link to="/proyectos-general/dormitorio">
              <img
                className="img-proyecto"
                src={Disenio5}
                alt="Dormitorio"
                title="Dormitorio"
              />
              <div className="content">
                <span>Dormitorio</span>
              </div>
            </Link>
          </div>

          <div className="cont-proyecto">
            <Link to="/proyectos-general/centro de trabajo">
              <img
                className="img-proyecto"
                src={HomeOffice}
                alt="HomeOffice"
                title="HomeOffice"
              />
              <div className="content">
                <span>Home Office</span>
              </div>
            </Link>
          </div>

          <div className="cont-proyecto">
            <Link to="/proyectos-general/baño">
              <img
                className="img-proyecto"
                src={Disenio3}
                alt="Banio"
                title="Banio"
              />
              <div className="content">
                <span>Baño</span>
              </div>
            </Link>
          </div>
        </div>
      </div>

      {/* Optimizador */}
      <div className="container-proyectos gx-mt-3">
        <div>
          <p>Optimizador</p>
        </div>
        <OptimizerWeb isBanner={true} />
      </div>

      <div className="container-especialistas" style={{ display: "none" }}>
        <div>
          <p>Últimos especialistas</p>
        </div>

        <div className="especialistas">
          <div className="cont-especialista">
            <img
              className="img-especialista"
              src={SinFoto}
              alt="Especialista"
              title="Especialista"
            />
            <div className="datos-esp">
              <h3>Nombre</h3>
              <span>Proyectos</span>
              <span>Descripción del proyecto.</span>
            </div>
          </div>
          <div className="cont-especialista">
            <img
              className="img-especialista"
              src={SinFoto}
              alt="Especialista"
              title="Especialista"
            />
            <div className="datos-esp">
              <h3>Nombre</h3>
              <span>Proyectos</span>
              <span>Descripción del proyecto</span>
            </div>
          </div>
          <div className="cont-especialista">
            <img
              className="img-especialista"
              src={SinFoto}
              alt="Especialista"
              title="Especialista"
            />
            <div className="datos-esp">
              <h3>Nombre</h3>
              <span>Proyectos</span>
              <span>Descripción del proyecto</span>
            </div>
          </div>
          <div className="cont-especialista">
            <img
              className="img-especialista"
              src={SinFoto}
              alt="Especialista"
              title="Especialista"
            />
            <div className="datos-esp">
              <h3>Nombre</h3>
              <span>Proyectos</span>
              <span>Descripción del proyecto</span>
            </div>
          </div>
        </div>
      </div>

      <div className="container-nuevosproyectos">
        <div className="nuevos-proyectos">
          <div>
            <p>Nuevos proyectos</p>
          </div>
          <div>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/proyectos-general/tv" className="ir-proyecto">
                  TV
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/proyectos-general/comedor" className="ir-proyecto">
                  COMEDORES
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/proyectos-general/sofa" className="ir-proyecto">
                  SOFÁS
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/proyectos-general/ropero" className="ir-proyecto">
                  ROPEROS
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/proyectos-general" className="ir-proyecto">
                  VER MÁS
                </Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>

        <div className="cont-proyectos">
          <Modal
            footer={false}
            centered
            visible={share}
            onCancel={() => shareProject(false)}
            className="gx-text-center"
          >
            <h2>Compartir</h2>
            <div className="gx-mt-4 gx-mb-2">
              <Button
                size="large"
                shape="circle"
                className="gx-mb-0 gx-bg-blue gx-border-blue gx-text-white"
              >
                <i className="fab fa-facebook-f" />
              </Button>
              <Button
                size="large"
                shape="circle"
                className="gx-mb-0 gx-text-white"
                style={{
                  backgroundColor: "#00ACED",
                  border: "1px solid #00ACED",
                }}
              >
                <i className="fab fa-twitter" />
              </Button>
              <Button
                size="large"
                shape="circle"
                className="gx-mb-0 gx-text-white"
                style={{
                  backgroundColor: "#D12798",
                  border: "1px solid #D12798",
                }}
              >
                <i className="fab fa-instagram" />
              </Button>
              <Button
                size="large"
                shape="circle"
                className="gx-mb-0 gx-text-white"
                style={{
                  backgroundColor: "#25CD64",
                  border: "1px solid #25CD64",
                }}
              >
                <i className="fab fa-whatsapp" />
              </Button>
            </div>
          </Modal>
          {items.length > 0 && (
            <Carousel breakPoints={breakPoints}>
              {items.map((item, index) => (
                <div
                  key={index}
                  ref={index + 1 === items.length ? lastItemElementRef : null}
                  onClick={
                    !item.advertisement
                      ? () => showProject(item)
                      : () => visitWebSite(item)
                  }
                >
                  {item.advertisement ? (
                    <div>
                      <Widget
                        styleName="gx-widget-bg"
                        bodyStyle={{ padding: "0" }}
                      >
                        <span className="gx-widget-badge gx-bg-red">
                          ANUNCIO
                        </span>
                        <img
                          src={`${cloudinarySecure}/c_scale,q_auto:eco,w_480/${
                            item.multimedia.url.split(cloudinarySecure)[1]
                          }`}
                          alt="Foto de anuncio"
                          style={{
                            width: "100%",
                          }}
                          className="gx-pointer"
                        />
                      </Widget>
                    </div>
                  ) : item.thumbnail && item.thumbnail.url ? (
                    <div>
                      <CardItem
                        item={item}
                        shareItem={shareProject}
                        {...props}
                      />
                    </div>
                  ) : null}
                </div>
              ))}
            </Carousel>
          )}
          {!error && loading && (
            <Fragment>
              <div className="gx-text-center gx-mb-4 gx-mt-4 gx-d-lg-block gx-d-none">
                <Spin spinning={loading} indicator={<Icon type="loading" />} />
              </div>
              <div
                className="gx-text-center gx-d-lg-none"
                style={{ marginBottom: "60px" }}
              >
                <Spin spinning={loading} indicator={<Icon type="loading" />} />
              </div>
            </Fragment>
          )}
          {error && (
            <div className="gx-text-center">
              Ha ocurrido un error al cargar información
            </div>
          )}
        </div>

        <div className="tipos-proyectos">
          <div className="content">
            <Link to="/proyectos-general/mesa de centro">
              <img
                src={Disenio4}
                alt="Productos de madera"
                title="Productos de madera"
              />
              <div className="description">
                <p>PROYECTOS EN MADERA</p>
              </div>
            </Link>
          </div>

          <div className="content">
            <Link to="/proyectos-general/aluminio">
              <img
                src={Aluminio}
                alt="Productos de aluminio"
                title="Productos de aluminio"
              />
              <div className="description">
                <p>PROYECTOS EN ALUMINIO</p>
              </div>
            </Link>
          </div>

          <div className="content">
            <Link to="proyectos-general/melamina">
              <img
                src={Melamina}
                alt="Productos de melamina"
                title="Productos de melamina"
              />
              <div className="description">
                <p>PROYECTOS EN MELAMINA</p>
              </div>
            </Link>
          </div>
        </div>
      </div>

      <div className="conteiner-disenios">
        <div className="header-disenio">
          <div>
            <p>Ideas para tus diseños</p>
          </div>
          <div>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link
                  to="/proyectos-general/centro de trabajo"
                  className="ir-proyecto"
                >
                  HOME OFFICE
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/proyectos-general/cama" className="ir-proyecto">
                  CAMAS
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/proyectos-general/silla" className="ir-proyecto">
                  SILLAS
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/proyectos-general/terraza" className="ir-proyecto">
                  TERRAZAS
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/proyectos-general" className="ir-proyecto">
                  VER MÁS
                </Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>

        <div className="disenios-fotos">
          <div>
            <img src={Disenio1} alt="Primer diseño" title="Primer diseño" />
          </div>
          <div>
            <img src={Disenio2} alt="Segundo diseño" title="Segundo diseño" />
          </div>
          <div>
            <img src={Disenio3} alt="Tercer diseño" title="Tercer diseño" />
          </div>
          <div>
            <img src={Disenio4} alt="Cuarto diseño" title="Cuarto diseño" />
          </div>
          <div>
            <img src={Disenio5} alt="Quinto diseño" title="Quinto diseño" />
          </div>
          <div>
            <img src={Disenio6} alt="Sexto diseño" title="Sexto diseño" />
          </div>
        </div>
      </div>
    </div>
  );
};

const CardItem = React.memo(
  (props) => {
    const dispatch = useDispatch();
    const { authUser } = useSelector(({ auth }) => auth);
    const { item } = props;

    let favorite = false;

    if (authUser) {
      const { favorites } = authUser;
      const filteredProject = favorites.filter((i) => i.project.id === item.id);
      if (filteredProject.length > 0) {
        favorite = true;
      }
    }

    const shareProject = (e) => {
      e.stopPropagation();
      props.shareItem(props.item);
    };

    const [/* iLikeProject,  */ { loading: makingFavorite }] = useMutation(
      CREATE_FAVORITE_PROJECT,
      {
        onCompleted({ registerFavorite }) {
          if (authUser) {
            let newFavorites = authUser.favorites;
            if (registerFavorite.project.status === true) {
              newFavorites.concat(registerFavorite);
            } else {
              newFavorites.filter(
                (i) => i.project.id !== registerFavorite.project.id
              );
            }
            const newAuthUser = {
              ...authUser,
              favorites: newFavorites,
            };
            localStorage.setItem("user", JSON.stringify(newAuthUser));
            dispatch(userSignIn(newAuthUser));
          }
        },
        onError() {
          message.error("Ha ocurrido un error al marcar como favorito");
        },
      }
    );

    const likeProject = (e) => {
      e.stopPropagation();
      let variables = {
        id: null,
        input: {
          project_id: item.id,
          status: true,
        },
      };
      if (authUser) {
        const { favorites } = authUser;
        const filteredProject = favorites.filter(
          (i) => i.project.id === item.id
        );
        // console.log(filteredProject);
        if (filteredProject.length > 0) {
          variables["id"] = filteredProject[0].id;
          variables["status"] = false;
        }
      }
      //iLikeProject({variables});
    };

    const reportProject = (e) => {
      e.stopPropagation();
      alert("reportar proyecto");
    };

    const saveItem = (e) => {
      e.stopPropagation();
      // console.log("save item into favorites");
    };

    const login = (e) => {
      e.stopPropagation();
      props.history.push("/login");
    };

    const goToProfileCarpenter = (e) => {
      e.stopPropagation();
      props.history.push(`/perfil/especialista/${btoa(item.user.id)}`);
    };

    return (
      <Fragment>
        <div className="tile">
          <img
            className="tile-img"
            src={`${cloudinarySecure}/c_scale,q_auto:low,w_400/${
              item.thumbnail.url.split(cloudinarySecure)[1]
            }`}
            alt="Imagen Destacada"
          />
          <div className="details gx-d-none gx-d-lg-block">
            <div className="actions">
              <div className="gx-d-inline-flex gx-align-items-center buttons">
                <Button
                  size="small"
                  className="gx-mb-0 gx-gb-white gx-border-white gx-text-black gx-d-inline-flex gx-align-items-center"
                  shape="round"
                  onClick={goToProfileCarpenter}
                >
                  <i className="far fa-user-circle gx-mr-2" />
                  {item.user.name}
                </Button>
                {authUser ? (
                  <Button
                    type="danger"
                    size="small"
                    shape="round"
                    className="gx-ml-auto gx-mb-0 gx-mr-0"
                    onClick={(e) => saveItem(e)}
                  >
                    <i className="far fa-thumbtack gx-mr-2" />
                    Guardar
                  </Button>
                ) : (
                  <Button
                    type="danger"
                    size="small"
                    shape="round"
                    className="gx-ml-auto gx-mb-0 gx-mr-0"
                    onClick={(e) => login(e)}
                  >
                    Iniciar sesión
                  </Button>
                )}
                {authUser && (
                  <Button
                    size="small"
                    shape="circle"
                    className="dropdown gx-position-absolute gx-right-0 gx-bottom-0 gx-mb-2 gx-bg-white gx-border-white gx-text-black"
                  >
                    <i className="far fa-ellipsis-h" />
                    <ul className="dropdown-content">
                      <li
                        onClick={(e) =>
                          !makingFavorite ? likeProject(e) : null
                        }
                      >
                        {makingFavorite ? (
                          <Icon type="loading" className="gx-mr-2" />
                        ) : (
                          <i
                            className={`gx-mr-2 ${
                              favorite ? "gx-text-danger fa" : "far"
                            } fa-heart`}
                          />
                        )}
                        Me gusta
                      </li>
                      <li onClick={(e) => shareProject(e)}>
                        <i className="far fa-share gx-mr-2" />
                        Compartir
                      </li>
                      <Divider className="gx-m-0 gx-p-0" />
                      <li onClick={(e) => reportProject(e)}>
                        <i className="far fa-bullhorn gx-mr-2" />
                        Reportar
                      </li>
                    </ul>
                  </Button>
                )}
              </div>
              <div className="data">
                <div className="gx-mb-1 gx-fs-sm">
                  <Tooltip title="Visitas">
                    <span>
                      {item.visited}
                      <i className="fa fa-eye gx-ml-1" />
                    </span>
                  </Tooltip>
                  <Tooltip title="Favoritos">
                    <span className="gx-ml-2">
                      {item.favorites}
                      <i className="fa gx-text-danger fa-heart gx-ml-1" />
                    </span>
                  </Tooltip>
                  <Tooltip title="Compartir">
                    <span className="gx-ml-2">
                      {item.shared}
                      <i className="fa fa-share gx-ml-1" />
                    </span>
                  </Tooltip>
                </div>
                <div className="title">
                  <EllipsisText text={capitalize(item.name)} length={30} />
                </div>
                <div className="info">
                  {item.description && (
                    <EllipsisText
                      text={capitalize(item.description)}
                      length={40}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="gx-d-lg-none gx-fs-md gx-text-black gx-mb-0 gx-d-inline-flex gx-align-items-center"
          style={{ width: "100%" }}
        >
          <div className="gx-mb-0 phone-title">
            {item.name}
            <div className="gx-fs-sm">
              <i className="far fa-user-circle gx-mr-1" />
              {item.user.name}
            </div>
          </div>
          <Button
            type="link"
            shape="circle"
            size="small"
            className="dropdown dropdown-sm gx-p-0 gx-m-0 gx-text-black gx-ml-auto"
          >
            <i className="far fa-ellipsis-h" />
            <ul className="dropdown-content">
              <li onClick={(e) => saveItem(e)}>
                <i className="far fa-thumbtack gx-mr-2" />
                Guardar
              </li>
              <li>
                <i className="far fa-heart gx-mr-2" />
                Me gusta
              </li>
              <li onClick={() => shareProject(props.item)}>
                <i className="far fa-share gx-mr-2" />
                Compartir
              </li>
              <Divider className="gx-m-0 gx-p-0" />
              <li>
                <i className="far fa-bullhorn gx-mr-2" />
                Reportar
              </li>
            </ul>
          </Button>
        </div>
      </Fragment>
    );
  },
  (p, n) => p.id === n.id
);

export default withRouter(MasonryApp);

/*<Masonry
              breakpointCols={breakpointColumnsObj}
              className="masonry"
              columnClassName="masonry-grid"
            >
              {items.map((item, index) => (
                <div
                  key={index}
                  ref={index + 1 === items.length ? lastItemElementRef : null}
                  onClick={
                    !item.advertisement
                      ? () => showProject(item)
                      : () => visitWebSite(item)
                  }
                >
                  {item.advertisement ? (
                    <Widget styleName="gx-widget-bg" bodyStyle={{ padding: "0" }}>
                      <span className="gx-widget-badge gx-bg-red">ANUNCIO</span>

                      <img
                        src={`${cloudinarySecure}/c_scale,q_auto:eco,w_480/${item.multimedia.url.split(cloudinarySecure)[1]
                          }`}
                        alt="Foto de anuncio"
                        style={{
                          width: "100%",
                        }}
                        className="gx-pointer"
                      />
                    </Widget>
                  ) : item.thumbnail && item.thumbnail.url ? (
                    <CardItem item={item} shareItem={shareProject} {...props} />
                  ) : null}
                </div>
              ))}
            </Masonry>*/
