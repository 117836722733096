import React, { useState, Fragment } from "react";
import illustration from "../illustrationa.jpg";
import { Row, Col, Button, Alert, message } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useMutation } from "@apollo/react-hooks";
import { TOGGLE_OPTIMIZER } from "../../../graphql/mutation/user";
import { updateUserStorageWithToken } from "../../../appRedux/actions";
import { v4 as uuidv4 } from "uuid";
import { useHistory } from "react-router-dom";
import axios from "../../../util/Api";
import Seo from "../../../components/seo";

const Activation = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { authUser } = useSelector(({ auth }) => auth);

  const [loading, setLoading] = useState(false);

  const [toggleOptimizerMutation] = useMutation(TOGGLE_OPTIMIZER, {
    onCompleted({ toggleOptimizer }) {
      dispatch(updateUserStorageWithToken(toggleOptimizer));
      setLoading(false);
      localStorage.setItem('display_tour_optimizer', true);
      history.push("/usuario/optimizador");
    },
    onError() {
      message.error("Ha ocurrido un error");
      setLoading(false);
    },
  });

  const handleToggleOptimizer = () => {
    setLoading(true);
    const uuid = uuidv4();
    const params = new FormData();
    params.append("email", authUser.email);
    params.append("names", authUser.name);
    params.append("last_names", authUser.last_name);
    params.append("uuid", uuid);
    axios
      .post("/system/replicate-user", params)
      .then(async ({ data }) => {
        const optimizerToken = data.token;
        const optimizerID = data.nIdUsuario;
        localStorage.setItem("optimizer_token", optimizerToken);
        localStorage.setItem("optimizer_id", optimizerID);
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + optimizerToken;
        const status = authUser ? authUser.optimizer : false;
        const variables = {
          id: authUser.id,
          input: {
            optimizer: !status,
            uuid: uuid,
          },
        };
        await toggleOptimizerMutation({ variables });
      })
      .catch(() => {
        message.error("Ha ocurrido un error");
        setLoading(false);
      });
  };

  return (
    <Fragment>
      <Seo
        title="Activar Optimizador"
        description="Con el optimizador de eCarpintero podrás gestionar con facilidad los
        planos de tus proyectos y entre otras funcionalidades."
        url={window.location.href}
      />
      <Row>
        <Col lg={12} md={12}>
          <h1 className="gx-mt-4 gx-fs-xxxl gx-font-weight-semi-bold">
            <i className="far fa-pencil-ruler gx-mr-4" />
            Optimizador
          </h1>
          <p className="gx-fs-lg" style={{ lineHeight: 1.5 }}>
            Con el optimizador de eCarpintero podrás gestionar con facilidad los
            planos de tus proyectos y entre otras funcionalidades.
          </p>
          <Alert
            type="warning"
            message={null}
            description="Aún no tienes activado el optimizador. Haga clic en el botón que se muestra a continuación para activar esta característica."
          />
          <div className="gx-mt-4">
            <Button
              disabled={loading}
              type="primary"
              onClick={handleToggleOptimizer}
            >
              {loading ? "Activando..." : "Activar Optimizador"}
            </Button>
          </div>
        </Col>
        <Col lg={12} md={12}>
          <img
            alt="Optimizador"
            src={illustration}
            className="gx-mb-4 gx-d-none gx-d-lg-block"
            style={{
              right: 0,
              position: "absolute",
              marginRight: "-6.5%",
              zIndex: -1,
              top: "15%",
            }}
          />
        </Col>
      </Row>
      <Row className="gx-d-block gx-d-lg-none">
        <Col span={24}>
          <img
            alt="Optimizador"
            src={illustration}
            style={{
              right: 0,
              position: "fixed",
              marginRight: "-6.5%",
              zIndex: -1,
            }}
          />
        </Col>
      </Row>
    </Fragment>
  );
};

export default Activation;
