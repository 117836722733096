import React from 'react';

const SubscriptionHeader = () => {
  return (
    <>
      <div className='gx-subscription-main-container__title gx-pb-2'>
        Selecciona un plan y empieza a optimizar tu trabajo
      </div>
      <ul className='gx-subscription-main-container__list gx-pb-4'>
        <li>
          <i className='fal fa-shield-check gx-pr-2' />
          Compras seguras
        </li>
        <li>
          <i className='fal fa-credit-card-blank gx-pr-2' />
          Paga solo con tarjeta de credito
        </li>
        <li>
          <i className='fal fa-calendar-star gx-pr-2' />
          Ciclo de pago mensual
        </li>
        <li>
          <i className='fal fa-cut gx-pr-2' />
          Los planes premium no tienen permanencia obligatoria. Cancela cuando quieras
        </li>
      </ul>
    </>
  );
};

export default SubscriptionHeader;
