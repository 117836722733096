import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_FACEBOOK_USER,
  SIGNIN_FACEBOOK_USER_SUCCESS,
  SIGNIN_GITHUB_USER,
  SIGNIN_GITHUB_USER_SUCCESS,
  SIGNIN_GOOGLE_USER,
  SIGNIN_GOOGLE_USER_SUCCESS,
  SIGNIN_TWITTER_USER,
  SIGNIN_TWITTER_USER_SUCCESS,
  SIGNIN_USER,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER,
  SIGNUP_USER_SUCCESS,
  MARK_LATEST_CONNECTION,
  UPDATE_WIZARD_USER_INFO,
  UPDATE_USER_STORAGE,
  UPDATE_USER_STORAGE_WITH_TOKEN,
  SKIP_FILL_INFO,
  FETCH_START,
  FETCH_ERROR,
  CREATED_OPTIMIZER_TOKEN
} from "../../constants/ActionTypes";

import api from '../../util/Api';
import {k, v} from '../../util/config';
import {showErrorInApi} from '../../util/helpers';
import cryptex from 'node-cryptex';

export const updateUserInfoWizard = info => {
  let currentUser = JSON.parse(localStorage.getItem('user'));
  const newAuthUser = {
    ...currentUser,
    reference_address: info.reference_address,
    cellphone: info.cellphone,
    lat: info.lat,
    lng: info.lng
  }
  localStorage.setItem('user', JSON.stringify(newAuthUser));
  return {
    type: UPDATE_WIZARD_USER_INFO,
    payload: newAuthUser
  };
};
export const updateUserStorage = user => {
  localStorage.setItem('user', JSON.stringify(user));
  return {
    type: UPDATE_USER_STORAGE,
    payload: user
  }
};
export const updateUserStorageWithToken = user => {
  localStorage.setItem('user', JSON.stringify(user));
  const optimizerToken = localStorage.getItem('optimizer_token');
  const optimizerID = localStorage.getItem('optimizer_id');
  return {
    type: UPDATE_USER_STORAGE_WITH_TOKEN,
    payload: user,
    token: optimizerToken,
    optimizer_id: optimizerID
  }
};
export const skipRegisterInfo = status => ({
  type: SKIP_FILL_INFO,
  payload: status
});
export const latestConnection = status => {
  return {
    type: MARK_LATEST_CONNECTION,
    payload: status
  }
};
export const userSignUp = (user) => {
  return {
    type: SIGNUP_USER,
    payload: user
  };
};
export const userSignIn = (user) => {
  return {
    type: SIGNIN_USER,
    payload: user
  };
};
export const userSignOut = () => {
  return {
    type: SIGNOUT_USER
  };
};
export const userSignUpSuccess = (authUser) => {
  return {
    type: SIGNUP_USER_SUCCESS,
    payload: authUser
  };
};
export const changeOptimizerToken = uuid => {
  return dispatch => {
    dispatch({ type: FETCH_START })
    const info = new FormData()
    info.set("uuid", uuid)
    api.post("/system/create-optimizer-token", info).then(({ data }) => {
      localStorage.setItem('optimizer_token', data.token)
      localStorage.setItem('optimizer_id', data.nIdUsuario)
      api.defaults.headers.common['Authorization'] = `Bearer ${cryptex.decrypt(data.token, k, v)}`
    }).catch(error => {
      showErrorInApi(error)
      dispatch({ type: FETCH_ERROR, payload: error.message })
    });
  };
}
export const userSignInSuccess = (authUser, token, optimizerToken, optimizerId) => {
  return {
    type: SIGNIN_USER_SUCCESS,
    payload: {authUser, token, optimizerToken, optimizerId}
  }
};
export const createdOptimizerToken = (token, optimizer_id) => {
  return dispatch => {
    dispatch({
      type: CREATED_OPTIMIZER_TOKEN,
      payload: {token, optimizer_id}
    })
  }
}
export const userSignOutSuccess = () => {
  delete api.defaults.headers.common['Authorization'];
  return {
    type: SIGNOUT_USER_SUCCESS,
  }
};

export const showAuthMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};

export const userGoogleSignIn = () => {
  return {
    type: SIGNIN_GOOGLE_USER
  };
};
export const userGoogleSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_GOOGLE_USER_SUCCESS,
    payload: authUser
  };
};
export const userFacebookSignIn = () => {
  return {
    type: SIGNIN_FACEBOOK_USER
  };
};
export const userFacebookSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_FACEBOOK_USER_SUCCESS,
    payload: authUser
  };
};
export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};
export const userTwitterSignIn = () => {
  return {
    type: SIGNIN_TWITTER_USER
  };
};
export const userTwitterSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_TWITTER_USER_SUCCESS,
    payload: authUser
  };
};
export const userGithubSignIn = () => {
  return {
    type: SIGNIN_GITHUB_USER
  };
};
export const userGithubSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_GITHUB_USER_SUCCESS,
    payload: authUser
  };
};
export const showAuthLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};
export const hideAuthLoader = () => {
  return {
    type: ON_HIDE_LOADER,
  };
};
