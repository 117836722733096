import React from "react";
import {Link} from "react-router-dom";
import {withRouter} from 'react-router-dom';
import {Button} from 'antd';

const Error404 = ({history}) => (
  <div className="gx-page-error-container">
    <div className="gx-page-error-content">
      <div className="gx-error-code gx-mb-4">404</div>
      <h2 className="gx-text-center">
        Ups, ha ocurrido un error. ¡Página no encontrada!
      </h2>
      <form className="gx-mb-4" role="search">
        <div className="gx-search-bar">
          <div className="gx-form-group">
            <input type="search" className="ant-input ant-input-lg" placeholder="Buscar..."/>
            <button className="gx-search-icon">
              <i className="icon icon-search"/>
            </button>
          </div>
        </div>
      </form>
      <p className="gx-text-center">
        <Button onClick={() => history.goBack()}>
          <i className="far fa-arrow-left gx-mr-2"/>
          Atrás
        </Button>
        <Button type="primary">
          <Link to="/">
            <i className="far fa-home gx-mr-2"/>
            Inicio
          </Link>
        </Button>
      </p>
    </div>
  </div>
);

export default withRouter(Error404);
