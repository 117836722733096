import {
  FETCH_GENERALS,
  SHOW_MODAL_GENERAL,
  START_SAVING_GENERALS,
  FETCH_SUCCESS_SAVING_GENERALS,
  FETCH_ERROR_SAVING_GENERALS,
  FETCH_START_DELETING_GENERALS,
  FETCH_SUCCESS_DELETING_GENERALS,
  FETCH_ERROR_DELETING_GENERALS,
  FETCH_GENERALS_BY_ENTITY,
  UPDATE_GENERALS,
  DELETE_GENERALS,
  SAVE_GENERALS,
  START_GENERAL_ENTITIES,
  SUCCESS_GENERAL_ENTITIES,
  ERROR_GENERAL_ENTITIES
} from "constants/ActionTypes";

const initialState = {
  modalGenerals: {
    visible: false,
    info: null
  },
  generalsList: [],
  generalsListByEntity: [],
  saving: false,
  updating: false,
  deleting: false,
  message: "",
  error: "",
  selectedId: null,
  generalEntities: {
    loading: false,
    data: []
  }
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case START_SAVING_GENERALS: {
      return { ...state, error: "", message: "", saving: true };
    }
    case FETCH_SUCCESS_SAVING_GENERALS: {
      return {
        ...state,
        error: "",
        message: "",
        saving: false,
        modalGenerals: {
          visible: false
        }
      };
    }
    case FETCH_ERROR_SAVING_GENERALS: {
      return { ...state, error: payload, message: "", saving: false };
    }

    case FETCH_START_DELETING_GENERALS: {
      return {
        ...state,
        error: "",
        message: "",
        deleting: true,
        selectedId: payload
      };
    }
    case FETCH_SUCCESS_DELETING_GENERALS: {
      return {
        ...state,
        error: "",
        message: payload,
        deleting: false,
        selectedId: null
      };
    }
    case FETCH_ERROR_DELETING_GENERALS: {
      return {
        ...state,
        error: payload,
        message: "",
        deleting: false,
        selectedId: null
      };
    }

    //system >configuration >generals
    case FETCH_GENERALS: {
      return {
        ...state,
        generalsList: payload
      };
    }

    case SAVE_GENERALS: {
      return {
        ...state,
        generalsList: state.generalsList.concat(payload)
      };
    }
    case UPDATE_GENERALS: {
      const generalsList = state.generalsListByEntity.filter(i => i.nId !== payload.nId);
      
      const newList = generalsList.concat(payload);
      const sorted = newList.sort((a, b) => {
        return a['nId'] - b['nId']
      });
      return {
        ...state,
        generalsListByEntity: sorted
      };
    }
    case DELETE_GENERALS: {
      return {
        ...state,
        generalsList: state.generalsList.filter(
          general => general.nId !== payload
        )
      };
    }
    case FETCH_GENERALS_BY_ENTITY: {
      return {
        ...state,
        generalsListByEntity: payload
      };
    }

    case START_GENERAL_ENTITIES: {
      return {
        ...state,
        generalEntities: {
          loading: true,
          data: []
        }
      }
    }

    case SUCCESS_GENERAL_ENTITIES: {
      return {
        ...state,
        generalEntities: {
          loading: false,
          data: payload
        }
      }
    }

    case ERROR_GENERAL_ENTITIES: {
      return {
        ...state,
        generalEntities: {
          loading: false,
          data: []
        }
      }
    }

    default:
      return state;

    // Modal > Maestros Generales
    case SHOW_MODAL_GENERAL: {
      if (payload.visible) {
        return {
          ...state,
          modalGenerals: {
            visible: payload.visible,
            info: payload.row
          }
        };
      } else {
        return {
          ...state,
          modalGenerals: {
            visible: payload.visible,
            info: null
          }
        };
      }
    }
  }
};

// H7vmwr9JnqtKYIch