import React, { useState } from "react";
import { connect } from "react-redux";
import { useMutation } from "@apollo/react-hooks";
import { Form, Input, Col, Row, Modal, Spin, Button, notification } from "antd";
import { UPDATE_PASSWORD } from "graphql/mutation/auth";
import { rxToggleModalUpdatePassword, userSignOut } from "appRedux/actions";
import { openNotification } from "util/helpers";

const UpdatePassword = (props) => {
  const { togglePassword } = props;
  const { validateFields, getFieldDecorator, getFieldValue } = props.form;
  const [confirmDirty, setConfirmDirty] = useState(false);
  const [disabledDutton, setDisabledButton] = useState(true);
  
  const [updatePassword, { loading }] = useMutation(UPDATE_PASSWORD, {
    onCompleted() {
      openNotification({
        message: "Contraseña Actualizada",
        description:
          "Para continuar su actividad en eCarpintero, inicie sesión con su nueva contraseña.",
        type: "success",
      });
      signOut();
    },
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) => {
          openNotification({
            message: "[Error]",
            description: (
              <pre>{` Message: ${message}, Location: ${locations}, Path: ${path}`}</pre>
            ),
            type: "error",
          });
        });

      if (networkError)
        openNotification({
          message: "[Network error]",
          description: <pre>{networkError}</pre>,
          type: "error",
        });
    },
  });

  const signOut = () => {
    localStorage.removeItem("token");
    props.rxToggleModalUpdatePassword({ info: null, visible: false });
    props.userSignOut();
  };

  const handleCancel = () => {
    props.rxToggleModalUpdatePassword({ info: null, visible: false });
    props.form.resetFields();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        const { old_password, password, password_confirmation } = values;
        if (old_password && password && password_confirmation) {
          updatePassword({ variables: values });
        } else {
          notification({
            message: "Error",
            description:
              "Todos los campos son obligatorios, para actualizar contraseña",
            type: "error",
          });
        }
      }
    });
  };

  const handleConfirmBlur = ({ target: { value } }) => {
    setConfirmDirty(confirmDirty || !!value);
  };

  const compareToFirstPassword = (rule, value, callback) => {
    if (value && value !== getFieldValue("password")) {
      callback("¡Las contraseñas que ingresas son inconsistentes!");
      setDisabledButton(false);
    } else {
      callback();
    }
  };

  const validateToNextPassword = (rule, value, callback) => {
    if (value.length >= 8) {
      if (value && confirmDirty) {
        validateFields(["password_confirmation"], { force: true });
      }
      callback();
    } else {
      callback();
    }
  };

  return (
    <Modal
      visible={togglePassword && togglePassword.visible}
      onCancel={handleCancel}
      footer={null}
      title="Actualizar contraseña"
    >
      <Spin spinning={loading} tip="Actualizando Contraseña">
        <Form onSubmit={handleSubmit} className="gx-form-row0" hideRequiredMark>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Form.Item className="form-control-ec" label="Contraseña actual">
                {getFieldDecorator("old_password", {
                  rules: [
                    {
                      required: true,
                      message: "Ingrese contraseña actual",
                    },
                  ],
                })(<Input.Password min={0} style={{ width: "100%" }} />)}
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Form.Item className="form-control-ec" label="Nueva contraseña">
                {getFieldDecorator("password", {
                  rules: [
                    {
                      required: true,
                      message: "Ingrese una contraseña con al menos 8 caracteres",
                      min: 8,
                    },
                    {
                      validator: validateToNextPassword,
                    },
                  ],
                })(<Input.Password min={0} style={{ width: "100%" }} />)}
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Form.Item
                className="form-control-ec"
                label="Confirmar nueva contraseña"
              >
                {getFieldDecorator("password_confirmation", {
                  rules: [
                    {
                      required: true,
                      message: "Confirme su contraseña",
                    },
                    {
                      validator: compareToFirstPassword,
                    },
                  ],
                })(
                  <Input.Password
                    onBlur={handleConfirmBlur}
                    style={{ width: "100%" }}
                  />
                )}
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Button
                htmlType="submit"
                disabled={disabledDutton}
                type="primary"
              >
                Actualizar
              </Button>
              <Button onClick={handleCancel}>
                Cancelar
              </Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};

const WrappedUpdatePassword = Form.create({ name: "actualizar_contrasenia" })(
  UpdatePassword
);

const mapStateToProps = ({ user }) => {
  const { togglePassword } = user;
  return {
    togglePassword,
  };
};

const mapDispatchToProps = {
  rxToggleModalUpdatePassword,
  userSignOut,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedUpdatePassword);
