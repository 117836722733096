import React, {Fragment, useEffect, useState} from 'react';
import {Button, message, Modal, Pagination, Skeleton, Spin, Table, Tag, Tooltip} from "antd";
import moment from "moment";
import {useLazyQuery, useMutation} from "@apollo/react-hooks";
import {useHistory} from 'react-router-dom';
import {useSelector, useDispatch} from "react-redux";
import {RETRIEVE_CLIENT_WITH_ADVERTISEMENTS} from "../../../../graphql/query/client";
import {DELETE_ADVERTISEMENT} from "../../../../graphql/mutation/advertisement";
import {setAdvertisementsList, toggleModalAdvertisement} from "../../../../appRedux/actions";
import Auxiliary from "../../../../util/Auxiliary";
import {Error500, spinner, Undefined} from "../../../../util/customComponents";

import AdvertisementForm from '../Form/ads';
import {cloudinarySecure} from "../../../../util/config";

export const AdvertisementClient = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {advertisements, modalAdvertisement} = useSelector(({client}) => client);

  const [loading, setLoading] = useState(true);
  const [parsedID, setParsedID] = useState(null);
  const [infoClient, setInfoClient] = useState(null);
  const [page, setPage] = useState(1);
  const [first, setFirst] = useState(20);
  const [pagination, setPagination] = useState(null);
  const [displayedError, setDisplayError] = useState(false);

  const {params: {id}} = props.match;
  const {visible, info} = modalAdvertisement;

  useEffect(() => {
    try {
      setParsedID(atob(id));
    } catch (e) {
      setDisplayError(true);
      setLoading(false);
    }
  }, [id]);

  const [handleRetrieveClient, {refetch, networkStatus}] = useLazyQuery(RETRIEVE_CLIENT_WITH_ADVERTISEMENTS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted({retrieveClientWithAdvertisement: information}) {
      const dataClient = {
        id: information.id,
        social_reason: information.social_reason
      };
      setInfoClient(dataClient);
      if (information.advertisements) {
        const dataAdvertisements = information.advertisements.data;
        const dataPagination = information.advertisements.paginatorInfo;
        setPagination(dataPagination);
        dispatch(setAdvertisementsList(dataAdvertisements));
      }
      setLoading(false);
    },
    onError() {
      setLoading(false);
      setDisplayError(true);
    }
  });

  useEffect(() => {
    if (parsedID) {
      handleRetrieveClient({variables: {id: parsedID, first, page}});
    }
  }, [parsedID, first, handleRetrieveClient, page]);

  const changePagination = page => {
    setPage(page);
    refetch();
  };

  const changeSizePagination = (current, size) => {
    setFirst(size);
    refetch();
  };

  const [deleteAdvertisement, {loading: deleting}] = useMutation(DELETE_ADVERTISEMENT, {
    onCompleted({deleteAdvertisement}) {
      const filtered = advertisements.filter(advertisement => advertisement.id !== deleteAdvertisement.id);
      dispatch(setAdvertisementsList(filtered));
    },
    onError() {
      message.error("Ha ocurrido un error al intentar eliminar el cliente");
    }
  });

  const handleDeleteAdvertisement = advertisement => {
    const modal = Modal.confirm({
      title: `¿Quieres eliminar este anuncio?`,
      content: `Al hacer clic en el botón Aceptar, el anuncio será eliminado definitivamente.`,
      okText: 'Aceptar',
      cancelText: 'Cancelar',
      okButtonProps: {
        loading: deleting,
        disabled: deleting
      },
      centered: true,
      onOk: async () => {
        modal.update({
          okButtonProps: {
            loading: true,
            disabled: true
          },
          cancelButtonProps: {
            disabled: true
          }
        });
        await deleteAdvertisement({
          variables: {
            id: advertisement.id
          }
        }).then(() => {
          modal.destroy();
        });
      }
    });
  };

  const handleEditAdvertisement = advertisement => {
    toggleModal(true, advertisement.id);
  };

  const handleAddAdvertisement = () => {
    toggleModal(true);
  };

  const toggleModal = (visible, info = null) => {
    dispatch(toggleModalAdvertisement({visible, info}));
  };

  const goClients = () => {
    history.push('/cms/clientes');
  };

  const columns = [
    {
      title: "Ubicación",
      dataIndex: "location",
      key: "location",
      width: "10%",
      render: value => {
        switch (value) {
          case 'H': {
            return 'Principal'
          }
          case 'P': {
            return 'Perfil de Proyecto'
          }
          default:
            return 'Principal'
        }
      }
    },
    {
      title: 'URL Redireccionamiento',
      dataIndex: 'redirect_url',
      key: 'redirect_url',
      width: '30%'
    },
    {
      title: 'Disponibilidad',
      children: [
        {
          title: 'Desde',
          dataIndex: 'from',
          key: 'from',
          width: '20%',
          align: 'center',
          render: value => value ? moment(value).format('YYYY-MM-DD [-] HH:mm A') : Undefined()
        },
        {
          title: 'Hasta',
          dataIndex: 'expire',
          key: 'expire',
          width: '20%',
          align: 'center',
          render: value => value ? moment(value).format('YYYY-MM-DD [-] HH:mm A') : Undefined()
        }
      ]
    },
    {
      title: 'Click',
      dataIndex: 'click',
      index: 'click',
      width: '10%',
      align: 'center'
    },
    {
      title: '',
      dataIndex: null,
      index: 'actions',
      width: '10%',
      align: 'center',
      render: (value, advertisement) => (
        <Fragment>
          <Tooltip title="Editar">
            <Button size="small" className="gx-mb-0 gx-mr-2" onClick={() => handleEditAdvertisement(advertisement)}>
              <i className="far fa-pencil"/>
            </Button>
          </Tooltip>
          <Tooltip title="Eliminar">
            <Button size="small" className="gx-mb-0" onClick={() => handleDeleteAdvertisement(advertisement)}>
              <i className="far fa-trash-alt gx-text-danger"/>
            </Button>
          </Tooltip>
        </Fragment>
      )
    }
  ];

  if (displayedError) return Error500();

  return (
    <Auxiliary>
      <Spin spinning={loading} indicator={spinner}>
        {infoClient ? (
          <Fragment>
            <Table
              showHeader={true}
              title={() => (
                <div className="gx-d-inline-flex gx-align-items-center gx-full-width gx-font-weight-semi-bold">
                  ANUNCIOS PUBLICITARIOS: {infoClient && infoClient.social_reason}
                  <Button
                    size="small"
                    disabled={networkStatus === 4 || loading}
                    className="gx-ml-auto gx-m-0 gx-mr-2"
                    onClick={goClients}
                  >
                    <i className="far fa-arrow-left"/>
                  </Button>
                  <Button disabled={loading} className="gx-m-0 gx-mr-2" size="small" onClick={() => refetch()}>
                    <i className={`far fa-sync-alt ${networkStatus === 4 && 'fa-spin'}`}/>
                  </Button>
                  <Button
                    type="primary"
                    disabled={loading}
                    className="gx-m-0"
                    size="small"
                    onClick={() => handleAddAdvertisement()}
                  >
                    <i className="far fa-plus gx-mr-2"/>
                    Agregar
                  </Button>
                </div>
              )}
              dataSource={advertisements}
              loading={{
                indicator: spinner,
                spinning: loading || networkStatus === 4
              }}
              expandedRowRender={advertisement => {
                let file = null;
                const media = advertisement.multimedia;
                if (advertisement.multimedia) {
                  file = `${cloudinarySecure}/c_scale,q_auto:low,w_250/${media.url.split(cloudinarySecure)[1]}`
                }
                return (
                  <Fragment>
                    {advertisement.tags && (
                      <div className="gx-full-width">
                        {JSON.parse(advertisement.tags).map((tag, index) => (
                          <Tag key={index}>{tag}</Tag>
                        ))}
                      </div>
                    )}
                    <span className="skeleton-avatar" style={{width: '150px', minHeight: '150px', height: '150px'}}>
                      <img src={file} alt="Multimedia"/>
                    </span>
                  </Fragment>
                );
              }}
              rowKey={advertisement => advertisement.id}
              columns={columns}
              bordered
              size="small"
              pagination={false}
              className="gx-table-responsive"
            />
            {pagination && (
              <Pagination
                size="small"
                className="gx-mt-2 gx-float-right"
                defaultCurrent={page}
                showSizeChanger={true}
                locale={{items_per_page: ''}}
                onShowSizeChange={changeSizePagination}
                pageSizeOptions={['5', '10', '20', '50', '100']}
                total={pagination ? pagination.lastPage : null}
                pageSize={first}
                disabled={loading || networkStatus === 4}
                onChange={changePagination}
              />
            )}
          </Fragment>
        ) : (
          <Skeleton
            avatar={null}
            title
            paragraph={{
              rows: 6
            }}
          />
        )}
      </Spin>
      <Modal
        visible={visible}
        centered
        footer={null}
        title={`${visible && info ? 'ACTUALIZAR' : 'REGISTRAR'} ANUNCIO`}
        closable={false}
        keyboard={false}
      >
        {parsedID && visible && (
          <AdvertisementForm
            clientID={parsedID}
            infoID={modalAdvertisement.info}
            refetchAdvertisements={refetch}
          />
        )}
      </Modal>
    </Auxiliary>
  )
}

export default AdvertisementClient;
