import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Button,
  Empty,
  Spin,
  Select,
  message,
  Divider,
  Tooltip,
  Modal,
  Affix,
} from "antd";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useLazyQuery } from "@apollo/react-hooks";

import { GET_TAGS } from "../../graphql/query/master";
import { SEARCH_PUBLIC_PROJECTS } from "../../graphql/query/project";
import { spinner } from "../../util/customComponents";
import {
  queriedTags,
  searchProjects,
  changeVariables,
} from "../../appRedux/actions";
import Masonry from "react-masonry-css";
import EllipsisText from "react-ellipsis-text";
import capitalize from "capitalize";
import { cloudinarySecure } from "../../util/config";
import Seo from "../../components/seo";

const { OptGroup, Option } = Select;

const breakpointColumnsObj = {
  default: 5,
  1750: 4,
  1350: 3,
  700: 3,
  600: 2,
  358: 1,
};

const Search = (props) => {
  const dispatch = useDispatch();
  const { filterProjects, queryTags } = useSelector(({ project }) => project);

  const [filteredTags, setFilteredTags] = useState([]);
  const [share, shareProject] = useState(false);

  const [searchPublicProjects] = useLazyQuery(SEARCH_PUBLIC_PROJECTS, {
    fetchPolicy: "network-only",
    onCompleted({ searchProject }) {
      const records = searchProject.data;
      const paginator = searchProject.paginatorInfo;
      dispatch(
        searchProjects({
          loading: false,
          data: filterProjects.data.concat(records),
          hasMore: paginator.hasMorePages,
        })
      );
    },
    onError() {},
  });

  const filteringProjects = useCallback(
    (values) => {
      let query = "";
      values.map((item) => (query += ` ${item}`));
      const variables = {
        query,
        page: 1,
      };
      dispatch(searchProjects({ loading: true, variables }));
      // console.log(variables, "---variables---");
      searchPublicProjects({ variables });
    },
    [dispatch, searchPublicProjects]
  );

  useEffect(() => {
    if (queryTags.length > 0) {
      filteringProjects(queryTags);
    }
  }, [queryTags, filteringProjects]);

  const [getTags, { loading: loadingTags }] = useLazyQuery(GET_TAGS, {
    fetchPolicy: "network-only",
    onCompleted({ masters }) {
      setFilteredTags(masters);
    },
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        graphQLErrors.map((error) => message.error(error.message));
      }
      if (networkError) {
        message.error(`[Error de Red]: ${networkError}`);
      }
    },
  });

  let timer = 0;

  const searchTag = async (value) => {
    if (value) {
      await clearInterval(timer);
      timer = await setTimeout(() => {
        const variables = {
          where: {
            column: "ENTITY",
            operator: "EQ",
            value: "ETIQUETA",
            AND: [
              {
                column: "DESCRIPTION",
                operator: "LIKE",
                value: `%${value}%`,
              },
            ],
          },
        };
        getTags({ variables });
      }, 300);
    }
  };

  const changeSelectedTags = (values) => {
    dispatch(queriedTags(values));
    if (values.length > 0) {
      filteringProjects(values);
    } else {
      setFilteredTags([]);
      dispatch(
        searchProjects({
          loading: false,
          data: [],
          variables: { query: null, page: 1 },
        })
      );
    }
  };

  const observer = useRef();
  const lastItemElementRef = useCallback(
    (node) => {
      if (filterProjects.loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && filterProjects.hasMore) {
          let variables = filterProjects.variables;
          variables.page = variables.page + 1;
          dispatch(changeVariables(variables));
          const data = queryTags.length > 0 ? filterProjects.data : [];
          dispatch(searchProjects({ loading: true, data, variables }));
          searchPublicProjects({ variables });
        }
      });
      if (node) observer.current.observe(node);
    },
    [filterProjects, dispatch, queryTags, searchPublicProjects]
  );

  const showProject = (item) => {
    props.history.push("/proyecto/" + btoa(item.id));
  };

  return (
    <div className="gx-search-mobile">
      <Seo
        title="Encuentra lo que buscas"
        url={window.location.href}
        description={`Buscar proyectos de profesional calificados.`}
        keywords="buscar"
      />

      <Modal
        footer={false}
        centered
        visible={share}
        onCancel={() => shareProject(false)}
        className="gx-text-center"
      >
        <h2>Compartir</h2>
        <div className="gx-mt-4 gx-mb-2">
          <Button
            size="large"
            shape="circle"
            className="gx-mb-0 gx-bg-blue gx-border-blue gx-text-white"
          >
            <i className="fab fa-facebook-f" />
          </Button>
          <Button
            size="large"
            shape="circle"
            className="gx-mb-0 gx-text-white"
            style={{ backgroundColor: "#00ACED", border: "1px solid #00ACED" }}
          >
            <i className="fab fa-twitter" />
          </Button>
          <Button
            size="large"
            shape="circle"
            className="gx-mb-0 gx-text-white"
            style={{ backgroundColor: "#D12798", border: "1px solid #D12798" }}
          >
            <i className="fab fa-instagram" />
          </Button>
          <Button
            size="large"
            shape="circle"
            className="gx-mb-0 gx-text-white"
            style={{ backgroundColor: "#25CD64", border: "1px solid #25CD64" }}
          >
            <i className="fab fa-whatsapp" />
          </Button>
        </div>
      </Modal>
      <Affix offsetTop={52}>
        <div
          className="gx-d-lg-none gx-p-2 gx-d-inline-flex gx-align-items-center"
          style={{ width: "100%", backgroundColor: "#FAFAFA" }}
        >
          <Select
            mode="multiple"
            allowClear
            value={queryTags}
            className="gx-custom-filter"
            onChange={changeSelectedTags}
            onSearch={searchTag}
            autoFocus
            filterOption={false}
            style={{ minWidht: "100%", width: "100%" }}
            placeholder="Buscar proyectos..."
            notFoundContent={
              loadingTags ? <Spin size="small" indicator={spinner} /> : null
            }
            onBlur={() => setFilteredTags([])}
          >
            {filteredTags.length > 0 && (
              <OptGroup label="Resultados de búsqueda">
                {filteredTags.map((tag, tagIndex) => (
                  <Option key={`filtered-${tagIndex}`} value={tag.description}>
                    {tag.description}
                  </Option>
                ))}
              </OptGroup>
            )}
          </Select>
        </div>
      </Affix>

      {filterProjects.data.length > 0 ? (
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="masonry"
          columnClassName="masonry-grid"
          style={{ padding: "0 10px" }}
        >
          {filterProjects.data.map((item, index) => (
            <div
              key={index}
              ref={
                index + 1 === filterProjects.data.length
                  ? lastItemElementRef
                  : null
              }
              onClick={() => showProject(item)}
            >
              <CardItem item={item} shareItem={shareProject} {...props} />
            </div>
          ))}
        </Masonry>
      ) : (
        <Empty className="gx-mt-4" description="No se encontraron registros" />
      )}
      <Spin
        indicator={spinner}
        spinning={filterProjects.loading}
        tip="Buscando..."
        className="gx-mt-4 gx-mb-4 gx-full-width"
      />
    </div>
  );
};

const CardItem = React.memo(
  (props) => {
    const { authUser } = useSelector(({ auth }) => auth);
    const { item } = props;

    const shareProject = (e) => {
      e.stopPropagation();
      props.shareItem(props.item);
    };

    const likeProject = (e) => {
      e.stopPropagation();
      alert("me gusta el proyecto");
    };

    const reportProject = (e) => {
      e.stopPropagation();
      alert("reportar proyecto");
    };

    const saveItem = (e) => {
      e.stopPropagation();
      // console.log("save item into favorites");
    };

    const login = (e) => {
      e.stopPropagation();
      props.history.push("/login");
    };

    const goToProfileCarpenter = (e) => {
      e.stopPropagation();
      props.history.push(`/perfil/especialista/${btoa(item.user.id)}`);
    };

    return (
      <Fragment>
        <Seo
          title="Resultados de busqueda"
          url={window.location.href}
          description={`Resultados de busqueda en eCarpintero.`}
          keywords="resultados"
        />
        <div className="tile">
          <img
            src={`${cloudinarySecure}/c_scale,q_auto:low,w_400/${
              item.thumbnail.url.split(cloudinarySecure)[1]
            }`}
            alt="Imagen Destacada"
          />
          <div className="details gx-d-none gx-d-lg-block">
            <div className="actions">
              <div className="gx-d-inline-flex gx-align-items-center buttons">
                <Button
                  size="small"
                  className="gx-mb-0 gx-gb-white gx-border-white gx-text-black gx-d-inline-flex gx-align-items-center"
                  shape="round"
                  onClick={goToProfileCarpenter}
                >
                  <i className="far fa-user-circle gx-mr-2" />
                  {item.user.name}
                </Button>
                {authUser ? (
                  <Button
                    type="danger"
                    size="small"
                    shape="round"
                    className="gx-ml-auto gx-mb-0 gx-mr-0"
                    onClick={(e) => saveItem(e)}
                  >
                    <i className="far fa-thumbtack gx-mr-2" />
                    Guardar
                  </Button>
                ) : (
                  <Button
                    type="danger"
                    size="small"
                    shape="round"
                    className="gx-ml-auto gx-mb-0 gx-mr-0"
                    onClick={(e) => login(e)}
                  >
                    Iniciar sesión
                  </Button>
                )}
                {authUser && (
                  <Button
                    size="small"
                    shape="circle"
                    className="dropdown gx-position-absolute gx-right-0 gx-bottom-0 gx-mb-2 gx-bg-white gx-border-white gx-text-black"
                  >
                    <i className="far fa-ellipsis-h" />
                    <ul className="dropdown-content">
                      <li onClick={(e) => likeProject(e)}>
                        <i className="far fa-heart gx-mr-2" />
                        Me gusta
                      </li>
                      <li onClick={(e) => shareProject(e)}>
                        <i className="far fa-share gx-mr-2" />
                        Compartir
                      </li>
                      <Divider className="gx-m-0 gx-p-0" />
                      <li onClick={(e) => reportProject(e)}>
                        <i className="far fa-bullhorn gx-mr-2" />
                        Reportar
                      </li>
                    </ul>
                  </Button>
                )}
              </div>
              <div className="data">
                <div className="gx-mb-1 gx-fs-sm">
                  <Tooltip title="Visitas">
                    <span>
                      {item.visited}
                      <i className="fa fa-eye gx-ml-1" />
                    </span>
                  </Tooltip>
                  <Tooltip title="Favoritos">
                    <span className="gx-ml-2">
                      {item.favorites}
                      <i className="fa gx-text-danger fa-heart gx-ml-1" />
                    </span>
                  </Tooltip>
                  <Tooltip title="Compartir">
                    <span className="gx-ml-2">
                      {item.shared}
                      <i className="fa fa-share gx-ml-1" />
                    </span>
                  </Tooltip>
                </div>
                <div className="title">
                  <EllipsisText text={capitalize(item.name)} length={30} />
                </div>
                <div className="info">
                  {item.description && (
                    <EllipsisText
                      text={capitalize(item.description)}
                      length={40}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="gx-d-lg-none gx-fs-md gx-text-black gx-mb-0 gx-d-inline-flex gx-align-items-center gx-full-width">
          <div className="gx-mb-0 phone-title">
            {item.name}
            <div className="gx-fs-sm">
              <i className="far fa-user-circle gx-mr-1" />
              {item.user.name}
            </div>
          </div>
          <Button
            type="link"
            shape="circle"
            size="small"
            className="dropdown dropdown-sm gx-p-0 gx-m-0 gx-text-black gx-ml-auto"
          >
            <i className="far fa-ellipsis-h" />
            <ul className="dropdown-content">
              <li onClick={(e) => saveItem(e)}>
                <i className="far fa-thumbtack gx-mr-2" />
                Guardar
              </li>
              <li>
                <i className="far fa-heart gx-mr-2" />
                Me gusta
              </li>
              <li onClick={() => shareProject(props.item)}>
                <i className="far fa-share gx-mr-2" />
                Compartir
              </li>
              <Divider className="gx-m-0 gx-p-0" />
              <li>
                <i className="far fa-bullhorn gx-mr-2" />
                Reportar
              </li>
            </ul>
          </Button>
        </div>
      </Fragment>
    );
  },
  (p, n) => p.id === n.id
);

export default withRouter(Search);
