import React, { Component, Fragment } from "react";
import {
  Form,
  Input,
  AutoComplete,
  Button,
  Popover,
  Spin,
  Skeleton,
  Tooltip,
  Empty,
} from "antd";
import { hotkeys } from "react-keyboard-shortcuts";
import { connect } from "react-redux";
import {
  getClients,
  searchClients,
  retrieveClientAction,
} from "../../appRedux/actions";
import { spinner } from "../../util/customComponents";

const { Option } = AutoComplete;

class AutoCompleteClient extends Component {
  constructor(props) {
    super(props);
    this.refSearchClient = React.createRef();
    window.addEventListener("keyup", (event) => {
      switch (event.key) {
        case "F2":
          this.refSearchClient.current && this.refSearchClient.current.focus();
          break;
        default:
          break;
      }
    });
    this.state = {
      visible: false,
    };
  }
  componentDidUpdate(prevProps) {
    if (this.props.cliNombre && prevProps.cliNombre !== this.props.cliNombre) {
      // console.log(prevProps.cliNombre, this.props.cliNombre);
      this.props.form.setFields({
        sCliNombre: {
          value: this.props.cliNombre,
        },
      });
    }
  }

  handleOnSearch = (value) => {
    const nIdUsuario = localStorage.getItem("optimizer_id");
    if (value !== "") {
      const valueTrim = value.trim();
      this.props.searchClients(nIdUsuario, valueTrim);
    } else {
      this.props.searchClients(nIdUsuario, "");
    }
  };

  handleOnSelectPatient = (value, option) => {
    if (option) {
      const { children } = option.props;
      const propSearch = children.replace(/,/gi, "");
      this.props.getClients({
        nIdUsuario: this.props.optimizerID,
        sBusqueda: propSearch,
      });
    } else {
      this.props.getClients({
        nIdUsuario: this.props.optimizerID,
        sBusqueda: null,
      });
    }
  };

  handleRetrieveClient = () => {
    this.props.retrieveClientAction(this.props.nIdCliente);
  };

  render() {
    const {
      pattientSearching,
      patientSearchList,
      placeHolder = "Buscar cliente (F2)",
      label = null,
      name = null,
      required = null,
      message = null,
      handleOnSelect = null,
      form = null,
      className = "form-item-imd",
      optionLabelProp = null,
      formItem = true,
      // defaultValue = null,
      infoClient,
      nIdCliente,
      // value
    } = this.props;

    const showInfoClient = (visible) => {
      this.setState({ visible });
      if (visible && nIdCliente) {
        this.props.getInfoClient(nIdCliente);
      }
    };

    return form ? (
      formItem ? (
        <Form.Item className={className} label={label}>
          <Input.Group>
            {form.getFieldDecorator(name, {
              rules: [{ required: true, message: "Campo requerido" }],
            })(
              <AutoComplete
                onSearch={this.handleOnSearch}
                onSelect={(value) => this.props.changeRegisteredCliente(value)}
                style={{ width: "100%" }}
                loading={pattientSearching}
                optionLabelProp="children"
                dataSource={
                  patientSearchList &&
                  patientSearchList.map((patient) => (
                    <Option
                      value={patient.nId.toString()}
                      key={patient.nId}
                      sdireccionfiscal={patient.sDireccionFiscal}
                    >
                      {patient.sNombres}
                    </Option>
                  ))
                }
                notFoundContent={
                  <Spin spinning={pattientSearching}>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  </Spin>
                }
                ref={this.refSearchClient}
              >
                <Input
                  placeholder={placeHolder}
                  style={{ width: "100%" }}
                  suffix={
                    <div
                      className="gx-d-flex gx-align-items-center"
                      style={{ marginTop: "36px" }}
                    >
                      {nIdCliente ? (
                        <Popover
                          width={300}
                          content={
                            <Spin
                              spinning={infoClient.loading}
                              indicator={spinner}
                              tip="Cargando..."
                            >
                              {!infoClient.loading && infoClient.info ? (
                                <Fragment>
                                  <p>
                                    <span className="gx-text-color-primary">
                                      {infoClient.info.sTipoDoc}
                                    </span>
                                    : {infoClient.info.sDocumento}
                                  </p>
                                  <p>
                                    <span className="gx-text-color-primary">
                                      Teléfono
                                    </span>
                                    : {infoClient.info.sTelefono1 || "-"}
                                  </p>
                                  <p>
                                    <span className="gx-text-color-primary">
                                      Correo
                                    </span>
                                    :{" "}
                                    {infoClient.info.sEmail || (
                                      <span className="gx-text-muted">
                                        No especificado
                                      </span>
                                    )}
                                  </p>
                                  <Button
                                    type="primary"
                                    size="small"
                                    className="gx-mb-0"
                                    onClick={() => {
                                      this.setState({ visible: false });
                                      this.handleRetrieveClient();
                                      this.props.handleShowModalRegCliente(
                                        "edit"
                                      );
                                    }}
                                  >
                                    <i className="gx-mr-2 far fa-pencil" />
                                    Editar
                                  </Button>
                                </Fragment>
                              ) : (
                                <Skeleton />
                              )}
                            </Spin>
                          }
                          title="Información de cliente"
                          trigger="click"
                          onVisibleChange={(visible) => showInfoClient(visible)}
                          visible={this.state.visible}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <i className="far fa-info-circle gx-pointer" />
                        </Popover>
                      ) : (
                        <i className="far fa-info-circle gx-pointer gx-text-muted" />
                      )}
                      <Tooltip title="Nuevo Cliente">
                        <i
                          className="far fa-plus gx-ml-3 gx-mr-2 gx-text-primary gx-pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            this.props.handleShowModalRegCliente("register");
                          }}
                        />
                      </Tooltip>
                    </div>
                  }
                />
              </AutoComplete>
            )}
          </Input.Group>
        </Form.Item>
      ) : (
        form.getFieldDecorator(name, {
          rules: [
            {
              required: required,
              message: message || null,
            },
          ],
        })(
          <AutoComplete
            onSearch={this.handleOnSearch}
            onSelect={handleOnSelect}
            placeholder={placeHolder}
            style={{ width: "100%" }}
            loading={pattientSearching}
            optionLabelProp="children"
            allowClear
            dataSource={
              patientSearchList &&
              patientSearchList.map((patient) => (
                <Option
                  value={patient.nId.toString()}
                  key={patient.nId}
                  sdocumento={patient.sDocumento}
                  sdireccionfiscal={patient.sDireccionFiscal}
                >
                  {patient.sNombres}
                </Option>
              ))
            }
            notFoundContent={
              <Spin spinning={pattientSearching}>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </Spin>
            }
          />
        )
      )
    ) : (
      <AutoComplete
        onSearch={this.handleOnSearch}
        onSelect={handleOnSelect}
        style={{ width: "100%" }}
        loading={pattientSearching}
        placeholder={placeHolder}
        allowClear
        optionLabelProp={optionLabelProp}
        dataSource={
          patientSearchList &&
          patientSearchList.map((patient) => (
            <Option
              value={patient.nId.toString()}
              key={patient.nId}
              sdocumento={patient.sDocumento}
              sdireccionfiscal={patient.sDireccionFiscal}
            >
              {patient.sNombres}
            </Option>
          ))
        }
        notFoundContent={
          <Spin spinning={pattientSearching}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </Spin>
        }
      />
    );
  }
}
const mapStateToProps = ({ settings, auth, clients }) => {
  const { pathname } = settings;
  const { selectRoles, permissions, optimizerID } = auth;
  const { pattientSearching, patientSearchList } = clients;

  return {
    pathname,
    selectRoles,
    permissions,
    pattientSearching,
    patientSearchList,
    optimizerID,
  };
};
export default connect(mapStateToProps, {
  getClients,
  retrieveClientAction,
  searchClients,
})(hotkeys(AutoCompleteClient));
