import gql from 'graphql-tag';

export const CREATE_QUOTATION = gql`
  mutation createQuotation($input: InputCreateQuotation!) {
    createQuotation(input: $input) {
      message
      status
      error
    }
  }
`;