import React from "react";
import { Route, Switch } from "react-router-dom";

import Masonry from "./masonry";
import Maps from "./Maps";
// import Users from "./Users";
import ContactUs from "./ContactUs";
import Search from "./search";
import Specialist from "./profile/Specialist";
import Project from "./Project";
import Login from "./login";
import ForgotPassword from "./login/forgot-password";
import Register from "./register";
import EmailVerify from "./register/email-verify";
import Error404 from "./customViews/errorPages/404";
import WrappedResetPasswordForm from "./login/reset-password";
import TermsAndConditions from "./eula/TermsAndConditions";
import PrivacyPolicies from "./eula/PrivacyPolicies";
import WizardSpecialist from "./wizard";
import ProjectsGeneral from './projectsGeneral';
import ERP from "./erp";

const App = ({ match }) => {
  return (
    <div className="gx-main-content-wrapper">
      <Switch>
        <Route path={`${match.url}`} exact component={Masonry} />
        <Route path={`${match.url}login`} exact component={Login} />
        <Route path={`${match.url}forgot-password`} exact component={ForgotPassword} />
        <Route path={`${match.url}reset-password`} exact component={WrappedResetPasswordForm} />
        <Route path={`${match.url}register`} exact component={Register} />
        <Route path={`${match.url}email-verify`} exact component={EmailVerify} />
        <Route path={`${match.url}erp`} exact component={ERP} />

        <Route exac path={`${match.url}mapas/tiendas-carpinteros-cercanos`} component={Maps} />
        <Route path={`${match.url}contactanos`} component={ContactUs} />
        <Route path={`${match.url}mapas`} component={Maps} />
        <Route path={`${match.url}proyecto/:id`} exact component={Project} />
        <Route path={`${match.url}perfil/especialista/:id`} exact component={Specialist} />
        <Route path={`${match.url}search`} exact component={Search} />
        <Route path={`${match.url}terminos-y-condiciones`} exact component={TermsAndConditions} />
        <Route path={`${match.url}politicas-de-privacidad`} exact component={PrivacyPolicies} />
        <Route path={`${match.url}buscar-especialista`} exact component={WizardSpecialist} />

        {/*--------------------------------------------------------------------------------- */}
        <Route path={`${match.url}proyectos-general/:tipo?`} exact component={ProjectsGeneral} />
        {/*--------------------------------------------------------------------------------- */}
        <Route component={Error404} />
      </Switch>
    </div>
  );
};

export default App;
