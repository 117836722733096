import React, { Fragment, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/react-hooks";
import { GET_TAGS } from "../../graphql/query/master";
import {
  Divider,
  Form,
  Spin,
  message,
  Checkbox,
  Button,
  Tooltip,
  Modal,
  Empty,
  Drawer,
} from "antd";
import EllipsisText from "react-ellipsis-text";
import { cloudinarySecure } from "../../util/config";
import Seo from "../../components/seo";
import capitalize from "capitalize";
import { useDispatch, useSelector } from "react-redux";
import { searchProjects } from "../../appRedux/actions";
import "./index.less";

import { SEARCH_PUBLIC_PROJECTS } from "../../graphql/query/project";

const ProjectsGeneral = (props) => {
  const { tipo } = useParams();
  const [records, setRecords] = useState([]);
  const [filteredTags, setFilteredTags] = useState([]);
  const [selectTipo, setSelectTipo] = useState("");
  const [numFotos, setNumFotos] = useState(0);

  //---
  const dispatch = useDispatch();
  const { filterProjects /* queryTags */ } = useSelector(
    ({ project }) => project
  );
  const [share, shareProject] = useState(false);
  //----

  //TRAER LISTA DE VALORES
  const [preloadTags, { loading: loadingTags }] = useLazyQuery(GET_TAGS, {
    fetchPolicy: "network-only",
    variables: {
      where: {
        column: "ENTITY",
        operator: "EQ",
        value: "ETIQUETA",
      },
      order: [
        {
          field: "DESCRIPTION",
          order: "ASC",
        },
      ],
    },
    onCompleted({ masters }) {
      setFilteredTags(masters);
    },
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        graphQLErrors.map((error) => message.error(error.message));
      }
      if (networkError) {
        message.error(`[Error de Red]: ${networkError}`);
      }
    },
  });

  useEffect(() => {
    preloadTags();
    if (tipo) {
      filteringProjects(tipo);
    }
    if (tipo === "todos" && filteredTags.length > 0) {
      let todos = "";
      filteredTags.forEach((tag) => {
        todos += tag.description;
        //todos = todos + " " + tag.description.toLowerCase();
      });

      filteringProjects(todos);
    }
    // eslint-disable-next-line
  }, [preloadTags, filteredTags]);
  //////////////////////////////////////////////////////////////////////////////////////////////////
  const [searchPublicProjects] = useLazyQuery(SEARCH_PUBLIC_PROJECTS, {
    fetchPolicy: "network-only",
    onCompleted({ searchProject }) {
      setRecords(searchProject.data);
      dispatch(
        searchProjects({
          loading: false,
          data: filterProjects.data.concat(records),
        })
      );
    },
    onError() {},
  });
  //console.log(filterProjects, "---filterProjects---")
  //console.log(records, "---records---")

  //--------------------------------------------------------------
  const filteringProjects = (values) => {
    let query = values.toString();
    let queryToString = query.replace(/,/g, " ");
    const variables = {
      query: queryToString,
      page: 1,
    };
    dispatch(searchProjects({ loading: true, variables }));
    searchPublicProjects({ variables });
  };

  const handleSelect = (value) => {
    setSelectTipo(value);
    filteringProjects(value);
    setNumFotos(0);
  };

  //console.log(numFotos, "----Afuera----")
  // const { Option } = Select;

  const goToProfileCarpenter = (value) => {
    props.history.push("/proyecto/" + btoa(value));
  };

  const [visibleDrawer, setVisibleDrawer] = useState(false);
  return (
    <Fragment>
      <div className="banner-proyectos"></div>
      <div className="body-proyectos">
        <span className="proyectos-mostrados gx-fs-xs gx-font-weight-bold">
          {" "}
          Mostrando {records.length !== 0
            ? numFotos + 1
            : records.length} -{" "}
          {records.slice(numFotos, numFotos + 12).length + numFotos} de{" "}
          {records.length}
        </span>

        {/*<Divider style={{ width: "88%", minWidth: "82%" }} />*/}

        <Divider />
        {/*<header className="header-proyectos">
                    <div className="header-filtros">
                        <p className="gx-fs-xl gx-font-weight-bold">FILTROS</p>
                    </div>
                    <div className="header-mostrar">
                        <p className="gx-mr-2 gx-fs-xs gx-font-weight-bold">Mostrar</p>
                        <div>
                            <Select className="gx-fs-xs" defaultValue="12" style={{ width: 50, height: 46, color: "#B4B4B4" }} >
                                <Option className="gx-fs-xs gx-text-black" value="12">12</Option>
                                <Option value=""></Option>
                                <Option value=""></Option>
                            </Select>
                        </div>
                    </div>
                    <div className="header-ordenar">
                        <p className="gx-mr-2 gx-fs-xs gx-font-weight-bold">Ordenar por</p>
                        <div>
                            <Select className="gx-fs-xs" defaultValue="Más recientes" style={{ width: 110, height: 46, color: "#B4B4B4" }} >
                                <Option className="gx-fs-xs gx-text-black" value="Mas recientes">Más recientes</Option>
                                <Option value=""></Option>
                                <Option value=""></Option>
                            </Select>
                        </div>
                    </div>

                </header>*/}

        {/* <Divider className="gx-mt-0" style={{ width: "88%", minWidth: "82%" }} />*/}
        <div className="viewDrawer">
          <Button
            className="gx-w-100"
            type="primary"
            onClick={() => setVisibleDrawer(true)}
          >
            <i class="fas fa-bars"></i>
          </Button>
          <Drawer
            placement="left"
            closable={false}
            onClose={() => setVisibleDrawer(false)}
            visible={visibleDrawer}
          >
            <aside className="aside-proyectos">
              <section className="aside-proyectos__tipos">
                <div className="gx-mb-3">
                  <p className="gx-fs-xl gx-text-white gx-font-weight-bold">
                    Ambientes
                  </p>
                </div>
                <div
                  className="nombre-tipo"
                  onClick={() => handleSelect("sala")}
                >
                  <p
                    className="gx-fs-sm gx-text-white"
                    style={{
                      color: `${selectTipo === "sala" ? "#eebf99" : ""}`,
                    }}
                  >
                    Sala
                  </p>
                </div>
                <div
                  className="nombre-tipo"
                  onClick={() => handleSelect("cocina")}
                >
                  <p
                    className="gx-fs-sm gx-text-white"
                    style={{
                      color: `${
                        selectTipo === "cocina" ? "#eebf99" : "#000000"
                      }`,
                    }}
                  >
                    Cocina
                  </p>
                </div>
                <div
                  className="nombre-tipo"
                  onClick={() => handleSelect("dormitorio")}
                >
                  <p
                    className="gx-fs-sm gx-text-white"
                    style={{
                      color: `${
                        selectTipo === "dormitorio" ? "#eebf99" : "#000000"
                      }`,
                    }}
                  >
                    Dormitorio
                  </p>
                </div>
                <div
                  className="nombre-tipo"
                  onClick={() => handleSelect("baño")}
                >
                  <p
                    className="gx-fs-sm gx-text-white"
                    style={{
                      color: `${selectTipo === "baño" ? "#eebf99" : "#000000"}`,
                    }}
                  >
                    Baño
                  </p>
                </div>
                <div
                  className="nombre-tipo"
                  onClick={() => handleSelect("centro de trabajo")}
                >
                  <p
                    className="gx-fs-sm gx-text-white"
                    style={{
                      color: `${
                        selectTipo === "centro de trabajo"
                          ? "#eebf99"
                          : "#000000"
                      }`,
                    }}
                  >
                    Home Office
                  </p>
                </div>
                <Divider />
              </section>
              <section className="aside-proyectos__form">
                <div className="gx-ml-2 gx-mb-0">
                  <p className="gx-fs-xl gx-font-weight-bold gx-text-white">
                    Proyectos
                  </p>
                </div>
                <div>
                  <Checkbox.Group
                    style={{ fontFamily: "Open Sans", color: "white" }}
                    className="gx-fs-xs gx-d-flex gx-flex-column"
                    options={
                      filteredTags.length > 0
                        ? filteredTags.map((tag) => ({
                            label: tag.description,
                            value: tag.description,
                          }))
                        : []
                    }
                    onChange={filteringProjects}
                    onClick={() => setSelectTipo("")}
                  ></Checkbox.Group>
                </div>
                <Divider />
              </section>
            </aside>
          </Drawer>
        </div>

        <aside className="view-aside aside-proyectos">
          <section className="aside-proyectos__tipos">
            <div className="gx-mb-3">
              <p
                className="gx-fs-xl"
                style={{ fontFamily: "Open Sans", fontWeight: "700" }}
              >
                Ambientes
              </p>
            </div>
            <div className="nombre-tipo" onClick={() => handleSelect("sala")}>
              <p
                className="gx-fs-sm"
                style={{
                  color: `${selectTipo === "sala" ? "#eebf99" : "#000000"}`,
                }}
              >
                Sala
              </p>
            </div>
            <div className="nombre-tipo" onClick={() => handleSelect("cocina")}>
              <p
                className="gx-fs-sm"
                style={{
                  color: `${selectTipo === "cocina" ? "#eebf99" : "#000000"}`,
                }}
              >
                Cocina
              </p>
            </div>
            <div
              className="nombre-tipo"
              onClick={() => handleSelect("dormitorio")}
            >
              <p
                className="gx-fs-sm "
                style={{
                  color: `${
                    selectTipo === "dormitorio" ? "#eebf99" : "#000000"
                  }`,
                }}
              >
                Dormitorio
              </p>
            </div>
            <div className="nombre-tipo" onClick={() => handleSelect("baño")}>
              <p
                className="gx-fs-sm"
                style={{
                  color: `${selectTipo === "baño" ? "#eebf99" : "#000000"}`,
                }}
              >
                Baño
              </p>
            </div>
            <div
              className="nombre-tipo"
              onClick={() => handleSelect("centro de trabajo")}
            >
              <p
                className="gx-fs-sm"
                style={{
                  color: `${
                    selectTipo === "centro de trabajo" ? "#eebf99" : "#000000"
                  }`,
                }}
              >
                Home Office
              </p>
            </div>
            <Divider />
          </section>
          <section className="aside-proyectos__form">
            <div className="gx-ml-4 gx-mb-0 gx-text-black">
              <p
                className="gx-fs-xl"
                style={{ fontFamily: "Open Sans", fontWeight: "700" }}
              >
                Proyectos
              </p>
            </div>
            <div>
              <Checkbox.Group
                className="gx-fx-sm gx-d-flex gx-flex-column"
                options={
                  filteredTags.length > 0
                    ? filteredTags.map((tag) => ({
                        label: tag.description,
                        value: tag.description,
                      }))
                    : []
                }
                onChange={filteringProjects}
                onClick={() => setSelectTipo("")}
              ></Checkbox.Group>
            </div>
            <Divider />
          </section>
        </aside>

        <main className="content-proyectos">
          <div className="main-proyectos-movil">
            <Modal
              footer={false}
              centered
              visible={share}
              onCancel={() => shareProject(false)}
              className="gx-text-center"
            >
              <h2>Compartir</h2>
              <div className="gx-mt-4 gx-mb-2">
                <Button
                  size="large"
                  shape="circle"
                  className="gx-mb-0 gx-bg-blue gx-border-blue gx-text-white"
                >
                  <i className="fab fa-facebook-f" />
                </Button>
                <Button
                  size="large"
                  shape="circle"
                  className="gx-mb-0 gx-text-white"
                  style={{
                    backgroundColor: "#00ACED",
                    border: "1px solid #00ACED",
                  }}
                >
                  <i className="fab fa-twitter" />
                </Button>
                <Button
                  size="large"
                  shape="circle"
                  className="gx-mb-0 gx-text-white"
                  style={{
                    backgroundColor: "#D12798",
                    border: "1px solid #D12798",
                  }}
                >
                  <i className="fab fa-instagram" />
                </Button>
                <Button
                  size="large"
                  shape="circle"
                  className="gx-mb-0 gx-text-white"
                  style={{
                    backgroundColor: "#25CD64",
                    border: "1px solid #25CD64",
                  }}
                >
                  <i className="fab fa-whatsapp" />
                </Button>
              </div>
            </Modal>
            {records.length > 0 ? (
              records.map((p) => (
                <div
                  className="cont-select-proy"
                  onClick={() => goToProfileCarpenter(p.id)}
                >
                  <Divider />
                  <CardItem item={p} shareItem={shareProject} {...props} />
                  <p className="gx-text-sm gx-mb-0 gx-mt-1 gx-font-weight-bold">
                    {p.description}
                  </p>
                  <p className="gx-fs-xs gx-mt-1 gx-font-weight-bold">
                    Material: {"Madera"}
                  </p>
                  <Divider />
                </div>
              ))
            ) : (
              <Empty />
            )}
          </div>

          <div className="main-proyectos">
            <Modal
              footer={false}
              centered
              visible={share}
              onCancel={() => shareProject(false)}
              className="gx-text-center"
            >
              <h2>Compartir</h2>
              <div className="gx-mt-4 gx-mb-2">
                <Button
                  size="large"
                  shape="circle"
                  className="gx-mb-0 gx-bg-blue gx-border-blue gx-text-white"
                >
                  <i className="fab fa-facebook-f" />
                </Button>
                <Button
                  size="large"
                  shape="circle"
                  className="gx-mb-0 gx-text-white"
                  style={{
                    backgroundColor: "#00ACED",
                    border: "1px solid #00ACED",
                  }}
                >
                  <i className="fab fa-twitter" />
                </Button>
                <Button
                  size="large"
                  shape="circle"
                  className="gx-mb-0 gx-text-white"
                  style={{
                    backgroundColor: "#D12798",
                    border: "1px solid #D12798",
                  }}
                >
                  <i className="fab fa-instagram" />
                </Button>
                <Button
                  size="large"
                  shape="circle"
                  className="gx-mb-0 gx-text-white"
                  style={{
                    backgroundColor: "#25CD64",
                    border: "1px solid #25CD64",
                  }}
                >
                  <i className="fab fa-whatsapp" />
                </Button>
              </div>
            </Modal>
            {records.length > 0 ? (
              records.slice(numFotos, numFotos + 12).map((p) => (
                <div
                  className="cont-select-proy"
                  onClick={() => goToProfileCarpenter(p.id)}
                >
                  <CardItem item={p} shareItem={shareProject} {...props} />
                  <p className="gx-text-sm gx-mb-0 gx-mt-1 gx-font-weight-bold">
                    {p.description}
                  </p>
                  <p className="gx-fs-xs gx-mt-1 gx-font-weight-bold">
                    Material: {"Madera"}
                  </p>
                </div>
              ))
            ) : (
              <Spin spinning={loadingTags} />
            )}
          </div>
          <div className="btn-proyectos">
            <Button
              type="primary"
              disabled={!(numFotos <= 12) || numFotos === 0}
              onClick={() => {
                setNumFotos(numFotos - 12);
                //console.log(numFotos, "numFotos")
              }}
            >
              {"<"}
            </Button>
            <Button
              type="primary"
              disabled={2 * numFotos >= records.length || records.length < 12}
              onClick={() => {
                setNumFotos(numFotos + 12);
                //console.log(numFotos, "numFotos")
              }}
            >
              {">"}
            </Button>
          </div>
        </main>
      </div>
    </Fragment>
  );
};

const CardItem = React.memo(
  (props) => {
    const { authUser } = useSelector(({ auth }) => auth);
    const { item } = props;

    const shareProject = (e) => {
      e.stopPropagation();
      props.shareItem(props.item);
    };

    const likeProject = (e) => {
      e.stopPropagation();
      alert("me gusta el proyecto");
    };

    const reportProject = (e) => {
      e.stopPropagation();
      alert("reportar proyecto");
    };

    const saveItem = (e) => {
      e.stopPropagation();
      // console.log("save item into favorites");
    };

    const login = (e) => {
      e.stopPropagation();
      props.history.push("/login");
    };

    const goToProfileCarpenter = (e) => {
      e.stopPropagation();
      props.history.push(`/perfil/especialista/${btoa(item.user.id)}`);
    };

    return (
      <Fragment>
        <Seo
          title="Resultados de busqueda"
          url={window.location.href}
          description={`Resultados de busqueda en eCarpintero.`}
          keywords="resultados"
        />
        <div>
          <div className="tile">
            <img
              src={`${cloudinarySecure}/c_scale,q_auto:low,w_300/${
                item.thumbnail.url.split(cloudinarySecure)[1]
              }`}
              alt="Imagen Destacada"
            />
            <div className="details gx-d-none gx-d-lg-block">
              <div className="actions">
                <div className="gx-d-inline-flex gx-align-items-center buttons">
                  <Button
                    size="small"
                    className="gx-mb-0 gx-gb-white gx-border-white gx-text-black gx-d-inline-flex gx-align-items-center gx-p-1"
                    shape="round"
                    onClick={goToProfileCarpenter}
                  >
                    <i className="far fa-user-circle gx-mr-2" />
                    {item.user.name}
                  </Button>
                  {authUser ? (
                    <Button
                      type="danger"
                      size="small"
                      shape="round"
                      className="gx-ml-auto gx-mb-0 gx-mr-0 gx-p-0"
                      onClick={(e) => saveItem(e)}
                    >
                      <i className="far fa-thumbtack gx-mr-2" />
                      Guardar
                    </Button>
                  ) : (
                    <Button
                      type="danger"
                      size="small"
                      shape="round"
                      className="gx-ml-auto gx-mb-0 gx-mr-0"
                      onClick={(e) => login(e)}
                    >
                      Iniciar sesión
                    </Button>
                  )}
                  {authUser && (
                    <Button
                      size="small"
                      shape="circle"
                      className="dropdown gx-position-absolute gx-right-0 gx-bottom-0 gx-mb-2 gx-bg-white gx-border-white gx-text-black"
                    >
                      <i className="far fa-ellipsis-h" />
                      <ul className="dropdown-content">
                        <li onClick={(e) => likeProject(e)}>
                          <i className="far fa-heart gx-mr-2" />
                          Me gusta
                        </li>
                        <li onClick={(e) => shareProject(e)}>
                          <i className="far fa-share gx-mr-2" />
                          Compartir
                        </li>
                        <Divider className="gx-m-0 gx-p-0" />
                        <li onClick={(e) => reportProject(e)}>
                          <i className="far fa-bullhorn gx-mr-2" />
                          Reportar
                        </li>
                      </ul>
                    </Button>
                  )}
                </div>
                <div className="data">
                  <div className="gx-mb-1 gx-fs-sm">
                    <Tooltip title="Visitas">
                      <span>
                        {item.visited}
                        <i className="fa fa-eye gx-ml-1" />
                      </span>
                    </Tooltip>
                    <Tooltip title="Favoritos">
                      <span className="gx-ml-2">
                        {item.favorites}
                        <i className="fa gx-text-danger fa-heart gx-ml-1" />
                      </span>
                    </Tooltip>
                    <Tooltip title="Compartir">
                      <span className="gx-ml-2">
                        {item.shared}
                        <i className="fa fa-share gx-ml-1" />
                      </span>
                    </Tooltip>
                  </div>
                  <div className="title">
                    <EllipsisText text={capitalize(item.name)} length={30} />
                  </div>
                  <div className="info">
                    {item.description && (
                      <EllipsisText
                        text={capitalize(item.description)}
                        length={40}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="gx-d-lg-none gx-fs-md gx-text-black gx-mb-0 gx-d-inline-flex gx-align-items-center gx-full-width">
            <div className="gx-mb-0 phone-title">
              {item.name}
              <div className="gx-fs-sm">
                <i className="far fa-user-circle gx-mr-1" />
                {item.user.name}
              </div>
            </div>
            <Button
              type="link"
              shape="circle"
              size="small"
              className="dropdown dropdown-sm gx-p-0 gx-m-0 gx-text-black gx-ml-auto"
            >
              <i className="far fa-ellipsis-h" />
              <ul className="dropdown-content">
                <li onClick={(e) => saveItem(e)}>
                  <i className="far fa-thumbtack gx-mr-2" />
                  Guardar
                </li>
                <li>
                  <i className="far fa-heart gx-mr-2" />
                  Me gusta
                </li>
                <li onClick={() => shareProject(props.item)}>
                  <i className="far fa-share gx-mr-2" />
                  Compartir
                </li>
                <Divider className="gx-m-0 gx-p-0" />
                <li>
                  <i className="far fa-bullhorn gx-mr-2" />
                  Reportar
                </li>
              </ul>
            </Button>
          </div>
        </div>
      </Fragment>
    );
  },
  (p, n) => p.id === n.id
);

export default Form.create()(ProjectsGeneral);
