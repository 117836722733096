import React, { useState } from "react";
import { Button, message, Modal } from "antd";
import carpenter from "assets/images/carpenter.svg";
import Subscription from "./Subscription";

const TENANT_HOST_API = process.env.REACT_APP_OPTIMIZER_API_HOST;

// /culqi/subscription/plans/list

const OptimizerWeb = () => {
  const [dataSource, setDataSource] = useState([]);
  const [visible, setVisible] = useState(false);

  /**
   * @param  {boolean} isRegister=true
   */
  const onGoToOtimizer = (isRegister = true) => {
    const HOST = process.env.REACT_APP_OPTIMIZER_HOST;
    const LINK = isRegister
      ? "/register?t=cd6feade5c3e5893d647d144116d74f4&verified=yes"
      : "/login?t=cd6feade5c3e5893d647d144116d74f4";
    window.open(`${HOST}${LINK}`, `_blank`);
  };

  /**
   * Petición a la api de proyectos para traer los planes actuales
   */
  const handleOnShowPlans = async () => {
    try {
      const response = await fetch(
        `${TENANT_HOST_API}/api/v2/system/culqi/subscription/plans/list`
      );
      const data = await response.json();
      setVisible(true);
      setDataSource(data?.records);
    } catch (error) {
      message.error("Error al obtener datos Plan");
    }
  };

  /**
   * Cerrar ventana modal
   */
  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <div className="gx-web-optimizer-container">
      <div className="gx-optimizer-item__first">
        <div className="gx-desc-optimizer">
          Regístrate a eCarpintero optimizador y disfruta de un mes gratis de
          todas nuestras funcionalidades premium.
          <button
            className="gx-pl-2"
            style={{
              background: "transparent",
              border: "none",
              outline: "none",
              textDecoration: "underline",
              color: "#c26e2d",
              cursor: "pointer",
            }}
            onClick={handleOnShowPlans}
          >
            Ver planes
          </button>
          <ul className="gx-p0 gx-mt-2">
            <li>Registra proyectos</li>
            <li>Optimiza tus planos</li>
            <li>Envía cotización por correo</li>
          </ul>
        </div>
        <div className="gx-d-flex gx-flex-column gx-align-items-center gx-desc-actions">
          <div>
            <Button
              type="primary"
              block
              className="gx-text-center"
              size="small"
              onClick={() => onGoToOtimizer(false)}
            >
              INICIAR SESIÓN
            </Button>
            <Button
              type="primary"
              ghost
              block
              size="small"
              onClick={() => onGoToOtimizer(true)}
            >
              REGISTRATE
            </Button>
          </div>
        </div>
      </div>
      <div className="gx-optimizer-item">
        <img
          src={carpenter}
          alt="carpenter"
          className="gx-carpenter-banner-bg"
        />
      </div>
      <Modal
        width="90%"
        style={{ top: 10 }}
        visible={visible}
        onCancel={handleCancel}
        footer={null}
        closable={false}
      >
        <Subscription dataSource={dataSource} />
      </Modal>
    </div>
  );
};

export default OptimizerWeb;
