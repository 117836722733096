import React from "react";
import { Table } from "antd";
import PropTypes from "prop-types";
import TableTitle from "components/TableTitle";

const columns = [
  {
    title: "CODIGO",
    dataIndex: "sCodTablero",
    key: "sCodTablero",
    width: "10%"
  },
  {
    title: "DESCRIPCIÓN",
    dataIndex: "sDescTablero",
    key: "sDescTablero"
  },
  {
    title: "MEDIDAS",
    dataIndex: "sMedidas",
    key: "sMedidas",
    align: "center",
    render: (text, row) => `${row.nAncho}x${row.nLargo}`
  },
  {
    title: "FORMATO",
    dataIndex: "sFormato",
    key: "sFormato",
    align: "center",
    render: text => (text ? text : "-")
  },
  {
    title: "CANTIDAD",
    dataIndex: "nCantidad",
    key: "nCantidad",
    align: "center"
  },
  {
    title: "M2",
    dataIndex: "nM2",
    key: "nM2",
    align: "center",
    render: value => (value ? value.toFixed(2) : "-")
  }
];
const Clientes = props => {
  const { dataSource } = props;
  return (
    <div className="gx-table-responsive">
      <Table
        title={() => <TableTitle title="TABLEROS DEL CLIENTE" />}
        className="gx-mb-3 gx-table-custom-imd"
        size="small"
        bordered
        pagination={false}
        rowKey={(row, dataIndex) => `${dataIndex + 1}_CL`}
        dataSource={dataSource}
        columns={columns}
      />
    </div>
  );
};

Clientes.propTypes = {
  dataSource: PropTypes.array
};

export default Clientes;
