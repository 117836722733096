import gql from 'graphql-tag';

export const FILTER_CLIENTS = gql`
  query filterClients(
    $query: String
    $first: Int!
    $page: Int
  ) {
    filterClients(query: $query, first: $first, page: $page) {
      data {
        id
        document_type {
          id
          description
        }
        document_number
        social_reason
        email
        first_phone_number
        second_phone_number
        address
        ubigeo {
          id
          code
          district
          province
          department
        }
        contact_name
        contact_email
        contact_phone_number
      }
      paginatorInfo {
        hasMorePages
        currentPage
        lastPage
      }
    }
  }
`;

export const RETRIEVE_CLIENT = gql`
  query retrieveClient($id: ID!) {
    retrieveClient(id: $id) {
      id
      document_type {
        id
        description
      }
      document_number
      social_reason
      email
      first_phone_number
      second_phone_number
      address
      ubigeo {
        id
        code
        district
        province
        department
      }
      contact_name
      contact_email
      contact_phone_number
    }
  }
`;

export const RETRIEVE_CLIENT_WITH_ADVERTISEMENTS = gql`
  query retrieveClientWithAdvertisement($id: ID!, $first: Int!, $page: Int!) {
    retrieveClientWithAdvertisement(id: $id) {
      id
      social_reason
      advertisements(first: $first, page:$page) {
        data {
          id
          location
          redirect_url
          from
          expire
          click
          multimedia {
            id
            url
            extension
          }
          tags
        }
        paginatorInfo {
          hasMorePages
          currentPage
          lastPage
        }
      }
    }
  }
`;
