import React, { useEffect, useRef, useState } from "react";
import { Modal, Row, Col, Table } from "antd";
import { connect } from "react-redux";
import { toggleModalMaterial, rxSetDataLeyendTableBoards } from "appRedux/actions";
import withSearchOnTable from "HOC/withSearchOnTable";

const columns = [
  {
    title: "Cod.",
    dataIndex: "sCodTablero",
    key: "sCodTablero",
    width: 60
  },
  {
    title: "Descripción",
    dataIndex: "sDescTablero",
    key: "sDescTablero",
    width: 200
  },
  {
    title: "Ancho",
    dataIndex: "nAncho",
    key: "nAncho",
    align: "center",
    width: 40
  },
  {
    title: "Largo",
    dataIndex: "nLargo",
    key: "nLargo",
    align: "center",
    width: 40
  },
  {
    title: "Espesor",
    dataIndex: "nEspesor",
    key: "nEspesor",
    align: "center",
    width: 40
  },
  {
    title: "Veta",
    dataIndex: "bVeta",
    key: "bVeta",
    align: "center",
    width: 30,
    render: value => (value ? "SI" : "NO")
  },
  {
    title: "1/2",
    dataIndex: "bPermMitad",
    key: "bPermMitad",
    width: 30
  },
  {
    title: "1/4",
    dataIndex: "bPermCuarto",
    key: "bPermCuarto",
    width: 30
  }
];

const Material = ({
  modal_material,
  toggleModalMaterial,
  fetchingBoard,
  listBoards,
  onSearchNow,
  resultSearch,
  getInputSearchProps,
  handleOnSelectBoardInModal,
  rxSetDataLeyendTableBoards,
  listCodeBoards
}) => {
  const refSearch = useRef();

  const [dataSource, setDataSource] = useState([]);
  useEffect(() => {
    refSearch.current && refSearch.current.focus();
    if (modal_material && modal_material.visible && dataSource.length === 0) {
      setTimeout(() => {
        setDataSource(listBoards.length > 0 ? listBoards.slice(0, 10) : []);
      }, 500);
    }
  }, [modal_material, listBoards, dataSource.length]);

  const handleCancel = () => {
    toggleModalMaterial({ visible: false, info: null });
  };

  const handleSelectBoard = (row, index, modal_material) => {
    if (row) {
      handleOnSelectBoardInModal(row, index, modal_material);
      handleGeneraCodeTablero(row);
    }
  };

  /**
   * GENERAR TABLEROS/CODIGO
   */
  const handleGeneraCodeTablero = async $row => {
    if ($row) {
      if (listCodeBoards) {
        const $list = listCodeBoards;
        const $code = await generateCodeTBL($list);
        rxSetDataLeyendTableBoards([
          ...listCodeBoards,
          {
            nLinea: $code,
            nIdTablero: $row.nIdTablero,
            sDescTablero: $row.sDescTablero,
            nStock: $row.nStock,
            nAncho: $row.nAncho,
            nLargo: $row.nLargo,
            bVeta: $row.bVeta,
            bMatCliente: false,
            nEspesor: $row.nEspesor,
            sCodTablero: $row.sCodTablero
          }
        ]);
      }
    }
  };

  /**
   * GENERA CODIGO TABLERO
   */
  const generateCodeTBL = async (list = []) => {
    const $newCode = (list.length) > 0 ? list[list.length - 1].nLinea : null;
    return $newCode + 1;
  };

  return (
    <Modal
      visible={modal_material.visible}
      onCancel={handleCancel}
      title="Tableros"
      footer={null}
      width={"90%"}
      centered
    >
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24}>
          {getInputSearchProps(
            "sDescTablero",
            listBoards,
            "Buscar tablero...",
            refSearch,
            "default"
          )}
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} style={{
          maxHeight: 'calc(100vh - 260px)',
          height: '100%',
          overflowX: 'auto',
          padding: 0
          }}
        >
          <div className="gx-table-responsive">
            <Table
              dataSource={
                resultSearch.length > 0 || onSearchNow === true
                  ? resultSearch
                  : dataSource
              }
              columns={columns}
              pagination={false}
              bordered={true}
              loading={fetchingBoard}
              rowClassName="gx-pointer"
              title={() => (
                <span>
                  <i className="fad fa-mouse gx-pr-1 gx-text-color-primary" />
                  Doble click para seleccionar un Tablero.
                </span>
              )}
              size={"middle"}
              rowKey={row => row.nIdTablero}
              onRow={(row, index) => {
                return {
                  onDoubleClick: event => {
                    event.stopPropagation();
                    handleSelectBoard(row, index, modal_material);
                  }
                };
              }}
            />
          </div>
          <p className="gx-text-muted gx-fs-sm gx-mb-0 gx-mt-2">Todas las medidas están expresadas en milímetros (mm)</p>
        </Col>
      </Row>
    </Modal>
  );
};

const mapStateToProps = ({ projects }) => {
  const { modal_material, fetchingBoard, listBoards, listCodeBoards } = projects;
  return { modal_material, fetchingBoard, listBoards, listCodeBoards };
};

export default connect(mapStateToProps, { toggleModalMaterial, rxSetDataLeyendTableBoards })(
  withSearchOnTable(Material)
);
