import React from "react";
import { Tag } from "antd";
const switchStatus = type => {
  const es = type ? type.toUpperCase() : "X";
  switch (es) {
    case "E":
      return <Tag className="gx-m-0" color="blue">Emitido</Tag>;
    case "F":
      return <Tag className="gx-m-0" color="purple">Facturado</Tag>;
    case "C":
      return <Tag className="gx-m-0" color="red">Cancelado</Tag>;
    case "A":
      return <Tag className="gx-m-0" color="green">Aprobado</Tag>;
    case "O":
      return <Tag className="gx-m-0" color="cyan">Optimizado</Tag>;
    case "P":
      return <Tag className="gx-m-0" color="geekblue">Pedido</Tag>;
    case "R":
      return <Tag className="gx-m-0" color="volcano">Registrado</Tag>;
    default:
      return <Tag className="gx-m-0" color="orange">Sin estado</Tag>;
  }
};
const switchStatusText = type => {
  const esText = type ? type.toUpperCase() : "X";
  switch (esText) {
    case "EMITIDO":
      return <Tag className="gx-m-0" color="blue">Emitido</Tag>;
    case "FACTURADO":
      return <Tag className="gx-m-0" color="purple">Facturado</Tag>;
    case "CANCELADO":
      return <Tag className="gx-m-0" color="red">Cancelado</Tag>;
    case "APROBADO":
      return <Tag className="gx-m-0" color="green">Aprobado</Tag>;
    case "OPTIMIZADO":
      return <Tag className="gx-m-0" color="cyan">Optimizado</Tag>;
    case "PEDIDO":
      return <Tag className="gx-m-0" color="geekblue">Pedido</Tag>;
    case "REGISTRADO":
      return <Tag className="gx-m-0" color="volcano">Registrado</Tag>;
    case "ABIERTO":
      return <Tag className="gx-m-0" color="blue">Abierto</Tag>;
    default:
      return <Tag className="gx-m-0" color="orange">Sin estado</Tag>;
  }
};
export const State = props => {
  const { text, letter = true } = props;
  const status = letter ? switchStatus(text) : switchStatusText(text);
  return status;
};
