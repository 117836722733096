import {
  FETCH_CLIENTS,
  FETCH_SEARCH_PATIENT_START,
  FETCH_SEARCH_PATIENT_SUCCESS,
  FETCH_SEARCH_PATIENT_ERROR,
  START_SAVING_CLIENTS,
  SUCCESS_SAVING_CLIENTS,
  ERROR_SAVING_CLIENTS,
  OBTENER_INFORMACION_ADICONAL_USUARIO_START,
  OBTENER_INFORMACION_ADICONAL_USUARIO_SUCCESS,
  OBTENER_INFORMACION_ADICONAL_USUARIO_ERROR,
  START_GET_INFO_CLIENT,
  ERROR_GET_INFO_CLIENT,
  SUCCESS_GET_INFO_CLIENT,
  CLEAR_RETRIEVE_INFO_CLIENTE,
  UPDATE_SELECTED_CLIENTE
} from "../../constants/ActionTypes";

const initialState = {
  savingClients: false,
  clientsList: [],
  pattientSearching: false,
  appointmentsError: "",
  appointmentsMessage: "",
  patientSearchList: [],
  infoClient: {
    loading: false,
    info: null
  },
  retrieveInfoClient: {
    loading: false,
    info: null
  }
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_SELECTED_CLIENTE: {
      const filteredClientes = state.patientSearchList.map(item => item.nId === payload.nId ? payload : item);
      return {
        ...state,
        patientSearchList: filteredClientes
      }
    }
    case CLEAR_RETRIEVE_INFO_CLIENTE: {
      return {
        ...state,
        retrieveInfoClient: {
          loading: false,
          info: null
        }
      }
    }
    case START_GET_INFO_CLIENT: {
      return {
        ...state,
        retrieveInfoClient: {
          loading: true,
          info: null
        }
      }
    }
    case SUCCESS_GET_INFO_CLIENT: {
      return {
        ...state,
        retrieveInfoClient: {
          loading: false,
          info: payload
        }
      }
    }
    case ERROR_GET_INFO_CLIENT: {
      return {
        ...state,
        retrieveInfoClient: {
          loading: false,
          info: null
        }
      }
    }
    case FETCH_CLIENTS: {
      return {
        ...state,
        clientsList: payload
      }
    }
    case START_SAVING_CLIENTS: {
      return {
        ...state,
        savingClients: true
      }
    }
    case SUCCESS_SAVING_CLIENTS: {
      return {
        ...state,
        savingClients: false,
        //patientSearchList: [payload]
      }
    }
    case ERROR_SAVING_CLIENTS: {
      return {
        ...state,
        savingClients: false
      }
    }
    case FETCH_SEARCH_PATIENT_START: {
      return {
        ...state,
        pattientSearching: true,
        appointmentsError: "",
        appointmentsMessage: "",
        patientSearchList: []
      };
    }
    case FETCH_SEARCH_PATIENT_SUCCESS: {
      return {
        ...state,
        pattientSearching: false,
        appointmentsError: "",
        appointmentsMessage: "",
        patientSearchList: payload
      };
    }
    case FETCH_SEARCH_PATIENT_ERROR: {
      return {
        ...state,
        pattientSearching: false,
        appointmentsError: payload,
        appointmentsMessage: "",
        patientSearchList: []
      };
    }
    case OBTENER_INFORMACION_ADICONAL_USUARIO_START: {
      return {
        ...state,
        infoClient: {
          loading: true,
          info: null
        }
      }
    }
    case OBTENER_INFORMACION_ADICONAL_USUARIO_SUCCESS: {
      return {
        ...state,
        infoClient: {
          loading: false,
          info: payload
        }
      }
    }
    case OBTENER_INFORMACION_ADICONAL_USUARIO_ERROR: {
      return {
        ...state,
        infoClient: {
          loading: false,
          info: null
        }
      }
    }
    default:
      return state;
  }
};
