import {ADVERTISEMENTS_LIST, CLIENTS_LIST, TOGGLE_MODAL_ADVERTISEMENT} from "../../constants/ActionTypes";

export const setClientsList = clients => ({
  type: CLIENTS_LIST,
  payload: clients
});

export const setAdvertisementsList = advertisements => ({
  type: ADVERTISEMENTS_LIST,
  payload: advertisements
});

export const toggleModalAdvertisement = information => ({
  type: TOGGLE_MODAL_ADVERTISEMENT,
  payload: information
});
