import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { Spin, Descriptions, Button, Affix, Empty, Result, Divider } from "antd";

import Helmet from "react-helmet";
import Auxiliary from "util/Auxiliary";

import { rxSelecionarProyectoPlano } from "appRedux/actions";
import crypt from 'node-cryptex'
import {k, v} from 'util/config'
import { colors } from "./colors";
import _ from "lodash";
// import ButtonGroup from "antd/lib/button/button-group";

const titleColor = "#000";
const arrayToObject = (array, keyField) =>
  array.reduce((obj, item) => {
    obj[item[keyField]] = item.sValue;
    return obj;
  }, {});

const ParametersPlan = ({ sParametros }) => {
  const params = arrayToObject(sParametros, "sParamName");
  return Array.isArray(sParametros) ? (
    <div className="ant-table gx-mb-3 gx-mt-2 ant-table-small ant-table-bordered ">
      <div className="ant-table-content">
        <div className="ant-table-body">
          <table className="customized-table">
            <tbody className="ant-table-tbody">
              <tr className="ant-table-row ant-table-row-level-0">
                <td
                  className="gx-text-left gx-pl-1 gx-pr-1 gx-pt-0 gx-pb-0 gx-text-color-primary"
                  colSpan={2}
                >
                  Ancho Sierra:
                  <span className="gx-text-leyend gx-ml-2">
                    {`${params.SawWidth} mm` || "-"}
                  </span>
                </td>
              </tr>
              <tr className="ant-table-row ant-table-row-level-0">
                <td
                  className="gx-text-left gx-pl-1 gx-pr-1 gx-pt-0 gx-pb-0 "
                  colSpan={2}
                >
                  SANEADO
                </td>
              </tr>
              <tr className="ant-table-row ant-table-row-level-0">
                <td className="gx-text-left gx-pl-1 gx-pr-1 gx-pt-0 gx-pb-0 gx-text-color-primary">
                  Izquierda:
                  <span className="gx-ml-2 gx-text-leyend">
                    {`${params.TrimLeft} mm` || "-"}
                  </span>
                </td>
                <td className="gx-text-left gx-pl-1 gx-pr-1 gx-pt-0 gx-pb-0 gx-text-color-primary">
                  Derecha:
                  <span className="gx-ml-2 gx-text-leyend">
                    {`${params.TrimRight} mm` || "-"}
                  </span>
                </td>
              </tr>
              <tr className="ant-table-row ant-table-row-level-0">
                <td className="gx-text-left gx-pl-1 gx-pr-1 gx-pt-0 gx-pb-0 gx-text-color-primary">
                  Superior:
                  <span className="gx-ml-2 gx-text-leyend">
                    {`${params.TrimTop} mm` || "-"}
                  </span>
                </td>
                <td className="gx-text-left gx-pl-1 gx-pr-1 gx-pt-0 gx-pb-0 gx-text-color-primary">
                  Inferior:
                  <span className="gx-ml-2 gx-text-leyend">
                    {`${params.TrimBottom} mm` || "-"}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  ) : null;
};

const TableDetail = ({ wastePart, part, nMedMinResto }) => {
  const grouped = part && _.groupBy(part, "datos");
  const aParts = grouped && _.values(grouped);
  return (
    <Fragment>
      <div className="ant-table gx-mb-3 ant-table-small ant-table-bordered  gx-mt-2">
        <div className="ant-table-content">
          <div className="ant-table-body">
            <table className="customized-table">
              <thead className="ant-table-thead">
                <tr>
                  <th
                    colSpan={5}
                    className="gx-text-left gx-pr-1 gx-pt-0 gx-pb-0 gx-text-color-primary"
                  >
                    PIEZAS
                  </th>
                </tr>
                <tr>
                  <th className="ant-table-column-title gx-pl-1 gx-pr-1 gx-pt-0 gx-pb-0 gx-text-center">
                    ITEM
                  </th>
                  <th className="ant-table-column-title gx-pl-1 gx-pr-1 gx-pt-0 gx-pb-0 gx-text-center">
                    CANT.
                  </th>
                  <th className="ant-table-column-title gx-pl-1 gx-pr-1 gx-pt-0 gx-pb-0 gx-text-center">
                    ANCHO (mm)
                  </th>
                  <th className="ant-table-column-title gx-pl-1 gx-pr-1 gx-pt-0 gx-pb-0 gx-text-center">
                    LARGO (mm)
                  </th>
                </tr>
              </thead>
              <tbody className="ant-table-tbody">
                {Array.isArray(aParts)
                  ? aParts.map((apart, idx) => {
                      return apart && apart.length > 0 ? (
                        apart.map((part, e) =>
                          e === 0 ? (
                            <tr key={e} className="ant-table-row ">
                              <td className="gx-text-center gx-pl-1 gx-pr-1 gx-pt-0 gx-pb-0">
                                ({part["datos"]})
                              </td>
                              <td className="gx-text-center gx-pl-1 gx-pr-1 gx-pt-0 gx-pb-0">
                                {(apart && apart.length) || 0}
                              </td>
                              <td className="gx-text-center gx-pl-1 gx-pr-1 gx-pt-0 gx-pb-0">
                                {part["length"]}
                              </td>
                              <td className="gx-text-center gx-pl-1 gx-pr-1 gx-pt-0 gx-pb-0">
                                {part["width"]}
                              </td>
                            </tr>
                          ) : null
                        )
                      ) : (
                        <tr key={idx} className="ant-table-row ">
                          <td className="gx-text-center gx-pl-1 gx-pr-1 gx-pt-0 gx-pb-0">
                            ({apart["datos"]})
                          </td>
                          <td className="gx-text-center gx-pl-1 gx-pr-1 gx-pt-0 gx-pb-0">
                            1
                          </td>
                          <td className="gx-text-center gx-pl-1 gx-pr-1 gx-pt-0 gx-pb-0">
                            {apart["length"]}
                          </td>
                          <td className="gx-text-center gx-pl-1 gx-pr-1 gx-pt-0 gx-pb-0">
                            {apart["width"]}
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="ant-table ant-table-small ant-table-bordered  gx-mt-2">
        <div className="ant-table-content">
          <div className="ant-table-body">
            <table className="customized-table">
              <thead className="ant-table-thead">
                <tr>
                  <th
                    colSpan={5}
                    className="gx-text-left gx-pr-1 gx-pt-0 gx-pb-0 gx-text-color-primary"
                  >
                    RESTOS
                  </th>
                </tr>
                <tr>
                  <th className="ant-table-column-title gx-pl-1 gx-pr-1 gx-pt-0 gx-pb-0 gx-text-center">
                    PARTE
                  </th>
                  <th className="ant-table-column-title gx-pl-1 gx-pr-1 gx-pt-0 gx-pb-0 gx-text-center">
                    ANCHO (mm)
                  </th>
                  <th className="ant-table-column-title gx-pl-1 gx-pr-1 gx-pt-0 gx-pb-0 gx-text-center">
                    LARGO (mm)
                  </th>
                </tr>
              </thead>
              <tbody className="ant-table-tbody">
                {Array.isArray(wastePart)
                  ? wastePart.map((wpart, idx) =>
                      nMedMinResto &&
                      wpart["length"] > nMedMinResto &&
                      wpart["width"] > nMedMinResto ? (
                        <tr
                          key={idx}
                          className="ant-table-row ant-table-row-level-0"
                        >
                          <td className="gx-text-center gx-pl-1 gx-pr-1 gx-pt-0 gx-pb-0">
                            {wpart["part"]}
                          </td>
                          <td className="gx-text-center gx-pl-1 gx-pr-1 gx-pt-0 gx-pb-0">
                            {wpart["length"]}
                          </td>
                          <td className="gx-text-center gx-pl-1 gx-pr-1 gx-pt-0 gx-pb-0">
                            {wpart["width"]}
                          </td>
                        </tr>
                      ) : null
                    )
                  : null}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

class Planos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nIdProyecto: null,
      nMedMinResto: 300,
      listColors: [],
      bSecCorte: true,
      displayPage: false
    };
  }

  componentDidMount() {
    const { nIdProyecto } = this.props.match.params;
    const { authUser } = this.props;
    if (authUser) {
      this.setState({displayPage: true});
      if (nIdProyecto) {
        const nId = nIdProyecto && JSON.parse(crypt.decrypt(nIdProyecto, k, v));
        this.props.rxSelecionarProyectoPlano(nId);
        this.setState({ nIdProyecto: nId });
      }
    }
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { listDataOptimize } = this.props;
    // sParametros
    if (listDataOptimize !== prevProps.listDataOptimize) {
      if (listDataOptimize) {
        const genColors = [];
        const parameters = (listDataOptimize && listDataOptimize.sParametros) || [];
        parameters.forEach((element, index) => {
          genColors.push({
            pila: `${index + 1}`,
            color: colors[index].hexString
          });
        });
        this.setState({ listColors: genColors });
      }
    }
  }
  
  handleRenderFillRect = pila => {
    const { listColors } = this.state;
    const result = listColors.filter(item => item.pila === pila);
    const stringColor = result.length > 0 ? result[0].color : "#9edfff";
    return stringColor;
  };

  handleOnPrintPlan = () => {
    window.print();
  };
  
  medMinRestoShow = (l = 0, w = 0, type) => {
    const { nMedMinResto } = this.state;
    if (Number(w) < nMedMinResto || Number(l) < nMedMinResto) {
      return null;
    }
    switch (type) {
      case "L":
        return l;
      case "W":
        return w;
      case "LW":
        return `(${w}x${l})`;
      default:
        return "";
    }
  };

  medMinRestoClass = (w = 0, l = 0) => {
    const { nMedMinResto } = this.state;
    if (Number(w) < nMedMinResto || Number(l) < nMedMinResto) {
      return "gx-unscrap";
    }
    return "gx-scrap";
  };

  render() {
    const {
      fetchingPlans,
      listDataOptimize
    } = this.props;
    const { listColors, bSecCorte, nMedMinResto } = this.state;
    const fz = 4;
    const whminima = 60;
    const sPlanos = (listDataOptimize && listDataOptimize.sPlanos) || [];
    const sParametros = (listDataOptimize && listDataOptimize.sParametros) || [];

    if (!this.state.displayPage) {
      return (
        <div className="gx-text-center gx-pt-4">
          <h2>eCarpintero</h2>
          <Result
            status="403"
            title="¡Acceso no autorizado!"
            subTitle="Lo sentimos, no estás autorizado para acceder a esta página."
            extra={(
              <Button type="primary">
                <Link to="/login">Iniciar sesión</Link>
              </Button>
            )}
          />
        </div>
      )
    }

    return (
      <Auxiliary>
        <Helmet>
          <title>
            eCarpintero - Planos
          </title>
        </Helmet>
        {fetchingPlans ? (
          <Spin
            spinning={fetchingPlans}
            tip="Generando plano ..."
            className="gx-print-d-none"
          >
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </Spin>
        ) : (
            <Fragment>
              <div className="gx-d-flex gx-full-width gx-justify-content-center gx-d-lg-none gx-align-items-center gx-my-3">
                <Button
                  icon="printer"
                  type="primary"
                  className="gx-buttons gx-mb-0"
                  size="small"
                  onClick={this.handleOnPrintPlan}
                >
                  Imprimir
                </Button>
                <Button className="gx-buttons gx-mb-0" size="small">
                  <Link to="/usuario/optimizador">Ir a Optimizador</Link>
                </Button>
              </div>
              <div id="container-plans" className="gx-container-planos">
                <svg
                  height="10"
                  width="10"
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  style={{ position: "absolute" }}
                >
                  <defs>
                    <pattern
                      id="anchoShepes"
                      patternUnits="userSpaceOnUse"
                      width="10"
                      height="10"
                    >
                      <image
                        href="data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPScxMCcgaGVpZ2h0PScxMCc+CiAgPHJlY3Qgd2lkdGg9JzEwJyBoZWlnaHQ9JzEwJyBmaWxsPSd3aGl0ZScvPgogIDxwYXRoIGQ9J00tMSwxIGwyLC0yCiAgICAgICAgICAgTTAsMTAgbDEwLC0xMAogICAgICAgICAgIE05LDExIGwyLC0yJyBzdHJva2U9J2JsYWNrJyBzdHJva2Utd2lkdGg9JzEnLz4KPC9zdmc+Cg=="
                        x="0"
                        y="0"
                      ></image>
                    </pattern>
                  </defs>
                </svg>
                {sPlanos.length > 0 && listColors.length > 0 ? (
                  sPlanos.map((tablero, t) => {
                    return (
                      tablero.layout.length > 0 &&
                      tablero.layout.map((lay, ly) => (
                        <Fragment key={ly}>
                          <div
                            className="gx-item-planos"
                            style={{
                              maxWidth: lay.sheetW / fz,
                              width: '100%'
                            }}
                          >
                            <div className="gx-text-center">
                              <Descriptions size="small" column={1}>
                                <Descriptions.Item
                                  label={
                                    <span style={{ color: titleColor }}>
                                      Material
                                    </span>
                                  }
                                >
                                  {lay.material}
                                </Descriptions.Item>
                              </Descriptions>
                              <div>
                                Tablero: {lay.stockCount} <Divider type="vertical"/> Piezas: {lay.partCount} <Divider type="vertical"/> Utilización: {lay.fillRatio ? (lay.fillRatio * 100).toFixed(2): 0}%
                              </div>
                            </div>

                            <div className="gx-d-flex gx-align-items-center gx-justify-content-center">
                              <svg
                                version="1.1"
                                xmlns="http://www.w3.org/1999/svg"
                                viewBox={`-12 -12 ${lay.sheetW / fz + 15} ${lay.sheetH / fz + 15}`}
                                style={{maxWidth: '95vw', width: '100%', marginTop: '15px'}}
                              >
                                <defs>
                                  <marker
                                    id="arrow"
                                    markerWidth="10"
                                    markerHeight="10"
                                    refX="9"
                                    refY="3"
                                    orient="auto"
                                    markerUnits="strokeWidth"
                                  >
                                    <path d="M0,0 L0,6 L9,3 z" fill="#f00" />
                                  </marker>
                                </defs>

                                <g>
                                  <rect
                                    width={lay.sheetW / fz}
                                    height={lay.sheetH / fz}
                                    style={{
                                      fill: "#fff",
                                      stroke: "#000",
                                      strokeWidth: 0
                                    }}
                                  />
                                  <text
                                    x={lay.sheetW / fz / 2}
                                    y={-3}
                                    className="gx-board-size"
                                  >
                                    {lay.sheetW}
                                  </text>
                                  <text
                                    x={-lay.sheetH / fz / 2}
                                    y={-3}
                                    transform="rotate(-90)"
                                    className="gx-board-size"
                                  >
                                    {lay.sheetH}
                                  </text>
                                  {lay.part.length > 0
                                    ? lay.part.map((parte, p) =>
                                        parte.rotated === "True" ? (
                                          <g key={p}>
                                            <rect
                                              x={parte.x / fz}
                                              y={parte.y / fz}
                                              width={parte["length"] / fz}
                                              height={parte["width"] / fz}
                                              fill={this.handleRenderFillRect(
                                                parte.datos
                                              )}
                                              className="gx-rect-parts"
                                            />
                                            {/* Texto Medidas */}
                                            {parte["width"] / fz >= whminima &&
                                            parte["length"] / fz >= whminima ? (
                                              <g>
                                                {/* X */}
                                                <g className="gx-cut-marker">
                                                  <rect
                                                    x={
                                                      parte.x / fz +
                                                      parte["width"] / fz / 2 -
                                                      25
                                                    }
                                                    y={parte.y / fz}
                                                    rx="2"
                                                    ry="2"
                                                  />
                                                  <text
                                                    x={
                                                      parte.x / fz +
                                                      parte["length"] / fz / 2
                                                    }
                                                    y={parte.y / fz + 14}
                                                    className="gx-text-part"
                                                  >
                                                    {parte["length"]}
                                                  </text>
                                                </g>
                                                {/* Y */}
                                                <g
                                                  className="gx-cut-marker"
                                                  transform="rotate(-90)"
                                                >
                                                  <rect
                                                    x={
                                                      -parte.y / fz -
                                                      parte["width"] / fz / 2 -
                                                      25
                                                    }
                                                    y={parte.x / fz}
                                                    rx={2}
                                                    ry={2}
                                                  />

                                                  <text
                                                    x={
                                                      -parte.y / fz -
                                                      parte["width"] / fz / 2
                                                    }
                                                    y={parte.x / fz + 14}
                                                    className="gx-text-part"
                                                  >
                                                    {parte["width"]}
                                                  </text>
                                                </g>
                                                {/* PILA (A)*/}
                                                <g className="gx-cut-marker">
                                                  <text
                                                    x={
                                                      parte.x / fz +
                                                      parte["length"] / fz / 2
                                                    }
                                                    y={
                                                      parte.y / fz +
                                                      parte["width"] / fz / 2
                                                    }
                                                    className="gx-text-pila"
                                                    style={{
                                                      fontSize: "1em"
                                                    }}
                                                  >
                                                    ({parte["datos"]})
                                                  </text>
                                                </g>
                                              </g>
                                            ) : (
                                              <g>
                                                {/* PILA */}
                                                <g className="gx-cut-marker">
                                                  <text
                                                    x={parte.x / fz + 14}
                                                    y={parte.y / fz}
                                                    className="gx-text-pila"
                                                    style={{
                                                      fontSize: "1em"
                                                    }}
                                                  >
                                                    <tspan alignmentBaseline="text-before-edge">
                                                      ({parte["datos"]})
                                                    </tspan>
                                                  </text>
                                                </g>
                                                {/* MEDIDAS */}
                                                <g className="gx-cut-marker">
                                                  <text
                                                    x={
                                                      parte.x / fz +
                                                      parte["length"] / fz / 2
                                                    }
                                                    y={
                                                      parte.y / fz +
                                                      (parte["width"] / fz - 9) / 2
                                                    }
                                                    className="gx-text-pila"
                                                    style={{
                                                      fontSize: 9
                                                    }}
                                                  >
                                                    <tspan alignmentBaseline="text-before-edge">
                                                      {/* A */}
                                                      {parte["length"]}x
                                                      {parte["width"]}
                                                    </tspan>
                                                  </text>
                                                </g>
                                              </g>
                                            )}
                                          </g>
                                        ) : (
                                          <g key={p}>
                                            {/* Texto Medidas */}
                                            <rect
                                              x={parte.x / fz}
                                              y={parte.y / fz}
                                              width={parte["width"] / fz}
                                              height={parte["length"] / fz}
                                              fill={this.handleRenderFillRect(
                                                parte.datos
                                              )}
                                              className="gx-rect-parts"
                                            />
                                            {parte["length"] / fz >= whminima &&
                                            parte["width"] / fz >= whminima ? (
                                              <g>
                                                {/* X */}
                                                <g className="gx-cut-marker">
                                                  <rect
                                                    x={
                                                      parte.x / fz +
                                                      (parte["width"] / fz / 2 - 25)
                                                    }
                                                    y={parte.y / fz}
                                                    rx="2"
                                                    ry="2"
                                                  />
                                                  <text
                                                    x={
                                                      parte.x / fz +
                                                      parte["width"] / fz / 2
                                                    }
                                                    y={parte.y / fz + 14}
                                                    className="gx-text-part"
                                                  >
                                                    {parte["width"]}
                                                  </text>
                                                </g>
                                                {/* Y */}
                                                <g
                                                  className="gx-cut-marker"
                                                  transform="rotate(-90)"
                                                >
                                                  <rect
                                                    x={
                                                      -parte.y / fz -
                                                      parte["length"] / fz / 2 -
                                                      25
                                                    }
                                                    y={parte.x / fz}
                                                    rx={2}
                                                    ry={2}
                                                  />
                                                  <text
                                                    x={
                                                      -parte.y / fz -
                                                      parte["length"] / fz / 2
                                                    }
                                                    y={parte.x / fz + 14}
                                                    className="gx-text-part"
                                                  >
                                                    {parte["length"]}
                                                  </text>
                                                </g>
                                                {/* PILA (B) */}
                                                <g
                                                  className="gx-cut-marker"
                                                  // transform="rotate(-90)"
                                                >
                                                  <text
                                                    x={
                                                      parte.x / fz +
                                                      parte["width"] / fz / 2
                                                    }
                                                    y={
                                                      parte.y / fz +
                                                      parte["length"] / fz / 2
                                                    }
                                                    className="gx-text-pila"
                                                  >
                                                    ({parte["datos"]})
                                                  </text>
                                                </g>
                                              </g>
                                            ) : (
                                              <g>
                                                {/* PILA */}
                                                <g className="gx-cut-marker">
                                                  <text
                                                    x={parte.x / fz + 7}
                                                    y={parte.y / fz}
                                                    className="gx-text-pila"
                                                    style={{
                                                      fontSize: 9
                                                    }}
                                                  >
                                                    <tspan alignmentBaseline="text-before-edge">
                                                      ({parte["datos"]})
                                                    </tspan>
                                                  </text>
                                                </g>
                                                {/* MEDIDAS */}
                                                {Number(parte["width"]) <
                                                Number(parte["length"]) ? (
                                                  <g
                                                    className="gx-cut-marker"
                                                    transform="rotate(-90)"
                                                  >
                                                    <text
                                                      x={
                                                        -parte.y / fz -
                                                        (parte["length"] / fz - 9) /
                                                          2 -
                                                        8
                                                      }
                                                      y={
                                                        parte.x / fz +
                                                        (parte["width"] / fz - 9) /
                                                          2 +
                                                        8
                                                      }
                                                      className="gx-text-pila"
                                                      style={{
                                                        fontSize: 9
                                                      }}
                                                    >
                                                      {/* BA */}
                                                      {parte["length"]}x
                                                      {parte["width"]}
                                                    </text>
                                                  </g>
                                                ) : (
                                                  <g className="gx-cut-marker">
                                                    <text
                                                      x={
                                                        parte.x / fz +
                                                        (parte["width"] / fz - 30) /
                                                          2 +
                                                        14
                                                      }
                                                      y={
                                                        parte.y / fz +
                                                        (parte["length"] / fz - 9) /
                                                          2 +
                                                        8
                                                      }
                                                      className="gx-text-pila"
                                                      style={{
                                                        fontSize: 9
                                                      }}
                                                    >
                                                      {/* B */}
                                                      {parte["width"]}x
                                                      {parte["length"]}
                                                    </text>
                                                  </g>
                                                )}
                                              </g>
                                            )}
                                          </g>
                                        )
                                      )
                                    : null}

                                  {/* Desperdicio */}
                                  {lay.wastePart.length > 0
                                    ? lay.wastePart.map((waste, w) => {
                                        return (
                                          <g key={w}>
                                            <rect
                                              x={waste.x / fz}
                                              y={waste.y / fz}
                                              width={waste["width"] / fz}
                                              height={waste["length"] / fz}
                                              className={this.medMinRestoClass(
                                                waste["width"],
                                                waste["length"]
                                              )}
                                              stroke="#000"
                                              strokeWidth={1}
                                            />
                                            {waste["length"] / fz >= whminima &&
                                            waste["width"] / fz >= whminima ? (
                                              <g>
                                                {/* X */}
                                                <g className="gx-cut-marker">
                                                  <rect
                                                    x={
                                                      waste.x / fz +
                                                      (waste["width"] / fz / 2 - 25)
                                                    }
                                                    y={waste.y / fz}
                                                    rx="2"
                                                    ry="2"
                                                  />
                                                  <text
                                                    x={
                                                      waste.x / fz +
                                                      waste["width"] / fz / 2
                                                    }
                                                    y={waste.y / fz + 14}
                                                    className="gx-text-waste"
                                                  >
                                                    {this.medMinRestoShow(
                                                      waste["length"],
                                                      waste["width"],
                                                      "W"
                                                    )}
                                                  </text>
                                                </g>
                                                {/* Y */}
                                                <g
                                                  className="gx-cut-marker"
                                                  transform="rotate(-90)"
                                                >
                                                  <rect
                                                    x={
                                                      -waste.y / fz -
                                                      waste["length"] / fz / 2 -
                                                      25
                                                    }
                                                    y={waste.x / fz}
                                                    rx={2}
                                                    ry={2}
                                                  />
                                                  <text
                                                    x={
                                                      -waste.y / fz -
                                                      waste["length"] / fz / 2
                                                    }
                                                    y={waste.x / fz + 14}
                                                    className="gx-text-waste"
                                                  >
                                                    {this.medMinRestoShow(
                                                      waste["length"],
                                                      waste["width"],
                                                      "L"
                                                    )}
                                                  </text>
                                                </g>
                                              </g>
                                            ) : (
                                              <g>
                                                {/* MEDIDAS */}
                                                {Number(waste["width"]) <
                                                Number(waste["length"]) ? (
                                                  <g
                                                    className="gx-cut-marker"
                                                    transform="rotate(-90)"
                                                  >
                                                    <text
                                                      x={
                                                        -waste.y / fz -
                                                        (waste["length"] / fz - 9) /
                                                          2 -
                                                        8
                                                      }
                                                      y={
                                                        waste.x / fz +
                                                        (waste["width"] / fz - 9) /
                                                          2 +
                                                        8
                                                      }
                                                      className="gx-text-waste"
                                                      style={{
                                                        fontSize: 9
                                                      }}
                                                    >
                                                      {/* BA */}
                                                      {this.medMinRestoShow(
                                                        waste["length"],
                                                        waste["width"],
                                                        "LW"
                                                      )}
                                                    </text>
                                                  </g>
                                                ) : (
                                                  <g className="gx-cut-marker">
                                                    <text
                                                      x={
                                                        waste.x / fz +
                                                        (waste["width"] / fz - 30) /
                                                          2 +
                                                        14
                                                      }
                                                      y={
                                                        waste.y / fz +
                                                        (waste["length"] / fz - 9) /
                                                          2 +
                                                        8
                                                      }
                                                      className="gx-text-waste"
                                                      style={{
                                                        fontSize: 9
                                                      }}
                                                    >
                                                      {/* B */}
                                                      {this.medMinRestoShow(
                                                        waste["length"],
                                                        waste["width"],
                                                        "LW"
                                                      )}
                                                    </text>
                                                  </g>
                                                )}
                                              </g>
                                            )}
                                          </g>
                                        );
                                      })
                                    : null}

                                  {/* secuencia */}
                                  {bSecCorte === true
                                    ? lay.cuts.length > 0
                                      ? lay.cuts.map((cut, ct) => (
                                          <g key={ct}>
                                            <line
                                              className="gx-cute-line"
                                              y1={cut.y1 / fz}
                                              y2={cut.y2 / fz}
                                              x1={cut.x1 / fz}
                                              x2={cut.x2 / fz}
                                            />
                                            <g>
                                              <circle
                                                className="gx-circle-text"
                                                cx={cut.x1 / fz}
                                                cy={cut.y1 / fz}
                                                r="10"
                                              />
                                              <text
                                                x={cut.x1 / fz}
                                                y={cut.y1 / fz}
                                                textAnchor="middle"
                                                strokeWidth="qpx"
                                                dy=".2em"
                                              >
                                                {ct + 1}
                                              </text>
                                            </g>
                                          </g>
                                        ))
                                      : null
                                    : null}
                                </g>
                              </svg>
                            </div>
                            <div style={{padding: '10px'}}>
                              <ParametersPlan sParametros={sParametros} />
                              <TableDetail
                                wastePart={lay.wastePart}
                                part={lay.part}
                                nMedMinResto={nMedMinResto}
                              />
                            </div>
                          </div>
                          {tablero.layout.length === ly + 1 ? null : (
                            <div className="salto-de-pagina" />
                          )}
                        </Fragment>
                      ))
                    );
                  })
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
              </div>
            </Fragment>
          )}
        <Affix
          offsetTop={10}
          style={{ position: "absolute", top: 10, right: 10 }}
          className="gx-btn-print gx-d-none gx-d-lg-block"
        >
          <Button
            icon="printer"
            type="primary"
            onClick={this.handleOnPrintPlan}
          >
            Imprimir
          </Button>
          <Button>
            <Link to="/usuario/optimizador">Ir a Optimizador</Link>
          </Button>
        </Affix>
      </Auxiliary>
    );
  }
}

const mapStateToProps = ({ auth, common, settings, projects }) => {
  const { pathname } = settings;
  const { loading } = common;
  const { selectRoles, permissions, authSucursal, authUser } = auth;
  const { fetchingPlans, listDataOptimize } = projects;
  return {
    pathname,
    selectRoles,
    loading,
    permissions,
    authSucursal,
    fetchingPlans,
    listDataOptimize,
    authUser
  };
};

const mapDispatchToProps = { rxSelecionarProyectoPlano };

export default connect(mapStateToProps, mapDispatchToProps)(Planos);
