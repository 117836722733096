import React, { useState, Fragment } from "react";
import {
  Form,
  Card,
  Input,
  Button,
  Row,
  Col,
  Avatar,
  Select,
  Radio,
  DatePicker,
  InputNumber,
  Upload,
  Divider,
  message,
  Spin,
  Modal,
  Empty,
  Tooltip,
} from "antd";
import { connect, useDispatch } from "react-redux";
import axios from "axios";
import { useMutation, useQuery, useLazyQuery } from "@apollo/react-hooks";
import {
  onErrorMessage,
  googleGeocode,
  getPublicIDCloudinaryFile,
  numbersOnly,
  numbersPhoneOnly,
} from "../../../util/helpers";
import {
  rxToggleModalUpdatePassword,
  userSignUpSuccess,
  updateUserStorage,
} from "appRedux/actions";
import { GoogleMap, withGoogleMap, Marker } from "react-google-maps";
import {
  UPDATE_USER,
  UPDATE_USER_AVATAR,
} from "../../../graphql/mutation/user";
import { GET_MASTERS } from "../../../graphql/query/master";
import { GET_LOCATIONS } from "../../../graphql/query/ubigeo";
import { LOGGED_USER_QUERY } from "../../../graphql/query/auth";
import { DELETE_CLOUDINARY_FILE } from "../../../graphql/mutation/cloudinary";
import { schema } from "./schema";
import WrappedUpdatePassword from "./Modal";
import moment from "moment";
import { spinner } from "../../../util/customComponents";
import {
  cloudinaryEndPoint,
  cloudinarySecure,
  cloudinaryUploadPreset,
  defaultImage,
} from "../../../util/config";
import "./skeleton.css";
import Seo from "../../../components/seo";

const entity = ["MEMBRESIA", "TIPO_DOCUMENTO", "ESPECIALIDAD", "PROFESION"];
let timer = 0;
const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};
const beforeUpload = (file) => {
  const isJpgOrPng =
    file.type === "image/jpeg" ||
    file.type === "image/png" ||
    file.type === "image/jpg";
  if (!isJpgOrPng) {
    message.error("Solo puedes subir archivos tipo JPG/PNG/JPEG!");
  }
  const isLt2M = file.size / 1024 / 1024 <= 2;
  if (!isLt2M) {
    message.error("El archivo debe pesar máximo de 4MB!");
  }
  return isJpgOrPng && isLt2M;
};

const google = window.google;
const GoogleMapConfig = withGoogleMap((props) => (
  <GoogleMap
    onClick={props.handleOnClickMap}
    defaultZoom={props.zoom}
    center={props.center}
    defaultOptions={props.options}
  >
    <Marker position={props.center} onClick={props.handleOnClickMap} />
  </GoogleMap>
));

const options = {
  scrollwheel: true,
  mapTypeControl: false,
  fullscreenControl: false,
  zoomControlOptions: {
    style: google && google.maps.ZoomControlStyle.SMALL,
  },
  mapTypeControlOptions: {
    position: google && google.maps.ControlPosition.TOP_RIGHT,
  },
  draggable: true,
  rotateControl: true,
  scaleControl: true,
  streetViewControl: false,
  panControl: true,
};

const UserProfile = (props) => {
  const { authUser } = props;
  const dispatch = useDispatch();
  const {
    validateFields,
    getFieldDecorator,
    setFieldsValue,
    getFieldValue,
  } = props.form;
  // const [currentDistrict, setCurrentDistrict] = useState(null);
  const [, setCurrentDistrict] = useState(null);
  const [currentDistrictName, setCurrentDistrictName] = useState(null);
  const [dataMasters, setDataMasters] = useState([]);
  const [locations, setLocations] = useState([]);
  const [updatingAvatar, setUpdatingAvatar] = useState(false);
  const [center, setCenter] = useState({
    lat: null,
    lng: null,
  });
  const [displayMap, setDisplayMap] = useState(false);

  const queryMasters = useQuery(GET_MASTERS, {
    variables: {
      column: "ENTITY",
      operator: "IN",
      value: entity,
    },
    onCompleted({ masters }) {
      setDataMasters(masters);
    },
    onError(error) {
      onErrorMessage(error);
    },
  });

  const [queryLocations, { loading: loadingLocation }] = useLazyQuery(
    GET_LOCATIONS,
    {
      onCompleted({ consultar_ubigeos }) {
        setLocations(consultar_ubigeos);
      },
      onError(error) {
        onErrorMessage(error);
      },
    }
  );

  const loadingMasters = queryMasters.loading || false;
  const [updateUser, { loading }] = useMutation(UPDATE_USER, {
    onCompleted({ updateUser: updatedUser }) {
      // localStorage.setItem('user', JSON.stringify(updatedUser));
      dispatch(updateUserStorage(updatedUser));
      if (authUser && authUser.user_type === "CARPINTERO") {
        const random_boolean = Math.random() >= 0.5;
        if (random_boolean) {
          Modal.confirm({
            centered: true,
            content:
              "Tu información fue actualizada correctamente. ¿Deseas registrar un proyecto",
            okText: "Si, por favor",
            cancelText: "No, solo cerrar",
            onOk() {
              props.history.push("/usuario/proyectos");
            },
            onCancel() {},
          });
        } else {
          message.success("Información actualizada correctamente");
        }
      } else {
        message.success("Información actualizada correctamente");
      }
    },
    onError(error) {
      onErrorMessage(error, false, "warning");
    },
  });

  const uploadAvatar = useMutation(UPDATE_USER_AVATAR, {
    onCompleted({ updateUserAvatar }) {
      if (updateUserAvatar && updateUserAvatar.avatar) {
        message.success("Avatar actualizado correctamente");
        const data = {
          ...authUser,
          avatar: updateUserAvatar.avatar,
        };
        props.userSignUpSuccess(data);
        localStorage.setItem("user", JSON.stringify(data));
      }
    },
    onError(error) {
      onErrorMessage(error);
      setUpdatingAvatar(false);
    },
  });

  const { loading: loadingInfo } = useQuery(LOGGED_USER_QUERY, {
    fetchPolicy: "network-only",
    onCompleted({ me }) {
      setFormValues(me);
    },
    onError(error) {
      onErrorMessage(error);
    },
  });

  const renderEntity = ({ entity, data = [] }) => {
    return (
      Array.isArray(data) &&
      data.map((master) =>
        master.entity === entity ? (
          <Select.Option value={master.id} key={master.id}>
            {master.description}
          </Select.Option>
        ) : null
      )
    );
  };

  const setFormValues = (me = null) => {
    me && me.ubigeo && me.ubigeo.id && handleOnSearch(me.ubigeo.district);
    setFieldsValue({
      sNombres: me && me.name,
      sApellidos: me && me.last_name,
      sTipoDoc: (me && me.document_type && me.document_type.id) || undefined,
      sDocumento: (me && me.document_number) || null,
      nTelefono2: (me && me.phone) || null,
      nTelefono1: (me && me.cellphone) || null,
      sCorreo: me && me.email,
      sDireccion: (me && me.address) || null,
      sDistrito: (me && me.ubigeo && me.ubigeo.id) || undefined,
      dFNacimiento:
        me && me.birth_date ? moment(me.birth_date).utc(false) : null,
      sSexo: (me && me.sex) || null,
    });
    if (authUser && authUser.user_type === "CARPINTERO") {
      setFieldsValue({
        sProfesion:
          (me &&
            me.specialty &&
            me.specialty.parent &&
            me.specialty.parent.id) ||
          undefined,
        sEspecialidad: (me && me.specialty && me.specialty.id) || undefined,
        nTExperiencia: (me && me.experience_years) || null,
        sResumen: (me && me.experience_resume) || null,
      });
    }
    if (me && me.lat && me.lng) {
      setCenter({
        lat: (me && Number(me.lat)) || null,
        lng: (me && Number(me.lng)) || null,
      });
      setDisplayMap(true);
    }
    setCurrentDistrictName(
      (me &&
        me.ubigeo &&
        `${me.ubigeo.district}/${me.ubigeo.province}/${me.ubigeo.department}`) ||
        null
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        const {
          sNombres,
          sApellidos,
          sTipoDoc,
          sDocumento,
          dFNacimiento,
          sSexo,
          sCorreo,
          nTelefono1,
          nTelefono2,
          sDireccion,
          sDistrito,
          sEspecialidad,
          nTExperiencia,
          sResumen,
        } = values;
        const variables = {
          id: authUser && authUser.id,
          name: sNombres,
          last_name: sApellidos,
          document_type_id: sTipoDoc || null,
          phone: nTelefono2,
          cellphone: nTelefono1,
          birth_date: dFNacimiento ? dFNacimiento.format("YYYY-MM-DD") : null,
          sex: sSexo,
          address: sDireccion,
          ubigeo_id: sDistrito || null,
          email: sCorreo,
          experience_years: nTExperiencia,
          experience_resume: sResumen,
          specialty_id: sEspecialidad || null,
          lat:
            center.lat === 0 || !center.lat
              ? null
              : parseFloat(center.lat).toString(),
          lng:
            center.lng === 0 || !center.lng
              ? null
              : parseFloat(center.lng).toString(),
        };
        if (sDocumento !== authUser.document_number) {
          variables["document_number"] = sDocumento;
        }
        updateUser({ variables });
      }
    });
  };

  const [deleteCloudinaryFile] = useMutation(DELETE_CLOUDINARY_FILE, {
    onError() {
      message.error("Ha ocurrido un error");
    },
  });

  const cleanCoords = () => {
    setCenter({ lat: 0, lng: 0 });
    setDisplayMap(false);
  };

  const addCoords = () => {
    const { authUser } = props;
    const address = getFieldValue("sDireccion");
    const district = getFieldValue("sDistrito");
    if (authUser) {
      if (authUser.ubigeo) {
        if (authUser.address === address && authUser.ubigeo.id === district) {
          if (authUser.lat && authUser.lng) {
            setCenter({
              lat: Number(authUser.lat),
              lng: Number(authUser.lng),
            });
            setDisplayMap(true);
          } else {
            getPosition({
              sDistrito: currentDistrictName,
              sDireccion: address,
            });
          }
        } else {
          getPosition({ sDistrito: currentDistrictName, sDireccion: address });
        }
      } else {
        getPosition({ sDistrito: currentDistrictName, sDireccion: address });
      }
    } else {
      getPosition({ sDistrito: currentDistrictName, sDireccion: address });
    }
  };

  const handleChangeFile = (info) => {
    if (info.file.originFileObj) {
      setUpdatingAvatar(true);
      if (authUser.avatar) {
        const public_id = getPublicIDCloudinaryFile(authUser.avatar);
        deleteCloudinaryFile({ variables: { file: public_id } }).then(
          ({ data }) => {
            if (data.deleteCloudinaryFile) {
              if (data.deleteCloudinaryFile.status) {
                uploadPhoto(info);
              } else {
                message.warning(data.deleteCloudinaryFile.message);
                setUpdatingAvatar(false);
              }
            } else {
              message.error("Ha ocurrido un error");
              setUpdatingAvatar(false);
            }
          }
        );
      } else {
        uploadPhoto(info);
      }
    }
  };
  const uploadPhoto = (info) => {
    const [updateUserAvatar] = uploadAvatar;
    getBase64(info.file.originFileObj, (imageUrl) => {
      const info = new FormData();
      info.append("file", imageUrl);
      info.append("upload_preset", cloudinaryUploadPreset);
      axios
        .post(cloudinaryEndPoint, info, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          updateUserAvatar({
            variables: {
              id: authUser && authUser.id,
              avatar: res.data.secure_url,
            },
          });
        })
        .catch(() => {
          message.warning("Error al subir foto");
          setUpdatingAvatar(false);
        });
    });
  };

  const handleOnSearch = (value) => {
    clearInterval(timer);
    timer = setTimeout(() => {
      if (value) {
        queryLocations({ variables: { value: `%${value}%` } });
      }
    }, 500);
  };

  const handleSelectDistric = (value, options) => {
    if (options) {
      const { text } = options.props;
      const stringChild = (text && text.replace(/[/]/g, "")) || "";
      const sDireccion = getFieldValue("sDireccion");
      setCurrentDistrict(value);
      setCurrentDistrictName(stringChild);
      if (displayMap) {
        getPosition({ sDistrito: stringChild, sDireccion });
      }
    }
  };

  const handleOnBlurAddress = ({ target: { value } }) => {
    if (displayMap) {
      getPosition({ sDistrito: currentDistrictName, sDireccion: value });
    }
  };

  const getPosition = async ({ sDireccion, sDistrito }) => {
    try {
      const stringLocation = `${sDireccion} ${sDistrito}`;
      const coordinates = await googleGeocode(stringLocation);
      setCenter(coordinates);
      setDisplayMap(true);
    } catch (error) {
      message.warning("No se pudo obtener coordenadas");
    }
  };

  const handleOnClickMap = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setCenter({ lat, lng });
  };

  const upload = {
    showUploadList: false,
    customRequest: ({ file, onSuccess }) => onSuccess("ok"),
    onChange: handleChangeFile,
    beforeUpload: beforeUpload,
    name: "file",
    accept: ".pdf,.png,.jpg,.jpeg",
    multiple: false,
    listType: "text",
  };

  const [reloadMe, { loading: reloadingInfo }] = useLazyQuery(
    LOGGED_USER_QUERY,
    {
      fetchPolicy: "network-only",
      onCompleted({ me }) {
        setFormValues(me);
      },
      onError(error) {
        onErrorMessage(error);
      },
    }
  );

  return (
    <div>
      <Row type="flex" justify="space-around" align="middle">
      <Seo
        title={`${(authUser && authUser.name) || ""} ${
          (authUser && authUser.last_name) || ""
        }`}
        url={window.location.href}
        description={`Encuentra aquí tus datos primarios, contacto y ubicación.`}
        keywords="mi cuenta"
      />
      <Col
        xs={24}
        sm={24}
        md={18}
        lg={12}
        xl={12}
        xxl={12}
        className="gx-p-0 gx-text-center"
      >
        <Spin spinning={loadingInfo || reloadingInfo} indicator={spinner}>
          <Card bordered={true} bodyStyle={{ padding: "6px" }}>
            <div className="gx-mt-3">
              <Upload multiple={false} {...upload} className="gx-pointer">
                <Spin spinning={updatingAvatar} indicator={spinner}>
                  <Avatar
                    size={80}
                    shape="circle"
                    src={
                      authUser
                        ? authUser.avatar
                          ? `${cloudinarySecure}/c_fill,ar_1:1,g_auto,r_max,q_auto:low,w_80,h_80/${
                              authUser.avatar.split(cloudinarySecure)[1]
                            }`
                          : defaultImage
                        : defaultImage
                    }
                    className="skeleton-avatar"
                  />
                </Spin>
              </Upload>
            </div>
            <h3 className="h3 gx-mt-3 gx-mb-0">
              {`${(authUser && authUser.name) || "-"} ${
                (authUser && authUser.last_name) || "-"
              }`}
            </h3>
            <p className="gx-text-grey gx-mb-4">
              {(authUser && authUser.email) || "-"}
            </p>
            <Form
              className="gx-form-row0 gx-text-left"
              onSubmit={handleSubmit}
              hideRequiredMark
            >
              <Spin
                spinning={loading}
                tip={loading ? "Actualizando Datos" : ""}
              >
                <Row>
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <h5 className="gx-mt-2 gx-text-primary gx-font-weight-bold gx-text-uppercase">
                      <i className="fal fa-address-card gx-mr-2" />
                      Datos Generales
                      <Tooltip
                        title={
                          <span className="gx-fs-sm">
                            Solo tu nombres, apellidos y sexo estarán
                            disponibles en tu perfil público.
                          </span>
                        }
                      >
                        <i className="fa fa-question-circle gx-pointer gx-float-right" />
                      </Tooltip>
                    </h5>
                    <div className="gx-text-muted gx-fs-sm gx-mb-2">
                      Usaremos esta información para verificar si eres una
                      persona real.
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item
                      className="form-control-ec"
                      label={schema.sNombres.label}
                    >
                      {getFieldDecorator(schema.sNombres.name, {
                        initialValue: authUser && authUser.name,
                        rules: [
                          {
                            required: true,
                            message: schema.sNombres.rule.message,
                          },
                        ],
                      })(<Input />)}
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item
                      className="form-control-ec"
                      label={schema.sApellidos.label}
                    >
                      {getFieldDecorator(schema.sApellidos.name, {
                        rules: [
                          {
                            required: true,
                            message: schema.sApellidos.rule.message,
                          },
                        ],
                      })(<Input />)}
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item
                      className="form-control-ec"
                      label={schema.sTipoDoc.label}
                    >
                      {getFieldDecorator(schema.sTipoDoc.name, {
                        rules: [
                          {
                            required: getFieldValue(schema.sDocumento.name),
                            message: schema.sTipoDoc.rule.message,
                          },
                        ],
                      })(
                        <Select
                          allowClear
                          placeholder="Seleccione"
                          loading={loadingMasters}
                          onChange={() =>
                            setFieldsValue({
                              [schema.sDocumento.name]: getFieldValue(
                                schema.sDocumento.name
                              ),
                              errors: [],
                            })
                          }
                        >
                          {renderEntity({
                            entity: "TIPO_DOCUMENTO",
                            data: dataMasters,
                          })}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item
                      className="form-control-ec"
                      label={schema.sDocumento.label}
                    >
                      {getFieldDecorator(schema.sDocumento.name, {
                        rules: [
                          {
                            required: getFieldValue(schema.sTipoDoc.name),
                            message: schema.sTipoDoc.rule.message,
                          },
                        ],
                      })(
                        <Input
                          allowClear
                          onKeyDown={numbersOnly}
                          placeholder="Número de identificación nacional"
                          onChange={() =>
                            setFieldsValue({
                              [schema.sTipoDoc.name]: getFieldValue(
                                schema.sTipoDoc.name
                              ),
                              errors: [],
                            })
                          }
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item
                      className="form-control-ec"
                      label={schema.dFNacimiento.label}
                    >
                      {getFieldDecorator(schema.dFNacimiento.name)(
                        <DatePicker
                          format="DD/MM/YYYY"
                          style={{ width: "100%" }}
                          placeholder="DD/MM/YYYY"
                          allowClear
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item
                      className="form-control-ec"
                      label={schema.sSexo.label}
                    >
                      {getFieldDecorator(schema.sSexo.name)(
                        <Radio.Group buttonStyle="solid">
                          <Radio.Button
                            value="M"
                            onClick={(e) =>
                              authUser &&
                              authUser.sex &&
                              getFieldValue(schema.sSexo.name) ===
                                e.target.value &&
                              setFieldsValue({ [schema.sSexo.name]: null })
                            }
                          >
                            Masculino
                          </Radio.Button>
                          <Radio.Button
                            value="F"
                            onClick={(e) =>
                              authUser &&
                              authUser.sex &&
                              getFieldValue(schema.sSexo.name) ===
                                e.target.value &&
                              setFieldsValue({ [schema.sSexo.name]: null })
                            }
                          >
                            Femenino
                          </Radio.Button>
                        </Radio.Group>
                      )}
                    </Form.Item>
                  </Col>
                  <Divider className="gx-mt-1 gx-mb-3" dashed />
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <h5 className="gx-mt-2 gx-text-primary gx-font-weight-bold gx-text-uppercase">
                      <i className="fal fa-phone-volume gx-mr-2" />
                      Datos de Contacto
                      <Tooltip
                        title={
                          <div className="gx-fs-sm">
                            <p className="gx-mb-1">
                              Tu correo electrónico, número de celular y
                              teléfono podrá ser visualizado por usuarios
                              registrados en la plataforma de eCarpintero.
                            </p>
                            <p className="gx-mb-0">
                              Si no deseas compartir tus números telefónicos
                              puedes dejar en blanco la información que creas
                              conveniente.
                            </p>
                          </div>
                        }
                      >
                        <i className="fa fa-question-circle gx-pointer gx-float-right" />
                      </Tooltip>
                    </h5>
                    <div className="gx-text-muted gx-fs-sm gx-mb-2">
                      Esta información será útil para que tus clientes se
                      contacten contigo mucho más rápido.
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <Form.Item
                      className="form-control-ec"
                      label={schema.sCorreo.label}
                      help={
                        <span className="gx-fs-sm">
                          <i className="gx-mr-1 fal fa-info-circle" />
                          El correo electrónico no puede ser modificado.
                        </span>
                      }
                    >
                      {getFieldDecorator(schema.sCorreo.name)(
                        <Input readOnly />
                      )}
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item
                      className="form-control-ec"
                      label={schema.nTelefono1.label}
                    >
                      {getFieldDecorator(schema.nTelefono1.name)(
                        <Input
                          allowClear
                          onKeyDown={numbersPhoneOnly}
                          placeholder="Celular: +51916859652 o 916859652"
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item
                      className="form-control-ec"
                      label={schema.nTelefono2.label}
                    >
                      {getFieldDecorator(schema.nTelefono2.name)(
                        <Input
                          allowClear
                          onKeyDown={numbersPhoneOnly}
                          placeholder="Teléfono: (01)562154 o 01562154"
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Divider className="gx-mt-1 gx-mb-3" dashed />
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <h5 className="gx-mt-2 gx-text-primary gx-font-weight-bold gx-text-uppercase">
                      <i className="fal fa-store gx-mr-2" />
                      Ubicación de taller
                      <Tooltip
                        title={
                          <div className="gx-fs-sm">
                            <p className="gx-mb-0">
                              La ubicación de tu taller estará disponible
                              públicamente y sabemos que es importante tu
                              privacidad; por lo tanto si no deseas compartir la
                              ubicación de tu centro de labores puedes dejar en
                              blanco dicha información u ocultarlo en el mapa.
                            </p>
                          </div>
                        }
                      >
                        <i className="fa fa-question-circle gx-pointer gx-float-right" />
                      </Tooltip>
                    </h5>
                    <div className="gx-text-muted gx-fs-sm gx-mb-2">
                      Posiciona tu taller en nuestro buscador y haz que tus
                      clientes te conozcan.
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item
                      className="form-control-ec"
                      label={schema.sDistrito.label}
                    >
                      {getFieldDecorator(schema.sDistrito.name)(
                        <Select
                          allowClear
                          showSearch
                          onSearch={handleOnSearch}
                          onSelect={handleSelectDistric}
                          onChange={(value) => {
                            // console.log(value);
                            if (!value) {
                              setDisplayMap(false);
                              setCenter({ lat: null, lng: null });
                              setFieldsValue({
                                [schema.sDireccion.name]: null,
                              });
                              setLocations([]);
                            }
                          }}
                          filterOption={(input, option) => {
                            const regExp = new RegExp(input, "gi");
                            return option.props.district.match(regExp);
                          }}
                          placeholder="Buscar distrito"
                          notFoundContent={
                            <Spin spinning={loadingLocation}>
                              <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description="Buscar distrito..."
                              />
                            </Spin>
                          }
                        >
                          {Array.isArray(locations) &&
                            locations.map((location) => (
                              <Select.Option
                                value={location.id}
                                key={location.id}
                                district={location.district}
                                text={`${location.district}, ${location.province}, ${location.department}`}
                              >
                                {`${location.district}/${location.province}/${location.department}`}
                              </Select.Option>
                            ))}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item
                      className="form-control-ec"
                      label={schema.sDireccion.label}
                    >
                      {getFieldDecorator(schema.sDireccion.name)(
                        <Input
                          onBlur={handleOnBlurAddress}
                          disabled={!getFieldValue(schema.sDistrito.name)}
                          allowClear
                          placeholder="Ingrese dirección"
                        />
                      )}
                    </Form.Item>
                  </Col>
                  {displayMap ? (
                    <Col xs={24} sm={24} md={24} lg={24}>
                      <GoogleMapConfig
                        loadingElement={<div style={{ height: `100%` }} />}
                        containerElement={
                          <div style={{ height: 300, width: "100%" }} />
                        }
                        mapElement={<div style={{ height: `100%` }} />}
                        center={center}
                        zoom={17}
                        options={options}
                        handleOnClickMap={handleOnClickMap}
                      />
                      <Button
                        type="primary"
                        htmlType="button"
                        size="small"
                        className="gx-mt-2 gx-mb-4"
                        onClick={cleanCoords}
                      >
                        Limpiar coordenadas
                      </Button>
                    </Col>
                  ) : (
                    <Col span={24}>
                      <Button
                        type="primary"
                        htmlType="button"
                        size="small"
                        className="gx-mt-2 gx-mb-4"
                        onClick={addCoords}
                      >
                        <i className="fal fa-map-marker-check gx-mr-2" />
                        Marcar ubicación en Google Maps
                      </Button>
                    </Col>
                  )}
                  {1 === 0 ? (
                    <Fragment>
                      <Col xs={24} sm={24} md={24} lg={24}>
                        <h5 className="gx-mt-2 gx-text-primary gx-font-weight-bold gx-text-uppercase">
                          <i className="fal fa-credit-card-front gx-pr-1" />
                          Datos de Pago
                        </h5>
                        <Divider dashed className="gx-mt-0" />
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12}>
                        <Form.Item
                          className="form-control-ec"
                          label={schema.dFRegistro.label}
                        >
                          {getFieldDecorator(schema.dFRegistro.name, {
                            rules: [
                              {
                                required: schema.dFRegistro.rule.required,
                                message: schema.dFRegistro.rule.message,
                              },
                            ],
                          })(
                            <DatePicker
                              format="DD/MM/YYYYY"
                              placeholder="DD/MM/YYYYY"
                              style={{ width: "100%" }}
                            />
                          )}
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12}>
                        <Form.Item
                          className="form-control-ec"
                          label={schema.bMembresia.label}
                        >
                          {getFieldDecorator(schema.bMembresia.name, {
                            rules: [
                              {
                                required: schema.bMembresia.rule.required,
                                message: schema.bMembresia.rule.message,
                              },
                            ],
                          })(
                            <Select loading={loadingMasters}>
                              {renderEntity({
                                entity: "MEMBRESIA",
                                data: dataMasters,
                              })}
                            </Select>
                          )}
                        </Form.Item>
                      </Col>
                    </Fragment>
                  ) : null}
                  <Divider className="gx-mt-1 gx-mb-3" dashed />
                  {authUser.user_type === "CARPINTERO" ? (
                    <Fragment>
                      <Col xs={24} sm={24} md={24} lg={24}>
                        <h5 className="gx-mt-2 gx-text-primary gx-font-weight-bold gx-text-uppercase">
                          <i className="fal fa-user-tie gx-mr-2" />
                          Datos Profesionales
                          <Tooltip
                            title={
                              <div className="gx-fs-sm">
                                <p className="gx-mb-1">
                                  Esta información se comparte de manera pública
                                  en tu perfil.
                                </p>
                                <p className="gx-mb-0">
                                  Si no deseas compartir estos datos puedes
                                  dejar en blanco la información que creas
                                  conveniente.
                                </p>
                              </div>
                            }
                          >
                            <i className="fa fa-question-circle gx-pointer gx-float-right" />
                          </Tooltip>
                        </h5>
                        <div className="gx-text-muted gx-fs-sm gx-mb-2">
                          Es importante que tus clientes sepan del
                          profesionalismo que brindas en tus servicios y/o
                          productos.
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={8} lg={8}>
                        <Form.Item
                          className="form-control-ec"
                          label={schema.sProfesion.label}
                        >
                          {getFieldDecorator(schema.sProfesion.name)(
                            <Select
                              onChange={() =>
                                setFieldsValue({ sEspecialidad: undefined })
                              }
                              loading={loadingMasters}
                              allowClear
                              placeholder="Seleccione"
                            >
                              {renderEntity({
                                entity: "PROFESION",
                                data: dataMasters,
                              })}
                            </Select>
                          )}
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8} lg={8}>
                        <Form.Item
                          className="form-control-ec"
                          label={schema.sEspecialidad.label}
                        >
                          {getFieldDecorator(schema.sEspecialidad.name)(
                            <Select
                              loading={loadingMasters}
                              allowClear
                              placeholder="Seleccione"
                            >
                              {renderEntity({
                                entity: "ESPECIALIDAD",
                                data: dataMasters,
                              })}
                            </Select>
                          )}
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8} lg={8}>
                        <Form.Item
                          className="form-control-ec"
                          label={schema.nTExperiencia.label}
                        >
                          {getFieldDecorator(schema.nTExperiencia.name)(
                            <InputNumber
                              allowClear
                              min={1}
                              max={80}
                              placeholder="Ingrese tus años de experiencia"
                              style={{ width: "100%" }}
                            />
                          )}
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24}>
                        <Form.Item
                          className="form-control-ec"
                          label={"Resumen de experiencia"}
                          help={
                            <small className="gx-text-grey gx-mt-0">
                              Describa brevemente sus habilidades y experiencia
                            </small>
                          }
                        >
                          {getFieldDecorator(schema.sResumen.name)(
                            <Input.TextArea
                              autoSize={{
                                maxRows: 8,
                                minRows: 4,
                              }}
                              allowClear
                              placeholder="Cuéntanos sobre tí y tu experiencia laboral"
                            />
                          )}
                        </Form.Item>
                      </Col>
                    </Fragment>
                  ) : null}

                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    className="gx-d-flex gx-align-items-center gx-full-width gx-mt-3"
                  >
                    <Button htmlType="submit" type="primary">
                      <i className="far fa-save gx-mr-2" />
                      Guardar
                    </Button>
                    <Button htmlType="button" onClick={reloadMe}>
                      Cancelar
                    </Button>
                    <Button
                      className="gx-ml-auto"
                      onClick={() => {
                        props.rxToggleModalUpdatePassword({
                          info: null,
                          visible: true,
                        });
                      }}
                    >
                      <i className="far fa-unlock gx-mr-2" />
                      Clave
                    </Button>
                  </Col>
                </Row>
              </Spin>
            </Form>
          </Card>
        </Spin>
      </Col>
      <WrappedUpdatePassword />
    </Row>
  
    </div>);
};

const WrappedUserProfile = Form.create({ name: "mi_cuenta" })(UserProfile);
const mapStateToProps = ({ auth }) => {
  const { authUser } = auth;
  return {
    authUser,
  };
};

const mapDispatchToProps = {
  rxToggleModalUpdatePassword,
  userSignUpSuccess,
};

export default connect(mapStateToProps, mapDispatchToProps)(WrappedUserProfile);
