import React from "react";
import { Table } from "antd";
import PropTypes from "prop-types";
import TableTitle from "components/TableTitle";

const columns = [
  {
    title: "CODIGO",
    dataIndex: "sCodTablero",
    key: "sCodTablero",
    width: "10%"
  },
  {
    title: "DESCRIPCIÓN",
    dataIndex: "sDescTablero",
    key: "sDescTablero"
  },

  {
    title: "CANTIDAD",
    dataIndex: "nCantidad",
    key: "nCantidad",
    align: "center"
  },
  {
    title: "MEDIDAS",
    dataIndex: "sMedidas",
    key: "sMedidas",
    align: "center"
  }
];
const Clientes = props => {
  const { dataSource } = props;
  return (
    <div className="gx-table-responsive">
      <Table
        title={() => <TableTitle title="RESTOS" />}
        className="gx-mb-3 gx-table-custom-imd"
        size="small"
        bordered
        pagination={false}
        rowKey={(row, dataIndex) => `${dataIndex + 1}_RS`}
        dataSource={dataSource}
        columns={columns}
      />
    </div>
  );
};

Clientes.propTypes = {
  dataSource: PropTypes.array
};

export default Clientes;
