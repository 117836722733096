import {
  TOGGLE_MODAL_PROJECT,
  SET_USER_PROJECTS,
  // ADD_USER_PROJECT,
  SEARCH_PROJECTS,
  QUERIED_TAGS, CHANGE_VARIABLES,
  TOGGLE_WIZARD_USER
} from "../../constants/ActionTypes";

const initialState = {
  userProjectList: [],
  modalProject: {
    info: null,
    visible: false,
  },
  visible_wizard: false,
  filterProjects: {
    loading: false,
    data: [],
    hasMore: false,
    variables: {
      query: null,
      page: 1
    }
  },
  queryTags: []
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case SET_USER_PROJECTS: {
      return {
        ...state,
        userProjectList: payload
      }
    }
    case TOGGLE_WIZARD_USER: {
      return {
        ...state,
        visible_wizard: payload
      }
    }
    case TOGGLE_MODAL_PROJECT: {
      return {
        ...state,
        modalProject: {info: payload.info, visible: payload.visible},
      };
    }

    case SEARCH_PROJECTS: {
      return {
        ...state,
        filterProjects: {
          loading: payload.loading,
          data: payload.data,
          variables: payload.variables || state.filterProjects.variables,
          hasMore: payload.hasMore
        }
      }
    }

    case CHANGE_VARIABLES: {
      return {
        ...state,
        filterProjects: {
          ...state.filterProjects,
          variables: payload
        }
      }
    }

    case QUERIED_TAGS: {
      return {
        ...state,
        queryTags: payload
      }
    }

    default:
      return state;
  }
};
