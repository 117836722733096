import gql from "graphql-tag";

export const WIZARD_SPECIALIST = gql`
  query wizardCarpenter(
    $east: String!
    $west: String!
    $north: String!
    $south: String!
    $query: String
    $first: Int
    $page: Int
  ) {
    wizardCarpenter(
      east: $east
      west: $west
      north: $north
      south: $south
      query: $query
      page: $page
      first: $first
      orderBy: { field: LATEST_CONNECTION, order: DESC }
    ) {
      paginatorInfo {
        hasMorePages
      }
      data {
        id
        email
        name
        last_name
        publicProjects {
          id
        }
        avatar
        phone
        cellphone
      }
    }
  }
`;

export const NEAR_BY_CARPENTERS = gql`
  query nearbyCarpenters(
    $east: Mixed
    $west: Mixed
    $north: Mixed
    $south: Mixed
  ) {
    nearbyCarpenters(
      where: {
        column: LNG
        operator: GTE
        value: $east
        AND: {
          column: LNG
          operator: LTE
          value: $west
          AND: {
            column: LAT
            operator: GTE
            value: $north
            AND: { column: LAT, operator: LTE, value: $south }
          }
        }
      }
    ) {
      id
      name
      last_name
      email
      phone
      cellphone
      address
      lat
      lng
      user_type
      avatar
      experience_years
      specialty {
        id
        description
        entity
        parent {
          id
          description
        }
      }
    }
  }
`;
export const VERIFY_EXISTENCE_MAIL = gql`
  query verifyExistenceMail($email: String!) {
    verifyExistenceMail(email: $email) {
      email
    }
  }
`;

export const PROFILE_CARPENTER = gql`
  query profileCarpenter($id: ID!) {
    profileCarpenter(id: $id) {
      id
      name
      last_name
      avatar
      email
      phone
      cellphone
      experience_years
      experience_resume
      latest_connection
      specialty {
        id
        description
        parent {
          id
          description
        }
      }
      publicProjects {
        id
        name
        description
        materials
        tags
        favorites
        shared
        visited
        gallery {
          url
          base64
        }
        created_at
      }
    }
  }
`;

export const PROFILE_CARPENTER_WITHOUT_CONTACT = gql`
  query profileCarpenter($id: ID!) {
    profileCarpenter(id: $id) {
      id
      name
      last_name
      avatar
      email
      experience_years
      experience_resume
      latest_connection
      specialty {
        id
        description
        parent {
          id
          description
        }
      }
      publicProjects {
        id
        name
        description
        materials
        tags
        favorites
        shared
        visited
        gallery {
          url
          base64
        }
        created_at
      }
    }
  }
`;
