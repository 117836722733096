import React from "react";
import { Table, Tooltip, Button } from "antd";

const LeyendaCodigoCantos = ({ dataSource, handleMassiveChange }) => {
  const columns = [
    {
      title: "Codigo",
      dataIndex: "sCodCanto",
      key: "sCodCanto",
      align: "center",
      render: (text, row) => (
        <Tooltip title="Reemplazo Masivo">
          <Button
            onClick={() => {
              handleMassiveChange(row);
            }}
            type="link"
            className="gx-p-0 gx-m-0"
          >
            <span className="gx-text-underline ">{text}</span>
          </Button>
        </Tooltip>
      )
    },
    {
      title: "Descripción",
      dataIndex: "sDescCanto",
      key: "sDescCanto"
    }
  ];
  return (
    <Table
      title={() => "Leyenda cantos"}
      columns={columns}
      dataSource={dataSource}
      rowKey={row => row.sCodCanto}
      size="small"
      bordered
      pagination={false}
    />
  );
};

export default LeyendaCodigoCantos;
