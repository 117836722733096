import gql from "graphql-tag";

const GET_LOCATIONS = gql`
  query consultar_ubigeos($value: Mixed) {
    consultar_ubigeos(
      where: { column: DISTRICT, operator: LIKE, value: $value }
    ) {
      id
      code
      province
      department
      district
    }
  }
`;

const FILTER_UBIGEOS = gql`
  query filterUbigeos($query: String) {
    filterUbigeos(query: $query) {
      data {
        id
        code
        district
        province
        department
      }
    }
  }
`;

const RETRIEVE_UBIGEO = gql`
  query retrieveUbigeo($id: ID!) {
    retrieveUbigeo(id: $id) {
      id
      district
      province
      department
    }
  }
`;

export {GET_LOCATIONS, FILTER_UBIGEOS, RETRIEVE_UBIGEO};
