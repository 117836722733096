import gql from 'graphql-tag';

export const DELETE_MULTIMEDIA_MUTATION = gql`
  mutation deleteMultimedia($id: ID!) {
    deleteMultimedia(id: $id) {
      id
      multimediaable_id
    }
  }
`;
