import axios from "axios";
import {backendUrlERP, k, v} from './config';
import crypt from 'node-cryptex';

let options = {
  baseURL: `${backendUrlERP}/api/v2`,
  headers: {
    "Content-Type": "multipart/form-data"
  }
}

let axiosInstance =  axios.create(options);

axiosInstance.interceptors.request.use(
  config => {
    const optimizerToken = localStorage.getItem('optimizer_token');
    config.headers.Authorization = optimizerToken ? `Bearer ${crypt.decrypt(optimizerToken, k, v)}` : null;
    return config;
  },
  error => Promise.reject(error)
);

export default axiosInstance;

/* if (optimizerToken) {
  options.headers['Authorization'] = `Bearer ${crypt.decrypt(optimizerToken, k, v)}`;
} */