import React, { Fragment, useState } from "react";
import { Card, Col, Row, Tabs, Select } from "antd";
import { connect } from "react-redux";
import Map from "./components/Map";
import Shop from "./components/Shop";
import Carpenter from "./components/Carpenter";
import Auxiliary from "util/Auxiliary";
import { onErrorMessage } from "util/helpers";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { NEAR_BY_SHOPS } from "graphql/query/shop";
import { NEAR_BY_CARPENTERS } from "graphql/query/user";
import { GET_MASTERS } from "graphql/query/master";
import Seo from "../../components/seo";
import './index.less';

const { TabPane } = Tabs;
const { Option } = Select;

const Title = ({ children, icon = "user-hard-hat" }) => (
  <Fragment>
    <i className={`fal fa-${icon} gx-pr-1`} />
    {children}
  </Fragment>
);
const Searcher = (props) => (
  <Select
    mode="tags"
    placeholder="Filtrar por Especialidad"
    style={{ width: "100%", marginBottom: 6 }}
    onChange={props.onChange}
    loading={props.loading}
  >
    {props.children}
  </Select>
);

const NearbyShops = () => {
  const height = "100vh";
  const [activeKey, setActiveKey] = useState("C");
  const [dataSourceShop, setDataSourceShop] = useState([]);
  const [dataSourceCarpenter, setDataSourceCarpenter] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [searchTag, setSearchTag] = useState([]);
  const [dataMasters, setDataMasters] = useState([]);

  const { loading: masterLoader } = useQuery(GET_MASTERS, {
    variables: {
      column: "ENTITY",
      operator: "EQ",
      value: "ESPECIALIDAD",
    },
    onCompleted({ masters }) {
      setDataMasters(masters);
    },
    onError(error) {
      // console.log("onError -> error", error);
      onErrorMessage(error, false);
    },
  });

  const getShops = useLazyQuery(NEAR_BY_SHOPS, {
    onCompleted({ nearbyShops }) {
      const shops = [];
      for (const item of nearbyShops) {
        shops.push({ ...item, showInfo: false });
      }
      setDataSourceShop(shops);
    },
    onError(error) {
      onErrorMessage(error, false);
    },
  });

  const geCarpenter = useLazyQuery(NEAR_BY_CARPENTERS, {
    onCompleted({ nearbyCarpenters }) {
      const shops = [];
      for (const item of nearbyCarpenters) {
        shops.push({ ...item, showInfo: false });
      }
      setDataSourceCarpenter(shops);
    },
    onError(error) {
      onErrorMessage(error);
    },
  });

  const getCarpenterShop = (bounds, currenTab = "C") => {
    if (currenTab === "C") {
      const [nearbyCarpenters] = geCarpenter;
      nearbyCarpenters({
        variables: {
          north: bounds.north.toString(),
          south: bounds.south.toString(),
          east: bounds.east.toString(),
          west: bounds.west.toString(),
        },
      });
    } else {
      const [nearbyShops] = getShops;
      nearbyShops({
        variables: {
          north: bounds.north.toString(),
          south: bounds.south.toString(),
          east: bounds.east.toString(),
          west: bounds.west.toString(),
        },
      });
    }
  };

  const handleOnChangeView = (value) => {
    setActiveKey(value);
  };

  // maps
  const handleMarkerClick = (targetMarker) => {
    if (activeKey === "C") {
      const marks = dataSourceCarpenter.map((marker) => {
        if (marker && marker.id === targetMarker.id) {
          return {
            ...marker,
            showInfo: true,
          };
        }
        return marker;
      });
      setDataSourceCarpenter(marks);
    }
    if (activeKey === "S") {
      const marks = dataSourceShop.map((marker) => {
        if (marker && marker.id === targetMarker.id) {
          return {
            ...marker,
            showInfo: true,
          };
        }
        return marker;
      });
      setDataSourceShop(marks);
    }
  };

  const handleMarkerClose = (targetMarker) => {
    if (activeKey === "C") {
      const marks = dataSourceCarpenter.map((marker) => {
        if (marker.id === targetMarker.id) {
          return {
            ...marker,
            showInfo: false,
          };
        }
        return marker;
      });
      setDataSourceCarpenter(marks);
    }
    if (activeKey === "S") {
      const marks = dataSourceShop.map((marker) => {
        if (marker.id === targetMarker.id) {
          return {
            ...marker,
            showInfo: false,
          };
        }
        return marker;
      });
      setDataSourceShop(marks);
    }
  };

  // filtro
  const handleOnChangeTag = (value) => {
    if (value.length > 0) {
      if (dataSourceCarpenter.length > 0) {
        for (const search of value) {
          let valueSearch = `(${search.toString().replace(/ /g, ").+?(")})`;
          const regExp = new RegExp(valueSearch, "gi");
          const result = dataSourceCarpenter.filter(
            (item) => item.specialty && regExp.exec(item.specialty.description)
          );
          setSearchData(result);
        }
      }
      setSearchTag(value);
    } else {
      setSearchTag([]);
      setSearchData([]);
    }
  };

  //especialidades
  const renderEntity = ({ entity, data = [] }) => {
    const options =
      Array.isArray(data) &&
      data.map((master) =>
        master.entity === entity ? (
          <Option value={master.description} key={master.id}>
            {master.description}
          </Option>
        ) : null
      );
    return options;
  };

  return (
    <Auxiliary>
      <Seo
        title="Encuentra carpinteros y tiendas cerca de ti."
        url={window.location.href}
        description={`Contrata profesionales calificados, compra productos y servicios de alta calidad en la industria de la madera.`}
        keywords="carpinteros cerca,tiendas cerca"
      />
      <div className="banner-tienda">
        <span className="titte-tienda">Encuentra tu tienda</span>
      </div>
      <Row className="gx-mb-4 gx-p-0 gx-m-auto">
        <Col xl={14} lg={14} md={24} sm={24} xs={24} className="gx-d-lg-block gx-d-none ">
          <Card bordered={false}>
            <Tabs defaultActiveKey={activeKey} onChange={handleOnChangeView}>
              <TabPane
                tab={<Title icon="store">TIENDAS CERCA</Title>}
                key="S"
              >
                <Shop
                  dataSource={dataSourceShop}
                  loading={getShops && getShops[1].loading}
                  handleMarkerClick={handleMarkerClick}
                  handleMarkerClose={handleMarkerClose}
                />
              </TabPane>
              <TabPane
                tab={<Title icon="user-hard-hat"><span className="gx-font-weight-bold">Carpinteros Cerca</span></Title>}
                key="C"
              >
                <Searcher
                  children={renderEntity({
                    entity: "ESPECIALIDAD",
                    data: dataMasters,
                  })}
                  onChange={handleOnChangeTag}
                  loading={masterLoader}
                />
                <Carpenter
                  dataSource={
                    searchData.length > 0 || searchTag.length > 0
                      ? searchData
                      : dataSourceCarpenter
                  }
                  loading={geCarpenter && geCarpenter[1].loading}
                  handleMarkerClick={handleMarkerClick}
                  handleMarkerClose={handleMarkerClose}
                />
              </TabPane>
            </Tabs>
          </Card>
        </Col>

        <Col xl={10} lg={10} md={24} sm={24} xs={23} className="gx-mapa-tiendas-carpinteros-cercanos">
          {/* Map */}
          <div className="gx-d-lg-block gx-d-none gx-mb-30">
            <Map
              height={height}
              activeKey={activeKey}
              dataSource={
                activeKey === "C" ? dataSourceCarpenter : dataSourceShop
              }
              getCarpenterShop={getCarpenterShop}
              handleMarkerClick={handleMarkerClick}
              handleMarkerClose={handleMarkerClose}
              loading={
                (geCarpenter && geCarpenter[1].loading) ||
                (getShops && getShops[1].loading)
              }
            />
          </div>
          {/* Map/> */}

          {/* Moviel */}
          <div className="gx-header-map-tap gx-p-0 gx-d-block gx-d-lg-none">
            <div className="gx-action-tab-container">
              <button
                onClick={() => handleOnChangeView("C")}
                className={activeKey === "C" ? "gx-active-tab" : ""}
              >
                <Title icon="user-hard-hat">Carpinteros Cerca</Title>
              </button>
              <button
                onClick={() => handleOnChangeView("S")}
                className={activeKey === "S" ? "gx-active-tab" : ""}
              >
                <Title icon="store"><span className="gx-font-weight-bold">Tiendas Cerca</span></Title>
              </button>
            </div>
            {activeKey === "C" ? (
              <div className="gx-searcher-container">
                <Searcher
                  children={renderEntity({
                    entity: "ESPECIALIDAD",
                    data: dataMasters,
                  })}
                  onChange={handleOnChangeTag}
                  loading={masterLoader}
                />
              </div>
            ) : null}
          </div>

          <div className="gx-p-0 gx-d-block gx-d-lg-none">
            <Map
              height={"73vh"}
              activeKey={activeKey}
              dataSource={
                activeKey === "C" ? dataSourceCarpenter : dataSourceShop
              }
              getCarpenterShop={getCarpenterShop}
              handleMarkerClick={handleMarkerClick}
              handleMarkerClose={handleMarkerClose}
            />
          </div>
          <div className="gx-mobile-shops-container gx-p-0 gx-d-block gx-d-lg-none gx-bg-white">
            {activeKey === "C" ? (
              <Carpenter
                dataSource={
                  searchData.length > 0 || searchTag.length > 0
                    ? searchData
                    : dataSourceCarpenter
                }
                loading={geCarpenter && geCarpenter[1].loading}
                isMobile={true}
              />
            ) : (
              <Shop
                dataSource={dataSourceShop}
                loading={getShops && getShops[1].loading}
                isMobile={true}
              />
            )}
          </div>
        </Col>
      </Row>
    </Auxiliary>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NearbyShops);
