import React from "react";

import SubscriptionItem from "./components/SubscriptionItem";
import SubscriptionHeader from "./components/SubscriptionHeader";

const plans = [
  {
    icon: "gift-card",
  },
  { icon: "user-hard-hat", isBest: true },
  { icon: "hammer" },
];

const renderIncludes = (item) => {
  let includes = [];
  if (item && item.sNombre === "Gratuito") {
    includes = [
      `${item?.nOpti} optimizaciones al mes`,
      `${item?.nPryt} proyectos al mes`,
      "Gratis 1 mes en plan Carpintero",
    ];
  } else {
    includes = [
      `${item?.nOpti} optimizaciones al mes`,
      `${item?.nPryt} proyectos al mes`,
      "Envío de cotización por correo electrónico",
      "Logo personalizado para tus cotizaciónes.",
    ];
  }

  return includes;
};

const Subscription = (props) => {
  const { dataSource } = props;
  return (
    <div className="gx-subscription-main-container">
      <SubscriptionHeader />
      <div className="gx-subscription-container">
        {dataSource &&
          dataSource.map((item, idx) => (
            <SubscriptionItem
              key={item?.nId}
              title={item?.sNombre}
              description={item?.sDescripcion}
              price={item?.nPrecio}
              icon={plans[idx].icon}
              isBest={plans[idx]?.isBest}
              include={renderIncludes(item)}
            />
          ))}
      </div>
    </div>
  );
};

export default Subscription;
