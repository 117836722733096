import React, { Component } from "react";
import { Card, Spin } from "antd";
import { connect } from "react-redux";
import { hotkeys } from "react-keyboard-shortcuts";
import { format } from "date-fns";
import Auxiliary from "util/Auxiliary";
import PageBack from "components/PageBack";
import Header from "./Components/Header";
import {
  rxSelectMaterialSummary,
  rxSelectProyectoCotiza,
  rxRegistrarProyectoCotiza,
} from "appRedux/actions";
import Tableros from "./Components/Tables/Tableros";
import TablerosTapaCantos from "./Components/Tables/TablerosTapaCantos";
import Clientes from "./Components/Tables/Clientes";
import Cantos from "./Components/Tables/Cantos";
import CantosCliente from "./Components/Tables/CantosCliente";
import Restos from "./Components/Tables/Restos";
import ModalCotizacion from "./Components/Modal/index";
import { printExistingElement } from "react-print-tool";
import crypt from "node-cryptex";
import { k, v } from "util/config";
import moment from "moment-timezone";
import Seo from "../../../components/seo";
moment.tz("America/Lima");

class MaterialSummary extends Component {
  constructor(props) {
    super(props);
    this.state = { nIdProyecto: null, sEstado: "Emitido", visible: false };
  }
  componentDidMount() {
    const { nIdProyecto, sEstado } = this.props.match.params;
    if (nIdProyecto) {
      const $nIdProyecto = JSON.parse(crypt.decrypt(nIdProyecto, k, v));
      const $sEstado = JSON.parse(crypt.decrypt(sEstado, k, v));
      this.setState({ nIdProyecto: $nIdProyecto, sEstado: $sEstado });
      this.props.rxSelectMaterialSummary($nIdProyecto);
    }
  }
  // modal
  showModal = (nId) => {
    this.props.rxSelectProyectoCotiza(nId);
    this.setState({ visible: true });
  };

  handleOk = () => {
    const { authUser, authSucursal, rxRegistrarProyectoCotiza } = this.props;
    const { nIdProyecto } = this.state;
    const form = new FormData();
    authSucursal && form.set("nIdSucursal", authSucursal.nIdSucursal);
    nIdProyecto && form.set("nIdProyecto", nIdProyecto);
    authUser && form.set("sUser", authUser.sUsuario);
    rxRegistrarProyectoCotiza(form, this.redirectToOrder);
    this.setState({ visible: false });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  redirectToOrder = (nIdPedido) => {
    const $nIdPedido = crypt.encrypt(JSON.stringify(nIdPedido), k, v);
    const $bType = crypt.encrypt(JSON.stringify(1), k, v);
    this.props.history.push(`/sales/orders/edit/${$nIdPedido}/${$bType}`);
  };
  // retroceder
  onBack = () => {
    this.props.history.goBack();
  };
  // imprimir
  handleOnPrint = async () => {
    await printExistingElement("#materialSummary");
  };

  render() {
    const {
      projectMaterialSummary,
      fetchingProjectMaterialSummary,
      fetchingCotizacion,
      listCotizacion,
      savingOrderInProject,
    } = this.props;
    // const { nIdProyecto, sEstado } = this.state;
    // const pageId = extractPageIdWithParameters(pathname, 1);
    // const read = validatePermission("sales.projects.material-summary");
    const dHeader = projectMaterialSummary
      ? projectMaterialSummary.dHeader
      : null;
    const tableros = projectMaterialSummary
      ? projectMaterialSummary.tableros
      : [];
    const tblTapaCantos = projectMaterialSummary
      ? projectMaterialSummary.tblTapaCantos
      : [];
    const tblCliente = projectMaterialSummary
      ? projectMaterialSummary.tblCliente
      : [];
    const tblCantos = projectMaterialSummary
      ? projectMaterialSummary.tblCantos
      : [];
    const tblCantosCliente = projectMaterialSummary
      ? projectMaterialSummary.tblCantosCliente
      : [];
    const tblRestos = projectMaterialSummary
      ? projectMaterialSummary.tblRestos
      : [];

    return (
      <Auxiliary>
        <Seo
          title="Resúmen de Materiales"
          description="Programa para optimizar cortes de melamina gratis"
          url={window.location.href}
        />
        <div id="materialSummary">
          <Card
            title={
              <PageBack
                arrowTitle="Regresar"
                pageTitle="MATERIALES Y SERVICIOS"
                handleOnClick={this.onBack}
              />
            }
            className="gx-mx-0 gx-mx-lg-4"
            bordered={true}
          >
            <Spin tip="Cargando..." spinning={fetchingProjectMaterialSummary}>
              <Header dHeader={dHeader} />
              {Array(tblTapaCantos) && tblTapaCantos.length > 0 ? (
                <TablerosTapaCantos dataSource={tblTapaCantos} />
              ) : null}
              {Array(tableros) && tableros.length > 0 ? (
                <Tableros dataSource={tableros} />
              ) : null}
              {Array(tblCliente) && tblCliente.length > 0 ? (
                <Clientes dataSource={tblCliente} />
              ) : null}
              {Array(tblCantos) && tblCantos.length > 0 ? (
                <Cantos dataSource={tblCantos} />
              ) : null}
              {Array(tblCantosCliente) && tblCantosCliente.length > 0 ? (
                <CantosCliente dataSource={tblCantosCliente} />
              ) : null}
              {Array(tblRestos) && tblRestos.length > 0 ? (
                <Restos dataSource={tblRestos} />
              ) : null}
            </Spin>
          </Card>
          <span className="gx-show-on-print gx-d-none">
            fecha y hora de impresión: {format(new Date(), "dd/MM/yyy HH:mm")}
          </span>
        </div>
        <ModalCotizacion
          visible={this.state.visible}
          handleOk={this.handleOk}
          onCancel={this.handleCancel}
          loading={fetchingCotizacion}
          dataSource={listCotizacion}
          savingOrderInProject={savingOrderInProject}
        />
      </Auxiliary>
    );
  }
}

const mapStateToProps = ({ auth, settings, projects }) => {
  const { pathname } = settings;
  const { selectRoles, permissions, authSucursal, authUser } = auth;
  const {
    projectMaterialSummary,
    fetchingProjectMaterialSummary,
    fetchingCotizacion,
    listCotizacion,
    savingOrderInProject,
  } = projects;
  return {
    pathname,
    selectRoles,
    permissions,
    projectMaterialSummary,
    fetchingProjectMaterialSummary,
    authSucursal,
    authUser,
    fetchingCotizacion,
    listCotizacion,
    savingOrderInProject,
  };
};
const mapDispatchToProps = {
  rxSelectMaterialSummary,
  rxSelectProyectoCotiza,
  rxRegistrarProyectoCotiza,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(hotkeys(MaterialSummary));
