import gql from "graphql-tag";

export const REGISTER_SHOP = gql`
  mutation registerShop(
    $ubigeo_id: ID!
    $name: String!
    $web: String
    $phone_numbers: String!
    $address: String!
    $location: String
    $lat: String!
    $lng: String!
    $email: String!
    $contact: String!
    $thumbnail: createCoverPageInput
    $attention: String
    $services: String
    $products: String
    $brands: String
  ) {
    registerShop(
      input: {
        ubigeo_id: $ubigeo_id
        name: $name
        web: $web
        phone_numbers: $phone_numbers
        address: $address
        location: $location
        lat: $lat
        lng: $lng
        email: $email
        contact: $contact
        thumbnail: $thumbnail
        attention: $attention
        services: $services
        products: $products
        brands: $brands
      }
    ) {
      id
    }
  }
`;
export const UPDATE_SHOP = gql`
  mutation updateShop(
    $id: ID!
    $ubigeo_id: ID!
    $name: String!
    $web: String
    $phone_numbers: String!
    $address: String!
    $location: String
    $lat: String!
    $lng: String!
    $email: String!
    $contact: String!
    $thumbnail: updateCoverPageInput
    $attention: String
    $services: String
    $products: String
    $brands: String
  ) {
    updateShop(
      input: {
        id: $id
        ubigeo_id: $ubigeo_id
        name: $name
        web: $web
        phone_numbers: $phone_numbers
        address: $address
        location: $location
        lat: $lat
        lng: $lng
        email: $email
        contact: $contact
        thumbnail: $thumbnail
        attention: $attention
        services: $services
        products: $products
        brands: $brands
      }
    ) {
      id
    }
  }
`;

export const DELETE_SHOP = gql`
  mutation deleteShop($id: ID!) {
    deleteShop(id: $id) {
      id
    }
  }
`;
