export const schema = {
  sNombres: {
    rule: {
      required: true,
      message: "Este campo es requerido",
    },
    label: "Nombres",
    name: "sNombres",
    placeHolder: "",
  },
  sApellidos: {
    rule: {
      required: true,
      message: "Este campo es requerido",
    },
    label: "Apellidos",
    name: "sApellidos",
    placeHolder: "",
  },
  sTipoDoc: {
    rule: {
      required: false,
      message: "Este campo es requerido",
    },
    label: "Tipo Documento",
    name: "Tipo Documento",
    placeHolder: "",
  },
  sDocumento: {
    rule: {
      required: false,
      message: "Este campo es requerido",
    },
    label: "Documento",
    name: "sDocumento",
    placeHolder: "",
  },
  nTelefono1: {
    rule: {
      required: true,
      message: "Este campo es requerido",
    },
    label: "Teléfono",
    name: "nTelefono1",
    placeHolder: "",
  },
  nTelefono2: {
    rule: {
      required: true,
      message: "Este campo es requerido",
    },
    label: "Telefono2",
    name: "nTelefono2",
    placeHolder: "",
  },
  sCorreo: {
    rule: {
      required: true,
      message: "Este campo es requerido",
    },
    label: "Correo",
    name: "sCorreo",
    placeHolder: "",
  },
  sDireccion: {
    rule: {
      required: true,
      message: "Este campo es requerido",
    },
    label: "Dirección",
    name: "sDireccion",
    placeHolder: "",
  },
  sDistrito: {
    rule: {
      required: true,
      message: "Este campo es requerido",
    },
    label: "Distrito",
    name: "sDistrito",
    placeHolder: "",
  },
  sEspecialidad: {
    rule: {
      required: true,
      message: "Este campo es requerido",
    },
    label: "Especialidad",
    name: "sEspecialidad",
    placeHolder: "",
  },
  sProfesion: {
    rule: {
      required: true,
      message: "Este campo es requerido",
    },
    label: "Profesión",
    name: "sProfesion",
    placeHolder: "",
  },
  dFRegistro: {
    rule: {
      required: true,
      message: "Este campo es requerido",
    },
    label: "Fecha de registro",
    name: "dFRegistro",
    placeHolder: "",
  },
  bMembresia: {
    rule: {
      required: true,
      message: "Este campo es requerido",
    },
    label: "Membresía",
    name: "bMembresia",
    placeHolder: "",
  },
  bSituacion: {
    rule: {
      required: true,
      message: "Este campo es requerido",
    },
    label: "Situación",
    name: "bSituacion",
    placeHolder: "",
  },
  nTExperiencia: {
    rule: {
      required: true,
      message: "Este campo es requerido",
    },
    label: "Años de experiencia",
    name: "nTExperiencia",
    placeHolder: "",
  },
  dFNacimiento: {
    rule: {
      required: true,
      message: "Este campo es requerido",
    },
    label: "Fecha de nacimiento",
    name: "dFNacimiento",
    placeHolder: "",
  },
  bSexo: {
    rule: {
      required: true,
      message: "Este campo es requerido",
    },
    label: "Sexo",
    name: "bSexo",
    placeHolder: "",
  },
};
