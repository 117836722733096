import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "antd";

const PageBack = props => {
  const {
    // iconType = "arrow-left",
    arrowTitle,
    arrowPlaceMent = "top",
    pageTitle,
    handleOnClick,
    headerContetn
  } = props;
  return (
    <div className="gx-page-back-container gx-d-flex gx-align-items-center">
      <div className="gx-font-weight-medium">
        <Tooltip title={arrowTitle} placement={arrowPlaceMent}>
          <i className="far fa-long-arrow-left gx-mr-3 gx-pointer" onClick={handleOnClick}/>
        </Tooltip>
        {pageTitle ? pageTitle : ""}
      </div>
      {headerContetn ? headerContetn : null}
    </div>
  );
};

PageBack.propTypes = {
  iconType: PropTypes.string,
  arrowTitle: PropTypes.any,
  arrowPlaceMent: PropTypes.string,
  pageTitle: PropTypes.any.isRequired,
  handleOnClick: PropTypes.func.isRequired,
  headerContetn: PropTypes.any
};

export default PageBack;
