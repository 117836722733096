import React, { Component } from "react";
import { Input } from "antd";

const { Search } = Input;
let searchTimer = 0;
const withSearchOnTable = WrappedComponente => {
  return class WithSearchOnTable extends Component {
    constructor(props) {
      super(props);
      this.state = {
        resultSearch: [],
        onSearchNow: false
      };
    }
    getInputSearchProps = (
      dataIndex,
      dataList,
      placeholder = "Buscar...",
      refRearch = null,
      size = "default"
    ) => (
      <Search
        allowClear
        ref={refRearch}
        size={size}
        placeholder={placeholder}
        onChange={event => {
          const { value } = event.target;
          clearInterval(searchTimer);
          searchTimer = setTimeout(() => {
            this.handleSearch(value, dataList, dataIndex);
          }, 300);
        }}
        // className="gx-ml-1"
      />
    );

    handleSearch = (value, dataList, dataIndex) => {
      value.length >= 1
        ? this.setState({ onSearchNow: true })
        : this.setState({ onSearchNow: false });

      if (dataList) {
        let valueSearch = `(${value.trim().replace(/ /g, ").+?(")})`;
        const regExp = new RegExp(valueSearch, "gi");
        const result = dataList.filter(item => regExp.exec(item[dataIndex]));
        this.setState({ resultSearch: result.length > 0 ? result : [] });
      }
    };

    render() {
      const { resultSearch, onSearchNow } = this.state;
      return (
        <WrappedComponente
          {...this.props}
          getInputSearchProps={this.getInputSearchProps}
          resultSearch={resultSearch}
          onSearchNow={onSearchNow}
        />
      );
    }
  };
};

export default withSearchOnTable;
