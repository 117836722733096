import axios from "../../util/Api";
import crypt from "node-cryptex";
import { k, v } from "../../util/config";
import { message } from "antd";
import {
  FETCH_START,
  FETCH_ERROR,
  FETCH_SUCCESS,
  FETCH_ADDITIONALS,
  SHOW_MODAL_CONFIG_ADDITIONALS,
  START_SAVING_ADDITIONALS,
  FETCH_SUCCESS_SAVING_ADDITIONALS,
  FETCH_ERROR_SAVING_ADDITIONALS,
  FETCH_START_DELETING_ADDITIONALS,
  FETCH_SUCCESS_DELETING_ADDITIONALS,
  FETCH_ERROR_DELETING_ADDITIONALS,
  UPDATE_ADDITIONALS,
  DELETE_ADDITIONALS,
  SAVE_ADDITIONALS
} from "constants/ActionTypes";

export const getAdditionals = () => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    axios
      .get("/system/config-patient")
      .then(({ data }) => {
        const decryptedRecords = JSON.parse(crypt.decrypt(data.record, k, v));
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: FETCH_ADDITIONALS, payload: decryptedRecords });
      })
      .catch(error => {
        message.error(error.message);
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const saveAdditionals = (
  {
    sEntidad,
    sCodigo,
    sDescripcion,
    nOrden,
    sEquivSunat,
    sValorOpc = 0,
    bEstado
  },
  form,
  id = null
) => {
  return dispatch => {
    dispatch({ type: START_SAVING_ADDITIONALS });

    const info = new FormData();
    info.set("sEntidad", sEntidad);
    info.set("sCodigo", sCodigo);
    info.set("sDescripcion", sDescripcion);
    info.set("nOrden", nOrden);
    info.set("sEquivSunat", sEquivSunat);
    info.set("sValorOpc", sValorOpc);
    info.set("bEstado", bEstado);

    const url = id ? `/${id}` : "";
    const method = id ? "PATCH" : "POST";
    axios({ url: `/system/general${url}`, data: info, method })
      .then(({ data }) => {
        if (data.state === "Successful") {
          const decrypted = JSON.parse(crypt.decrypt(data.record, k, v));
          id
            ? dispatch({ type: UPDATE_ADDITIONALS, payload: decrypted })
            : dispatch({ type: SAVE_ADDITIONALS, payload: decrypted });
          dispatch({
            type: FETCH_SUCCESS_SAVING_ADDITIONALS,
            payload: decrypted
          });
          message.success(data.message);
          form.resetFields();
        } else {
          message.error(data.message);
        }
      })
      .catch(error => {
        message.error(error.message);
        dispatch({
          type: FETCH_ERROR_SAVING_ADDITIONALS,
          payload: error.message
        });
      });
  };
};

export const deleteAdditionals = id => {
  return dispatch => {
    dispatch({ type: FETCH_START_DELETING_ADDITIONALS, payload: id });
    axios
      .delete(`/system/general/${id}`)
      .then(({ data }) => {
        if (data.state === "Successful") {
          dispatch({ type: DELETE_ADDITIONALS, payload: id });
          dispatch({
            type: FETCH_SUCCESS_DELETING_ADDITIONALS,
            payload: data.message
          });
          message.success(data.message);
        } else {
          message.error(data.message);
        }
      })
      .catch(error => {
        message.error(error.message);
        dispatch({
          type: FETCH_ERROR_DELETING_ADDITIONALS,
          payload: error.message
        });
      });
  };
};

export const showModalAdditionals = payload => {
  return dispatch => {
    dispatch({ type: SHOW_MODAL_CONFIG_ADDITIONALS, payload });
  };
};
