import axios from "util/Api";
import { k, v } from "util/config";
import crypt from "node-cryptex";
import { message } from "antd";
import {
  FETCH_START_LOCATION,
  FETCH_SUCCESS_LOCATION,
  FETCH_ERROR_LOCATION,
  CLEAN_LOCATION_LIST
} from "constants/ActionTypes";
let timer = 0;
export const searchLocation = ({ sLocation }) => {
  return dispacth => {
    dispacth({ type: FETCH_START_LOCATION });
    clearTimeout(timer);
    timer = setTimeout(() => {
      axios
        .get(`/system/ubigeo/${sLocation ? sLocation.trim() : null}`)
        .then(({ data }) => {
          const decryptedRecords = JSON.parse(
            crypt.decrypt(data.records, k, v)
          );
          dispacth({ type: FETCH_SUCCESS_LOCATION, payload: decryptedRecords });
        })
        .catch(error => {
          dispacth({ type: FETCH_ERROR_LOCATION, payload: error.message });
          message.error(error.message);
        });
    }, 500);
  };
};

export const cleanLocationList = () => {
  return dispatch => {
    dispatch({type: CLEAN_LOCATION_LIST});
  };
};