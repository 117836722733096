import React from "react";
import axios from "../../util/Api";
import { message } from "antd";
import crypt from "node-cryptex";
import { k, v } from "../../util/config";
import { showErrorInApi, displayNotificationMessage } from "../../util/helpers";
import { printComponent } from "react-print-tool";
import { PrintStyle } from "../../util/printStyle";
import LabelsPDF from "../../pdf/LabelsPDF";
import {
  FETCH_PROJECTS_LIST_START,
  FETCH_PROJECTS_LIST_SUCCESS,
  FETCH_PROJECTS_LIST_ERROR,
  SELECT_HIDE_SHOW_PROJECT,
  FETCH_PROJECTS_DETAILS_START,
  FETCH_PROJECTS_DETAILS_SUCCESS,
  FETCH_PROJECTS_DETAILS_ERROR,
  SHOW_HIDE_MODAL_CANTOS,
  UPDATE_DATA_DETAIL_PROJECT,
  FETCH_BOARD_LIST_START,
  FETCH_BOARD_LIST_SUCCESS,
  FETCH_BOARD_LIST_ERROR,
  SELECT_TABLE_IN_PROJECT_DETAIL,
  FETCH_EDGES_LIST_START,
  FETCH_EDGES_LIST_SUCCESS,
  FETCH_EDGES_LIST_ERROR,
  FETCH_GROOVE_LIST_START,
  FETCH_GROOVE_LIST_SUCCESS,
  FETCH_GROOVE_LIST_ERROR,
  FETCH_PERFORATION_LIST_START,
  FETCH_PERFORATION_LIST_SUCCESS,
  FETCH_PERFORATION_LIST_ERROR,
  TOGGLE_MODAL_GROOVE,
  TOGGLE_MODAL_PERFORATION,
  FETCH_GROOVE_LIST_D25_START,
  FETCH_GROOVE_LIST_D25_SUCCESS,
  FETCH_GROOVE_LIST_D25_ERROR,
  FETCH_PERFORATION_LIST_D25_START,
  FETCH_PERFORATION_LIST_D25_SUCCESS,
  FETCH_PERFORATION_LIST_D25_ERROR,
  TOGGLE_MODAL_GROOVE_D25,
  TOGGLE_MODAL_PERFORATION_D25,
  RESET_TABLEPROYECT_DETAIL,
  FETCH_PROJECTS_ADD_EDIT_START,
  FETCH_PROJECTS_ADD_EDIT_SUCCESS,
  FETCH_PROJECTS_ADD_EDIT_ERROR,
  FETCH_OPTIMIZE_DATA_START,
  FETCH_OPTIMIZE_DATA_SUCCESS,
  FETCH_OPTIMIZE_DATA_ERROR,
  FETCH_PLANS_DATA_START,
  FETCH_PLANS_DATA_SUCCESS,
  FETCH_PLANS_DATA_ERROR,
  FETCH_UPDATE_STATE_PROJECT_START,
  FETCH_UPDATE_STATE_PROJECT_SUCCESS,
  FETCH_UPDATE_STATE_PROJECT_ERROR,
  FETCH_MATERIAL_SAMMARY_START,
  FETCH_MATERIAL_SAMMARY_SUCCESS,
  FETCH_MATERIAL_SAMMARY_ERROR,
  FETCH_COTIZA_PROJECT_START,
  FETCH_COTIZA_PROJECT_SUCCESS,
  FETCH_COTIZA_PROJECT_ERROR,
  FETCH_COTIZA_PROJECT_INSERT_START,
  FETCH_COTIZA_PROJECT_INSERT_SUCCESS,
  FETCH_COTIZA_PROJECT_INSERT_ERROR,
  FETCH_DUPLICATE_PROJECT_START,
  FETCH_DUPLICATE_PROJECT_SUCCESS,
  FETCH_DUPLICATE_PROJECT_ERROR,
  FETCH_LABELS_PROJECT_LIST_START,
  FETCH_LABELS_PROJECT_LIST_SUCCESS,
  FETCH_LABELS_PROJECT_LIST_ERROR,
  FETCH_SELECT_TRACING_PROJECT_START,
  FETCH_SELECT_TRACING_PROJECT_SUCCESS,
  FETCH_SELECT_TRACING_PROJECT_ERROR,
  TOGGLE_MODAL_MATERIAL,
  FETCH_SUCCESS,
  HANDLE_RESET_FILTER_PROJECT,
  OBTENER_INFORMACION_ADICONAL_USUARIO_START,
  OBTENER_INFORMACION_ADICONAL_USUARIO_SUCCESS,
  OBTENER_INFORMACION_ADICONAL_USUARIO_ERROR,
  RX_TOGGLE_MODAL_TABLEROS,
  RX_SET_DATA_LOYEND_TABLE_BOARDS,
  START_FILTERING_OPTIMIZER,
  SUCCESS_FITLERING_OPTIMIZER,
  ERROR_FITLERING_OPTIMIZER,
  CHANGE_VALUE_FILTER_OPTIMIZER,
  CLEAR_FILTER_OPTIMIZER
} from "constants/ActionTypes";

export const changeFilterProject = ({field, value}) => dispatch => {
  return dispatch({
    type: CHANGE_VALUE_FILTER_OPTIMIZER,
    payload: {field, value}
  })
}

export const clearFilterOptimizer = () => dispatch => {
  return dispatch({type: CLEAR_FILTER_OPTIMIZER})
}

export const filterProjects = ({nIdUsuario, sProyecto, nIdCliente, dDesde, dHasta, setVisible}) => dispatch => {
  dispatch({type: START_FILTERING_OPTIMIZER});

  const body = new FormData();
  nIdUsuario && body.append("nIdUsuario", nIdUsuario);
  sProyecto && body.append("sProyecto", sProyecto);
  nIdCliente && body.append("nIdCliente", nIdCliente);
  dDesde && body.append("dDesde", dDesde);
  dHasta && body.append("dHasta", dHasta);

  axios.post("/system/projects/list", body).then(({data}) => {
    const records = JSON.parse(crypt.decrypt(data.records, k, v));
    dispatch({
      type: SUCCESS_FITLERING_OPTIMIZER,
      payload: records
    });
    if (setVisible) {
      setVisible(false)
    }
  }).catch(error => {
    showErrorInApi(error);
    dispatch({type: ERROR_FITLERING_OPTIMIZER});
  });
};

const selectProyectosListar = ({
  nIdUsuario,
  sProyecto,
  nIdCliente,
  dDesde,
  dHasta
}) => {
  return dispatch => {
    dispatch({
      type: FETCH_PROJECTS_LIST_START,
      payload: { fetchingProyect: true }
    });

    const body = new FormData();
    nIdUsuario && body.append("nIdUsuario", nIdUsuario);
    sProyecto && body.append("sProyecto", sProyecto);
    nIdCliente && body.append("nIdCliente", nIdCliente);
    dDesde && body.append("dDesde", dDesde);
    dHasta && body.append("dHasta", dHasta);

    axios
      .post("/system/projects/list", body)
      .then(({ data }) => {
        const records = JSON.parse(crypt.decrypt(data.records, k, v));
        dispatch({
          type: FETCH_PROJECTS_LIST_SUCCESS,
          payload: {
            fetchingProyect: false,
            listProyects: records
          }
        });
        dispatch({
          type: FETCH_SUCCESS
        })
      })
      .catch(error => {
        showErrorInApi(error);
        dispatch({
          type: FETCH_PROJECTS_LIST_ERROR,
          payload: { fetchingProyect: false, listProyects: [] }
        });
      });
  };
};

const rxHandleResetFilter = () => ({
  type: HANDLE_RESET_FILTER_PROJECT
});

const rxRegistroEditarProyecto = (data, nId = null) => {
  return dispatch => {
    dispatch({
      type: FETCH_PROJECTS_ADD_EDIT_START,
      payload: { fetchingProyect: true }
    });
    const url = nId ? "/system/projects/edit" : "/system/projects/add";
    const method = nId ? "PATCH" : "POST";
    axios({ url, data, method })
      .then(({ data }) => {
        dispatch({
          type: FETCH_PROJECTS_ADD_EDIT_SUCCESS,
          payload: data.record
        });
        displayNotificationMessage({ title: data.message });
      })
      .catch(error => {
        showErrorInApi(error);
        dispatch({
          type: FETCH_PROJECTS_ADD_EDIT_ERROR,
          payload: { fetchingProyect: false, listProyects: [] }
        });
      });
  };
};
/**
 * DETALLE PROYECTO
 */
const detalleProyecto = nIdProyecto => {
  return dispatch => {
    dispatch({ type: FETCH_PROJECTS_DETAILS_START });
    axios
      .get(`/system/projects/detail/${nIdProyecto}`)
      .then(({ data }) => {
        const record = JSON.parse(data.record);
        const records = JSON.parse(data.records);
        const edges = JSON.parse(data.edges);
        const listCodeBoards = JSON.parse(data.listCodeBoards);
        dispatch({
          type: FETCH_PROJECTS_DETAILS_SUCCESS,
          payload: { header: record, details: records, listCodeEdges: edges, listCodeBoards }
        });
      })
      .catch(error => {
        showErrorInApi(error);
        dispatch({ type: FETCH_PROJECTS_DETAILS_ERROR });
      });
  };
};

const rxOptimizarDataProyecto = ({ nIdProyecto, sEstado, sObs, sUser }) => {
  return dispatch => {
    dispatch({ type: FETCH_OPTIMIZE_DATA_START });
    const formData = new FormData();
    formData.set("sEstado", sEstado);
    formData.set("sObs", sObs);
    formData.set("sUser", sUser);
    axios
      .post(`/system/projects/optimize/${nIdProyecto}`, formData)
      .then(({ data }) => {
        if (data.state === "Successful") {
          dispatch({
            type: FETCH_OPTIMIZE_DATA_SUCCESS,
            payload: data.records
          });
          displayNotificationMessage({ title: data.message });
        }
      })
      .catch(error => {
        showErrorInApi(error);
        dispatch({ type: FETCH_OPTIMIZE_DATA_ERROR, payload: error.message });
      });
  };
};

const rxSelecionarProyectoPlano = nIdProyecto => {
  return dispatch => {
    dispatch({ type: FETCH_PLANS_DATA_START });
    axios({ url: `/system/projects/plans/${nIdProyecto}`, method: "post" })
      .then(({ data }) => {
        if (data.state === "Successful") {
          dispatch({
            type: FETCH_PLANS_DATA_SUCCESS,
            payload: JSON.parse(data.records)
          });
        }
      })
      .catch(error => {
        showErrorInApi(error);
        dispatch({ type: FETCH_PLANS_DATA_ERROR, payload: error.message });
      });
  };
};
const rxSelectMaterialSummary = nIdProyecto => {
  return async dispatch => {
    try {
      dispatch({ type: FETCH_MATERIAL_SAMMARY_START });
      const response = await axios.get(
        `/system/projects/material-summary/${nIdProyecto}`
      );
      const data = await response.data;
      dispatch({
        type: FETCH_MATERIAL_SAMMARY_SUCCESS,
        payload: JSON.parse(data.records)
      });
    } catch (error) {
      showErrorInApi(error);
      dispatch({ type: FETCH_MATERIAL_SAMMARY_ERROR, payload: error.message });
    }
  };
};
const rxActualizarProyectoEstado = ({ nIdProyecto, sEstado, sObs, sUser }) => {
  return dispatch => {
    dispatch({ type: FETCH_UPDATE_STATE_PROJECT_START });
    const formData = new FormData();
    formData.set("nIdProyecto", nIdProyecto);
    formData.set("sEstado", sEstado);
    formData.set("sObs", sObs);
    formData.set("sUser", sUser);
    axios
      .patch(`/system/projects/update/state/${nIdProyecto}`, formData)
      .then(({ data }) => {
        if (data.state === "Successful") {
          dispatch({
            type: FETCH_UPDATE_STATE_PROJECT_SUCCESS,
            payload: true
          });
          displayNotificationMessage({
            title: data.message
          });
        }
      })
      .catch(error => {
        showErrorInApi(error);
        dispatch({
          type: FETCH_UPDATE_STATE_PROJECT_ERROR,
          payload: error.message
        });
      });
  };
};

/**
 * TABLERO (BOARD)
 */

const rxTableroListar = (nId) => {
  return dispatch => {
    dispatch({ type: FETCH_BOARD_LIST_START });
    axios
      .get(`/system/boards/list/${nId}`)
      .then(({ data }) => {
        const records = JSON.parse(data.records);
        dispatch({
          type: FETCH_BOARD_LIST_SUCCESS,
          payload: records
        });
      })
      .catch(error => {
        showErrorInApi(error);
        dispatch({ type: FETCH_BOARD_LIST_ERROR });
      });
  };
};
/**
 * TABLERO (EDGES)
 */

const rxCantosListar = (nId) => {
  return dispatch => {
    dispatch({ type: FETCH_EDGES_LIST_START });
    axios
      .get(`/system/boards/edges/list/${nId}`)
      .then(({ data }) => {
        const records = JSON.parse(data.records);
        dispatch({
          type: FETCH_EDGES_LIST_SUCCESS,
          payload: records
        });
      })
      .catch(error => {
        showErrorInApi(error);
        dispatch({ type: FETCH_EDGES_LIST_ERROR });
      });
  };
};
/**
 * TABLERO (GROOVE)
 */

const rxRanuraListar = () => {
  return dispatch => {
    dispatch({ type: FETCH_GROOVE_LIST_START });
    axios
      .get(`/system/boards/groove/list`)
      .then(({ data }) => {
        const records = JSON.parse(data.records);
        dispatch({
          type: FETCH_GROOVE_LIST_SUCCESS,
          payload: records
        });
      })
      .catch(error => {
        showErrorInApi(error);
        dispatch({ type: FETCH_GROOVE_LIST_ERROR });
      });
  };
};

const rxRanuraD25Listar = () => {
  return dispatch => {
    dispatch({ type: FETCH_GROOVE_LIST_D25_START });
    axios
      .get(`/system/boards/groove/d25/list`)
      .then(({ data }) => {
        const records = JSON.parse(data.records);
        dispatch({
          type: FETCH_GROOVE_LIST_D25_SUCCESS,
          payload: records
        });
      })
      .catch(error => {
        showErrorInApi(error);
        dispatch({ type: FETCH_GROOVE_LIST_D25_ERROR });
      });
  };
};
/**
 * TABLERO (PERFORATION)
 */
const rxPerforacionListar = () => {
  return dispatch => {
    dispatch({ type: FETCH_PERFORATION_LIST_START });
    axios
      .get(`/system/boards/perforation/list`)
      .then(({ data }) => {
        const records = JSON.parse(data.records);
        dispatch({
          type: FETCH_PERFORATION_LIST_SUCCESS,
          payload: records
        });
      })
      .catch(error => {
        showErrorInApi(error);
        dispatch({ type: FETCH_PERFORATION_LIST_ERROR });
      });
  };
};

const rxPerforacionD25Listar = () => {
  return dispatch => {
    dispatch({ type: FETCH_PERFORATION_LIST_D25_START });
    axios
      .get(`/system/boards/perforation/d25/list`)
      .then(({ data }) => {
        const records = JSON.parse(data.records);
        dispatch({
          type: FETCH_PERFORATION_LIST_D25_SUCCESS,
          payload: records
        });
      })
      .catch(error => {
        showErrorInApi(error);
        dispatch({ type: FETCH_PERFORATION_LIST_D25_ERROR });
      });
  };
};
const rxSelectProyectoCotiza = nIdProyecto => {
  return async dispatch => {
    try {
      dispatch({ type: FETCH_COTIZA_PROJECT_START });
      const url = `/system/projects/cotizacion/select/${nIdProyecto}`;
      const response = await axios.get(url);
      const data = await response.data;
      dispatch({
        type: FETCH_COTIZA_PROJECT_SUCCESS,
        payload: JSON.parse(data.records)
      });
    } catch (error) {
      showErrorInApi(error);
      dispatch({ type: FETCH_COTIZA_PROJECT_ERROR });
    }
  };
};
const rxRegistrarProyectoCotiza = (form, callBack) => {
  return async dispatch => {
    try {
      dispatch({ type: FETCH_COTIZA_PROJECT_INSERT_START });
      const url = `/system/projects/cotizacion/insert`;
      const response = await axios.post(url, form);
      const data = await response.data;
      const nIdPedido = data.record;
      dispatch({
        type: FETCH_COTIZA_PROJECT_INSERT_SUCCESS,
        payload: nIdPedido
      });
      callBack(nIdPedido);
    } catch (error) {
      showErrorInApi(error);
      dispatch({ type: FETCH_COTIZA_PROJECT_INSERT_ERROR });
    }
  };
};
const rxDuplicarProyectoCopia = (form, callBack) => {
  return async dispatch => {
    try {
      dispatch({ type: FETCH_DUPLICATE_PROJECT_START });
      const url = `/system/projects/duplicate`;
      const response = await axios.post(url, form);
      const data = await response.data;
      if (data.state === "Successful") {
        dispatch({ type: FETCH_DUPLICATE_PROJECT_SUCCESS });
        displayNotificationMessage({
          title: data.message,
          description: "Proyecto duplicado correctamente."
        });
        callBack({});
      }
    } catch (error) {
      showErrorInApi(error);
      dispatch({ type: FETCH_DUPLICATE_PROJECT_ERROR });
    }
  };
};
const rxSeleccionarProyectoSeguimiento = nIdProyecto => {
  return async dispatch => {
    try {
      dispatch({ type: FETCH_SELECT_TRACING_PROJECT_START });
      const url = `/system/projects/tracing/list/${nIdProyecto}`;
      const response = await axios.get(url);
      const data = await response.data;
      dispatch({
        type: FETCH_SELECT_TRACING_PROJECT_SUCCESS,
        payload: JSON.parse(data.records)
      });
    } catch (error) {
      showErrorInApi(error);
      dispatch({ type: FETCH_SELECT_TRACING_PROJECT_ERROR });
    }
  };
};
const rxSeleccionarProyectoEtiquetas = nId => {
  return async dispatch => {
    try {
      dispatch({ type: FETCH_LABELS_PROJECT_LIST_START });
      const url = `/system/projects/labels/list/${nId}`;
      const response = await axios.get(url);
      const data = await response.data;
      const dataSource = JSON.parse(data.records);
      dispatch({
        type: FETCH_LABELS_PROJECT_LIST_SUCCESS,
        payload: dataSource
      });
      if (dataSource.length > 0) {
        const tamanio = `10cm ${dataSource.length * 6 + 1}cm`;
        await PrintStyle(tamanio, "0.5cm");
        await printComponent(
          <LabelsPDF dataSource={dataSource} width={9} height={6} />
        );
      } else {
        message.warning("No existen etiquetas para Imprimir");
      }
    } catch (error) {
      showErrorInApi(error);
      dispatch({ type: FETCH_LABELS_PROJECT_LIST_ERROR });
    }
  };
};

/**
 * CLIENTE INFO ADICIONAL
 */
const rxSelectProyetoCliente = nIdCliente => {
  return async dispatch => {
    try {
      dispatch({ type: OBTENER_INFORMACION_ADICONAL_USUARIO_START });
      const url = `/system/projects/client/info/${nIdCliente}`;
      const response = await axios.get(url);
      const data = await response.data;
      if (data && data.record) {
        dispatch({
          type: OBTENER_INFORMACION_ADICONAL_USUARIO_SUCCESS,
          payload: data.record
        });
      }
    } catch (error) {
      showErrorInApi(error);
      dispatch({
        type: OBTENER_INFORMACION_ADICONAL_USUARIO_ERROR
      });
    }
  };
};

/**
 * SELECCIONAR TABLERO
 */

export const rxSeleccionarTableInProjectDetail = payload => ({
  type: SELECT_TABLE_IN_PROJECT_DETAIL,
  payload
});

/**
 * SELECT PROJECTO>ACCIÓNES
 */
const onSelectProject = payload => ({
  type: SELECT_HIDE_SHOW_PROJECT,
  payload
});

/**
 * MODAL MATERIAL
 */

const toggleModalMaterial = payload => {
  return dispatch => {
    dispatch({
      type: TOGGLE_MODAL_MATERIAL,
      payload
    });
  };
};

/**
 * MODAL CANTOS
 */
const toggleModalCantos = payload => ({
  type: SHOW_HIDE_MODAL_CANTOS,
  payload
});

/**
 * MODAL RANURA
 */

const toggleModalRanura = payload => ({ type: TOGGLE_MODAL_GROOVE, payload });
const toggleModalRanuraD25 = payload => ({
  type: TOGGLE_MODAL_GROOVE_D25,
  payload
});
/**
 * MODAL PERFORATION
 */
const toggleModalPerforacion = payload => ({
  type: TOGGLE_MODAL_PERFORATION,
  payload
});
const toggleModalPerforacionD25 = payload => ({
  type: TOGGLE_MODAL_PERFORATION_D25,
  payload
});

/**
 * DETAIL PROJECT
 */
const updateDataDetailProject = payload => ({
  type: UPDATE_DATA_DETAIL_PROJECT,
  payload
});

/**
 * RESET DETALLE PROYECTO
 */
const resetTableProyectDetail = () => ({ type: RESET_TABLEPROYECT_DETAIL });

/**
*TOGGLE MODAL TBL
*/
const rxToggleModalTableros = payload => ({
  type: RX_TOGGLE_MODAL_TABLEROS,
  payload
});

/**
 * MODIFICAR DATOS TABLA LEYENDA - TABLEROS(BOARDS)
 */
const rxSetDataLeyendTableBoards = payload => ({
  type: RX_SET_DATA_LOYEND_TABLE_BOARDS,
  payload
});

export {
  selectProyectosListar,
  onSelectProject,
  detalleProyecto,
  resetTableProyectDetail,
  toggleModalMaterial,
  toggleModalCantos,
  updateDataDetailProject,
  rxTableroListar,
  rxCantosListar,
  rxRanuraListar,
  rxPerforacionListar,
  toggleModalRanura,
  toggleModalPerforacion,
  rxRanuraD25Listar,
  rxPerforacionD25Listar,
  toggleModalRanuraD25,
  toggleModalPerforacionD25,
  rxRegistroEditarProyecto,
  rxOptimizarDataProyecto,
  rxSelecionarProyectoPlano,
  rxActualizarProyectoEstado,
  rxSelectMaterialSummary,
  rxSelectProyectoCotiza,
  rxRegistrarProyectoCotiza,
  rxDuplicarProyectoCopia,
  rxSeleccionarProyectoEtiquetas,
  rxSeleccionarProyectoSeguimiento,
  rxHandleResetFilter,
  rxSelectProyetoCliente,
  rxToggleModalTableros,
  rxSetDataLeyendTableBoards
};
