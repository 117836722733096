import React, { useState, Fragment, useRef } from "react";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Row,
  Col,
  Card,
  // Divider,
  Spin,
  Modal,
  message,
  Radio,
  Tooltip,
} from "antd";
import { connect } from "react-redux";
//import { Link } from "react-router-dom";
//import { ReactComponent as IconRowrker } from "assets/icons/carpintero.svg";
//import { ReactComponent as IconYoung } from "assets/icons/equipo.svg";
import { openNotification, onErrorMessage } from "util/helpers";
import { schema } from "./schema";
import { ADD_USER } from "graphql/mutation/user";
import { VERIFY_EXISTENCE_MAIL } from "graphql/query/user";
//import Widget from "components/Widget/index";
import Informacion from "../../assets/images/informacion.svg";
import Seo from "../../components/seo";
const exp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/;
// const exp = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

const wrapperStyle = {
  minWidth: "320px",
  maxWidth: "500px",
  width: "100%",
  margin: "0 auto",
};

/*const iconStyle = {
  width: 60,
  height: 60,
};*/

const CreateAccount = (props) => {
  const { getFieldDecorator, validateFieldsAndScroll, resetFields } =
    props.form;

  const [names, setNames] = useState(null);
  const [lastNames, setLastNames] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [politics, setPolitics] = useState(false);
  const [validateEmail, setValidateEmail] = useState("");
  const [messaMailExist, setMessaMailExist] = useState("");
  const emailRef = useRef(null);

  const [addUser, { loading }] = useMutation(ADD_USER, {
    onCompleted() {
      handleUserRegisterSuccess();
    },
    onError(error) {
      onErrorMessage(error, false);
    },
  });

  const verifyEmail = useLazyQuery(VERIFY_EXISTENCE_MAIL, {
    onCompleted({ verifyExistenceMail }) {
      if (verifyExistenceMail && verifyExistenceMail.email) {
        setValidateEmail("error");
        setMessaMailExist("El correo electrónico ya se encuentra registrado");
      } else {
        setValidateEmail("success");
        setMessaMailExist("");
      }
    },
    onError(error) {
      onErrorMessage(error, false);
    },
  });

  const handleOnBlur = ({ target: { value } }) => {
    if (value) {
      const regExp = new RegExp(exp, "gi");
      if (regExp.test(value.trim())) {
        const [verifyExistenceMail] = verifyEmail;
        verifyExistenceMail({ variables: { email: value.trim() } });
        setMessaMailExist("");
        setValidateEmail("");
      } else {
        setValidateEmail("error");
        setMessaMailExist("El correo no es válido");
      }
    }
  };

  const handleUserRegisterSuccess = () => {
    Modal.success({
      content: (
        <Fragment>
          Hemos enviado un email de confirmación con instrucciones sobre cómo
          verificar tu cuenta a la dirección de email de registro:
          <span className="gx-pl-1">{email || ""}</span>
          <span className="gx-text-warning gx-mt-2 gx-pl-1 gx-d-block">
            No olvide revisar la carpeta de spam.
          </span>
        </Fragment>
      ),
      okText: "De acuerdo",
      onOk: () => {
        props.history.push("/login");
      },
      onCancel: () => {
        props.history.push("/login");
      },
    });
    resetFields();
    setValidateEmail("");
    setMessaMailExist("");
    setEmail(null);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateEmail !== "error") {
      validateFieldsAndScroll((err, values) => {
        if (!err) {
          const {
            sPassword,
            sPasswordRepeat,
            politics,
            sApellidos,
            sCorreo,
            sNombres,
            especialidad,
          } = values;
          if (sPassword === sPasswordRepeat) {
            if (especialidad === "CLIENTE" || especialidad === "CARPINTERO") {
              if (politics) {
                addUser({
                  variables: {
                    name: sNombres,
                    last_name: sApellidos,
                    email: sCorreo,
                    password: sPassword,
                    user_type: especialidad,
                  },
                });
              } else {
                openNotification({
                  type: "warning",
                  message: "Servicio Y Políticas",
                  description:
                    "Es importante que Aceptes los Términos de Servicio y Política de Privacidad",
                });
              }
            } else {
              openNotification({
                type: "info",
                message: "No se pudo registrar el usuario",
              });
            }
          } else {
            openNotification({
              type: "info",
              message: "Las contraseñas no coinciden",
            });
          }
        }
      });
    } else {
      message.error(
        "El correo electrónico ya se encuentra registrado, ingrese un correo válido."
      );
    }
  };

  return (
    <div className="gx-mb-3">
      <Card style={wrapperStyle}>
        <Seo
          title="Registrate fácil en eCarpintero 📝"
          url={window.location.href}
          description={`Crea tu cuenta en eCarpintero, solo necesitas un correo.`}
          keywords="registrate,carpintero"
        />
        <Form
          onSubmit={handleSubmit}
          className="gx-form-row0 gx-text-left"
          hideRequiredMark
        >
          <Spin spinning={loading} tip="Creando Cuenta">
            <Row type="flex" justify="center">
              <Col span={24} className=" gx-mb-0">
                <h1
                  className="gx-font-weight-bold"
                  style={{
                    color: "#000000",
                    fontFamily: "Open Sans",
                    fontSize: "22px",
                  }}
                >
                  {" "}
                  Registrarse
                </h1>
                <Card
                  className="gx-card-full gx-dot-arrow-hover gx-mb-0 gx-mb-md-2"
                  style={{
                    boxShadow: "none!important",
                    border: 0,
                    borderRadius: 0,
                  }}
                >
                  <div className="gx-mb-3 gx-media gx-align-items-center gx-flex-nowrap">
                    <div>
                      <p
                        className="gx-mb-0 gx-text-black gx-fs-s"
                        style={{ fontFamily: "Open Sans" }}
                      >
                        Ingrese sus datos para crear una cuenta en eCarpintero.
                      </p>
                      <p
                        className="gx-mb-0 gx-text-black gx-fs-s"
                        style={{ fontFamily: "Open Sans" }}
                      >
                        Luego seleccione el tipo de usuario.
                      </p>
                    </div>
                  </div>
                </Card>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                <Form.Item className="form-control-ec">
                  {getFieldDecorator(schema.sNombres.name, {
                    rules: [
                      {
                        required: schema.sNombres.rule.required,
                        message: schema.sNombres.rule.message,
                      },
                    ],
                  })(
                    <Input
                      autoFocus
                      placeholder={schema.sNombres.label}
                      onChange={(e) => setNames(e.target.value)}
                    />
                  )}
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12} lg={12}>
                <Form.Item className="form-control-ec">
                  {getFieldDecorator(schema.sApellidos.name, {
                    rules: [
                      {
                        required: schema.sApellidos.rule.required,
                        message: schema.sApellidos.rule.message,
                      },
                    ],
                  })(
                    <Input
                      placeholder={schema.sApellidos.label}
                      onChange={(e) => setLastNames(e.target.value)}
                    />
                  )}
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={24} lg={24}>
                <Form.Item
                  className="form-control-ec"
                  hasFeedback
                  validateStatus={
                    verifyEmail && verifyEmail[1].loading
                      ? "validating"
                      : validateEmail
                  }
                  help={messaMailExist}
                >
                  {getFieldDecorator(schema.sCorreo.name, {
                    rules: [
                      {
                        required: schema.sCorreo.rule.required,
                        message: schema.sCorreo.rule.message,
                      },
                      {
                        type: "email",
                        message: "El correo no es válido",
                      },
                    ],
                  })(
                    <Input
                      // type="email"
                      placeholder={schema.sCorreo.label}
                      onChange={(e) => setEmail(e.target.value)}
                      onBlur={handleOnBlur}
                      ref={emailRef}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Form.Item hasFeedback className="form-control-ec">
                  {getFieldDecorator("sPassword", {
                    rules: [
                      {
                        required: true,
                        message:
                          "Ingrese una contraseña con al menos 8 caracteres",
                        min: 8,
                      },
                    ],
                  })(
                    <Input.Password
                      placeholder="Contraseña"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Form.Item hasFeedback className="form-control-ec">
                  {getFieldDecorator("sPasswordRepeat", {
                    rules: [
                      {
                        required: true,
                        message: "Repita su contraseña.",
                        min: 8,
                      },
                    ],
                  })(
                    <Input.Password
                      placeholder="Repetir contraseña"
                      onChange={(e) => setRepeatPassword(e.target.value)}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Form.Item>
                  {getFieldDecorator("especialidad")(
                    <Radio.Group className="gx-d-flex gx-align-items-center gx-mt-1">
                      <Radio
                        className="gx-text-black"
                        value="CLIENTE"
                        style={{ fontSize: "12px" }}
                      >
                        Cliente
                      </Radio>
                      <Tooltip title="Contacta a los carpinteros especializados de forma rápida y totalmente segura.">
                        <img src={Informacion} width="3%" alt="Información" />
                      </Tooltip>
                      <Radio
                        className="gx-text-black gx-ml-5"
                        value="CARPINTERO"
                        style={{ fontSize: "12px" }}
                      >
                        Especialista
                      </Radio>
                      <Tooltip title="Publica tus proyectos  y permite que los clientes te encuentren.">
                        <img src={Informacion} width="3%" alt="Información" />
                      </Tooltip>
                    </Radio.Group>
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={24} className="gx-mt-3">
                <Form.Item className="gx-text-left form-control-ec">
                  {getFieldDecorator(
                    "politics",
                    {
                      valuePropName: "checked",
                      initialValue: politics,
                    },
                    {
                      rules: [
                        {
                          required: true,
                          message:
                            "Acepta Términos de Servicio & Política de Privacidad",
                        },
                      ],
                    }
                  )(
                    <Checkbox
                      className="gx-container-checked-description"
                      onChange={(e) => setPolitics(e.target.checked)}
                    >
                      Acepto
                      <a
                        href="/terminos-y-condiciones"
                        target="_blank"
                        className="gx-pr-1 gx-pl-1"
                      >
                        Términos de Servicio
                      </a>
                      y
                      <a
                        href="/politicas-de-privacidad"
                        target="_blank"
                        className="gx-pr-1 gx-pl-1"
                      >
                        Política de Privacidad
                      </a>
                    </Checkbox>
                  )}
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={24} lg={24}>
                <Form.Item className="form-control-ec gx-mb-0">
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button gx-ml-0 gx-mt-2"
                    block
                    disabled={
                      !names ||
                      !lastNames ||
                      password.length < 8 ||
                      !politics ||
                      (validateEmail !== "success" && validateEmail !== "")
                    }
                  >
                    CREAR CUENTA
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Spin>
        </Form>
      </Card>
      <div className="gx-d-none">{repeatPassword}</div>
    </div>
  );
};

const WrappedCreateAccount = Form.create({ name: "create_account_form" })(
  CreateAccount
);

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedCreateAccount);
