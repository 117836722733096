import React from "react";
import { Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";

import Profile from "./profile/User";
import OptionsPanel from "./options-panel";
import Projects from "./Project/User";
import Error404 from "./customViews/errorPages/404";
import Joined from "./joined";
import Activation from "./optimizer/Activation";
import ProjectTable from "./optimizer/Table/ProjectTable";
import ProjectForm from "./optimizer/Form";
// import Planos from "./optimizer/Plans/Planos";
import MaterialSummary from "./optimizer/MaterialSummary";

const App = ({ match }) => {
  const { authUser } = useSelector(({ auth }) => auth);
  return (
    <div className="gx-main-content-wrapper">
      <Switch>
        <Route path={`${match.url}/perfil`} exact component={Profile} />
        <Route
          path={`${match.url}/panel-de-opciones`}
          exact
          component={OptionsPanel}
        />
        <Route path={`${match.url}/proyectos`} exact component={Projects} />
        <Route path={`${match.url}/joined`} exact component={Joined} />

        {authUser.optimizer && (
          <Route
            path={`${match.url}/optimizador`}
            exact
            component={ProjectTable}
          />
        )}
        {authUser.optimizer && (
          <Route
            path={`${match.url}/optimizador/add`}
            exact
            component={ProjectForm}
          />
        )}
        {authUser.optimizer && (
          <Route
            path={`${match.url}/optimizador/edit/:nIdProyecto`}
            exact
            component={ProjectForm}
          />
        )}
        {authUser.optimizer && (
          <Route
            path={`${match.url}/optimizador/material-summary/:nIdProyecto/:sEstado`}
            exact
            component={MaterialSummary}
          />
        )}
        {!authUser.optimizer && (
          <Route
            path={`${match.url}/optimizador/activar`}
            exact
            component={Activation}
          />
        )}

        <Route component={Error404} />
      </Switch>
    </div>
  );
};

export default App;
