import React, { useEffect, useState } from "react";
import { Button, Form, Input, Spin, Result } from "antd";
import { useMutation } from "@apollo/react-hooks";
import { openNotification } from "util/helpers";
import { UPDATE_FORGOTTEN_PASSWORD } from "./../../graphql/mutation/auth";
import { withRouter, Link } from "react-router-dom";
import Seo from "../../components/seo";
import qs from 'qs';

const FormItem = Form.Item;

const ResetPassword = (props) => {
  const { resetFields, getFieldDecorator } = props.form;
  const [sToken, setToken] = useState(null);
  const [sEmail, setEmail] = useState(null);
  const [displayPage, setDisplayPage] = useState(true);

  const [updateForgottenPassword, { loading }] = useMutation(
    UPDATE_FORGOTTEN_PASSWORD,
    {
      onCompleted({ updateForgottenPassword }) {
        const { status, message } = updateForgottenPassword;
        if (status === "PASSWORD_UPDATED") {
          openNotification({
            message: "Bien",
            description: message || "Correo Electronico enviado",
            type: "success",
          });
          props.history.push("/login");
          resetFields();
        } else {
          openNotification({
            message: "Error",
            description: message || "No se pudo actualizar contraseña",
            type: "error",
          });
        }
      },
      onError({ graphQLErrors, networkError }) {
        if (graphQLErrors) {
          graphQLErrors.forEach(({ extensions }) => {
            openNotification({
              message: "[Error]",
              description: extensions.reason,
              type: "error",
            });
          });
        }
        if (networkError) {
          openNotification({
            message: `[Error de Red]`,
            description: networkError,
            type: "error",
          });
        }
      },
    }
  );

  useEffect(() => {
    try {
      if (props && props.location) {
        const query = qs.parse(props.location.search, {ignoreQueryPrefix: true});
        setEmail(query.email);
        setToken(query.token);
      } else {
        setDisplayPage(false)
      }
    } catch (e) {
      setDisplayPage(false);
    }
  }, [props]);

  const handleSubmit = (e) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        updateForgottenPassword({
          variables: {
            token: sToken,
            email: sEmail,
            password: values.password,
            password_confirmation: values.confirm,
          },
        });
      }
    });
  };

  const compareToFirstPassword = (rule, value, callback) => {
    const form = props.form;
    if (value && value !== form.getFieldValue("password")) {
      callback("Last dos contraseñas que ingresas son inconsistentes!");
    } else {
      callback();
    }
  };

  const validateToNextPassword = (rule, value, callback) => {
    const form = props.form;
    if (value && props.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  if (!displayPage) {
    return (
      <div className="gx-text-center gx-pt-4">
        <h2>eCarpintero</h2>
        <Result
          status="403"
          title="¡Oh no! Ha ocurrido un error"
          subTitle="Lo sentimos, no es posible continuar con la recuperación de su contraseña."
          extra={(
            <Button type="primary">
              <Link to="/login">Iniciar sesión</Link>
            </Button>
          )}
        />
      </div>
    )
  }

  return (
    <div className="gx-login-container">
      <Seo
        title="Restablecer la contraseña"
        url={window.location.href}
        description={`Ingrese una nueva contraseña para su cuenta`}
        keywords="carpintero"
      />
      <div className="gx-login-content">
        <div className="gx-login-header">
          <img
            src={require("assets/images/eCarpintero-logo.svg")}
            alt="eCarpintero"
            title="eCarpintero"
          />
        </div>
        <div className="gx-mb-4">
          <h2>Restablecer la contraseña</h2>
          <p>Ingrese una nueva contraseña para su cuenta</p>
        </div>

        <Spin spinning={loading}>
          <Form onSubmit={handleSubmit} className="gx-login-form gx-form-row0">
            <FormItem>
              {getFieldDecorator("password", {
                rules: [
                  {
                    required: true,
                    message: "Por favor ingrese su contraseña!",
                  },
                  {
                    validator: validateToNextPassword,
                  },
                ],
              })(<Input type="password" placeholder="Nueva contraseña" />)}
            </FormItem>

            <FormItem>
              {getFieldDecorator("confirm", {
                rules: [
                  {
                    required: true,
                    message: "Por favor, confirme su contraseña!",
                  },
                  {
                    validator: compareToFirstPassword,
                  },
                ],
              })(
                <Input
                  placeholder="Reescriba nueva contraseña"
                  type="password"
                />
              )}
            </FormItem>

            <FormItem>
              <Button type="primary" block htmlType="submit">
                REINICIAR
              </Button>
            </FormItem>
          </Form>
        </Spin>
      </div>
    </div>
  );
};

const WrappedResetPasswordForm = Form.create()(ResetPassword);

export default withRouter(WrappedResetPasswordForm);
