import React, { useEffect, useState, useRef } from "react";
import { Modal, Row, Col, Table } from "antd";
import { connect } from "react-redux";
import { toggleModalCantos } from "appRedux/actions";
import withSearchOnTable from "HOC/withSearchOnTable";
// import currency from "currency-formatter";
// import { currencyFE } from "util/config";

const Cantos = ({
  modalCantos,
  toggleModalCantos,
  fetchingEdges,
  listEdges,
  resultSearch,
  onSearchNow,
  getInputSearchProps,
  handleOnSelectEdgesModal
}) => {
  const refSearch = useRef();
  const [dataSource, setDataSource] = useState([]);
  useEffect(() => {
    refSearch.current && refSearch.current.focus();
    if (modalCantos && modalCantos.visible && dataSource.length === 0) {
      setTimeout(() => {
        setDataSource(listEdges.length > 0 ? listEdges.slice(0, 10) : []);
      }, 500);
    }
  }, [modalCantos, listEdges, dataSource]);

  const handleCancelModalCantos = () => {
    toggleModalCantos({ visible: false, info: null });
  };

  const columns = [
    {
      title: "Descripción",
      dataIndex: "sDescCanto",
      key: "sDescCanto"
    },
    /* {
      title: "Stock",
      dataIndex: "nStock",
      key: "nStock",
      align: "center",
      width: 120,
      render: value =>
        isNaN(value) ? (
          <Fragment>
            0
            <Icon
              type="warning"
              theme="twoTone"
              twoToneColor="#ffae42"
              className="gx-pl-1"
            />
          </Fragment>
        ) : value > 0 ? (
          value
        ) : (
          <Fragment>
            0
            <Icon
              type="warning"
              theme="twoTone"
              twoToneColor="#ffae42"
              className="gx-pl-1"
            />
          </Fragment>
        )
    },
    {
      title: "Precio",
      dataIndex: "nPrecioFinal",
      key: "nPrecioFinal",
      align: "right",
      width: 100,
      render: text =>
        text ? currency.format(Number(text).toFixed(2), currencyFE) : "0.00"
    }, */
    {
      title: "Espesor",
      dataIndex: "nEspesor",
      key: "nEspesor",
      align: "center",
      width: 100
    },
    {
      title: "Ancho",
      dataIndex: "nAncho",
      key: "nAncho",
      align: "center",
      width: 100
    }
  ];
  return (
    <Modal
      visible={modalCantos && modalCantos.visible}
      onCancel={handleCancelModalCantos}
      title="Cantos"
      footer={null}
      width={"50%"}
    >
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24}>
          {getInputSearchProps(
            "sDescCanto",
            listEdges,
            "Buscar canto",
            refSearch,
            "default"
          )}
        </Col>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Table
            dataSource={
              resultSearch.length > 0 || onSearchNow === true
                ? resultSearch
                : dataSource
            }
            title={() => (
              <span>
                <i className="fad fa-mouse gx-pr-1 gx-text-color-primary" />
                Doble click para seleccionar un Canto.
              </span>
            )}
            loading={fetchingEdges}
            columns={columns}
            pagination={false}
            bordered={true}
            size={"middle"}
            rowKey={row => row.nIdCanto}
            rowClassName="gx-pointer"
            scroll={{ y: "calc(100vh - 360px)" }}
            onRow={(row, index) => {
              return {
                onDoubleClick: event => {
                  handleOnSelectEdgesModal(row, index, modalCantos);
                }
              };
            }}
          />
          <p className="gx-text-muted gx-fs-sm gx-mb-0 gx-mt-2">Todas las medidas están expresadas en milímetros (mm)</p>
        </Col>
      </Row>
    </Modal>
  );
};

const mapStateToProps = ({ projects }) => {
  const { modalCantos, fetchingEdges, listEdges } = projects;
  return { modalCantos, fetchingEdges, listEdges };
};

export default connect(mapStateToProps, {
  toggleModalCantos
})(withSearchOnTable(Cantos));
