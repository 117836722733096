import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import Settings from "./Settings";
import Auth from "./Auth";
import Notes from "./Notes";
import Common from "./Common";
import Project from "./Project";
import Shops from "./Shops";
import User from "./User";
import Client from "./Client";
import Projects from "./Projects";
import Clients from "./Clients";
import ReniecSunat from "./ReniecSunat";
import Ubigeo from "./Ubigeo";
import ConfigAdicionales from "./ConfigAdicionales";
import General from "./General";

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    auth: Auth,
    notes: Notes,
    common: Common,
    project: Project,
    shops: Shops,
    user: User,
    client: Client,
    projects: Projects,
    clients: Clients,
    reniecSunat: ReniecSunat,
    ubigeo: Ubigeo,
    configadicionales: ConfigAdicionales,
    general: General
  });

export default createRootReducer;
