import gql from "graphql-tag";

const GET_MASTERS = gql`
  query getMasters(
    $column: MastersWhereColumn
    $operator: SQLOperator
    $value: Mixed
  ) {
    masters(where: { column: $column, operator: $operator, value: $value }) {
      id
      entity
      description
      parent_id
    }
  }
`;

const GET_TAGS = gql`
  query tags($where: MastersWhereWhereConditions, $order: [MastersOrderOrderByClause!]) {
    masters(where: $where, order: $order) {
      description
    }
  }
`;

export {GET_MASTERS, GET_TAGS};
