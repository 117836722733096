import React, {useState, useEffect} from 'react'
import {Modal, Button, Input, List, Row, Col, Alert, Card, Spin} from 'antd'
import validator from 'email-validator'
import phoneValidator from 'awesome-phonenumber'
import {useMutation} from '@apollo/react-hooks'

import {spinner} from '../../../util/customComponents'
import {CREATE_QUOTATION} from '../../../graphql/mutation/quotation'
import wizard_quotation from '../../../assets/images/wizard_quotation.jpg'

const WizardQuotation = ({visible, plan, setVisible, setPlan}) => {
  const [user, setUser] = useState({name: null, cellphone: null, email: null, commentaries: null})
  const [foundUser, setFoundUser] = useState(false)
  const [anotherPerson, setAnotherPerson] = useState(false)
  const [step, setStep] = useState(1)
  const [closing, setClosing] = useState(false)
  const [error, setError] = useState(false)
  const [emailWarning, setEmailWarning] = useState(false)
  const [cellphoneWarning, setCellphoneWarning] = useState(false)
  const [sending, setSending] = useState(false)
  const [successful, setSuccessful] = useState(false)

  const icon = {basic: 'pencil-ruler', standard: 'hammer', advanced: 'galaxy'}[plan]
  const color = {basic: '#039BE5', standard: '#2E3A4D', advanced: '#C26E2D'}[plan]
  const rgba = {basic: '3, 155, 229', standard: '46, 58, 77', advanced: '194, 110, 45'}[plan]
  const textPlan = {basic: 'Básico', standard: 'Estándar', advanced: 'Avanzado'}[plan]


  const closeWizard = () => {
    setUser({name: null, cellphone: null, email: null, commentaries: null})
    setVisible(false)
    setFoundUser(false)
    setAnotherPerson(false)
    setPlan(false)
    setStep(1)
    setClosing(true)
    setError(false)
    setEmailWarning(false)
    setCellphoneWarning(false)
    setSending(false)
    setSuccessful(false)
  }

  const quotationAnotherPerson = () => {
    setAnotherPerson({name: null, cellphone: null, email: null, commentaries: null})
    setStep(2)
  }

  const validateEmail = (value, self = true) => {
    if (self) {
      setUser({...user, email: value ? value.toLowerCase() : ''})
    } else {
      setAnotherPerson({...anotherPerson, email: value ? value.toLowerCase() : ''})
    }
    if (value) {
      if (validator.validate(value)) {
        setEmailWarning(false)
      } else {
        setEmailWarning('Inválido')
      }
    }
  }

  const verifyCellphone = (cellphone, self = true) => {
    if (self) {
      setUser({...user, cellphone})
    } else {
      setAnotherPerson({...anotherPerson, cellphone})
    }
    if (cellphone) {
      const number = new phoneValidator(cellphone, 'PE')
      if (!number.isValid()) {
        setCellphoneWarning('Inválido')
      } else {
        setCellphoneWarning(false)
      }
    }
  }

  const [createQuotationMutation] = useMutation(CREATE_QUOTATION, {
    async onCompleted({createQuotation}) {
      if (createQuotation.status) {
        setSuccessful(true)
      } else {
        setSuccessful(false)
        setError('No pudemos registrar tu solicitud de cotización')
      }
    },
    onError() {
      setError('Error al registrar cotización')
    }
  })

  const submitQuotation = async (self = true) => {
    const client = self ? user.name : anotherPerson.name
    const email = self ? user.email : anotherPerson.email
    const cellphone = self ? user.cellphone : anotherPerson.cellphone
    const commentaries = self ? user.commentaries : anotherPerson.commentaries
    if (email && cellphone) {
      const number = new phoneValidator(cellphone, 'PE')
      if (!number.isValid()) {
        setCellphoneWarning('Inválido')
        return
      }
      if (!validator.validate(email)) {
        setEmailWarning('Inválido')
        return
      }
      setSending(true)
      const variables = {
        input: {client, cellphone, email, commentaries, plan: plan.toUpperCase()}
      }
      if (!self) {
        variables.input['referenced_user'] = JSON.stringify({name: user.name, cellphone: user.cellphone, email: user.email})
      }
      await createQuotationMutation({variables}).then(() => {
        setSending(false)
      })
    } else {
      setError('Por favor ingrese la información solicitada')
    }
  }

  useEffect(() => {
    const userStorage = localStorage.getItem('user')
    if (userStorage) {
      if (!user.name) {
        const parsed = JSON.parse(userStorage)
        setUser({
          name: `${parsed.name} ${parsed.last_name}`,
          cellphone: parsed.cellphone,
          email: parsed.email
        })
        setFoundUser(true)
      }
    }
    if (visible && closing) {
      setClosing(false)
    }
  }, [user, visible, closing, setClosing])
  
  return (
    <Modal
      centered
      visible={visible}
      footer={null}
      keyboard={false}
      closable={false}
      maskClosable={false}
      onCancel={closeWizard}
    >
      {closing ? 'Cerrando...' : (
        <Spin spinning={sending} indicator={spinner}>
          {successful ? (
            <div>
              <h1 className="gx-text-center gx-my-2 gx-font-weight-bold">
                ¡Solicitud registrada!
              </h1>
              <img src={wizard_quotation} alt="Ilustración" className="gx-mt-2 gx-mb-2"/>
              <p className="gx-text-center gx-mt-3">
                {user.name && user.name.split(' ')[0]} tu solicitud de cotización ha sido registrada, nos pondremos en contacto con usted durante el transcurso del día.
              </p>
              <div className="gx-d-flex gx-justify-content-center">
                <Button shape="round" onClick={closeWizard} className="gx-mb-2 gx-mt-3">
                  Cerrar ventana
                </Button>
              </div>
            </div>
          ) : (
            <div>
              {foundUser && (
                <div>
                  {step === 1 && (
                    <div>
                      <h1 className="gx-text-center gx-my-2 gx-font-weight-bold">Hola {user.name.split(' ')[0]}.</h1>
                      <p className="gx-text-center">¿Deseas que la cotización se haga a tu nombre?</p>
                      <img src={wizard_quotation} alt="Ilustración" className="gx-mt-2 gx-mb-2"/>
                      <List bordered>
                        <List.Item
                          onClick={() => setStep(2)}
                          extra={<i className="far fa-long-arrow-right"/>}
                          className="gx-pointer hovered-list-item"
                        >
                          Si, continuar
                        </List.Item>
                        <List.Item
                          onClick={quotationAnotherPerson}
                          extra={<i className="far fa-long-arrow-right"/>}
                          className="gx-pointer hovered-list-item"
                        >
                          No, solicitar para otra persona
                        </List.Item>
                        <List.Item
                          onClick={closeWizard}
                          className="gx-pointer gx-text-danger"
                        >
                          Cancelar
                        </List.Item>
                      </List>
                    </div>
                  )}
                  {step === 2 && (
                    <div>
                      {anotherPerson ? (
                        <div>
                          <img src={wizard_quotation} alt="Ilustración" className="gx-mb-2"/>
                          <h2 className="gx-font-weight-bold">Genial {user.name.split(' ')[0]}</h2>
                          <p>Para continuar necesitamos saber los nombres y apellidos de la persona hacia quién irá dirigida la cotización.</p>
                          <Input
                            autoFocus
                            placeholder="Nombres y apellidos"
                            allowClear
                            onChange={e => {
                              const value = e.target.value
                              setAnotherPerson({...anotherPerson, name: value})
                            }}
                          />
                          <div className="gx-d-flex gx-justify-content-end gx-mt-3">
                            <Button
                              type="primary"
                              disabled={!anotherPerson.name}
                              onClick={() => setStep(3)}
                              className="gx-mb-0"
                            >
                              Continuar
                            </Button>
                            <Button onClick={closeWizard} className="gx-mb-0">
                              Cancelar
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <h2 className="gx-font-weight-bold">¡Perfecto! Un paso más...</h2>
                          <p>Verifica que tu información sea corrrecta y si tienes alguna duda o pregunta hágalo saber.</p>
                          <Card className="gx-card-widget" style={{border: `1px solid ${color}`, backgroundColor: `rgba(${rgba}, .1)`}}>
                            <div className="gx-media gx-align-items-center gx-flex-nowrap">
                              <div className="gx-mr-lg-4 gx-mr-3">
                                <i className={`fad fa-${icon} gx-fs-xlxl gx-d-flex`} style={{fontSize: 40, color: color}}/>
                              </div>
                              <div className="gx-media-body gx-ml-2">
                                <h1 className="gx-fs-xxxl gx-font-weight-medium gx-mb-1">
                                  Plan {textPlan}
                                </h1>
                                <p className="gx-text-grey gx-mb-0">
                                  {user.name}
                                </p>
                              </div>
                            </div>
                          </Card>
                          <Row guttter={16} className="gx-mt-3 gx-mb-2">
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                              <label htmlFor="cellphone" className="gx-d-flex">
                                Celular
                                {cellphoneWarning && <small className="gx-text-danger gx-ml-auto">{cellphoneWarning}</small>}
                              </label>
                              <Input
                                autoFocus
                                allowClear
                                id="cellphone"
                                placeholder="Número de celular"
                                className="gx-mt-1 gx-mb-3 gx-mb-md-0"
                                value={user.cellphone}
                                onChange={e => verifyCellphone(e.target.value)}
                              />
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                              <label htmlFor="email" className="gx-d-flex">
                                Correo electrónico
                                {emailWarning && <small className="gx-text-danger gx-ml-auto">{emailWarning}</small>}
                              </label>
                              <Input
                                allowClear
                                id="email"
                                placeholder="ejemplo@mail.com"
                                className="gx-mt-1 gx-mb-3"
                                value={user.email}
                                onChange={e => validateEmail(e.target.value)}
                              />
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                              <label htmlFor="commentaries">Comentarios</label>
                              <Input.TextArea
                                id="commentaries"
                                placeholder="¿Tiene alguna pregunta o duda adicional? Escríbela aquí..."
                                className="gx-mt-1"
                                autoSize={{
                                  maxRows: 4,
                                  minRows: 3
                                }}
                                allowClear
                                value={user.commentaries}
                                onChange={e => setUser({...user, commentaries: e.target.value})}
                              />
                            </Col>
                          </Row>
                          {error && (
                            <Alert
                              banner
                              type="error"
                              message={null}
                              description={error}
                              showIcon={false}
                            />
                          )}
                          <small className="gx-text-muted">* El celular y correo electrónico son obligatorios.</small>
                          <div className="gx-mt-3 gx-d-flex gx-justify-content-end">
                            <Button
                              type="primary"
                              className="gx-mb-0"
                              disabled={!user.email || !user.cellphone || emailWarning || cellphoneWarning}
                              onClick={submitQuotation}
                            >
                              Finalizar
                            </Button>
                            <Button onClick={closeWizard} className="gx-mb-0">
                              Cancelar
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {step === 3 && (
                    <div>
                      <h2 className="gx-font-weight-bold">¡Perfecto! Un paso más...</h2>
                      <p>Completa la información solicitada y si tienes alguna duda o pregunta hágalo saber.</p>
                      <Card className="gx-card-widget" style={{border: `1px solid ${color}`, backgroundColor: `rgba(${rgba}, .1)`}}>
                        <div className="gx-media gx-align-items-center gx-flex-nowrap">
                          <div className="gx-mr-lg-4 gx-mr-3">
                            <i className={`fad fa-${icon} gx-fs-xlxl gx-d-flex`} style={{fontSize: 40, color: color}}/>
                          </div>
                          <div className="gx-media-body gx-ml-2">
                            <h1 className="gx-fs-xxxl gx-font-weight-medium gx-mb-1">
                              Plan {textPlan}
                            </h1>
                            <p className="gx-text-grey gx-mb-0">
                              {anotherPerson.name}
                            </p>
                          </div>
                        </div>
                      </Card>
                      <Row guttter={16} className="gx-mt-3 gx-mb-2">
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                          <label htmlFor="cellphone" className="gx-d-flex">
                            Celular
                            {cellphoneWarning && <small className="gx-text-danger gx-ml-auto">{cellphoneWarning}</small>}
                          </label>
                          <Input
                            autoFocus
                            allowClear
                            id="cellphone"
                            placeholder="Número de celular"
                            className="gx-mt-1 gx-mb-3 gx-mb-md-0"
                            value={anotherPerson.cellphone}
                            onChange={e => verifyCellphone(e.target.value, false)}
                          />
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                          <label htmlFor="email" className="gx-d-flex">
                            Correo electrónico
                            {emailWarning && <small className="gx-text-danger gx-ml-auto">{emailWarning}</small>}
                          </label>
                          <Input
                            allowClear
                            id="email"
                            placeholder="ejemplo@mail.com"
                            className="gx-mt-1 gx-mb-3"
                            value={anotherPerson.email}
                            onChange={e => validateEmail(e.target.value, false)}
                          />
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="gx-mt-3">
                          <label htmlFor="commentaries">Comentarios</label>
                          <Input.TextArea
                            id="commentaries"
                            placeholder="¿Tiene alguna pregunta o duda adicional? Escríbela aquí..."
                            className="gx-mt-1"
                            autoSize={{
                              maxRows: 4,
                              minRows: 3
                            }}
                            allowClear
                            value={anotherPerson.commentaries}
                            onChange={e => setAnotherPerson({...anotherPerson, commentaries: e.target.value})}
                          />
                        </Col>
                      </Row>
                      {error && (
                        <Alert
                          banner
                          type="error"
                          message={null}
                          description={error}
                          showIcon={false}
                        />
                      )}
                      <small className="gx-text-muted">* El celular y correo electrónico son obligatorios.</small>
                      <div className="gx-mt-3 gx-d-flex gx-justify-content-end">
                        <Button
                          type="primary"
                          className="gx-mb-0"
                          disabled={!anotherPerson.email || !anotherPerson.cellphone || emailWarning || cellphoneWarning}
                          onClick={() => submitQuotation(false)}
                        >
                          Finalizar
                        </Button>
                        <Button onClick={closeWizard} className="gx-mb-0">
                          Cancelar
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {!foundUser && !anotherPerson && (
                <div>
                  {step === 1 && (
                    <div>
                      <h1 className="gx-text-center gx-my-2">Bienvenido</h1>
                      <p className="gx-text-center gx-d-none gx-d-md-block">Antes de continuar necesitamos saber quién eres.</p>
                      <p className="gx-text-center gx-d-block gx-d-md-none">Antes de continuar indicanos quién eres.</p>
                      <img src={wizard_quotation} alt="Ilustración" className="gx-mb-3"/>
                      <Input
                        autoFocus
                        placeholder="Nombres y apellidos"
                        allowClear
                        onChange={e => {
                          const value = e.target.value
                          setUser({...user, name: value})
                        }}
                      />
                      <div className="gx-d-flex gx-justify-content-end gx-mt-4">
                        <Button
                          type="primary"
                          disabled={!user.name}
                          onClick={() => setStep(2)}
                          className="gx-mb-0"
                        >
                          Continuar
                        </Button>
                        <Button onClick={closeWizard} className="gx-mb-0">
                          Cancelar
                        </Button>
                      </div>
                    </div>
                  )}
                  {step === 2 && (
                    <div>
                      <h1>¡Perfecto {user.name.split(' ')[0]}! Un paso más...</h1>
                      <p>Completa la información solicitada y si tienes alguna duda o pregunta hágalo saber.</p>
                      <Card className="gx-card-widget" style={{border: `1px solid ${color}`, backgroundColor: `rgba(${rgba}, .1)`}}>
                        <div className="gx-media gx-align-items-center gx-flex-nowrap">
                          <div className="gx-mr-lg-4 gx-mr-3">
                            <i className={`fad fa-${icon} gx-fs-xlxl gx-d-flex`} style={{fontSize: 40, color: color}}/>
                          </div>
                          <div className="gx-media-body gx-ml-2">
                            <h1 className="gx-fs-xxxl gx-font-weight-medium gx-mb-1">
                              Plan {textPlan}
                            </h1>
                            <p className="gx-text-grey gx-mb-0">
                              {user.name}
                            </p>
                          </div>
                        </div>
                      </Card>
                      <Row guttter={16} className="gx-mt-3 gx-mb-2">
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                          <label htmlFor="cellphone" className="gx-d-flex">
                            Celular
                            {cellphoneWarning && <small className="gx-text-danger gx-ml-auto">{cellphoneWarning}</small>}
                          </label>
                          <Input
                            autoFocus
                            allowClear
                            id="cellphone"
                            placeholder="Número de celular"
                            className="gx-mt-1 gx-mb-3 gx-mb-md-0"
                            value={user.cellphone}
                            onChange={e => verifyCellphone(e.target.value)}
                          />
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                          <label htmlFor="email" className="gx-d-flex">
                            Correo electrónico
                            {emailWarning && <small className="gx-text-danger gx-ml-auto">{emailWarning}</small>}
                          </label>
                          <Input
                            allowClear
                            id="email"
                            placeholder="ejemplo@mail.com"
                            className="gx-mt-1 gx-mb-3"
                            value={user.email}
                            onChange={e => validateEmail(e.target.value)}
                          />
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                          <label htmlFor="commentaries">Comentarios</label>
                          <Input.TextArea
                            id="commentaries"
                            placeholder="¿Tiene alguna pregunta o duda adicional? Escríbela aquí..."
                            className="gx-mt-1"
                            autoSize={{
                              maxRows: 4,
                              minRows: 3
                            }}
                            allowClear
                            value={user.commentaries}
                            onChange={e => setUser({...user, commentaries: e.target.value})}
                          />
                        </Col>
                      </Row>
                      {error && (
                        <Alert
                          banner
                          type="error"
                          message={null}
                          description={error}
                          showIcon={false}
                        />
                      )}
                      <small className="gx-text-muted">* El celular y correo electrónico son obligatorios.</small>
                      <div className="gx-mt-3 gx-d-flex gx-justify-content-end">
                        <Button
                          type="primary"
                          className="gx-mb-0"
                          disabled={!user.email || !user.cellphone || emailWarning || cellphoneWarning}
                          onClick={submitQuotation}
                        >
                          Finalizar
                        </Button>
                        <Button onClick={closeWizard} className="gx-mb-0">
                          Cancelar
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </Spin>
      )}
    </Modal>
  )
}

export default WizardQuotation
