import React from "react";
import PropTypes from "prop-types";
import { Button } from "antd";
import { hotkeys } from "react-keyboard-shortcuts";

class NextButton extends React.PureComponent {
  static propTypes = {
    button: PropTypes.object.isRequired,
    shortcut: PropTypes.string.isRequired
  };

  handleAction = event => {
    event.preventDefault();
    this.props.button.action();
  };

  handleClick = event => {
    event.preventDefault();
    event.stopPropagation();
    this.props.button.action();
  };

  hot_keys = {
    [this.props.shortcut.toString()]: {
      priority: 1,
      handler: e => this.handleAction(e)
    }
  };

  render() {
    const { button } = this.props;

    return (
      <Button
        type={button.type || "primary"}
        size={button.size || "default"}
        shape={button.shape}
        onClick={this.handleClick}
        className={this.className || ''}
        {...this.props}
      >
        {button.icon} {button.title}
      </Button>
    );
  }
}

export default hotkeys(NextButton);
