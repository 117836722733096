import React, {useState, Fragment} from 'react'
import {Button, Empty, Card, Divider, Menu, Dropdown} from 'antd'
import {useSelector} from 'react-redux'
import moment from 'moment'
import crypt from 'node-cryptex'

import {FilterDrawer} from './components'
import {State} from "components/Statet";
import {k, v} from "util/config";

const OptimizerMobile = ({details}) => {
  const {filterOptimizer: {projects}} = useSelector(({projects}) => projects)
  const [visible, setVisible] = useState(false)

  const menu = project => (
    <Menu>
      <Menu.Item key="1" onClick={() => details(project)}>
        <i className="far fa-info-circle gx-mr-2"/>
        Ver detalles
      </Menu.Item>
      {project.sEstado === 'Optimizado' && (
        <Menu.Item key="2" onClick={() => window.open(
          `/optimizador/planos/${crypt.encrypt(
            JSON.stringify(project.nIdProyecto),
            k,
            v
          )}`,
          "_blank"
        )}>
          <i className="far fa-presentation gx-mr-2"/>
          Ver planos
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <div>
      <FilterDrawer visible={visible} setVisible={setVisible}/>
      <div className="gx-d-flex gx-align-items-center gx-mx-4 gx-mt-4">
        <h2 className="gx-mb-0">Optimizador</h2>
        <div className="gx-ml-auto">
          <Button type="primary" size="small" className="gx-mb-0">
            <i className="far fa-plus gx-mr-2"/>
            Nuevo
          </Button>
          <Button size="small" className="gx-mb-0" onClick={() => setVisible(true)}>
            <i className="far fa-filter gx-mr-2"/>
            Filtrar
          </Button>
        </div>
      </div>
      <div className="gx-mt-3">
        {projects.length > 0 ? (
          <Fragment>
            {projects.map((project, key) => (
              <Card
                key={key}
                title={(
                  <div className="gx-d-flex gx-align-items-center">
                    {project.sProyecto} - {project.sEstado}
                    <div className="gx-ml-auto">
                      <Dropdown overlay={() => menu(project)} trigger={['click']}>
                        <Button type="link" className="gx-mb-0" size="small">
                          <i className="far fa-ellipsis-v gx-fs-md"/>
                        </Button>
                      </Dropdown>
                    </div>
                  </div>
                )}
              >
              <p className="gx-text-grey gx-fs-sm">
                {moment(project.dFechaRegistro).format('LL')}
              </p>
                <h2 className="gx-mb-1">{project.sObra}</h2>
                <p className="gx-text-muted">
                  {project.sNombres}
                </p>
                <div className="gx-d-flex gx-align-items-center">
                  <State text={project.sEstado} letter={false} />
                  <div className="gx-ml-auto">
                    Items: {project.nTotalItems}
                    <Divider type="vertical"/>
                    Cortes: {project.nTotalCortes}
                    <Divider type="vertical"/>
                    Cantos: {project.nTotalCantos}
                  </div>
                </div>
              </Card>
            ))}
          </Fragment>
        ) : (
          <div className="gx-d-flex gx-align-items-center gx-justify-content-center" style={{minHeight: '70vh', height: '100%'}}>
            <Empty description={null}>
              <div>
                No se encontraron registros
                <div className="gx-text-muted">
                  <small>
                    Usa el botón de búsqueda para encontrar tus proyectos
                  </small>
                </div>
                <div className="gx-mt-3">
                  <Button size="small" className="gx-mb-0" onClick={() => setVisible(true)}>
                    <i className="far fa-search gx-mr-2"/>
                    Buscar proyecto
                  </Button>
                </div>
              </div>
            </Empty>
          </div>
        )}
        </div>
    </div>
  )
}

export default OptimizerMobile
