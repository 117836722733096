import React, { Fragment, useState } from "react";
import {Col, Row, Table, Input, Button, Pagination, Tag, message, Empty, Modal, Tooltip} from "antd";
import Auxiliary from "util/Auxiliary";
import { useDispatch } from "react-redux";
import EllipsisText from "react-ellipsis-text";
import capitalize from 'capitalize';
import { useQuery, useMutation } from '@apollo/react-hooks';
import moment from 'moment';
import 'moment/locale/es';

import { CMS_PROJECTS } from '../../../graphql/query/project';
import { CMS_UPDATE_STATUS_PROJECT } from '../../../graphql/mutation/project';
import { toggleProjectModal } from "appRedux/actions";

import { spinner, Error500 } from 'util/customComponents';
import { getVariablesListFromCache } from 'util/helpers';
import ProjectsModal from "../../Project/User/Modal";

const { Search } = Input;

const ProjectTable = props => {
  const dispatch = useDispatch();

  // const [range, setRange] = useState(null);
  const [range] = useState(null);
  const [query, setQuery] = useState(null);
  const [first, setFirst] = useState(20);
  const [page, setPage] = useState(1);

  const { refetch, loading, data, error, networkStatus } = useQuery(CMS_PROJECTS, {
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        graphQLErrors.forEach(error => {
          message.error(error.message);
        });
      }
      if (networkError) {
        message.error(`[Error de Red]: ${networkError}`);
      }
    },
    variables: { query, range, first, page },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  });

  const [updateStatusProject, { loading: updating }] = useMutation(CMS_UPDATE_STATUS_PROJECT, {
    update: (proxy, { data: { updateStatusProject } } ) => {
      message.success(`El proyecto ha sido ${updateStatusProject.status.toLowerCase()}`);
      const query = CMS_PROJECTS;
      const variablesList = getVariablesListFromCache(proxy, query);

      for (const variables of variablesList) {
        const data = proxy.readQuery({ query, variables });
        const filteredIndex = data.filterCMSProjects.data.findIndex(item => item.id === updateStatusProject.id);
        if (filteredIndex > -1) {
          data.filterCMSProjects.data[filteredIndex] = updateStatusProject;
          proxy.writeQuery({ query, data, variables });
        }
      }
    },
    onError({graphQLErrors, networkError }) {
      if (graphQLErrors) {
        graphQLErrors.forEach(({extensions}) => {
          message.warn(extensions.reason);
        });
      }
      if (networkError) {
        message.error(`[Error de Red]: ${networkError}`);
      }
    }
  });

  const changeQuery = e => {
    const value = e.target.value;
    if (!value) {
      setQuery(null);
      refetch();
    }
  };

  const searchProject = value => {
    if (value) {
      setQuery(value);
      refetch();
    }
  };

  const changePagination = page => {
    setPage(page);
    refetch();
  };

  const changeSizePagination = (current, size) => {
    setFirst(size);
    refetch();
  };

  const changeStatus = (project, status) => {
    const modal = Modal.confirm({
      title: `¿Quieres ${status === 'APROBADO' ? 'aprobar' : 'rechazar'} este proyecto?`,
      content: `Al hacer clic en el botón Aceptar, el proyecto "${project.name}" será ${status.toLowerCase()}.`,
      okText: 'Aceptar',
      cancelText: 'Cancelar',
      okButtonProps: {
        loading: updating,
        disabled: updating
      },
      centered: true,
      onOk: args => {
        modal.update({
          okButtonProps: {
            loading: true,
            disabled: true
          },
          cancelButtonProps: {
            disabled: true
          }
        });

        updateStatusProject({
          variables: {
            id: project.id,
            status
          }
        }).then(() => {
          modal.destroy();
        });
      }
    });
  };
  const editProject = id => {
    dispatch(toggleProjectModal({info: {id}, visible: true}));
  };

  if (error) return <Error500/>;

  const projects = data ? data.filterCMSProjects : null;

  const columns = [
    {
      key: "user",
      title: "Usuario",
      dataIndex: 'user',
      width: "25%",
      render: value => `${value.name} ${value.last_name}`
    },
    {
      title: "Proyecto",
      dataIndex: "name",
      key: "name",
      width: "38%",
      render: (value, project) => (
        <Button type="link" className="gx-m-0" onClick={() => editProject(project.id)}>
          <EllipsisText text={capitalize(value)} length={60} />
        </Button>
      )
    },
    {
      title: "Fecha publicación",
      dataIndex: "created_at",
      key: "created_at",
      align: "center",
      width: "15%",
      render: value => capitalize.words(moment(value).locale('es').format('ddd DD MMM[, ]YYYY'))
    },
    {
      title: "Estado",
      dataIndex: "status",
      key: "status",
      align: "center",
      width: "10%",
      render: status => {
        const colors = {
          'REGISTRADO': 'blue',
          'APROBADO': 'green',
          'REPORTADO': 'orange',
          'RECHAZADO': 'red'
        };
        return (
          <Tag color={colors[status]} className="gx-m-0">
            {capitalize(status)}
          </Tag>
        )
      }
    },
    {
      dataIndex: 'status',
      key: "actions",
      width: "12%",
      align: "center",
      render: (status, project) => (
        <Fragment>
          <Button
            size="small"
            className="gx-m-0 gx-mr-2"
            disabled={status === 'APROBADO'}
            onClick={() => changeStatus(project, 'APROBADO')}
          >
            Aprobar
          </Button>
          <Button
            size="small"
            className="gx-m-0"
            disabled={status === 'RECHAZADO'}
            onClick={() => changeStatus(project, 'RECHAZADO')}
          >
            Rechazar
          </Button>
        </Fragment>
      ),
    },
  ];

  return (
    <Auxiliary>
      <Row>
        <Col xl={12} lg={12} md={12} sm={16} xs={16}>
          <Search
            allowClear
            placeholder="Buscar proyecto"
            onChange={changeQuery}
            onSearch={searchProject}
            disabled={networkStatus === 4}
          />
        </Col>
        <Col xl={12} lg={12} md={12} sm={8} xs={8} className="gx-text-right">
          <Tooltip title="Actualizar" placement="left">
            <Button onClick={() => refetch()} className="gx-ml-2" icon={networkStatus === 4 ? 'loading' : 'sync'}/>
          </Tooltip>
        </Col>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <div className="gx-table-responsive">
            <Table
              className="gx-table-responsive"
              loading={{
                spinning: loading || (networkStatus === 4),
                indicator: spinner
              }}
              dataSource={projects ? projects.data : []}
              columns={columns}
              bordered
              size="middle"
              pagination={false}
              rowKey={(project, key) => key}
              locale={{
                emptyText: <Empty description="Sin registros" imageStyle={{height: 60}}/>
              }}
            />
            {projects && (
              <Pagination
                size="small"
                className="gx-mt-2 gx-float-right"
                defaultCurrent={page}
                showSizeChanger={true}
                locale={{items_per_page: ''}}
                onShowSizeChange={changeSizePagination}
                pageSizeOptions={['5', '10', '20', '50', '100']}
                total={projects.paginatorInfo.lastPage}
                pageSize={first}
                onChange={changePagination}
              />
            )}
          </div>
        </Col>
      </Row>
      <ProjectsModal updateStateUserProjects={null} useCMS={true}/>
    </Auxiliary>
  );
};

export default ProjectTable;
