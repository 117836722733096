export const schema = {
  nIdProyecto: {
    rule: {
      required: false,
      message: "Campo requerido"
    },
    label: "nIdProyecto",
    name: "nIdProyecto",
    type: "text",
    placeHolder: ""
  },
  sProyecto: {
    rule: {
      required: false,
      message: "Campo requerido"
    },
    label: "Proyecto",
    name: "sProyecto",
    type: "text",
    placeHolder: ""
  },
  sCliNombre: {
    rule: {
      required: true,
      message: "Campo requerido"
    },
    label: "Cliente",
    name: "sCliNombre",
    type: "text",
    placeHolder: ""
  },
  sObra: {
    rule: {
      required: true,
      message: "Campo requerido"
    },
    label: "Nombre Proyecto",
    name: "sObra",
    type: "text",
    placeHolder: ""
  },
  sTipoMedida: {
    rule: {
      required: true,
      message: "Campo requerido"
    },
    label: "Tipo Medida",
    name: "sTipoMedida",
    type: "checkbox",
    placeHolder: ""
  },
  dFechaRegistro: {
    rule: {
      required: false,
      message: "Campo requerido"
    },
    label: "Fecha",
    name: "dFechaRegistro",
    type: "DatePicker",
    placeHolder: ""
  },
  sEstado: {
    rule: {
      required: false,
      message: "Campo requerido"
    },
    label: "Estado",
    name: "sEstado",
    type: "text",
    placeHolder: ""
  },
  nOptimizador: {
    rule: {
      required: false,
      message: "Campo requerido"
    },
    label: "Optimizador",
    name: "nOptimizador",
    type: "text",
    placeHolder: ""
  },
  sPedido: {
    rule: {
      required: false,
      message: "Campo requerido"
    },
    label: "Pedido",
    name: "sPedido",
    type: "text",
    placeHolder: ""
  },
  dPedido: {
    rule: {
      required: false,
      message: "Campo requerido"
    },
    label: "Fecha",
    name: "dPedido",
    type: "text",
    placeHolder: ""
  },
  sFactura: {
    rule: {
      required: false,
      message: "Campo requerido"
    },
    label: "Factura",
    name: "sFactura",
    type: "text",
    placeHolder: ""
  },
  dFactura: {
    rule: {
      required: false,
      message: "Campo requerido"
    },
    label: "Fecha",
    name: "dFactura",
    type: "DatePicker",
    placeHolder: ""
  },
  sObsVentas: {
    rule: {
      required: false,
      message: "Campo requerido"
    },
    label: "Ventas",
    name: "sObsVentas",
    type: "textArea",
    placeHolder: ""
  },
  sObsProd: {
    rule: {
      required: false,
      message: "Campo requerido"
    },
    label: "Producción",
    name: "sObsProd",
    type: "text",
    placeHolder: ""
  }
};
