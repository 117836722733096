import {
  TOGGLE_MODAL_SHOPS,
  SET_LIST_SHOP,
  DELETE_SHOP_TABLE,
} from "../../constants/ActionTypes";

const initialState = {
  listShops: [],
  modalShops: {
    info: null,
    visible: false,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case TOGGLE_MODAL_SHOPS:
      return {
        ...state,
        modalShops: { info: payload.info, visible: payload.visible },
      };
    case SET_LIST_SHOP:
      return {
        ...state,
        listShops: payload,
      };
    case DELETE_SHOP_TABLE:
      const newList = state.listShops.filter((item) => item.id !== payload);
      return {
        ...state,
        listShops: newList,
      };

    default:
      return state;
  }
};
