import {
  TOGGLE_MODAL_PROJECT,
  SET_USER_PROJECTS,
  SEARCH_PROJECTS,
  QUERIED_TAGS,
  CHANGE_VARIABLES,
  TOGGLE_WIZARD_USER
} from "../../constants/ActionTypes";

export const toggleProjectModal = ({info, visible}) => ({
  type: TOGGLE_MODAL_PROJECT,
  payload: {info, visible}
});

export const toggleWizard = (visible, form = null) => {
  if (form) form.resetFields();
  return {
    type: TOGGLE_WIZARD_USER,
    payload: visible
  };
};

export const setUserProjects = projects => ({
  type: SET_USER_PROJECTS,
  payload: projects
});

export const queriedTags = tags => ({
  type: QUERIED_TAGS,
  payload: tags
});

export const searchProjects = ({loading, variables = null, data = [], hasMore = false}) => ({
  type: SEARCH_PROJECTS,
  payload: {data, loading, hasMore, variables}
});

export const changeVariables = variables => ({
  type: CHANGE_VARIABLES,
  payload: variables
});
